import dayjs from "dayjs";
import validate from "../../utils/Validations";

function NewPGData() {
    return {
        ap_contactId: '',
        ap_sin: '',
        ap_birthDate: '',
        ap_drivingExperienceYears: '',
        ap_everBankrupt: false,
        ap_everRepossessed: false,
        ap_defendantInLegalAction: false,
        ap_homeowner: false,
        ap_residentAtleast2Years: false,
    }
}

function NewCoAppData() {
    return {
        ap_clientId: '',
    }
}

function PGDetails(pgDataObj, contactsList, validateFields, isAppOpen) {
    // fetch contact name from contact list
    const IndividualPG = (pg) => {
        return {
            contact: {
                value: pg?.ap_contactId ? contactsList.find((contact) => contact.value === pg.ap_contactId)?.label : '',
                error: validateFields ? validate.RequiredField(contactsList.find((contact) => contact.value === pg.ap_contactId)) : '',
                hidden: false,
                required: true,
                disabled: !isAppOpen,
                inputType: 'themed-dropdown-search',
                label: 'Contact',
                dropdownContent: contactsList || [],
                placeholder: 'Search',
                size: 'large',
                enableLock: true,
            },
            sin: {
                value: validate.ValidateSIN(pg?.ap_sin).value,
                error: validateFields ? validate.ValidateSIN(pg?.ap_sin).error : '',
                hidden: false,
                required: true,
                disabled: !isAppOpen,
                inputType: 'themed-input',
                label: 'SIN',
                placeholder: 'Enter SIN#',
                size: 'large',
                enableLock: true,
            },
            birthDate: {
                value: pg?.ap_birthDate ? dayjs(pg?.ap_birthDate) : '',
                error: validateFields ? validate.RequiredField(pg?.ap_birthDate) : '',
                hidden: false,
                required: true,
                disabled: !isAppOpen,
                inputType: 'themed-date',
                label: 'Date of Birth',
                size: 'large',
                enableLock: true,
            },
            drivingExperience: {
                value: pg?.ap_drivingExperienceYears,
                error: (pg?.ap_drivingExperienceYears)? validate.ValidateDrivingExperience(pg?.ap_drivingExperienceYears).error : '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-input',
                label: 'Years of Driving Experience',
                placeholder: 'Years',
                size: 'large',
                enableLock: true,
            },
            everBankrupt: {
                value: !!pg?.ap_everBankrupt,
                error: '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-radio',
                label: 'Ever Bankrupt?',
                size: 'large',
                enableLock: true,
            },
            everRepossessed: {
                value: !!pg?.ap_everRepossessed,
                error: '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-radio',
                label: 'Ever Repossessed?',
                size: 'large',
                enableLock: true,
            },
            defandantInLegalAction: {
                value: !!pg?.ap_defendantInLegalAction,
                error: '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-radio',
                label: 'Defendant in legal action?',
                size: 'large',
                enableLock: true,
            },
            homeowner: {
                value: !!pg?.ap_homeowner,
                error: '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-radio',
                label: 'Homeowner?',
                size: 'large',
                enableLock: true,
            },
            residentfor2Years: {
                value: !!pg?.ap_residentAtleast2Years,
                error: '',
                hidden: false,
                required: false,
                disabled: !isAppOpen,
                inputType: 'themed-radio',
                label: 'Resident for at least 2 years?',
                size: 'large',
                enableLock: true,
            },
            pgId: {
                value: pg._id,
                error: '',
                hidden: true,
                required: true,
                disabled: !isAppOpen,
                inputType: 'themed-input',
                label: 'SIN',
                placeholder: 'Enter SIN#',
                size: 'large',
                enableLock: true,
            },
        }
    }

    return Object.values(pgDataObj)?.map(pg => IndividualPG(pg));
}

function CoAppDetails(coAppDataObj, clientList, validateFields, isAppOpen) {
    const IndividualCoApp = (coApp) => {
        const coAppClientId = coApp?.ap_clientId?._id || coApp?.ap_clientId;
        return {
            client: {
                value: coApp?.name || coAppClientId,
                error: validateFields ? validate.RequiredField(coAppClientId) : '',
                hidden: false,
                required: true,
                disabled: !isAppOpen,
                // disabled: Boolean(coApp?.existing),
                inputType: 'themed-dropdown-search',
                label: 'Co-Applicant',
                dropdownContent: clientList || [],
                placeholder: 'Search',
                size: 'large',
                enableLock: true,
            },
        }
    }

    return Object.values(coAppDataObj)?.map(coApp => IndividualCoApp(coApp));

}

export default {
    NewPGData,
    NewCoAppData,
    PGDetails,
    CoAppDetails,
}