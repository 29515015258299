import { Form, Input, theme } from 'antd';
import { useState } from 'react';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from './index.module.css';

export default function PasswordComponent({ data, item, layout, showRequirement }) {
    const { token: { inputColorBg, ap_userPrimaryColor }, } = theme.useToken();
    const [focused, setFocused] = useState(false);
    return (
        <>
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout == 'vertical' ?
                            <>
                                <Input.Password
                                    onChange={data.eventHandler}
                                    value={data.value}
                                    status={!!data.error ? 'error' : ''}
                                    style={{ height: 'fit-content', backgroundColor: inputColorBg }}
                                    addonBefore={data.addonBefore}
                                    pattern={data.pattern}
                                    disabled={data.disabled}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                />
                                {showRequirement ? <div style={focused ? { textAlign: 'left', marginLeft: '-6%', color: ap_userPrimaryColor } : { textAlign: 'left', marginLeft: '-6%', color: 'lightgray' }}>
                                    <div style={{ marginLeft: '25px' }}>Password must:</div>
                                    <ul>
                                        <li>Contain min. 8 characters</li>
                                        <li>Contain upper and lowercase letters</li>
                                        <li>Contain atleast 1 number</li>
                                        <li>Contain 1 special character</li>
                                    </ul>
                                </div> : <>{!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}</>}
                            </>
                            : null}
                    </Form.Item>
                    {layout == 'horizontal' ?
                        <>
                            <Input.Password
                                onChange={data.eventHandler}
                                value={data.value}
                                status={!!data.error ? 'error' : ''}
                                style={{ height: 'fit-content', backgroundColor: inputColorBg }}
                                addonBefore={data.addonBefore}
                                disabled={data.disabled}
                                suffix={data.suffix}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                            />
                            {/* TODO: Bring back the checkmarks for Each Password Requirement */}
                            {showRequirement ? <div style={focused ? { textAlign: 'left', marginLeft: '-6%', color: ap_userPrimaryColor } : { textAlign: 'left', marginLeft: '-6%', color: 'lightgray' }}>
                                <div style={{ marginLeft: '25px' }}>Password must:</div>
                                <ul>
                                    <li>Contain min. 8 characters</li>
                                    <li>Contain upper and lowercase letters</li>
                                    <li>Contain atleast 1 number</li>
                                    <li>Contain 1 special character</li>
                                </ul>
                            </div> : <>{!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}</>}
                        </>
                        : null}
                </div>
            )}
        </>
    )
}