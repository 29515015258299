import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Table, Tag, theme } from "antd";

import BeaconPagination from "../../components/BeaconPagination";
import ApiService from '../../api';
import utils from "../../utils";
import ApprovalConstants from "./constants";

export default function ApprovalsList({ entity, entityId, status, tab }) {

    const { token: { themePrimaryColor, themeFontColor }, } = theme.useToken();

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [approvalsData, setApprovalsData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // Filter Fields
    const [filters, setFilters] = useState({});
    const [customSort, setCustomSort] = useState({
        ap_client: { order: null, type: 'string' },
        ap_lender: { order: null, type: 'string' },
        ap_submissionType: { order: null, type: 'string' },
        ap_approvedAmount: { order: null, type: 'string' },
        ap_creditAvailable: { order: null, type: 'string' },
        ap_expiryDate: { order: null, type: 'date' },
    });

    // Fetch Approvals
    useEffect(() => {
        setLoading(true);
        if (entity === 'agent') {
            if (status === 'Expired') {
                ApiService.fetchExpiredApprovals().then((data) => {
                    if (!data) {
                        setLoading(false);
                        return;
                    }
                    for (let i = 0; i < data.length; i++) {
                        data[i].ap_approvedAmount = utils.TransformDBCurrency(data[i].ap_approvedAmount);
                        data[i].ap_creditAvailable = utils.TransformDBCurrency(data[i].ap_creditAvailable);
                        data[i].ap_client = data[i]?.ap_clientId?.ap_businessName;
                        data[i].ap_lender = data[i]?.ap_lenderId?.ap_abbreviation || data[i]?.ap_lenderId?.ap_name;
                    }
                    setApprovalsData(data);
                    setLoading(false);
                });
            } else if (status === 'Cancelled') {
                ApiService.fetchAgentCancelled().then((data) => {
                    if (!data) {
                        setLoading(false);
                        return;
                    }
                    for (let i = 0; i < data.length; i++) {
                        data[i].ap_approvedAmount = utils.TransformDBCurrency(data[i].ap_approvedAmount);
                        data[i].ap_creditAvailable = utils.TransformDBCurrency(data[i].ap_creditAvailable);
                        data[i].ap_client = data[i]?.ap_clientId?.ap_businessName;
                        data[i].ap_lender = data[i]?.ap_lenderId?.ap_abbreviation || data[i]?.ap_lenderId?.ap_name;
                    }
                    setApprovalsData(data);
                    setLoading(false);
                });
            } else {
                ApiService.fetchAgentCreditAvailable().then((data) => {
                    if (!data) {
                        setLoading(false);
                        return;
                    }
                    
                    const filteredData = data.filter((item) => item?.ap_status !== 'Cancelled');
                    for (let i = 0; i < filteredData.length; i++) {
                        filteredData[i].ap_approvedAmount = utils.TransformDBCurrency(filteredData[i].ap_approvedAmount);
                        filteredData[i].ap_creditAvailable = utils.TransformDBCurrency(filteredData[i].ap_creditAvailable);
                        filteredData[i].ap_client = filteredData[i]?.ap_clientId?.ap_businessName;
                        filteredData[i].ap_lender = filteredData[i]?.ap_lenderId?.ap_abbreviation || filteredData[i]?.ap_lenderId?.ap_name;
                    }
                    setApprovalsData(filteredData);
                    setLoading(false);
                });
            }
        } else if (entity === 'client') {
            // here entity id is client id
            ApiService.fetchClientCreditAvailable(entityId).then((data) => {
                if (!data) {
                    setLoading(false);
                    return;
                }
                for (let i = 0; i < data.length; i++) {
                    data[i].ap_approvedAmount = utils.TransformDBCurrency(data[i].ap_approvedAmount);
                    data[i].ap_creditAvailable = utils.TransformDBCurrency(data[i].ap_creditAvailable);
                    data[i].ap_client = data[i]?.ap_clientId?.ap_businessName;
                    data[i].ap_lender = data[i]?.ap_lenderId?.ap_abbreviation || data[i]?.ap_lenderId?.ap_name;
                }
                setApprovalsData(data);
                setLoading(false);
            });
        }

    }, [entity, entityId, tab]);

    const { pagedData, paginationComponent } = BeaconPagination(approvalsData, ApprovalsList.name, true, true, searchedColumn, searchText, filters, customSort, setCustomSort);

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50',
                    },
                }}
            >
                <Table
                    id={ApprovalsList.name}
                    columns={ApprovalConstants.generateApprovalsListTableColumns(approvalsData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, entity, setFilters, customSort, setCustomSort)}
                    dataSource={pagedData}
                    loading={loading}
                    scroll={{ x: true, y: 380 }}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate(`/approvals/${record._id}`);
                        },
                        className: 'clickable-row'
                    })}
                    pagination={false}
                    footer={() => paginationComponent}
                />
            </ConfigProvider>
        </>
    );
}