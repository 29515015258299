import { Button, ConfigProvider, Modal, theme } from "antd";
import styles from './index.module.css'

export default function DeleteModal({ open, handler, deleteEntity, eventHandler }) {

    const { token: { themeFontColor } } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: themeFontColor,
                }
            }}
        >
            <Modal
                open={open}
                footer={null}
                closable={false}
                getContainer=".AppTheme"
                centered
            >
                <div style={{ margin: '5%' }}>

                    <div className={styles["header"]}>
                        {`Are you sure you want to delete this ${deleteEntity}?`}
                    </div>

                    <div style={{ color: 'gray', textAlign: 'center', fontSize: '16px' }}>
                        This action cannot be undone.
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-around', margin: '5%' }}>
                        <Button
                            className={styles["deleteButton"]}
                            onClick={eventHandler}
                        >Delete</Button>

                        <Button
                            type='primary'
                            style={{ width: '100px' }}
                            onClick={() => handler(false)}
                        >Cancel</Button>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    );
}