import SideNavIcons from "../../assets/images/icons/SideNavIcons";

const mainNavItems = [
    {
        name: 'Dashboard',
        icon: SideNavIcons?.Dashboard,
        path: 'home'
    },
    {
        name: 'Clients',
        icon: SideNavIcons?.Client,
        path: 'clients'
    },
    {
        name: 'Applications',
        icon: SideNavIcons?.Application,
        path: 'applications'
    },
    {
        name: 'Approvals',
        icon: SideNavIcons?.Approval,
        path: 'approvals'
    },
    {
        name: 'Drawdowns',
        icon: SideNavIcons?.Drawdown,
        path: 'drawdowns'
    },
    {
        name: 'Requirements',
        icon: SideNavIcons?.Requirement,
        path: 'requirements'
    },
];

const additionalNavItems = [
    {
        name: 'Vendor Market',
        icon: SideNavIcons?.VendorMarket,
        path: 'listings'
    },
    {
        name: 'Resources',
        icon: SideNavIcons?.Resources,
        path: 'resources'
    },
];

const sideNavConstants = {
    mainNavItems,
    additionalNavItems,
};

export default sideNavConstants;