import { useState } from "react";

import { theme } from "antd";
import Icon from '@ant-design/icons';


export default function DownloadIcon({ eventHandler }) {

    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovered ?
                <Icon component={DownloadFilled} onClick={eventHandler} /> :
                <Icon component={Download} onClick={eventHandler} />
            }
        </div>
    );

}

function Download() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M8 21C8 20.4477 8.41787 20 8.93333 20H21.0667C21.5821 20 22 20.4477 22 21C22 21.5523 21.5821 22 21.0667 22H8.93333C8.41787 22 8 21.5523 8 21Z" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3356 12.0192C10.7484 11.6683 11.3805 11.7038 11.7474 12.0987L14 14.5226L14 7.95652C14 7.42825 14.4477 7 15 7C15.5523 7 16 7.42825 16 7.95652L16 14.5227L18.2526 12.0987C18.6195 11.7038 19.2516 11.6683 19.6644 12.0192C19.8843 12.2062 19.9976 12.4651 20 12.7259L20 12.7408C19.9985 12.9647 19.9152 13.1891 19.7474 13.3696L15.973 17.4312C15.5 18 15.3329 18 15 18C14.6671 18 14.5 18 14.027 17.4312L10.2526 13.3696C10.0836 13.1878 10.0004 12.9615 10 12.736L10 12.7305C10.0011 12.4681 10.1145 12.2073 10.3356 12.0192Z" fill={ap_userPrimaryColor} />
        </svg>

    )
}

function DownloadFilled() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 15C30 23.2843 23.2852 30 15 30C6.71484 30 0 23.2843 0 15C0 6.71573 6.71484 0 15 0C23.2852 0 30 6.71573 30 15ZM8 21C8 20.4477 8.41797 20 8.93359 20H21.0664C21.582 20 22 20.4477 22 21C22 21.5523 21.582 22 21.0664 22H8.93359C8.41797 22 8 21.5523 8 21ZM11.748 12.0987C11.3809 11.7038 10.748 11.6683 10.3359 12.0192C10.1152 12.2073 10.002 12.4681 10 12.7305V12.736C10 12.9615 10.084 13.1878 10.252 13.3696L14.0273 17.4312C14.5 18 14.668 18 15 18C15.332 18 15.5 18 15.9727 17.4312L19.748 13.3696C19.916 13.1891 19.998 12.9647 20 12.7408V12.7259C19.998 12.4651 19.8848 12.2062 19.6641 12.0192C19.252 11.6683 18.6191 11.7038 18.252 12.0987L16 14.5227V7.95652C16 7.42825 15.5527 7 15 7C14.4473 7 14 7.42825 14 7.95652V14.5226L11.748 12.0987Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}