import Icon from '@ant-design/icons';
import { theme } from "antd";

function Location() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="28" height="24" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0C6.3 0 0 6.3 0 14C0 23.45 12.25 34.125 12.775 34.65C13.125 34.825 13.65 35 14 35C14.35 35 14.875 34.825 15.225 34.65C15.75 34.125 28 23.45 28 14C28 6.3 21.7 0 14 0ZM14 30.975C10.325 27.475 3.5 19.95 3.5 14C3.5 8.225 8.225 3.5 14 3.5C19.775 3.5 24.5 8.225 24.5 14C24.5 19.775 17.675 27.475 14 30.975ZM14 7C10.15 7 7 10.15 7 14C7 17.85 10.15 21 14 21C17.85 21 21 17.85 21 14C21 10.15 17.85 7 14 7ZM14 17.5C12.075 17.5 10.5 15.925 10.5 14C10.5 12.075 12.075 10.5 14 10.5C15.925 10.5 17.5 12.075 17.5 14C17.5 15.925 15.925 17.5 14 17.5Z" fill={ap_userPrimaryColor} />
        </svg>

    )
}

export default function LocationIconOutlined(props) {
    return <Icon component={Location} {...props} />
}