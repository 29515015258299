import { ConfigProvider, Row, Table, Tag, theme } from "antd";
import styles from "./index.module.css";

// NOTE: This is a custom wrapper on top of Ant Design's <Tag /> component supporting just red and green styles for bijections of binary data
// For more advances uses, please use the Ant Design component directly, such as when supporting more nuanced styles and additional colors
const TagComponent = ({ _id, active, message }) => {
    return (
        <>
            <Tag key={`status_${_id}`} className={active ? styles["greenTag"] : styles["redTag"] }>
                {message}
            </Tag>
        </>
    )
}

export default TagComponent;
