export default function ValidateYear(year){
    let yearInt = parseInt(year);
    const thresholdStart = 1950;
    const thresholdEnd = 2050;

    // If Input contains Number
    if(!!yearInt){  
        return {
            value: yearInt,
            error: ((thresholdStart <= yearInt) && (yearInt <= thresholdEnd))?'':'Year should be between 1950 and 2050',
        } 
    }

    // If Input is not a Number
    return{
        value: '',
        error: 'Please enter a valid year'
    }
}
