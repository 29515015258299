import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";

import BeaconPagination from "../../components/BeaconPagination";
import ApiService from "../../api";
import ongoingConstants from "./constants";

import styles from "./index.module.css";

export default function OngoingDrawdownsList({ entity, entityId }) {

    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [uncleanData, setUncleanData] = useState();
    const [cleanData, setCleanData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customSort, setCustomSort] = useState({
        lcNumber: { order: null, type: 'string' },
        clientName: { order: null, type: 'string' },
        lenderName: { order: null, type: 'string' },
    });

    useEffect(() => {
        setLoading(true);
        if (entity === 'agent')
            ApiService.fetchAgentOngoingDrawdowns(entityId).then((data) => {
                if (!data) {
                    setLoading(false);
                    return;
                }
                setUncleanData(data);
                setLoading(false);
            });
        else if (entity === 'client')
            ApiService.fetchClientOngoingDrawdowns(entityId).then((data) => {
                if (!data) {
                    setLoading(false);
                    return;
                }
                setUncleanData(data);
                setLoading(false);
            });
    }, [entity, entityId]);

    useEffect(() => {
        const tempData = [];
        for (let i = 0; i < uncleanData?.length; i++) {
            tempData.push({
                id: uncleanData[i]?._id,
                lcNumber: uncleanData[i]?.crm_lcNumber,
                clientName: uncleanData[i]?.ap_clientId?.ap_businessName,
                assetDescription: uncleanData[i]?.ap_assetDescription,
                lenderName: uncleanData[i]?.ap_lenderId?.ap_abbreviation || uncleanData[i]?.ap_lenderId?.ap_name,
                agentFee: uncleanData[i]?.ap_agentFee,
                drawdownFee: uncleanData[i]?.ap_drawdownFee,
                processStage: uncleanData[i]?.ap_processStage,
                paymentScheduleId: uncleanData[i]?.ap_paymentSchedule?._id,
                responsibleType: uncleanData[i]?.ap_responsibleType,
                ap_dealPrepDate: uncleanData[i]?.ap_dealPrepDate,
                ap_documentsRequestedDate: uncleanData[i]?.ap_documentsRequestedDate,
                ap_documentsReceivedDate: uncleanData[i]?.ap_documentsReceivedDate,
                ap_fundingRequestedDate: uncleanData[i]?.ap_fundingRequestedDate,
                ap_vendorFundedDate: uncleanData[i]?.ap_vendorFundedDate,
                ap_postFundingRequirementDate: uncleanData[i]?.ap_postFundingRequirementDate,
                ap_brokerFundedDate: uncleanData[i]?.ap_brokerFundedDate,
            });
        }
        setCleanData(tempData);
    }, [uncleanData]);

    const { pagedData, paginationComponent } = BeaconPagination(cleanData, OngoingDrawdownsList.name, true, true, searchedColumn, searchText, {}, customSort);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <Table
                id={OngoingDrawdownsList.name}
                columns={ongoingConstants.generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort)}
                dataSource={pagedData}
                loading={loading}
                scroll={{ x: true, y: 400 }}
                onRow={(record) => ({
                    onClick: () => {
                        navigate(`/drawdowns/${record.id}`);
                    },
                    className: styles["OngoingDrawdownRow"]
                })}
                pagination={false}
                footer={() => paginationComponent}
            />
        </ConfigProvider>
    );
}