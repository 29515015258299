import Icon from '@ant-design/icons';
import { theme } from "antd";

function CompanyType() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="24" height="20" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.8 26.5927C2.03 26.5927 1.3706 26.3183 0.821802 25.7695C0.273002 25.2207 -0.000930956 24.5617 2.37691e-06 23.7927V8.39268C2.37691e-06 7.62268 0.274402 6.96328 0.823202 6.41448C1.372 5.86568 2.03094 5.59174 2.8 5.59268H8.4V2.79268C8.4 2.02268 8.6744 1.36328 9.2232 0.814478C9.772 0.265678 10.4309 -0.00825517 11.2 -0.00732184H16.8C17.57 -0.00732184 18.2294 0.267078 18.7782 0.815878C19.327 1.36468 19.6009 2.02361 19.6 2.79268V5.59268H25.2C25.97 5.59268 26.6294 5.86708 27.1782 6.41588C27.727 6.96468 28.0009 7.62361 28 8.39268V23.7927C28 24.5627 27.7256 25.2221 27.1768 25.7709C26.628 26.3197 25.9691 26.5936 25.2 26.5927H2.8ZM2.8 23.7927H25.2V8.39268H2.8V23.7927ZM11.2 5.59268H16.8V2.79268H11.2V5.59268Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function CompanyTypeIcon(props) {
    return <Icon component={CompanyType} {...props} />
}