import { theme } from "antd";
import fallbackTheme from '../../../../../constants/DEFAULT_THEME';

export default function ConcessionRequestApproved() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor || fallbackTheme.token.ap_userSecondaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.0159 43.3333C41.7252 43.3333 44.7323 40.3263 44.7323 36.6169C44.7323 32.9076 41.7252 29.9005 38.0159 29.9005C34.3065 29.9005 31.2994 32.9076 31.2994 36.6169C31.2994 40.3263 34.3065 43.3333 38.0159 43.3333ZM37.0588 37.6076L35.6148 36.1636C35.4917 36.0404 35.335 35.9789 35.1447 35.9789C34.9544 35.9789 34.7977 36.0404 34.6745 36.1636C34.5514 36.2867 34.4898 36.4434 34.4898 36.6337C34.4898 36.824 34.5514 36.9807 34.6745 37.1039L36.5887 39.0181C36.723 39.1524 36.8797 39.2195 37.0588 39.2195C37.2379 39.2195 37.3947 39.1524 37.529 39.0181L41.3238 35.2233C41.4469 35.1001 41.5085 34.9434 41.5085 34.7531C41.5085 34.5628 41.4469 34.4061 41.3238 34.283C41.2006 34.1598 41.0439 34.0983 40.8536 34.0983C40.6633 34.0983 40.5066 34.1598 40.3835 34.283L37.0588 37.6076Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9294 40.7806C19.1426 41.7362 21.5073 42.214 24.0236 42.214C26.4209 42.2151 28.6805 41.7821 30.8026 40.9148C30.1964 39.8996 29.7985 38.7456 29.6678 37.5118C27.9428 38.2212 26.0614 38.5759 24.0236 38.5759C19.9611 38.5759 16.5201 37.1662 13.7006 34.3467C10.8811 31.5272 9.47137 28.0862 9.47137 24.0237C9.47137 19.9612 10.8811 16.5202 13.7006 13.7007C16.5201 10.8812 19.9611 9.47143 24.0236 9.47143C28.0861 9.47143 31.5271 10.8812 34.3466 13.7007C37.1661 16.5202 38.5759 19.9612 38.5759 24.0237C38.5759 25.5056 38.3883 26.9049 38.0131 28.2214C38.0141 28.2214 38.0151 28.2214 38.0162 28.2214C39.2738 28.2214 40.4669 28.498 41.5379 28.9936C41.9886 27.4084 42.2139 25.7518 42.2139 24.0237C42.2151 21.5073 41.7379 19.1426 40.7823 16.9295C39.8267 14.7163 38.5304 12.7912 36.8933 11.154C35.2561 9.51812 33.331 8.22237 31.1178 7.26677C28.9047 6.31117 26.5399 5.83337 24.0236 5.83337C21.5073 5.83459 19.1426 6.31239 16.9294 7.26677C14.7162 8.22115 12.7911 9.51691 11.154 11.154C9.51806 12.7912 8.22231 14.7163 7.26671 16.9295C6.31111 19.1426 5.83331 21.5073 5.83331 24.0237C5.83453 26.54 6.31232 28.9047 7.26671 31.1179C8.22109 33.331 9.51685 35.2562 11.154 36.8933C12.7911 38.5292 14.7162 39.825 16.9294 40.7806Z" fill="white" />
            <path d="M23.9779 36.7568C24.4023 36.7568 24.774 36.5974 25.093 36.2784C25.4119 35.9595 25.5708 35.5884 25.5695 35.1652V34.483C27.0854 34.2102 28.389 33.619 29.4805 32.7095C30.5719 31.8 31.1176 30.4509 31.1176 28.6621C31.1176 27.3888 30.7538 26.2216 30.0262 25.1605C29.2986 24.0994 27.8433 23.1747 25.6605 22.3865C23.8415 21.7802 22.5833 21.2496 21.886 20.7948C21.1887 20.3401 20.8401 19.7186 20.8401 18.9303C20.8401 18.1421 21.1208 17.5206 21.6823 17.0658C22.2438 16.6111 23.0544 16.3837 24.1143 16.3837C24.7207 16.3837 25.2512 16.4898 25.706 16.702C26.1607 16.9142 26.5397 17.2023 26.8429 17.5661C27.146 17.9299 27.4874 18.1797 27.867 18.3155C28.2466 18.4513 28.6025 18.444 28.9348 18.2937C29.3895 18.1118 29.7006 17.8007 29.8679 17.3605C30.0353 16.9203 29.9971 16.5189 29.7533 16.1563C29.2682 15.459 28.6692 14.8678 27.9561 14.3828C27.2431 13.8977 26.4778 13.6248 25.6605 13.5642V12.8821C25.6605 12.4576 25.501 12.0859 25.1821 11.767C24.8632 11.4481 24.4927 11.2892 24.0707 11.2904C23.6462 11.2904 23.2745 11.4499 22.9556 11.7688C22.6367 12.0878 22.4778 12.4588 22.479 12.8821V13.5642C20.9632 13.8977 19.7808 14.5647 18.9319 15.5651C18.083 16.5656 17.658 17.6873 17.6568 18.9303C17.6568 20.3552 18.0739 21.5073 18.9083 22.3865C19.7426 23.2657 21.0535 24.0236 22.841 24.6603C24.751 25.3576 26.0777 25.9791 26.821 26.5248C27.5644 27.0705 27.9355 27.7829 27.9343 28.6621C27.9343 29.6626 27.5778 30.3981 26.8647 30.8686C26.1516 31.3391 25.2955 31.5738 24.2962 31.5726C23.508 31.5726 22.7955 31.3834 22.1589 31.0051C21.5222 30.6267 20.9917 30.0579 20.5672 29.2988C20.3247 28.8744 20.0063 28.5864 19.6122 28.4348C19.2181 28.2832 18.824 28.2832 18.4299 28.4348C18.0054 28.5864 17.6944 28.8744 17.4967 29.2988C17.299 29.7232 17.2917 30.1325 17.4749 30.5266C17.9599 31.5574 18.6118 32.399 19.4303 33.0515C20.2489 33.7039 21.2342 34.1508 22.3862 34.3921V35.1652C22.3862 35.5896 22.5457 35.9613 22.8646 36.2802C23.1836 36.5992 23.5547 36.758 23.9779 36.7568Z" fill="#645A80" />
        </svg>
    );
}