import NotificationService from "../services/notification.service";

export default async function CopyToClipboard (url) {
    try {
        await navigator.clipboard.writeText(url);
        NotificationService.success('Link copied to clipboard!');
    } catch (err) {
        NotificationService.error('Failed to copy link to clipboard!');
        console.error('Failed to copy: ', err);
    }
};