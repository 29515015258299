import { Button, Upload } from "antd";
import UploadIcon from "../../assets/images/icons/UploadIcon";

import ApiService from "../../api";
import utils from "../../utils";

import styles from "./index.module.css";
import NotificationService from "../../services/notification.service";

const { Dragger } = Upload;

export default function UploadDragger({ allowMultiple, showUploadList, customRequest, folderName, setFiles, ...props }) {
 
    const uploadFile = (fileData) => {
        ApiService.uploadDocs(fileData).then((response) => {
            if (response?.error){
                setFiles((prevFiles) => 
                    prevFiles?.filter((item) => item?.name !== fileData?.fileName)
                );
                // TODO: change the message of the error , for now the notification is sent by the handleInterceptorError func.
                // NotificationService.error("Error while uploading file");
                return;
            };
   
            setFiles((prevFiles) =>
                prevFiles.map((file) =>
                    file.name === fileData?.fileName
                        ? {
                            ...file,
                            url: response?.data?.url,
                            sentToSharePoint: false,
                            uploading: false
                        }
                        : file
                )
            );
        });
    };    
 
    const handleFileUpload = (file) => {
        const fileName = utils.sanitizeFileName(file?.file?.name);

        // Add the file to the state first with 'sentToSharePoint: false'
        setFiles(prevFiles => [
            ...prevFiles,
            {
                name: fileName,
                sentToSharePoint: false,
                uploading: true
            }
        ]);

        const reader = new FileReader();

        reader.onload = function (event) {
            const base64 = event.target.result;
            const fileData = {
                data: base64,
                fileName: utils.sanitizeFileName(file?.file?.name),
                folderName: folderName,
                containerName: JSON.parse(localStorage?.getItem("user"))?.agent_id,
            }

            // Use the uploadFile function to handle the upload and update the URL
            uploadFile(fileData);
        }

        reader.onerror = function (event) {
            console.error('Error reading file: ', event)
        }

        reader.readAsDataURL(file?.file);
    }

    const uploadProps = {
        multiple: allowMultiple,
        showUploadList: showUploadList,
        customRequest: customRequest || handleFileUpload,
    };

    return (
        <div id="requirementsDragger">

            <Dragger {...uploadProps}>

                <div>
                    <div><UploadIcon /></div>

                    <div className={styles["DraggerInfo-Header"]}>Drag and drop files here</div>

                    <div className={styles["DraggerInfo-SubHeader"]}>or</div>

                    <Button className={styles["browseButton"]}>Browse</Button>
                </div>

            </Dragger>
        </div>
    );
}