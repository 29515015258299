import ApiService from "../../api";
import utils from "../../utils";

function CleanListingRecord(data = {}) {
    const fallbackImage = process.env.REACT_APP_STATIC_CONTAINER + process.env.REACT_APP_LISTING_FALLBACK_IMAGE;
    const cardAssetImage = data?.ap_assetPhoto?.[0] || fallbackImage;
    const assetPrice = utils.parseDBCurrency(data?.ap_price)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    const listingName = `${data?.ap_year} ${data?.ap_make} ${data?.ap_model} ${data?.ap_equipmentType}`;
    const listingTitle = `${data?.ap_year} ${data?.ap_make} ${data?.ap_equipmentType}`;

    const cleanedData = {
        fallbackImage,
        cardAssetImage,
        assetDescription: data?.ap_assetDescription || '--',
        year: data?.ap_year || '--',
        make: data?.ap_make || '--',
        model: data?.ap_model || '--',
        type: data?.ap_equipmentType || '--',
        listNumber: data?.crm_listNumber || '--',
        kmsHours: utils.NumberWithCommas(data?.ap_kmsHours) || '--',
        price: data?.ap_price || '--',
        priceFormatted: assetPrice || '--',
        assetPictures: data?.ap_assetPhoto || [],
        _id: data?._id,
        description: data?.ap_description,
        multipleAssets: data?.ap_multipleAssets,
        quantity: data?.ap_quantity,
        status: data?.ap_status,
        listStatus: data?.ap_listStatus,
        uniqueEntityId: data?.ap_uniqueEntityId,
        listingName: listingName,
        agentInterested: data?.agentInterested,
        listingTitle: listingTitle,
    }
    return cleanedData;
}

async function fetchListingPictures(uniqueEntityId) {
    if (!uniqueEntityId)
        return [];

    const requestData = {
        containerName: 'vendorlistings',    // Move to env vars
        blobName: uniqueEntityId
    }
    const pictures = await ApiService.fetchDocuments(requestData).then((response) => {
        if (response?.data) {
            return response?.data?.map(item => item.url);;
        }
        else
            return [];
    });
    return pictures;
}

async function fetchAndClean(data) {
    if (!data || Object.values(data).length === 0)
        return {};

    const fallbackImage = process.env.REACT_APP_STATIC_CONTAINER + process.env.REACT_APP_LISTING_FALLBACK_IMAGE;
    const assetPhotos = await fetchListingPictures(data?.ap_uniqueEntityId);
    const transformedData = {
        ...data,
        ap_assetPhoto: assetPhotos?.length > 0 ? assetPhotos : [fallbackImage]
    };

    const cleanedData = CleanListingRecord(transformedData);
    return cleanedData;
}

const exportObj = {
    fetchListingPictures,
    fetchAndClean,
}

export default exportObj;