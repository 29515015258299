import { theme } from 'antd';
import Icon from '@ant-design/icons';

const CheckTask = ({fillColor}) => {
    const { token: {iconColor}, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_2301_16549" fill="white">
        <path d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5Z"/>
        </mask>
        <path d="M19 10.5C19 15.1944 15.1944 19 10.5 19V23C17.4036 23 23 17.4036 23 10.5H19ZM10.5 19C5.80558 19 2 15.1944 2 10.5H-2C-2 17.4036 3.59644 23 10.5 23V19ZM2 10.5C2 5.80558 5.80558 2 10.5 2V-2C3.59644 -2 -2 3.59644 -2 10.5H2ZM10.5 2C15.1944 2 19 5.80558 19 10.5H23C23 3.59644 17.4036 -2 10.5 -2V2Z" fill="#90EE90" mask="url(#path-1-inside-1_2301_16549)"/>
        <path d="M5.83337 10.401L8.87937 13.8601M9.00255 13.9999L15.1667 6.99994" stroke="#90EE90" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};

const CheckTaskHover = ({fillColor}) => {
    const { token: {iconColor}, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21ZM15.9172 7.66087C16.2822 7.24639 16.2421 6.61449 15.8276 6.2495C15.4131 5.88451 14.7812 5.92463 14.4162 6.33911L9.00257 12.4869L6.58388 9.7402C6.21889 9.32572 5.587 9.28559 5.17251 9.65059C4.75802 10.0156 4.7179 10.6475 5.0829 11.062L8.12889 14.521C8.14948 14.5444 8.17092 14.5666 8.19311 14.5875C8.23563 14.646 8.28518 14.7007 8.34169 14.7505C8.75617 15.1155 9.38807 15.0754 9.75306 14.6609L15.9172 7.66087Z" fill="#90EE90"/>
        </svg>

  );
};

const CheckIcon = {
    CheckTaskIcon : function CheckTaskIcon (props) {
        return <Icon component={CheckTask} {...props}/>
    },
    CheckTaskHoverIcon: function CheckTaskHoverIcon (props) {
        return <Icon component={CheckTaskHover} {...props}/>
    }
}

export default CheckIcon;

