import { useNavigate } from "react-router-dom";
import { Button, theme } from "antd";

import generateDetails from "./constants";

import NotificationDetailsBlock from "../../NotificationDetailBlock";
import validate from "../../../utils/Validations";

import styles from "./index.module.css";
import NotificationComparisionBlock from "../../NotificationComparisionBlock";

import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";

export default function SubmissionAmendment({ notificationData }) {

    const navigate = useNavigate();

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const clientTelephone = `tel:${notificationData.ap_data.clientTelephone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`
    const clientCity = [notificationData.ap_data.clientCity, notificationData.ap_data.clientStateOrProvince].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const lenderCityProvince = [notificationData.ap_data.lenderCity, notificationData.ap_data.lenderStateOrProvince].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const details = generateDetails(notificationData.ap_data);


    return (
        <div className={styles["NotificationBody"]}>
            <div><NotificationScreenIcons.SubmissionUpdatedIcon /></div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: ap_userPrimaryColor }}
            >APPROVAL UPDATED</div>

            <div>
                Approval updated for <span className={styles["NotificationTitleInfo"]}>{notificationData.ap_data.clientBusinessName} - {notificationData.ap_data.lenderName}</span>.
            </div>

            <div className={styles["AmendmentBlock"]}>
                <NotificationComparisionBlock data={details.amendedDetails} />
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientBusinessName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData.ap_data.clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock data={details.submissionDetails} />
                    <NotificationDetailsBlock data={details.approvalDetails} />
                </div>
                {
                    details.preFundingRequirements.hidden ?
                        <></> :
                        <NotificationDetailsBlock data={details.preFundingRequirements} fullWidth={true} />
                }
                <NotificationDetailsBlock data={details.approvalNotes} fullWidth={true} />
            </div>

            <div className={styles["InstructionsTitle"]} style={{ color: ap_userPrimaryColor }}>Next Steps</div>
            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>If you would like to proceed with this approval, please send us the requested invoice with the above invoicing instructions and advise us on the plating province and yard address.</div>

            <div className={styles["InstructionsTitle"]} style={{ color: ap_userPrimaryColor }}>Invoice Instructions:</div>
            <div className={styles["InvoicingInstruction"]}>
                <div style={{ textAlign: 'start', margin: 'auto' }}>
                    <b>Sold to:</b>
                    <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.lenderName}</div>
                    <div>{notificationData.ap_data.lenderAddress}</div>
                    <div>{lenderCityProvince}</div>
                    <div>{notificationData.ap_data.lenderPostalZipCode}</div>
                    <div>{notificationData.ap_data.lenderCountry}</div>
                </div>
                <div style={{ textAlign: 'start', margin: 'auto' }}>
                    <b>Ship to:</b>
                    <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientBusinessName}</div>
                    <div>{notificationData.ap_data.clientAddress}</div>
                    <div>{clientCity}</div>
                    <div>{notificationData.ap_data.clientPostalCode}</div>
                    <div>{notificationData.ap_data.clientCountry}</div>
                </div>
            </div>
            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/approvals/${notificationData.ap_entityId}`)}
                    style={{ height: "40px" }}
                >View Approval</Button>
            </div>
        </div>
    );
}