import { Button, Layout, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Error404Icon from '../../assets/images/icons/Error404Icon';
import Error404Mobile from "../../assets/images/icons/Error404Mobile";
import constants from "../../constants";

import styles from './index.module.css';
import ApiService from "../../api";
import { useEffect, useState } from "react";
import utils from "../../utils";

export default function Error404() {
    const navigate = useNavigate();

    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });

    const userSecondaryHoverColor = utils.DarkenHexColor(constants.DEFAULT_THEME.token?.ap_userSecondaryColor);

    const [themeColorVariables, setThemeColorVariables] = useState({
        '--userPrimaryColor': constants.DEFAULT_THEME.token.ap_userPrimaryColor,
        '--userHighlightColor': constants.DEFAULT_THEME.token.ap_userHighlightColor,
        '--userSecondaryColor': constants.DEFAULT_THEME.token.ap_userSecondaryColor,
        '--userSecondaryHoverColor': userSecondaryHoverColor,
        '--themePrimaryColor': constants.THEMES.light.themePrimaryColor,
        '--themeSecondaryColor': constants.THEMES.light.themeSecondaryColor,
        '--themeFontColor': constants.THEMES.light.themeFontColor,
        '--themeDangerColor': constants.THEMES.light.themeDangerColor,
    });

    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;

    useEffect(() => {
        if (agentId) {
            ApiService.fetchAgentProfileData(agentId).then(agentData => {
                const settingsData = agentData?.ap_settingsId;
                if (settingsData?._id) {
                    const userSecondaryHoverColor = utils.DarkenHexColor(constants.THEMES[settingsData?.ap_theme]?.ap_userSecondaryColor);
                    setThemeColorVariables(prevColors => {
                        return {
                            '--userPrimaryColor': settingsData?.ap_userPrimaryColor,
                            '--userHighlightColor': settingsData?.ap_userHighlightColor,
                            '--userSecondaryColor': constants.THEMES[settingsData?.ap_theme]?.ap_userSecondaryColor,
                            '--userSecondaryHoverColor': userSecondaryHoverColor,
                            // '--userSecondaryHoverColor': constants.THEMES[settingsData?.ap_theme]?.userSecondaryHoverColor,
                            '--themePrimaryColor': constants.THEMES[settingsData?.ap_theme]?.themePrimaryColor,
                            '--themeSecondaryColor': constants.THEMES[settingsData?.ap_theme]?.themeSecondaryColor,
                            '--themeFontColor': constants.THEMES[settingsData?.ap_theme]?.themeFontColor,
                        }
                    });
                }
            });
        }
    }, [])

    return (
        <Layout style={themeColorVariables}>
            <div className={styles["container"]}>
                {isMobile ?
                    <div style={{ width: '60%', margin: '20% 20%' }}>
                        <div className={styles["errorCode-mobile"]}>Error 404</div>
                        <div className={styles["notFound-mobile"]}>Page not found</div>
                        <div><Error404Mobile userColor1={themeColorVariables["--userPrimaryColor"]} /></div>
                        <div className={styles["errorMessage-mobile"]}>Sorry, the page you visited does not exists.</div>
                        <div className={styles["buttonContainer"]}><Button className={styles["homeButton"]} onClick={() => navigate('/home')}>Home</Button></div>
                    </div> :
                    <div className={styles["layout"]}>
                        <div className={styles["leftLayout"]}>
                            <div>
                                <div className={styles["errorCode"]}>Error 404</div>
                                <div className={styles["notFound"]}>Page not found</div>
                                <div className={styles["errorMessage"]}>Sorry, the page you visited does not exists.</div>
                                <div className={styles["buttonContainer"]}><Button className={styles["homeButton"]} onClick={() => navigate('/home')}>Home</Button></div>
                            </div>
                        </div>
                        <div className={styles["rightLayout"]}>
                            <div>
                                <Error404Icon userColor1={themeColorVariables["--userPrimaryColor"]} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Layout>
    );
}