// Imports
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Progress, Row, Table, Tag, theme } from "antd";

//Custom Imports
import ApiService from "../../api";
import EmptyDrawdownIcon from "../../assets/images/icons/Home/EmptyDrawdownIcon";
import EmptyDataComponent from "../../components/EmptyDataComponent";
import "../../assets/styles/fonts.css";

//Custom Styling
import styles from "./index.module.css";
import { useMediaQuery } from "react-responsive";
import constants from "../../constants";
import utils from "../../utils";

const HomeDrawdownRecent = ({ entity, entityId }) => {

    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });

    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [ongoingData, setOngoingData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // Fetch Ongoing Deals
    useEffect(() => {
        setLoading(true);

        if (entity === 'agent') {
            ApiService.fetchAgentOngoingDrawdowns(entityId).then((data) => {
                if (!data) return;
                setOngoingData(() => {
                    data.map((item) => {
                        item.ap_clientName = item?.ap_clientId?.ap_businessName;
                        item.ap_lender = item?.ap_lenderId?.ap_abbreviation || item?.ap_lenderId?.ap_name;
                        return item;
                    });
                    return data;
                });
            });
        } else if (entity === 'client') {
            ApiService.fetchClientOngoingDrawdowns(entityId).then((data) => {
                if (!data) return;
                setOngoingData(() => {
                    data.map((item) => {
                        item.ap_clientName = item?.ap_clientId?.ap_businessName;
                        item.ap_lender = item?.ap_lenderId?.ap_abbreviation || item?.ap_lenderId?.ap_name;
                        return item;
                    });
                    return data;
                });
            });
        }

        setLoading(false);
    }, [entity, entityId]);

    const DrawdownTable = () => {
        return (
            <Row key="HomeDrawdownRecentTable">
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: themePrimaryColor,
                            colorFillAlter: themePrimaryColor,
                            colorFillSecondary: themePrimaryColor,
                            colorText: themeFontColor,
                            colorIcon: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                            borderRadius: '50',
                        },
                    }}
                >
                    <Table
                        columns={generateOngoingTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity)}
                        dataSource={ongoingData}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: true, y: 350 }}
                        className={`${styles.tab} ${styles.table}`}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate(`/drawdowns/${record._id}`);
                            },
                            className: 'clickable-row'
                        })}
                    />
                </ConfigProvider>
            </Row>)
    }

    const MobileDrawdownCards = () => {
        return (
            <div className={styles["Mobile_Content"]}>
                {ongoingData?.map((item, index) => (
                    <div key={`mobile_${index}`} onClick={() => navigate(`/drawdowns/${item?._id}`)}>
                        <div>
                            <div>{item?.ap_clientName}</div>
                            <div className={styles["Mobile_Content_Asset"]}>{item?.ap_assetDescription}</div>
                            <div>{item?.ap_lender}</div>
                        </div>
                        <div>
                            <div>{(item?.ap_agentFee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                            <Progress
                                percent={utils.ProcessStageProgress(item.ap_processStage)}
                                strokeColor={(item.ap_responsibleType === 'Agent' || item.ap_responsibleType === 'Client') ? "#FF5555" : "#8ECEAE"}
                                format={(percent) => <span style={{ color: (item.ap_responsibleType === 'Agent' || item.ap_responsibleType === 'Client') ? "#FF5555" : "#8ECEAE" }}>{percent}%</span>}
                            />
                            <div style={{ textAlign: 'right' }}><Tag bordered={false} key={`status_${item._id}`} className={styles[(item.ap_responsibleType === 'Agent' || item.ap_responsibleType === 'Client') ? "RedTags" : "GreenTags"]} style={{ whiteSpace: 'break-spaces', width: '100%', textAlign: 'center' }}>{item?.ap_processStage}</Tag></div>
                        </div>
                    </div>
                )
                )}
            </div>
        );
    }

    const RenderFunction = () => {
        if (ongoingData?.length !== undefined && ongoingData?.length === 0) {
            return <EmptyDataComponent flex={true} className="centerDrawdown" iconComponent={<EmptyDrawdownIcon />} ContentLine1={"There is nothing to show here."} ContentLine2={"Start a deal to see content"} />;
        } else {
            return isMobile ? <MobileDrawdownCards /> : <DrawdownTable />;
        }
    }

    return (
        <>
            <>
                <Row className={styles["Label-header"]}>
                    <span className={`${styles["Label"]} Font_SemiBold`}>Recent Ongoing Drawdowns</span>
                </Row>
                <Row className={styles["Table"]} style={{ height: '90%', display: 'flex', justifyContent: 'center' }}>
                    <RenderFunction />
                </Row>
            </>
        </>
    );
}

export default HomeDrawdownRecent;


function generateOngoingTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity) {

    const column = [];

    if (entity !== 'client') {
        column.push(
            {
                title: 'Client',
                dataIndex: 'ap_clientName',
                key: 'ap_clientName',
                width: 30,
                sorter: (a, b) => {
                    const clientA = (a?.ap_clientName || '').toLowerCase();
                    const clientB = (b?.ap_clientName || '').toLowerCase();
                    return clientA.localeCompare(clientB);
                },
                sortDirections: ['ascend', 'descend'],
                ellipsis: true,
            },
        );
    }

    column.push(
        {
            title: 'Asset Desc',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Lender',
            dataIndex: 'ap_lenderId',
            key: 'ap_lenderId',
            render: (record) => record?.ap_abbreviation || record?.ap_name,
            width: 100,
            sorter: (a, b) => (a?.ap_lender || "").localeCompare(b?.ap_lender || ""),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Agent Fee',
            key: 'ap_agentFee',
            width: 100,
            render: (record) => (record?.ap_agentFee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        },
        {
            title: 'Status',
            key: 'ap_processStageName',
            style: { fontFamily: 'Gilroy-Regular' },
            width: 100,
            render: (record) => {
                return (
                    <Tag bordered={false} key={`status_${record._id}`} className={styles[(record.ap_responsibleType === 'Agent' || record.ap_responsibleType === 'Client') ? "RedTags" : "GreenTags"]}>
                        {record.ap_processStage}
                    </Tag>
                )
            }
        }
    );

    return column;
}