import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from "./index.module.css";

function List(selected) {
    return (
        <svg className={styles["ListIcon"]} width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="38" height="40" rx="9" className={styles[selected ? 'backgroundFill_selected' : 'backgroundFill']} />
            <rect x="1" y="1" width="38" height="40" rx="9" strokeWidth="2" className={styles[selected ? 'selectedBorder' : 'unselectedBorder']} />
            <path d="M9.08936 12.5C9.08936 11.9477 9.53707 11.5 10.0894 11.5H12.0894C12.6416 11.5 13.0894 11.9477 13.0894 12.5V14.5C13.0894 15.0523 12.6416 15.5 12.0894 15.5H10.0894C9.53707 15.5 9.08936 15.0523 9.08936 14.5V12.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0894 13.5C15.0894 12.8096 15.649 12.25 16.3394 12.25H30.6609C31.3512 12.25 31.9109 12.8096 31.9109 13.5C31.9109 14.1904 31.3512 14.75 30.6609 14.75H16.3394C15.649 14.75 15.0894 14.1904 15.0894 13.5Z" className={styles['Lines']} />
            <path d="M9.08936 20C9.08936 19.4477 9.53707 19 10.0894 19H12.0894C12.6416 19 13.0894 19.4477 13.0894 20V22C13.0894 22.5523 12.6416 23 12.0894 23H10.0894C9.53707 23 9.08936 22.5523 9.08936 22V20Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0894 21C15.0894 20.3096 15.649 19.75 16.3394 19.75H30.6609C31.3512 19.75 31.9109 20.3096 31.9109 21C31.9109 21.6904 31.3512 22.25 30.6609 22.25H16.3394C15.649 22.25 15.0894 21.6904 15.0894 21Z" className={styles['Lines']} />
            <path d="M9.08936 27.5C9.08936 26.9477 9.53707 26.5 10.0894 26.5H12.0894C12.6416 26.5 13.0894 26.9477 13.0894 27.5V29.5C13.0894 30.0523 12.6416 30.5 12.0894 30.5H10.0894C9.53707 30.5 9.08936 30.0523 9.08936 29.5V27.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0894 28.5C15.0894 27.8096 15.649 27.25 16.3394 27.25H30.6609C31.3512 27.25 31.9109 27.8096 31.9109 28.5C31.9109 29.1904 31.3512 29.75 30.6609 29.75H16.3394C15.649 29.75 15.0894 29.1904 15.0894 28.5Z" className={styles['Lines']} />
        </svg>
    );
}

export default function ListIcon({ selected }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();

    const themeColorVariables = {
        '--userHighlightColor': ap_userHighlightColor,
        '--userPrimaryColor': ap_userPrimaryColor,
    };

    return <Icon component={() => List(selected)} style={themeColorVariables} />
}