import HomeContent from "../../components/HomeContent";
import Profile from "../../layouts/Profile";

export default function ProfilePage({ agentId }) {
    return (
        <HomeContent
            contentHeader={<div style={{ fontSize: "24px" }}>Profile</div>}
            content={<Profile agentId={agentId} />}
            bgColor="transparent"
        />
    );
}