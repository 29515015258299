import { useMediaQuery } from "react-responsive";

import InputLayout from "../InputLayout";
import constants from "../../constants";

export default function RegistrationCommunication({ data }) {

    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });

    const receiveSMSNotification = { receiveSMSNotification: { ...data.receiveSMSNotification } }
    const receiveEmailNotification = { receiveEmailNotification: { ...data.receiveEmailNotification } }
    const receiveWhatsAppNotification = { receiveWhatsAppNotification: { ...data.receiveWhatsAppNotification } }

    return (
        <>
            <div style={{ marginBottom: '2%', minWidth: 'fit-content', fontSize: '18px' }}>
                Please set your communication preferences for your primary contact.
            </div>
            <div style={{ marginBottom: '2%', minWidth: 'fit-content', fontSize: '14px', paddingBottom: '5%' }}>
                You can add additional contacts on your profile after registration.
            </div>
            <div style={isMobile ? { marginLeft: '15%' } : { marginLeft: '25%' }}>
                <div style={{ margin: 'auto' }}>
                    <InputLayout data={receiveSMSNotification} switchLayout="horizontal" layout="horizontal" />
                    <InputLayout data={receiveWhatsAppNotification} switchLayout="horizontal" layout="horizontal" />
                    <InputLayout data={receiveEmailNotification} switchLayout="horizontal" layout="horizontal" />
                </div>
            </div>
        </>
    )
}