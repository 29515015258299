import React from 'react';
import { Button, Result } from 'antd';


const ResultScreen = ({ }) => {
    return (
        <Result
            status="success"
            title="Funding Initiated"
            subTitle="Your funding request has been initiated successfully. Open the Drawdowns tab to check up on your new Drawdown."
            extra={[
                <div>
                    Note: Please allow up to 5 minutes for the new Drawdown to appear.
                </div>
            ]}
        />
    );
};

export default ResultScreen;