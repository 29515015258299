import { useEffect, useState } from "react";
import { ConfigProvider, Pagination, theme } from "antd";

import PreviousIcon from "../../assets/images/icons/PreviousIcon";
import NextIcon from "../../assets/images/icons/NextIcon";

import PaginationUtils from "./utils";

export default function CustomPagination({
    data = [],
    setPagedData,
    showPagination = true,
    autoPagination = false,
    searchedColumn = null,
    searchText = null,
    filters = {},
    pageSize = 10,
    setCurrentPageNum
}) {

    // Fetch and Set User Them
    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();
    const UserTheme = {
        token: {
            colorBgContainer: themePrimaryColor,
            colorFillAlter: themePrimaryColor,
            colorFillSecondary: themePrimaryColor,
            colorText: themeFontColor,
            colorIcon: themeFontColor,
            colorTextPlaceholder: themeFontColor,
            borderRadius: '50%',
        }
    }

    // State Variables
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [processedData, setProcessedData] = useState([]);
    const [paginationVisibility, setPaginationVisibility] = useState(showPagination);

    useEffect(() => {

        // Process Data
        // Apply Search
        const searchedData = PaginationUtils.processSearch(data, searchedColumn, searchText);

        // Apply Filters
        const filteredData = PaginationUtils.processFilters(searchedData, filters);

        setProcessedData(filteredData);

        if (searchText)
            setCurrentPage(1);

    }, [data, searchedColumn, searchText, filters]);

    // Pagination Logic
    const totalItems = processedData?.length;
    const maxPage = Math.ceil(totalItems / pageSize);
    useEffect(() => {
        if (autoPagination && showPagination)
            setPaginationVisibility(totalItems > pageSize);
        else
            setPaginationVisibility(showPagination);
    }, [autoPagination, showPagination, totalItems, pageSize]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        setPagedData(paginationVisibility ? processedData.slice(startIndex, endIndex) : processedData);

    }, [processedData, currentPage, paginationVisibility, pageSize]);

    // Function to handle page changes
    const handlePageChange = (page) => setCurrentPage(page);

    // Custom Prevous and Next Buttons
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev')
            return <PreviousIcon enabled={currentPage > 1} />

        if (type === 'next')
            return <NextIcon enabled={currentPage < maxPage} />

        return originalElement;
    };

    useEffect(() => {
        if (setCurrentPageNum)
            setCurrentPageNum(currentPage);
    }, [currentPage]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <ConfigProvider theme={UserTheme}>
                <div style={{ flexGrow: 5 }}>
                    {paginationVisibility && (
                        <Pagination
                            total={totalItems}
                            pageSize={pageSize}
                            current={currentPage}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            itemRender={itemRender}
                        />
                    )}
                </div>
            </ConfigProvider>
        </div>
    );
}