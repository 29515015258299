import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Void = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M45.2059 48.3236C45.2059 47.1378 46.1672 46.1765 47.353 46.1765H75.2648C76.4505 46.1765 77.4118 47.1378 77.4118 48.3236C77.4118 49.5094 76.4505 50.4706 75.2648 50.4706H47.353C46.1672 50.4706 45.2059 49.5094 45.2059 48.3236Z" fill="white" />
            <path d="M53.7941 59.0587C53.7941 57.8729 54.7553 56.9116 55.9411 56.9116H75.2647C76.4504 56.9116 77.4117 57.8729 77.4117 59.0587C77.4117 60.2445 76.4504 61.2057 75.2647 61.2057H55.9411C54.7553 61.2057 53.7941 60.2445 53.7941 59.0587Z" fill="white" />
            <path d="M15.1471 31.147H83.853V39.7352H15.1471V31.147Z" fill="white" />
            <path d="M83.8529 29H38.6144C39.3625 30.3523 39.9326 31.7956 40.3106 33.2941H81.7059V67.6471H17.2941V54.55C15.723 53.8181 14.2745 52.8476 13 51.6729V69.7941C13 70.3636 13.2262 70.9097 13.6289 71.3123C14.0315 71.715 14.5776 71.9412 15.1471 71.9412H83.8529C84.4224 71.9412 84.9685 71.715 85.3711 71.3123C85.7738 70.9097 86 70.3636 86 69.7941V31.1471C86 30.5776 85.7738 30.0315 85.3711 29.6289C84.9685 29.2262 84.4224 29 83.8529 29Z" fill="white" />
            <path d="M15.1471 29H60.3856C59.6375 30.3523 59.0674 31.7956 58.6894 33.2941H17.2941V67.6471H81.7059V54.55C83.277 53.8181 84.7255 52.8476 86 51.6729V69.7941C86 70.3636 85.7738 70.9097 85.3711 71.3123C84.9685 71.715 84.4224 71.9412 83.8529 71.9412H15.1471C14.5776 71.9412 14.0315 71.715 13.6289 71.3123C13.2262 70.9097 13 70.3636 13 69.7941V31.1471C13 30.5776 13.2262 30.0315 13.6289 29.6289C14.0315 29.2262 14.5776 29 15.1471 29Z" fill="white" />
            <path d="M25.5817 61.3026C25.3097 61.3026 25.0878 61.2454 24.9161 61.1309C24.7515 61.0163 24.6119 60.8338 24.4974 60.5834L21.8995 54.8507C21.7849 54.6002 21.7492 54.3748 21.7921 54.1744C21.835 53.974 21.9352 53.8201 22.0927 53.7128C22.2501 53.5983 22.447 53.541 22.6831 53.541C22.9766 53.541 23.1948 53.6054 23.338 53.7342C23.4883 53.8631 23.6171 54.0527 23.7245 54.3032L25.9145 59.3703H25.3133L27.5033 54.2925C27.6106 54.042 27.7395 53.8559 27.8897 53.7342C28.04 53.6054 28.2512 53.541 28.5231 53.541C28.745 53.541 28.9311 53.5983 29.0814 53.7128C29.2317 53.8201 29.3247 53.974 29.3605 54.1744C29.4034 54.3748 29.3676 54.6002 29.2531 54.8507L26.6445 60.5834C26.5371 60.8338 26.4011 61.0163 26.2365 61.1309C26.0719 61.2454 25.8536 61.3026 25.5817 61.3026Z" fill="white" />
            <path d="M33.6936 61.3241C32.9278 61.3241 32.2551 61.1631 31.6754 60.841C31.0957 60.5118 30.6448 60.0537 30.3227 59.4669C30.0078 58.88 29.8504 58.1965 29.8504 57.4164C29.8504 56.8296 29.9398 56.2964 30.1187 55.8169C30.3048 55.3374 30.5661 54.9259 30.9024 54.5823C31.246 54.2388 31.6539 53.9776 32.1262 53.7986C32.5986 53.6126 33.1211 53.5195 33.6936 53.5195C34.4594 53.5195 35.1286 53.6806 35.7011 54.0026C36.2808 54.3247 36.7317 54.7791 37.0537 55.366C37.3758 55.9457 37.5368 56.6292 37.5368 57.4164C37.5368 58.0033 37.4438 58.5365 37.2577 59.016C37.0788 59.4955 36.8176 59.9106 36.474 60.2613C36.1377 60.6048 35.7333 60.8696 35.261 61.0557C34.7886 61.2346 34.2662 61.3241 33.6936 61.3241ZM33.6936 59.6709C34.0729 59.6709 34.395 59.5814 34.6598 59.4025C34.9246 59.2236 35.1286 58.9659 35.2717 58.6295C35.4148 58.2932 35.4864 57.8888 35.4864 57.4164C35.4864 56.7079 35.3289 56.1568 35.014 55.7632C34.6991 55.3696 34.259 55.1728 33.6936 55.1728C33.3214 55.1728 32.9994 55.2622 32.7274 55.4411C32.4626 55.6129 32.2587 55.867 32.1155 56.2034C31.9724 56.5326 31.9008 56.9369 31.9008 57.4164C31.9008 58.125 32.0583 58.6796 32.3732 59.0804C32.6881 59.474 33.1282 59.6709 33.6936 59.6709Z" fill="white" />
            <path d="M39.6908 61.3026C39.3759 61.3026 39.1325 61.2167 38.9608 61.045C38.789 60.8661 38.7031 60.6156 38.7031 60.2935V54.5501C38.7031 54.2209 38.789 53.9704 38.9608 53.7986C39.1325 53.6269 39.3759 53.541 39.6908 53.541C40.0128 53.541 40.2562 53.6269 40.4208 53.7986C40.5925 53.9704 40.6784 54.2209 40.6784 54.5501V60.2935C40.6784 60.6156 40.5961 60.8661 40.4315 61.045C40.2669 61.2167 40.02 61.3026 39.6908 61.3026Z" fill="white" />
            <path d="M43.078 61.206C42.7416 61.206 42.484 61.1201 42.3051 60.9484C42.1333 60.7694 42.0474 60.5154 42.0474 60.1861V54.6575C42.0474 54.3283 42.1333 54.0778 42.3051 53.906C42.484 53.7271 42.7416 53.6376 43.078 53.6376H45.1821C46.499 53.6376 47.5153 53.9668 48.231 54.6253C48.9538 55.2837 49.3152 56.2141 49.3152 57.4164C49.3152 58.0176 49.2222 58.5544 49.0361 59.0267C48.85 59.4919 48.5816 59.8891 48.231 60.2184C47.8803 60.5404 47.4473 60.7873 46.932 60.9591C46.4238 61.1237 45.8406 61.206 45.1821 61.206H43.078ZM44.0227 59.6064H45.0533C45.4326 59.6064 45.7583 59.5599 46.0302 59.4669C46.3093 59.3738 46.5384 59.2379 46.7173 59.0589C46.9034 58.88 47.0393 58.6546 47.1252 58.3826C47.2183 58.1107 47.2648 57.7886 47.2648 57.4164C47.2648 56.6721 47.0823 56.1246 46.7173 55.7739C46.3523 55.4161 45.7976 55.2372 45.0533 55.2372H44.0227V59.6064Z" fill="white" />
        </svg>

    );
};

export default function VoidIcon(props) {
    return <Icon component={Void} {...props} />
}