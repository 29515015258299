import { useState } from "react";
import { Button, Drawer, Spin, theme } from "antd";

import InputLayout from "../InputLayout";
import ApiService from "../../api";
import NotificationService from "../../services/notification.service";
import styles from './index.module.css';
import "../../assets/styles/fonts.css"

export default function RequestAmendment({ approvalId, data, setRefresh, headerName }) {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState(generateRequestAmendmentFields());

    const requestAmendmentForm = () => {
        setOpen(!open);
        setFormFields(generateRequestAmendmentFields(data));
    }


    const handleAmendmentDetails = (e) => {
        const amendmentRequest = e?.target?.value;
        setFormFields({
            ...formFields,
            amendmentDetails: {
                ...formFields.amendmentDetails,
                value: amendmentRequest,
                error: amendmentRequest ? '' : 'Required'
            }
        })
    }

    const form = {
        ...formFields,
        amendmentDetails: {
            ...formFields.amendmentDetails,
            eventHandler: handleAmendmentDetails,
        }
    }

    const handleRequestAmendment = () => {
        setLoading(true);
        if (!formFields.amendmentDetails.value) {
            setFormFields({
                ...formFields,
                amendmentDetails: {
                    ...formFields.amendmentDetails,
                    error: 'Required'
                }
            });
            setLoading(false);
        } else {
            const obj = {
                ap_status: 'Amendment Requested',
                ap_amendmentRequestNote: formFields?.amendmentDetails?.value,
                ap_amendmentStatus: 'Pending'
            }
            // Update the status
            ApiService.updateApproval(approvalId, obj).then((response) => {
                if (response.success) {
                    NotificationService.success('Success', 'Amendment Requested Successfully');
                    requestAmendmentForm();
                    setRefresh((prev) => prev + 1);
                } else {
                    NotificationService.error('Error', 'Something wnet wrong, try again later');
                }
                setLoading(false);
            })
        }
    }

    return (
        <>
            {data.ap_amendmentRequestNote ?
                <Button
                    type="primary"
                    disabled={data.ap_status === 'Cancelled'}
                    onClick={requestAmendmentForm}
                > Edit Amendment Request</Button>
                :
                <Button
                    type="primary"
                    disabled={data.ap_status === 'Cancelled'}
                    onClick={requestAmendmentForm}
                >Request Amendment</Button>
            }

            {/* Quick Create Form */}
            <Drawer
                title='Request Amendment'
                open={open}
                onClose={requestAmendmentForm}
                getContainer=".AppTheme"
            >

                <Spin
                    tip="Sending..."
                    spinning={loading}
                >

                    <div className={`${styles['RequestAmendment_Header']} Font_UltraLight`}>{headerName}</div>
                    <div className={styles["RequestAmendment_Helper"]}>Please describe your request in the field below.</div>

                    {/* Generating Input Fields */}
                    <InputLayout data={form} layout='vertical' />

                    <Button
                        type="primary"
                        style={{ backgroundColor: ap_userPrimaryColor, display: 'block', fontWeight: "bold" }}
                        onClick={handleRequestAmendment}
                    >Done</Button>

                </Spin>
            </Drawer>
        </>
    );

}

function generateRequestAmendmentFields(data) {
    const note = data?.ap_amendmentRequestNote
    return ({
        amendmentDetails: {
            value: note || '',
            error: note ? '' : 'Required',
            hidden: false,
            required: true,
            inputType: 'textarea',
            label: '',
        }
    })
}