const COMPANY_TYPES =  [
    {
        label: 'Individual',
        value: 'Individual',
    },
    {
        label: 'Sole Proprietorship',
        value: 'SoleProprietorship',
    },
    {
        label: 'Corporation',
        value: 'Corporation',
    }
]

export default COMPANY_TYPES;