import { Tag } from "antd";
import utils from "../../utils";
import dayjs from "dayjs";
import styles from "./index.module.css";

function generateApprovalsTableColumns() {

    const redTagsArray = ['Declined', 'Cancelled', 'Expired'];
    const neutralTagsArray = ['Pre-Submission', 'Submitted', 'Amendment Requested', 'Credit Extension Requested'];
    const greenTagsArray = ['Approved', 'Funded', 'Partially Funded', 'Expired/Partially Funded', 'In Funding', 'Lease Transfer'];


    return [
        {
            title: 'Asset Description',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            width: 150,
        },
        {
            title: 'Lender',
            dataIndex: 'ap_lender',
            key: 'ap_lender',
            sorter: (a, b) => (a?.ap_lender || "").localeCompare(b?.ap_lender || ""),
            sortDirections: ['ascend', 'descend'],
            width: 150,
        },
        {
            title: 'Submission Type',
            dataIndex: 'ap_submissionType',
            key: 'ap_submissionType',
            sorter: (a, b) => (a?.ap_submissionType || "").localeCompare(b?.ap_submissionType || ""),
            sortDirections: ['ascend', 'descend'],
            width: 150,
        },
        {
            title: 'Approved Amount',
            key: 'ap_approvedAmount',
            render: (record) => (record?.ap_approvedAmount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            sorter: (a, b) => (a?.ap_approvedAmount || "").localeCompare(b?.ap_approvedAmount || ""),
            sortDirections: ['ascend', 'descend'],
            width: 150,
        },
        {
            title: 'Credit Available',
            key: 'ap_creditAvailable',
            render: (record) => (record.ap_creditAvailable / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            sorter: (a, b) => (a?.ap_creditAvailable || "").localeCompare(b?.ap_creditAvailable || ""),
            sortDirections: ['ascend', 'descend'],
            width: 150,
        },
        {
            title: 'Expiry Date',
            key: 'ap_expiryDate',
            sorter: (a, b) => {
                const dateA = (a?.ap_expiryDate) ? dayjs(a?.ap_expiryDate) : "";
                const dateB = (b?.ap_expiryDate) ? dayjs(b?.ap_expiryDate) : "";
                return dateA - dateB;
            },
            sortDirections: ['ascend', 'descend'],
            render: (record) => utils.FormatDate(record.ap_expiryDate),
            width: 150,
        },
        {
            title: 'Status',
            key: 'ap_status',
            render: (record) => {
                const statusClass = greenTagsArray.includes(record.ap_status) ? 'GreenTags' : redTagsArray.includes(record.ap_status) ? 'RedTags' : 'NeutralTags';
                return (
                    <Tag
                        bordered={false}
                        className={styles[statusClass]}
                    >{record.ap_status}</Tag>
                )
            },
            width: 150,
        },
    ];

}

const constantsObj = {
    generateApprovalsTableColumns: generateApprovalsTableColumns,
}

export default constantsObj;