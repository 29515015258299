import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Alert, Button, Spin, Tabs } from "antd";
import { PrinterOutlined, CheckOutlined } from '@ant-design/icons';

import ApiService from "../../api";
import PaymentScheduleSummary from "../PaymentScheduleSummary";
import ModifyPaymentSchedule from "../ModifyPaymentSchedule";
import { useSanitizedDictState } from "../../utils/santitizeHtml";

import styles from './index.module.css';
import NotificationService from "../../services/notification.service";

import DocumentRequest from "../DocumentRequest";

export default function PaymentSchedules({ setHeaderName, approvalId, ppsId, isDrawdown = false, hideHoldAlert = true }) {

    // const { id, paymentScheduleId } = useParams();
    const navigate = useNavigate();

    const [pps, setPPS] = useSanitizedDictState({
        client: <p>Loading Client Schedule...</p>,
        broker: <p>Loading Broker Schedule...</p>,
    });

    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState({ loading: false, tip: '' });
    const [refresh, setRefresh] = useState(0);
    const [isDraft, setIsDraft] = useState(false);
    const [isDrawdownCreated, setIsDrawdownCreated] = useState(false);
    const [approvedPPSId, setApprovedPPSId] = useState('');
    const [currentPPSData, setCurrentPPSData] = useState({});
    const [approvalData, setApprovalData] = useState({});
    const [drawdownId, setDrawdownId] = useState(null);
    const [isDrawdownOnHold, setIsDrawdownOnHold] = useState(false);

    useEffect(() => {
        if (!approvalId) return;
        ApiService.fetchIndividualApprovals(approvalId).then((data) => {
            if (!data) return;
            setApprovalData(data);
            setHeaderName(<div className={styles["HeaderName"]}>
                <div>Payment Schedule | </div>
                <div className={styles["HeaderName_Custom"]}>{data?.ap_lenderId?.ap_name + " - " + data?.ap_clientId?.ap_businessName + " - " + data?.ap_assetDescription}</div>
            </div>);
        });
        if (!ppsId) return;
        ApiService.fetchIndividualPaymentSchedule(ppsId).then((data) => {
            if (!data) return;
            setCurrentPPSData(data);
            setIsDraft(data.ap_isDraft);
            setIsDrawdownCreated(data.ap_drawdownId ? true : false);
            if(data.ap_drawdownId) {
                setDrawdownId(data.ap_drawdownId || "");
            }
            if (data.ap_isDraft) {
                setApprovedPPSId(data.ap_draftReferredFrom);
            }
        });
    }, [approvalId, ppsId, refresh]);

    useEffect(() => {
        if (!drawdownId) return;  
        ApiService.fetchIndividualDrawdown(drawdownId)
            .then(drawdownData => {
                if(drawdownData?.ap_isOnHold){
                    setIsDrawdownOnHold(true)
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [drawdownId]);

    // handle initiateFunding
    const handleInitiateFunding = () => {
        setOpen(!open);
    }

    // handle refresh when modal is closed
    useEffect(() => {
        if (!open)
            setRefresh(prevRefresh => prevRefresh + 1);
    }, [open]);

    // handle modification cancel
    const handleCancel = () => {
        if (!ppsId) return;
        ApiService.cancelRequestedPaymentSchedule(ppsId).then((response) => {
            if (!response.error)
                NotificationService.success('Success', "Modification Cancelled Successfully");
            //TODO: use navitage to change history data; provide approved PPS ID in history

            else
                NotificationService.error('Error', "There was an error in cancel the modification, please try again later")
        });
        navigate(`/approvals/${approvalId}/paymentschedules/${approvedPPSId}`, { replace: true });
    }

    // handle View Approved PPS Notification
    const handleViewPPS = () => {
        navigate(`/approvals/${approvalId}/paymentschedules/${approvedPPSId}`);
    }

    const tabItems = [
        {
            key: 'summary',
            label: 'Summary',
            children: <PaymentScheduleSummary hideHoldAlert={false} setPPS={setPPS} refresh={refresh} handleCancel={handleCancel} handleViewPPS={handleViewPPS} paymentScheduleId={ppsId} />
        },
        {
            key: 'clientSchedule',
            label: 'Client Schedule',
            children: <>
                {isDraft && !isDrawdown ?
                    <div className={styles["ClientBrokerSchedule_Align"]}>
                        <Alert
                            className={styles["ClientBrokerSchedule_Alert"]}
                            message={<div className={styles["ClientBrokerSchedule_Alert_Message"]}>
                                <span>Payment Schedule requested, not confirmed.</span>
                                <div className={styles["Banner_Buttons"]}>
                                    <Button
                                        onClick={handleViewPPS}
                                        className={styles["ClientBrokerSchedule_View_PPS_Button"]}
                                        type="text"
                                    >View Approved PPS</Button>
                                    <Button
                                        onClick={handleCancel}
                                        className={styles["ClientBrokerSchedule_Alert_Button"]}
                                        type="text"
                                        danger>Cancel</Button>
                                </div>
                            </div>}
                            banner
                        />
                    </div> : <></>
                }
                <div className={styles["ClientBrokerSchedule_Print"]}>
                    <Button type="primary" icon={<PrinterOutlined />} onClick={() => printSchedule('clientSchedule')}>Print</Button>
                </div>
                <div className={styles["ClientBrokerSchedule_HTML"]}>
                    <div id="clientSchedule" dangerouslySetInnerHTML={{ __html: pps.client }} />
                </div>
            </>
        },
        {
            key: 'brokerSchedule',
            label: 'Broker Schedule',
            children: <>
                {isDraft && !isDrawdown ?
                    <div className={styles["ClientBrokerSchedule_Align"]}>
                        <Alert
                            className={styles["ClientBrokerSchedule_Alert"]}
                            message={<div className={styles["ClientBrokerSchedule_Alert_Message"]}>
                                <span>Payment Schedule requested, not confirmed.</span>
                                <div className={styles["Banner_Buttons"]}>
                                    <Button
                                        onClick={handleViewPPS}
                                        className={styles["ClientBrokerSchedule_View_PPS_Button"]}
                                        type="text"
                                    >View Approved PPS</Button>
                                    <Button
                                        onClick={handleCancel}
                                        className={styles["ClientBrokerSchedule_Alert_Button"]}
                                        type="text"
                                        danger>Cancel</Button>
                                </div>
                            </div>}
                            banner
                        />
                    </div> : <></>
                }
                <div className={styles["ClientBrokerSchedule_Print"]}>
                    <Button type="primary" icon={<PrinterOutlined />} onClick={() => printSchedule('brokerSchedule')}>Print</Button>
                </div>
                <div className={styles["ClientBrokerSchedule_HTML"]}>
                    <div id="brokerSchedule" dangerouslySetInnerHTML={{ __html: pps.broker }} />
                </div>
            </>
        },
    ];

    return (
        <Spin
            spinning={loader.loading}
            tip={loader.tip}
            size="large"
            className={styles["PaymentSchedule_Loader"]}
        >
            
            <div className={(hideHoldAlert || !isDrawdownOnHold) ?  styles["Hide"] : styles["WarningAlert"]}>
                <Alert style={{textAlign: "left"}} 
                    type="warning" 
                    message="The Drawdown related to this Payment Schedule is on hold."  
                    showIcon />
            </div>

            <Tabs
                defaultActiveKey={tabItems[0].key}
                items={tabItems}
            />
            <div style={isDraft || isDrawdown ? { display: 'none' } : {}}>
                {isDrawdownCreated ?
                    <div className={styles["PaymentSchedule_FundingInitiated"]}>
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            className={styles["PaymentSchedule_FundingInitiatedButton"]}
                        >Document Requested</Button>
                    </div> :
                    <div className={styles["PaymentSchedule_Buttons"]}>
                        <ModifyPaymentSchedule setLoader={setLoader} approvalData={approvalData} id={approvalId} paymentScheduleId={ppsId} isDrawdown={isDrawdown} />
                        <Button
                            type="primary"
                            onClick={handleInitiateFunding}
                            className={styles["PaymentSchedule_InitiateFunding"]}
                            disabled={currentPPSData.ap_confirmed || approvalData.ap_status === 'Cancelled'}
                        >Request Documents</Button>
                        <DocumentRequest open={open} setOpen={setOpen} id={approvalId} isYardAddressRequired={approvalData?.ap_isYardAddressRequired} paymentScheduleId={ppsId}  />
                    </div>
                }
            </div>
        </Spin>
    );
}

function printSchedule(type) {

    let schedule = document.getElementById(type).innerHTML;
    const title = (type === 'clientSchedule') ? "Client Payment Schedule" : "Broker Payment Schedule"
    var mywindow = window.open('', 'PRINT', 'height=800,width=800');
    mywindow.document.write(`<html><head><title>${title}</title>`);
    mywindow.document.write('</head><body >');
    mywindow.document.write(schedule);
    mywindow.document.write('</body></html>');
    // mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    // mywindow.close();

    return true;
}
