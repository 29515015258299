export default [
    {
        label: 'Agriculture',
        value: 'Agriculture'
    },
    {
        label: 'Aviation',
        value: 'Aviation'
    },
    {
        label: 'Brokerage',
        value: 'Brokerage'
    },
    {
        label: 'Construction',
        value: 'Construction'
    },
    {
        label: 'Construction Transportation',
        value: 'Construction Transportation'
    },
    {
        label: 'Forestry',
        value: 'Forestry'
    },
    {
        label: 'Manufacturing',
        value: 'Manufacturing'
    },
    {
        label: 'Medical',
        value: 'Medical'
    },
    {
        label: 'Mining',
        value: 'Mining'
    },
    {
        label: 'Transportation',
        value: 'Transportation'
    },
    {
        label: 'Hospitality',
        value: 'Hospitality'
    },
    {
        label: 'Retail',
        value: 'Retail'
    },
    {
        label: 'Other',
        value: 'Other'
    },
];