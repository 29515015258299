export default [
    {
        label:"WhatsApp",
        value: 'whatsapp',
    },
    {
        label:'SMS',
        value: 'sms',
    },
    {
        label:'E-mail',
        value:'email',
    },
];
