import { theme } from "antd";
import fallbackTheme from '../../../../../constants/DEFAULT_THEME';

export default function ApprovalUpdated() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor || fallbackTheme.token.ap_userSecondaryColor} />
            <path d="M44.1886 25.4168C44.1886 28.0209 43.6821 30.4605 42.6693 32.7355C41.6564 35.0105 40.2898 36.9897 38.5695 38.673C36.8464 40.3563 34.8221 41.6924 32.4966 42.6813C30.1711 43.6702 27.6759 44.1654 25.0109 44.1667C22.3474 44.1667 19.8522 43.6716 17.5253 42.6813C15.1984 41.6911 13.1741 40.3549 11.4524 38.673C9.73063 36.9883 8.36405 35.0091 7.35261 32.7355C6.34116 30.4619 5.83473 28.0223 5.83331 25.4168C5.83331 22.5695 6.45481 19.8695 7.6978 17.3168C8.9408 14.764 10.6987 12.6029 12.9717 10.8334L10.095 10.8334C9.49127 10.8334 8.98484 10.6334 8.57571 10.2334C8.16659 9.83343 7.96274 9.33898 7.96416 8.7501C7.96416 8.15982 8.16872 7.66468 8.57784 7.26468C8.98697 6.86468 9.49269 6.66537 10.095 6.66676L18.6184 6.66676C19.2221 6.66676 19.7286 6.86676 20.1377 7.26676C20.5468 7.66676 20.7507 8.1612 20.7492 8.75009L20.7492 17.0834C20.7492 17.6737 20.5447 18.1688 20.1356 18.5688C19.7264 18.9688 19.2207 19.1681 18.6184 19.1668C18.0147 19.1668 17.5082 18.9668 17.0991 18.5668C16.69 18.1668 16.4861 17.6723 16.4876 17.0834L16.4876 13.4376C14.4988 14.8612 12.9361 16.6147 11.7997 18.698C10.6632 20.7813 10.095 23.0209 10.095 25.4168C10.095 29.4793 11.5426 32.9258 14.4377 35.7563C17.3328 38.5869 20.8572 40.0015 25.0109 40.0001C29.1661 40.0001 32.6912 38.5848 35.5863 35.7542C38.4815 32.9237 39.9283 29.4779 39.9269 25.4168C39.9269 22.2223 38.968 19.3313 37.0502 16.7438C35.1325 14.1563 32.611 12.4119 29.4857 11.5105C28.882 11.3369 28.3763 11.0154 27.9686 10.5459C27.5609 10.0765 27.4273 9.54732 27.568 8.95843C27.71 8.33343 28.0652 7.87301 28.6334 7.57717C29.2016 7.28134 29.8053 7.22093 30.4446 7.39593C34.5287 8.54176 37.8408 10.7813 40.3807 14.1147C42.9207 17.448 44.19 21.2154 44.1886 25.4168Z" fill="white" />
        </svg>
    );
}