import Icon from '@ant-design/icons';
import { theme } from "antd";

function Telephone() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.96638 2.75594H9.15347L11.1474 7.74145L7.94362 9.87731C7.75495 10.0032 7.60027 10.1737 7.4933 10.3737C7.38633 10.5737 7.33038 10.797 7.33042 11.0238C7.33455 11.1533 7.33042 11.0252 7.33042 11.0252V11.0541C7.33113 11.1157 7.33389 11.1773 7.33869 11.2387C7.34695 11.3517 7.36073 11.5047 7.38691 11.6935C7.44066 12.0655 7.544 12.5781 7.74519 13.1817C8.15031 14.3943 8.94402 15.9638 10.4901 17.5099C12.0362 19.056 13.6057 19.8497 14.8169 20.2548C15.4219 20.456 15.9331 20.558 16.3079 20.6131C16.5195 20.6426 16.7324 20.661 16.9459 20.6682L16.9638 20.6696H16.9748C16.9748 20.6696 17.1292 20.6613 16.9762 20.6696C17.2321 20.6694 17.4828 20.5981 17.7004 20.4635C17.918 20.3289 18.0938 20.1364 18.2081 19.9076L19.1314 18.0611L25.2441 19.0808V25.0336C22.3352 25.4539 14.478 25.8687 8.30464 19.6954C2.13133 13.522 2.54472 5.66347 2.96638 2.75594ZM10.187 11.6935L12.677 10.0344C13.203 9.68352 13.5924 9.16244 13.7798 8.55854C13.9672 7.95464 13.9413 7.30465 13.7063 6.71761L11.7124 1.73211C11.5078 1.22075 11.1546 0.782433 10.6985 0.473694C10.2424 0.164956 9.70425 -3.69206e-05 9.15347 6.19705e-09H2.89473C1.64215 6.19705e-09 0.463983 0.869501 0.258665 2.22818C-0.209845 5.3176 -0.84509 14.4425 6.35619 21.6438C13.5575 28.8451 22.6824 28.2085 25.7718 27.7413C27.1305 27.5346 28 26.3579 28 25.1053V19.0808C28.0001 18.4284 27.7687 17.7971 27.3471 17.2993C26.9255 16.8015 26.3409 16.4694 25.6974 16.362L19.5847 15.3437C19.0034 15.2467 18.4062 15.3386 17.8809 15.6059C17.3556 15.8733 16.9299 16.302 16.6662 16.8292L16.1894 17.7841C16.0208 17.7426 15.8539 17.6943 15.6892 17.6394C14.8349 17.3556 13.6484 16.7713 12.4386 15.5614C11.2287 14.3516 10.6444 13.1651 10.3606 12.3094C10.2928 12.1071 10.2353 11.9016 10.1883 11.6935H10.187Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function TelephoneIcon(props) {
    return <Icon component={Telephone} {...props} />
}