import { theme } from 'antd';
import Icon from '@ant-design/icons';

const License = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M79.9167 23H19.0833C15.7679 23.1217 13.1217 25.7679 13 29.0833V71.6667C13.1217 74.9821 15.7679 77.6283 19.0833 77.75H79.9167C83.2321 77.6283 85.8783 74.9821 86 71.6667V29.0833C85.944 27.4878 85.2851 25.9728 84.1562 24.8438C83.0272 23.7149 81.5122 23.056 79.9167 23ZM79.9167 71.6667H19.0833V29.0833H79.9167V71.6667Z" fill="white" />
            <path d="M55.5834 38.2083L73.8334 38.2083" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M55.5834 44.2915L73.8334 44.2915" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M55.5834 50.375L67.75 50.375" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M39.1285 38.2694C37.4828 38.2694 35.9044 38.9232 34.7407 40.0869C33.577 41.2506 32.9232 42.8289 32.9232 44.4747C32.9232 46.1204 33.577 47.6988 34.7407 48.8625C35.9044 50.0262 37.4828 50.68 39.1285 50.68C40.7743 50.68 42.3526 50.0262 43.5163 48.8625C44.68 47.6988 45.3338 46.1204 45.3338 44.4747C45.3338 42.8289 44.68 41.2506 43.5163 40.0869C42.3526 38.9232 40.7743 38.2694 39.1285 38.2694ZM29.8206 44.4747C29.8206 42.0061 30.8012 39.6386 32.5468 37.893C34.2924 36.1474 36.6599 35.1667 39.1285 35.1667C41.5971 35.1667 43.9646 36.1474 45.7102 37.893C47.4558 39.6386 48.4364 42.0061 48.4364 44.4747C48.4364 46.9433 47.4558 49.3108 45.7102 51.0564C43.9646 52.8019 41.5971 53.7826 39.1285 53.7826C36.6599 53.7826 34.2924 52.8019 32.5468 51.0564C30.8012 49.3108 29.8206 46.9433 29.8206 44.4747Z" fill="white" />
            <path d="M39.1285 38.2694C37.4828 38.2694 35.9044 38.9232 34.7407 40.0869C33.577 41.2506 32.9232 42.8289 32.9232 44.4747C32.9232 46.1204 33.577 47.6988 34.7407 48.8625C35.9044 50.0262 37.4828 50.68 39.1285 50.68C40.7743 50.68 42.3526 50.0262 43.5163 48.8625C44.68 47.6988 45.3338 46.1204 45.3338 44.4747C45.3338 42.8289 44.68 41.2506 43.5163 40.0869C42.3526 38.9232 40.7743 38.2694 39.1285 38.2694Z" fill="white" />
            <path d="M26.7179 66.1932C26.3065 66.1932 25.9119 66.0297 25.621 65.7388C25.3301 65.4479 25.1666 65.0533 25.1666 64.6418C25.1666 62.5847 25.9838 60.6117 27.4385 59.1571C28.8931 57.7024 30.8661 56.8852 32.9232 56.8852H45.3338C47.391 56.8852 49.3639 57.7024 50.8186 59.1571C52.2732 60.6117 53.0904 62.5847 53.0904 64.6418C53.0904 65.0533 52.927 65.4479 52.636 65.7388C52.3451 66.0297 51.9505 66.1932 51.5391 66.1932H26.7179Z" fill="white" />
        </svg>
    );
};

export default function LicenseIcon(props) {
    return <Icon component={License} {...props} />
}