import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from "./index.module.css";

function Grid(selected) {
    return (
        <svg className={styles['GridIcon']} width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1" width="38" height="40" rx="9" className={styles[selected ? 'backgroundFill_selected' : 'backgroundFill']} />
            <rect x="1.5" y="1" width="38" height="40" rx="9" strokeWidth="2" className={styles[selected ? 'selectedBorder' : 'unselectedBorder']} />
            <path d="M11 13.5C11 12.3954 11.8954 11.5 13 11.5H17C18.1046 11.5 19 12.3954 19 13.5V17.5C19 18.6046 18.1046 19.5 17 19.5H13C11.8954 19.5 11 18.6046 11 17.5V13.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path d="M22 13.5C22 12.3954 22.8954 11.5 24 11.5H28C29.1046 11.5 30 12.3954 30 13.5V17.5C30 18.6046 29.1046 19.5 28 19.5H24C22.8954 19.5 22 18.6046 22 17.5V13.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path d="M11 24.5C11 23.3954 11.8954 22.5 13 22.5H17C18.1046 22.5 19 23.3954 19 24.5V28.5C19 29.6046 18.1046 30.5 17 30.5H13C11.8954 30.5 11 29.6046 11 28.5V24.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
            <path d="M22 24.5C22 23.3954 22.8954 22.5 24 22.5H28C29.1046 22.5 30 23.3954 30 24.5V28.5C30 29.6046 29.1046 30.5 28 30.5H24C22.8954 30.5 22 29.6046 22 28.5V24.5Z" strokeWidth="2" className={styles[selected ? 'selectedBlocks' : 'unselectedBlocks']} />
        </svg>
    )
}

export default function GridIcon({ selected }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();

    const themeColorVariables = {
        '--userHighlightColor': ap_userHighlightColor,
        '--userPrimaryColor': ap_userPrimaryColor,
    };

    return <Icon component={() => Grid(selected)} style={themeColorVariables} />
}