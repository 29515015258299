import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EmptyPie = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="77" height="73" viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 39C0.5 43.4649 1.37944 47.8862 3.0881 52.0112C4.79676 56.1363 7.30118 59.8844 10.4584 63.0416C13.6156 66.1988 17.3637 68.7032 21.4888 70.4119C25.6138 72.1206 30.0351 73 34.5 73C38.9649 73 43.3862 72.1206 47.5112 70.4119C51.6363 68.7032 55.3844 66.1988 58.5416 63.0416C61.6988 59.8844 64.2032 56.1363 65.9119 52.0112C67.6206 47.8862 68.5 43.4649 68.5 39C68.5 34.5351 67.6206 30.1138 65.9119 25.9888C64.2032 21.8637 61.6988 18.1156 58.5416 14.9584C55.3844 11.8012 51.6363 9.29676 47.5112 7.5881C43.3862 5.87944 38.9649 5 34.5 5C30.0351 5 25.6138 5.87944 21.4888 7.5881C17.3637 9.29676 13.6156 11.8012 10.4584 14.9584C7.30118 18.1156 4.79676 21.8637 3.0881 25.9888C1.37944 30.1138 0.5 34.5351 0.5 39Z" fill="#D9D9D9" />
            <path d="M6.5 36C6.5 40.4649 7.37944 44.8862 9.0881 49.0112C10.7968 53.1363 13.3012 56.8844 16.4584 60.0416C19.6156 63.1988 23.3637 65.7032 27.4888 67.4119C31.6138 69.1206 36.0351 70 40.5 70C44.9649 70 49.3862 69.1206 53.5112 67.4119C57.6363 65.7032 61.3844 63.1988 64.5416 60.0416C67.6988 56.8844 70.2032 53.1363 71.9119 49.0112C73.6206 44.8862 74.5 40.4649 74.5 36C74.5 31.5351 73.6206 27.1138 71.9119 22.9888C70.2032 18.8637 67.6988 15.1156 64.5416 11.9584C61.3844 8.80118 57.6363 6.29676 53.5112 4.5881C49.3862 2.87944 44.9649 2 40.5 2C36.0351 2 31.6138 2.87944 27.4888 4.5881C23.3637 6.29676 19.6156 8.80118 16.4584 11.9584C13.3012 15.1156 10.7968 18.8637 9.0881 22.9888C7.37944 27.1138 6.5 31.5351 6.5 36Z" fill="white" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.5 2V36H74.5" fill="white" />
            <path d="M40.5 2V36H74.5" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default function EmptyPieIcon(props) {
    return <Icon component={EmptyPie} {...props} />
}

