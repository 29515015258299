import { ConfigProvider, Popover, Tooltip, theme } from "antd";
import styles from "./index.module.css";
import ToolTipIcon from "../../assets/images/icons/ToolTipIcon";

export default function RequiredLabelIndicator({ label, required, tooltip, popoverTitle, popoverContent }) {

    const { token: { themeSecondaryColor, themeFontColor } } = theme.useToken();

    const TooltipComponent = ({ title }) => {
        return (
            <ConfigProvider theme={{
                token: {
                    colorTextLightSolid: themeFontColor
                }
            }}>
                <Tooltip color={themeSecondaryColor} title={title}><ToolTipIcon /></Tooltip>
            </ConfigProvider>
        )
    }

    const PopOverComponent = ({ title, content }) => {
        return (
            <ConfigProvider theme={{
                token: {
                    colorBgElevated: themeSecondaryColor,
                    colorText: themeFontColor,
                    colorTextHeading: themeFontColor
                }
            }}>
                <Popover title={title} content={content}><ToolTipIcon /></Popover>
            </ConfigProvider>
        )
    }

    const popoverParsedContent = () => {
        if (popoverContent?.length === 0)
            return null;

        const content = popoverContent?.map((item, index) => {
            return (
                <>
                    <div><b>{item?.title}</b></div>
                    <div className={styles[(item?.isTotal) ? "TotalsBorder" : ""]}>{item?.content}</div>
                </>
            )
        });

        return <div className={styles["PopOverParsedContent"]}>{content}</div>
    }

    return (
        <div className={styles["PopOver"]}>
            <span className={required ? styles["ReqLabel"] : styles["Label"]}>{label}</span>
            {!!tooltip && <TooltipComponent title={tooltip} />}
            {(!!popoverTitle || popoverContent?.length > 0) && <PopOverComponent open={true} title={popoverTitle} content={popoverParsedContent()} />}
        </div>
    )
}