import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Dashboard = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3334 2H3.11119C2.31345 2 1.66675 2.6467 1.66675 3.44444V13.5556C1.66675 14.3533 2.31345 15 3.11119 15H10.3334C11.1312 15 11.7779 14.3533 11.7779 13.5556V3.44444C11.7779 2.6467 11.1312 2 10.3334 2Z" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.2223 2H19.0001C18.2024 2 17.5557 2.6467 17.5557 3.44444V7.77778C17.5557 8.57552 18.2024 9.22222 19.0001 9.22222H26.2223C27.0201 9.22222 27.6668 8.57552 27.6668 7.77778V3.44444C27.6668 2.6467 27.0201 2 26.2223 2Z" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.2223 15H19.0001C18.2024 15 17.5557 15.6467 17.5557 16.4444V26.5556C17.5557 27.3533 18.2024 28 19.0001 28H26.2223C27.0201 28 27.6668 27.3533 27.6668 26.5556V16.4444C27.6668 15.6467 27.0201 15 26.2223 15Z" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3334 20.7778H3.11119C2.31345 20.7778 1.66675 21.4245 1.66675 22.2223V26.5556C1.66675 27.3534 2.31345 28.0001 3.11119 28.0001H10.3334C11.1312 28.0001 11.7779 27.3534 11.7779 26.5556V22.2223C11.7779 21.4245 11.1312 20.7778 10.3334 20.7778Z" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default function DashboardIcon(props) {
    return <Icon component={Dashboard} {...props} />
}