import Icon from '@ant-design/icons';
import { theme } from 'antd';

const Cross = () => {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.40118 0.436126C1.66907 0.16831 2.03237 0.0178597 2.41118 0.0178597C2.78998 0.0178597 3.15328 0.16831 3.42118 0.436126L10.9826 7.99755L18.544 0.436126C18.6758 0.299683 18.8334 0.190851 19.0077 0.115981C19.182 0.0411112 19.3695 0.00170225 19.5592 5.39386e-05C19.7489 -0.00159437 19.937 0.0345506 20.1125 0.10638C20.2881 0.17821 20.4476 0.284286 20.5817 0.418419C20.7159 0.552551 20.8219 0.712054 20.8938 0.88762C20.9656 1.06319 21.0018 1.2513 21.0001 1.44098C20.9985 1.63067 20.959 1.81813 20.8842 1.99242C20.8093 2.16671 20.7005 2.32434 20.564 2.45613L13.0026 10.0176L20.564 17.579C20.8243 17.8484 20.9683 18.2093 20.965 18.5838C20.9617 18.9584 20.8115 19.3167 20.5466 19.5816C20.2818 19.8464 19.9235 19.9967 19.5489 19.9999C19.1743 20.0032 18.8135 19.8592 18.544 19.599L10.9826 12.0376L3.42118 19.599C3.15174 19.8592 2.79088 20.0032 2.41632 19.9999C2.04175 19.9967 1.68345 19.8464 1.41858 19.5816C1.15371 19.3167 1.00347 18.9584 1.00021 18.5838C0.996958 18.2093 1.14095 17.8484 1.40118 17.579L8.9626 10.0176L1.40118 2.45613C1.13336 2.18823 0.98291 1.82493 0.98291 1.44613C0.98291 1.06732 1.13336 0.704023 1.40118 0.436126Z" fill={themeDangerColor} />
        </svg>
    );
}

export default function CrossIcon({ onClick }) {
    return <Icon component={Cross} onClick={onClick} />;
}