import utils from "../../../utils";
import { Button } from "antd";

const SynergyFundedAssetTableColumns = [
  {
    title: 'Contract #',
    dataIndex: 'synergyContractNumber',
    key: 'synergyContractNumber',
    width: '10%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Vendor',
    dataIndex: 'ap_vendor',
    key: 'ap_vendor',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'VIN',
    dataIndex: 'ap_vin',
    key: 'ap_vin',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Model',
    dataIndex: 'ap_model',
    key: 'ap_model',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Make',
    dataIndex: 'ap_make',
    key: 'ap_make',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Year',
    dataIndex: 'ap_year',
    key: 'ap_year',
    width: '10%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Fee',
    dataIndex: 'ap_synergyAgentFee',
    key: 'ap_synergyAgentFee',
    width: '10%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{utils.TransformDBCurrency(record?.ap_synergyAgentFee)}</div>
      };
    }
  },
  {
    title: 'Invoice',
    key: 'ap_synergyInvoiceLink',
    width: '10%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)" }
        },
        children: <div>{<Button
          type="link"
          onClick={() => window.open(`${record.ap_synergyInvoiceLink}?${JSON.parse(localStorage?.getItem("user"))?.sasToken}`, '_blank')}
        >Invoice</Button>}</div>
      };
    }
  }
];

const generateDetails = (notificationData) => {
  const assetDetails = {
    title: "Drawdown Details",
    data: [
      {
        "label": "Lender",
        "value": notificationData.lenderName
      },
      {
        "label": "Admin",
        "value": notificationData.adminAnalyst
      }
    ]
  };

  const fundingDetails = {
    title: "Funding Details",
    data: [
      {
        "label": "Total Agent Fee",
        "value": utils.TransformDBCurrency(notificationData.totalSynergyAgentFee)
      }
    ]
  };

  return {
    "assetDetails": assetDetails,
    "fundingDetails": fundingDetails
  };
}

export const constants = {
  "SynergyFundedAssetTableColumns": SynergyFundedAssetTableColumns,
  "generateDetails": generateDetails
}
