import { useState } from "react";
import { Spin } from "antd";

import JoinUsForm from "../../layouts/JoinUsForm";
import JoinUsSuccess from "../../layouts/JoinUsSuccess";

import styles from "./index.module.css";

export default function JoinUs() {

    const [loading, setLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    return (
        <Spin spinning={loading} tip="Sending Request...">
            {requestSent ? <JoinUsSuccess /> :
                <div className={styles["JoinUs_Container"]}>

                    <div className={styles["JoinUs_StaticHolder"]}>
                        <div className={styles["JoinUs_StaticHeader"]}>Join Us</div>
                    </div>

                    <div className={styles["JoinUs_Form"]}>

                        <div className={styles["JoinUs_Header"]}>Join Us</div>

                        <JoinUsForm
                            setLoading={setLoading}
                            setRequestSent={setRequestSent}
                        />

                    </div>

                </div>
            }

        </Spin>

    );
}