import { theme } from "antd";
import styles from "./index.module.css";

function configuration(data) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, ap_userSecondaryColor, userSecondaryHoverColor } } = theme.useToken();

    return {
        appendPadding: 10,
        data,
        angleField: 'count',
        colorField: 'label',
        radius: 1,
        innerRadius: 0.7,
        meta: {
            value: {
                formatter: (v) => `${v}`,
            },
        },
        color: [ap_userPrimaryColor, ap_userHighlightColor, userSecondaryHoverColor, "#EDCB00", ap_userSecondaryColor, "#4ECBD7"],
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        },
        legend: {
            layout: 'vertical',
            position: 'bottom',
            title: {
                text: "",
                style: {
                    lineWidth: 1,
                    fontSize: 14
                }
            },
        },
        statistic: {
            title: {
                offsetY: -4,
                customHtml: (container, view, datum) => {
                    const text = datum ? datum._id : 'All Deals';
                    return (<span className={styles["dealsLabel"]}>{text}</span>);
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '6px',
                },
                customHtml: (container, view, datum, data) => {
                    const text = datum ? `${datum.percentage_count} %` : `100 %`;
                    return (
                        <span className={styles["dealsPercentage"]}>{text}</span>
                    );
                },
            },
        },
        // Added central stats text interaction
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    }
}

const exportObj = {
    configuration: configuration,
}

export default exportObj;