import { useState } from "react";
import { theme } from "antd";
import HomeContent from "../../components/HomeContent";
import NewSettings from "../../layouts/NewSettings";
import DarkenHexColor from "../../utils/DarkenHexColor";

export default function Settings() {

    const [loader, setLoader] = useState({ loader: false, tip: "" });

    const { token: { ap_userSecondaryColor }, } = theme.useToken();

    const themeColors = { "--userSecondaryHoverColor": DarkenHexColor(ap_userSecondaryColor) }

    return (
        <div style={themeColors}>
            <HomeContent
                contentHeader="Settings"
                content={<NewSettings setLoader={setLoader} />}
                bgColor="transparent"
                loader={loader}
            />
        </div>
    );
}