import styles from "./index.module.css";

export default function EmptyDataComponent({ className, iconComponent, ContentLine1, ContentLine2, ComponentLine3, flex = true }) {
    return (
        <div className={styles["Container"]}>
            <div>{iconComponent}</div>
            <div className={styles["SubContainer"]}>
                <div className={styles["ContentLine1"]}>{ContentLine1 || "There is nothing to show here."}</div>
                <div>{ContentLine2}</div>
                <div>{ComponentLine3}</div>
            </div>
        </div>
    );
}