import { useEffect, useRef, useState } from "react";
import HomeDonutChart from "../Graphs/HomeDonutChart";
import HomeGrowthChart from "../Graphs/HomeGrowthChart";
import HomeApplications from "../HomeApplications";
import HomeApprovalsRecent from "../HomeApprovalsRecent";
import HomeDrawdownRecent from "../HomeDrawdownRecent";
import HomeTasksCard from "../HomeTasksCard";
import HomeUrgentRequirements from "../HomeUrgentRequirements";
import styles from "./index.module.css";

export default function HomeLayout() {
    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;

    const containerRef = useRef(null);
    const [numComponents, setNumComponents] = useState(0);

    useEffect(() => {
        if (containerRef?.current) {
            const availableHeight = containerRef?.current?.clientHeight;
            const newNumComponents = Math.floor(availableHeight / 30);
            setNumComponents(newNumComponents);
        }
    }, []);

    return (
        <>
            <div className={styles["Home_Grid1"]}>
                <div className={styles["Container"]}>
                    <HomeGrowthChart />
                </div>
                <div className={styles["Container"]}>
                    <HomeDonutChart />
                </div>
            </div>

            <div className={styles["Home_Grid2"]}>
                <div className={styles["Container"]}>
                    <HomeDrawdownRecent entity={'agent'} entityId={agentId} />
                </div>
                <div className={styles["Container"]}>
                    <HomeUrgentRequirements />
                </div>
            </div>

            <div className={styles["Home_Grid3"]} >
                <div className={styles["Container2"]} ref={containerRef}>
                    <HomeTasksCard  entity={"agent"} entityId={agentId} numComponents={numComponents} />
                </div>
                <div className={styles["Home_Grid3_Internal"]}>
                    <div className={styles["Container4"]}>
                        <HomeApprovalsRecent callbackHeight={() => { }} entity={"agent"} entityId={agentId} />
                    </div>
                    <div className={styles["TransparentContainer"]}>
                        <HomeApplications />
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>
        </>
    );
}