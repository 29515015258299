import { StarOutlined, StarFilled } from "@ant-design/icons";
import { useState } from "react";

export default function StarIcon() {
    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovered ? <StarFilled style={{ color: 'gold', fontSize: '24px' }} /> : <StarOutlined style={{ color: 'gold', fontSize: '24px' }} />}
        </div>
    );
}