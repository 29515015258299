import { theme } from 'antd';
import Icon from '@ant-design/icons';

const PrimaryColor = () => {
    const { token: { themeFontColor }, } = theme.useToken();
    return (
        <svg width="18" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 16C7.44943 16 6.40914 15.7931 5.43853 15.391C4.46793 14.989 3.58601 14.3997 2.84315 13.6569C1.34285 12.1566 0.5 10.1217 0.5 8C0.5 5.87827 1.34285 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0C12.9 0 16.5 3.2 16.5 7.2C16.5 8.47304 15.9943 9.69394 15.0941 10.5941C14.1939 11.4943 12.973 12 11.7 12H10.26C10.02 12 9.86 12.16 9.86 12.4C9.86 12.48 9.94 12.56 9.94 12.64C10.26 13.04 10.42 13.52 10.42 14C10.5 15.12 9.62 16 8.5 16ZM8.5 1.6C6.80261 1.6 5.17475 2.27428 3.97452 3.47452C2.77428 4.67475 2.1 6.30261 2.1 8C2.1 9.69739 2.77428 11.3253 3.97452 12.5255C5.17475 13.7257 6.80261 14.4 8.5 14.4C8.74 14.4 8.9 14.24 8.9 14C8.9 13.84 8.82 13.76 8.82 13.68C8.5 13.28 8.34 12.88 8.34 12.4C8.34 11.28 9.22 10.4 10.34 10.4H11.7C12.5487 10.4 13.3626 10.0629 13.9627 9.46274C14.5629 8.86263 14.9 8.04869 14.9 7.2C14.9 4.08 12.02 1.6 8.5 1.6ZM4.1 6.4C4.74 6.4 5.3 6.96 5.3 7.6C5.3 8.24 4.74 8.8 4.1 8.8C3.46 8.8 2.9 8.24 2.9 7.6C2.9 6.96 3.46 6.4 4.1 6.4ZM6.5 3.2C7.14 3.2 7.7 3.76 7.7 4.4C7.7 5.04 7.14 5.6 6.5 5.6C5.86 5.6 5.3 5.04 5.3 4.4C5.3 3.76 5.86 3.2 6.5 3.2ZM10.5 3.2C11.14 3.2 11.7 3.76 11.7 4.4C11.7 5.04 11.14 5.6 10.5 5.6C9.86 5.6 9.3 5.04 9.3 4.4C9.3 3.76 9.86 3.2 10.5 3.2ZM12.9 6.4C13.54 6.4 14.1 6.96 14.1 7.6C14.1 8.24 13.54 8.8 12.9 8.8C12.26 8.8 11.7 8.24 11.7 7.6C11.7 6.96 12.26 6.4 12.9 6.4Z" fill={themeFontColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4372 12.3345C13.5639 12.2128 13.7295 12.1633 13.9078 12.1633H15.0919C15.51 12.1633 15.8625 12.2717 16.1192 12.5159C16.3793 12.757 16.5 13.0856 16.5 13.4752C16.5 13.8654 16.379 14.1952 16.1193 14.4392C15.8623 14.6803 15.5098 14.787 15.0919 14.787H14.5458V15.3523C14.5458 15.5284 14.4991 15.6929 14.3823 15.8199L14.3795 15.8229C14.2554 15.9524 14.0836 16 13.903 16C13.7251 16 13.559 15.9506 13.4343 15.8259L13.4314 15.823C13.3097 15.6962 13.2602 15.5306 13.2602 15.3523V12.8109C13.2602 12.6314 13.3087 12.4629 13.4343 12.3374L13.4372 12.3345ZM14.9378 12.842C15.1296 12.842 15.3084 12.8821 15.4465 12.9906C15.5981 13.1097 15.6577 13.2857 15.6577 13.4752C15.6577 13.6636 15.5969 13.8362 15.4498 13.957L15.4465 13.9598C15.3084 14.0682 15.1296 14.1083 14.9378 14.1083H14.1458V12.842H14.9378Z" fill={themeFontColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4465 12.9906C15.3084 12.8821 15.1296 12.842 14.9378 12.842H14.1458V14.1083H14.9378C15.1296 14.1083 15.3084 14.0682 15.4465 13.9598L15.4498 13.957C15.5969 13.8362 15.6577 13.6636 15.6577 13.4752C15.6577 13.2857 15.5981 13.1097 15.4465 12.9906ZM14.5458 13.242V13.7084H14.9378C15.0779 13.7084 15.1556 13.6787 15.1976 13.6465C15.2295 13.6196 15.2577 13.5738 15.2577 13.4752C15.2577 13.3694 15.2274 13.3272 15.1993 13.3051C15.1577 13.2724 15.0798 13.242 14.9378 13.242H14.5458Z" fill={themeFontColor} />
        </svg>
    );
}

export default function PrimaryColorIcon(props) {
    return <Icon component={PrimaryColor} {...props} />
}