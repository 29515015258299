import { Button, Upload, notification } from "antd";
import ColorPreferences from "../../components/ColorPreferences";
import ThemePreference from "../../components/ThemePreference";
import { useTheme } from "../../utils/ThemeContext";
import UploadIcon from "../../assets/images/icons/UploadIcon";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import ApiService from "../../api";
import appInsightsObj from "../../services/app-insight-service";
import constants from "../../constants";
import utils from "../../utils";

const { Dragger } = Upload;

export default function NewSettings({ setLoader }) {

    const { theme, setTheme } = useTheme();
    const { fileList, setFileList } = useState({});
    const [settingsId, setSettingsId] = useState('');

    const [notifApi, contextHolder] = notification.useNotification();
    const openNotification = (type, message, description) => {
        notifApi[type]({
            message: message,
            description: description,
            placement: 'bottomRight',
        });
    }

    const handleFileUpload = (file) => {
        if (file?.file?.type === 'image/svg+xml') {
            const reader = new FileReader();

            reader.onload = function (event) {
                const base64 = event.target.result;

                const fileData = {
                    data: base64,
                    fileName: file.file.name,
                    folderName: '',
                    containerName: JSON.parse(localStorage?.getItem("user"))?.agent_id,    // TODO: agentId to be fetched from application memory in the future
                }
                setLoader({ loader: true, tip: "Uploading logo..." });
                ApiService.uploadDocs(fileData).then((resp) => {
                    setTheme(prevTheme => {
                        return {
                            token: {
                                ...prevTheme.token,
                                ap_logoUrl: resp.data.url,
                            }
                        }
                    });
                    setLoader({ loader: false, tip: "" });
                });
            }

            reader.readAsDataURL(file?.fileList?.[0]?.originFileObj);
        } else openNotification('error', 'Please upload a valid SVG file');
    }

    const props = {
        multiple: false,
        listType: 'picture',
        accept: '.svg',
        beforeUpload: (file) => {
            setFileList([file]);
        },
        onChange: handleFileUpload,
        fileList: fileList,
        onDrop(e) {
            appInsightsObj.info('Dropped files ' + e.dataTransfer.files);
        },
        maxCount: 1,
    };

    const handleSave = () => {

        if (!settingsId) {
            openNotification('error', 'An error occurred while saving the settings. Please try again later.');
            return;
        }

        const data = {
            ap_theme: theme?.token?.ap_theme,
            ap_userPrimaryColor: theme?.token?.ap_userPrimaryColor,
            ap_userSecondaryColor: theme?.token?.ap_userSecondaryColor,
            ap_userHighlightColor: theme?.token?.ap_userHighlightColor,
            ap_logoUrl: theme?.token?.ap_logoUrl,
        }

        setLoader({ loader: true, tip: "Saving settings..." });
        ApiService.updateSettings(settingsId, data).then(response => {
            if (response?.success)
                openNotification('success', response?.success);
            else
                openNotification('error', response?.error);
            setLoader({ loader: false, tip: "" });
        })
    }

    const handleReset = () => {
        // This data should go on to the server
        const resetData = {
            ap_theme: theme?.token?.ap_theme,
            ap_logoUrl: theme?.token?.ap_logoUrl,
            ap_userPrimaryColor: constants.DEFAULT_THEME.token.ap_userPrimaryColor,
            ap_userSecondaryColor: constants.DEFAULT_THEME.token.ap_userSecondaryColor,
            ap_userHighlightColor: constants.DEFAULT_THEME.token.ap_userHighlightColor,
        }

        if (settingsId) {
            setLoader({ loader: true, tip: "Resetting settings..." });
            ApiService.updateSettings(settingsId, resetData).then(response => {
                if (response?.success)
                    openNotification('success', response?.success);
                else
                    openNotification('error', response?.error);
                setLoader({ loader: false, tip: "" });
            })
        }

        setTheme(prevTheme => {
            return {
                token: {
                    ...prevTheme.token,
                    ap_theme: theme?.token?.ap_theme,
                    colorPrimary: constants.DEFAULT_THEME.token.ap_userPrimaryColor,
                    colorPrimaryHover: constants.DEFAULT_THEME.token.ap_userHighlightColor,
                    ap_userPrimaryColor: constants.DEFAULT_THEME.token.ap_userPrimaryColor,
                    ap_userSecondaryColor: constants.DEFAULT_THEME.token.ap_userSecondaryColor,
                    ap_userHighlightColor: constants.DEFAULT_THEME.token.ap_userHighlightColor,
                }
            }
        })
    }

    useEffect(() => {
        const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;
        if (!agentId)
            return;
        setLoader({ loader: true, tip: "Fetching settings..." });
        ApiService.fetchAgentProfileData(agentId).then((agentResponse) => {
            setSettingsId(agentResponse?.ap_settingsId?._id);
            setLoader({ loader: false, tip: "" });
        });
    }, []);

    return (
        <div className={styles["Container"]}>
            <div className={styles["InnerContainer"]}>
                <div className={styles["InnerContainer_Header"]}>Step 1. Please select your theme preference.</div>
                <ThemePreference theme={theme} setTheme={setTheme} />
            </div>

            <div className={styles["InnerContainer"]}>
                <div className={styles["InnerContainer_Header"]}>Step 2. Please select your color preferences.</div>
                <div className={styles["InnerContainer_SubHeader"]}>For best results, we recommend selecting a lighter hue for you Highlight Colour</div>
                <ColorPreferences />
            </div>

            <div id="SettingsDragger" className={styles["InnerContainer"]}>
                <div className={styles["InnerContainer_Header"]}>Step 3. Please upload your business logo.</div>
                <div className={styles["InnerContainer_SubHeader"]}>File must be in .svg format</div>
                <Dragger {...props} style={{ margin: '2% 0%' }}>
                    <div className={styles["Settings_Dragger"]}>
                        <div><UploadIcon /></div>
                        <div className={styles["Settings_DraggerContent"]}>
                            Drag and drop files here or
                            <a> browse</a>
                        </div>
                    </div>
                </Dragger>
            </div>

            <div className={styles["Settings_ActionsSection"]}>
                <Button
                    type="primary"
                    className={styles["Settings_SaveButton"]}
                    onClick={handleSave}
                >Save</Button>
                <Button
                    type="primary"
                    className={styles["Settings_ResetButton"]}
                    onClick={handleReset}
                >Reset to Default</Button>
            </div>
            {contextHolder}
        </div>
    )
}