import { useState } from "react";
import { Button } from "antd";

import RequestAsset from "../RequestAsset";
import DoesNotExists from "../../assets/images/icons/DoesNotExist";

import styles from "./index.module.css";

export default function ListingDoesNotExists({ searched = false, filtered = false }) {

    const searchedOrFiltered = searched || filtered;
    const [requestAsset, setRequestAsset] = useState(false);

    const handleRequestAsset = () => {
        setRequestAsset(prevAssetRequest => !prevAssetRequest);
    }

    return (
        <div className={styles["Container"]}>
            <DoesNotExists />
            {searchedOrFiltered && <div>We're sorry - we have nothing listed matching your search.</div>}
            {!searchedOrFiltered && <div>We're sorry - we have no active listings to show.</div>}
            <div>Click here to Request the asset you're looking for.</div>
            <Button
                type="primary"
                onClick={handleRequestAsset}
            >Request Asset</Button>
            <RequestAsset requestAsset={requestAsset} setRequestAsset={setRequestAsset} />
        </div>
    )
}