import Icon from '@ant-design/icons';
import { theme } from 'antd';

const Email = (color) => {

    const { token: { ap_userPrimaryColor } } = theme.useToken();

    return (
        <svg width="24" height="16" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.10005C0 2.35744 0.294999 1.64525 0.820101 1.12015C1.3452 0.595048 2.05739 0.300049 2.8 0.300049H25.2C25.9426 0.300049 26.6548 0.595048 27.1799 1.12015C27.705 1.64525 28 2.35744 28 3.10005V19.9C28 20.6427 27.705 21.3548 27.1799 21.8799C26.6548 22.405 25.9426 22.7 25.2 22.7H2.8C2.05739 22.7 1.3452 22.405 0.820101 21.8799C0.294999 21.3548 0 20.6427 0 19.9V3.10005ZM4.9266 3.10005L14 11.0394L23.0734 3.10005H4.9266ZM25.2 4.96065L14.9226 13.9542C14.6673 14.1779 14.3394 14.3012 14 14.3012C13.6606 14.3012 13.3327 14.1779 13.0774 13.9542L2.8 4.96065V19.9H25.2V4.96065Z" fill={ap_userPrimaryColor} />
        </svg>

    );
}

export default function EmailIconOutlined({ color, ...props }) {
    return <Icon component={() => Email(color)} {...props} />
}