import CreditExpired from "./CreditExpired";
import CreditExtended from "./CreditExtended";
import DealFunded from "./DealFunded";
import NewRequirements from "./NewRequirements";
import SubmissionAmendment from "./SubmissionAmendment";
import SubmissionApproved from "./SubmissionApproved";
import ApprovalMaintained from "./ApprovalMaintained";
import CreditExpiredMaintained from "./CreditExpiredMaintained";
import VendorFunded from "./VendorFunded";
import AssetDeclined from "./AssetDeclined";
import NewPps from "./NewPps";
import SynergyFunded from "./SynergyFunded";
import SynergyCancelled from "./SynergyCancelled";
import ApproachingExpiry from "./ApproachingExpiry";
import OwnershipRequest from "./OwnershipRequest";
import SigningAuditing from "./SigningAuditing";

export default {
    ApproachingExpiry: ApproachingExpiry,
    CreditExpired: CreditExpired,
    CreditExtended: CreditExtended,
    DealFunded: DealFunded,
    NewRequirements: NewRequirements,
    SubmissionAmendment: SubmissionAmendment,
    SubmissionApproved: SubmissionApproved,
    ApprovalMaintained: ApprovalMaintained,
    CreditExpiredMaintained: CreditExpiredMaintained,
    VendorFunded: VendorFunded,
    AssetDeclined: AssetDeclined,
    NewPps: NewPps,
    SynergyFunded: SynergyFunded,
    SynergyCancelled: SynergyCancelled,
    OwnershipRequest: OwnershipRequest,
    SigningAuditing: SigningAuditing,
}