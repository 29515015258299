export default [
    {
        label: 'Percent',
        value: 'percent',
    },
    {
        label: 'Amount',
        value: 'amount'
    },
    {
        label: 'First',
        value: 'first',
    },
    {
        label: 'First And Last',
        value: 'first-last'
    }
];