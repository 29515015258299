import Icon from '@ant-design/icons';

function email() {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.45898C0 1.92855 0.210714 1.41984 0.585786 1.04477C0.960859 0.669698 1.46957 0.458984 2 0.458984H18C18.5304 0.458984 19.0391 0.669698 19.4142 1.04477C19.7893 1.41984 20 1.92855 20 2.45898V14.459C20 14.9894 19.7893 15.4981 19.4142 15.8732C19.0391 16.2483 18.5304 16.459 18 16.459H2C1.46957 16.459 0.960859 16.2483 0.585786 15.8732C0.210714 15.4981 0 14.9894 0 14.459V2.45898ZM3.519 2.45898L10 8.12998L16.481 2.45898H3.519ZM18 3.78798L10.659 10.212C10.4766 10.3718 10.2424 10.4598 10 10.4598C9.75755 10.4598 9.52336 10.3718 9.341 10.212L2 3.78798V14.459H18V3.78798Z" fill="#447EB7" />
        </svg>
    );
}

function emailHovered() {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0407758 2.05715C0.0138524 2.18841 0 2.32298 0 2.45898V14.459C0 14.9894 0.210714 15.4981 0.585786 15.8732C0.960859 16.2483 1.46957 16.459 2 16.459H18C18.5304 16.459 19.0391 16.2483 19.4142 15.8732C19.7893 15.4981 20 14.9894 20 14.459V2.45898C20 2.33371 19.9882 2.20965 19.9654 2.08828L18 3.80387V14.459H2V3.78798L9.341 10.212L0.0407758 2.05715ZM1.34518 0.56922L10 8.12998L18.6548 0.56922C18.4462 0.496909 18.225 0.458984 18 0.458984H2C1.77497 0.458984 1.55384 0.496909 1.34518 0.56922ZM10 8.12998L16.481 2.45898H3.519L10 8.12998Z" fill="#447EB7" />
            <path d="M3.519 2.45898L10 8.12998L16.481 2.45898H3.519Z" fill="#447EB7" />
            <path d="M10.659 10.212C10.4766 10.3718 10.2424 10.4598 10 10.4598C9.75755 10.4598 9.52336 10.3718 9.341 10.212L2 3.78798V14.459H18V3.80387L10.659 10.212Z" fill="#447EB7" />
        </svg>
    );
}

function emailSelected() {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0407758 2.05715C0.0138524 2.18841 0 2.32298 0 2.45898V14.459C0 14.9894 0.210714 15.4981 0.585786 15.8732C0.960859 16.2483 1.46957 16.459 2 16.459H18C18.5304 16.459 19.0391 16.2483 19.4142 15.8732C19.7893 15.4981 20 14.9894 20 14.459V2.45898C20 2.33371 19.9882 2.20965 19.9654 2.08828L18 3.80387V14.459H2V3.78798L9.341 10.212L0.0407758 2.05715ZM1.34518 0.56922L10 8.12998L18.6548 0.56922C18.4462 0.496909 18.225 0.458984 18 0.458984H2C1.77497 0.458984 1.55384 0.496909 1.34518 0.56922ZM10 8.12998L16.481 2.45898H3.519L10 8.12998Z" fill="white" />
            <path d="M3.519 2.45898L10 8.12998L16.481 2.45898H3.519Z" fill="white" />
            <path d="M10.659 10.212C10.4766 10.3718 10.2424 10.4598 10 10.4598C9.75755 10.4598 9.52336 10.3718 9.341 10.212L2 3.78798V14.459H18V3.80387L10.659 10.212Z" fill="white" />
        </svg>
    );
}

export default function JoinUsEmail({ hovered, selected }) {
    if (selected)
        return <Icon component={() => emailSelected()} style={iconStyles} />
    else if (hovered)
        return <Icon component={() => emailHovered()} style={iconStyles} />
    else
        return <Icon component={() => email()} style={iconStyles} />
}

const iconStyles = {
    width: '15px',
    height: '40px'
}