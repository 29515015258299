export default function CreateFilter(data, column) {
    let filter = [];
    for (let i = 0; i < data.length; i++) {
        filter.push({
            text: data[i][column],
            label: data[i][column],
            value: data[i][column],
        });
    }
    return filter;
}