import constants from "../../constants"

export default function generateRegistrationData() {
    return ({
        currentStep: {
            value: 0,
            error: '',
        },
        firstName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'First Name',
        },
        lastName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Last Name',
        },
        email: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Email',
        },
        phoneNumber: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Phone',
        },
        password: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'password',
            label: 'Password',
        },
        confirmPassword: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'password',
            label: 'Confirm Password',
        },
        businessName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Business Name',
        },
        taxNumber: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Tax Number',
        },
        address: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Address',
        },
        city: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'City',
        },
        provinceState: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown-search',
            label: 'Province/State',
            dropdownContent: constants.CANADIAN_PROVINCES
        },
        postalZipCode: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Postal/ZIP Code',
        },
        country: {
            value: 'Canada',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown-search',
            label: 'Country',
            dropdownContent: constants.COUNTRIES,
        },
        receiveSMSNotification: {
            value: true,
            error: '',
            hidden: false,
            required: false,
            inputType: 'inline-switch',
            label: 'Receive SMS Notification',
        },
        receiveWhatsAppNotification: {
            value: true,
            error: '',
            hidden: false,
            required: false,
            inputType: 'inline-switch',
            label: 'Receive WhatsApp Notification',
        },
        receiveEmailNotification: {
            value: true,
            error: '',
            hidden: false,
            required: false,
            inputType: 'inline-switch',
            label: 'Receive Email Notification',
        },
    })
}