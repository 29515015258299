import Icon from "@ant-design/icons"
import { theme } from "antd";

function Unread(themeDangerColor) {
    return (
        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.05273" cy="8" r="7.25" fill={themeDangerColor} />
        </svg>
    )
}

export default function UnreadMark() {
    const { token: { themeDangerColor } } = theme.useToken();
    return <Icon component={() => Unread(themeDangerColor)} />
}