import SecurityService from './security.service';

export default class EncryptionService extends SecurityService {

  _isSecure = Symbol('protected');
  constructor() {
    super();
    if (this.constructor === EncryptionService) {
        throw new Error("Abstract classes can't be instantiated.");
    }
    this._isSecure = (process.env.REACT_APP_SECURE === 'true');
   }
  /**
   ** This function will encrypt requests before sending request to the server
   * @author Ishank Agarwal
   *
   * @param request
   * @returns request
   */
  encrypt(request) {
    let encryptionNotRequired=request?.params?.no_encryption || request?.params?.no_encryption;
    if(request?.data instanceof FormData) {
      encryptionNotRequired = 'true';
    }
    if (this._isSecure && !request.url.includes('en.json') && !encryptionNotRequired) {
        let params;
        if(request?.params)  
          // params = this.encryptAES(this._secure_key, request?.params?.toString());
          params = this.encryptAES(this._secure_key, JSON.stringify(request?.params));
        let data;
        if(request?.data) {
          data = this.encryptAES(this._secure_key, JSON.stringify(request?.data));
        }
        let headers = request.headers;
        if (request.headers['Authorization']) {
          headers['Authorization'] = this.encryptAES(this._secure_key, headers['Authorization'])
        }
        if (request && request.userData && request?.userData?.id) {
          const token = request.userData.id.substring(0, 16);
          params = this.encryptAES(token, params);
          data = this.encryptAES(token, data);
          if (request.headers['Authorization']) {
            headers['Authorization'] = btoa(this.encryptAES(token, headers['Authorization']));
          }
        }
        else {
          if (headers['Authorization'])
            headers['Authorization'] = btoa(headers['Authorization']);
        }
        if(request?.params)
          request.params = { query: btoa(params) };
        if(request?.data)
          request.data = { data: btoa(data) };
      }
    if ((this._isSecure && encryptionNotRequired)||!this._isSecure) {
      if(request?.params)
        request.params['internal'] = 'true';
    }
    return request;
  }

   /**
   ** This function will decrypt response payloads coming from the server
   * @author Ishank Agarwal
   *
   * @param response
   * @returns response
   */
  async decrypt(response) {
    let encryptionNotRequired = response?.config?.params?.no_encryption;

    if (this._isSecure && !response?.config?.url.includes('en.json') && !encryptionNotRequired) {
      try{
        const data = JSON.parse(await this.decryptAES(this._secure_key, await atob(response?.data?.data)))
        response.data = {
          data,
          status: response.status
        }
      }
      catch(e){
        console.error("error", e);
        return;
      }
    }
    return response;
  }

   /**
   ** This function will decrypt error response payloads coming from the server
   * @author Ishank Agarwal
   *
   * @param request
   * @param error
   * @returns error
   */
  errorDecrypt(request, error) {
    let encryptionNotRequired=request.params['no_encryption'];
    if(request.body instanceof FormData) {
      encryptionNotRequired = 'true';
    }
    if (this._isSecure && error.error && error.error.errors && !encryptionNotRequired) {
        const err = JSON.parse(this.decryptAES(this._secure_key, atob(error.error.errors)));
        return error = {
          error: {
            status: error.status,
            error: err
          }
        };
        // return throwError(error);
      }
    //   return throwError(error);
  }
}
