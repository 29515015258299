import constants from "../../constants";
import utils from "../../utils";
import dayjs from 'dayjs';

function generatePopoverContent(data) {

    // Commission Popover Content
    const commissionPopoverContent = [{ title: 'Type', content: utils.CommissionTypeLabel(data?.ap_commissionType) }];

    if (data?.ap_commissionType === '%invoice' || data?.ap_commissionType === '%netFinanced')
        commissionPopoverContent.push({ title: 'Amount', content: utils.TransformDBCurrency(data?.ap_baseAmount) })

    if (data?.ap_commissionType === '%netFinanced')
        commissionPopoverContent.push({ title: 'Down Payment', content: `- ${utils.TransformDBCurrency(data?.ap_downPaymentAmount)}` });

    if (data?.ap_commissionType === '%spread') {
        commissionPopoverContent.push({ title: 'Max Yield', content: data?.ap_maximumYield + " bp" });
        commissionPopoverContent.push({ title: 'Max Client Rate', content: `${Number(data?.ap_costOfFunds) + (data?.ap_maximumYield / 100)} %`, isTotal: true });
    } else {
        commissionPopoverContent.push({ title: 'Commission %', content: data?.ap_maxCommissionPercent + " %" });
        commissionPopoverContent.push({ title: 'Max Commission', content: utils.TransformDBCurrency(data?.ap_maxCommissionAmount), isTotal: true });
    }

    // Down Payment Popover Content
    const downPaymentPopoverContent = [];
    if (data?.ap_downPayment === "First" || data?.ap_downPayment === "FirstAndLast") {
        downPaymentPopoverContent.push({ title: 'Type', content: utils.DownPaymentLabel(data?.ap_downPayment) });
        downPaymentPopoverContent.push({ title: 'Monthly Payment', content: `${utils.TransformDBCurrency(data?.ap_regularMonthlyPayment)} ${(data?.ap_downPayment === "FirstAndLast") ? "(×2)" : ""}` })
    } else {
        downPaymentPopoverContent.push({ title: 'Amount', content: utils.TransformDBCurrency(data?.ap_baseAmount) })
        downPaymentPopoverContent.push({ title: 'Type', content: data?.ap_downPayment });
    }

    if (data?.ap_bonusDownPaymentAmount && data?.ap_bonusDownPaymentAmount > 0) {
        downPaymentPopoverContent.push({ title: 'Bonus Amount', content: data?.ap_bonusDownPaymentAmount ? utils.TransformDBCurrency(data?.ap_bonusDownPaymentAmount) : '$0.00' });
        downPaymentPopoverContent.push({ title: 'Total', content: utils.TransformDBCurrency(data?.ap_downPaymentAmount + data?.ap_bonusDownPaymentAmount), isTotal: true });
    } else downPaymentPopoverContent.push({ title: 'Total', content: utils.TransformDBCurrency(data?.ap_downPaymentAmount), isTotal: true });

    // Initial Amount Popover Content
    const initialAmountPopoverContent = [{ title: 'Base Amount', content: data?.ap_baseAmount ? utils.TransformDBCurrency(data?.ap_baseAmount) : '$0.00' }];
    let totalAmount = data?.ap_baseAmount;

    if (data?.ap_totalDocFeeToClient && data?.ap_totalDocFeeToClient > 0) {
        initialAmountPopoverContent.push({ title: 'Doc Fee', content: data?.ap_totalDocFeeToClient ? utils.TransformDBCurrency(data?.ap_totalDocFeeToClient) : '$0.00' });
        totalAmount += data?.ap_totalDocFeeToClient;
    }

    if (data?.ap_otherFees && data?.ap_otherFees > 0) {
        initialAmountPopoverContent.push({ title: 'Other Fees', content: data?.ap_otherFees ? utils.TransformDBCurrency(data?.ap_otherFees) : '$0.00' });
        totalAmount += data?.ap_otherFees;
    }
    initialAmountPopoverContent.push({ title: 'Total', content: utils.TransformDBCurrency(totalAmount), isTotal: true });

    return { commissionPopoverContent, downPaymentPopoverContent, initialAmountPopoverContent }
}

function generatePaymentScheduleFields(data) {

    const popoverContents = generatePopoverContent(data);
    let finalDownPaymentAmount = 0;
    if (data?.ap_downPaymentAmount && data?.ap_downPaymentAmount > 0)
        finalDownPaymentAmount += data?.ap_downPaymentAmount;
    if (data?.ap_bonusDownPaymentAmount && data?.ap_bonusDownPaymentAmount > 0)
        finalDownPaymentAmount += data?.ap_bonusDownPaymentAmount;

    return ({
        ap_commissionType: {
            value: data?.ap_commissionType,
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'dropdown',
            label: 'Commission Type',
            eventHandler: () => { },
            dropdownContent: constants.COMMISSION_TYPE,
        },
        ap_maxCommissionPercent: {
            value: data?.ap_maxCommissionPercent + " %",
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Max Commission (%)',
            eventHandler: () => { },
        },
        ap_maxCommissionAmount: {
            value: utils.TransformDBCurrency(data?.ap_maxCommissionAmount),
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Max Commission ($)',
            eventHandler: () => { },
        },
        ap_actualCommission: {
            value: utils.TransformDBCurrency(data?.ap_actualCommission),
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'themed-input',
            label: 'Commission',
            eventHandler: () => { },
            enableLock: !Boolean(data?._id),
            popoverContent: popoverContents?.commissionPopoverContent
        },
        ap_baseAmount: {
            value: utils.TransformDBCurrency(data?.ap_baseAmount + (data?.ap_totalDocFeeToClient || 0) + (data?.ap_otherFees || 0)),
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Initial Amount',
            eventHandler: () => { },
            popoverContent: popoverContents?.initialAmountPopoverContent
        },
        ap_term: {
            value: data?.ap_term + " months",
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Term (months)',
            eventHandler: () => { },
        },
        ap_costOfFunds: {
            value: utils.TransformtoFixed(data?.ap_costOfFunds, 2) + " %",
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Cost of Funds',
            eventHandler: () => { },
        },
        ap_clientRate: {
            value: utils.TransformtoFixed(data?.ap_clientRate, 2) + " %",
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Client Rate',
            eventHandler: () => { },
        },
        ap_totalDocFeeToClient: {
            value: data?.ap_totalDocFeeToClient ? utils.TransformDBCurrency(data?.ap_totalDocFeeToClient) : '$0.00',
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Total Doc Fee to Client',
            eventHandler: () => { },
        },
        ap_otherFees: {
            value: data?.ap_otherFees ? utils.TransformDBCurrency(data?.ap_otherFees) : '$0.00',
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Other Fees',
            eventHandler: () => { },
        },
        ap_downPayment: {
            value: utils.DownPaymentLabel(data?.ap_downPayment),
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'dropdown',
            label: 'Down Payment Type',
            eventHandler: () => { },
            dropdownContent: constants.DOWN_PAYMENT,
        },
        ap_downPaymentAmount: {
            // value: data?.ap_downPaymentAmount ? utils.TransformDBCurrency(data?.ap_downPaymentAmount) : "$0.00",
            value: finalDownPaymentAmount ? utils.TransformDBCurrency(finalDownPaymentAmount) : "$0.00",
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Down Payment',
            eventHandler: () => { },
            popoverContent: popoverContents?.downPaymentPopoverContent
        },
        ap_bonusDownPaymentAmount: {
            value: data?.ap_bonusDownPaymentAmount ? utils.TransformDBCurrency(data?.ap_bonusDownPaymentAmount) : "$0.00",
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Bonus Down Payment',
            eventHandler: () => { },
        },
        ap_purchaseOption: {
            value: data?.ap_purchaseOption ? utils.TransformDBCurrency(data?.ap_purchaseOption) : "$0.00",
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Residual',
            eventHandler: () => { },
        },
        ap_regularMonthlyPayment: {
            value: utils.TransformDBCurrency(data?.ap_regularMonthlyPayment),
            error: '',
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'themed-input',
            enableLock: !Boolean(data?._id),
            label: 'Monthly Payment',
            eventHandler: () => { },
        },
        ap_specialPayments: {
            value: data?.ap_specialPayments,
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Special Payments',
            eventHandler: () => { },
        },
        ap_taxBalloon: {
            value: data?.ap_taxBalloon,
            error: '',
            hidden: !data?.ap_specialPayments,
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Tax Balloon',
            eventHandler: () => { },
        },
        ap_taxRatePercent: {
            value: data?.ap_taxRatePercent + " %",
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_taxBalloon),
            required: data?.ap_taxBalloon,
            disabled: true,
            inputType: 'text',
            label: 'Tax Rate (%)',
            eventHandler: () => { },
        },
        ap_taxAmount: {
            value: data?.ap_taxAmount ? utils.TransformDBCurrency(data?.ap_taxAmount) : '',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_taxBalloon),
            required: data?.ap_taxBalloon,
            disabled: true,
            inputType: 'text',
            label: 'Tax Amount',
            eventHandler: () => { },
        },
        ap_taxBalloonAmountNumber: {
            value: data?.ap_taxBalloonAmountNumber ? data?.ap_taxBalloonAmountNumber : '',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_taxBalloon),
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Tax Balloon Payment #',
            eventHandler: () => { },
        },
        ap_taxBalloonMonthYear: {
            value: data?.ap_taxBalloonMonthYear ? data?.ap_taxBalloonMonthYear : '',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_taxBalloon),
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Tax Balloon Month Year',
            eventHandler: () => { },
        },
        ap_acceleratedPayments: {
            value: data?.ap_acceleratedPayments,
            error: '',
            hidden: !data?.ap_specialPayments,
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Accelerated Payments',
            eventHandler: () => { },
        },
        ap_acceleratedAmountPercent: {
            value: data?.ap_acceleratedAmountPercent,
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_acceleratedPayments),
            required: data?.ap_acceleratedPayments,
            disabled: true,
            inputType: 'dropdown',
            label: 'Accelerated Amount (%)',
            eventHandler: () => { },
            dropdownContent: constants.ACCELERATED_AMOUNT_PERCENT,
        },
        ap_acceleratedMonthlyBonus: {
            value: data?.ap_acceleratedMonthlyBonus ? utils.TransformDBCurrency(data?.ap_acceleratedMonthlyBonus) : "$0.00",
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_acceleratedPayments),
            required: data?.ap_acceleratedPayments,
            disabled: true,
            inputType: 'text',
            label: 'Accelerated Monthly Bonus',
            eventHandler: () => { },
        },
        ap_acceleratedTerm: {
            value: data?.ap_acceleratedTerm ? data?.ap_acceleratedTerm : "",
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_acceleratedPayments),
            required: data?.ap_acceleratedPayments,
            disabled: true,
            inputType: 'text',
            label: 'Accelerated Term',
            eventHandler: () => { },
        },
        ap_skipPayments: {
            value: data?.ap_skipPayments,
            error: '',
            hidden: !data?.ap_specialPayments,
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Skip Payments',
            eventHandler: () => { },
        },
        ap_skipMonths: {
            value: data?.ap_skipMonths.join(', ') || '',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_skipPayments),
            required: data?.ap_skipPayments,
            disabled: true,
            inputType: 'text',
            label: 'Months',
            eventHandler: () => { },
        },
        ap_skipAmount: {
            value: data?.ap_skipAmount ? utils.TransformDBCurrency(data?.ap_skipAmount) : '$0.00',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_skipPayments),
            required: data?.ap_skipPayments,
            disabled: true,
            inputType: 'text',
            label: 'Skip Amount',
            eventHandler: () => { },
        },
        ap_skipFullTerm: {
            value: data?.ap_skipFullTerm,
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_skipPayments),
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Full Term',
            eventHandler: () => { },
        },
        ap_skipPaymentStartDate: {
            value: dayjs(data?.ap_skipPaymentStartDate ? data?.ap_skipPaymentStartDate : '').format("MMM YYYY"),
            error: '',
            hidden: Boolean(data?.ap_skipFullTerm) ? (data?.ap_skipPayments || data?.ap_skipFullTerm) : (!data?.ap_skipPayments && !data?.ap_skipFullTerm),
            required: data?.ap_skipPayments && !data?.ap_skipFullTerm,
            disabled: true,
            inputType: 'text',
            label: 'Start',
            eventHandler: () => { },
        },
        ap_skipPaymentEndDate: {
            value: dayjs(data?.ap_skipPaymentEndDate ? data?.ap_skipPaymentEndDate : '').format("MMM YYYY"),
            error: '',
            hidden: Boolean(data?.ap_skipFullTerm) ? (data?.ap_skipPayments || data?.ap_skipFullTerm) : (!data?.ap_skipPayments && !data?.ap_skipFullTerm),
            required: data?.ap_skipPayments && !data?.ap_skipFullTerm,
            disabled: true,
            inputType: 'text',
            label: 'End',
            eventHandler: () => { },
        },
        ap_interestOnlyPayment: {
            value: data?.ap_interestOnlyPayment,
            error: '',
            hidden: !data?.ap_specialPayments,
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Interest Only Payments',
            eventHandler: () => { },
        },
        ap_interestOnlyMonths: {
            value: data?.ap_interestOnlyMonths.join(', ') || '',
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_interestOnlyPayment),
            required: data?.ap_interestOnlyPayment,
            disabled: true,
            inputType: 'textarea',
            label: 'Months',
            eventHandler: () => { },
        },
        ap_interestOnlyFullTerm: {
            value: data?.ap_interestOnlyFullTerm,
            error: '',
            hidden: !(data?.ap_specialPayments && data?.ap_interestOnlyPayment),
            required: false,
            disabled: true,
            inputType: 'switch',
            label: 'Full Term',
            eventHandler: () => { },
        },
        ap_interestOnlyStartDate: {
            value: dayjs(data?.ap_interestOnlyStartDate ? data?.ap_interestOnlyStartDate : '').format("MMM YYYY"),
            error: '',
            hidden: Boolean(data?.ap_interestOnlyFullTerm) ? (data?.ap_interestOnlyPayment || data?.ap_interestOnlyFullTerm) : (!data?.ap_interestOnlyPayment && !data?.ap_interestOnlyFullTerm),
            required: !data?.ap_interestOnlyFullTerm,
            disabled: true,
            inputType: 'text',
            label: 'Start',
            eventHandler: () => { },
        },
        ap_interestOnlyEndDate: {
            value: dayjs(data?.ap_interestOnlyEndDate ? data?.ap_interestOnlyEndDate : '').format("MMM YYYY"),
            error: '',
            hidden: Boolean(data?.ap_interestOnlyFullTerm) ? (data?.ap_interestOnlyPayment || data?.ap_interestOnlyFullTerm) : (!data?.ap_interestOnlyPayment && !data?.ap_interestOnlyFullTerm),
            required: !data?.ap_interestOnlyFullTerm,
            disabled: true,
            inputType: 'text',
            label: 'End',
            eventHandler: () => { },
        },
    })
}

// not in use now
function commissionData(formData) {
    return {
        ap_commissionType: { ...formData.ap_commissionType },
        ap_maxCommissionPercent: { ...formData.ap_maxCommissionPercent },
        ap_maxCommissionAmount: { ...formData.ap_maxCommissionAmount },
        ap_actualCommission: { ...formData.ap_actualCommission },
    }
}

function details(formData) {
    return {
        ap_actualCommission: { ...formData.ap_actualCommission },
        ap_clientRate: { ...formData.ap_clientRate },
        ap_regularMonthlyPayment: { ...formData.ap_regularMonthlyPayment },
        ap_baseAmount: { ...formData.ap_baseAmount },
        ap_term: { ...formData.ap_term },
        ap_costOfFunds: { ...formData.ap_costOfFunds },
        ap_downPaymentAmount: { ...formData.ap_downPaymentAmount },
        ap_purchaseOption: { ...formData.ap_purchaseOption },

        // ap_baseAmount: { ...formData.ap_baseAmount },
        // ap_totalDocFeeToClient: { ...formData.ap_totalDocFeeToClient },
        // ap_otherFees: { ...formData.ap_otherFees },
        // ap_downPayment: { ...formData.ap_downPayment },
        // ap_bonusDownPaymentAmount: { ...formData.ap_bonusDownPaymentAmount },
    }
}

function taxBalloonFields(formData) {
    return {
        ap_taxBalloon: { ...formData.ap_taxBalloon },
        ap_taxRatePercent: { ...formData.ap_taxRatePercent },
        ap_taxAmount: { ...formData.ap_taxAmount },
        ap_taxBalloonAmountNumber: { ...formData.ap_taxBalloonAmountNumber },
        ap_taxBalloonMonthYear: { ...formData.ap_taxBalloonMonthYear },
    }
}

function acceleratedFields(formData) {
    return {
        ap_acceleratedPayments: { ...formData.ap_acceleratedPayments },
        ap_acceleratedAmountPercent: { ...formData.ap_acceleratedAmountPercent },
        ap_acceleratedMonthlyBonus: { ...formData.ap_acceleratedMonthlyBonus },
        ap_acceleratedTerm: { ...formData.ap_acceleratedTerm },
    }
}

function skipPaymentFields(formData) {
    return {
        ap_skipPayments: { ...formData.ap_skipPayments },
        ap_skipMonths: { ...formData.ap_skipMonths },
        ap_skipAmount: { ...formData.ap_skipAmount },
        ap_skipFullTerm: { ...formData.ap_skipFullTerm },
        ap_skipPaymentStartDate: { ...formData.ap_skipPaymentStartDate },
        ap_skipPaymentEndDate: { ...formData.ap_skipPaymentEndDate },
    }
}

function interestOnlyFields(formData) {
    return {
        ap_interestOnlyPayment: { ...formData.ap_interestOnlyPayment },
        ap_interestOnlyMonths: { ...formData.ap_interestOnlyMonths },
        ap_interestOnlyFullTerm: { ...formData.ap_interestOnlyFullTerm },
        ap_interestOnlyStartDate: { ...formData.ap_interestOnlyStartDate },
        ap_interestOnlyEndDate: { ...formData.ap_interestOnlyEndDate },
    }
}

export default {
    generatePaymentScheduleFields: generatePaymentScheduleFields,
    // commissionData: commissionData,
    details: details,
    taxBalloonFields: taxBalloonFields,
    acceleratedFields: acceleratedFields,
    skipPaymentFields: skipPaymentFields,
    interestOnlyFields: interestOnlyFields
}