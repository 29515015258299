import { Tag, theme } from "antd";

import SearchColumn from "../../components/SearchColumn";
import SortColumn from "../../components/SortColumn";
import utils from "../../utils";
import VisualizeDrawdownStage from "../../utils/VisualizeDrawdownStage";
import styles from "./index.module.css";

function generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort) {

    const { token: { themeFontColor  } } = theme.useToken();

    const columns = [
        {
            title: 'LC#',
            dataIndex: 'lcNumber',
            key: 'lcNumber',
            ...SortColumn(customSort, setCustomSort, 'lcNumber'),
            width: 100,
            ellipsis: true,
        }
    ];

    if (entity !== 'client') {
        columns.push({
            title: 'Client',
            key: 'clientName',
            dataIndex: 'clientName',
            ...SortColumn(customSort, setCustomSort, 'clientName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'clientName'),
            width: 150,
            ellipsis: true,
        });
    }

    columns.push(
        {
            title: 'Asset Description',
            dataIndex: 'assetDescription',
            key: 'assetDescription',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Lender',
            key: 'lenderName',
            dataIndex: 'lenderName',
            ...SortColumn(customSort, setCustomSort, 'lenderName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'lenderName'),
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Fee',
            key: 'drawdownFee',
            render: (record) => utils.TransformDBCurrency(record?.drawdownFee),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Process Stage',
            key: 'processStage',
            width: 150,
            render: (record) => VisualizeDrawdownStage(record, themeFontColor)?.bpf,
            ellipsis: true,
        },
        {
            title: 'Status',
            key: 'status',
            render: (record) => {
                return (
                    <Tag
                        className={styles[(record?.responsibleType === 'Agent' || record?.responsibleType === 'Client') ? "RedTags" : "GreenTags"]}
                        bordered={false}
                    >{VisualizeDrawdownStage(record, themeFontColor)?.processStage}</Tag>
                )
            },
            width: 100,
            ellipsis: true,
        },
    );

    return columns;
}

const exportObjs = {
    generateTableColumns: generateTableColumns
}

export default exportObjs;