import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Skeleton, Spin } from "antd";
import dayjs from "dayjs";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";

import Error404 from "../../layouts/Error404";
import CreateApplicationForm from "../../layouts/CreateApplicationForm";

import SaveSyncIcon from "../../assets/images/icons/SaveSyncIcon";
import UnsavedIcon from "../../assets/images/icons/UnsavedIcon";

import ApiService from "../../api";

import styles from "./index.module.css";

export default function CreateApplication() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showError, setShowError] = useState(false);
    const [applicationName, setApplicationName] = useState('');
    const [creditName, setCreditName] = useState('Lantern Credit');
    const [autoSave, setAutoSave] = useState(true);
    const [draftData, setDraftData] = useState({});
    const [clientList, setClientList] = useState([]);
    const [unsaved, setUnsaved] = useState(false);

    useEffect(() => {
        ApiService.fetchAgentClient().then(clients => {
            if (!clientList)
                return;
            setClientList(clients);
        });
    }, []);

    useEffect(() => {
        if (!id) {
            setApplicationName('New Application');
        } else {
            setLoading(true);
            ApiService.fetchIndividualApplicationDraft(id).then((data) => {
                // TODO: When there is an applicationId on the draft, then show error or redirect them to the appropriate application
                setAutoSave(!data?.ap_applicationId)
                const errorExists = !data || !!data?.ap_applicationId;
                setShowError(errorExists);
                setDraftData(data);
                setLoading(false);
                if (setUnsaved) setUnsaved(false);
                if (errorExists) {
                    setApplicationName("No Application Found");
                    return;
                }
                setApplicationName(`${dayjs(data?.createdAt).format("MMMM YYYY")} - ${data?.ap_clientId?.ap_businessName} - ${data?.ap_clientId?.ap_type}`);
            });
        }
    }, [id, refresh]);

    const saveTrackerComponent = (
        <>
            {(autoSave && !unsaved) && <div className={styles["AutoSave"]}>Autosave is on <SaveSyncIcon /></div>}
            {unsaved && <div className={styles["UnsavedChanges"]}>Unsaved changes <UnsavedIcon /></div>}
        </>
    )

    const contentHeader = (
        <div className={styles["HeaderContainer"]}>
            <GoBackButton />
            <div className={styles["HeaderName"]}>
                <div style={{ display: 'flex', gap: 15 }}>
                    <div>{loading ? <Skeleton.Input active size="small" /> : applicationName}</div>
                    {saveTrackerComponent}
                </div>
                <div>Credit: <span>{loading ? <Skeleton.Input active size="small" /> : creditName}</span></div>
            </div>
        </div>
    );

    const content = (
        <Spin
            spinning={loading}
            tip="Loading..."
            size="large"
        >
            {
                showError ?
                    <div style={{ maxHeight: '73vh', overflowY: 'hidden' }}>
                        <Error404 />
                    </div> :
                    <CreateApplicationForm
                        draftData={draftData}
                        clientList={clientList}
                        setRefresh={setRefresh}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                    />
            }
        </Spin>
    );

    return (
        <HomeContent
            contentHeader={contentHeader}
            content={content}
            bgColor="transparent"
        />
    );
}