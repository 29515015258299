import SubmissionUpdatedIcon from "./ApprovalUpdated";
import CreditExpiredIcon from "./CreditExpired";
import CreditExtendedIcon from "./CreditExtended";
import DealFundedIcon from "./DealFunded";
import NewRequirementsIcon from "./NewRequirements";
import SubmissionApprovedIcon from "./SubmissionApproved";
import VendorFundedIcon from "./VendorFunded";
import ApprovalMaintainedIcon from "./ApprovalMaintained";
import AssetDeclinedIcon from "./AssetDeclined";
import NewPpsIcon from "./NewPps";
import SynergyFundedIcon from "./SynergyFunded";
import SynergyCancelledIcon from "./SynergyCancelled";
import ApproachingExpiryIcon from "./ApproachingExpiry";
import OwnershipRequestIcon from "./OwnershipRequest";
import SigningAuditingIcon from "./SigningAuditing";

export default {
    SubmissionUpdatedIcon: SubmissionUpdatedIcon,
    ApprovalMaintainedIcon: ApprovalMaintainedIcon,
    CreditExpiredIcon: CreditExpiredIcon,
    CreditExtendedIcon: CreditExtendedIcon,
    DealFundedIcon: DealFundedIcon,
    NewRequirementsIcon: NewRequirementsIcon,
    SubmissionApprovedIcon: SubmissionApprovedIcon,
    VendorFundedIcon: VendorFundedIcon,
    AssetDeclinedIcon: AssetDeclinedIcon,
    NewPpsIcon: NewPpsIcon,
    SynergyFundedIcon: SynergyFundedIcon,
    SynergyCancelledIcon: SynergyCancelledIcon,
    ApproachingExpiryIcon: ApproachingExpiryIcon,
    OwnershipRequestIcon: OwnershipRequestIcon,
    SigningAuditingIcon: SigningAuditingIcon,
}