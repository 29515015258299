import { ConfigProvider, Form, Select, theme } from 'antd';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from './index.module.css';

export default function Dropdown({ data, item, layout, autoFill }) {
    const { Option } = Select;
    const { token: { ap_userHighlightColor, themePrimaryColor, themeSecondaryColor, themeFontColor, themeDisabledContainer, autoFillDisabled }, } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorBgContainerDisabled: autoFill ? autoFillDisabled : themeDisabledContainer,
                    colorText: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                    controlOutline: ap_userHighlightColor,
                    colorPrimaryHover: ap_userHighlightColor,
                    controlItemBgHover: themeSecondaryColor,
                    colorIconHover: themeSecondaryColor,
                    colorTextQuaternary: themeFontColor,
                    colorBorder: ap_userHighlightColor
                }
            }}
        >
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                        disabled={data.disabled}
                    >
                        {layout == 'vertical' ?
                            <>
                                <Select
                                    value={data.value}
                                    onChange={data.eventHandler}
                                    status={!!data.error ? 'error' : ''}
                                    style={{ textAlign: 'left', backgroundColor: 'transparent' }}
                                    disabled={data.disabled}
                                >
                                    {data.dropdownContent.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </> : null
                        }
                    </Form.Item>
                    {layout == 'horizontal' ?
                        <>
                            <Select
                                value={data.value}
                                onChange={data.eventHandler}
                                status={!!data.error ? 'error' : ''}
                                style={{ textAlign: 'left' }}
                                disabled={data.disabled}
                            >
                                {data.dropdownContent.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </> : null
                    }
                </div>
            )}
        </ConfigProvider>
    );
}