import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";

import ApiService from "../../api";
import drawdownConstants from "./constants";

import styles from "./index.module.css";

export default function DrawdownDocuments({ documents }) {

    const { id } = useParams();

    const { token: { themePrimaryColor, themeFontColor } } = theme.useToken();

    const [loading, setLoading] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [documentDetails, setDocumentDetails] = useState([]);

    useEffect(() => {
        // Append Documents and any saved on the drawdowns
        setDocumentsList([...documents]);

        // Fetch Requirements for the drawdown
        // Fetch Unsigned Docs and Uploaded Docs
        // Push it to the Documents

        setLoading(true);
        ApiService.fetchDrawdownRequirements(id).then(reqResponse => {
            // If no requirements, return
            if (!reqResponse || reqResponse?.length === 0) return;

            // Ideally thete should be only one requirement per drawdown
            const data = reqResponse[0];

            // If there are requirements, fetch the unsigned docs
            const unsignedDocs = data?.ap_unsignedDocumentsList?.map(doc => doc?.fileUrl);

            // if there are any uploaded docs
            const uploadedDocs = data?.ap_uploadedDocuments?.map(doc => doc?.url);

            setDocumentsList([...documents, ...unsignedDocs, ...uploadedDocs]);
            setLoading(false);
        })
    }, [documents, id]);

    useEffect(() => {
        if (!documentsList || documentsList?.length === 0) return;

        setLoading(true);
        ApiService.fetchArrayFileDetails(documentsList)
            .then(response => {
                setDocumentDetails(response);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching document details: ", error);
                setLoading(false);
            });
    }, [documentsList]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <div className={styles["header"]}>
                Uploaded Documents
            </div>

            <Table
                columns={drawdownConstants.generateDocumentTableColumns(documentDetails)}
                dataSource={documentDetails}
                loading={loading}
                scroll={{ x: true, y: 400 }}
                pagination={false}
            />
        </ConfigProvider>
    );
}