export default [
    {
        label: '---Clear Filter---',
        value: '',
    },
    {
        label: 'Construction Equipment',
        value: 'Construction Equipment',
    },
    {
        label: 'Dump Truck',
        value: 'Dump Truck',
    },
    {
        label: 'Reefers',
        value: 'Reefers',
    },
    {
        label: 'Trailers',
        value: 'Trailers',
    },
    {
        label: 'Trucks',
        value: 'Trucks',
    },
    {
        label: 'Other',
        value: 'Other',
    }
];