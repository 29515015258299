import { ConfigProvider, Form, Radio, theme } from 'antd';
import RequiredLabelIndicator from '../../RequiredLabelIndicator';
import styles from './index.module.css';

export default function ThemedRadioComponent({ data, item, layout }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor, themeDisabledContainer }, } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    activeBorderColor: ap_userPrimaryColor,
                    hoverBorderColor: ap_userPrimaryColor,
                    colorBgContainer: themePrimaryColor,
                    colorBgContainerDisabled: themeDisabledContainer,
                    colorText: ap_userPrimaryColor,
                    colorTextPlaceholder: themeFontColor,
                    colorTextDisabled: ap_userPrimaryColor,
                    colorIcon: themeFontColor,
                    colorBorder: ap_userHighlightColor
                }
            }}
        >

            {data?.hidden || !data?.enableLock ? (
                !data?.hidden && <div className={styles["StaticRadioContainer"]}><div className={styles["StaticRadioLabel"]}>{data?.label}</div>: {data?.value ? "Yes" : "No"}</div>
            ) : (
                <div className={(layout === 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}

                    >
                        {(layout === 'vertical' && !data?.enableLock) ?
                            <div className={styles["New-Disabled-Box"]}>{(data?.value) ? "Yes" : "No"}</div> : null
                        }
                        {(layout === 'vertical' && data?.enableLock) ?
                            <Radio.Group disabled={data?.disabled} onChange={data?.eventHandler} value={data?.value} style={{ textAlign: 'left', width: '100%' }}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                            : null}
                    </Form.Item>
                    {(layout === 'horizontal' && !data?.enableLock) ?
                        <div className={styles["New-Disabled-Box"]}>{(data?.value) ? "Yes" : "No"}</div> : null
                    }
                    {(layout === 'horizontal' && data?.enableLock) ?
                        <Radio.Group onChange={data?.eventHandler} value={data?.value}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                        : null}
                </div>
            )
            }
        </ConfigProvider >
    )
}