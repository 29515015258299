import Icon from '@ant-design/icons';
import { theme } from 'antd';

const HomeAvgPercentage = () => {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5 43.5C33.3741 43.5 43 33.8741 43 22C43 10.1259 33.3741 0.5 21.5 0.5C9.62588 0.5 0 10.1259 0 22C0 33.8741 9.62588 43.5 21.5 43.5ZM11.5492 29.2994L28.824 12.0238C29.1781 11.6833 29.6516 11.4952 30.1428 11.5001C30.634 11.5049 31.1037 11.7023 31.4509 12.0497C31.7982 12.3971 31.9953 12.8669 31.9999 13.3581C32.0045 13.8493 31.8162 14.3227 31.4756 14.6766L14.2007 31.9509C13.8491 32.3025 13.3722 32.5 12.8749 32.5C12.3777 32.5 11.9008 32.3025 11.5492 31.9509C11.1975 31.5993 11 31.1224 11 30.6251C11 30.1279 11.1975 29.651 11.5492 29.2994ZM13.8573 12.8918C14.1797 12.7587 14.5253 12.6903 14.8742 12.6906C15.5788 12.6913 16.2543 12.9719 16.7521 13.4707C17.2499 13.9694 17.5291 14.6454 17.5284 15.3501C17.5277 16.0547 17.2471 16.7302 16.7483 17.2279C16.2496 17.7256 15.5735 18.0049 14.8689 18.0042C14.52 18.0038 14.1746 17.9347 13.8524 17.8009C13.5301 17.6671 13.2375 17.4711 12.991 17.2241C12.7445 16.9772 12.5491 16.6841 12.4159 16.3616C12.2827 16.0392 12.2143 15.6936 12.2147 15.3447C12.215 14.9958 12.2841 14.6504 12.418 14.3282C12.5518 14.006 12.7478 13.7134 12.9947 13.4669C13.2417 13.2204 13.5348 13.025 13.8573 12.8918ZM26.279 26.7507C26.7778 26.253 27.4538 25.9738 28.1585 25.9745C28.5074 25.9748 28.8528 26.0439 29.175 26.1777C29.4972 26.3116 29.7899 26.5075 30.0364 26.7545C30.2828 27.0014 30.4782 27.2945 30.6114 27.617C30.7446 27.9395 30.813 28.285 30.8127 28.6339C30.8123 28.9828 30.7433 29.3282 30.6094 29.6504C30.4756 29.9726 30.2796 30.2653 30.0326 30.5117C29.7857 30.7582 29.4926 30.9536 29.1701 31.0868C28.8476 31.22 28.5021 31.2884 28.1532 31.288C27.4485 31.2873 26.773 31.0067 26.2753 30.508C25.7775 30.0092 25.4983 29.3332 25.499 28.6286C25.4997 27.924 25.7803 27.2485 26.279 26.7507Z" fill={ap_userSecondaryColor} />
        </svg>
    );
};

export default function HomeAvgPercentageIcon(props) {
    return <Icon component={HomeAvgPercentage} {...props} />
}