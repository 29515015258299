import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EmptyDrawdown = () => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="80" height="69" viewBox="0 0 80 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74 31.8636C74 29.6214 73.1004 27.4709 71.4991 25.8854C69.8979 24.2998 67.7261 23.4091 65.4615 23.4091H37L28.8031 6.5H8.53846C6.27392 6.5 4.10213 7.39074 2.50086 8.97628C0.899585 10.5618 0 12.7123 0 14.9545V60.0455C0 62.2877 0.899585 64.4382 2.50086 66.0237C4.10213 67.6093 6.27392 68.5 8.53846 68.5H65.4615C67.7261 68.5 69.8979 67.6093 71.4991 66.0237C73.1004 64.4382 74 62.2877 74 60.0455V31.8636Z" fill="#D9D9D9"/>
            <path d="M41.6502 18.0634C41.901 18.5806 42.4252 18.9091 43 18.9091H71.4615C73.333 18.9091 75.1249 19.6454 76.4437 20.9513C77.7621 22.2566 78.5 24.024 78.5 25.8636V54.0455C78.5 55.8851 77.7621 57.6524 76.4437 58.9578C75.1249 60.2637 73.333 61 71.4615 61H14.5385C12.667 61 10.8751 60.2637 9.55627 58.9578C8.23791 57.6524 7.5 55.8851 7.5 54.0455V8.95455C7.5 7.11489 8.23791 5.34756 9.55627 4.04216C10.8751 2.73627 12.667 2 14.5385 2H33.8633L41.6502 18.0634Z" fill="white" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default function EmptyDrawdownIcon (props) {
    return <Icon component={EmptyDrawdown} {...props}/>
}