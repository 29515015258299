const VendorFundedAssetTableColumns = [
  {
    title: 'VIN',
    dataIndex: 'ap_vin',
    key: 'ap_vin',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };

    }
  },
  {
    title: 'Vendor',
    key: 'ap_vendorId',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
        },
        children: <div>{record?.ap_vendorId?.ap_name}</div>
      };
    }
  },
  {
    title: 'Year',
    dataIndex: 'ap_year',
    key: 'ap_year',
    width: '10%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Make',
    dataIndex: 'ap_make',
    key: 'ap_make',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: 'Model',
    dataIndex: 'ap_model',
    key: 'ap_model',
    width: '20%',
    ellipsis: true,
    render(text, record, index) {
      return {
        props: {
          style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
        },
        children: <div>{text}</div>
      };
    }
  }
];

export const constants = {
  "VendorFundedAssetTableColumns": VendorFundedAssetTableColumns
}
