import { theme } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon"
import styles from './index.module.css';

function Interested(interested = false, customColor = false, hovered = false) {
    const width = customColor ? 22 : 25;
    const height = customColor ? 19 : 23;

    const outerStylings = customColor ? styles[hovered ? "hovered" : "notHovered"] : interested ? styles["interestedOuter"] : styles["defaultOuter"];
    const innerStylings = customColor ? styles[hovered ? "hovered" : "notHovered"] : styles[interested ? "interestedInner" : "defaultInner"];

    return (
        <svg width={width} height={height} viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["InterestedIcon"]}>
            <path d="M12.5 0C12.7228 0.000118975 12.9411 0.0622579 13.1306 0.179458C13.3201 0.296657 13.4732 0.46429 13.5728 0.663581L16.6687 6.853L23.4725 7.84177C23.6941 7.87396 23.9023 7.96753 24.0735 8.11189C24.2448 8.25625 24.3722 8.44565 24.4413 8.65866C24.5105 8.87167 24.5187 9.09979 24.4649 9.3172C24.4112 9.53462 24.2977 9.73266 24.1373 9.88891L19.185 14.7176L20.2986 21.5262C20.3344 21.7466 20.3079 21.9726 20.2223 22.1788C20.1366 22.385 19.9951 22.5632 19.8137 22.6934C19.6322 22.8236 19.4181 22.9005 19.1953 22.9157C18.9725 22.9308 18.75 22.8834 18.5526 22.7789L12.5 19.5678L6.44738 22.7789C6.25004 22.8834 6.02746 22.9308 5.80468 22.9157C5.5819 22.9005 5.36775 22.8236 5.18633 22.6934C5.00491 22.5632 4.86341 22.385 4.77774 22.1788C4.69208 21.9726 4.66565 21.7466 4.70143 21.5262L5.81499 14.7176L0.862737 9.88891C0.702298 9.73266 0.58879 9.53462 0.535054 9.3172C0.481317 9.09979 0.489498 8.87167 0.558669 8.65866C0.62784 8.44565 0.755243 8.25625 0.926462 8.11189C1.09768 7.96753 1.30588 7.87396 1.52752 7.84177L8.33132 6.853L11.4272 0.663581C11.5268 0.46429 11.6799 0.296657 11.8694 0.179458C12.0589 0.0622579 12.2772 0.000118975 12.5 0ZM12.5 3.88309L10.1985 8.48615C10.1121 8.65927 9.98514 8.80893 9.82844 8.92238C9.67173 9.03584 9.48992 9.10972 9.29849 9.13774L4.27784 9.86731L7.93773 13.4348C8.07604 13.5696 8.17979 13.7357 8.24016 13.9191C8.30054 14.1025 8.31575 14.2978 8.28452 14.4884L7.46015 19.5246L11.9372 17.1487C12.1105 17.0567 12.3038 17.0085 12.5 17.0085C12.6962 17.0085 12.8895 17.0567 13.0628 17.1487L17.5399 19.5246L16.7155 14.4884C16.6844 14.298 16.6998 14.103 16.7601 13.9198C16.8205 13.7366 16.9241 13.5706 17.0623 13.436L20.7222 9.86731L15.7027 9.13774C15.5113 9.10972 15.3295 9.03584 15.1728 8.92238C15.0161 8.80893 14.8891 8.65927 14.8027 8.48615L12.5 3.88309Z" className={outerStylings} />
            <path d="M12.5 1C12.7135 1.00011 12.9228 1.05705 13.1043 1.16444C13.2859 1.27183 13.4326 1.42543 13.5281 1.60803L16.495 7.27936L23.0153 8.18537C23.2277 8.21486 23.4272 8.30059 23.5913 8.43288C23.7554 8.56516 23.8775 8.7387 23.9438 8.93388C24.0101 9.12906 24.0179 9.33808 23.9664 9.53729C23.9149 9.73651 23.8061 9.91797 23.6524 10.0612L18.9065 14.4856L19.9736 20.7243C20.0079 20.9263 19.9826 21.1334 19.9005 21.3223C19.8184 21.5113 19.6828 21.6745 19.5089 21.7938C19.3351 21.9131 19.1298 21.9836 18.9163 21.9975C18.7029 22.0113 18.4895 21.9679 18.3004 21.8722L12.5 18.9299L6.69957 21.8722C6.51045 21.9679 6.29715 22.0113 6.08365 21.9975C5.87015 21.9836 5.66493 21.9131 5.49107 21.7938C5.31721 21.6745 5.1816 21.5113 5.0995 21.3223C5.01741 21.1334 4.99208 20.9263 5.02637 20.7243L6.09354 14.4856L1.34762 10.0612C1.19387 9.91797 1.08509 9.73651 1.03359 9.53729C0.982096 9.33808 0.989935 9.12906 1.05622 8.93388C1.12251 8.7387 1.24461 8.56516 1.40869 8.43288C1.57278 8.30059 1.77231 8.21486 1.9847 8.18537L8.50502 7.27936L11.4719 1.60803C11.5674 1.42543 11.7141 1.27183 11.8957 1.16444C12.0772 1.05705 12.2865 1.00011 12.5 1Z" className={innerStylings} />
        </svg>
    )
}

export default function InterestedIcon({ interested, customColor, hovered }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
    }
    return <Icon component={() => Interested(interested, customColor, hovered)} style={themeColorVariables} />
}