import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import dayjs from "dayjs";

import HomeContent from "../../components/HomeContent";
import HomeLayout from "../../layouts/HomeLayout";

import ApiService from "../../api";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function Home({ agentId }) {

    const [agentName, setAgentName] = useState("Agent");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchAgentProfileData(agentId).then((data) => {
            setAgentName(data?.ap_name);
            setLoading(false);
        });
    }, [agentId]);

    const header = (
        <div className={styles["Home_Header"]}>
            <div className="Font_SemiBold">
                Dashboard |
                <span className={`${styles["Home_HeaderGreetings"]} Font_SemiBold`}>
                    Hello {loading ? <Skeleton.Input active={true} size="small" /> : agentName}!
                </span>
            </div>
            <div className={`${styles["Home_HeaderDate"]} Font_Mediumm`}>
                {dayjs().format('dddd, MMM DD, YYYY')}
            </div>
        </div>
    );

    return (
        <HomeContent
            contentHeader={header}
            content={<HomeLayout />}
            bgColor="tranparent"
            dashboard={true}
        />
    );
}