import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Spin } from "antd";
import dayjs from "dayjs";

import HorizontalDivider from "../../components/HorizontalDivider";

import InputLayout from "../InputLayout";
import RequestAmendment from "../RequestAmendment";
import RequestCreditExtension from "../RequestCreditExtension";

import ApiService from "../../api";

import generateApprovalDetailsFields from "./constants";
import constants from "../../constants";
import CancelApproval from "../CancelApproval";

export default function ApprovalDetails({ setHeaderName, setData, data }) {

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [layout, setLayout] = useState('horizontal');
    const [refresh, setRefresh] = useState(0);

    // Check if its a Mobile Screen
    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });
    useEffect(() => {
        setLayout(isMobile ? 'vertical' : 'horizontal')
    }, [isMobile]);

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        ApiService.fetchIndividualApprovals(id).then((data) => {
            if (!data) return;

            setData(data);
            setFormData(generateApprovalDetailsFields(data));
            setHeaderName(<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>{(data.ap_lenderId?.ap_abbreviation || data.ap_lenderId?.ap_name || "Lender") + " - " + data.ap_clientId.ap_businessName + " - " + data.ap_assetDescription}</div>
                <div style={{ display: "flex", gap: '20px' }}>
                    {
                        (dayjs(data.ap_expiryDate).$d < dayjs().$d) ?
                            <RequestCreditExtension data={data} setRefresh={setRefresh} /> :
                            <RequestAmendment approvalId={id} data={data} setRefresh={setRefresh} headerName={(data?.ap_lenderId?.ap_abbreviation || data?.ap_lenderId?.ap_name) + " - " + data?.ap_clientId?.ap_businessName + " - " + data.ap_assetDescription} />
                    }
                    {(dayjs(data.ap_expiryDate).$d >= dayjs().$d && data?.ap_expiryApproaching) ? <RequestCreditExtension data={data} setRefresh={setRefresh} /> : null}
                </div>
            </div>);
        })
        setLoading(false);
    }, [id, refresh]);

    const dealDetails = {
        ap_client: { ...formData.ap_client },
        ap_salesCoordinator: { ...formData.ap_salesCoordinator },
        ap_creditAnalyst: { ...formData.ap_creditAnalyst },
        ap_lender: { ...formData.ap_lender },
    }

    const submissionDetails = {
        ap_financingType: { ...formData.ap_financingType },
        ap_submittedDate: { ...formData.ap_submittedDate },
        ap_submissionType: { ...formData.ap_submissionType },
    }

    const approvalDetails = {
        ap_approvalDate: { ...formData.ap_approvalDate },
        ap_expiryDate: { ...formData.ap_expiryDate },
        ap_approvedAmount: { ...formData.ap_approvedAmount },
        ap_term: { ...formData.ap_term },
        ap_downPayment: { ...formData.ap_downPayment },
        ap_downPaymentAmount: { ...formData.ap_downPaymentAmount },
        ap_costOfFunds: { ...formData.ap_costOfFunds },
    }

    const notes = {
        ap_preFundingRequirements: { ...formData.ap_preFundingRequirements },
        ap_approvalNotes: { ...formData.ap_approvalNotes },
    }

    return (
        <>
            <Spin
                spinning={loading}
                tip="Loading..."
                size="large"
            >
                <div style={{ margin: "0% 2%" }}>
                    <InputLayout
                        data={dealDetails}
                        layout={layout}
                    />
                </div>

                <HorizontalDivider title="Submission Details" />
                <div style={{ margin: "0% 2%" }}>
                    <InputLayout
                        data={submissionDetails}
                        layout={layout}
                    />
                </div>

                <HorizontalDivider title="Approval Details" />
                <div style={{ margin: "0% 2%" }}>
                    <InputLayout
                        data={approvalDetails}
                        layout={layout}
                    />
                </div>

                <HorizontalDivider title="Notes" />
                <div style={{ margin: "0% 2%" }}>
                    <InputLayout
                        data={notes}
                        layout={layout}
                    />
                </div>

                <div>
                    {(!data?.ap_cancelledReason) ?
                        <CancelApproval
                            approvalId={id}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        /> :
                        <></>
                    }
                </div>
            </Spin>
        </>
    );
}

