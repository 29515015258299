export default function IndividualAssetManagement(assetStatus) {
    if (!assetStatus)
        return {
            allowEdit: true,
            allowDelete: true
        };

    const allowEditStatuses = ['Drafted', 'Requested', 'Assigned', 'Accepted', 'Submitted', 'Approved'];
    const allowDeleteStatuses = ['Drafted', 'Requested', 'Assigned', 'Accepted', 'Submitted', 'Approved'];

    const contactDetails = [
        {
            status: ['Funded'],
            label: "Customer Service",
            contact: process.env.REACT_APP_CUSTOMER_SERVICE_DEPARTMENT_EMAIL
        },
        {
            status: ['In-Funding'],
            label: "Admin",
            contact: process.env.REACT_APP_ADMIN_DEPARTMENT_EMAIL
        }
    ];
    // Find the contact details for the given asset status
    const { label, contact } = contactDetails.find(({ status }) => status?.includes(assetStatus)) || {};

    return {
        allowEdit: allowEditStatuses.includes(assetStatus),
        allowDelete: allowDeleteStatuses.includes(assetStatus),
        contactDetails: { label, contact }
    }
}