import { useEffect, useState } from "react";
import { theme } from "antd";
import Icon from '@ant-design/icons';

export default function DeleteIcon({ hoverControlled = false, customStyle = {}, editColor }) {

    const { token: { themeDangerColor } } = theme.useToken();

    const [isHovered, setHovered] = useState(hoverControlled);

    const handleMouseEnter = () => setHovered(hoverControlled || true);
    const handleMouseLeave = () => setHovered(hoverControlled || false);

    useEffect(() => {
        setHovered(hoverControlled)
    }, [hoverControlled]);

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isHovered ?
                <Icon component={() => DeletFilled(editColor || themeDangerColor)} style={customStyle} /> :
                <Icon component={() => Delete(editColor || themeDangerColor)} style={customStyle} />
            }
        </div>
    );
}

function Delete(themeDangerColor) {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 2.80957C6.25 2.14653 6.51339 1.51064 6.98223 1.0418C7.45107 0.572962 8.08696 0.30957 8.75 0.30957H16.25C16.913 0.30957 17.5489 0.572962 18.0178 1.0418C18.4866 1.51064 18.75 2.14653 18.75 2.80957V5.30957H23.75C24.0815 5.30957 24.3995 5.44127 24.6339 5.67569C24.8683 5.91011 25 6.22805 25 6.55957C25 6.89109 24.8683 7.20903 24.6339 7.44345C24.3995 7.67787 24.0815 7.80957 23.75 7.80957H22.4137L21.33 22.9871C21.2851 23.6178 21.0029 24.2081 20.5401 24.639C20.0774 25.07 19.4686 25.3096 18.8363 25.3096H6.1625C5.53017 25.3096 4.92133 25.07 4.45861 24.639C3.99588 24.2081 3.71364 23.6178 3.66875 22.9871L2.5875 7.80957H1.25C0.918479 7.80957 0.600537 7.67787 0.366116 7.44345C0.131696 7.20903 0 6.89109 0 6.55957C0 6.22805 0.131696 5.91011 0.366116 5.67569C0.600537 5.44127 0.918479 5.30957 1.25 5.30957H6.25V2.80957ZM8.75 5.30957H16.25V2.80957H8.75V5.30957ZM5.0925 7.80957L6.16375 22.8096H18.8375L19.9088 7.80957H5.0925ZM10 10.3096C10.3315 10.3096 10.6495 10.4413 10.8839 10.6757C11.1183 10.9101 11.25 11.228 11.25 11.5596V19.0596C11.25 19.3911 11.1183 19.709 10.8839 19.9435C10.6495 20.1779 10.3315 20.3096 10 20.3096C9.66848 20.3096 9.35054 20.1779 9.11612 19.9435C8.8817 19.709 8.75 19.3911 8.75 19.0596V11.5596C8.75 11.228 8.8817 10.9101 9.11612 10.6757C9.35054 10.4413 9.66848 10.3096 10 10.3096ZM15 10.3096C15.3315 10.3096 15.6495 10.4413 15.8839 10.6757C16.1183 10.9101 16.25 11.228 16.25 11.5596V19.0596C16.25 19.3911 16.1183 19.709 15.8839 19.9435C15.6495 20.1779 15.3315 20.3096 15 20.3096C14.6685 20.3096 14.3505 20.1779 14.1161 19.9435C13.8817 19.709 13.75 19.3911 13.75 19.0596V11.5596C13.75 11.228 13.8817 10.9101 14.1161 10.6757C14.3505 10.4413 14.6685 10.3096 15 10.3096Z" fill={themeDangerColor} />
        </svg>
    );
}

function DeletFilled(themeDangerColor) {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.96594 2.5C6.96594 1.83696 7.22933 1.20107 7.69817 0.732233C8.16702 0.263392 8.8029 0 9.46594 0H16.9659C17.629 0 18.2649 0.263392 18.7337 0.732233C19.2025 1.20107 19.4659 1.83696 19.4659 2.5V5H24.4659C24.7975 5 25.1154 5.1317 25.3498 5.36612C25.5842 5.60054 25.7159 5.91848 25.7159 6.25C25.7159 6.58152 25.5842 6.89946 25.3498 7.13388C25.1154 7.3683 24.7975 7.5 24.4659 7.5H23.1297L22.0459 22.6775C22.001 23.3082 21.7188 23.8985 21.2561 24.3295C20.7934 24.7604 20.1845 25 19.5522 25H6.87844C6.24612 25 5.63728 24.7604 5.17455 24.3295C4.71182 23.8985 4.42959 23.3082 4.38469 22.6775L3.30344 7.5H1.96594C1.63442 7.5 1.31648 7.3683 1.08206 7.13388C0.847638 6.89946 0.715942 6.58152 0.715942 6.25C0.715942 5.91848 0.847638 5.60054 1.08206 5.36612C1.31648 5.1317 1.63442 5 1.96594 5H6.96594V2.5ZM9.46594 5H16.9659V2.5H9.46594V5ZM10.7159 10C11.0475 10 11.3654 10.1317 11.5998 10.3661C11.8342 10.6005 11.9659 10.9185 11.9659 11.25V18.75C11.9659 19.0815 11.8342 19.3995 11.5998 19.6339C11.3654 19.8683 11.0475 20 10.7159 20C10.3844 20 10.0665 19.8683 9.83206 19.6339C9.59764 19.3995 9.46594 19.0815 9.46594 18.75V11.25C9.46594 10.9185 9.59764 10.6005 9.83206 10.3661C10.0665 10.1317 10.3844 10 10.7159 10ZM16.5998 10.3661C16.3654 10.1317 16.0475 10 15.7159 10C15.3844 10 15.0665 10.1317 14.8321 10.3661C14.5976 10.6005 14.4659 10.9185 14.4659 11.25V18.75C14.4659 19.0815 14.5976 19.3995 14.8321 19.6339C15.0665 19.8683 15.3844 20 15.7159 20C16.0475 20 16.3654 19.8683 16.5998 19.6339C16.8342 19.3995 16.9659 19.0815 16.9659 18.75V11.25C16.9659 10.9185 16.8342 10.6005 16.5998 10.3661Z" fill={themeDangerColor} />
        </svg>
    );
}