import { Divider, Layout, theme } from "antd";
import dayjs from "dayjs";

import accordianConstants from "../NotificationAccordian/constants";

import MarkAsUnreadIcon from "../../assets/images/icons/MarkAsUnread";
import MarkAsReadIcon from "../../assets/images/icons/MarkAsRead";
import NotificationDeleteIcon from "../../assets/images/icons/NotificationDelete";
import UnreadMark from "../../assets/images/icons/UnreadMark";

import styles from "./index.module.css";
import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

export default function NotificationPageList({ notifications, isRead, navigate, setRefresh, setNotifications, setLoader }) {

    const { token: { ap_userPrimaryColor, themePrimaryColor, themeDangerColor, themeFontColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
        '--themeDangerColor': themeDangerColor
    };

    const handleMarkNotification = (id, data) => {
        setLoader({ loading: true, tip: 'Updating notification...' });
        ApiService.updateNotification(id, data).then(response => {
            if (response?.success)
                setRefresh(prevRefresh => prevRefresh + 1);
            setLoader({ loading: false, tip: '' });
        });
        setNotifications((prevNotifications) => {
            prevNotifications.map((notif) => {
                if (notif._id === id) {
                    notif.ap_isRead = !notif.ap_isRead;
                }
                return notif;
            });
            return [...prevNotifications];
        });
        NotificationService.success("Notification marked as " + (data.ap_isRead ? "read" : "unread"))

    }

    const handleDeleteNotification = (id) => {
        setLoader({ loading: true, tip: 'Deleting notification...' });
        ApiService.updateNotification(id, { ap_isActive: false, ap_isRead: true }).then(response => {
            if (response?.success)
                setRefresh(prevRefresh => prevRefresh + 1);
            setLoader({ loading: false, tip: '' });
        });
        setNotifications((prevNotifications) => {
            return prevNotifications.filter((notif) => notif._id !== id);
        });
        NotificationService.success("Notification deleted successfully");

    }

    if (notifications?.length === 0)
        return (
            <div className={styles["EmptyItem"]}>
                <div
                    className={styles["EmptyItem_Content"]}
                >
                    You have no {isRead ? 'read' : 'unread'} notifications.
                </div>
            </div>
        )
    return (
        <div className={!isRead ? styles["UnreadItem"] : styles["ReadItem"]}>
            {notifications?.map((notif, index) => {

                const compiledData = accordianConstants.generateData(notif?.ap_notificationType, notif?.ap_data);

                return (
                    <Layout style={themeColorVariables}>
                        <div
                            className={!isRead ? styles["UnreadItemGrid"] : null}
                            onClick={(e) => { navigate(`/notifications/${notif?._id}`); e.stopPropagation(); }}
                        >

                            {!isRead ?
                                <div className={styles["MarginCenter"]}>
                                    <UnreadMark />
                                </div> :
                                <></>
                            }

                            <div key={index} className={styles["IndividualItem"]}>

                                <div className={styles["IndividualItem_Icon"]}>
                                    {compiledData?.icon}
                                </div>

                                <div className={styles["IndividualItem_Content"]}>
                                    <div className={styles["IndividualItem_Header"]}>
                                        {compiledData?.headerText}
                                    </div>

                                    <div className={styles["IndividualItem_Subtext"]}>
                                        {compiledData?.subText}
                                    </div>
                                </div>

                                <div className={styles["Flex"]}>

                                    <div className={styles["Timeline"]}>
                                        <div>{dayjs(notif?.createdAt).format("HH:mm a")}</div>
                                        <div>{dayjs(notif?.createdAt).format("MM-DD-YYYY")}</div>
                                    </div>

                                    <Divider
                                        type="vertical"
                                        className={styles["IndividualItem_Divider"]}
                                    />

                                </div>

                                <div
                                    className={styles["MarginCenter"]}
                                    onClick={(e) => { e.stopPropagation(); handleMarkNotification(notif?._id, { ap_isRead: !isRead }) }}
                                >
                                    {isRead ? <MarkAsUnreadIcon /> : <MarkAsReadIcon />}
                                </div>

                                <div
                                    className={styles["MarginCenter"]}
                                    onClick={(e) => { e.stopPropagation(); handleDeleteNotification(notif?._id) }}
                                >
                                    <NotificationDeleteIcon />
                                </div>

                            </div>

                        </div>
                    </Layout>
                )
            })}
        </div>
    )
}