import Icon from '@ant-design/icons';
import { theme } from "antd";

function Telephone() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="24" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.652 19.8243L21.0566 16.8688L21.0384 16.8604C20.696 16.714 20.3224 16.6552 19.9516 16.6894C19.5808 16.7237 19.2244 16.8498 18.9145 17.0564C18.8781 17.0805 18.843 17.1067 18.8095 17.1348L15.4019 20.0399C13.2431 18.9912 11.0143 16.7792 9.96568 14.6484L12.8749 11.189C12.9029 11.154 12.9295 11.119 12.9547 11.0812C13.1569 10.7722 13.2796 10.418 13.3118 10.0502C13.3441 9.68233 13.2849 9.31222 13.1395 8.97277V8.95597L10.1757 2.34933C9.98351 1.90589 9.65309 1.5365 9.23373 1.29629C8.81438 1.05608 8.32858 0.957942 7.84886 1.01652C5.95181 1.26615 4.21049 2.1978 2.95013 3.63747C1.68978 5.07714 0.996583 6.92637 1.00001 8.83977C1.00001 19.9559 10.0441 28.9999 21.1602 28.9999C23.0736 29.0033 24.9228 28.3101 26.3625 27.0498C27.8021 25.7894 28.7338 24.0481 28.9834 22.1511C29.0421 21.6715 28.9442 21.1858 28.7042 20.7665C28.4643 20.3472 28.0952 20.0166 27.652 19.8243ZM21.1602 26.7599C16.409 26.7547 11.854 24.865 8.49444 21.5055C5.13489 18.1459 3.24522 13.5909 3.24003 8.83977C3.23476 7.47265 3.7273 6.15035 4.62567 5.11982C5.52404 4.08928 6.7668 3.42099 8.12186 3.23973C8.12131 3.24532 8.12131 3.25095 8.12186 3.25653L11.0619 9.83658L8.16806 13.3002C8.13869 13.334 8.11201 13.37 8.08826 13.408C7.87758 13.7313 7.75399 14.1035 7.72946 14.4886C7.70493 14.8737 7.7803 15.2586 7.94826 15.606C9.21667 18.2002 11.8305 20.7945 14.4527 22.0615C14.8027 22.2279 15.1898 22.3006 15.5763 22.2726C15.9628 22.2447 16.3354 22.1169 16.6577 21.9019C16.6937 21.8777 16.7283 21.8515 16.7613 21.8235L20.1647 18.9198L26.7448 21.8669H26.7602C26.5812 23.2238 25.9139 24.4691 24.8831 25.3697C23.8524 26.2703 22.5289 26.7645 21.1602 26.7599Z" fill={ap_userPrimaryColor} stroke={ap_userPrimaryColor} />
        </svg>

    );
}

export default function TelephoneIconOutlined(props) {
    return <Icon component={Telephone} {...props} />
}