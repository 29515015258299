import constants from "../../constants";

export default function generateClientProfileFields() {

    const profileData = {
        ap_businessName: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Client Business Name',
        },
        ap_industry: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Industry',
            dropdownContent: constants.CLIENT_INDUSTRIES,
            placeholder: 'Select Industry',
        },
        ap_type: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Client Type',
            dropdownContent: constants.CLIENT_TYPES,
            placeholder: 'Select Client Type',
        },
        ap_companyType: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Company Type',
            dropdownContent: constants.COMPANY_TYPES,
            placeholder: 'Select Company Type',
        },
        ap_dateOfIncorporation: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'date',
            label: 'Date of Incorporation',
            placeholder: 'Select date',
        },
        ap_filedFsYearEnd: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'date',
            label: 'Filed FS Year End',
            placeholder: 'Select date',
        },
        ap_email: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'email',
            label: 'Email',
        },
        ap_telephone: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Telephone',
        },
        ap_address: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Address',
        },
        ap_city: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'City',
        },
        ap_stateOrProvince: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Province/State',
            dropdownContent: constants.CANADIAN_PROVINCES,
            placeholder: 'Select Province/State',
        },
        ap_postalCode: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Postal Code',
        },
        ap_country: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Country',
            dropdownContent: constants.COUNTRIES,
            placeholder: 'Select Country',
        },
    }
    return profileData;
}