export default [
    {
        label: 'Lender Declined',
        value: 'LenderDeclined',
    },
    {
        label: 'Customer Withdrawn',
        value: 'CustomerWithdrawn'
    },
    {
        label: 'Lantern Withdrawn',
        value: 'LanternWithdrawn',
    },
    {
        label: 'Information Required',
        value: 'InformationRequired'
    }
];