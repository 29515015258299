import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";

import FolderIcon from "../../assets/images/FolderIcon";
import ApiService from "../../api";

import styles from "./index.module.css";

export default function ResourcesLayout() {

    const navigate = useNavigate();

    const [resources, setResources] = useState([]);
    const [loader, setLoader] = useState({ loading: false, tip: '' });

    useEffect(() => {
        setLoader({ loading: true, tip: 'Loading...' });
        ApiService.fetchResources().then((data) => {
            setResources(data);
            setLoader({ loading: false, tip: '' });
        });
    }, []);

    return (
        <Spin spinning={loader.loading} tip={loader.tip}>
            <div className={styles["Resources_Grid"]}>
                {resources.map((resource, index) => {

                    const brandedNavigationLink = `/resources/${resource._id}/branded`;
                    const whiteLabelNavigationLink = `/resources/${resource._id}/white-label`;

                    return (
                        <div key={index} className={styles["Resources_IndividualGrid"]}>
                            <div><FolderIcon /></div>
                            <div className={styles["Resources_IndividualGrid_Header"]}>{resource.ap_name}</div>
                            <div className={(resource?.ap_branded?.length > 0 && resource?.ap_whiteLabel?.length > 0) ? styles["ButtonContainer"] : styles["HideContainer"]}>
                                {/* <div className={styles["ButtonContainer"]}> */}
                                <Button
                                    type="primary"
                                    className={styles["BrandedButton"]}
                                    onClick={() => navigate(brandedNavigationLink)}
                                >Branded</Button>

                                <Button
                                    type="primary"
                                    className={styles["WhiteLabelButton"]}
                                    onClick={() => navigate(whiteLabelNavigationLink)}
                                >White Label</Button>
                            </div>
                        </div>
                    )
                })}

                <div className={styles["Resources_BeaconPolicies"]} onClick={()=>navigate("beaconpolicies")}>
                    <div><FolderIcon /></div>
                    <div className={styles["Resources_IndividualGrid_Header"]}>Beacon Policies</div>
                </div>
            </div>
        </Spin>
    );
}