import InputLayout from "../InputLayout";
import styles from "./index.module.css";

export default function RegistrationBusinessProfile({ data }) {

    const businessName = { businessName: { ...data.businessName } }
    const taxNumber = { taxNumber: { ...data.taxNumber } }

    const address = { address: { ...data.address } }
    const city = { city: { ...data.city } }

    const provinceState = { provinceState: { ...data.provinceState } }
    const postalZipCode = { postalZipCode: { ...data.postalZipCode } }
    const country = { country: { ...data.country } }

    return (
        <>
            <div style={{ marginBottom: '5%', minWidth: 'fit-content', fontSize: '18px' }}>
                Please enter the business details for your account.
            </div>
            <div className={styles["row2"]}>
                <InputLayout data={businessName} layout="vertical" />
                <InputLayout data={taxNumber} layout="vertical" />
            </div>

            <div className={styles["row2"]}>
                <InputLayout data={address} layout="vertical" />
                <InputLayout data={city} layout="vertical" />
            </div>

            <div className={styles["row3"]}>
                <InputLayout data={provinceState} layout="vertical" />
                <InputLayout data={postalZipCode} layout="vertical" />
                <InputLayout data={country} layout="vertical" />
            </div>

        </>
    )

}