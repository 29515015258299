import { useEffect, useState } from "react";

import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import InputLayout from "../InputLayout";
import AddUserUtils from "./utils";
import AddUserConstants from "./constants";

import styles from "./index.module.css";

export default function AddUser({ open, setOpen, setRefresh }) {

    const [invokeValidation, setInvokeValidation] = useState(false);
    const [newUserData, setNewUserData] = useState({});

    useEffect(() => {
        if (open) return;

        // Reset the form
        setInvokeValidation(false);
        setNewUserData({});
        setOpen(false);
    }, [open]);

    return (
        <Modal
            centered
            closable={false}
            destroyOnClose={true}
            footer={null}
            getContainer=".AppTheme"
            open={open}
        >

            <div className={styles["AddUser_Title"]}>
                <div>Invite a new user</div>
                <div onClick={() => AddUserUtils.handleModal(setOpen)}><CloseOutlined /></div>
            </div>

            <InputLayout
                key={"AddUser"}
                data={AddUserConstants.generateAddUserFields(invokeValidation, newUserData, setNewUserData)}
                layout={"vertical"}
            />

            <div className={styles["AddUser_Container"]}>
                <Button
                    type="primary"
                    className={styles["AddUser_Button"]}
                    onClick={() => AddUserUtils.handleSendInvitation(newUserData, setNewUserData, setInvokeValidation, setOpen, setRefresh)}
                >Send Invitation</Button>
            </div>

        </Modal>
    )
}