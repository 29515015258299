import ClientIcon from "./Client";
import DashboardIcon from "./Dashboard";
import ApplicationIcon from "./Application";
import ApprovalIcon from "./Approval";
import DrawdownIcon from "./Drawdown";
import RequirementIcon from "./Requirements";
import VendorMarketIcon from "./VendorMarket";
import ResourcesIcon from "./Resources";

const SideNavIcons = {
    Dashboard: <DashboardIcon />,
    Client: <ClientIcon />,
    Application: <ApplicationIcon />,
    Approval: <ApprovalIcon />,
    Drawdown: <DrawdownIcon />,
    Requirement: <RequirementIcon />,
    VendorMarket: <VendorMarketIcon />,
    Resources: <ResourcesIcon />,
}

export default SideNavIcons;