import ContactsList from "../ContactsList";
import CreateContact from "../CreateContact";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function ContactsBlock({ entity, entityId, refresh, setRefresh }) {

    return (
        <>
            <div className={styles["profileContainer"]}>

                <div className={styles["profileInnerContainer"]}>
                    <div className={styles["contactsHeader"]}>
                        <div className={styles["contactsSubHeader"]}>
                            {/* <div className={`${styles["headerStyle"]} Font_SemiBold`}>Contacts</div> */}
                            <div>
                                <span className={styles["warning"]}>* </span>
                                {(entity === 'agent') ?
                                    <span className={`${styles["alertText"]} Font_UltraLight`}>Email and Billing notifications will automatically be turned on for Primary Contact.</span> :
                                    <span className={`${styles["alertText"]} Font_UltraLight`}>We <b>never</b> contact clients directly, the contacts here are for your records only.</span>
                                }
                            </div>
                        </div>
                        <div className={styles["contactCreate"]}>
                            <CreateContact
                                entity={entity}
                                entityId={entityId}
                                refresh={refresh}
                                setRefresh={setRefresh}
                            />
                        </div>
                    </div>
                    <div>
                        <ContactsList
                            entity={entity}
                            entityId={entityId}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}