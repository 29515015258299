import dayjs from 'dayjs';
import DataConstants from '../../constants';

function Details(draftData, clientList) {
    const isApplicationSent = !!draftData?.ap_applicationId;
    const isDraftSaved = !!draftData?._id;

    return {
        client: {
            value: draftData?.ap_clientId?._id || draftData?.ap_clientId?._id || '',
            error: (isDraftSaved && !draftData?.ap_clientId) ? 'Required' : '',
            hidden: false,
            required: true,
            disabled: isApplicationSent,
            inputType: 'themed-dropdown-search',
            label: 'Client',
            dropdownContent: clientList || [],
            placeholder: 'Search',
            size: 'large',
            enableLock: !isApplicationSent,
        },
        applicationType: {
            value: draftData?.ap_applicationType || DataConstants.APPLICATION_TYPE[0]?.value,
            error: '',
            hidden: false,
            required: true,
            disabled: isApplicationSent,
            inputType: 'themed-dropdown-search',
            label: 'Application Type',
            dropdownContent: DataConstants.APPLICATION_TYPE,
            size: 'large',
            enableLock: !isApplicationSent,
        },
        filedFSYearEnd: {
            value: (draftData?.ap_filedFSYearEnd) ? dayjs(draftData?.ap_filedFSYearEnd) : '',
            error: '',
            hidden: false,
            required: true,
            disabled: isApplicationSent,
            inputType: 'themed-date',
            label: 'Filed FS Year End',
            size: 'large',
            enableLock: !isApplicationSent,
        },
    }
}

function Documents(clientType, assets, pgs) {
    const isCommercial = (clientType === "Commercial");

    const isPrivateSale = assets?.some(asset => asset?.ap_vendorId?.ap_privateSale === true);

    const pgAvailable = pgs?.length > 0;

    const tempDoc = {
        articlesOfIncorporation: {
            name: 'Articles of Incorporation',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        filledAndSignedApplication: {
            name: 'Filled and Signed Application by All Shareholders(signatures matching those on Drivers Licence)',
            additionalInfo: '*electronic signatures are acceptable',
            required: pgAvailable,
            hidden: false,
            resource: `${process.env.REACT_APP_RESOURCE_URL}/Equipment%20Finance%20Checklist/Branded/Credit%20Application.pdf`,
            checked: false,
        },
        identification: {
            name: '2 Pieces of Identification',
            additionalInfo: '(i.e. Drivers Licence, Passport, Permanent Resident Card)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        financialStatements: {
            name: 'Schedule 100 and 125 (Most Recent)',
            additionalInfo: 'or T2 or accountant prepared financial statements or Bank Statements (3 Months Most Recent)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        voidCheque: {
            name: 'Void Cheque',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        billOfSale: {
            name: 'Bill of Sale',
            additionalInfo: '(for equipment client is looking to purchase)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        operationsSummary: {
            name: 'Operations Summary',
            additionalInfo: '',
            required: isCommercial,
            hidden: false,
            resource: `${process.env.REACT_APP_RESOURCE_URL}/Equipment%20Finance%20Checklist/Branded/Operations%20summary.pdf`,
            checked: false,
        },
        jobLetter: {
            name: 'Job Letter',
            additionalInfo: '',
            required: !isCommercial,
            hidden: false,
            resource: null,
            checked: false,
        },
        assetPictures: {
            name: 'Asset Pictures',
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        ownership: {
            name: 'Ownership',
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        sellerDriversLicence: {
            name: "Seller's Drivers Licence",
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        sellerVoidCheque: {
            name: "Seller's Void Cheque",
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        payout: {
            name: 'Payout',
            additionalInfo: '(if applicable)',
            required: false,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        otherDocuments: {
            name: 'Other Documents',
            additionalInfo: '*Please specify in comments',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
    }

    return tempDoc;
}

const FormConstants = {
    Details,
    Documents,
};

export default FormConstants;