import constants from '../constants';
import CleanData from './CleanData';
import ConvertHexToRGB from './ConvertHexToRGB';
import ConvertRGBAtoHEX from './ConvertRGBAtoHEX';
import DarkenHexColor from './DarkenHexColor';
import FilterSelectedTimePreferences from './FilterSelectedTimePreferences';
import FormBlobFileUrl from './FormBlobFileUrl';
import ProcessStageProgress from './ProcessStageProgress';
import TransformNotificationDate from './TransformNotificationDate';
import UpdateFileUrl from './UpdateFileUrl';
import dayjs from "dayjs";
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import ProcessSortingRules from './ProcessSortingRules';
import NumberWithCommas from './NumberWithCommas';

import CheckIdenticalObjects from './CheckIdenticalObjects';
import IndividualAssetManagement from './IndividualAssetManagement';
import AssetManager from './AssetManager';
import CheckErrorsInFields from './CheckErrorsInFields';
import CleanListingRecord from './CleanListingRecord';
import CopyToClipboard from './CopyToClipboard';
import GenerateAssetDescription from './GenerateAssetDescription';

// Extend Day.js with plugins
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

function FormatCurrency(amount) {
    return Number(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

function TransformCurrencyToNumber(value) {
    if (!value)
        return;
    return parseFloat(String(value).replace(/[^\d.-]/g, ''))
}

function isBetweenValue(min, value, max) {
    if (min > max)
        return 'Please enter a correct value'
    if ((value < min || value > max) && max && typeof (value) === 'number')
        return `Please enter a value between ${min} and ${max}.`;
    if (value !== 0 && !value)
        return 'Required'
    return null;
}

function TransformDBCurrency(amount) {
    return (amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function TransformCurrency(amount) {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function FormatDate(dateString) {
    // Format of dateString: 'yyyy-MM-ddThh:mm:ssZ'
    let date = new Date(dateString);
    return (((date.getDate() + 1 < 10) ? '0' + (date.getDate() + 1) : date.getDate() + 1) + '-' + ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + date.getFullYear());
}

function TransformYearMonthDate(dateString) {
    // dateString Format => 'dd-MM-yyyy'
    const [day, month, year] = dateString.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);
    return dateObj.toLocaleString('default', { month: 'short', year: 'numeric' });
}

// function FormatYearMonth(dateString){
//     // Format of dateString: 'yyyy-MM-ddThh:mm:ssZ'
//     return new Date(dateString).toLocaleDateString('en-us', { year:"numeric", month:"short"});
// }

function FormatYearMonth(dateString) {
    const months = constants.SHORT_MONTHS;
    const year = dateString.slice(0, 4);
    const monthIndex = parseInt(dateString.slice(5, 7)) - 1;
    const month = months[monthIndex];
    return month + ' ' + year;
}

// Converts Telephone String to numeric telephone
function TransformTelephone(phoneString) {
    return parseInt(phoneString.replace(/\D/g, ""));
}

function ValidateYear(yearString) {
    const pattern = /^\d{4}$/;
    if (!yearString) {
        return {
            value: '',
            error: '',
        }
    }
    const year = parseInt(yearString.slice(0, 4));
    if (isNaN(year)) {
        return {
            value: '',
            error: 'Invalid year',
        }
    }

    if (pattern.test(String(year))) {
        if (year >= 1950 && year <= new Date().getFullYear() + 1) {
            return {
                value: year,
                error: '',
            }
        }
        return {
            value: year,
            error: 'Year should be between 1950 and ' + (new Date().getFullYear() + 1),
        }

    }
    return {
        value: year,
        error: 'Invalid year',
    }
}

// ToDo: To be generalized generateAssetDescription Azure Function
// Generate Asset Description for Application
function generateAssetDescription(arr) {
    const countObj = {};
    arr.forEach((item) => {
        const type = item.ap_type;
        if (countObj[type]) {
            countObj[type]++;
        } else {
            countObj[type] = 1;
        }
    });
    const output = Object.entries(countObj)
        .map(([name, count]) => `${count} ${name}`)
        .join(" & ");
    return output;
}

// Checks whether the contacts list contains a primary contact or not
function hasPrimaryContact(array) {
    try {
        for (let i = 0; i < array.length; i++) {
            if (array[i].ap_primaryContact === true) {
                return true;
            }
        }
        return false;
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

function parseDBCurrency(amount) {
    return Number(amount / 100);
}

function convertToDBCurrency(amount) {
    const floatedAmount = parseFloat(amount);
    return String(parseFloat(floatedAmount.toFixed(2)));
}

function FormatDepartmentTelephone(phoneNumber) {
    return String(phoneNumber).replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
}

function GetInitials(name) {
    if (!name) return;
    const words = name?.split(' ');
    const initials = words?.map(word => word.charAt(0).toUpperCase());
    return initials.join('');
}

function formatDates(dt) {
    if (!dt)
        return '';

    const parsedDate = dayjs.utc(dt);
    const estDate = parsedDate.tz('America/New_York', true);
    return estDate.format('DD/MM/YYYY');
}

function CommissionTypeLabel(type) {
    const label = {
        "%invoice": "% Invoice",
        "%netFinanced": "% Net Financed",
        "%spread": "% Spread",
    }
    return label[type];
}

function DownPaymentLabel(dp) {
    const label = {
        "First": "First",
        "FirstAndLast": "First and Last",
        "5%": "5%",
        "10%": "10%",
        "15%": "15%",
        "20%": "20%",
        "Other": "Other",
        "None": "None",
    }
    return label[dp];
}

function sanitizeFileName(fileName) {
    const regex = /[^a-zA-Z0-9. -]/g;
    return fileName.replace(regex, '');
}

function TransformtoFixed(num, fixed) {
    const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)?.[0] || "--";
}

const exportObj = {
    FormatCurrency: FormatCurrency,
    TransformCurrencyToNumber: TransformCurrencyToNumber,
    isBetweenValue: isBetweenValue,
    TransformDBCurrency: TransformDBCurrency,
    TransformCurrency: TransformCurrency,
    FormatDate: FormatDate,
    TransformYearMonthDate: TransformYearMonthDate,
    FormatYearMonth: FormatYearMonth,
    TransformTelephone: TransformTelephone,
    ValidateYear: ValidateYear,
    generateAssetDescription: generateAssetDescription,
    hasPrimaryContact: hasPrimaryContact,
    CleanData: CleanData,
    ConvertHexToRGB: ConvertHexToRGB,
    ConvertRGBAtoHEX: ConvertRGBAtoHEX,
    TransformNotificationDate: TransformNotificationDate,
    FormBlobFileUrl: FormBlobFileUrl,
    UpdateFileUrl: UpdateFileUrl,
    FilterSelectedTimePreferences: FilterSelectedTimePreferences,
    parseDBCurrency: parseDBCurrency,
    convertToDBCurrency: convertToDBCurrency,
    FormatDepartmentTelephone: FormatDepartmentTelephone,
    GetInitials: GetInitials,
    ProcessStageProgress: ProcessStageProgress,
    formatDates: formatDates,
    CommissionTypeLabel: CommissionTypeLabel,
    DownPaymentLabel: DownPaymentLabel,
    DarkenHexColor: DarkenHexColor,
    ProcessSortingRules: ProcessSortingRules,
    CheckIdenticalObjects: CheckIdenticalObjects,
    IndividualAssetManagement: IndividualAssetManagement,
    AssetManager: AssetManager,
    CheckErrorsInFields: CheckErrorsInFields,
    sanitizeFileName: sanitizeFileName,
    NumberWithCommas: NumberWithCommas,
    CleanListingRecord: CleanListingRecord,
    CopyToClipboard: CopyToClipboard,
    TransformtoFixed: TransformtoFixed,
    GenerateAssetDescription: GenerateAssetDescription,
}

export default exportObj;