import utils from '../../utils';

export default function NotificationsBackground(color) {

  const colorPrimary = utils.ConvertRGBAtoHEX(utils.ConvertHexToRGB(color, 20));

  const svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="348" viewBox="0 0 348 1436"><path d="M348.361 1436H2.72168C169.151 1262.18 274.219 1004.15 273.367 716.396C272.517 429.523 166.57 172.818 0 0H348.361V1436Z" fill="${colorPrimary}" /></svg>`;
  
  const imageSrc = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
  
  return <img src={imageSrc} alt="Background" />;
}
