import utils from "../../../utils"

export default function generateDetails(notificationData) {

    const pgs = [notificationData.personalGuarantor1, notificationData.personalGuarantor2, notificationData.personalGuarantor3, notificationData.personalGuarantor4].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const corpCoApp = [notificationData.corpCoApp1, notificationData.corpCoApp2, notificationData.corpCoApp3].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const submissionDetails = {
        title: "Submission Details",
        data: [
            {
                label: "Submission Date",
                value: utils.TransformNotificationDate(notificationData.submittedDate)
            },
            {
                label: "Submitted Amount",
                value: utils.TransformDBCurrency(notificationData.submittedAmount)
            },
            {
                label: "Asset Description",
                value: notificationData.assetDescription
            },
            {
                label: "Submission Type",
                value: notificationData.submissionType
            },
            {
                label: "Lender",
                value: notificationData.lenderName
            },
            {
                label: "Credit",
                value: notificationData.creditAnalyst
            },
            {
                label: "Personal Guarantor(s)",
                value: pgs,
                hidden: !pgs
            },
            {
                label: "Corporate Co-Applicant(s)",
                value: corpCoApp,
                hidden: !corpCoApp
            }
        ]
    }

    const approvalDetails = {
        title: "Approval Details",
        data: [
            {
                label: "Approval Date",
                value: utils.TransformNotificationDate(notificationData.approvalDate)
            },
            {
                label: "Approved Amount",
                value: utils.TransformDBCurrency(notificationData.approvedAmount)
            },
            {
                label: "Rate",
                value: `${notificationData.costOfFunds} %`,
                hidden: notificationData.submissionType === 'PAL'
            },
            {
                label: "Term",
                value: `${notificationData.term} months`,
                hidden: notificationData.submissionType === 'PAL'
            },
            {
                label: "Down Payment",
                value: (notificationData.downPayment !== 'Other') ? notificationData.downPayment : utils.TransformDBCurrency(notificationData.downPaymentAmount),
                hidden: notificationData.submissionType === 'PAL'
            },
            {
                label: "Expiry Date",
                value: utils.TransformNotificationDate(notificationData.expiryDate),
                hidden: !notificationData.expiryDate
            },
        ]
    }

    const preFundingRequirements = {
        title: "Pre-Funding Requirements",
        data: [
            {
                label: "",
                value: <ul>
                    <li>{notificationData.preFundingRequirements}</li>
                </ul>
            }
        ],
        hidden: !notificationData.preFundingRequirements
    }

    const approvalNotes = {
        title: "Approval Notes",
        data: [
            {
                label: "",
                value: <ul>
                    <li>{notificationData.approvalNotes}</li>
                </ul>
            }
        ]
    }

    return {
        submissionDetails: submissionDetails,
        approvalDetails: approvalDetails,
        preFundingRequirements: preFundingRequirements,
        approvalNotes: approvalNotes
    }
}