import Icon from '@ant-design/icons';
import { theme } from 'antd';

function CreditExpired() {
    const { token: { ap_userPrimaryColor, themeDangerColor } } = theme.useToken();
    return (
        <svg width="100" height="100" viewBox="0 0 132 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66 24.3749C67.6162 24.3749 69.1662 25.017 70.309 26.1597C71.4518 27.3025 72.0938 28.8525 72.0938 30.4687V73.1249H39.5938C37.9776 73.1249 36.4276 72.4829 35.2848 71.3401C34.142 70.1973 33.5 68.6473 33.5 67.0312C33.5 65.415 34.142 63.8651 35.2848 62.7223C36.4276 61.5795 37.9776 60.9374 39.5938 60.9374H59.9063V30.4687C59.9063 28.8525 60.5483 27.3025 61.6911 26.1597C62.8339 25.017 64.3839 24.3749 66 24.3749Z" fill={themeDangerColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M51.6106 1.61681C60.5664 -0.416169 69.8513 -0.528313 78.8536 1.28777C87.8558 3.10385 96.3711 6.80692 103.838 12.153C111.305 17.4991 117.555 24.3668 122.175 32.3036C126.795 40.2404 129.681 49.0662 130.643 58.1993C130.812 59.8069 130.335 61.4158 129.318 62.6721C128.301 63.9284 126.827 64.7292 125.219 64.8984C123.612 65.0675 122.003 64.5912 120.746 63.5741C119.49 62.557 118.689 61.0825 118.52 59.4749C117.476 49.5513 113.641 40.1265 107.461 32.2924C101.28 24.4583 93.0068 18.5355 83.5987 15.2099C74.1907 11.8843 64.033 11.2921 54.3023 13.5018C44.5715 15.7116 35.6659 20.6328 28.617 27.6956C21.568 34.7584 16.6642 43.6737 14.4736 53.4087C12.2829 63.1438 12.895 73.3003 16.2391 82.7018C19.5831 92.1033 25.5222 100.365 33.3684 106.53C41.2146 112.695 50.6468 116.511 60.5725 117.536C61.3744 117.611 62.1534 117.844 62.8647 118.222C63.5759 118.599 64.2053 119.114 64.7163 119.737C65.2274 120.359 65.61 121.076 65.8421 121.848C66.0741 122.619 66.1511 123.428 66.0684 124.229C65.9857 125.03 65.7451 125.807 65.3604 126.515C64.9757 127.222 64.4546 127.846 63.8272 128.351C63.1998 128.856 62.4785 129.232 61.7051 129.456C60.9317 129.68 60.1215 129.749 59.3213 129.659C43.9887 128.081 29.7157 121.106 19.0498 109.979C8.38383 98.852 2.01914 84.2966 1.09171 68.9111C0.164282 53.5255 4.73447 38.311 13.9866 25.9832C23.2386 13.6554 36.5705 5.01659 51.6025 1.60868L51.6106 1.61681Z" fill={themeDangerColor} />
            <path d="M84.0375 83.0374C85.1801 81.8963 86.7289 81.2553 88.3438 81.2553C89.9586 81.2553 91.5074 81.8963 92.65 83.0374L106.625 97.0124L120.6 83.0374C121.158 82.4387 121.831 81.9585 122.578 81.6255C123.326 81.2924 124.133 81.1133 124.951 81.0989C125.769 81.0844 126.582 81.235 127.34 81.5414C128.099 81.8479 128.789 82.3041 129.367 82.8828C129.946 83.4614 130.402 84.1507 130.709 84.9095C131.015 85.6682 131.166 86.481 131.151 87.2992C131.137 88.1174 130.958 88.9243 130.624 89.6718C130.291 90.4193 129.811 91.0921 129.213 91.6499L115.238 105.625L129.213 119.6C129.811 120.158 130.291 120.831 130.624 121.578C130.958 122.326 131.137 123.132 131.151 123.951C131.166 124.769 131.015 125.582 130.709 126.34C130.402 127.099 129.946 127.788 129.367 128.367C128.789 128.946 128.099 129.402 127.34 129.708C126.582 130.015 125.769 130.165 124.951 130.151C124.133 130.137 123.326 129.957 122.578 129.624C121.831 129.291 121.158 128.811 120.6 128.212L106.625 114.237L92.65 128.212C92.0921 128.811 91.4194 129.291 90.6719 129.624C89.9244 129.957 89.1175 130.137 88.2993 130.151C87.4811 130.165 86.6683 130.015 85.9095 129.708C85.1508 129.402 84.4615 128.946 83.8828 128.367C83.3042 127.788 82.848 127.099 82.5415 126.34C82.235 125.582 82.0845 124.769 82.099 123.951C82.1134 123.132 82.2925 122.326 82.6255 121.578C82.9586 120.831 83.4388 120.158 84.0375 119.6L98.0125 105.625L84.0375 91.6499C82.8964 90.5074 82.2554 88.9585 82.2554 87.3437C82.2554 85.7288 82.8964 84.18 84.0375 83.0374Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function CreditExpiredIcon(props) {
    return <Icon component={CreditExpired} {...props} />
}