import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Share() {
    return (
        <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["Share"]}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.2001 0C9.12846 0 9.05759 0.0066701 8.98816 0.0196925C8.76203 0.062094 8.55205 0.171743 8.38704 0.336708L4.937 3.78675C4.72752 4.00364 4.6116 4.29414 4.61422 4.59566C4.61684 4.89719 4.73779 5.18563 4.95101 5.39885C5.16423 5.61207 5.45267 5.73302 5.7542 5.73564C6.05573 5.73826 6.34623 5.62235 6.56312 5.41286L8.05009 3.9259V14.9499C8.05009 15.2549 8.17125 15.5474 8.38692 15.7631C8.60259 15.9788 8.8951 16.0999 9.2001 16.0999C9.5051 16.0999 9.79761 15.9788 10.0133 15.7631C10.2289 15.5474 10.3501 15.2549 10.3501 14.9499V3.9259L11.8371 5.41286C12.003 5.57307 12.2119 5.67855 12.4359 5.71813C12.5048 5.73032 12.575 5.73626 12.646 5.73564C12.9475 5.73302 13.236 5.61207 13.4492 5.39885C13.6624 5.18563 13.7834 4.89719 13.786 4.59566C13.7886 4.29414 13.6727 4.00364 13.4632 3.78675L10.0132 0.336708C9.7975 0.121114 9.50504 0 9.2001 0Z" className={styles["Inner"]} />
            <path d="M0 10.3499C0 9.73986 0.242323 9.15484 0.673661 8.7235C1.105 8.29216 1.69002 8.04984 2.30002 8.04984H7.05016V14.9499C7.05016 15.5201 7.27668 16.067 7.67989 16.4702C8.08309 16.8734 8.62995 17.0999 9.20017 17.0999C9.77039 17.0999 10.3173 16.8734 10.7205 16.4702C11.1237 16.067 11.3502 15.5201 11.3502 14.9499V8.04984H16.1002C16.7102 8.04984 17.2952 8.29216 17.7265 8.7235C18.1579 9.15484 18.4002 9.73986 18.4002 10.3499V20.7C18.4002 21.31 18.1579 21.895 17.7265 22.3263C17.2952 22.7577 16.7102 23 16.1002 23H2.30002C1.69002 23 1.105 22.7577 0.673661 22.3263C0.242323 21.895 0 21.31 0 20.7V10.3499Z" className={styles["Inner"]} />
            <path d="M8.38704 0.336708C8.6027 0.121114 8.89516 0 9.2001 0C9.50504 0 9.7975 0.121114 10.0132 0.336708L13.4632 3.78674C13.6727 4.00364 13.7886 4.29414 13.786 4.59566C13.7834 4.89719 13.6624 5.18563 13.4492 5.39885C13.236 5.61207 12.9475 5.73302 12.646 5.73564C12.3445 5.73826 12.054 5.62235 11.8371 5.41286L10.3501 3.9259V14.9499C10.3501 15.2549 10.2289 15.5474 10.0133 15.7631C9.79761 15.9788 9.5051 16.0999 9.2001 16.0999C8.8951 16.0999 8.60259 15.9788 8.38692 15.7631C8.17125 15.5474 8.05009 15.2549 8.05009 14.9499V3.9259L6.56312 5.41286C6.34622 5.62235 6.05573 5.73826 5.7542 5.73564C5.45267 5.73302 5.16423 5.61207 4.95101 5.39885C4.73779 5.18563 4.61684 4.89719 4.61422 4.59566C4.6116 4.29414 4.72752 4.00364 4.937 3.78674L8.38704 0.336708ZM0 10.3499C0 9.73986 0.242323 9.15484 0.673661 8.7235C1.105 8.29216 1.69002 8.04984 2.30002 8.04984H4.60005C4.90505 8.04984 5.19756 8.171 5.41323 8.38667C5.6289 8.60234 5.75006 8.89485 5.75006 9.19985C5.75006 9.50485 5.6289 9.79736 5.41323 10.013C5.19756 10.2287 4.90505 10.3499 4.60005 10.3499H2.30002V20.7H16.1002V10.3499H13.8001C13.4951 10.3499 13.2026 10.2287 12.987 10.013C12.7713 9.79736 12.6501 9.50485 12.6501 9.19985C12.6501 8.89485 12.7713 8.60234 12.987 8.38667C13.2026 8.171 13.4951 8.04984 13.8001 8.04984H16.1002C16.7102 8.04984 17.2952 8.29216 17.7265 8.7235C18.1579 9.15484 18.4002 9.73986 18.4002 10.3499V20.7C18.4002 21.31 18.1579 21.895 17.7265 22.3263C17.2952 22.7577 16.7102 23 16.1002 23H2.30002C1.69002 23 1.105 22.7577 0.673661 22.3263C0.242323 21.895 0 21.31 0 20.7V10.3499Z" className={styles["Outer"]} />
        </svg>
    )
}

export default function ShareIcon() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();

    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor
    };

    return <Icon component={Share} style={themeColorVariables} />
}