import * as CryptoJS from 'crypto-js';

export default class SecurityService {

  _secure_key = Symbol('protected');

  constructor() {
    this._secure_key = process.env.REACT_APP_SECRET_KEY
  }

  
  /**
   ** This function will encrypt requests before sending request to the server
   * @author Ishank Agarwal
   *
   * @param keys
   * @param value
   */
  encryptAES(keys, value) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  
  /**
   ** This function will decrypt response payloads coming from the server
   * @author Ishank Agarwal
   *
   * @param keys
   * @param value
   */
  async decryptAES(keys, value) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const decrypted = await CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return await decrypted.toString(CryptoJS.enc.Utf8);
  }
}
