import { theme } from "antd";
import fallbackTheme from '../../../../../constants/THEMES';

export default function SynergyCancelled() {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.320496 10C0.320496 4.47715 4.79765 0 10.3205 0H39.6795C45.2023 0 49.6795 4.47715 49.6795 10V40C49.6795 45.5228 45.2023 50 39.6795 50H10.3205C4.79764 50 0.320496 45.5228 0.320496 40V10Z" fill={themeDangerColor || fallbackTheme.light.themeDangerColor} />
            <path d="M8.4209 21.9902C8.4209 16.1474 8.4209 13.226 9.1115 12.2431C9.80028 11.262 12.5463 10.3211 18.0401 8.44113L19.0869 8.08304C21.9498 7.10194 23.3804 6.6123 24.8639 6.6123C26.3456 6.6123 27.778 7.10194 30.6409 8.08304L31.6877 8.44113C37.1815 10.3211 39.9275 11.262 40.6163 12.2431C41.3069 13.226 41.3069 16.1492 41.3069 21.9902V24.8659C41.3069 35.1665 33.5622 40.167 28.7024 42.2881C27.3852 42.8636 26.7274 43.1523 24.8639 43.1523C23.0004 43.1523 22.3426 42.8636 21.0254 42.2881C16.1656 40.1652 8.4209 35.1683 8.4209 24.8659V21.9902Z" stroke="white" strokeWidth="4" />
            <path d="M21.3822 21.2974C21.4801 21.1996 21.6128 21.1446 21.7513 21.1446C21.8897 21.1446 22.0224 21.1996 22.1203 21.2974L24.8833 24.0604L27.6462 21.2974C27.6944 21.2476 27.752 21.2078 27.8156 21.1804C27.8793 21.1531 27.9478 21.1387 28.0171 21.1381C28.0864 21.1375 28.1552 21.1507 28.2193 21.1769C28.2835 21.2032 28.3418 21.2419 28.3908 21.291C28.4398 21.34 28.4786 21.3982 28.5048 21.4624C28.531 21.5265 28.5443 21.5953 28.5437 21.6646C28.5431 21.7339 28.5287 21.8024 28.5013 21.8661C28.4739 21.9298 28.4342 21.9874 28.3843 22.0355L25.6214 24.7985L28.3843 27.5614C28.4794 27.6599 28.532 27.7917 28.5308 27.9286C28.5296 28.0655 28.4747 28.1964 28.378 28.2932C28.2812 28.39 28.1502 28.4449 28.0134 28.446C27.8765 28.4472 27.7447 28.3946 27.6462 28.2995L24.8833 25.5366L22.1203 28.2995C22.0219 28.3946 21.89 28.4472 21.7531 28.446C21.6163 28.4449 21.4853 28.39 21.3886 28.2932C21.2918 28.1964 21.2369 28.0655 21.2357 27.9286C21.2345 27.7917 21.2871 27.6599 21.3822 27.5614L24.1452 24.7985L21.3822 22.0355C21.2843 21.9376 21.2294 21.8049 21.2294 21.6665C21.2294 21.5281 21.2843 21.3953 21.3822 21.2974Z" fill="white" stroke="white" strokeWidth="3" />
        </svg>
    );
}