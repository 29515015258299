import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Inspection = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M80.2951 75.2124L80.2952 75.2123L80.289 75.2062L63.9411 58.8582C66.257 55.7639 67.8325 52.1771 68.5434 48.374C69.2814 44.4262 69.0666 40.3592 67.9167 36.5111C66.7668 32.6631 64.7151 29.145 61.9322 26.2493C59.1492 23.3536 55.7153 21.1639 51.9159 19.8622C48.1165 18.5606 44.0612 18.1845 40.0872 18.7653C36.1132 19.346 32.3352 20.8669 29.0672 23.2014C25.7992 25.5359 23.1355 28.6167 21.2976 32.1877C19.4597 35.7587 18.5006 39.7168 18.5 43.733C18.4995 48.4557 19.8242 53.0838 22.3234 57.091C24.8227 61.0982 28.3962 64.3237 32.6377 66.4007C36.8791 68.4778 41.6183 69.3229 46.3163 68.8402C50.8678 68.3725 55.2036 66.6765 58.8618 63.9376L75.2097 80.2855L75.2097 80.2856L75.2159 80.2916C75.8934 80.9459 76.8008 81.308 77.7426 81.2998C78.6844 81.2916 79.5854 80.9138 80.2514 80.2478C80.9174 79.5818 81.2951 78.6809 81.3033 77.7391C81.3115 76.7972 80.9495 75.8899 80.2951 75.2124ZM30.9716 30.968C34.3571 27.5826 38.9488 25.6806 43.7366 25.6806C48.5244 25.6806 53.1161 27.5826 56.5016 30.968C59.8871 34.3535 61.789 38.9452 61.789 43.733C61.789 48.5209 59.8871 53.1126 56.5016 56.498C53.1161 59.8835 48.5244 61.7855 43.7366 61.7855C38.9488 61.7855 34.3571 59.8835 30.9716 56.498C27.5861 53.1126 25.6841 48.5209 25.6841 43.733C25.6841 38.9452 27.5861 34.3535 30.9716 30.968Z" fill="white" stroke="white" />
        </svg>
    );
};

export default function InspectionIcon(props) {
    return <Icon component={Inspection} {...props} />
}