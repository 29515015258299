import { useState } from "react";

import { Button, Drawer } from "antd";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import InputLayout from "../InputLayout";

import utils from "../../utils";
import HasError from "../../utils/HasErrors";
import validate from "../../utils/Validations";

import generateClientProfileFields from "./constants";
import constants from "../../constants";
import dayjs from "dayjs";

import styles from "./index.module.css";
import { ExclamationCircleFilled } from "@ant-design/icons";

export default function EditClients({ clientId, setRefresh, setLoading, setEdited }) {
    const [openClient, setOpenClient] = useState(false);
    const [formData, setFormData] = useState(generateClientProfileFields());
    const handleDrawer = () => {
        setOpenClient(!openClient);
    }

    // Event Handlers
    const handleBusinessName = (e) => {
        setFormData({
            ...formData,
            ap_businessName: {
                ...formData.ap_businessName,
                value: e.target.value,
                error: formData.ap_businessName.required ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            },
        });
    }

    const handleIndustry = (industry) => {
        setFormData({
            ...formData,
            ap_industry: {
                ...formData.ap_industry,
                value: industry,
                error: validate.ValidateRequiredField(industry).errorMessage,
            }
        });
    }

    const handleType = (type) => {
        setFormData({
            ...formData,
            ap_type: {
                ...formData.ap_type,
                value: type,
                error: formData.ap_type.required ? validate.ValidateRequiredField(type).errorMessage : '',
            }
        });
    }

    const handleCompanyType = (companyType) => {
        setFormData({
            ...formData,
            ap_companyType: {
                ...formData.ap_companyType,
                value: companyType,
                error: formData.ap_companyType.required ? validate.ValidateRequiredField(companyType).errorMessage : '',
            }
        });
    }

    const handleDateOfIncorporation = (date, dateString) => {
        setFormData({
            ...formData,
            ap_dateOfIncorporation: {
                ...formData.ap_dateOfIncorporation,
                value: dayjs(dateString, 'DD-MM-YYYY'),
            }
        });
    }

    const handleFiledFSYearEnd = (date, dateString) => {
        setFormData({
            ...formData,
            ap_filedFsYearEnd: {
                ...formData.ap_filedFsYearEnd,
                value: dayjs(dateString, 'DD-MM-YYYY'),
            }
        });
    }

    const handleEmail = (e) => {
        setFormData({
            ...formData,
            ap_email: {
                ...formData.ap_email,
                value: e.target.value,
                error: !!validate.ValidateRequiredField(e.target.value).errorMessage ? validate.ValidateRequiredField(e.target.value).errorMessage : (!validate.EmailValidation(e.target.value) ? 'Invalid Email Address' : ''),
            },
        });
    }

    const handleTelephone = (e) => {
        setFormData({
            ...formData,
            ap_telephone: {
                ...formData.ap_telephone,
                value: validate.FormatTelephone(e.target.value).input,
                error: validate.FormatTelephone(e.target.value).error,
            }
        });
    }

    const handleAddress = (e) => {
        setFormData({
            ...formData,
            ap_address: {
                ...formData.ap_address,
                value: e.target.value,
                error: '',
            }
        });
    }

    const handleCity = (e) => {
        setFormData({
            ...formData,
            ap_city: {
                ...formData.ap_city,
                value: e.target.value,
                error: '',
            }
        });
    }

    const handleProvinceStates = (provinceStates) => {
        setFormData({
            ...formData,
            ap_stateOrProvince: {
                ...formData.ap_stateOrProvince,
                value: provinceStates,
                error: formData.ap_stateOrProvince.required ? validate.ValidateRequiredField(provinceStates).errorMessage : '',
            }
        });
    }

    const handlePostalCode = (e) => {
        setFormData({
            ...formData,
            ap_postalCode: {
                ...formData.ap_postalCode,
                value: e.target.value,
                error: '',
            }
        });
    }

    const handleCountry = (country) => {
        setFormData({
            ...formData,
            ap_country: {
                ...formData.ap_country,
                value: country,
                error: formData.ap_country.required ? validate.ValidateRequiredField(country).errorMessage : '',
            },
            ap_stateOrProvince: {
                ...formData.ap_stateOrProvince,
                dropdownContent: country === 'Canada' ? constants.CANADIAN_PROVINCES : constants.AMERICAN_STATES,
            }
        });
    }

    const resetForm = () => {
        // Reset form values
        setFormData({
            ...formData,
            ap_businessName: {
                ...formData?.ap_businessName,
                value: '',
                error: '',
            },
            ap_industry: {
                ...formData?.ap_industry,
                value: null,
                error: '',
            },
            ap_type: {
                ...formData?.ap_type,
                value: null,
                error: '',
            },
            ap_companyType: {
                ...formData.ap_companyType,
                value: null,
                error: '',
            },
            ap_dateOfIncorporation: {
                ...formData?.ap_dateOfIncorporation,
                value: '',
                error: '',
            },
            ap_filedFsYearEnd: {
                ...formData?.ap_filedFsYearEnd,
                value: '',
                error: '',
            },
            ap_email: {
                ...formData?.ap_email,
                value: '',
                error: '',
            },
            ap_telephone: {
                ...formData?.ap_telephone,
                value: '',
                error: '',
            },
            ap_address: {
                ...formData?.ap_address,
                value: '',
                error: '',
            },
            ap_city: {
                ...formData?.ap_city,
                value: '',
                error: '',
            },
            ap_stateOrProvince: {
                ...formData?.ap_stateOrProvince,
                value: '',
                error: '',
            },
            ap_postalCode: {
                ...formData?.ap_postalCode,
                value: '',
                error: '',
            },
            ap_country: {
                ...formData?.ap_country,
                value: 'Canada',
                error: '',
            }
        });
    }

    const handleSave = () => {
        setLoading(true);
        if (!HasError(formData)) {
            const data = {
                ap_businessName: formData?.ap_businessName.value,
                ap_industry: formData?.ap_industry.value,
                ap_type: formData?.ap_type.value,
                ap_companyType: formData?.ap_companyType.value,
                ap_dateOfIncorporation: formData?.ap_dateOfIncorporation?.value ? dayjs(formData?.ap_dateOfIncorporation?.value).format('DD-MM-YYYY') : '',
                ap_filedFsYearEnd: formData?.ap_filedFsYearEnd?.value ? dayjs(formData?.ap_filedFsYearEnd?.value).format('DD-MM-YYYY') : '',
                ap_email: formData?.ap_email.value,
                ap_telephone: utils.TransformTelephone(formData?.ap_telephone.value),
                ap_address: formData?.ap_address.value,
                ap_city: formData?.ap_city.value,
                ap_province: formData?.ap_stateOrProvince.value,
                ap_postalCode: formData?.ap_postalCode.value,
                ap_country: formData?.ap_country.value,
                ap_responsibleType: '',
                ap_lastModifiedBy: "beacon",
            }

            ApiService.sendUpdateRequest(clientId, data).then((resp) => {
                if (!!resp.success) {
                    NotificationService.success('Client Update Request Sent Successfully');
                    setRefresh(true);
                    setLoading(false);
                    handleDrawer();
                    resetForm();
                    setEdited(true);
                } else {
                    NotificationService.error('Client Updatation Failed');
                    setLoading(false);
                }
            }
            ).catch((err) => {
                NotificationService.error('Client Updation Failed');
                setLoading(false);
            });

        }
    }

    // Add Event Handlers to Form 
    const clientProfileForm = {
        ...formData,
        ap_businessName: {
            ...formData.ap_businessName,
            eventHandler: handleBusinessName,
        },
        ap_industry: {
            ...formData.ap_industry,
            eventHandler: handleIndustry,
        },
        ap_type: {
            ...formData.ap_type,
            eventHandler: handleType,
        },
        ap_companyType: {
            ...formData.ap_companyType,
            eventHandler: handleCompanyType,
        },
        ap_dateOfIncorporation: {
            ...formData?.ap_dateOfIncorporation,
            eventHandler: handleDateOfIncorporation,
        },
        ap_filedFsYearEnd: {
            ...formData?.ap_filedFsYearEnd,
            eventHandler: handleFiledFSYearEnd,
        },
        ap_email: {
            ...formData.ap_email,
            eventHandler: handleEmail,
        },
        ap_telephone: {
            ...formData.ap_telephone,
            eventHandler: handleTelephone,
        },
        ap_address: {
            ...formData.ap_address,
            eventHandler: handleAddress,
        },
        ap_city: {
            ...formData.ap_city,
            eventHandler: handleCity,
        },
        ap_stateOrProvince: {
            ...formData.ap_stateOrProvince,
            eventHandler: handleProvinceStates,
        },
        ap_postalCode: {
            ...formData.ap_postalCode,
            eventHandler: handlePostalCode,
        },
        ap_country: {
            ...formData.ap_country,
            eventHandler: handleCountry,
        }
    }

    return (
        <>
            <div onClick={handleDrawer}>
                <Button
                    type="primary"
                    className={styles["editButton"]}
                >Edit</Button>
            </div>

            <Drawer
                width="550px"
                title="Edit Client"
                open={openClient}
                onClose={handleDrawer}
                destroyOnClose={true}
                getContainer=".AppTheme"
            >

                <div className={styles["AutoFill_AlertContainer"]}>
                    <ExclamationCircleFilled className={styles["ExclamationCircle"]} />
                    <div className={styles["Edit_AlertText"]}>
                        This client already exists in our system. If you would like to make changes to their information, please fill out this form and someone from our team will review the amendment.
                    </div>
                </div>

                <InputLayout
                    layout="vertical"
                    data={clientProfileForm}
                />

                <div className={styles["SaveButton"]}>
                    <Button
                        type="primary"
                        onClick={handleSave}
                    >Save</Button>
                </div>
            </Drawer>
        </>
    );

}