import { theme } from "antd";
import { useState } from "react";

export default function NextIcon({ enabled }) {
    const { token: { themeSecondaryColor, ap_userSecondaryColor, userSecondaryHoverColor } } = theme.useToken();

    const [hovered, setHovered] = useState(false);
    const onHover = () => setHovered(true);
    const onUnhover = () => setHovered(false);
    const iconColor = (hovered && enabled) ? userSecondaryHoverColor : (enabled ? ap_userSecondaryColor : themeSecondaryColor);
    return (
        <div
            onMouseEnter={onHover}
            onMouseLeave={onUnhover}
            style={containerStyle}
        >
            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 8.81818C10.5 9.01386 10.4618 9.20367 10.3855 9.38761C10.3091 9.57155 10.2074 9.72417 10.0801 9.8455L3.05725 16.5964C2.77735 16.8655 2.42111 17 1.98855 17C1.55598 17 1.19974 16.8655 0.919846 16.5964C0.639948 16.3274 0.5 15.9849 0.5 15.5691C0.5 15.1533 0.639948 14.8108 0.919846 14.5418L6.87403 8.81818L0.919846 3.09458C0.639948 2.82552 0.5 2.48308 0.5 2.06727C0.5 1.65145 0.639948 1.30901 0.919846 1.03995C1.19974 0.770895 1.55598 0.636364 1.98855 0.636364C2.42111 0.636364 2.77735 0.770895 3.05725 1.03995L10.0801 7.79087C10.2328 7.93763 10.3412 8.09662 10.4053 8.26784C10.4694 8.43906 10.501 8.6225 10.5 8.81818Z" fill={iconColor} />
            </svg>
        </div>
    );
}

const containerStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}