export default [
    {
        label: 'Construction Equipment',
        value: 'Construction Equipment',
    },
    {
        label: 'Dump Truck',
        value: 'Dump Truck',
    },
    {
        label: 'Reefers',
        value: 'Reefers',
    },
    {
        label: 'Trailers',
        value: 'Trailers',
    },
    {
        label: 'Trucks',
        value: 'Trucks',
    },
    {
        label: 'Other',
        value: 'Other',
    },
    {
        label: 'Amount (PAL)',
        value: 'Amount (PAL)',
    },
    {
        label: 'Amount (Working Capital)',
        value: 'Amount (Working Capital)',
    },
];