import { useNavigate } from "react-router-dom";
import { Button, theme } from "antd";

import generateDetails from "./constants";

import NotificationDetailsBlock from "../../NotificationDetailBlock";
import validate from "../../../utils/Validations";

import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";

import styles from "./index.module.css";
import utils from "../../../utils";

export default function DealFunded({ notificationData }) {

    const navigate = useNavigate();

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const clientTelephone = `tel:${notificationData.ap_data.clientTelephone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`
    // const clientCity = [notificationData.ap_data.clientCity, notificationData.ap_data.clientStateOrProvince].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');
    // const lenderCityProvince = [notificationData.ap_data.lenderCity, notificationData.ap_data.lenderStateOrProvince].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const details = generateDetails(notificationData.ap_data);
    const invoiceLink = notificationData?.ap_data?.invoiceLink;

    return (
        <div className={styles["NotificationsBody"]}>
            <div><NotificationScreenIcons.DealFundedIcon /></div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: ap_userPrimaryColor }}
            >DEAL FUNDED</div>

            <div>
                Deal for <span className={styles["NotificationTitleInfo"]}>{notificationData.ap_data.clientName} - {notificationData.ap_data.lenderName}</span> has been fully funded.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData.ap_data.clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock data={details.drawdownDetails} />
                    <NotificationDetailsBlock data={details.fundingDetails} />
                </div>
            </div>

            <div className={styles["InstructionsTitle"]} style={{ color: ap_userPrimaryColor }}>Next Steps</div>
            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>
                You should receive payment for your fee of {utils.TransformDBCurrency(notificationData.ap_data.agentFee + notificationData.ap_data.agentFeeTax)} withing 1-2 business days, confirmed by a separate notification. Please see the attached invoice for details.
            </div>

            <div style={invoiceLink ? { padding: "3%" } : { display: 'none', margin: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => window.location.href = `${invoiceLink}`}
                    style={{ height: "40px" }}
                >See Invoice</Button>
            </div>
        </div>
    );
}