import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EmptyRequirement = () => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="66" height="72" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8477 37.1724C14.8477 28.5391 21.8465 21.5402 30.4798 21.5402C39.1132 21.5402 46.112 28.5391 46.112 37.1724V62.1839H14.8477V37.1724Z" fill="white" stroke="#D9D9D9" strokeWidth="4" strokeLinejoin="round"/>
            <path d="M30.4799 5.90804V10.5977" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M49.0693 12.674L46.0548 16.2665" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.96 29.8054L54.3414 30.6199" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 29.8056L6.6184 30.6199" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.46838 5.90804L58.6178 70" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.34192 62.1839H60.181" stroke="#D9D9D9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.9741 33.2644C17.9741 24.631 24.973 17.6322 33.6063 17.6322C42.2396 17.6322 49.2385 24.631 49.2385 33.2644V58.2759H17.9741V33.2644Z" fill="white" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinejoin="round"/>
            <path d="M33.6063 2V6.68966" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.1958 8.76595L49.1813 12.3585" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.0864 25.8974L57.4679 26.7118" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.12646 25.8976L9.74487 26.7118" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.59485 2L61.7443 66.092" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.46838 58.2759H63.3075" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default function EmptyRequirementIcon (props) {
    return <Icon component={EmptyRequirement} {...props}/>
}