import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spin } from "antd";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";

import Requirement from "../../layouts/Requirement";
import Error404 from "../../layouts/Error404";

import ApiService from "../../api";
import CopyableRequirementEmail from "../CopyableRequirementEmail";

export default function IndividualRequirement() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [headerName, setHeaderName] = useState('');
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showError, setShowError] = useState(false);
    const [requirementData, setRequirementData] = useState({});
    const [isSingningAuditing, setIsSigningAuditing] = useState(false);
    const [isOwnershipRequest, setIsOwnershipRequest] = useState(false);
    const [requestEmail, setRequestEmail] = useState(false);
    const [notificationId, setNotificationId] = useState(null);

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        ApiService.fetchIndividualRequirement(id).then(response => {
            setShowError(!response);
            setRequirementData(!response ? {} : response);
            const signingAuditingExists = response?.ap_requirementsList?.some(item => item?.crm_requirementType === "Signing/Auditing");
            const ownershipRequestExists = response?.ap_requirementsList?.some(item => item?.crm_requirementType === "Ownership Request");

            // Set either setIsSigningAuditing or setIsOwnershipRequest based on the condition
            if (signingAuditingExists) {
                setIsSigningAuditing(true);
                setIsOwnershipRequest(false);
            } else if (ownershipRequestExists) {
                setIsSigningAuditing(false);
                setIsOwnershipRequest(true);
            } else {
                setIsSigningAuditing(false);
                setIsOwnershipRequest(false);
            }

            setLoading(false);
        });

    }, [id, refresh]);

    useEffect(() => {
        if (!isSingningAuditing && !isOwnershipRequest) return;

        let notificationType;

        if (isOwnershipRequest) notificationType = "ownershipRequest";
        if (isSingningAuditing) notificationType = "signingAuditing";

        // Fetch notification id
        ApiService.fetchNotificationId(requirementData.ap_entityId, notificationType).then(response => {
            setNotificationId(response[0]?._id);
        });

    }, [isSingningAuditing, isOwnershipRequest, requirementData]);

    const requirementsList = requirementData?.ap_requirementsList?.filter(item => !item?.crm_confirmed && !item?.checked).map(item => item?.name);

    return (
        <HomeContent
            contentHeader={
                <>
                    {showError ?
                        <></> :
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ display: 'flex', width: '100%' }}>
                                <GoBackButton />
                                <div>{headerName}</div>
                            </div>

                            <div style={{ display: 'flex', gap: '10px' }}>
                                {notificationId ? <Button
                                    type="primary"
                                    onClick={() => {
                                        navigate(`/notifications/${notificationId}`);
                                    }}
                                    style={(requirementsList?.length > 0) ? {} : { display: 'none' }}
                                >See Notification</Button> : <></>}
                                <Button
                                    type="primary"
                                    onClick={() => setRequestEmail(prev => !prev)}
                                    style={(requirementsList?.length > 0) ? {} : { display: 'none' }}
                                >{requestEmail ? "Back to Submission" : "Generate Email"}</Button>
                            </div>
                        </div>
                    }
                </>
            }
            content={<>
                {
                    showError ?
                        <div style={{ maxHeight: '73vh', overflowY: 'hidden' }
                        } >
                            <Error404 />
                        </div > :
                        <>
                            {
                                requestEmail ?
                                    <CopyableRequirementEmail /> :
                                    <Spin spinning={loading} tip="Loading...">
                                        <Requirement
                                            setHeaderName={setHeaderName}
                                            requirementData={requirementData}
                                            setRefresh={setRefresh}
                                        />
                                    </Spin>
                            }
                        </>
                }
            </>}
            bgColor="transparent"
        />
    );
}