import React, { useState } from 'react';
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { Button, ConfigProvider, Divider, Input, theme } from 'antd';
import NotificationService from "../../services/notification.service";
import ApiService from "../../api";

export default function RegistrationRegenerateLayout() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);

    const handleSendCode = () => {
        setSuccessMessage('');

        if (!email) {
            NotificationService.error('error', 'Please enter your email address.');
            return;
        }

        const data = {
            email_address: email,
        };

        ApiService.generateResetPasswordCode(data).then(response => {
            if (response?.status === 'success') {
                setSuccessMessage("If a user with that email exists, you will receive a email soon");
                setIsCodeSent(true);
            } else {
                setSuccessMessage('');
                setIsCodeSent(false);
            }
        });
    }

    return (
        <div className={styles["Container"]}>
            <div className={styles["emptyContainer"]} />
            <div className={styles["rightLayout"]}>
                <h1>Regenerate Registration</h1>
                <p>
                    Please enter your email address below. We will send you a registration link that you can use to register.
                </p>
                <div className={styles["emailInput"]}>
                    <label style={{ color: '#447EB7' }}>Email</label>
                    <Input
                        style={{ width: '350px' }}
                        placeholder="Email Address"
                        value={email}
                        size='large'
                        onChange={(e) => { setEmail(e.target.value); }}
                    />
                </div>
                {successMessage}
                <Button
                    type='primary'
                    className={styles["sendCodeButton"]}
                    onClick={handleSendCode}
                    disabled={isCodeSent}
                >
                    Regenerate
                </Button>
                <div style={{ display: 'flex', justifyContent: 'center', width: "30%" }}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorSplit: 'black'
                            },
                        }}
                    >
                        <Divider className={styles["divider"]} />
                    </ConfigProvider>
                </div>
               <Button type='primary' className={styles["backToLoginButton"]} onClick={() => navigate("/")}>Back to Login</Button>
            </div>
        </div>
    )
}
