// Import necessary components and dependencies
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd";

import InputLayout from "../InputLayout";
import CrossIcon from "../../assets/images/icons/CrossIcon";

import ApiService from "../../api";

import pgConstants from "./constants";
import pgUtility from "./utils";
import utils from "../../utils";
import styles from "./index.module.css";
import EditIcon from "../../assets/images/icons/HomeTasks/EditTaskIcon.js";

export default function PGSnCoApps({
    applicationDraftId,
    applicationId,
    validatePg,
    pgData,
    setPgErrorExists,
    setPgData,
    refreshPgs,
    setRefreshPgs,
    applicationData,
    coApps,
    setCoApps,
    setCoAppErrorExists,
    clientId,
    contactList,
    isAppOpen,
    setLoader,
    setUnsaved,
}) {

    const { EditTaskHoverIcon, EditTaskIcon } = EditIcon;

    const [pgDataObj, setPgDataObj] = useState({});
    const [pgDetails, setPgDetails] = useState([]);

    const [clientList, setClientList] = useState([]);
    const [coAppDataObj, setCoAppDataObj] = useState({});
    const [coAppDetails, setCoAppDetails] = useState([]);
    const [showEditSIN, setShowEditSIN] = useState("none");
    const [disableSIN, setDisableSIN] = useState([]);
    const [isBtnHovered, setIsBtnHovered] = useState(false);

    const location = useLocation();
    const hideSIN = !(location?.pathname?.startsWith('/applications/new') || location?.pathname?.startsWith('/applications/draft'));

    // Fetch agent's client list
    useEffect(() => {
        const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;
        setLoader({ loading: true, text: 'Fetching client list...' });
        ApiService.fetchAgentClient(agentId).then((response) => {
            if (!response) {
                setLoader({ loading: false, text: '' });
                return;
            }
            setClientList(response);
            setLoader({ loading: false, text: '' });
        });
    }, []);

    // TODO: Fetch Existing PGs and Co-Applicants
    useEffect(() => {
        setPgData([]);
        setCoApps([]);

        if (!clientId) return;

        // Fetch All Draft PGs
        if (applicationDraftId) {
            setLoader({ loading: true, text: 'Fetching PG Drafts...' });
            ApiService.fetchAllAvailablePGDrafts(applicationDraftId)
                .then((response) => {
                    if (response && response.length > 0)
                        setPgData(response);
                    setLoader({ loading: false, text: '' });
                });
        } else if (applicationId) {
            setLoader({ loading: true, text: 'Fetching PGs...' });
            ApiService.fetchAllAvailablePG(applicationId)
                .then((result) => {
                    setLoader({ loading: false, text: '' });
                    if (result && result.length > 0)
                        setPgData(result);

                    const newDisableSINArr = result?.filter(pg => pg?._id)?.map(pg => pg._id) || [];
                    setDisableSIN(newDisableSINArr);
                });
        }
    }, [applicationId, applicationDraftId, clientId, refreshPgs]);

    useEffect(() => {
        const coApp1 = applicationData?.ap_corporateCoApplicant1;
        const coApp2 = applicationData?.ap_corporateCoApplicant2;
        const coApp3 = applicationData?.ap_corporateCoApplicant3;

        const parsedCoApps = [];
        if (coApp1)
            parsedCoApps.push({
                ap_clientId: coApp1,
                name: applicationData?.ap_corporateCoApplicant1?.ap_businessName,
                existing: Boolean(applicationId)
            });

        if (coApp2)
            parsedCoApps.push({
                ap_clientId: coApp2,
                name: applicationData?.ap_corporateCoApplicant2?.ap_businessName,
                existing: Boolean(applicationId)
            });

        if (coApp3)
            parsedCoApps.push({
                ap_clientId: coApp3,
                name: applicationData?.ap_corporateCoApplicant3?.ap_businessName,
                existing: Boolean(applicationId)
            });

        setCoApps(parsedCoApps);
    }, [applicationData]);

    // Transform PGs into an object
    useEffect(() => {
        if (!pgData || pgData.length === 0) {
            setPgDataObj({});
            return;
        }

        const pgObj = pgData.reduce((acc, pg, index) => {
            acc[`pg${index + 1}`] = pg;
            return acc;
        }, {});

        setPgDataObj(pgObj);
    }, [pgData]);

    // Transfrom Co-Applicants into an object
    useEffect(() => {
        if (!coApps || coApps.length === 0) {
            setCoAppDataObj({});
            return;
        }

        const coAppObj = coApps.reduce((acc, coApp, index) => {
            acc[`coApp${index + 1}`] = coApp;
            return acc;
        }, {});

        setCoAppDataObj(coAppObj);
    }, [coApps])

    // Generate fields for PGs
    useEffect(() => {
        const filterObj = Object.values(pgDataObj).filter(item => !item?.ap_deleted);
        const allFields = pgConstants.PGDetails(filterObj, contactList, '', isAppOpen);
        allFields.forEach((pg, index) => {
            pg.contact.eventHandler = (value) => pgUtility.handleContact(value, index, setPgData, setUnsaved);
            pg.sin.eventHandler = (e) => pgUtility.handleSin(e.target.value, index, setPgData, setUnsaved);
            pg.birthDate.eventHandler = (date, dateString) => pgUtility.handleDOB(dateString, index, setPgData, setUnsaved);
            pg.drivingExperience.eventHandler = (e) => pgUtility.handleDrivingExperience(e.target.value, index, setPgData, setUnsaved);
            pg.everBankrupt.eventHandler = (e) => pgUtility.handleEverBankrupt(e.target.value, index, setPgData, setUnsaved);
            pg.everRepossessed.eventHandler = (e) => pgUtility.handleEverRespossessed(e.target.value, index, setPgData, setUnsaved);
            pg.defandantInLegalAction.eventHandler = (e) => pgUtility.handleDefandentInLegalAction(e.target.value, index, setPgData, setUnsaved);
            pg.homeowner.eventHandler = (e) => pgUtility.handleHomeowner(e.target.value, index, setPgData, setUnsaved);
            pg.residentfor2Years.eventHandler = (e) => pgUtility.handleResidentFor2Years(e.target.value, index, setPgData, setUnsaved);
        });
        setPgDetails(allFields);
    }, [pgDataObj, contactList]);

    // Generate fields for Co-Applicants
    useEffect(() => {
        const allFields = pgConstants.CoAppDetails(coAppDataObj, clientList, '', isAppOpen);
        allFields.forEach((coApp, index) => {
            coApp.client.eventHandler = (value) => pgUtility.handleClient(value, index, setCoApps, setUnsaved);
        });
        setCoAppDetails(allFields);
    }, [coAppDataObj, clientList]);


    // Validate PGs and Co-Applicants
    useEffect(() => {
        if (!validatePg) return;
        const filterObj = Object.values(pgDataObj).filter(item => !item?.ap_deleted);
        const allFields = pgConstants.PGDetails(filterObj, contactList, true, isAppOpen);
        allFields.forEach((pg, index) => {
            pg.contact.eventHandler = (value) => pgUtility.handleContact(value, index, setPgData, setUnsaved);
            pg.sin.eventHandler = (e) => pgUtility.handleSin(e.target.value, index, setPgData, setUnsaved);
            pg.birthDate.eventHandler = (date, dateString) => pgUtility.handleDOB(dateString, index, setPgData, setUnsaved);
            pg.drivingExperience.eventHandler = (e) => pgUtility.handleDrivingExperience(e.target.value, index, setPgData, setUnsaved);
            pg.everBankrupt.eventHandler = (e) => pgUtility.handleEverBankrupt(e.target.value, index, setPgData, setUnsaved);
            pg.everRepossessed.eventHandler = (e) => pgUtility.handleEverRespossessed(e.target.value, index, setPgData, setUnsaved);
            pg.defandantInLegalAction.eventHandler = (e) => pgUtility.handleDefandentInLegalAction(e.target.value, index, setPgData, setUnsaved);
            pg.homeowner.eventHandler = (e) => pgUtility.handleHomeowner(e.target.value, index, setPgData, setUnsaved);
            pg.residentfor2Years.eventHandler = (e) => pgUtility.handleResidentFor2Years(e.target.value, index, setPgData, setUnsaved);
        });
        setPgErrorExists(utils.CheckErrorsInFields(allFields));
        setPgDetails(allFields);


        //CoApps
        const allCoAppFields = pgConstants.CoAppDetails(coAppDataObj, clientList, true, isAppOpen);
        allCoAppFields.forEach((coApp, index) => {
            coApp.client.eventHandler = (value) => pgUtility.handleClient(value, index, setCoApps, setUnsaved);
        });
        setCoAppErrorExists(utils.CheckErrorsInFields(allCoAppFields));
        setCoAppDetails(allCoAppFields);

    }, [validatePg]);


    const deletePG = (pg) => {
        const id = pg?.pgId?.value;

        setPgData(prev => {

            const newPgData = prev.map(item => 
                String(item?._id) === String(id) ? { ...item, ap_deleted: true } : item
            );
            return newPgData;
        });

        setPgDetails(prev => {
            return prev?.filter((item) => !(item?.pgId?.value === id));
        });
    };

    const handleEditSIN = (pg) => {
        setPgDetails(prev =>
            prev.map(item =>
                item?.pgId?.value === pg.pgId.value
                    ? { ...item, sin: { ...item.sin, value: '' } }
                    : item
            )
        );
        setDisableSIN(disableSIN.filter((id) => id !== pg.pgId.value));
        setShowEditSIN("none");
        setIsBtnHovered(false);
    };

    return (
        <div className={styles["Container"]} id="PgCoAppContainer">
            <div className={styles["HeaderContent"]}>
                <div className={`${styles['Header']}`}>Personal Guarantors & Co-Applicants</div>
                <div className={styles["HeaderButtons"]}>
                    <Button
                        type="primary"
                        disabled={pgData?.length >= 4 || !isAppOpen}
                        className={styles["ActionButton"]}
                        onClick={() => pgUtility.handleAddPGs(setPgData, clientId, contactList, setUnsaved)}
                    >+ Personal Guarantor</Button>
                    <Button
                        type="primary"
                        className={styles["ActionButton_CoApp"]}
                        disabled={coApps?.length >= 3 || !isAppOpen}
                        onClick={() => pgUtility.handleAddCoApps(setCoApps, clientList, setUnsaved)}
                    >+ Co-Applicant</Button>
                </div>
            </div>

            {pgDetails?.map((pg, index) => {
                // Masking SIN for saved applications only.
                pg.sin.disabled = (hideSIN && pg?.pgId?.value && disableSIN?.some(id => id === pg.pgId.value));

                const { radioFields, otherFields } = Object.entries(pg).reduce((acc, [key, value]) => {
                    acc[value.inputType === 'themed-radio' ? 'radioFields' : 'otherFields'][key] = value;
                    return acc;
                }, { radioFields: {}, otherFields: {} });

                return (
                    <>
                        {(!pg?.contact?.disabled && isAppOpen) && <div className={styles["DeletePG"]} ><CrossIcon /*onClick={() => pgUtility.handleDeletePG(index, setPgData, pgData, setRefreshPgs)}*/ onClick={() => deletePG(pg)} /></div>}
                        <div key={index} className={styles["pgContainer"]}>

                            <div className={styles["pg-OtherFields"]}>
                                {Object.keys(otherFields).map((key, index2) => {
                                    const eachData = { [key]: pg[key] }

                                    return key !== 'sin' || !pg.sin.disabled ? (
                                        <InputLayout
                                          key={index2}
                                          data={eachData}
                                          layout="vertical"
                                        />
                                      ) : (
                                        <div
                                          className={styles['SIN_container']}
                                          onMouseEnter={() => setShowEditSIN(pg.pgId.value)}
                                          onMouseLeave={() => setShowEditSIN("none")}
                                        >
                                          <InputLayout
                                            key={index2}
                                            data={eachData}
                                            layout="vertical"
                                          />
                                          {showEditSIN === pg.pgId.value && (
                                            <Button
                                              type="text"
                                              size="small"
                                              icon={isBtnHovered ? <EditTaskHoverIcon /> : <EditTaskIcon />}
                                              onClick={() => handleEditSIN(pg)}
                                              className={styles['SIN_edit']}
                                              onMouseEnter={() => setIsBtnHovered(true)}
                                              onMouseLeave={() => setIsBtnHovered(false)}
                                            />
                                          )}
                                        </div>
                                      );
                                })}
                            </div>

                            <div className={styles["pg-radioFields"]}>
                                {Object.keys(radioFields).map((key, index2) => {
                                    const eachData = { [key]: pg[key] }
                                    return (
                                        <InputLayout
                                            key={index2}
                                            data={eachData}
                                            layout='vertical'
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )
            })}

            {coAppDetails?.map((coApp, index) => {
                return (
                    <>
                        <div key={index} className={styles["pgContainer"]}>
                            <div className={styles["IndividualCoApp"]}>
                                {Object.keys(coApp).map((key, index2) => {
                                    const eachData = { [key]: coApp[key] }
                                    return (
                                        <InputLayout
                                            key={index2}
                                            data={eachData}
                                            layout='vertical'
                                        />
                                    );
                                })}
                                {(!coApp?.client?.disabled && isAppOpen) && <div className={styles["DeletePG"]} ><CrossIcon onClick={() => pgUtility.handleDeleteCoApp(index, setCoApps)} /></div>}
                            </div>
                        </div>
                    </>
                );
            })}
        </div>
    );
}