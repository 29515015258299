import { useNavigate } from "react-router-dom";
import { Table, Button, theme, ConfigProvider } from "antd";
import utils from "../../../utils"
import NotificationDetailsBlock from "../../NotificationDetailBlock";
import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";
import { constants } from "./constants.js";

import validate from "../../../utils/Validations";

import styles from "./index.module.css";

export default function VendorFunded({ notificationData }) {
    const navigate = useNavigate();
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor }, } = theme.useToken();

    const clientTelephone = `tel:${notificationData.ap_data.clientTelephone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`

    return (
        <div className={styles["NotificationsBody"]}>
            <div><NotificationScreenIcons.VendorFundedIcon /></div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: ap_userPrimaryColor }}
            >VENDOR FUNDED</div>

            <div>
                The vendor, <span className={styles["NotificationTitleInfo"]}>{notificationData.ap_data.ap_vendorName}</span>, is funded for <span className={styles["NotificationTitleInfo"]}>{notificationData.ap_data.ap_clientName}</span>.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.ap_clientName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData.ap_data.ap_clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.ap_clientEmail}</a>
                </div>
            </div>

            <div className={styles["Notification_RequirementListContainer"]}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorFillAlter: ap_userPrimaryColor,
                            // colorText: 'white',
                            colorBgContainer: themePrimaryColor,
                            colorFillSecondary: themePrimaryColor,
                            colorText: themeFontColor,
                            colorIcon: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                            colorTextHeading: themePrimaryColor
                        }
                    }}
                >
                    <Table
                        title={() => (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '70%' , margin: 'auto'}}>
                                    <div>
                                        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Date Funded </span> {utils.TransformNotificationDate(notificationData.ap_data.ap_dateFunded)}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Lender </span> {notificationData.ap_data.ap_lenderName}
                                    </div >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Admin </span> {notificationData.ap_data.ap_adminName}
                                    </div>
                                </div>
                            </div>

                        )}
                        columns={constants.VendorFundedAssetTableColumns}
                        dataSource={notificationData.ap_data.ap_assetData}
                        scroll={{ x: true, y: 500 }}
                        pagination={false}
                    />
                </ConfigProvider>
            </div>

            <div className={styles["InstructionsTitle"]} style={{ color: ap_userPrimaryColor }}>Next Steps</div>
            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>
                Please see the attached documents for post-funding requirements.
            </div>

            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/drawdowns/${notificationData.ap_entityId}`)}
                    style={{ height: "40px" }}
                >See Post-Funding Requirements</Button>
            </div>
        </div>
    )
}
