import { useNavigate } from "react-router-dom";

import NotificationDetailsBlock from "../../NotificationDetailBlock";
import validate from "../../../utils/Validations";

import generateDetails from "./constants";
import styles from "./index.module.css";
import { Button } from "antd";
import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";

export default function ApproachingExpiry({ notificationData }) {

    const navigate = useNavigate();

    const clientTelephone = `tel:${notificationData?.ap_data?.clientTelephone}`
    const clientEmail = `mailto:${notificationData?.ap_data?.clientEmail}`

    const details = generateDetails(notificationData?.ap_data);

    return (
        <div className={styles["NotificationsBody"]}>
            <div><NotificationScreenIcons.ApproachingExpiryIcon /></div>

            <div className={styles["NotificationTitle"]}>Approaching Expiry</div>

            <div>
                Credit availability for <span className={styles["NotificationTitleInfo"]}>{notificationData?.ap_data?.clientName} - {notificationData?.ap_data?.lenderAbbreviation || notificationData?.ap_data?.lenderName}</span> is approaching expiry.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData?.ap_data?.clientName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData?.ap_data?.clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock data={details?.expiryDetails} />
                    <NotificationDetailsBlock data={details?.approvalDetails} />
                </div>
                <NotificationDetailsBlock data={details.approvalNotes} fullWidth={true} />
            </div>

            <div className={styles["InstructionsTitle"]}>Next Steps</div>
            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>Reply to this notification to request an extension.</div>

            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => navigate(`/approvals/${notificationData.ap_entityId}?requestExtension=true`)}
                >Request Extension</Button>
            </div>
        </div>
    )
}