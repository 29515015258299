export default function CheckCurrency(value) {
    const amount = parseFloat(value);
    if (amount.toString() !== "NaN")
        return {
            value: amount,
            error: ''
        }
    return {
        value: '',
        error: 'Please enter a correct value'
    }
}