export default [
    {
        label: 'CIMC',
        value: 'CIMC',
    },
    {
        label: 'Doepker',
        value: 'Doepker',
    },
    {
        label: 'East',
        value: 'East',
    },
    {
        label: 'Felling',
        value: 'Felling',
    },
    {
        label: 'Foutaine',
        value: 'Foutaine',
    },
    {
        label: 'Great Dane',
        value: 'Great Dane',
    },
    {
        label: 'Hyundai',
        value: 'Hyundai',
    },
    {
        label: 'Mack',
        value: 'Mack',
    },
    {
        label: 'Manac',
        value: 'Manac',
    },
    {
        label: 'Reitnouer',
        value: 'Reitnouer',
    },
    {
        label: 'Stargate',
        value: 'Stargate',
    },
    {
        label: 'Stoughton',
        value: 'Stoughton',
    },
    {
        label: 'Timpte',
        value: 'Timpte',
    },
    {
        label: 'Utility',
        value: 'Utility',
    },
    {
        label: 'Vanguard',
        value: 'Vanguard',
    },
    {
        label: 'Wabash',
        value: 'Wabash',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];