import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Client = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12635_779)">
                <path d="M7.91842 2H7C4.23858 2 2 4.23858 2 7V23C2 25.7614 4.23858 28 7 28H7.91842M7.91842 2H23C25.7614 2 28 4.23858 28 7V23C28 25.7614 25.7614 28 23 28H7.91842M7.91842 2V28" stroke={ap_userPrimaryColor} strokeWidth="3" />
                <path d="M17.594 15.3838C19.3624 15.3838 20.796 14.0096 20.796 12.3145C20.796 10.6193 19.3624 9.24512 17.594 9.24512C15.8256 9.24512 14.392 10.6193 14.392 12.3145C14.392 14.0096 15.8256 15.3838 17.594 15.3838Z" fill={ap_userPrimaryColor} />
                <path d="M17.594 16.151C14.5931 16.151 12.791 17.6864 12.791 19.2197C12.791 19.9863 14.5931 20.7551 17.594 20.7551C20.4105 20.7551 22.3971 19.9884 22.3971 19.2197C22.3971 17.6864 20.5143 16.151 17.594 16.151Z" fill={ap_userPrimaryColor} />
            </g>
        </svg>
    );
};

export default function ClientIcon(props) {
    return <Icon component={Client} {...props} />
}