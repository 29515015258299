import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Folder = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="50" height="40" viewBox="0 0 55 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.91217 5.8973C0.91217 4.47374 1.47768 3.10848 2.48429 2.10186C3.49091 1.09525 4.85617 0.529739 6.27974 0.529739H19.6986C20.4104 0.529891 21.0929 0.812747 21.5961 1.31609L26.1773 5.8973H49.2203C50.6438 5.8973 52.0091 6.46281 53.0157 7.46943C54.0223 8.47604 54.5878 9.8413 54.5878 11.2649V38.1027C54.5878 39.5263 54.0223 40.8915 53.0157 41.8981C52.0091 42.9048 50.6438 43.4703 49.2203 43.4703H6.27974C4.85617 43.4703 3.49091 42.9048 2.48429 41.8981C1.47768 40.8915 0.91217 39.5263 0.91217 38.1027V5.8973ZM18.5876 5.8973H6.27974V38.1027H49.2203V11.2649H25.0662C24.3545 11.2647 23.672 10.9819 23.1688 10.4785L18.5876 5.8973Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function FolderIcon(props) {
    return <Icon component={Folder} {...props} />
}