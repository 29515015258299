import { theme, Input, Button } from "antd";
import styles from './index2.module.css';


export default function PasswordInputLayout({ passwordData, passwordHandlers, messageText, resetButtonLabel }) {
    const { oldPassword, newPassword, confirmPassword, isPasswordValid, passwordsMatch, isMinLengthValid, isNumberValid, isUpperCaseValid, isSpecialCharValid, focused } = passwordData;
    const { setOldPassword, setNewPassword, setConfirmPassword, verifyConfirmPassword, setFocused, setConfirmPasswordFocused, handleButtonClick } = passwordHandlers;

    const { token : { ap_userPrimaryColor}} = theme.useToken();

    return (
        <div className={styles['rightLayout']}>
                    <div className={styles['emailInput']}>
                        <label style={{ color: {ap_userPrimaryColor} }}>Old Password</label>
                        <Input.Password
                            className={styles['passwordInput']}
                            style={{ width: '350px' }}
                            size='large'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e?.target?.value)}
                        />
                    </div>
                    <div className={styles['emailInput']} >
                        <label style={{ color: {ap_userPrimaryColor} }}>New Password</label>
                        <Input.Password
                            className={styles['passwordInput']}
                            id="newPassword"
                            style={{ width: '350px' }}
                            size='large'
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // addonAfter={
                            //     isPasswordValid ? (
                            //         <CheckCircleFilled style={{ color: '#8ECEAE' }} />
                            //     ) : null
                            // }
                        />
                        <div style={focused ? { textAlign: 'left', marginLeft: '-6%', color: '#447EB7' } : { textAlign: 'left', marginLeft: '-6%', color: 'lightgray' }}>
                            <div style={{ marginLeft: '25px', color: isPasswordValid ? '#8ECEAE' : '#447EB7' }}>Password must:</div>
                            <ul style={{ margin: '0px' }}>
                                <li style={{ color: isMinLengthValid ? '#8ECEAE' : '#447EB7' }}>Contain min. 8 characters</li>
                                <li style={{ color: isUpperCaseValid ? '#8ECEAE' : '#447EB7' }}>Contain upper letters</li>
                                <li style={{ color: isNumberValid ? '#8ECEAE' : '#447EB7' }}>Contain at least 1 number</li>
                                <li style={{ color: isSpecialCharValid ? '#8ECEAE' : '#447EB7' }}>Contain 1 special character</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles['emailInput']}>
                        <label style={{ color: {ap_userPrimaryColor} }}>Confirm New Password</label>
                        <Input.Password
                            className={styles['passwordInput']}
                            style={{ width: '350px' }}
                            size='large'
                            value={confirmPassword}
                            onChange={e => { setConfirmPassword(e.target.value); if (e.target.value === newPassword && isPasswordValid) { setConfirmPasswordFocused(true); verifyConfirmPassword() } }}
                            onBlur={() => { (confirmPassword.length > 0 && setConfirmPasswordFocused(true)); verifyConfirmPassword() }}
                            // addonAfter={
                            //     passwordsMatch ? (
                            //         <CheckCircleFilled style={{ color: '#8ECEAE' }} />
                            //     ) : null
                            // }
                        />
                        {(passwordsMatch !== null) ? !passwordsMatch ? <div style={{ color: 'red' }}>Passwords do not match</div> : <div style={{ color: '#8ECEAE' }}>Passwords match</div> : null}
                    </div>
                    {messageText}
                    <Button
                        type="primary"
                        className={styles['resetPasswordButton']}
                        onClick={handleButtonClick}
                    >
                        {resetButtonLabel}
                    </Button>
                </div>
    )
}