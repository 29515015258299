import { theme } from 'antd';
import Icon from '@ant-design/icons';

const CSR = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M86 28.3C86 26.3639 85.2309 24.5071 83.8619 23.1381C82.4929 21.7691 80.6361 21 78.7 21H20.3C18.3639 21 16.5071 21.7691 15.1381 23.1381C13.7691 24.5071 13 26.3639 13 28.3V72.1C13 74.0361 13.7691 75.8929 15.1381 77.2619C16.5071 78.6309 18.3639 79.4 20.3 79.4H78.7C80.6361 79.4 82.4929 78.6309 83.8619 77.2619C85.2309 75.8929 86 74.0361 86 72.1V28.3ZM78.7 35.6H20.3V28.3H78.7V35.6ZM20.3 46.55H78.7V72.1H20.3V46.55Z" fill="white" />
            <circle cx="77" cy="27" r="14" fill="white" />
            <path d="M77 29.7333C76.6463 29.7333 76.3605 29.6074 76.1429 29.3556C75.9388 29.1037 75.8095 28.7556 75.7551 28.3111L75.0408 21.6C74.9456 20.8296 75.0748 20.2074 75.4286 19.7333C75.7823 19.2444 76.3061 19 77 19C77.7075 19 78.2313 19.2444 78.5714 19.7333C78.9116 20.2074 79.034 20.8296 78.9388 21.6L78.2449 28.3111C78.1905 28.7556 78.0612 29.1037 77.8571 29.3556C77.6531 29.6074 77.3673 29.7333 77 29.7333ZM77 35C76.3878 35 75.898 34.8 75.5306 34.4C75.1769 34 75 33.4741 75 32.8222C75 32.1852 75.1769 31.6741 75.5306 31.2889C75.898 30.8889 76.3878 30.6889 77 30.6889C77.6395 30.6889 78.1293 30.8889 78.4694 31.2889C78.8231 31.6741 79 32.1852 79 32.8222C79 33.4741 78.8231 34 78.4694 34.4C78.1293 34.8 77.6395 35 77 35Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function CSRIcon(props) {
    return <Icon component={CSR} {...props} />
}