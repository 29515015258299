import { useState } from "react";
import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";
import PaymentSchedules from "../../layouts/PaymentSchedules";
import { useParams } from "react-router-dom";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function IndividualPaymentSchedule() {
    const [headerName, setHeaderName] = useState('PPS');
    const { id, paymentScheduleId } = useParams();

    return (
        <HomeContent
            contentHeader={
                <div className={styles["contentHeader"]}>
                    <GoBackButton />
                    {headerName}
                </div>
            }
            content={
                <>
                    <div className={`${styles["PaymentSchedule_Description"]} Font_UltraLight`}>You can accept this Payment Schedule or make changes by clicking Modify below.</div>
                    <div className={styles["container"]}>
                        <PaymentSchedules hideHoldAlert={false} setHeaderName={setHeaderName} approvalId={id} ppsId={paymentScheduleId} />
                    </div>
                </>

            }
            bgColor="transparent"
        />
    );
}