import { useEffect, useState } from 'react';
import { ConfigProvider, Tabs, Tooltip, theme } from 'antd';

import HomeContent from "../../components/HomeContent";

import Error404 from '../../layouts/Error404';
import OngoingDrawdownsList from '../../layouts/OngoingDrawdownsList';
import CancelledDrawdownsList from '../../layouts/CancelledDrawdownList';
import CompletedDrawdownsList from '../../layouts/CompletedDrawdownsList';

import AuthService from '../../services/auth.service';

import styles from './index.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useUpdateActiveTab } from '../../utils/UpdateActiveTab';

export default function DrawdownsPage() {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const [refresh, setRefresh] = useState(0);
    const [agentId, setAgentId] = useState('');
    const [pageLoaded, setPageLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('ongoing');

    useEffect(() => {
        setPageLoaded(true);
    }, []);

    // Effect hook for initial setup
    useEffect(() => {

        // Checking if the user is authenticated and authorized
        const isLoggedIn = AuthService.isLoggedIn();
        const authGuard = AuthService.authGuard();

        if (!authGuard || !isLoggedIn) {
            // Refreshing component if not authenticated
            setRefresh(prevRefresh => prevRefresh + 1);
        }
        else {
            const currentUser = AuthService?.getCurrentUser();
            if (currentUser?.agent_id)
                // Setting agent ID if available
                setAgentId(currentUser?.agent_id);
            else
                // Refreshing component if agent ID is not available
                setRefresh(prevRefresh => prevRefresh + 1);
        }
    }, [refresh]);

    const tabItems = [
        {
            key: 'ongoing',
            label: 'Ongoing',
            childrens: <OngoingDrawdownsList
                entity="agent"
                entityId={agentId}
            />,
        },
        {
            key: 'completed',
            label: 'Completed',
            childrens: <CompletedDrawdownsList
                entity="agent"
                entityId={agentId}
            />,
        },
        {
            key: 'cancelled',
            label: 'Cancelled',
            childrens: <CancelledDrawdownsList
                entity="agent"
                entityId={agentId}
            />,
        },
    ];

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(activeTab, setActiveTab, tabItems, 'ongoing');

    const content = (
        <div className={styles["Container"]}>
            <div className={styles["TabWrap"]}>
                <Tabs
                    activeKey={activeTab}
                    defaultActiveKey={tabItems[0].key}
                    items={tabItems}
                    onChange={(key) => setActiveTab(key)}
                />
                <div className={(activeTab === 'ongoing') ? styles['TabWrap_Content'] : styles["Hide"]}>
                    Add Drawdown
                    <ConfigProvider theme={{ token: { colorBgSpotlight: '#D9D9D9', colorTextLightSolid: '#000000' } }}>
                        <Tooltip
                            overlayInnerStyle={{ textAlign: "center" }}
                            title={<div>In order to add a drawdown, please review your <a style={{ textDecoration: 'underline', color: ap_userPrimaryColor }} href="/approvals">Approvals</a> and request documents</div>}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </ConfigProvider>
                </div>
            </div>
            {tabItems.find(tab => tab.key === activeTab)?.childrens}
        </div>
    );

    return (
        <>
            {pageLoaded && !agentId ?
                <Error404 /> :
                <HomeContent
                    contentHeader='Drawdowns'
                    content={content}
                    bgColor="transparent"
                />
            }
        </>
    );
}
