const themeVariables = {
    "--userPrimaryColor": "#447EB7",
    "--userHighlightColor": "#CFDCE9",
}

const contentData = [
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/Profile.svg",
        title: "Manage Your Profile",
        description: "You can manage your agent profile, update the contact details and manage communication preferences to your account.",
    },
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/Contacts.svg",
        title: "Manage Your Contacts",
        description: "You can add, manage clients, and update changes to client profiles.",
    },
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/PaymentSchedule.svg",
        title: "Select or Request Your Own Payment Schedule",
        description: "You can now initiate funding by selecting a payment schedule or propose your own.",
    },
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/Deals.svg",
        title: "Send Deals Quickly and Efficiently",
        description: "Send and manage new deals without calling or emailing sales representatives.",
    },
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/DealUpdates.svg",
        title: "Get Updates on Deals",
        description: "Choose when and how you want to be notified when a deal has progressed, updated, or changed.",
    },
    {
        image: "https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/AllInOne.svg",
        title: "Everything in One Place",
        description: "View your deals, files, contacts, statistics, and more, all in one place.",
    },
]

const exportObj = {
    themeVariables,
    contentData
}

export default exportObj;