import { Layout, theme } from "antd";
import styles from "./index.module.css";

/**
 * A component representing an item in the header popover list.
 * @param {boolean} hovered - Whether the item is currently hovered.
 * @param {Function} onMouseEnter - Event handler for mouse enter.
 * @param {Function} onMouseLeave - Event handler for mouse leave.
 * @param {Function} onClick - Event handler for click.
 * @param {ReactNode} icon - Icon element to display.
 * @param {string} iconPosition - Position of the icon ('start' or 'end').
 * @param {string} label - Label text for the item.
 */

export default function HeaderListItem({
    hovered,
    onMouseEnter,
    onMouseLeave,
    onClick,
    icon,
    iconPosition,
    label
}) {

    const { token: { ap_userHighlightColor } } = theme.useToken();
    const themeColorVariables = {
        '--userHighlightColor': ap_userHighlightColor,
    }

    if (iconPosition === 'end')
        return (
            <Layout style={themeColorVariables}>
                <div
                    className={hovered ? styles["ListItemSelectedEnd"] : styles["ListItemEnd"]}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                >

                    <div className={styles["FontPadding"]}>
                        {label}
                    </div>

                    <div className={styles["FlexCenter"]}>
                        {icon}
                    </div>

                </div>
            </Layout>
        );

    return (
        <Layout style={themeColorVariables}>
            {/* <div
                className={hovered ? styles["ListItemSelected"] : styles["ListItem"]}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            >

                <div className={styles["FlexCenter"]}>
                    {icon}
                </div>

                <div className={styles["FontPadding"]}>
                    {label}
                </div>

            </div> */}
            <div
                className={hovered ? styles["ListItemSelected"] : styles["ListItem"]}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            >
                <div>{icon}</div>
                <div>{label}</div>
            </div>
        </Layout>
    );
}