import Icon from '@ant-design/icons';
import { theme } from 'antd';

const DocumentReqStep2Filled = (color) => {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="35" height="35" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.375" width="50" height="50" rx="25" fill={color || ap_userPrimaryColor} />
            <path d="M17.8983 36V33.12L25.7063 25.152C27.3063 23.552 28.5543 21.92 28.5543 20.224C28.5543 17.984 27.0183 16.768 25.0983 16.768C23.2423 16.768 21.8023 17.76 20.9383 19.552L17.8343 17.728C19.2743 14.656 22.0903 13.184 25.0663 13.184C28.7463 13.184 32.2343 15.648 32.2343 20.096C32.2343 22.88 30.5703 25.216 28.4263 27.392L23.3703 32.448H32.6503V36H17.8983Z" fill="white" />
        </svg>



    );
}

export default function DocumentRequestStep2Filled({ color, ...props }) {
    return <Icon component={() => DocumentReqStep2Filled(color)} {...props} />
}