import { theme } from 'antd';
import Icon from '@ant-design/icons';

const SpecSheet = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M31 47.7989C31 46.7914 31.3981 45.8251 32.1068 45.1127C32.8154 44.4002 33.7765 44 34.7787 44H65.0085C66.0107 44 66.9718 44.4002 67.6805 45.1127C68.3891 45.8251 68.7872 46.7914 68.7872 47.7989V72.492C68.7872 73.4995 68.3891 74.4658 67.6805 75.1782C66.9718 75.8907 66.0107 76.2909 65.0085 76.2909H34.7787C33.7765 76.2909 32.8154 75.8907 32.1068 75.1782C31.3981 74.4658 31 73.4995 31 72.492V47.7989ZM48.0043 47.7989H34.7787V53.4973H48.0043V47.7989ZM51.783 47.7989V53.4973H65.0085V47.7989H51.783ZM65.0085 57.2963H51.783V62.9947H65.0085V57.2963ZM65.0085 66.7936H51.783V72.492H65.0085V66.7936ZM48.0043 72.492V66.7936H34.7787V72.492H48.0043ZM34.7787 62.9947H48.0043V57.2963H34.7787V62.9947Z" fill="white" />
            <path d="M21 20.3C21 18.3639 21.7691 16.5071 23.1381 15.1381C24.5071 13.7691 26.3639 13 28.3 13H57.5C58.468 13.0002 59.3962 13.3849 60.0806 14.0695L78.3306 32.3195C79.0151 33.0038 79.3998 33.932 79.4 34.9V78.7C79.4 80.6361 78.6309 82.4929 77.2619 83.8619C75.8929 85.2309 74.0361 86 72.1 86H28.3C26.3639 86 24.5071 85.2309 23.1381 83.8619C21.7691 82.4929 21 80.6361 21 78.7V20.3ZM70.5889 34.9L57.5 21.8111V34.9H70.5889ZM50.2 20.3H28.3V78.7H72.1V42.2H53.85C52.882 42.2 51.9536 41.8154 51.2691 41.1309C50.5846 40.4464 50.2 39.518 50.2 38.55V20.3Z" fill="white" />
            <path d="M31 25.4488C31 24.7993 31.258 24.1765 31.7172 23.7172C32.1765 23.258 32.7993 23 33.4488 23H45.6927C46.3422 23 46.965 23.258 47.4243 23.7172C47.8835 24.1765 48.1415 24.7993 48.1415 25.4488V37.6927C48.1415 38.3422 47.8835 38.965 47.4243 39.4243C46.965 39.8835 46.3422 40.1415 45.6927 40.1415H33.4488C32.7993 40.1415 32.1765 39.8835 31.7172 39.4243C31.258 38.965 31 38.3422 31 37.6927V25.4488ZM45.6927 25.4488H33.4488V37.6927H45.6927V25.4488Z" fill="white" stroke="white" />
        </svg>
    );
};

export default function SpecSheetIcon(props) {
    return <Icon component={SpecSheet} {...props} />
}