import { Button, Checkbox, ConfigProvider, Table, theme, Input } from "antd";
import { useNavigate } from "react-router-dom";

import AssetIcon from "../../assets/images/icons/AssetIcon";
import ArchiveFolderIcon from "../../assets/images/icons/ArchieveFolder";
import TransferAssetIcon from "../../assets/images/icons/TransferAsset";

import approvalConstants from "./constants";
import styles from "./index.module.css";

const ApprovalSelectionStep = ({selectedRows, approvals, loading, rowSelection, transfering, selectedApprovals, handleNext, hasSelected, setTransferToApprovals}) => {
    const { token: { themeFontColor, themePrimaryColor } } = theme.useToken();
    const userTheme = { token: { colorText: themeFontColor } };

    return(
        <>
            {/* Sub-Header */}
            <div className={styles["SubHeader"]}>
                You have selected:
            </div>

            {/* Selected Assets */}
            {selectedRows.map((row) => {
                const selectedAssetNameArray = [row?.ap_name, row?.ap_vendorId?.ap_name, row?.ap_vin || row?.ap_serialNumber];
                const selectedAssetName = selectedAssetNameArray.filter(item => item !== null && item !== "" && item !== undefined).join(" - ");

                return (
                    <div className={styles["SelectedAssetRow"]}>
                        <Checkbox checked={true} />
                        <AssetIcon />
                        <span>{selectedAssetName}</span>
                    </div>
                )
            })}

            <div className={styles["SubHeader"]}>
                Select the approval(s) you would like to transfer the above asset(s) to:
            </div>

            {/* Approvals Table */}
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                    },
                }}
                renderEmpty={() => (<div>No Approvals Found with available credit</div>)}
            >
                <Table
                    loading={loading}
                    dataSource={approvals}
                    columns={approvalConstants.generateApprovalsTableColumns()}
                    rowSelection={rowSelection}
                    pagination={false}
                    scroll={{ x: true, y: 300 }}
                    rowKey="_id"
                />
            </ConfigProvider>

            <div className={styles["ActionButtons"]}>
                <Button
                    className={styles["CancelButton"]}
                    onClick={() => setTransferToApprovals(false)}
                >Cancel</Button>

                <Button
                    type="primary"
                    disabled={!(selectedApprovals.length > 0) }
                    loading={transfering}
                    className={styles["Button"]}
                    onClick={handleNext}
                >Next</Button>
            </div>
        </>
    );
}


const AssetTransferReasonStep = ({selectedApprovalList, transferReason, transfering, selectedApprovals, setTransferReason, setTransferToApprovals, handleTransfer, handlePrev }) => {
    const { token: { themeFontColor, themePrimaryColor } } = theme.useToken();
    const userTheme = { token: { colorText: themeFontColor } };

    return(
        <>
            <div className={styles["SubHeader"]}>
                You have selected the following approval(s):
            </div>

            {/* Selected Assets */}
            {selectedApprovalList.map((eachApproval) => {
                const selectedApprovalNameArray = [
                    (eachApproval?.ap_lenderId?.ap_abbreviation || eachApproval?.ap_lenderId?.ap_name), 
                    eachApproval?.ap_creditAvailable, 
                    eachApproval?.ap_submissionType, 
                    eachApproval?.ap_clientId?.ap_businessName
                ];
                const selectedApprovalName = selectedApprovalNameArray.filter(item => item !== null && item !== "" && item !== undefined).join(" - ");
        
                return (
                    <div className={styles["SelectedAssetRow"]}>
                        <Checkbox checked={true} />
                        <ArchiveFolderIcon />
                        <span>{selectedApprovalName}</span>
                    </div>
                )
            })}

            <div className={styles["SubHeader"]}>
                Please explain your reason for this transfer:
            </div>

            <div>
                <Input.TextArea
                    placeholder="Type the reason for your transfer here..."
                    autoSize={{ minRows: 5 , maxRows: 5 }}
                    value={transferReason}
                    onChange={(e) => setTransferReason(e.target.value)}
                />
            </div>
            <div className={styles["ActionButtons"]}>
                <Button
                    className={styles["CancelButton"]}
                    onClick={() => handlePrev()}
                >Back</Button>

                <Button
                    type="primary"
                    disabled={!(selectedApprovals.length > 0 && transferReason.length > 0)}
                    loading={transfering}
                    className={styles["Button"]}
                    onClick={handleTransfer}
                >Transfer</Button>
            </div>
        </>
    );
}


const TransferRequested = ({selectedRows, selectedApprovals, closeModal}) => {
    const navigate = useNavigate();
    return(
        <>
        <div>
            <TransferAssetIcon className={styles["Center"]} />

            {/* Sub-Header */}
            <div className={styles["Center"]}>
                The following asset(s) have been successfully transferred to the listed approval(s).
            </div>

            {/* Selected Assets */}
            <div className={styles["Header_Row"]}>Assets</div>
            {selectedRows.map((asset) => {
                const selectedAssetNameArray = [asset?.ap_name, asset?.ap_vendorId?.ap_name, asset?.ap_vin || asset?.ap_serialNumber];
                const selectedAssetName = selectedAssetNameArray.filter(item => item !== null && item !== "" && item !== undefined).join(" - ");
                return (
                    <div className={styles["TransferredAssetRow"]}>
                        <AssetIcon />
                        <span>{selectedAssetName}</span>
                    </div>
                )
            })}

            {/* Selected Approvals */}
            <div className={styles["Header_Row"]}>Approvals</div>
            {selectedApprovals.map((approvals) => {
                const selectedApprovalNameArray = [
                    (approvals?.ap_lenderId?.ap_abbreviation || approvals?.ap_lenderId?.ap_name),
                    approvals?.ap_submittedAmount,
                    approvals?.ap_submissionType,
                    approvals?.ap_clientId?.ap_businessName
                ];

                return (
                    <div className={styles["TransferredApprovalRow"]}>
                        <ArchiveFolderIcon />
                        <span>{selectedApprovalNameArray.join(", ")}</span>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                size="small"
                                className={styles["ViewButton"]}
                                onClick={() => navigate(`/approvals/${approvals?._id}`)}
                            >View</Button>
                        </div>
                    </div>
                );
            })}

            <div className={styles["BackCenter"]}>
                <Button
                    type="primary"
                    onClick={closeModal}
                    className={styles["BackButton"]}
                >Back to Application</Button>
            </div>

        </div>
        </>
    );
}

export {
    ApprovalSelectionStep,
    AssetTransferReasonStep,
    TransferRequested
}