import React from 'react';
import { Form } from 'antd';
import Dropdown from '../../components/Dropdown';
import DropdownSearch from '../../components/DropdownSearch';
import AddDropdownSearch from '../../components/AddDropdownSearch';
import SwitchComponent from '../../components/Switch';
import DateComponent from '../../components/DateComponent';
import UploadComponent from '../../components/UploadComponent';
import PasswordComponent from '../../components/PasswordComponent';
import InputComponent from '../../components/InputComponent';
import TextAreaComponent from '../../components/TextAreaComponent';
import SliderComponent from '../../components/SliderComponent';
import InlineSwitch from '../../components/InlineSwitch';
import ContactInputField from '../../components/ContactInputField';
import ThemedDropdownSearch from '../../components/ThemedComponents/ThemedDropdownSearch';
import ThemedDateComponent from '../../components/ThemedComponents/ThemedDateComponent';
import ThemedInputComponent from '../../components/ThemedComponents/ThemedInputComponent';
import ThemedRadioComponent from '../../components/ThemedComponents/ThemedRadioComponent';

export default function InputLayout({ data, layout, newApp, setData, formStyle, fieldStyle, switchLayout, rowCount, autoFill = false, autoSize, ...props }) {

    return (
        <Form layout={layout} style={formStyle}>
            {Object.keys(data).map((item) => (
                <React.Fragment key={item}>
                    <div style={fieldStyle}>
                        {(() => {
                            switch (data[item].inputType) {
                                case 'dropdown':
                                    return <Dropdown data={data[item]} item={item} layout={layout} autoFill={autoFill} />
                                case 'dropdown-search':
                                    return <DropdownSearch data={data[item]} item={item} layout={layout} />
                                case 'themed-dropdown-search':
                                    return <ThemedDropdownSearch data={data[item]} item={item} layout={layout} />
                                case 'add-dropdown-search':
                                    return <AddDropdownSearch data={data[item]} item={item} layout={layout} />
                                case 'switch':
                                    return <SwitchComponent data={data[item]} item={item} layout={switchLayout ? switchLayout : layout} />
                                case 'inline-switch':
                                    return <InlineSwitch data={data[item]} item={item} layout={switchLayout ? switchLayout : layout} />
                                case 'themed-radio':
                                    return <ThemedRadioComponent data={data[item]} item={item} layout={layout} />
                                case 'date':
                                    return <DateComponent data={data[item]} item={item} layout={layout} autoFill={autoFill} />
                                case 'themed-date':
                                    return <ThemedDateComponent data={data[item]} item={item} layout={layout} />
                                case 'upload':
                                    return <UploadComponent data={data} item={item} layout={layout} newApp={newApp} />
                                case 'password':
                                    return <PasswordComponent data={data[item]} item={item} layout={layout} showRequirement={props.showRequirement} />
                                case 'textarea':
                                    return <TextAreaComponent data={data[item]} item={item} layout={layout} rowCount={rowCount} autoSize={autoSize}/>
                                case 'slider':
                                    return <SliderComponent data={data[item]} item={item} layout={layout} />
                                case 'contact-input':
                                    return <ContactInputField data={data[item]} item={item} layout={layout} />
                                case 'themed-input':
                                    return <ThemedInputComponent data={data[item]} item={item} layout={layout} />
                                default:
                                    return <InputComponent data={data[item]} item={item} layout={layout} autoFill={autoFill} />
                            }
                        })()}
                    </div>
                </React.Fragment>
            ))}
        </Form>
    );
}