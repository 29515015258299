import { useEffect } from 'react'
import { useLocation } from 'react-router';

export function useUpdateActiveTab(tab, setTab, tabItems, defaultTab) {

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabValue = queryParams.get("section");
        const findTab = tabItems.find((item) => (item?.key)?.toLowerCase() === tabValue);
        setTab(findTab ? findTab?.key : defaultTab);
    }, [location]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("section", tab?.toLowerCase());
        const baseUrl = window.location.href.split("?")[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
    }, [tab]);
};