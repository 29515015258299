const theme = {
    token: {
        fontFamily: 'Gilroy-Regular',
        ap_theme: 'light',
        ap_userPrimaryColor: "#447EB7",
        ap_userSecondaryColor: "#8ECEAE",
        ap_userHighlightColor: "#CFDCE9",
        // ap_logoUrl: "https://lanterncontainerstorage.blob.core.windows.net/agent-portal-blob/LC.svg",
        ap_fontSize: 16,
        colorBgContainer: 'white',
        colorBgLayout: '#F5F5F5',
        contentBgContainer: 'white',
        inputColorBg: '#FFFFFF',
        colorBgContainerDisabled: '#525252',
        colorFillAlter: 'white',
        fontSize: 16,
        iconColor: 'white',
        colorPrimary: '#447EB7',
        sideNavBgColor: '#447EB7',
        headerFooterBgColor: '#9C9CB4',
        colorTextDisabled: 'black',
        userSecondaryHoverColor: "#6FACB2",
        autoFillDisabled: '#FBF5CC',
        colorTextPlaceholder: '#00000040'
    }
}

export default theme;