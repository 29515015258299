import { theme } from "antd";

export default function PhoneIcon() {

    const { token: { ap_userPrimaryColor } } = theme.useToken();

    return (
        <svg width="18" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2503 8.4249L9.30707 7.1055L9.29582 7.1005C9.10451 7.01802 8.89558 6.98482 8.68813 7.00393C8.48068 7.02303 8.28132 7.09384 8.1083 7.20988C8.08404 7.22611 8.06068 7.24364 8.0383 7.26238L6.64389 8.4499C5.83138 8.00927 4.99198 7.17675 4.55072 6.37423L5.742 4.95795C5.76115 4.93509 5.77889 4.91109 5.79512 4.88608C5.90811 4.71367 5.97672 4.516 5.99482 4.31066C6.01292 4.10532 5.97996 3.89869 5.89888 3.70918C5.89695 3.70555 5.89528 3.70179 5.89388 3.69793L4.5751 0.749741C4.46685 0.503188 4.2821 0.298037 4.04819 0.164647C3.81428 0.0312578 3.54364 -0.0232815 3.27632 0.00910075C2.36904 0.128287 1.53615 0.573658 0.933235 1.26204C0.330315 1.95041 -0.00141353 2.83472 4.52764e-06 3.7498C4.52764e-06 8.85053 4.14947 13 9.2502 13C10.1653 13.0014 11.0496 12.6697 11.738 12.0668C12.4263 11.4638 12.8717 10.631 12.9909 9.72368C13.0233 9.45636 12.9687 9.18572 12.8354 8.95181C12.702 8.7179 12.4968 8.53315 12.2503 8.4249ZM9.2502 11.5C7.19549 11.4975 5.22565 10.6802 3.77275 9.22725C2.31985 7.77435 1.50252 5.80451 1.50004 3.7498C1.4986 3.23198 1.6744 2.72925 1.99822 2.32516C2.32204 1.92107 2.77438 1.63995 3.28007 1.52851L4.45635 4.15356L3.25882 5.57984C3.23947 5.60291 3.22152 5.62712 3.20507 5.65234C3.08704 5.83269 3.01764 6.0405 3.00362 6.25558C2.98961 6.47067 3.03144 6.68572 3.12507 6.87987C3.71383 8.08489 4.92698 9.28992 6.14451 9.87993C6.33995 9.97258 6.55609 10.0129 6.77179 9.99706C6.9875 9.98117 7.1954 9.90959 7.37516 9.7893C7.39933 9.773 7.42249 9.75526 7.44453 9.73618L8.84644 8.54428L11.4715 9.71993C11.3601 10.2256 11.0789 10.678 10.6748 11.0018C10.2708 11.3256 9.76802 11.5014 9.2502 11.5Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}