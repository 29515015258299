import { useState } from "react";
import { Button, Carousel, Image } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import SpeedometerIcon from "../../assets/images/icons/ListingIcons/Speedometer";
import InterestedIcon from "../../assets/images/icons/InterestedIcon";

import ListUtils from "./utils";

import styles from "./index.module.css";
import RequestAsset from "../RequestAsset";

export default function Listing({ data, setRefresh }) {

    const multipleAssets = data?.quantity && data?.quantity > 1;

    const [hoveredButtons, setHoveredButtons] = useState({
        call: false,
        email: false,
        website: false,
        interested: false
    });

    const [currentImage, setCurrentImage] = useState(1);
    const [requestAsset, setRequestAsset] = useState(false);

    const onImageChange = (number) => setCurrentImage(number + 1);

    // assetPhotos
    const AssetImages = (
        <>
            <Carousel arrows draggable infinite afterChange={onImageChange} dots={false}>
                {data?.assetPictures?.map((img) => (
                    <div>
                        <Image
                            src={img}
                            style={{ width: '100%', maxHeight: '300px', textAlign: 'center', objectFit: 'contain' }}
                        />
                    </div>
                ))}
            </Carousel>
            <div className={styles["SlideIndex"]}>{currentImage} / {data?.assetPictures?.length}</div>
        </>
    )

    const AssetDetails = (
        <>
            <div>
                <div>{data?.year} {data?.make}</div>
                <div>{data?.priceFormatted}</div>
            </div>
            <div>
                <div>{data?.model}</div>
                <div>UL#{data?.listNumber}</div>
            </div>
            <div>
                {multipleAssets &&
                    <div>
                        <div>{data?.quantity} Units</div>
                        <div>Available</div>
                    </div>
                }

                {!multipleAssets &&
                    <div>
                        <div><SpeedometerIcon /> {data?.kmsHours}</div>
                        <div>Kilometers</div>
                    </div>
                }

                <Button
                    type="primary"
                    size="large"
                    onMouseEnter={() => ListUtils.handleButtonHovers('interested', setHoveredButtons)}
                    onMouseLeave={() => ListUtils.handleButtonHovers('interested', setHoveredButtons)}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (data?.agentInterested)
                            ListUtils.handleNotInterested(data?._id, setRefresh);
                        else
                            ListUtils.handleInteresed(data?._id, setRefresh);
                    }}
                    className={styles[(data?.agentInterested) ? "Interested_Button" : ""]}
                >
                    Interested
                    {data?.agentInterested && <CheckOutlined />}
                    {!data?.agentInterested &&
                        <InterestedIcon
                            interested={data?.agentInterested}
                            customColor={true}
                            hovered={hoveredButtons?.interested}
                        />
                    }
                </Button>
            </div>
        </>
    );

    const listingDescriptionArr = data?.description?.split('\n')?.filter(item => item && item.trim() !== "");
    const AssetDescription = (
        <>
            <div>Description</div>
            <div>
                {listingDescriptionArr?.map((value, index) => (
                    <>
                        <div key={index}>{value}</div>
                        <br />
                    </>
                ))}
            </div>
        </>
    );

    return (
        <div>
            <div className={styles['Listings']}>
                <div>{AssetImages}</div>
                <div className={styles["AssetDetails"]}>{AssetDetails}</div>
                <div className={styles["AssetDescription"]}>{AssetDescription}</div>
            </div>
            <div className={styles["Listings_Footer"]}>
                Not what you're looking for? <a onClick={() => setRequestAsset(prev => !prev)}>Request Asset</a>
            </div>
            <RequestAsset requestAsset={requestAsset} setRequestAsset={setRequestAsset} />
        </div>
    )
}