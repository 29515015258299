import { useState } from "react";
import { theme } from "antd";
import Icon from '@ant-design/icons';

function Phone() {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" fill={themePrimaryColor} />
            <path d="M6.05942 5.98439H8.2691L8.98122 7.76493L7.83701 8.52773C7.76962 8.57269 7.71438 8.63358 7.67618 8.70501C7.63798 8.77643 7.61799 8.85618 7.61801 8.93719V8.94801C7.61826 8.97002 7.61925 8.99202 7.62096 9.01396C7.62391 9.05431 7.62883 9.10894 7.63818 9.17636C7.65738 9.30924 7.69429 9.49231 7.76614 9.70787C7.91083 10.1409 8.19429 10.7015 8.74647 11.2537C9.29864 11.8058 9.85918 12.0893 10.2918 12.234C10.5078 12.3058 10.6904 12.3423 10.8243 12.3619C10.8998 12.3725 10.9759 12.3791 11.0521 12.3816L11.0585 12.3821H11.0624C11.1538 12.3821 11.2439 12.3566 11.3216 12.3085C11.3993 12.2605 11.4621 12.1917 11.5029 12.11L11.8326 11.4505L14.0157 11.8147V13.9407C12.9768 14.0908 10.1707 14.2389 7.96594 12.0342C5.76119 9.82942 5.90883 7.02279 6.05942 5.98439ZM8.6382 9.17636L9.52748 8.58383C9.71535 8.45852 9.85442 8.27242 9.92135 8.05674C9.98829 7.84106 9.97902 7.60893 9.89511 7.39927L9.18299 5.61873C9.10991 5.43611 8.98379 5.27956 8.8209 5.1693C8.658 5.05903 8.4658 5.00011 8.2691 5.00012H6.03383C5.58648 5.00012 5.16571 5.31066 5.09238 5.7959C4.92506 6.89926 4.69818 10.1582 7.27007 12.7301C9.84196 15.3019 13.1009 15.0746 14.2042 14.9077C14.6895 14.8339 15 14.4136 15 13.9663V11.8147C15 11.5817 14.9174 11.3562 14.7668 11.1784C14.6162 11.0007 14.4075 10.882 14.1776 10.8437L11.9945 10.48C11.7869 10.4454 11.5736 10.4782 11.386 10.5737C11.1984 10.6692 11.0464 10.8223 10.9522 11.0105L10.7819 11.3516C10.7217 11.3368 10.6621 11.3195 10.6033 11.2999C10.2982 11.1985 9.87444 10.9899 9.44234 10.5578C9.01025 10.1257 8.80159 9.70196 8.70021 9.39635C8.676 9.3241 8.65498 9.25068 8.6382 9.17636Z" fill={ap_userPrimaryColor} />
            <path d="M6.05942 5.98439H8.2691L8.98122 7.76493L7.83701 8.52773C7.76962 8.57269 7.71438 8.63358 7.67618 8.70501C7.63798 8.77643 7.61799 8.85618 7.61801 8.93719V8.94801C7.61826 8.97002 7.61925 8.99202 7.62096 9.01396C7.62391 9.05431 7.62883 9.10894 7.63818 9.17636C7.65738 9.30924 7.69429 9.49231 7.76614 9.70787C7.91083 10.1409 8.19429 10.7015 8.74647 11.2537C9.29864 11.8058 9.85918 12.0893 10.2918 12.234C10.5078 12.3058 10.6904 12.3423 10.8243 12.3619C10.8998 12.3725 10.9759 12.3791 11.0521 12.3816L11.0585 12.3821H11.0624C11.1538 12.3821 11.2439 12.3566 11.3216 12.3085C11.3993 12.2605 11.4621 12.1917 11.5029 12.11L11.8326 11.4505L14.0157 11.8147V13.9407C12.9768 14.0908 10.1707 14.2389 7.96594 12.0342C5.76119 9.82942 5.90883 7.02279 6.05942 5.98439Z" fill={themePrimaryColor} />
            <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke={ap_userHighlightColor} strokeWidth="1.5" />
        </svg>
    );
}

function PhoneHovered() {
    const { token: { ap_userPrimaryColor, themePrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill={ap_userPrimaryColor} />
            <path d="M6.05942 5.98439H8.2691L8.98122 7.76493L7.83701 8.52773C7.76962 8.57269 7.71438 8.63358 7.67618 8.70501C7.63798 8.77643 7.61799 8.85619 7.61801 8.93719V8.94801C7.61826 8.97002 7.61925 8.99202 7.62096 9.01396C7.62391 9.05431 7.62883 9.10894 7.63818 9.17636C7.65738 9.30924 7.69429 9.49231 7.76614 9.70787C7.91083 10.1409 8.19429 10.7015 8.74647 11.2537C9.29864 11.8058 9.85918 12.0893 10.2918 12.234C10.5078 12.3058 10.6904 12.3423 10.8243 12.3619C10.8998 12.3725 10.9759 12.3791 11.0521 12.3816L11.0585 12.3821H11.0624C11.1538 12.3821 11.2439 12.3566 11.3216 12.3085C11.3993 12.2605 11.4621 12.1917 11.5029 12.11L11.8326 11.4505L14.0157 11.8147V13.9407C12.9768 14.0908 10.1707 14.2389 7.96594 12.0342C5.76119 9.82942 5.90883 7.02279 6.05942 5.98439ZM8.6382 9.17636L9.52748 8.58384C9.71535 8.45852 9.85442 8.27242 9.92135 8.05674C9.98829 7.84106 9.97902 7.60893 9.89511 7.39927L9.18299 5.61873C9.10991 5.43611 8.98379 5.27956 8.8209 5.1693C8.658 5.05903 8.4658 5.00011 8.2691 5.00012H6.03383C5.58648 5.00012 5.16571 5.31066 5.09238 5.7959C4.92506 6.89926 4.69818 10.1582 7.27007 12.7301C9.84196 15.3019 13.1009 15.0746 14.2042 14.9077C14.6895 14.8339 15 14.4136 15 13.9663V11.8147C15 11.5817 14.9174 11.3562 14.7668 11.1784C14.6162 11.0007 14.4075 10.882 14.1776 10.8437L11.9945 10.48C11.7869 10.4454 11.5736 10.4782 11.386 10.5737C11.1984 10.6692 11.0464 10.8223 10.9522 11.0105L10.7819 11.3516C10.7217 11.3368 10.6621 11.3195 10.6033 11.2999C10.2982 11.1985 9.87444 10.9899 9.44234 10.5578C9.01025 10.1257 8.80159 9.70196 8.70021 9.39635C8.676 9.3241 8.65498 9.25068 8.6382 9.17636Z" fill={themePrimaryColor} />
            <path d="M6.05942 5.98439H8.2691L8.98122 7.76493L7.83701 8.52773C7.76962 8.57269 7.71438 8.63358 7.67618 8.70501C7.63798 8.77643 7.61799 8.85619 7.61801 8.93719V8.94801C7.61826 8.97002 7.61925 8.99202 7.62096 9.01396C7.62391 9.05431 7.62883 9.10894 7.63818 9.17636C7.65738 9.30924 7.69429 9.49231 7.76614 9.70787C7.91083 10.1409 8.19429 10.7015 8.74647 11.2537C9.29864 11.8058 9.85918 12.0893 10.2918 12.234C10.5078 12.3058 10.6904 12.3423 10.8243 12.3619C10.8998 12.3725 10.9759 12.3791 11.0521 12.3816L11.0585 12.3821H11.0624C11.1538 12.3821 11.2439 12.3566 11.3216 12.3085C11.3993 12.2605 11.4621 12.1917 11.5029 12.11L11.8326 11.4505L14.0157 11.8147V13.9407C12.9768 14.0908 10.1707 14.2389 7.96594 12.0342C5.76119 9.82942 5.90883 7.02279 6.05942 5.98439Z" fill={themePrimaryColor} />
        </svg>
    );
}

export default function InputPhone() {
    const [isHovered, setHovered] = useState(false);

    const handleHover = () => setHovered(true);
    const handleHoverOut = () => setHovered(false);

    return (
        <div
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverOut}
        >
            {isHovered ?
                <Icon component={() => PhoneHovered()} style={iconStyles} /> :
                <Icon component={() => Phone()} style={iconStyles} />
            }
        </div>
    );
}

const iconStyles = {
    width: '20px',
    height: '20px'
}