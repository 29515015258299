import { useState } from "react";
import { Button } from "antd";

import exportObj from "./constants.js";

import styles from "./index.module.css";

export default function GetStartedPage({ setGetStarted, formData }) {

    const { themeVariables, contentData } = exportObj;

    const [content, setContent] = useState(contentData)

    return (
        <div className={styles["GetStarted"]} style={themeVariables}>
            <div className={styles["HeaderContainer"]}>
                <img className={styles["Logo"]} src="https://lanternpublicstaticfiles.blob.core.windows.net/main/Get Started/BeaconLogo.svg" />
                <div className={styles["Header"]}>Welcome to Beacon, <span className={styles["HeaderSpan"]}>{formData?.firstName?.value} {formData?.lastName?.value}</span>!</div>
            </div>

            <div className={styles["SubHeader"]}>Let's get started!</div>
            <div className={styles["SubHeader_Text"]}>Before registering, we wanted to give you an idea of what you can accomplish by using Beacon.</div>

            <div className={styles["GetStarted_Container"]}>
                {content.map((item, index) => (
                    <div key={index} className={styles["GetStarted_Card"]}>
                        <img className={styles["Card_Image"]} src={item.image} />
                        <div className={styles["Card_Title"]}>{item.title}</div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </div>

            <div className={styles["Action"]}>
                <Button
                    type="primary"
                    className={styles["ContinueButton"]}
                    onClick={() => setGetStarted(true)}
                >Continue</Button>
            </div>

        </div>
    );
}