import React from 'react';

// Import required components
import HeaderQuickQuote from '../Header/HeaderQuickQuote';
import HeaderSalesProfile from '../Header/HeaderSalesProfile';
import HeaderNotification from '../Header/HeaderNotification';
import HeaderProfile from '../Header/HeaderProfile';

// Importing CSS styles
import styles from './index.module.css';
import MobileHamBurgerMenuIcon from '../../assets/images/icons/MobileHamBurgerMenu';

// Define the HeaderContent component
export default function HeaderContent({ mobileSideOpen, setMobileSideOpen, collapsed, setCollapsed }) {
    return (
        <>
            {/* Header layout container */}
            <div className={styles["header-layout"]}>

                <div
                    className={styles["Header_CloseBtn"]}
                    onClick={() => { setMobileSideOpen(prev => !prev); setCollapsed(prevCollapse => !prevCollapse) }}
                ><MobileHamBurgerMenuIcon /></div>
                <div className={styles["Header_Empty"]}>&nbsp;</div>

                {/* Quick Quote component */}
                <div>
                    <HeaderQuickQuote />
                </div>

                {/* Sales Profile component */}
                <div>
                    <HeaderSalesProfile />
                </div>

                {/* Notification List component */}
                <div>
                    <HeaderNotification />
                </div>

                {/* Profile component */}
                <div>
                    <HeaderProfile />
                </div>

            </div>
        </>
    );
}
