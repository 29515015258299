// Imports
import { useEffect, useRef, useState } from "react";
import { Col, Row, Checkbox, Spin } from "antd";


//Custom Imports
import utilities from './utilities';
import constants from "./constants";
import EditIcon from "../../assets/images/icons/HomeTasks/EditTaskIcon.js";
import DeleteIcon from "../../assets/images/icons/HomeTasks/DeleteTaskIcon.js";
import AddTaskIcon from "../../assets/images/icons/HomeTasks/AddTaskIcon.js";
import CheckIcon from "../../assets/images/icons/HomeTasks/CheckTaskIcon.js";
import CancelIcon from "../../assets/images/icons/HomeTasks/CancelTaskIcon.js";
//Custom Styling
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

const HomeTasksCard = ({ entity, entityId, divHeight, numComponents }) => {
    // State Variables
    const [editData, setEditData] = useState([]);
    const labelRef = useRef(null);
    const listRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [maxHeight, setMaxHeight] = useState(12);

    const { fetchAllTasks, addMyTask, setEditTaskData, onHover, completeEditTaskData, deleteMyTask } = utilities;
    const { _task } = constants;
    const [task, setTask] = useState(_task);

    // Fetch All Agent's Tasks
    useEffect(() => {
        setLoading(true);
        fetchAllTasks(entityId, editData, setEditData);
        setLoading(false);

    }, [entity, entityId]);

    useEffect(() => {
        const height = divHeight - labelRef.current.clientHeight - 30;
        listRef.current.style.height = `${height}px`;

    }, [editData, divHeight]);

    useEffect(() => {
        if (editData.length && editData.length > 0) {
            setMaxHeight(12 - editData.length);
        }
    }, [editData]);


    const addTaskComponent = (isAdding) => {

        if (isAdding) {
            return (
                <>
                    <Row className={styles["AddNewTask"]}>
                        <Col span={1}>
                            <Checkbox />
                        </Col>
                        <Col span={19}>
                            <input onChange={(e) => { setTask({ ...task, new_task_description: e.target.value }); }} className={styles["inputAddTask"]} placeholder="Add new task"></input>
                        </Col>
                        <Col
                            span={2}
                            onMouseEnter={() => { setTask({ ...task, new_task_on_hover_cancel: false, new_task_on_hover_edit: true }) }}
                            onMouseLeave={() => { setTask({ ...task, new_task_on_hover_edit: false }) }}
                        >

                            {
                                task.new_task_on_hover_edit == true ?
                                    <CheckIcon.CheckTaskHoverIcon
                                        onClick={() => { addMyTask(entityId, editData, setEditData, setLoading, task, setTask, setIsAdding); }}
                                    />
                                    :
                                    <CheckIcon.CheckTaskIcon
                                        onClick={() => { addMyTask(entityId, editData, setEditData, setLoading, task, setTask, setIsAdding); }}
                                    />
                            }
                        </Col>
                        <Col span={2}
                            onMouseEnter={() => { setTask({ ...task, new_task_on_hover_edit: false, new_task_on_hover_cancel: true }) }}
                            onMouseLeave={() => { setTask({ ...task, new_task_on_hover_cancel: false }) }}
                        >
                            {
                                task.new_task_on_hover_cancel == true ?
                                    <CancelIcon.CancelTaskHoverIcon
                                        onClick={() => { setIsAdding(false) }}
                                    />
                                    :
                                    <CancelIcon.CancelTaskIcon
                                        onClick={() => { setIsAdding(false) }}
                                    />
                            }
                        </Col>

                    </Row>
                    <Row className={styles["AddNewTask"]}>
                        <Col>
                            <a className={styles["link"]} style={{ display: "flex" }} onClick={((event) => {
                                setIsAdding(true);
                            })}>
                                <AddTaskIcon />
                                <span style={{ marginLeft: "1rem" }} className={styles["add_task"]}> Add new task</span>
                            </a>
                        </Col>
                    </Row>
                </>
            )
        }
        return (
            <>

                <Row className={styles["AddNewTask"]}>
                    <Col>
                        <a className={styles["link"]} style={{ display: "flex" }} onClick={((event) => {
                            setIsAdding(true);
                        })}>
                            <AddTaskIcon />
                            <span style={{ marginLeft: "1rem" }} className={styles["add_task"]}> Add new task</span>
                        </a>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div id="MyTask">
            <Row ref={labelRef}>
                <span className={`${styles["cardLabel"]} Font_SemiBold`}>My Tasks</span>
            </Row>
            <Spin spinning={loading} tip="Loading your tasks...">
                <div ref={listRef} className={styles["tab"]} id="TaskTest">
                    {editData.map((element, index) => {
                        return (

                            <Row key={index} className={styles["MyTask-Row"]}>
                                <Col span={1}>
                                    <Checkbox
                                        checked={element.task_is_done}
                                        onClick={(e) => {
                                            let obj = {};
                                            obj["_id"] = element._id;
                                            obj["field"] = "task_is_done";
                                            obj["value"] = e.target.checked;
                                            obj["edit_type"] = "task_is_done";
                                            setEditTaskData(obj, editData, setEditData);
                                            obj["field"] = "is_edit";
                                            obj["value"] = false;
                                            completeEditTaskData(entityId, obj, editData, setEditData);
                                        }}
                                    />
                                </Col>
                                <Col span={19}>
                                    {
                                        editData.find(taskItem => taskItem._id == element._id)?.is_edit ?
                                            <input
                                                id={element._id}
                                                key={element._id}
                                                value={editData[index].task_description}
                                                style={{ color: "#CCCCCC" }}
                                                onChange={(e) => {
                                                    const obj = {
                                                        _id: element._id,
                                                        field: "task_description",
                                                        value: e.target.value,
                                                    };
                                                    setEditTaskData(obj, editData, setEditData);
                                                }}
                                                className={styles["inputAddTask"]}
                                                placeholder="Add new task">
                                            </input>
                                            :
                                            <span className={styles["task_list"]}>
                                                {element.task_description}
                                            </span>
                                    }

                                </Col>

                                <Col
                                    span={2}
                                    onMouseEnter={() => {
                                        const obj = {
                                            _id: element._id,
                                            field: "on_edit_hover",
                                            value: true,
                                        };
                                        onHover(obj, editData, setEditData);
                                    }}
                                    onMouseLeave={() => {
                                        const obj = {
                                            _id: element._id,
                                            field: "on_edit_hover",
                                            value: false,
                                        };
                                        onHover(obj, editData, setEditData);
                                    }}
                                >
                                    {
                                        editData.find(taskItem => taskItem._id == element._id)?.is_edit ?


                                            (element.on_edit_hover ?

                                                <CheckIcon.CheckTaskHoverIcon
                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: false,
                                                            edit_type: "task_description"
                                                        };
                                                        completeEditTaskData(entityId, obj, editData, setEditData);
                                                    }}
                                                /> :

                                                <CheckIcon.CheckTaskIcon
                                                    className={styles["link"]}
                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: false,
                                                            edit_type: "task_description"
                                                        };
                                                        completeEditTaskData(entityId, obj, editData, setEditData);
                                                    }}
                                                />

                                            )
                                            :
                                            (element.on_edit_hover ?
                                                <EditIcon.EditTaskHoverIcon
                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: true,
                                                        };
                                                        setEditTaskData(obj, editData, setEditData);
                                                    }}
                                                /> :

                                                <EditIcon.EditTaskIcon
                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: true,
                                                        };
                                                        setEditTaskData(obj, editData, setEditData);
                                                    }}
                                                />)
                                    }
                                </Col>
                                <Col
                                    span={2}
                                    onMouseOver={() => {
                                        const obj = {
                                            _id: element._id,
                                            field: "on_delete_hover",
                                            value: true,
                                        };
                                        onHover(obj, editData, setEditData);
                                    }}

                                    onMouseOut={() => {
                                        const obj = {
                                            _id: element._id,
                                            field: "on_delete_hover",
                                            value: false,
                                        };
                                        onHover(obj, editData, setEditData);
                                    }}

                                >
                                    {
                                        editData.find(taskItem => taskItem._id == element._id)?.is_edit ?
                                            (element.on_delete_hover ?

                                                <CancelIcon.CancelTaskHoverIcon
                                                    className={styles["link"]}
                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: false,
                                                        };
                                                        setEditTaskData(obj, editData, setEditData);
                                                    }}
                                                />
                                                :

                                                <CancelIcon.CancelTaskIcon
                                                    className={styles["link"]}

                                                    onClick={(e) => {
                                                        const obj = {
                                                            _id: element._id,
                                                            field: "is_edit",
                                                            value: false,
                                                        };
                                                        setEditTaskData(obj, editData, setEditData);
                                                    }}
                                                />)
                                            :
                                            (
                                                element.on_delete_hover ?
                                                    <DeleteIcon.DeleteTaskHoverIcon
                                                        onClick={(e) => {
                                                            deleteMyTask(entityId, element._id, editData, setEditData, setLoading, setIsAdding);
                                                        }}
                                                    />
                                                    :
                                                    <DeleteIcon.DeleteTaskIcon
                                                        onClick={(e) => {
                                                            deleteMyTask(entityId, element._id, editData, setEditData, setLoading, setIsAdding);
                                                        }}
                                                    />)
                                    }
                                </Col>
                            </Row>

                        );
                    })}

                    {addTaskComponent(isAdding)}

                    {Array.from({ length: maxHeight }).map((_, index) => (
                        <div key={index} className={styles["PaperRuler"]} />
                    ))}

                </div>
            </Spin>
        </div>
    );
}


export default HomeTasksCard;