export default function CheckErrorsInFields(array = []) {
    return array.some(field => {
        for (const key in field) {
            if (Object.hasOwnProperty.call(field, key)) {
                if (field[key].error !== '') {
                    return true;
                }
            }
        }
        return false;
    });
}