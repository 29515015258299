import utils from "../../../utils";
import DownloadIcon from "../../../assets/images/icons/DownloadIcon";
import PrintIcon from "../../../assets/images/icons/PrintIcon";
import styles from "./index.module.css";

function generateDetails(notificationData) {

    const drawdownDetails = {
        title: "Drawdown Details",
        data: [
            {
                label: "Amount",
                value: utils.TransformDBCurrency(notificationData?.ap_drawdownAmount)
            },
            {
                label: "Asset Description",
                value: notificationData?.ap_assetDescription
            },
            {
                label: "LC#",
                value: notificationData?.ap_lcNumber
            },
            {
                label: "Lender",
                value: notificationData?.ap_lenderName
            },
            {
                label: "Admin",
                value: notificationData?.ap_adminName
            }
        ]
    }

    const instructionDetails = {
        title: "Instructions",
        hidden: !notificationData?.ap_instructions,
        data: [
            {
                label: "",
                // value: <ul>
                //     <li>{notificationData?.ap_instructions}</li>
                // </ul>
                value: notificationData?.ap_instructions
            }
        ]
    }

    return {
        drawdownDetails,
        instructionDetails
    }
}

function generateTableColumns() {
    // Function to download a resource
    const downloadResource = (resource) => {
        if (resource) {
            window.location.href = resource;
        }
    };

    // Function to print a resource
    const printResource = (resource) => {
        if (resource) {
            const printWindow = window.open(resource, '_blank');
            printWindow.onload = () => {
                printWindow.print();
            };
        }
    };

    return [
        {
            title: "Name",
            key: 'fileName',
            dataIndex: 'fileName',
            ellipsis: { showTitle: false },
        },
        {
            title: "Size",
            key: 'fileSize',
            dataIndex: 'fileSize',
            width: 110,
            ellipsis: { showTitle: false },
        },
        {
            title: "Actions",
            key: 'fileUrl',
            ellipsis: { showTitle: false },
            width: 90,
            render: (record) => {
                const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;
                const fileUrl = `${record?.fileUrl}?${sasToken}`;
                return (
                    <div className={styles["ActionIcons"]}>
                        <div onClick={() => downloadResource(fileUrl)} className={styles["CenterIcon"]}>
                            <DownloadIcon />
                        </div>
                        <div onClick={() => printResource(fileUrl)} className={styles["CenterIcon"]}>
                            <PrintIcon />
                        </div>
                    </div>
                );
            }
        }
    ];
}

const exportObj = {
    generateDetails: generateDetails,
    generateTableColumns: generateTableColumns,
}

export default exportObj;