import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Upload = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="80" height="60" viewBox="0 0 86 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6277 54.3894C16.6873 54.3894 11.9493 52.5328 8.45592 49.2281C4.96255 45.9233 3 41.4411 3 36.7675C3 32.0938 4.96255 27.6116 8.45592 24.3068C11.9493 21.0021 16.6873 19.1455 21.6277 19.1455C22.7945 14.0045 26.2079 9.48662 31.117 6.58577C33.5477 5.14942 36.2725 4.15329 39.1357 3.65425C41.9989 3.15521 44.9446 3.16305 47.8044 3.67731C50.6642 4.19156 53.3823 5.20217 55.8033 6.65142C58.2244 8.10068 60.301 9.9602 61.9147 12.1238C63.5284 14.2874 64.6475 16.7128 65.2081 19.2614C65.7688 21.81 65.76 24.4319 65.1822 26.9775H69.1417C72.8172 26.9775 76.3421 28.4215 78.941 30.9919C81.5399 33.5622 83 37.0484 83 40.6834C83 44.3185 81.5399 47.8047 78.941 50.375C76.3421 52.9454 72.8172 54.3894 69.1417 54.3894H65.1822" stroke={ap_userPrimaryColor} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.5469 42.6415L41.4254 30.8935M41.4254 30.8935L53.3039 42.6415M41.4254 30.8935V66.1374" stroke={ap_userPrimaryColor} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default function UploadIcon() {
    return <Icon component={Upload} />
}