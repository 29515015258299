import { Button } from "antd";
import dayjs from "dayjs";
import SortColumn from "../../components/SortColumn";
import SearchColumn from "../../components/SearchColumn";
import utils from "../../utils";

function generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort) {

    const columns = [
        {
            title: 'LC#',
            dataIndex: 'lcNumber',
            key: 'lcNumber',
            ...SortColumn(customSort, setCustomSort, 'lcNumber'),
            width: 100,
            ellipsis: true,
        }
    ];

    if (entity !== 'client') {
        columns.push({
            title: 'Client',
            key: 'clientName',
            dataIndex: 'clientName',
            ...SortColumn(customSort, setCustomSort, 'clientName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'clientName'),
            width: 150,
            ellipsis: true,
        });
    }

    columns.push(
        {
            title: 'Asset Description',
            dataIndex: 'assetDescription',
            key: 'assetDescription',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Lender',
            key: 'lenderName',
            dataIndex: 'lenderName',
            ...SortColumn(customSort, setCustomSort, 'lenderName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'lenderName'),
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Fee',
            key: 'drawdownFee',
            render: (record) => utils.TransformDBCurrency(record?.drawdownFee),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Paid On',
            key: 'paidOnDate',
            width: 150,
            render: (record) => (record?.paidOnDate) ? dayjs(record?.paidOnDate).format('DD-MM-YYYY') : null,
            ...SortColumn(customSort, setCustomSort, 'paidOnDate'),
            ellipsis: true,
        },
        {
            title: 'Invoice',
            key: 'invoice',
            render: (record) => {
                return (
                    record?.invoiceLink && record.invoiceLink !== '' ? (
                        <Button
                            type="link"
                            onClick={() => window.location.href = record.invoiceLink}
                        >
                            Invoice
                        </Button>
                    ) : (
                        "-"
                    )
                );
            },
            width: 100,
            ellipsis: true,
        },
    );

    return columns;
}

const exportObj = {
    generateTableColumns: generateTableColumns
}

export default exportObj;