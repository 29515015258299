import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Button, Form, Steps, Tabs, Alert } from "antd";

import ApiService from "../../api";

import CancelDrawdown from "../CancelDrawdown";
import InputLayout from "../InputLayout";

import constants from "../../constants";
import drawdownContants from "./constants";

import styles from "./index.module.css";
import { CloseCircleFilled } from "@ant-design/icons";

export default function Drawdown({ drawdownData, setRefresh }) {

    const navigate = useNavigate();

    const [headerName, setHeaderName] = useState();

    const tabKeyItems = drawdownContants?.tabKeyItems(drawdownData, setHeaderName);

    const [loading, setLoading] = useState(false);
    const [direction, setDirection] = useState('horizontal');
    const [tabKey, setTabKey] = useState(0);
    const [tabContent, setTabContent] = useState(tabKeyItems[0]);
    const [currentBpf, setCurrentBpf] = useState({});
    const [bpfItems, setBpfItems] = useState([]);
    const [outstandingRequirementId, setOutstandingRequirementId] = useState(null);

    const isDesktop = useMediaQuery({ query: constants?.BREAKPOINTS?.xl })

    // Screen Sizing
    useEffect(() => {
        setDirection(isDesktop ? 'horizontal' : 'vertical');
    }, [isDesktop]);

    useEffect(() => {

        if (!drawdownData?._id && Object.keys(drawdownData).length === 0) return;

        setCurrentBpf(drawdownContants?.getCurrentBpf(drawdownData));

        setBpfItems(drawdownContants?.generateBpfItems(drawdownData));

    }, [drawdownData]);

    useEffect(() => {

        if (!drawdownData?._id && !drawdownData?.ap_agentId?._id) return;

        setLoading(true);
        ApiService.fetchDrawdownOutstandingRequirements(drawdownData?.ap_agentId?._id, drawdownData?._id).then(response => {
            if (!response || response?.length === 0) {
                setLoading(false);
                return;
            }

            const requirement = response[0];

            if (!requirement?.ap_requirementsList || !requirement?.ap_requirementsList?.length === 0) return;

            const tempArr = [];
            for (const item of requirement?.ap_requirementsList) {
                if (!item.checked) {
                    tempArr.push(item.name);
                }
            }
            setOutstandingRequirementId(tempArr.length > 0 ? requirement._id : null)
            setLoading(false);
        });

    }, [drawdownData]);

    const onTabSwitch = (tabKey) => {
        setTabKey(tabKey);
        setTabContent(tabKeyItems[tabKey])
    };

    const admin = { admin: drawdownContants?.generateDetailsFields(drawdownData)?.admin };
    const sales = { sales: drawdownContants?.generateDetailsFields(drawdownData)?.sales };
    const lcNumber = { lcNumber: drawdownContants?.generateDetailsFields(drawdownData)?.lcNumber };
    const agentFee = { agentFee: drawdownContants?.generateDetailsFields(drawdownData)?.agentFee };

    return (
        <>
            <div className={(drawdownData?.ap_drawdownCancelled) ? styles["AlertBlock"] : styles["Hide"]}>

            </div>

            <div id="drawdown" className={styles["DrawdownContainer"]}>

                <div className={(drawdownData?.ap_beaconDrawdownCancelled) ? styles["Alert"] : styles["Hide"]}
                >
                    <div><CloseCircleFilled className={styles["AlertIcon"]} /></div>
                    <div>
                        <div className={styles["AlertContent"]}>This Drawdown was cancelled by <b>{drawdownData?.ap_drawdownCancelledBy}</b>.</div>
                        <div className={styles["AlertContent"]}><b>Reason: </b>{drawdownData?.ap_drawdownCancelledReason}</div>
                    </div>
                </div>

            <div className={(drawdownData?.ap_isOnHold) ? styles["WarningAlert"] : styles["Hide"]}>
                <Alert type="warning" message="This drawdown has been put on hold."  showIcon />
            </div>

                <Tabs
                    defaultActiveKey="0"
                    items={drawdownContants?.tabItems()}
                    onChange={onTabSwitch}
                />

                <div style={{ display: 'flex' }}>
                    <Steps
                        progressDot
                        current={currentBpf}
                        direction={direction}
                        items={bpfItems}
                        status={((drawdownData?.ap_responsibleType === 'Agent' || drawdownData?.ap_responsibleType === 'Client') && outstandingRequirementId) ? 'error' : 'process'}
                    />
                </div>

                <div className={styles["TabContent"]}>
                    <div className={styles[(tabKey === 0) ? "DrawdownDetails" : "Hide"]}>
                        <InputLayout
                            data={admin}
                            layout="vertical"
                        />

                        <InputLayout
                            data={sales}
                            layout="vertical"
                        />

                        <div>
                            <Form layout="vertical">
                                <Form.Item
                                    key="approval"
                                    label={<b>Approval</b>}
                                >
                                    <div className={styles["DisabledLink"]}>
                                        <a href={`/approvals/${drawdownData?.ap_paymentScheduleId?.ap_approvalId}`}>{drawdownData?.ap_lenderId?.ap_abbreviation || drawdownData?.ap_lenderId?.ap_name}</a>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>

                        <InputLayout
                            data={agentFee}
                            layout="vertical"
                        />

                    </div>

                    {tabContent}
                </div>

            </div>

            <div className={styles["FlexBetween"]}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/requirements/${outstandingRequirementId}`)}
                    style={{ visibility: outstandingRequirementId ? 'visible' : 'hidden', height: '40px', marginTop: "2%" }}
                >See Requirements</Button>
                {(!drawdownData?.ap_beaconDrawdownCancelled) ?
                    <CancelDrawdown
                        drawdownId={drawdownData?._id}
                        setRefresh={setRefresh}
                    /> :
                    <></>
                }
            </div>

        </>
    );
}