import { Button, Input, Space, theme } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

export default function SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, dataIndex) {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, dataIndex, selectedKeys, confirm) => {
        clearFilters();
        confirm();
        setSearchText('');
        setSearchedColumn('');
    };

    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{ padding: 8, }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                            background: ap_userPrimaryColor,
                        }}
                    >
                        Search
                    </Button>

                    <Button
                        onClick={() => {
                            handleReset(clearFilters, dataIndex, selectedKeys, confirm);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Clear Filter
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            close();
                        }}
                    />
                </Space>
            </div>
        ),

        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                    backgroundColor: 'white',
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
}