import { useEffect, useState } from "react";
import { Spin } from "antd";
import { Pie } from "@ant-design/plots";

import EmptyPieIcon from "../../../assets/images/icons/Home/EmptyPieIcon";
import EmptyDataComponent from "../../../components/EmptyDataComponent/index.js";
import ApiService from "../../../api";

import pieConstants from "./constants.js";
import styles from "./index.module.css";
import "../../../assets/styles/fonts.css";

export default function HomeDonutChart() {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState({ loading: false, tip: '' })

    useEffect(() => {
        setLoader({ loading: true, tip: 'Loading Data...' });
        ApiService.fetchDrawdownsByProcessStagePie().then((pieData) => {
            if (!pieData) {
                setLoader({ loading: false, tip: '' });
                return;
            }
            setData(pieData?.data || []);
            setLoader({ loading: false, tip: '' });
        });
    }, []);

    const RenderFunction = () => {
        if (data?.length !== undefined && data?.length === 0) {
            return (<EmptyDataComponent flex={true} className="centerPie" iconComponent={<EmptyPieIcon />} ContentLine1={"There is nothing to show here."} ContentLine2={"Begin a new Submission to see content"} />);
        } else {
            const { configuration } = pieConstants;
            return <Pie className={styles["Pie"]} {...configuration(data || [])} />;
        }
    }

    return (
        <>
            <div className={`${styles["header"]} Font_SemiBold`}>All Ongoing Drawdowns</div>
            <div className={styles["Container"]}>
                <Spin spinning={loader?.loading} tip={loader?.tip}>
                    <RenderFunction />
                </Spin>
            </div>
        </>
    );
}