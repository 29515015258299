import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Approval = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.1381 15.1381C21.7691 16.5071 21 18.3639 21 20.3V78.7C21 80.6361 21.7691 82.4929 23.1381 83.8619C24.5071 85.2309 26.3639 86 28.3 86H72.1C74.0361 86 75.8929 85.2309 77.2619 83.8619C78.6309 82.4929 79.4 80.6361 79.4 78.7V34.9C79.3998 33.932 79.0151 33.0038 78.3306 32.3195L60.0806 14.0695C59.3962 13.3849 58.468 13.0002 57.5 13H28.3C26.3639 13 24.5071 13.7691 23.1381 15.1381ZM57.5 21.8111L70.5889 34.9H57.5V21.8111ZM28.3 20.3H50.2V38.55C50.2 39.518 50.5846 40.4464 51.2691 41.1309C51.9536 41.8154 52.882 42.2 53.85 42.2H72.1V78.7H28.3V20.3Z" fill="white" />
            <path d="M63.7305 50.5695C64.4148 51.2539 64.7992 52.1822 64.7992 53.15C64.7992 54.1179 64.4148 55.0461 63.7305 55.7305L49.1306 70.3306C48.4461 71.0148 47.5179 71.3992 46.55 71.3992C45.5822 71.3992 44.6539 71.0148 43.9695 70.3306L36.6695 63.0306C36.3208 62.6939 36.0428 62.2911 35.8515 61.8458C35.6602 61.4005 35.5595 60.9215 35.5553 60.4369C35.5511 59.9522 35.6434 59.4716 35.827 59.023C36.0105 58.5744 36.2815 58.1669 36.6242 57.8242C36.9669 57.4815 37.3744 57.2105 37.823 57.027C38.2716 56.8434 38.7522 56.7511 39.2369 56.7553C39.7215 56.7595 40.2005 56.8602 40.6458 57.0515C41.0911 57.2428 41.4938 57.5208 41.8305 57.8695L46.55 62.5889L58.5695 50.5695C59.2539 49.8852 60.1822 49.5008 61.15 49.5008C62.1179 49.5008 63.0461 49.8852 63.7305 50.5695Z" fill="white" />
        </svg>
    );
};

export default function ApprovalIcon(props) {
    return <Icon component={Approval} {...props} />
}