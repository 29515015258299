export default function ConvertRGBAtoHEX(rgbaColorString) {

    // Remove parentheses, commas, and "rgba" using regular expressions
    const cleanedColor = rgbaColorString.replace(/[(),]/g, "").replace("rgba", "");

    // Split the cleaned string into an array of values
    const colorValues = cleanedColor.split(" ");

    const r = Number(colorValues[0]).toString(16).padStart(2, '0');
    const g = Number(colorValues[1]).toString(16).padStart(2, '0');
    const b = Number(colorValues[2]).toString(16).padStart(2, '0');
    const a = Math.round(Number(colorValues[3]) * 255).toString(16).padStart(2, '0');

    return '#' + r + g + b + a;
}