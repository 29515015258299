import dayjs from "dayjs";

function getChartData(data = [], timeState) {
    const timeStateFormat = getTimeStateFormat(timeState);
    const updatedData = data.map((item) => {
        return {
            vendorFundedDate: dayjs(item?.vendorFundedDate).format(timeStateFormat),
            drawdownAmount: item?.drawdownAmount,
            agentFee: item?.agentFee,
        };
    });
    const consolidatedData = consolidateData(updatedData);
    return consolidatedData;
}

function getTimeStateFormat(timeState) {
    switch (timeState) {
        case "daily":
            return "YYYY-MM-DD";
        case "monthly":
            return "YYYY-MM";
        case "yearly":
            return "YYYY";
        default:
            return "YYYY-MM-DD";
    }
}

function consolidateData(data = []) {
    return data.reduce((acc, curr) => {
        const existing = acc.find(item => item.vendorFundedDate === curr.vendorFundedDate);
        if (existing) {
            existing.drawdownAmount = parseFloat((existing.drawdownAmount + curr.drawdownAmount).toFixed(2));
            existing.agentFee = parseFloat((existing.agentFee + curr.agentFee).toFixed(2));
        } else {
            acc.push({
                vendorFundedDate: curr.vendorFundedDate,
                drawdownAmount: parseFloat(curr.drawdownAmount?.toFixed(2)),
                agentFee: parseFloat(curr.agentFee?.toFixed(2))
            });
        }
        return acc;
    }, []);
}

const metricsCleaner = (value) => (value && !isNaN(value)) ? value : 0;

function calculateMetrics(data = []) {
    let totalDrawdownAmount = 0;
    let totalAgentFee = 0;
    data.forEach(individualData => {
        totalDrawdownAmount += (individualData.drawdownAmount || 0);
        totalAgentFee += (individualData.agentFee || 0);
    });

    const totalYield = (totalAgentFee / totalDrawdownAmount) * 100;

    return {
        totalVolume: metricsCleaner(totalDrawdownAmount),
        totalFee: metricsCleaner(totalAgentFee),
        totalYield: metricsCleaner(totalYield),
    };
}

const growthChartUtils = {
    getChartData,
    getTimeStateFormat,
    consolidateData,
    calculateMetrics,
};

export default growthChartUtils;