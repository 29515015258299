import { useState } from "react";
import { theme } from "antd";
import Icon from '@ant-design/icons';

function Mail() {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" fill={themePrimaryColor} />
            <path d="M5 7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H14C14.2652 6 14.5196 6.10536 14.7071 6.29289C14.8946 6.48043 15 6.73478 15 7V13C15 13.2652 14.8946 13.5196 14.7071 13.7071C14.5196 13.8946 14.2652 14 14 14H6C5.73478 14 5.48043 13.8946 5.29289 13.7071C5.10536 13.5196 5 13.2652 5 13V7ZM6.7595 7L10 9.8355L13.2405 7H6.7595ZM14 7.6645L10.3295 10.8765C10.2383 10.9564 10.1212 11.0004 10 11.0004C9.87878 11.0004 9.76168 10.9564 9.6705 10.8765L6 7.6645V13H14V7.6645Z" fill={ap_userPrimaryColor} />
            <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke={ap_userHighlightColor} strokeWidth="1.5" />
        </svg>
    );
}

function MailHovered() {
    const { token: { ap_userPrimaryColor, themePrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.02039 6.79908C5.00693 6.86471 5 6.932 5 7V13C5 13.2652 5.10536 13.5196 5.29289 13.7071C5.48043 13.8946 5.73478 14 6 14H14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V7C15 6.93736 14.9941 6.87533 14.9827 6.81465L14 7.67245V13H6V7.6645L9.6705 10.8765L5.02039 6.79908ZM5.67259 6.05512L10 9.8355L14.3274 6.05512C14.2231 6.01896 14.1125 6 14 6H6C5.88748 6 5.77692 6.01896 5.67259 6.05512ZM10 9.8355L13.2405 7H6.7595L10 9.8355Z" fill={themePrimaryColor} />
            <path d="M6.7595 7L10 9.8355L13.2405 7H6.7595Z" fill={themePrimaryColor} />
            <path d="M10.3295 10.8765C10.2383 10.9564 10.1212 11.0004 10 11.0004C9.87878 11.0004 9.76168 10.9564 9.6705 10.8765L6 7.6645V13H14V7.67245L10.3295 10.8765Z" fill={themePrimaryColor} />
        </svg>
    );
}

export default function InputMail() {
    const [isHovered, setHovered] = useState(false);

    const handleHover = () => setHovered(true);
    const handleHoverOut = () => setHovered(false);

    return (
        <div
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverOut}
        >
            {isHovered ?
                <Icon component={() => MailHovered()} style={iconStyles} /> :
                <Icon component={() => Mail()} style={iconStyles} />
            }
        </div>
    );
}

const iconStyles = {
    width: '20px',
    height: '20px'
}