import { useEffect, useState } from "react";

import { Avatar, Button, ConfigProvider, Layout, Modal, Upload, theme } from "antd";
import { UserOutlined } from '@ant-design/icons';

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import styles from "./index.module.css";
import utils from "../../utils";

export default function ProfileUser({ agentId, setError, setLoader }) {

    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
    }

    const [agentData, setAgentData] = useState({});

    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        setLoader({ loading: true, tip: 'Loading...' })
        ApiService.fetchAgentProfileData(agentId).then((response) => {
            if (!response) {
                setError(true);
                setLoader({ loading: false, tip: '' })
                return;
            }
            setAgentData(response);
            setLoader({ loading: false, tip: '' })
        });
    }, [agentId]);

    // Event Handler to delete the profile picture
    const handleDelete = (id) => {
        const updatedData = { ap_profilePhoto: '' }
        setLoader({ loading: true, tip: 'Deleting...' })
        ApiService.updateAgentProfile(id, updatedData).then((response) => {
            if (response.success) {
                setAgentData({
                    ...agentData,
                    ap_profilePhoto: ''
                })
                setDeleteModal(false);
            }
            else NotificationService.error("Error", "An Error occurred while removing the profile picture. Please try again later.");
            setLoader({ loading: false, tip: '' })
        });
    }

    // Event handler to upload and update the profile picture
    const handleUpload = (file) => {
        if (file && file['type'].split('/')[0] !== 'image') {
            return NotificationService.error("Please upload a valid Image File");
        }
        const reader = new FileReader();
        reader.onload = () => {
            const base64File = reader.result;
            const fileData = {
                data: base64File,
                fileName: file.name
            }

            // Upload the Picture to Blob
            setLoader({ loading: true, tip: 'Uploading...' })
            ApiService.uploadDocs(fileData).then((uploadResp) => {
                if (uploadResp.code === 200) {
                    // Update the Agent Profile
                    const updatedData = { ap_profilePhoto: uploadResp.data.url }
                    ApiService.updateAgentProfile(agentData._id, updatedData).then((response) => {
                        if (response?.success)
                            setAgentData({
                                ...agentData,
                                ap_profilePhoto: uploadResp.data.url
                            })
                        else NotificationService.error("Error", "An Error occurred while updating the profile picture. Please try again later.");
                    });
                } else NotificationService.error("Error", "An Error occurred while uploading the profile picture. Please try again later.");
            });
            setLoader({ loading: false, tip: '' })
        }
        reader.readAsDataURL(file);
    }

    const profilePhotoUrl = `${agentData?.ap_profilePhoto}?${JSON.parse(localStorage?.getItem("user"))?.sasToken}`

    const avatarParams = {
        size: { xs: 60, sm: 60, md: 60, lg: 80, xl: 100, xxl: 110 },
        className: styles["profileAvatar"],
        ...(agentData?.ap_profilePhoto) ? { src: <img src={profilePhotoUrl} alt="avatar" /> } : (!utils.GetInitials(agentData?.ap_name)) ? { icon: <UserOutlined /> } : {}
    }

    return (
        <Layout style={themeColorVariables}>

            <div className={styles["profileContainer"]}>

                <div className={styles["profileContent"]}>

                    <div className={styles["profileDivision"]} style={{ padding: '10% 0%' }}>

                        <div>

                            <div className={styles["profileDivision"]}>

                                <Avatar {...avatarParams}>
                                    {(agentData?.ap_profilePhoto) ? null : utils.GetInitials(agentData?.ap_name)}
                                </Avatar>

                            </div>

                            <div className={styles["profileButtons"]}>

                                <Upload
                                    beforeUpload={handleUpload}
                                    showUploadList={false}
                                    multiple={false}
                                    accept="image/*"
                                    maxCount={1}
                                >
                                    <Button
                                        type="primary"
                                        size="small"
                                        className={styles["primaryButton"]}
                                    >Upload</Button>
                                </Upload>

                                <div style={{ lineHeight: '1.5' }}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        className={styles["deleteButton_Small"]}
                                        onClick={() => setDeleteModal(true)}
                                    >Delete</Button>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div style={{ margin: 'auto 0%' }}>

                        <div className={styles["profileName"]}>
                            {agentData?.ap_name}
                        </div>

                        <div style={{ fontWeight: 'lighter', fontSize: '16px', color: 'gray' }}>
                            {JSON.parse(localStorage.user).email_address}
                        </div>

                    </div>

                </div>

            </div>

            <ConfigProvider
                theme={{
                    token: {
                        contentBg: themePrimaryColor,
                        colorText: themeFontColor,
                    }
                }}
            >
                <Modal
                    open={deleteModal}
                    footer={null}
                    closable={false}
                    getContainer=".AppTheme"
                    centered
                >
                    <div style={{ margin: '5%' }}>

                        <div className={styles["modalHeader"]}>
                            Are you sure you want to delete the profile photo?
                        </div>

                        <div style={{ color: 'gray', textAlign: 'center', fontSize: '16px' }}>
                            This action cannot be undone.
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '5%' }}>
                            <Button
                                className={styles["deleteButton"]}
                                onClick={() => handleDelete(agentData._id)}
                            >Delete</Button>

                            <Button
                                type='primary'
                                style={{ width: '100px' }}
                                onClick={() => setDeleteModal(false)}
                            >Cancel</Button>
                        </div>
                    </div>
                </Modal>
            </ConfigProvider>
        </Layout>
    );
}