import utils from "../../../utils";

export default function generateDetails(notificationData) {

    const expiryDetails = {
        title: "Expiry Details",
        data: [
            {
                label: "Amount Expiring",
                value: utils.TransformDBCurrency(notificationData?.creditAvailable)
            },
            {
                label: "Amount Used",
                value: utils.TransformDBCurrency(notificationData?.creditUsed)
            },
            {
                label: "Expiry Date",
                value: utils.TransformNotificationDate(notificationData?.expiryDate)
            },
            {
                label: "Submission Type",
                value: notificationData?.submissionType
            },
            {
                label: "Lender",
                value: notificationData?.lenderAbbreviation || notificationData?.lenderName
            },
            {
                label: "Credit",
                value: notificationData?.creditName
            },
        ]
    }

    const approvalDetails = {
        title: "Approval Details",
        data: [
            {
                label: "Approved Amount",
                value: utils.TransformDBCurrency(notificationData?.approvedAmount)
            },
            {
                label: "Rate",
                value: `${notificationData?.rate}%`,
                hidden: notificationData?.submissionType === 'PAL'
            },
            {
                label: "Term",
                value: `${notificationData?.term} months`,
                hidden: notificationData?.submissionType === 'PAL'
            },
            {
                label: "Down Payment",
                value: (notificationData?.downPayment !== 'Other') ? notificationData?.downPayment : utils.TransformDBCurrency(notificationData?.downPaymentAmount),
                hidden: notificationData?.submissionType === 'PAL'
            },
            {
                label: "Date Submitted",
                value: utils.TransformNotificationDate(notificationData?.submittedDate)
            },
            {
                label: "Date Approved",
                value: utils.TransformNotificationDate(notificationData?.approvedDate)
            }
        ]
    }

    const approvalNotes = {
        title: "Submission Notes",
        data: [
            {
                label: "",
                value: <ul>
                    <li>{notificationData?.submissionNotes}</li>
                </ul>
            }
        ]
    }

    return { expiryDetails, approvalDetails, approvalNotes }
}