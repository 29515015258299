import { theme } from 'antd';
import Icon from '@ant-design/icons';

const CancelTask = () => {
    const { token: { themeDangerColor }, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_2346_16643" fill="white">
                <path d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5Z" />
            </mask>
            <path d="M19 10.5C19 15.1944 15.1944 19 10.5 19V23C17.4036 23 23 17.4036 23 10.5H19ZM10.5 19C5.80558 19 2 15.1944 2 10.5H-2C-2 17.4036 3.59644 23 10.5 23V19ZM2 10.5C2 5.80558 5.80558 2 10.5 2V-2C3.59644 -2 -2 3.59644 -2 10.5H2ZM10.5 2C15.1944 2 19 5.80558 19 10.5H23C23 3.59644 17.4036 -2 10.5 -2V2Z" fill={themeDangerColor} mask="url(#path-1-inside-1_2346_16643)" />
            <path d="M7.14639 7.15264C7.24016 7.05891 7.36731 7.00625 7.49989 7.00625C7.63248 7.00625 7.75963 7.05891 7.85339 7.15264L10.4999 9.79914L13.1464 7.15264C13.1925 7.10489 13.2477 7.0668 13.3087 7.04059C13.3697 7.01439 13.4353 7.0006 13.5017 7.00002C13.5681 6.99944 13.6339 7.01209 13.6954 7.03723C13.7568 7.06237 13.8126 7.0995 13.8596 7.14645C13.9065 7.19339 13.9437 7.24922 13.9688 7.31067C13.9939 7.37212 14.0066 7.43796 14.006 7.50434C14.0054 7.57073 13.9916 7.63634 13.9654 7.69735C13.9392 7.75835 13.9011 7.81352 13.8534 7.85964L11.2069 10.5061L13.8534 13.1526C13.9445 13.2469 13.9949 13.3732 13.9937 13.5043C13.9926 13.6354 13.94 13.7608 13.8473 13.8536C13.7546 13.9463 13.6292 13.9988 13.4981 14C13.367 14.0011 13.2407 13.9507 13.1464 13.8596L10.4999 11.2131L7.85339 13.8596C7.75909 13.9507 7.63279 14.0011 7.50169 14C7.37059 13.9988 7.24519 13.9463 7.15248 13.8536C7.05978 13.7608 7.0072 13.6354 7.00606 13.5043C7.00492 13.3732 7.05531 13.2469 7.14639 13.1526L9.79289 10.5061L7.14639 7.85964C7.05266 7.76588 7 7.63873 7 7.50614C7 7.37356 7.05266 7.24641 7.14639 7.15264Z" fill={themeDangerColor} stroke={themeDangerColor} />
        </svg>
    );
};

const CancelTaskHover = () => {
    const { token: { themeDangerColor }, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5ZM13.8847 6.57447C13.7618 6.52419 13.6301 6.49888 13.4973 6.50004C13.3646 6.50119 13.2333 6.52878 13.1113 6.58119C12.9909 6.63293 12.8818 6.70783 12.7903 6.80164L10.4999 9.09204L8.20689 6.79904C8.01937 6.61157 7.76506 6.50625 7.49989 6.50625C7.23473 6.50625 6.98031 6.61167 6.79279 6.79914C6.60532 6.98667 6.5 7.24098 6.5 7.50614C6.5 7.77131 6.60537 8.02567 6.79284 8.2132L9.08579 10.5061L6.79279 12.799L6.78675 12.8053C6.60459 12.9939 6.5038 13.2465 6.50607 13.5087C6.50835 13.7709 6.61352 14.0217 6.79893 14.2071C6.98434 14.3925 7.23515 14.4977 7.49735 14.5C7.75954 14.5022 8.0122 14.4015 8.2008 14.2193L10.4999 11.9202L12.7928 14.2133L12.799 14.2193C12.9876 14.4014 13.2402 14.5022 13.5024 14.5C13.7646 14.4977 14.0154 14.3925 14.2009 14.2071C14.3863 14.0217 14.4914 13.7709 14.4937 13.5087C14.496 13.2465 14.3952 12.9938 14.2131 12.8052L11.914 10.5061L14.2044 8.21574C14.2982 8.12422 14.3731 8.01513 14.4248 7.89469C14.4773 7.77269 14.5048 7.64147 14.506 7.50869C14.5072 7.37591 14.4819 7.24423 14.4316 7.12133C14.3813 6.99844 14.307 6.88678 14.2131 6.79289C14.1192 6.699 14.0076 6.62475 13.8847 6.57447Z" fill={themeDangerColor} />
        </svg>
    );
};

const CancelIcon = {
    CancelTaskIcon: function CancelTaskIcon(props) {
        return <Icon component={CancelTask} {...props} />
    },
    CancelTaskHoverIcon: function CancelTaskHoverIcon(props) {
        return <Icon component={CancelTaskHover} {...props} />
    },
}

export default CancelIcon;