import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Revised = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M77.2635 34.9862L77.2634 34.9863L70.8135 41.4362L49.3139 62.9358C49.3139 62.9358 49.3139 62.9358 49.3139 62.9358C48.8169 63.4327 48.5376 64.1066 48.5375 64.8093V64.8094V73.4093C48.5375 74.1121 48.8167 74.7861 49.3136 75.2831C49.8106 75.7801 50.4846 76.0592 51.1874 76.0592H59.7873H59.7874C60.4901 76.0591 61.164 75.7798 61.6609 75.2829C61.6609 75.2828 61.6609 75.2828 61.6609 75.2828L89.6105 47.3333L89.6105 47.3332C90.1073 46.8363 90.3864 46.1624 90.3864 45.4597C90.3864 44.757 90.1073 44.0831 89.6105 43.5862L89.6105 43.5861L81.0106 34.9863L81.0105 34.9862C80.5136 34.4894 79.8397 34.2103 79.137 34.2103C78.4343 34.2103 77.7604 34.4894 77.2635 34.9862ZM53.8374 70.7593V65.9066L72.6871 47.0569L77.5398 51.9096L58.6901 70.7593H53.8374ZM79.137 40.607L83.9897 45.4597L81.287 48.1624L76.4343 43.3097L79.137 40.607Z" fill="white" stroke="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.1381 15.1381C16.7691 16.5071 16 18.3639 16 20.3V78.7C16 80.6361 16.7691 82.4929 18.1381 83.8619C19.5071 85.2309 21.3639 86 23.3 86H67.1C69.0361 86 70.8929 85.2309 72.2619 83.8619C73.6309 82.4929 74.4 80.6361 74.4 78.7V65.86L67.1 72.9395V78.7H23.3V20.3H45.2V38.55C45.2 39.518 45.5846 40.4464 46.2691 41.1309C46.9536 41.8154 47.882 42.2 48.85 42.2H67.1L74.4 34.9C74.3998 33.932 74.0151 33.0038 73.3305 32.3195L55.0805 14.0694C54.3962 13.3849 53.468 13.0002 52.5 13H23.3C21.3639 13 19.5071 13.7691 18.1381 15.1381ZM52.5 21.8111L65.5889 34.9H52.5V21.8111Z" fill="white" />
            <path d="M26.3832 74.4326C27.4732 74.4326 28.9043 73.5357 29.6865 72.847C31.2278 71.4899 33.7647 68.7495 36.6879 63.7575C38.1373 61.2824 39.4895 58.6127 39.58 55.6721C39.5917 55.2928 39.3833 54.6673 39.1262 54.6101C38.6978 54.5148 38.2809 54.453 37.843 54.7658C35.8093 56.2185 33.9346 60.2736 33.9346 60.2736C33.9346 60.2736 32.861 62.3632 32.0467 65.4652C31.8187 66.3339 31.222 71.126 32.5187 72.0727C34.4372 73.4734 37.6667 70.4898 38.1007 70.1282C39.2555 69.3113 41.4173 66.9127 42.9019 66.8811C46.198 66.9337 43.8459 72.1652 45.7337 73.8834C47.6216 75.6016 50.4534 74.4326 50.4534 74.4326" stroke="white" strokeWidth="3.5" strokeLinecap="round" />
            <circle cx="76" cy="25" r="14" fill="white" />
            <path d="M71.0094 33.411C70.5054 33.1126 70.2029 32.7149 70.1021 32.2176C70.0349 31.7204 70.1861 31.19 70.5558 30.6265L73.9332 25.4552L74.6893 26.4497L68.3882 26.6983C67.716 26.7315 67.1783 26.5989 66.7751 26.3005C66.3718 26.0022 66.1702 25.5713 66.1702 25.0077C66.1702 24.4442 66.3718 24.0133 66.7751 23.7149C67.1783 23.4166 67.716 23.284 68.3882 23.3171L74.6893 23.6155L73.9836 24.6099L70.6062 19.389C70.2365 18.7923 70.0853 18.2619 70.1525 17.7978C70.2533 17.3006 70.5558 16.9194 71.0599 16.6542C71.5303 16.3227 72.0008 16.2895 72.4713 16.5547C72.9754 16.7868 73.3787 17.2011 73.6811 17.7978L76.3024 22.9193H75.6975L78.3188 17.7978C78.6212 17.168 79.0077 16.7371 79.4782 16.505C79.9823 16.273 80.4864 16.3227 80.9905 16.6542C81.4946 16.9525 81.7634 17.3503 81.797 17.8475C81.8642 18.3116 81.713 18.842 81.3433 19.4387L77.9659 24.6099L77.3106 23.6155L83.5613 23.3171C84.2671 23.284 84.8216 23.4166 85.2248 23.7149C85.6281 24.0133 85.8297 24.4442 85.8297 25.0077C85.8297 25.6044 85.6281 26.0519 85.2248 26.3503C84.8216 26.6154 84.2671 26.7315 83.5613 26.6983L77.3106 26.4497L77.9659 25.4552L81.3433 30.6265C81.713 31.19 81.8642 31.7204 81.797 32.2176C81.7634 32.6817 81.4946 33.0629 80.9905 33.3613C80.4528 33.6596 79.9319 33.7093 79.4278 33.5104C78.9573 33.2784 78.5876 32.864 78.3188 32.2673L75.6975 27.0961H76.3024L73.6307 32.2673C73.3283 32.864 72.9418 33.2784 72.4713 33.5104C72.0008 33.7093 71.5135 33.6762 71.0094 33.411Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function RevisedIcon(props) {
    return <Icon component={Revised} {...props} />
}