export default function FormatAmericanZipCode(zipCode) {

    if (!zipCode)
        return { input: '', error: '' }

    const cleanedZipCode = (zipCode.replace(/\D/g, '')).substring(0, 9);

    const zipCodeLength = cleanedZipCode.length;

    if (zipCodeLength === 0)
        return {
            input: '',
            error: ''
        }

    return {
        input: addHyphen(cleanedZipCode),
        error: (zipCodeLength === 5 || zipCodeLength === 9) ? '' : 'Invalid Zip Code'
    }

}

// Adds Space or a Hyphen after 5 characters to format the code
function addHyphen(text) {
    const startCharacters = text.substring(0, 5);
    const remainingChars = text.substring(5);
    if (remainingChars)
        return startCharacters + '-' + remainingChars;
    return startCharacters
}