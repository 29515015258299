import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Layout, ConfigProvider } from 'antd';
import { ThemeContext } from '../../utils/ThemeContext';

import SideNavigator from '../../components/SideNavigator';
import HeaderContent from '../HeaderContent';
import ApiService from '../../api';

import styles from "./index.module.css";
import NotificationsBackground from '../../assets/images/NotificationsBackground';
import constants from '../../constants';
import utils from '../../utils';


const { Header, Footer, Content } = Layout;

export default function MainLayout(props) {

    // Check if the screen is mobile
    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });

    const [navSelected, setNavSelected] = useState('clients');
    const [mobileSideOpen, setMobileSideOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const [theme, setTheme] = useState(constants.DEFAULT_THEME);

    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;

    const { pathname } = useLocation();
    useEffect(() => {
        if (!agentId)
            return;

        ApiService.fetchAgentProfileData(agentId).then((agentResponse) => {
            let settingsId = agentResponse?.ap_settingsId?._id;

            //If no settings is present, then create and use default theme
            if (!settingsId) {
                ApiService.createDefaultSettings().then(newSettings => {
                    if (newSettings?.code === 200) {
                        settingsId = newSettings?.data?._id;
                        const updatedData = {
                            ap_settingsId: newSettings?.data?._id
                        }
                        ApiService.updateAgentProfile(agentId, updatedData)
                    }
                })
            }

            ApiService.fetchAgentSettingsData(settingsId).then((settingsResponse) => {

                const themeType = settingsResponse?.ap_theme || 'light';
                const themeDetails = constants.THEMES[themeType];

                const userSecondaryHoverColor = utils.DarkenHexColor(settingsResponse?.ap_userSecondaryColor || constants.DEFAULT_THEME.token.ap_userSecondaryColor);
                setTheme({
                    token: {
                        ...constants.DEFAULT_THEME.token,
                        ap_theme: themeType,
                        colorPrimary: settingsResponse?.ap_userPrimaryColor || constants.DEFAULT_THEME.token.ap_userPrimaryColor,
                        colorPrimaryHover: settingsResponse?.ap_userHighlightColor || constants.DEFAULT_THEME.token.ap_userHighlightColor,
                        ap_userPrimaryColor: settingsResponse?.ap_userPrimaryColor || constants.DEFAULT_THEME.token.ap_userPrimaryColor,
                        ap_userSecondaryColor: settingsResponse?.ap_userSecondaryColor || constants.DEFAULT_THEME.token.ap_userSecondaryColor,
                        ap_userHighlightColor: settingsResponse?.ap_userHighlightColor || constants.DEFAULT_THEME.token.ap_userHighlightColor,
                        ap_logoUrl: settingsResponse?.ap_logoUrl,
                        // userSecondaryHoverColor: themeDetails?.userSecondaryHoverColor,
                        userSecondaryHoverColor: userSecondaryHoverColor,
                        themePrimaryColor: themeDetails?.themePrimaryColor,
                        themeSecondaryColor: themeDetails?.themeSecondaryColor,
                        themeDangerColor: themeDetails?.themeDangerColor,
                        themeFontColor: themeDetails?.themeFontColor,
                        themeDisabledContainer: themeDetails?.themeDisabledContainer,
                        colorBgElevated: themeDetails?.themePrimaryColor,
                        colorBgLayout: themeDetails?.themeSecondaryColor,
                        colorTextDescription: themeDetails?.themeFontColor,
                        colorTextHeading: themeDetails?.themeFontColor,
                        colorTextLabel: themeDetails?.themeFontColor,
                        colorTextPlaceholder: constants.DEFAULT_THEME.token.colorTextPlaceholder,
                        colorFillAlter: themeDetails?.themeSecondaryColor,
                        borderColor: themeDetails?.themeSecondaryColor,
                        footerBg: themeDetails?.themePrimaryColor,
                        bodySortBg: themeDetails?.themePrimaryColor,
                        colorText: themeDetails?.themeFontColor,
                        colorIcon: themeDetails?.themeFontColor,
                        autoFillDisabled: constants.DEFAULT_THEME.token.autoFillDisabled,
                    }
                })
            });
        });

    }, [agentId]);

    useEffect(() => {
        document.getElementById('MainLayout-content').scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    const divStyle = {
        backgroundImage: `url(${NotificationsBackground(theme.token.colorPrimary).props.src})`,
        background: props.bgColor || 'white',
    };

    const isNotificationScreen = props?.bgImage;

    const themeColorVariables = {
        '--userPrimaryColor': theme.token.ap_userPrimaryColor,
        '--userSecondaryColor': theme.token.ap_userSecondaryColor,
        '--userHighlightColor': theme.token.ap_userHighlightColor,
        '--themePrimaryColor': theme.token.themePrimaryColor,
        '--themeSecondaryColor': theme.token.themeSecondaryColor,
        '--themeFontColor': theme.token.themeFontColor,
        '--autoFillDisabled': theme.token.autoFillDisabled,
    }

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <ConfigProvider theme={theme}>
                <Layout
                    style={{ minHeight: '100vh', ...themeColorVariables }}
                    className="AppTheme"
                >

                    {/* Side Navigation */}
                    <SideNavigator
                        sendData={setNavSelected}
                        mobileSideOpen={mobileSideOpen}
                        setMobileSideOpen={setMobileSideOpen}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        themes={theme}
                    />

                    <Layout style={themeColorVariables}>

                        <Header className={styles["App_Header"]}>
                            <HeaderContent
                                agentId={props.agentId}
                                mobileSideOpen={mobileSideOpen}
                                setMobileSideOpen={setMobileSideOpen}
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                                style={themeColorVariables}
                            />
                        </Header>

                        <Content
                            id='MainLayout-content'
                            className={(props?.dashboard) ? styles["MainLayout-content-container"] : styles["MainLayout-content-container-all"]}
                        >

                            <div
                                style={isNotificationScreen ? divStyle : {}}
                                className={isNotificationScreen ? styles["divStyle"] : {}}
                            >
                                <div className={(isMobile && !collapsed) ? styles["Hide"] : null}>{props.renderContent}</div>
                            </div>
                        </Content>
                    </Layout>

                </Layout>
            </ConfigProvider>
        </ThemeContext.Provider>
    );
}