import Icon from '@ant-design/icons';
import { theme } from 'antd';

const DocumentReqStep1Filled = (color) => {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="35" height="35" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.625" width="50" height="50" rx="25" fill={color || ap_userPrimaryColor} />
            <path d="M25.9759 13.6H29.1759V36H25.4959V17.536L20.9839 18.816L20.0879 15.68L25.9759 13.6Z" fill="white" />
        </svg>


    );
}

export default function DocumentRequestStep1Filled({ color, ...props }) {
    return <Icon component={() => DocumentReqStep1Filled(color)} {...props} />
}