import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import ActionRequiredIcon from "../../assets/images/icons/ActionRequired";
import EmptyRequirementIcon from "../../assets/images/icons/Home/EmptyRequirementIcon";
import EmptyDataComponent from "../../components/EmptyDataComponent";

import ApiService from "../../api";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import { Spin } from "antd";

export default function HomeUrgentRequirements() {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchUrgentRequirements().then((urgentReq) => {
            setData(urgentReq || []);
            setLoading(false);
        });
    }, []);

    const RenderFunction = () => {
        if (data?.length !== undefined && data?.length === 0) {
            return <EmptyDataComponent flex={true} className="centerRequirements" iconComponent={<EmptyRequirementIcon />} ContentLine1={"There is nothing to show here."} ContentLine2={"You currently have no statistics."} />
        } else {
            return (
                <div className={styles["ReqList"]}>
                    {data.map((req, index) => (
                        <div
                            className={styles["IndividualRequirement"]}
                            onClick={() => navigate(`/requirements/${req?._id}`)}
                            key={index}
                        >
                            {/* Dates */}
                            <div className={styles["Dates"]}>
                                <div>{dayjs(req?.createdAt).format('h:mm A')}</div>
                                <div>{dayjs(req?.createdAt).format('MMMM D, YYYY')}</div>
                            </div>

                            {/* Icon */}
                            <div style={{ margin: 'auto' }}>
                                <ActionRequiredIcon />
                            </div>

                            {/* Description */}
                            <div>
                                <div className={styles["IndividualHeader"]}>Action Required</div>
                                <div className={styles["IndividualDescription"]}>Documents for <b>{req?.ap_clientId?.ap_businessName}</b> are required to move forward.</div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    }

    return (
        <>
            <div className={`${styles["Header"]} Font_SemiBold`}>Urgent Requirements</div>
            <div className={styles["Container"]}>
                <Spin spinning={loading} tip="Loading Requiredments...">
                    <RenderFunction />
                </Spin>
            </div>
        </>
    )
}