import { useEffect, useState } from "react";
import { Button, Checkbox, Divider } from "antd";
import CreateFilter from "../../utils/CreateFilter";
import styles from "./index.module.css";

export default function FilterColumn(fullData, setFilters, dataIndex) {

    const [cleanedFilterArray, setCleanedFilterArray] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);

    useEffect(() => {
        const filterArray = CreateFilter(fullData, dataIndex);
        const cleanedFilterArray = Object.values(filterArray.reduce((accumulator, currentValue) => {
            accumulator[currentValue.text] = currentValue;
            return accumulator;
        }, {})).sort((a, b) => a?.value?.localeCompare(b?.value));
        setCleanedFilterArray(cleanedFilterArray);
    }, [fullData, dataIndex]);


    const handleFilter = (value, checked, confirm) => {
        setSelectedFilters(prevFilters => {
            const array = prevFilters || [];
            if (checked) {
                const newArray = [...array, value];
                return newArray;
            } else {
                const newArr = array.filter(item => item !== value);
                return newArr;
            }
        });
    }

    const handleApplyFilter = (confirm) => {

        setFilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
            if (selectedFilters && selectedFilters.length === 0) {
                delete updatedFilters[dataIndex];
            } else {
                updatedFilters[dataIndex] = selectedFilters;
            }
            return updatedFilters;
        });
        confirm();
    }

    const handleResetFilter = (confirm) => {
        setFilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[dataIndex];
            return updatedFilters;
        });
        setSelectedFilters([]);
        confirm();
    }

    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {cleanedFilterArray.map((item) => (
                    <>
                        <Checkbox key={item.value} checked={selectedFilters.includes(item?.label)} onChange={(e) => handleFilter(item?.value, e?.target?.checked, confirm)}>
                            {item?.label}
                        </Checkbox>
                        <br />
                    </>
                ))}

                <Divider className={styles["FilterDivider"]} />

                <div className={styles["Actions"]}>
                    <Button
                        onClick={() => handleResetFilter(confirm)}
                        size="small"
                        className={styles["ActionButtons"]}
                    >Reset</Button>

                    <Button
                        type="primary"
                        onClick={() => handleApplyFilter(confirm)}
                        size="small"
                        className={styles["ActionButtons"]}
                    >Apply</Button>
                </div>
            </div>
        ),
        onFilter: (value, record) => record[dataIndex].indexOf(value) === 0,
        render: (text) => text,
    })
}