import Icon from '@ant-design/icons';
import { theme } from 'antd';

function SubmissionApproved() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="180" height="100" viewBox="0 0 201 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M185.547 49.7355L189.216 57.6643L192.884 49.7355L200.954 46.1315L192.884 42.5274L189.216 34.5986L185.547 42.5274L177.477 46.1315L185.547 49.7355Z" fill="#FFC700" />
            <path d="M154 17.2993L166.032 22.7774L171.608 34.5986L177.184 22.7774L189.216 17.2993L177.184 11.8212L171.608 0L166.032 11.8212L154 17.2993Z" fill="#FFC700" />
            <path d="M13.7813 116.219L10.5 109L7.21875 116.219L-9.80511e-07 119.5L7.21875 122.781L10.5 130L13.7813 122.781L21 119.5L13.7813 116.219Z" fill="#FFC700" />
            <path d="M82.2583 77.1917L68.1937 63.1271C66.9944 61.9278 65.4681 61.3281 63.6146 61.3281C61.7611 61.3281 60.2347 61.9278 59.0354 63.1271C57.8361 64.3264 57.2365 65.8528 57.2365 67.7063C57.2365 69.5597 57.8361 71.0861 59.0354 72.2854L77.6792 90.9292C78.9875 92.2375 80.5139 92.8917 82.2583 92.8917C84.0028 92.8917 85.5292 92.2375 86.8375 90.9292L123.798 53.9688C124.997 52.7694 125.597 51.2431 125.597 49.3896C125.597 47.5361 124.997 46.0097 123.798 44.8104C122.599 43.6111 121.072 43.0115 119.219 43.0115C117.365 43.0115 115.839 43.6111 114.64 44.8104L82.2583 77.1917ZM91.4167 130.833C82.3674 130.833 73.8632 129.115 65.9042 125.678C57.9451 122.242 51.0219 117.582 45.1344 111.699C39.2469 105.811 34.587 98.8882 31.1548 90.9292C27.7226 82.9701 26.0044 74.466 26 65.4167C26 56.3674 27.7183 47.8632 31.1548 39.9042C34.5914 31.9451 39.2512 25.0219 45.1344 19.1344C51.0219 13.2469 57.9451 8.58703 65.9042 5.15483C73.8632 1.72264 82.3674 0.00436111 91.4167 0C100.466 0 108.97 1.71828 116.929 5.15483C124.888 8.59139 131.811 13.2512 137.699 19.1344C143.586 25.0219 148.248 31.9451 151.685 39.9042C155.122 47.8632 156.838 56.3674 156.833 65.4167C156.833 74.466 155.115 82.9701 151.678 90.9292C148.242 98.8882 143.582 105.811 137.699 111.699C131.811 117.586 124.888 122.248 116.929 125.685C108.97 129.122 100.466 130.838 91.4167 130.833ZM91.4167 117.75C106.026 117.75 118.401 112.68 128.541 102.541C138.68 92.401 143.75 80.0264 143.75 65.4167C143.75 50.8069 138.68 38.4323 128.541 28.2927C118.401 18.1531 106.026 13.0833 91.4167 13.0833C76.8069 13.0833 64.4323 18.1531 54.2927 28.2927C44.1531 38.4323 39.0833 50.8069 39.0833 65.4167C39.0833 80.0264 44.1531 92.401 54.2927 102.541C64.4323 112.68 76.8069 117.75 91.4167 117.75Z" fill={ap_userSecondaryColor} />
        </svg>
    );
}

export default function SubmissionApprovedIcon(props) {
    return <Icon component={SubmissionApproved} {...props} />
}