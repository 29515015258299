import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Drawer, theme, Spin } from "antd";

import InputLayout from "../InputLayout";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";
import { CheckOutlined } from '@ant-design/icons';
import styles from "./index.module.css";

export default function RequestCreditExtension({ data, setRefresh }) {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headerName, setHeaderName] = useState('');

    const requestExtensionForm = () => {
        setOpen(!open);
    }

    const [formFields, setFormFields] = useState(generateRequestExtensionFields());

    const handleExtensionDetails = (e) => {
        setFormFields({
            ...formFields,
            extensionDetails: {
                ...formFields.extensionDetails,
                value: e.target.value,
            }
        })
    }

    const form = {
        ...formFields,
        extensionDetails: {
            ...formFields.extensionDetails,
            eventHandler: handleExtensionDetails,
        }
    }

    const handleRequestExtension = () => {
        setLoading(true);
        if (!formFields.extensionDetails.value) {
            setFormFields({
                ...formFields,
                extensionDetails: {
                    ...formFields.extensionDetails,
                    error: 'Required'
                }
            });
            setLoading(false);
        } else {
            const obj = {
                ap_status: 'Credit Extension Requested',
                ap_extensionRequestNote: formFields?.extensionDetails?.value,
                ap_extensionRequestStatus: 'Pending',
            }
            // Update the status
            ApiService.updateApproval(id, obj).then((response) => {
                if (response.success) {
                    requestExtensionForm();
                    setRefresh((prev) => prev + 1);
                } else {
                    NotificationService.error('Error', 'Something wnet wrong, try again later');
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        const queryParameters = window.location.search;
        if (queryParameters) {
            const searchParams = new URLSearchParams(queryParameters);
            const requestExtension = searchParams.get('requestExtension');
            setOpen(requestExtension);
        }
        ApiService.fetchIndividualApprovals(id).then(approvalDetails => {
            setHeaderName(`${approvalDetails.ap_lenderId?.ap_abbreviation || approvalDetails.ap_lenderId?.ap_name} - ${approvalDetails.ap_clientId?.ap_businessName} - ${approvalDetails.ap_assetDescription}`);
        })
    }, [id]);

    return (
        <>
            {data.ap_extensionRequestNote ?
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    disabled={data.ap_status === 'Cancelled'}
                    className={styles["AmendmentRequestedButton"]}
                > Extension Requested</Button>
                :
                <Button
                    type="primary"
                    style={{ backgroundColor: ap_userPrimaryColor, display: 'block'}}
                    onClick={requestExtensionForm}
                    disabled={data.ap_status === 'Cancelled'}
                >Request Extension</Button>
            }

            <Drawer
                title='Request Extension'
                open={open}
                onClose={requestExtensionForm}
                getContainer=".AppTheme"
            >

                <Spin
                    tip="Sending..."
                    spinning={loading}
                >

                    <div className={styles['RequestAmendment_Header']}>{headerName}</div>
                    <div className={styles["RequestAmendment_Helper"]}>Please describe your request in the field below.</div>

                    {/* Generating Input Fields */}
                    <InputLayout data={form} layout='vertical' />

                    <Button
                        type="primary"
                        style={{ backgroundColor: ap_userPrimaryColor, display: 'block', fontWeight: "bold" }}
                        onClick={handleRequestExtension}
                    >Done</Button>

                </Spin>
            </Drawer>
        </>
    );
}

function generateRequestExtensionFields() {
    return ({
        extensionDetails: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'textarea',
            label: '',
        }
    })
}