import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Insurance = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M17 43.7221C17 32.0493 17 26.213 18.3797 24.2493C19.7557 22.2892 25.2417 20.4095 36.2173 16.6536L38.3087 15.9383C44.0283 13.9782 46.8862 13 49.85 13C52.8101 13 55.6717 13.9782 61.3913 15.9383L63.4827 16.6536C74.4583 20.4095 79.9442 22.2892 81.3203 24.2493C82.7 26.213 82.7 32.053 82.7 43.7221V49.4672C82.7 70.0459 67.2276 80.0359 57.5186 84.2736C54.887 85.4233 53.573 86 49.85 86C46.127 86 44.813 85.4233 42.1813 84.2736C32.4723 80.0323 17 70.0495 17 49.4672V43.7221Z" stroke="white" strokeWidth="6.5" />
            <path d="M38.0024 45.53L40.4998 38.0379H58.8138L61.3112 45.53M58.8138 53.8546C58.1515 53.8546 57.5163 53.5914 57.0479 53.1231C56.5796 52.6548 56.3165 52.0195 56.3165 51.3572C56.3165 50.6949 56.5796 50.0596 57.0479 49.5913C57.5163 49.1229 58.1515 48.8598 58.8138 48.8598C59.4762 48.8598 60.1114 49.1229 60.5797 49.5913C61.0481 50.0596 61.3112 50.6949 61.3112 51.3572C61.3112 52.0195 61.0481 52.6548 60.5797 53.1231C60.1114 53.5914 59.4762 53.8546 58.8138 53.8546ZM40.4998 53.8546C39.8375 53.8546 39.2022 53.5914 38.7339 53.1231C38.2655 52.6548 38.0024 52.0195 38.0024 51.3572C38.0024 50.6949 38.2655 50.0596 38.7339 49.5913C39.2022 49.1229 39.8375 48.8598 40.4998 48.8598C41.1621 48.8598 41.7974 49.1229 42.2657 49.5913C42.7341 50.0596 42.9972 50.6949 42.9972 51.3572C42.9972 52.0195 42.7341 52.6548 42.2657 53.1231C41.7974 53.5914 41.1621 53.8546 40.4998 53.8546ZM61.178 37.2054C60.845 36.2398 59.9127 35.5405 58.8138 35.5405H40.4998C39.401 35.5405 38.4686 36.2398 38.1356 37.2054L34.6726 47.1949V60.5142C34.6726 60.9558 34.848 61.3793 35.1602 61.6915C35.4725 62.0037 35.896 62.1791 36.3375 62.1791H38.0024C38.444 62.1791 38.8675 62.0037 39.1797 61.6915C39.4919 61.3793 39.6673 60.9558 39.6673 60.5142V58.8493H59.6463V60.5142C59.6463 60.9558 59.8217 61.3793 60.1339 61.6915C60.4462 62.0037 60.8696 62.1791 61.3112 62.1791H62.9761C63.4177 62.1791 63.8412 62.0037 64.1534 61.6915C64.4656 61.3793 64.641 60.9558 64.641 60.5142V47.1949L61.178 37.2054Z" fill="white" />
        </svg>
    );
};

export default function InsuranceIcon(props) {
    return <Icon component={Insurance} {...props} />
}