import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import { useState } from "react";

export default function GoBackButton() {

    const navigate = useNavigate();

    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    return (
        <>
            <Tooltip
                title="Back"
                arrow={false}
                placement="bottom"
            >

                <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={styles["back"]}
                >
                    {isHovered ?
                        <ArrowLeftOutlined onClick={() => navigate(generateBackPath())} /> :
                        <LeftOutlined onClick={() => navigate(generateBackPath())} />
                    }
                </div>

            </Tooltip>
        </>
    );
}


/**
 * @author Sohel Tharani
 * Generate a shortened back path from the current window location pathname.
 *
 * This function takes the window.location.pathname and creates a shortened back path
 * by removing the last segments of the path. The number of segments removed is half of the
 * total number of segments in the path (excluding leading and trailing slashes).
 *
 * ----------------PATCH----------------
 * @author Marven Parmar
 * 
 * If no history is available, the function will use the shortened back path.
 * 
 * @returns {string} The shortened back path.
 *
 * @example
 * // Example 1:
 * const pathName1 = '/approvals/{approval_id}/paymentschedules/{pps_id}';
 * generateBackPath(); // Returns '/approvals/{approval_id}'
 *
 * @example
 * // Example 2:
 * const pathName2 = '/approvals/{approval_id}';
 * generateBackPath(); // Returns '/approvals'
 */
function generateBackPath() {
    const history = window.history;
    if (history?.length && history.length > 2) return history.back();
    const pathName = window.location.pathname;
    const length = (pathName.split('/').filter(item => item !== '').length) / -2;
    return pathName.split('/').slice(0, length).join('/');
}
