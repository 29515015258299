import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Sales(open) {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["Sales"]}>
            <rect x="0.5" width="80" height="80" rx="5" fillOpacity="0.5" className={styles[open ? "Sales_Selected" : "Sales_BG"]} />
            <path d="M40.1717 42.7238C39.827 42.7238 39.4963 42.5869 39.2525 42.3431C39.0087 42.0993 38.8717 41.7686 38.8717 41.4238V40.1238C38.8717 39.779 39.0087 39.4484 39.2525 39.2046C39.4963 38.9608 39.827 38.8238 40.1717 38.8238C40.5165 38.8238 40.8472 38.9608 41.091 39.2046C41.3348 39.4484 41.4717 39.779 41.4717 40.1238V41.4238C41.4717 41.7686 41.3348 42.0993 41.091 42.3431C40.8472 42.5869 40.5165 42.7238 40.1717 42.7238ZM38.2217 45.9738C38.2217 45.4567 38.4272 44.9607 38.7929 44.595C39.1586 44.2293 39.6546 44.0238 40.1717 44.0238C40.6889 44.0238 41.1849 44.2293 41.5506 44.595C41.9163 44.9607 42.1217 45.4567 42.1217 45.9738C42.1217 46.491 41.9163 46.987 41.5506 47.3527C41.1849 47.7184 40.6889 47.9238 40.1717 47.9238C39.6546 47.9238 39.1586 47.7184 38.7929 47.3527C38.4272 46.987 38.2217 46.491 38.2217 45.9738Z" className={styles["Sales_Fill"]} />
            <path d="M40.6788 34.6782C39.4347 34.6197 38.3908 35.3152 38.2036 36.4371C38.1381 36.7687 37.946 37.0618 37.6681 37.2542C37.3902 37.4465 37.0483 37.5231 36.7149 37.4677C36.3815 37.4122 36.0828 37.2291 35.8821 36.9571C35.6814 36.6851 35.5946 36.3457 35.64 36.0107C36.1028 33.2339 38.6339 31.9781 40.8023 32.0821C41.9125 32.1341 43.0552 32.5332 43.934 33.3548C44.8349 34.1959 45.3718 35.4023 45.3718 36.8739C45.3718 38.5522 44.7114 39.7976 43.5739 40.5555C42.53 41.251 41.2495 41.4239 40.1718 41.4239C39.827 41.4239 39.4963 41.287 39.2525 41.0432C39.0087 40.7994 38.8717 40.4686 38.8717 40.1238C38.8717 39.779 39.0087 39.4484 39.2525 39.2046C39.4963 38.9608 39.827 38.8238 40.1717 38.8238C41.0427 38.8238 41.7136 38.6718 42.1322 38.3923C42.4572 38.1752 42.7718 37.7943 42.7718 36.8739C42.7718 36.0705 42.4962 35.57 42.1582 35.2541C41.7981 34.9174 41.2742 34.7081 40.6788 34.6782Z" className={styles["Sales_Fill"]} />
            <circle cx="40.5" cy="40" r="13" strokeWidth="3" className={styles["Sales_Stroke"]} />
        </svg>
    );
}

export default function HeaderSalesIcon({ open }) {
    const {
        token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor },
    } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
        display: 'flex',
        width: '68.5px',
        height: '60px',
    };

    return <Icon component={() => Sales(open)} style={themeColorVariables} />;
}
