import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Button, ConfigProvider, Layout, Modal, Spin, Steps } from "antd";

import generateRegistrationData from "./constants";
import RegistrationProfile from "../../layouts/RegistrationProfile";
import RegistrationBusinessProfile from "../../layouts/RegistrationBusinessProfile";
import RegistrationCommunication from "../../layouts/RegistrationCommunication";
import RegistrationComplete from "../../layouts/RegistrationComplete";

import utils from "../../utils";
import validate from "../../utils/Validations";
import HasError from "../../utils/HasErrors";
import constants from "../../constants";
import ApiService from "../../api";

import styles from './index.module.css';
import AuthService from "../../services/auth.service";
import GetStartedPage from "../../layouts/GetStartedPage";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

export default function Registration() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState(generateRegistrationData());
    const [getStarted, setGetStarted] = useState(false);
    const [loader, setLoader] = useState({ loading: false, tip: '' })

    // Steps Items
    const items = [
        { title: 'Login Information' },
        { title: 'Business Details' },
        { title: 'Communication Preferences' },
    ];

    // Keep Track of code validation
    const [code, setCode] = useState({
        code: '',
        validated: false,
        entityId: '',
        accessToken: '',
    });

    // Keep track whether the user registration is complete or not
    const [registered, setRegistered] = useState(false);
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [redirect, setRedirect] = useState(false);

    // Fetches the code from query parameter and validates it
    useEffect(() => {
        const queryParameters = window.location.search;
        if (queryParameters) {
            const searchParams = new URLSearchParams(queryParameters);
            const paramCode = searchParams.get('ap_code')
            if (paramCode.length > 0) {
                setLoader({ loading: true, tip: 'Validating URL...' })
                ApiService.validateCode(paramCode).then(resp => {
                    setCode({
                        code: (resp?.validated) ? paramCode : '',
                        validated: resp?.validated,
                        entityId: resp?.entityId,
                        accessToken: resp?.accessToken,
                    })
                    setLoader({ loading: false, tip: '' })
                });
            }
        } else setCode({ code: '', validated: false, entityId: '', accessToken: '' });
    }, []);

    // TODO: Move harcoded default salesId to configuration
    useEffect(() => {
        if (code.entityId) {
            // Fetch Agent Record
            setLoader({ loading: true, tip: 'Fetching Agent Data...' })
            ApiService.fetchAgentData(code.entityId, code.accessToken)
                .then(agentResponse => {
                    const contactData = agentResponse?.ap_contact_Id

                    setFormData({
                        ...formData,
                        firstName: {
                            ...formData.firstName,
                            value: contactData?.ap_firstName,
                        },
                        lastName: {
                            ...formData.lastName,
                            value: contactData?.ap_lastName,
                        },
                        email: {
                            ...formData.email,
                            value: contactData?.ap_email,
                            error: !validate.EmailValidation(contactData?.ap_email) ? 'Invalid Email Address' : '',
                            disabled: validate.EmailValidation(contactData?.ap_email),
                        },
                        phoneNumber: {
                            ...formData.phoneNumber,
                            value: validate.FormatTelephone(String(contactData?.ap_telephone)).input,
                            error: validate.FormatTelephone(String(contactData?.ap_telephone)).error,
                        },
                        agentType: {
                            ...formData.agentType,
                            value: agentResponse.ap_agentType,
                            error: '',
                        },
                        feePercent: {
                            ...formData.feePercent,
                            value: agentResponse.ap_feePercent,
                            error: '',
                        },
                        salesCoordinatorId: {
                            ...formData.salesCoordinatorId,
                            value: agentResponse?.ap_salesCoordinatorId?._id ? agentResponse?.ap_salesCoordinatorId?._id : '64da43b4d1e5368a32c84734',  // TODO: Move harcoded default salesId to configuration
                            error: '',
                        },
                        businessName: {
                            ...formData.businessName,
                            value: agentResponse.ap_businessName,
                        },
                        taxNumber: {
                            ...formData.taxNumber,
                            value: agentResponse.ap_taxNumber
                        },
                        address: {
                            ...formData.address,
                            value: agentResponse.ap_address,
                        },
                        city: {
                            ...formData.city,
                            value: agentResponse.ap_city,
                        },
                        provinceState: {
                            ...formData.provinceState,
                            value: agentResponse.ap_country ? agentResponse.ap_stateOrProvince : '',
                        },
                        postalZipCode: {
                            ...formData.postalZipCode,
                            value: agentResponse.ap_country ? ((agentResponse.ap_country === 'USA') ? validate.FormatAmericanZipCode(agentResponse.ap_postalCode).input : validate.FormatCanadianPostalCode(agentResponse.ap_postalCode).input) : '',
                            error: agentResponse.ap_country ? ((agentResponse.ap_country === 'USA') ? validate.FormatAmericanZipCode(agentResponse.ap_postalCode).error : validate.FormatCanadianPostalCode(agentResponse.ap_postalCode).error) : '',
                        },
                        country: {
                            ...formData.country,
                            value: agentResponse.ap_country,
                        }
                    })

                    setLoader({ loading: false, tip: '' })
                })
        }
    }, [code]);

    useEffect(() => {
        if (getStarted && !(code.validated)) {
            setTimeout(() => {
                setRedirect(true);
            }, 1500)
        }
    }, [code, getStarted]);

    useEffect(() => {
        if (redirect)
            setTimeout(() => {
                navigate('/regenerate-registration-link')
            }, 3000
        );
    }, [redirect]);

    const [nexts, setNexts] = useState({
        profileNext: false,
        businessNext: false,
        communicationNext: false,
    })

    // Form Theme
    const defaultTheme = {
        token: {
            colorBgContainer: 'white',
            colorBgLayout: '#F5F5F5',
            contentBgContainer: 'white',
            inputColorBg: '#FFFFFF',
            colorFillAlter: 'white',
            iconColor: 'white',
            colorPrimary: '#447EB7',
            // fontSize: '20px',
        }
    }

    // Event Handlers
    const handleFirstName = (e) => {
        setFormData({
            ...formData,
            firstName: {
                ...formData.firstName,
                value: e.target.value,
                error: nexts.profileNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    const handleLastName = (e) => {
        setFormData({
            ...formData,
            lastName: {
                ...formData.lastName,
                value: e.target.value,
                error: nexts.profileNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    const handleEmail = (e) => {
        setFormData({
            ...formData,
            email: {
                ...formData.email,
                value: e.target.value,
                error: (nexts.profileNext && !validate.EmailValidation(e.target.value)) ? 'Invalid Email Address' : '',
            }
        })
    }

    const handlePhoneNumber = (e) => {
        setFormData({
            ...formData,
            phoneNumber: {
                ...formData.phoneNumber,
                value: validate.FormatTelephone(e.target.value).input,
                error: nexts.profileNext ? validate.FormatTelephone(e.target.value).error : '',
            }
        })
    }

    const handlePassword = (e) => {
        setFormData({
            ...formData,
            password: {
                ...formData.password,
                value: e.target.value,
                error: validate.ValidatePassword(e.target.value),
            },
            confirmPassword: {
                ...formData.confirmPassword,
                error: validate.ValidateConfirmPassword(e.target.value, formData.confirmPassword.value),
            }
        })
    }

    const handleConfirmPassword = (e) => {
        setFormData({
            ...formData,
            confirmPassword: {
                ...formData.confirmPassword,
                value: e.target.value,
                error: validate.ValidateConfirmPassword(formData.password.value, e.target.value),
            }
        })
    }

    const handleBusinessName = (e) => {
        setFormData({
            ...formData,
            businessName: {
                ...formData.businessName,
                value: e.target.value,
                error: nexts.businessNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    const handleTaxNumber = (e) => {
        setFormData({
            ...formData,
            taxNumber: {
                ...formData.taxNumber,
                value: e.target.value,
                error: nexts.businessNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    const handleAddress = (e) => {
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                value: e.target.value,
                error: nexts.businessNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    const handleCity = (e) => {
        setFormData({
            ...formData,
            city: {
                ...formData.city,
                value: e.target.value,
                error: nexts.businessNext ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    const handleProvince = (provinceState) => {
        setFormData({
            ...formData,
            provinceState: {
                ...formData.provinceState,
                value: provinceState,
                error: nexts.businessNext ? validate.ValidateRequiredField(provinceState).errorMessage : '',
            }
        })
    }

    const handlePostalZipCode = (e) => {
        setFormData({
            ...formData,
            postalZipCode: {
                ...formData.postalZipCode,
                value: (formData.country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).input : validate.FormatCanadianPostalCode(e.target.value).input,
                error: nexts.businessNext ? (validate.ValidateRequiredField(e.target.value).errorMessage || (formData.country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).error : validate.FormatCanadianPostalCode(e.target.value).error) : '',
            }
        });
    }

    const handleCountry = (country) => {
        setFormData({
            ...formData,
            provinceState: {
                ...formData.provinceState,
                dropdownContent: (country === "USA") ? constants.AMERICAN_STATES : constants.CANADIAN_PROVINCES,
                value: '',
            },
            postalZipCode: {
                ...formData.postalZipCode,
                value: '',
                error: nexts.businessNext ? 'Required' : '',
            },
            country: {
                ...formData.country,
                value: country,
                error: nexts.businessNext ? validate.ValidateRequiredField(country).errorMessage : '',
            },
        })
    }

    const handleSMSNotification = (value) => {
        setFormData({
            ...formData,
            receiveSMSNotification: {
                ...formData.receiveSMSNotification,
                value: value,
            },
        });
    }

    const handleWhatsAppNotification = (value) => {
        setFormData({
            ...formData,
            receiveWhatsAppNotification: {
                ...formData.receiveWhatsAppNotification,
                value: value,
            },
        });
    }

    const handleEmailNotification = (value) => {
        setFormData({
            ...formData,
            receiveEmailNotification: {
                ...formData.receiveEmailNotification,
                value: value,
            },
        });
    }

    const handleNext = () => {
        // Update Nexts
        setNexts({
            ...nexts,
            profileNext: true,
        });

        let tempData = { ...formData };

        // Validate the data
        if (formData.currentStep.value === 0) {
            tempData = {
                ...formData,
                firstName: {
                    ...formData.firstName,
                    error: validate.ValidateRequiredField(formData.firstName.value).errorMessage,
                },
                lastName: {
                    ...formData.lastName,
                    error: validate.ValidateRequiredField(formData.lastName.value).errorMessage,
                },
                email: {
                    ...formData.email,
                    error: !validate.EmailValidation(formData.email.value) ? 'Invalid Email Address' : '',
                },
                phoneNumber: {
                    ...formData.phoneNumber,
                    error: validate.FormatTelephone(formData.phoneNumber.value).error
                },
                password: {
                    ...formData.password,
                    error: validate.ValidatePassword(formData.password.value)
                },
                confirmPassword: {
                    ...formData.confirmPassword,
                    error: validate.ValidateConfirmPassword(formData.password.value, formData.confirmPassword.value)
                },
            }
        } else if (formData.currentStep.value === 1) {
            tempData = {
                ...formData,
                businessName: {
                    ...formData.businessName,
                    error: validate.ValidateRequiredField(formData.businessName.value).errorMessage,
                },
                address: {
                    ...formData.address,
                    error: validate.ValidateRequiredField(formData.address.value).errorMessage,
                },
                city: {
                    ...formData.city,
                    error: validate.ValidateRequiredField(formData.city.value).errorMessage,
                },
                provinceState: {
                    ...formData.provinceState,
                    error: validate.ValidateRequiredField(formData.provinceState.value).errorMessage,
                },
                postalZipCode: {
                    ...formData.postalZipCode,
                    error: validate.ValidateRequiredField(formData.postalZipCode.value).errorMessage || (formData.country.value === 'USA' ? validate.FormatAmericanZipCode(formData.postalZipCode.value).error : validate.FormatCanadianPostalCode(formData.postalZipCode.value).error),
                },
                country: {
                    ...formData.country,
                    error: validate.ValidateRequiredField(formData.country.value).errorMessage,
                },
            }
        } else if (formData.currentStep.value === 2) {
            tempData = {
                ...formData,
            }
        }

        setFormData({
            ...tempData,
            currentStep: {
                ...formData.currentStep,
                value: (!HasError(tempData)) ? (formData.currentStep.value + 1) : formData.currentStep.value
            },
        });

    }

    const handleBack = () => {
        if (formData.currentStep.value <= 0 || formData.currentStep.value === 3) {
            navigate('/');
        } else {
            setFormData({
                ...formData,
                currentStep: {
                    ...formData.currentStep,
                    value: formData.currentStep.value - 1,
                }
            })
        }
    }

    const handleRegister = () => {
        setFormData({
            ...formData,
            currentStep: {
                ...formData.currentStep,
                value: (!HasError(formData)) ? (formData.currentStep.value + 1) : formData.currentStep.value
            },
        });

        if (!registered && !HasError(formData)) {

            const agentFormData = {
                ap_agentType: formData.agentType.value,
                ap_feePercent: formData.feePercent.value,
                ap_salesCoordinatorId: formData.salesCoordinatorId.value,
                ap_name: formData.firstName.value + ' ' + formData.lastName.value,
                ap_businessName: formData.businessName.value,
                ap_taxNumber: formData.taxNumber.value,
                ap_address: formData.address.value,
                ap_city: formData.city.value,
                ap_stateOrProvince: formData.provinceState.value,
                ap_state: (formData.country.value === 'USA') ? formData.provinceState.value : '',
                ap_province: (formData.country.value !== 'USA') ? formData.provinceState.value : '',
                ap_postalCode: formData.postalZipCode.value,
                ap_country: formData.country.value,
            }

            // Update Agent Profile
            setLoader({ loading: true, tip: 'Updating Agent Profile...' })
            ApiService.updateAgent(code.entityId, agentFormData, code.accessToken).then(agentResponse => {
                if (agentResponse.success) {
                    ApiService.fetchAgentContactsData(code.accessToken).then(contactResponse => {
                        if (contactResponse.success) {
                            const contacts = contactResponse.success;
                            const primaryContact = contacts.find(contact => contact.ap_primaryContact === true);
                            if (primaryContact) {
                                // Update Primary Contact
                                const primaryContactData = {
                                    ap_agentId: code.entityId,
                                    ap_firstName: formData.firstName.value,
                                    ap_lastName: formData.lastName.value,
                                    ap_email: formData.email.value,
                                    ap_telephone: utils.TransformTelephone(formData.phoneNumber.value),
                                    ap_primaryContact: true,
                                    ap_stateOrProvince: formData.provinceState.value,
                                    ap_state: (formData.country.value === 'USA') ? formData.provinceState.value : '',
                                    ap_province: (formData.country.value !== 'USA') ? formData.provinceState.value : '',
                                    ap_country: formData.country.value,
                                    ap_smsNotification: formData.receiveSMSNotification.value,
                                    ap_whatsAppNotification: formData.receiveWhatsAppNotification.value,
                                    ap_emailNotification: formData.receiveEmailNotification.value,
                                    ap_billingNotification: true,
                                }

                                ApiService.updateContactsData(primaryContact._id, primaryContactData, code.accessToken).then(contactResponse => {
                                    if (contactResponse.success) {
                                        // USER CREDENTAILS
                                        const userRegistration = {
                                            email_address: formData.email.value,
                                            username: formData.email.value,
                                            password: formData.password.value,
                                            is_registered: true,
                                            agent_id: code.entityId,
                                        }
                                        // Create User Login Credentials
                                        ApiService.createUserCredentails({ ...userRegistration, codeId: code.code }, code.accessToken).then(async (userResp) => {
                                            if (userResp.code === 200) {
                                                if (userResp?.data?.expired) {
                                                    setRegistered(true);
                                                    await AuthService.login({
                                                        email_address: formData.email.value,
                                                        password: formData.password.value
                                                    })
                                                }
                                            } else {
                                                setRegistrationFailed(true);
                                            }
                                        });
                                    } else {
                                        setRegistrationFailed(true);
                                    }
                                })
                            } else {
                                setRegistrationFailed(true);
                            }
                        }
                    });

                } else setRegistrationFailed(true)

                setLoader({ loading: false, tip: '' })
            })
        }
    }

    // Steps Data
    const profileStep = {
        firstName: {
            ...formData.firstName,
            eventHandler: handleFirstName,
        },
        lastName: {
            ...formData.lastName,
            eventHandler: handleLastName,
        },
        email: {
            ...formData.email,
            eventHandler: handleEmail,
        },
        phoneNumber: {
            ...formData.phoneNumber,
            eventHandler: handlePhoneNumber,
        },
        password: {
            ...formData.password,
            eventHandler: handlePassword,
        },
        confirmPassword: {
            ...formData.confirmPassword,
            eventHandler: handleConfirmPassword,
        },
    }

    const businessStep = {
        businessName: {
            ...formData.businessName,
            eventHandler: handleBusinessName,
        },
        taxNumber: {
            ...formData.taxNumber,
            eventHandler: handleTaxNumber,
        },
        address: {
            ...formData.address,
            eventHandler: handleAddress,
        },
        city: {
            ...formData.city,
            eventHandler: handleCity,
        },
        provinceState: {
            ...formData.provinceState,
            eventHandler: handleProvince,
            dropdownContent: (formData.country.value === 'USA') ? constants.AMERICAN_STATES : constants.CANADIAN_PROVINCES,
        },
        postalZipCode: {
            ...formData.postalZipCode,
            eventHandler: handlePostalZipCode,
        },
        country: {
            ...formData.country,
            eventHandler: handleCountry,
        },
    }

    const communicationStep = {
        receiveSMSNotification: {
            ...formData.receiveSMSNotification,
            eventHandler: handleSMSNotification,
        },
        receiveWhatsAppNotification: {
            ...formData.receiveWhatsAppNotification,
            eventHandler: handleWhatsAppNotification,
        },
        receiveEmailNotification: {
            ...formData.receiveEmailNotification,
            eventHandler: handleEmailNotification,
        },
    }

    // Output
    return (
        <ConfigProvider theme={defaultTheme}>
            {getStarted ?
                <Layout className={styles["Registration_Layout"]}>

                    <Content className={styles["Registration_Content"]}>

                        <div className={styles["Registration-Header"]}>Registration</div>

                        <Layout className={styles["Registration_InnerLayout"]} >

                            <div className={styles["Registration_Steps"]}>
                                <Steps
                                    current={formData.currentStep.value}
                                    items={items}
                                    size="small"
                                    labelPlacement="vertical"
                                />
                            </div>


                            <div style={{ width: '70%', margin: 'auto' }}>
                                {/* <div style={{ width: '70%', margin: '0% auto' }}> */}

                                <Spin spinning={loader?.loading} tip={loader?.tip}>
                                    {displayStepsForm(formData, profileStep, businessStep, communicationStep, registered, registrationFailed)}
                                </Spin>

                                {(formData.currentStep.value < 3) ?
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5% 0%' }}>
                                        <Button className={styles["Buttons"]} onClick={handleBack}>Back</Button>

                                        {(formData.currentStep.value === 2) ?
                                            <Button type="primary" className={styles["Buttons"]} onClick={handleRegister}>Register</Button> :
                                            <Button type="primary" className={styles["Buttons"]} onClick={handleNext}>Next</Button>
                                        }

                                    </div> : <></>
                                }

                            </div>

                            <Modal
                                title="Registration link is invalid or expired"
                                open={!(code.validated)}
                                closable={false}
                                footer={null}
                                style={{ top: '38%' }}
                                getContainer=".AppTheme"
                                width={'420px'}
                            >   
                                {
                                    redirect ?
                                        <div style={{margin: '20px 0px 5px'}}>
                                            <div><LoadingOutlined style={{ fontSize: '28px' }} /></div>
                                            <div>Please wait, we are redirecting you to regenerate link page.</div>
                                        </div> :
                                        <></>
                                }

                            </Modal>

                        </Layout>

                    </Content>

                </Layout> :
                <GetStartedPage setGetStarted={setGetStarted} formData={formData} />
            }
        </ConfigProvider>
    );

}

function displayStepsForm(formData, profileStep, businessStep, communicationStep, registered, registrationFailed) {
    switch (formData.currentStep.value) {
        case 0: return <RegistrationProfile data={profileStep} />
        case 1: return <RegistrationBusinessProfile data={businessStep} />
        case 2: return <RegistrationCommunication data={communicationStep} />
        case 3: return <RegistrationComplete registered={registered} registrationFailed={registrationFailed} data={formData} />
        default: return <>Login Info</>
    }
}