import { useEffect, useState } from "react";
import { Skeleton, Tabs } from "antd";
import { DualAxes } from "@ant-design/plots";

import EmptyDataComponent from "../../../components/EmptyDataComponent";
import EmptyGrowthIcon from "../../../assets/images/icons/Home/EmptyGrowthIcon";

import growthChartUtils from "./utils";
import growthChartConstants from "./constants";
import ApiService from "../../../api";

import styles from "./index.module.css";

export default function HomeGrowthChart2() {

    const [loader, setLoader] = useState({ loader: false, message: "" });
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [timeState, setTimeState] = useState("daily"); // timeState: ["daily", "monthly", "yearly"];
    const [metrics, setMetrics] = useState({
        totalVolume: 0,
        totalFee: 0,
        totalYield: 0,
    });

    useEffect(() => {
        setLoader({ loader: true, message: "Fetching Data..." });
        ApiService.fetchDrawdownsForGrowthChart().then((drawdowns) => {
            setData(growthChartConstants?.cleanData(drawdowns));
        });
        setLoader({ loader: false, message: "" });
    }, []);

    useEffect(() => {
        setLoader({ loader: true, message: "Visualizing Data..." });
        setChartData(growthChartUtils.getChartData(data, timeState));
        setMetrics(growthChartUtils.calculateMetrics(data));
        setTimeout(() => setLoader({ loader: false, message: "" }), 1000);
    }, [data, timeState]);


    const GrowthChart = () => {
        const config = growthChartConstants.growthChartConfiguration(chartData);
        const chartTabs = [
            { key: 'daily', label: 'Daily' },
            { key: 'monthly', label: 'Monthly' },
            { key: 'yearly', label: 'Yearly' },
        ];
        
        return (
            <>
                <Tabs
                    activeKey={timeState}
                    defaultActiveKey="monthly"
                    items={chartTabs}
                    onChange={(key) => setTimeState(key)}
                />
                {chartData?.length ?
                    <DualAxes {...config} /> :
                    <EmptyDataComponent
                        flex={true}
                        className="centerPie"
                        iconComponent={<EmptyGrowthIcon />}
                        ContentLine1={"There is nothing to show here."}
                        ContentLine2={"You currently have no statistics."}
                    />
                }

            </>
        );
    }
    const metricsData = growthChartConstants?.metricsComponentData(metrics);

    return (
        <div className={styles["Container"]}>
            <div className={styles["Metrics_Container"]}>
                {metricsData?.map((item, index) => (
                    <div key={index} className={styles["Metrics_Inner"]}>
                        <div className={styles["Metrics_Icon"]}>{item?.icon}</div>
                        <div className={styles["Metrics_Data"]}>

                            <div className={styles["Metrics_Value"]}>
                                {loader.loader ? <Skeleton.Input active={true} size="small" /> : (item?.value)}
                            </div>

                            <div className={styles["Metrics_Label"]}>{item?.label}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <GrowthChart className={styles["Metrics_Container"]} />
            </div>
        </div>
    );
}