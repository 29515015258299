import { ConfigProvider, Form, Switch, theme } from 'antd';
import styles from "./index.module.css";

export default function SwitchComponent({ data, item, layout }) {

    const { token: { themePrimaryColor, userPrimaryColor } } = theme.useToken();
    return (
        // TODO: Provide correct theme colors for the switch component
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: "green",
                },
            }}
        >
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <>
                        <Form.Item
                            key={item}
                            label={data.label}
                        >
                            {layout == 'vertical' ?
                                <Switch
                                    checked={data.value}
                                    onChange={data.eventHandler}
                                    disabled={data.disabled}
                                /> : null
                            }
                        </Form.Item>

                        {layout == 'horizontal' ?
                            <div style={{ textAlign: 'right' }}>
                                <Switch
                                    checked={data.value}
                                    onChange={data.eventHandler}
                                    style={{ width: 'fit-content', }}
                                    disabled={data.disabled}
                                />
                            </div>
                            : null}
                    </>
                </div>
            )}
        </ConfigProvider>
    )
}