// export default [
//     { 
//         value: 99320000, 
//         label: 'Owner Operator', 
//         disabled: false 
//     },
// ];

export default [
    {
        label: 'Owner Operator',
        value: 'Owner Operator',
    },
    {
        label: 'Commercial',
        value: 'Commercial',
    },
]