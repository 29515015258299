import { useEffect, useState } from "react";
import { ConfigProvider, Modal, theme } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import ApiService from "../../api";

import styles from "./index.module.css";
import { ApprovalSelectionStep, AssetTransferReasonStep, TransferRequested } from "./utils";

export default function TransferToApprovals({ applicationId, clientId, transferToApprovals, setTransferToApprovals, selectedRows }) {
    const { token: { themeFontColor, themePrimaryColor } } = theme.useToken();
    const userTheme = { token: { colorText: themeFontColor } };
    const modalProps = {
        open: transferToApprovals,
        footer: null,
        closable: false,
        getContainer: ".AppTheme",
        width: "80vw",
        centered: true
    }

    const [loading, setLoading] = useState(false);
    
    const [transferSteps, setTransferSteps] = useState(0);
    const [transferReason, setTransferReason] = useState("");
    const [totalAssetPrice, setTotalAssetPrice] = useState(0);
    const [approvals, setApprovals] = useState([]);
    const [selectedApprovalsKeys, setSelectedApprovalsKeys] = useState([]);
    const [selectedApprovals, setSelectedApprovals] = useState([]);
    const [transfering, setTransfering] = useState(false);
    const [transfered, setTransfered] = useState(false);

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedApprovalsKeys(newSelectedRowKeys);
        setSelectedApprovals(selectedRows);
    }

    const onNext = () => {
        setTransferSteps(step => step + 1);
    }

    const onPrev = () => {
        setTransferSteps(step => step - 1);
    }

    const rowSelection = {
        selectedApprovalsKeys,
        type: 'checkbox',
        onChange: onSelectChange,
    };
    const hasSelected = selectedApprovalsKeys.length > 0;

    // Calculate Max Credit Available
    useEffect(() => {
        setLoading(true);
        const totalApPrice = selectedRows.reduce((sum, item) => sum + item.ap_price, 0);
        setTotalAssetPrice(totalApPrice);
        setLoading(false);
    }, [selectedRows, transferToApprovals]);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchClientCreditAvailableApprovals(clientId, totalAssetPrice * 100).then(approvalResponse => {
            setLoading(false);
            setApprovals(approvalResponse);
        })
    }, [clientId, totalAssetPrice]);

    const closeModal = () => {
        setTransferToApprovals(false);
        setSelectedApprovalsKeys([]);
        setSelectedApprovals([]);
        setTransfered(false);
        setTransferSteps(0);
        setTransferReason("");
    }

    const handleTransfer = () => {
        const selectedApprovalsIds = selectedApprovals.map(approval => approval?._id);
        const selectedAssetIds = selectedRows.map(asset => asset?._id);

        const data = {
            ap_applicationId: applicationId,
            ap_requestedAssets: selectedAssetIds,
            ap_approvalIds: selectedApprovalsIds,
            ap_transferReason :  transferReason
        }
        
        ApiService.createAssetTransferRequest(data).then(response => {
            if(response.success){
                setTransfering(false);
                setTransfered(true);
                setTransferSteps(0);
                setTransferReason("");
            }else if(response.error){
                closeModal();
            }
        });
    }

    return (
        <ConfigProvider theme={userTheme}>
            <Modal {...modalProps}>

                <div className={styles["Container"]}>

                    {/* Header */}
                    <div className={styles["Header"]}>
                        <CloseOutlined
                            onClick={closeModal}
                            className={styles["Close"]}
                        />
                        Asset Transfer
                    </div>

                    {!transfered && (
                        <>
                        {
                            transferSteps === 0 ? (
                                <ApprovalSelectionStep 
                                    selectedRows={selectedRows} 
                                    approvals={approvals} 
                                    loading={loading} 
                                    rowSelection={rowSelection} 
                                    transfering={transfering}
                                    handleNext={onNext} 
                                    hasSelected={hasSelected}
                                    setTransferToApprovals={setTransferToApprovals}
                                    selectedApprovals={selectedApprovals}
                                    
                                />
                            ) : transferSteps === 1 ? (
                                <AssetTransferReasonStep 
                                    selectedApprovalList={selectedApprovals}
                                    transferReason={transferReason}
                                    setTransferReason={setTransferReason}
                                    setTransferToApprovals={setTransferToApprovals}
                                    handleTransfer={handleTransfer}
                                    handlePrev={onPrev}
                                    hasSelected={hasSelected}
                                    transfering={transfering}
                                    selectedApprovals={selectedApprovals}
                                />
                            ) : (<></>)
                        }
                        </>
                    )}

                    {transfered && (
                        <TransferRequested 
                            selectedRows={selectedRows} 
                            selectedApprovals={selectedApprovals}
                            closeModal={closeModal}
                        />
                    )}

                </div>
            </Modal>
        </ConfigProvider>
    );
}