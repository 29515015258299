import Icon from '@ant-design/icons';
import { theme } from "antd";

function Email() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="20" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.0998C0 2.3572 0.294999 1.64501 0.820101 1.11991C1.3452 0.594804 2.05739 0.299805 2.8 0.299805H25.2C25.9426 0.299805 26.6548 0.594804 27.1799 1.11991C27.705 1.64501 28 2.3572 28 3.0998V19.8998C28 20.6424 27.705 21.3546 27.1799 21.8797C26.6548 22.4048 25.9426 22.6998 25.2 22.6998H2.8C2.05739 22.6998 1.3452 22.4048 0.820101 21.8797C0.294999 21.3546 0 20.6424 0 19.8998V3.0998ZM4.9266 3.0998L14 11.0392L23.0734 3.0998H4.9266ZM25.2 4.9604L14.9226 13.954C14.6673 14.1777 14.3394 14.301 14 14.301C13.6606 14.301 13.3327 14.1777 13.0774 13.954L2.8 4.9604V19.8998H25.2V4.9604Z" fill={ap_userPrimaryColor} />
        </svg>
    )
}

export default function EmailSign(props) {
    return <Icon component={Email} {...props} />
}