export default function ValidateKMS(kms){
    let kmsInt = parseInt(kms);

    if(!isNaN(kmsInt)){
        return {
            value: kmsInt,
            error: CheckKMs(kms)?'':'Please provide correct KMs'
        }
    }

    return {
        value: '',
        error: 'Please enter correct KMs'
    }
}

function CheckKMs(kms){
    return /^[0-9]\d*$/.test(kms)
}