import { Button, Drawer, Radio, Spin, theme } from "antd";
import { useEffect, useState } from "react";
import InputLayout from "../InputLayout";
import validate from "../../utils/Validations";
import { useParams, useNavigate } from "react-router-dom";
import ApiService from "../../api";
import utils from "../../utils";
import ppsConstants from './contants';

import NotificationService from "../../services/notification.service";

import styles from "./index.module.css";

export default function ModifyPaymentSchedule({ setLoader, approvalData, id, paymentScheduleId, isDrawdown }) {

    // const { id, paymentScheduleId } = useParams();
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(ppsConstants.generateModifyPaymentScheduleData());
    const [open, setOpen] = useState(false);
    const [existingPPSData, setexistingPPSData] = useState({});
    const [updatedPaymentFields, setUpdatedPaymentFields] = useState({});
    const [paymentScheduleBalancedData, setPaymentScheduleBalancedData] = useState({});
    const [header, setHeader] = useState("");
    const [lockType, setLockType] = useState("Commission");
    const [requestDisabled, setRequestDisabled] = useState(true);

    const ppsData = ppsConstants.generatePpsData(existingPPSData);

    // Event Handlers for modification side panel
    const handleOpen = () => {
        setOpen(!open);
    }

    const handleTerm = (e) => {
        let value;
        if (typeof (e) === 'object')
            value = e.target.value
        else value = e;

        setFormData(prevFormData => ({
            ...prevFormData,
            term: {
                ...prevFormData.term,
                value: validate.WithinRange(formData.term.min, value, formData.term.max).value,
                error: validate.WithinRange(formData.term.min, value, formData.term.max).error
            }
        }))
    }

    const handleDownPayment = (e) => {
        let value;
        if (typeof (e) === 'object')
            value = e.target.value
        else value = e;

        setFormData(prevFormData => ({
            ...prevFormData,
            downPayment: {
                ...prevFormData.downPayment,
                value: validate.WithinRange(formData.downPayment.min, value, formData.downPayment.max).value,
                error: validate.WithinRange(formData.downPayment.min, value, formData.downPayment.max).error
            }
        }))

    }

    const handleCommission = (e) => {
        let value;
        if (typeof (e) === 'object')
            value = e.target.value
        else value = e;

        setFormData(prevFormData => ({
            ...prevFormData,
            commission: {
                ...prevFormData.commission,
                value: validate.WithinRange(formData.commission.min, value, formData.commission.max).value,
                error: validate.WithinRange(formData.commission.min, value, formData.commission.max).error
            }
        }))

    }

    useEffect(() => {
        if (formData?.commission?.error || formData?.downPayment?.error || formData?.term?.error) return;

        const delay = 2000;
        const debounce = setTimeout(() => {

            // These conditions are just to see if there are any changes to any of the fields, if true, process to call the PPS-MS service
            const commissionCondition = (!formData?.commission?.value && !existingPPSData.ap_actualCommission) || Number(formData?.commission?.value) === Number(existingPPSData.ap_actualCommission / 100)
            const downPaymentCondition = ((!formData?.downPayment?.value && !existingPPSData.ap_downPaymentAmount) || Number(formData?.downPayment?.value) === Number(existingPPSData.ap_downPaymentAmount / 100))
            const termCondition = ((!formData?.term?.value && !existingPPSData.ap_term) || Number(formData?.term?.value) === Number(existingPPSData.ap_term))

            if (commissionCondition && downPaymentCondition && termCondition) {
                return;
            }

            if (ppsData) {
                ppsData["commissionAmount"] = Number(formData?.commission?.value);
                ppsData["downPaymentAmount"] = Number(formData?.downPayment?.value);
                ppsData["term"] = Number(formData?.term?.value);

                if (ppsData?.specialPayments?.interestOnlyPayments) {
                    const updatedArr = ppsData.specialPayments.interestOnlyPayments["paymentNumbers"].filter(value => value <= ppsData["term"]);
                    ppsData.specialPayments.interestOnlyPayments["paymentNumbers"] = updatedArr;
                } else if (ppsData?.specialPayments?.fixedPayments) {
                    const updatedArr = ppsData.specialPayments.fixedPayments["paymentNumbers"].filter(value => value <= ppsData["term"]);
                    ppsData.specialPayments.fixedPayments["paymentNumbers"] = updatedArr;
                }

                if (Number(formData?.downPayment?.value) !== Number(existingPPSData.ap_downPaymentAmount / 100)) {
                    ppsData["downPaymentType"] = 'Other';
                }

                // PPS-MS call
                setLoading(true);
                ApiService.fetchBalancedPPS(ppsData).then((response) => {
                    if (response?.success) {
                        setUpdatedPaymentFields({
                            ap_regularMonthlyPayment: response.success.calculatedValues.regularPaymentAmount * 100,
                            ap_clientRate: response.success.calculatedValues.annualClientRate
                        });
                        setPaymentScheduleBalancedData(response.success);
                    } else if (!response?.error || (!response?.error?.commissionAmount?.[0] && !response?.error?.downPaymentAmount?.[0] && !response?.error?.term?.[0])) {
                        setOpen(false);
                    }

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        commission: {
                            ...prevFormData.commission,
                            value: validate.WithinRange(formData.commission.min, formData?.commission?.value, formData.commission.max).value,
                            error: response?.error?.commissionAmount?.[0] || validate.WithinRange(formData.commission.min, formData?.commission?.value, formData.commission.max).error
                        },
                        downPayment: {
                            ...prevFormData.downPayment,
                            value: validate.WithinRange(formData.downPayment.min, formData?.downPayment?.value, formData.downPayment.max).value,
                            error: response?.error?.downPaymentAmount?.[0] || validate.WithinRange(formData.downPayment.min, formData?.downPayment?.value, formData.downPayment.max).error
                        },
                        term: {
                            ...prevFormData.term,
                            value: validate.WithinRange(formData.term.min, formData?.term?.value, formData.term.max).value,
                            error: response?.error?.term?.[0] || validate.WithinRange(formData.term.min, formData?.term?.value, formData.term.max).error
                        },

                    }))
                    setLoading(false);
                });
            }
        }, delay)
        return () => {
            clearTimeout(debounce);
        }
    }, [formData.commission.value, formData.downPayment.value, formData.term.value]);


    useEffect(() => {
        if (Object.keys(paymentScheduleBalancedData).length === 0) {
            return;
        }

        if ((paymentScheduleBalancedData?.calculatedValues?.regularPaymentAmount * 100 === existingPPSData?.ap_regularMonthlyPayment) && (paymentScheduleBalancedData?.calculatedValues?.annualClientRate === existingPPSData?.ap_clientRate)) {
            return;
        }

        setRequestDisabled(false);

    }, [paymentScheduleBalancedData]);

    const handleSubmit = () => {

        if (Object.keys(paymentScheduleBalancedData).length === 0) {
            return;
        }

        if ((paymentScheduleBalancedData?.calculatedValues?.regularPaymentAmount * 100 === existingPPSData?.ap_regularMonthlyPayment) && (paymentScheduleBalancedData?.calculatedValues?.annualClientRate === existingPPSData?.ap_clientRate)) {
            return;
        }

        const ppsDraftData = ppsConstants.generatePpsDraftData(existingPPSData, paymentScheduleBalancedData, lockType);

        ApiService.createDraftPaymentSchedule(ppsDraftData).then((response) => {
            if (response?.success) {
                const ppsDraftId = response.success.paymentScheduleDraftId;
                const newPpsRequestData = {
                    ap_approvalId: id,
                    ap_draftPaymentScheduleId: ppsDraftId
                }
                ApiService.createNewPPSRequest(newPpsRequestData).then(response => {
                    if (response.success) {
                        NotificationService.success('Success', "New Schedule Requested");
                        setRequestDisabled(true)
                        setOpen(!open)
                        navigate(`/approvals/${id}/paymentschedules/${ppsDraftId}`);
                    } else {
                        NotificationService.error('Error', "There was an error in requesting new schedule, please try again later")
                    }
                })
            }
        })
    }

    const handleRadio = ({ target: { value } }) => {
        setLockType(value);
    }

    useEffect(() => {
        if (!open) return;
        setLoader({ loading: true, tip: "Fetching data..." });

        ApiService.fetchIndividualPaymentSchedule(paymentScheduleId).then((paymentScheduleData) => {
            setexistingPPSData(paymentScheduleData);
            setLoader({ loading: false, tip: "" })
        })

    }, [id, paymentScheduleId, open]);

    useEffect(() => {
        if (Object.keys(existingPPSData).length === 0) return;
        setLockType(prevLockType => existingPPSData?.ap_expectedLockedIn || prevLockType)
        setLoader({ loading: true, tip: "Fetching data..." });
        ApiService.fetchIndividualApprovals(id).then((approvalData) => {
            setHeader(`${approvalData.ap_lenderId?.ap_abbreviation || approvalData.ap_lenderId?.ap_name} - ${approvalData.ap_clientId?.ap_businessName} - ${existingPPSData.ap_assetDescription}`);
            setFormData({
                ...formData,
                term: {
                    ...formData.term,
                    value: Number(existingPPSData.ap_term),
                    min: 12,
                    max: Number(approvalData.ap_term),
                    marks: {
                        12: {
                            label: "12 months",
                            style: { transform: 'translateX(-10%)', fontSize: 'small' }
                        },
                        [Number(approvalData.ap_term)]: {
                            label: <div style={{ whiteSpace: 'nowrap' }}>{Number(approvalData.ap_term)} months</div>,
                            style: { transform: 'translateX(-90%)', fontSize: 'small' }
                        }
                    }
                },
                downPayment: {
                    ...formData.downPayment,
                    value: (existingPPSData.ap_downPaymentAmount / 100),
                    min: (existingPPSData.ap_downPaymentAmount / 100),
                    max: (existingPPSData.ap_baseAmount / 100),
                    marks: {
                        [existingPPSData.ap_downPaymentAmount / 100]: {
                            label: utils.TransformDBCurrency(existingPPSData.ap_downPaymentAmount),
                            style: { transform: 'translateX(-10%)', fontSize: 'small' }
                        },
                        [existingPPSData.ap_baseAmount / 100]: {
                            label: utils.TransformDBCurrency(existingPPSData.ap_baseAmount),
                            style: { transform: 'translateX(-90%)', fontSize: 'small' }
                        }
                    }
                },
                commission: {
                    ...formData.commission,
                    value: (existingPPSData.ap_actualCommission / 100),
                    min: 0,
                    max: (existingPPSData.ap_maxCommissionAmount / 100),
                    marks: {
                        0: {
                            label: '$0',
                            style: { transform: 'translateX(-10%)', fontSize: 'small' }
                        },
                        [existingPPSData.ap_maxCommissionAmount / 100]: {
                            label: utils.TransformDBCurrency(existingPPSData.ap_maxCommissionAmount),
                            style: { transform: 'translateX(-90%)', fontSize: 'small' }
                        }
                    }
                },
            });
            setUpdatedPaymentFields({
                ap_regularMonthlyPayment: existingPPSData.ap_regularMonthlyPayment,
                ap_clientRate: existingPPSData.ap_clientRate
            });
            setLoader({ loading: false, tip: "" })
        });
    }, [existingPPSData])

    const formFields = {
        term: {
            ...formData.term,
            eventHandler: handleTerm,
        },
        downPayment: {
            ...formData.downPayment,
            eventHandler: handleDownPayment,
        },
        commission: {
            ...formData.commission,
            eventHandler: handleCommission,
        },
    }

    const lockingType = [
        {
            label: (lockType === 'Commission') ? <span style={{ color: ap_userPrimaryColor, fontWeight: 'bold', }}>Commission</span> : 'Commission',
            value: 'Commission'
        },
        {
            label: (lockType === 'Monthly Payment') ? <span style={{ color: ap_userPrimaryColor, fontWeight: 'bold', }}>Monthly Payment</span> : 'Monthly Payment',
            value: 'Monthly Payment'
        },
        {
            label: (lockType === 'Client Rate') ? <span style={{ color: ap_userPrimaryColor, fontWeight: 'bold', }}>Client Rate</span> : 'Client Rate',
            value: 'Client Rate'
        },
    ]

    return (
        <>
            {!isDrawdown ?
                <Button
                    type="primary"
                    onClick={handleOpen}
                    className={styles["ModifyButton"]}
                    disabled={approvalData?.ap_status === 'Cancelled'}
                >Modify</Button>
                : <></>
            }



            <Drawer
                title="Modify Payment Schedule"
                open={open}
                onClose={handleOpen}
                width="450px"
                getContainer=".AppTheme"
            >

                <Spin spinning={loading} tip="Calculating Payment Schedule..." size="large">
                    <div style={{ fontWeight: 'bold', fontSize: '16px', color: '#898989', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', paddingBottom: '5%', textAlign: 'justify', textTransform: 'uppercase' }}>{header}</div>
                    <div className={styles["subHeader"]}>Please select which section to keep the same:</div>

                    <Radio.Group options={lockingType} value={lockType} onChange={handleRadio} style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', padding: '0% 0% 5% 0%' }} />

                    <div style={{ display: 'grid', gridTemplateColumns: '48% 48%', gridGap: '1rem' }}>
                        <InputLayout data={ppsConstants.monthlyPaymentField(updatedPaymentFields)} layout='vertical' />
                        <InputLayout data={ppsConstants.clientRateField(updatedPaymentFields)} layout='vertical' />
                    </div>

                    <div className={styles["subHeader"]}>Please use the sliders to modify the Payment Schedule:</div>
                    <InputLayout data={formFields} layout='vertical' />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={requestDisabled}
                        >
                            Request New Schedule
                        </Button>
                    </div>
                </Spin>
            </Drawer>
        </>
    );
}