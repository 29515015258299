import { theme } from "antd";
import utils from "../../utils";
import validate from "../../utils/Validations";

function generateFormFields() {
    return {
        ap_paymentAmount: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: <b>Payment Amount</b>,
            placeholder: '$',
        },
        ap_leaseAmount: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: <b>Lease Amount</b>,
            placeholder: '$',
        },
        ap_downPaymentAmount: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: <b>Down Payment Amount</b>,
            placeholder: "$",
        },
        ap_numberOfPayments: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'number',
            label: <b>Number of Payments</b>,
            suffix: 'month(s)',
        },
        ap_interestRate: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'number',
            label: <b>Interest Rate</b>,
            suffix: '%',
        },
        ap_residualAmount: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: <b>Residual Amount</b>,
            placeholder: '$',
        },
    }
}

function CheckErrors(formFields, calcType) {
    const data = {
        leaseAmount: String(utils.TransformCurrencyToNumber(formFields?.ap_leaseAmount?.value)),
        minDownPayment: 0,
        downPayment: utils.TransformCurrencyToNumber(formFields?.ap_downPaymentAmount?.value),
        maxDownPayment: utils.TransformCurrencyToNumber(formFields?.ap_leaseAmount?.value),
        minTerm: 12,
        term: Number(formFields?.ap_numberOfPayments?.value) || 0,
        maxTerm: 72,
        interestRate: (calcType === 'paymentAmount') ? formFields?.ap_interestRate?.value : 0,
        minResidual: 0,
        residual: utils.TransformCurrencyToNumber(formFields?.ap_residualAmount?.value),
        maxResidual: utils.TransformCurrencyToNumber(formFields?.ap_leaseAmount?.value) - utils.TransformCurrencyToNumber(formFields?.ap_downPaymentAmount?.value),
        paymentAmount: String(utils.TransformCurrencyToNumber(formFields?.ap_paymentAmount?.value))   // TODO: Add min-max range for payment amount after service implementation
    }

    return {
        ...formFields,
        ap_leaseAmount: {
            ...formFields.ap_leaseAmount,
            error: validate.ValidateCurrency(data?.leaseAmount).error
        },
        ap_downPaymentAmount: {
            ...formFields.ap_downPaymentAmount,
            error: utils.isBetweenValue(data?.minDownPayment, data?.downPayment, data?.maxDownPayment)
        },
        ap_numberOfPayments: {
            ...formFields.ap_numberOfPayments,
            error: utils.isBetweenValue(data?.minTerm, data?.term, data?.maxTerm),
        },
        ap_interestRate: {
            ...formFields.ap_interestRate,
            error: ((data?.interestRate <= 0) && (calcType === 'paymentAmount') ? "Incorrect Interest Rate" : ""),
        },
        ap_paymentAmount: {
            ...formFields.ap_paymentAmount,
            error: (calcType !== 'paymentAmount') ? validate.ValidateCurrency(data?.paymentAmount).error : '',       // TODO: To add validation for paymentAmount after service implemntation
        },
        ap_residualAmount: {
            ...formFields.ap_residualAmount,
            error: utils.isBetweenValue(data?.minResidual, data?.residual, data?.maxResidual)
        }
    }
}

function calcTypeOptions(calcType) {
    const { token: { ap_userPrimaryColor, themeFontColor }, } = theme.useToken();
    return [
        {
            label: <div style={(calcType === 'paymentAmount') ? { color: ap_userPrimaryColor } : { color: themeFontColor }}><b>Payment Amount</b></div>,
            value: "paymentAmount",
        },
        {
            label: <div style={(calcType === 'interestRate') ? { color: ap_userPrimaryColor } : { color: themeFontColor }}><b>Interest Rate</b></div>,
            value: "interestRate",
        },
    ];
}

function generateCalculationData(calcType, formFields) {
    const currentDate = new Date();
    return {
        "lockedIn": "commissionAmount",
        "generateHTML": false,
        "commissionType": "%invoice",
        "maxCommissionPercent": 1,
        "maximumYield": 0,
        "maxCommissionAmount": 1,
        "commissionAmount": 0,
        "downPaymentType": "Other",
        "downPaymentAmount": utils.TransformCurrencyToNumber(formFields?.ap_downPaymentAmount?.value),
        "bonusDownPaymentAmount": 0,
        "baseAmount": utils.TransformCurrencyToNumber(formFields?.ap_leaseAmount?.value),
        "docFeeToClient": 0,
        "otherFees": 0,
        "annualCostOfFunds": (calcType === 'paymentAmount') ? formFields?.ap_interestRate?.value : 0,
        "annualClientRate": 0,
        "residualPurchaseOption": utils.TransformCurrencyToNumber(formFields?.ap_residualAmount?.value),
        "initiationDate": currentDate.toISOString().split('T')[0],
        "downPaymentDate": currentDate.toISOString().split('T')[0],
        "firstRegularPaymentDate": new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()).toISOString().split('T')[0],
        "daysInYear": 365,
        "term": formFields?.ap_numberOfPayments?.value,
        "regularPaymentAmount": (calcType == 'paymentAmount') ? 0 : utils.TransformCurrencyToNumber(formFields?.ap_paymentAmount?.value),
    }
}

function findLabel(calcType) {
    const options = [
        { label: "Payment Amount", value: "paymentAmount" },
        { label: "Interest Rate", value: "interestRate", },
    ];
    for (const option of options) {
        if (option?.value === calcType)
            return option?.label;
    }
}

export default {
    generateFormFields: generateFormFields,
    CheckErrors: CheckErrors,
    calcTypeOptions: calcTypeOptions,
    findLabel: findLabel,
    generateCalculationData: generateCalculationData
}