// Converts Currency fields from cents to dollars
export default function CleanData(data) {
    return {
        ...data,
        ap_maxCommissionAmount: data.ap_maxCommissionAmount / 100,
        ap_actualCommission: data.ap_actualCommission / 100,
        ap_baseAmount: data.ap_baseAmount / 100,
        ap_totalDocFeeToClient: data.ap_totalDocFeeToClient / 100,
        ap_otherFees: data.ap_otherFees / 100,
        ap_downPaymentAmount: data.ap_downPaymentAmount / 100,
        ap_bonusDownPaymentAmount: data.ap_bonusDownPaymentAmount / 100,
        ap_purchaseOption: data.ap_purchaseOption / 100,
        ap_regularMonthlyPayment: data.ap_regularMonthlyPayment / 100,
        ap_brokerDownPaymentInterest: data.ap_brokerDownPaymentInterest / 100,
        ap_brokerFirstPaymentInterest: data.ap_brokerFirstPaymentInterest / 100,
        ap_clientDownPaymentInterest: data.ap_clientDownPaymentInterest / 100,
        ap_clientFirstPaymentInterest: data.ap_clientFirstPaymentInterest / 100,
        ap_totalOfPayments: data.ap_totalOfPayments / 100,
        ap_taxAmount: data.ap_taxAmount / 100,
        ap_acceleratedAmount: data.ap_acceleratedAmount / 100,
        ap_acceleratedMonthlyBonus: data.ap_acceleratedMonthlyBonus / 100,
        ap_skipAmount: data.ap_skipAmount / 100,
    }
}