// Importing all notification accordian icons
import AccessRequest from "./AccessRequest";
import ActionRequired from "./ActionRequired";
import ApproachingExpiry from "./ApproachingExpiry";
import ApprovalUpdated from "./ApprovalUpdated";
import ApprovalMaintained from "./ApprovalMaintained";
import ConcessionRequest from "./ConcessionRequest";
import ConcessionRequestApproved from "./ConcessionRequestApproved";
import ConcessionRequestDeclined from "./ConcessionRequestDeclined";
import CreditExpired from "./CreditExpired";
import CreditExtended from "./CreditExtended";
import DealFunded from "./DealFunded";
import FundingInitiated from "./FundingInitiated";
import MonthlyInvoice from "./MonthlyInvoice";
import NewApplication from "./NewApplication";
import SubmissionApproved from "./SubmissionApproved";
import SynergyFunded from "./SynergyFunded";
import SynergyCancelled from "./SynergyCancelled";
import VendorFunded from "./VendorFunded";
import WeeklyReport from "./WeeklyReport";
import AssetDeclined from "./AssetDeclined";
import NewPps from "./NewPps";
import OwnershipRequest from "./OwnershipRequest";
import SigningAuditing from "./SigningAuditing";

// Exporting all notification icons as an object
export default {
    AccessRequest: AccessRequest,
    ActionRequired: ActionRequired,
    ApproachingExpiry: ApproachingExpiry,
    ApprovalUpdated: ApprovalUpdated,
    ApprovalMaintained: ApprovalMaintained,
    ConcessionRequest: ConcessionRequest,
    ConcessionRequestApproved: ConcessionRequestApproved,
    ConcessionRequestDeclined: ConcessionRequestDeclined,
    CreditExpired: CreditExpired,
    CreditExtended: CreditExtended,
    DealFunded: DealFunded,
    FundingInitiated: FundingInitiated,
    MonthlyInvoice: MonthlyInvoice,
    NewApplication: NewApplication,
    SubmissionApproved: SubmissionApproved,
    VendorFunded: VendorFunded,
    WeeklyReport: WeeklyReport,
    AssetDeclined: AssetDeclined,
    NewPps: NewPps,
    SynergyCancelled: SynergyCancelled,
    SynergyFunded: SynergyFunded,
    OwnershipRequest: OwnershipRequest,
    SigningAuditing: SigningAuditing,
}