export default [
    {
        label: 'Lease',
        value: 'Lease',
    },
    {
        label: 'Loan',
        value: 'Loan'
    }
];
