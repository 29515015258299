import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Letter = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M17.248 40.6889C16.7264 40.1673 16.0189 39.8743 15.2813 39.8743C14.5436 39.8743 13.8362 40.1673 13.3146 40.6889C12.793 41.2105 12.5 41.9179 12.5 42.6556V83.7188C12.5 84.4564 12.793 85.1639 13.3146 85.6855C13.8362 86.2071 14.5436 86.5001 15.2813 86.5001H83.72C84.4577 86.5001 85.1651 86.2071 85.6867 85.6855C86.2083 85.1639 86.5013 84.4564 86.5013 83.7188V42.6556C86.5013 41.9179 86.2083 41.2105 85.6867 40.6889C85.1651 40.1673 84.4577 39.8743 83.72 39.8743C82.9824 39.8743 82.2749 40.1673 81.7533 40.6889C81.2318 41.2105 80.9387 41.9179 80.9387 42.6556V80.9375H18.0626V42.6556C18.0626 41.9179 17.7696 41.2105 17.248 40.6889Z" fill="white" stroke="white" />
            <path d="M81.9386 50.2753V50.2751V28.9678C81.9386 28.2302 81.6456 27.5227 81.124 27.0011C80.6024 26.4796 79.895 26.1865 79.1574 26.1865H19.8438C19.1061 26.1865 18.3987 26.4796 17.8771 27.0011C17.3555 27.5227 17.0625 28.2302 17.0625 28.9678L17.0625 50.2751L17.0625 50.2753C17.0627 50.7334 17.1761 51.1842 17.3925 51.5879C17.6088 51.9915 17.9215 52.3355 18.3028 52.5892C18.3028 52.5893 18.3029 52.5893 18.303 52.5894L47.9595 72.3452L47.9597 72.3453C48.4162 72.6491 48.9523 72.8112 49.5006 72.8112C50.0489 72.8112 50.5849 72.6491 51.0414 72.3453L51.0416 72.3452L80.6982 52.5894C80.6983 52.5893 80.6983 52.5893 80.6984 52.5892C81.0796 52.3355 81.3923 51.9915 81.6087 51.5879C81.8251 51.1842 81.9384 50.7334 81.9386 50.2753ZM49.5006 66.6881L22.6251 48.7846V31.7491H76.3761V48.7846L49.5006 66.6881Z" fill="white" stroke="white" />
            <path d="M47.9597 12.9658L47.9593 12.9661L27.4277 26.6538L27.0119 26.931L27.2889 27.3469L29.8166 31.143L30.0938 31.5594L30.5101 31.2819L49.5005 18.6231L68.491 31.2819L68.9072 31.5594L69.1845 31.143L71.7122 27.3469L71.9891 26.931L71.5733 26.6538L51.0417 12.9661L51.0414 12.9658C50.5849 12.6621 50.0488 12.5 49.5005 12.5C48.9522 12.5 48.4162 12.6621 47.9597 12.9658ZM81.126 36.1243L80.7725 35.7708L80.4189 36.1243L77.1886 39.3546L76.835 39.7082L77.1886 40.0617L81.7512 44.6243L82.1047 44.9779L82.4583 44.6243L85.6886 41.394L86.0421 41.0404L85.6886 40.6869L81.126 36.1243ZM21.8125 40.0617L22.166 39.7082L21.8125 39.3546L18.5821 36.1243L18.2286 35.7708L17.875 36.1243L13.3125 40.6869L12.9589 41.0404L13.3125 41.394L16.5428 44.6243L16.8963 44.9779L17.2499 44.6243L21.8125 40.0617Z" fill="white" stroke="white" />
            <path d="M30.5903 38.5059L69.2892 38.5059" stroke="white" strokeWidth="5" strokeLinecap="round" />
            <path d="M34.1084 48.1807L65.7711 48.1807" stroke="white" strokeWidth="5" strokeLinecap="round" />
        </svg>
    );
};

export default function LetterIcon(props) {
    return <Icon component={Letter} {...props} />
}