import AGENT_TYPES from './AGENT_TYPES';
import ASSET_TYPES from './ASSET_TYPES';
import CLIENT_TYPES from './CLIENT_TYPES';
import DOWN_PAYMENT_TYPES from './DOWN_PAYMENT_TYPES';
import DOWN_PAYMENT from './DOWN_PAYMENT';
import FINANCE_TYPES from './FINANCE_TYPES';
import SUBMISSION_TYPES from './SUBMISSION_TYPES';
import COMPANY_TYPES from './COMPANY_TYPES';

import AMERICAN_STATES from './AMERICAN_STATES';
import CANADIAN_PROVINCES from './CANADIAN_PROVINCES';
import COUNTRIES from './COUNTRIES';

import CLIENT_INDUSTRIES from './CLIENT_INDUSTRIES';
import TRAILER_BRANDS from './TRAILER_BRANDS';
import TRUCK_BRANDS from './TRUCK_BRANDS';

import SHORT_MONTHS from './SHORT_MONTHS';

import COMMUNICATION_PREFERENCES from './COMMUNICATION_PREFERENCES';

import DECLINE_CATEGORIES from './DECLINE_CATEGORIES';
import BREAKPOINTS from './BREAKPOINTS';
import APPLICATION_TYPE from './APPLICATION_TYPE';
import ACCELERATED_AMOUNT_PERCENT from './ACCELERATED_AMOUNT_PERCENT';
import RESOURCE_TYPE from './RESOURCE_TYPE';
import DEFAULT_THEME from './DEFAULT_THEME';
import THEMES from './THEMES';
import PREFERED_HOURS from './PREFERED_HOURS';
import PROCESS_STAGE_NAMES from './PROCESS_STAGE_NAMES';
import STAGE_PROPERTIES from './STAGE_PROPERTIES';
import ASSET_PURPOSES from './ASSET_PURPOSES';
import LISTING_EQUIPMENT_TYPES from './LISTING_EQUIPMENT_TYPES';

const COMMISSION_TYPE = [
    {
        label: '% Invoice',
        value: '% Invoice',
    },
    {
        label: '% Net Financed',
        value: '% Net Financed',
    },
    {
        label: '% Spread',
        value: '% Spread',
    },
]

export default {
    AGENT_TYPES: AGENT_TYPES,
    ASSET_TYPES: ASSET_TYPES,
    CLIENT_TYPES: CLIENT_TYPES,
    COMMISSION_TYPE: COMMISSION_TYPE,
    DOWN_PAYMENT_TYPES: DOWN_PAYMENT_TYPES,
    DOWN_PAYMENT: DOWN_PAYMENT,
    FINANCE_TYPES: FINANCE_TYPES,
    SUBMISSION_TYPES: SUBMISSION_TYPES,
    AMERICAN_STATES: AMERICAN_STATES,
    CANADIAN_PROVINCES: CANADIAN_PROVINCES,
    COUNTRIES: COUNTRIES,
    CLIENT_INDUSTRIES: CLIENT_INDUSTRIES,
    TRAILER_BRANDS: TRAILER_BRANDS,
    ASSET_PURPOSES: ASSET_PURPOSES,
    TRUCK_BRANDS: TRUCK_BRANDS,
    COMMUNICATION_PREFERENCES: COMMUNICATION_PREFERENCES,
    DECLINE_CATEGORIES: DECLINE_CATEGORIES,
    SHORT_MONTHS: SHORT_MONTHS,
    BREAKPOINTS: BREAKPOINTS,
    APPLICATION_TYPE: APPLICATION_TYPE,
    ACCELERATED_AMOUNT_PERCENT: ACCELERATED_AMOUNT_PERCENT,
    DEFAULT_THEME: DEFAULT_THEME,
    THEMES: THEMES,
    RESOURCE_TYPE: RESOURCE_TYPE,
    PREFERED_HOURS: PREFERED_HOURS,
    COMPANY_TYPES: COMPANY_TYPES,
    PROCESS_STAGE_NAMES: PROCESS_STAGE_NAMES,
    STAGE_PROPERTIES: STAGE_PROPERTIES,
    LISTING_EQUIPMENT_TYPES: LISTING_EQUIPMENT_TYPES,
}