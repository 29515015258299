import utils from "../../../utils"

export default function generateDetails(notificationData) {

    const summaryDetails = {
        title: "Summary",
        data: [
            {
                label: "Asset Description",
                value: notificationData.assetDescription
            },
            {
                label: "Base Amount",
                value: utils.TransformDBCurrency(notificationData.baseAmount)
            },
            {
                label: "Term",
                value: notificationData.termLength
            },
            {
                label: "Client Rate",
                value: notificationData.clientRate
            },
            {
                label: "Regular Monthly Payment",
                value: utils.TransformDBCurrency(notificationData.regularMonthlyPayment)
            },
            {
                label: "Down Payment",
                value: notificationData.downPayment
            },          
            {
                label: "Down Payment Amount",
                value: utils.TransformDBCurrency(notificationData.downPaymentAmount)
            },
            {
                label: "Commission",
                value: utils.TransformDBCurrency(notificationData.commission)
            },
        ]
    }
    const approvalDetails = {
        title: "Approval Details",
        data: [
            {
                label: "Approval Date",
                value: utils.TransformNotificationDate(notificationData.approvalDate)
            },
            {
                label: "Lender",
                value: notificationData.lenderName,
            },
            {
                label: "Credit",
                value: notificationData.creditName
            },
        ]
    }

    return {
        summaryDetails: summaryDetails,
        approvalDetails: approvalDetails
    }
}