import { useNavigate } from "react-router-dom";
import { Button, theme } from "antd";

import generateDetails from "./constants";

import NotificationDetailsBlock from "../../NotificationDetailBlock";
import validate from "../../../utils/Validations";

import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";

import styles from "./index.module.css";

export default function NewPps({ notificationData }) {

    const navigate = useNavigate();

    const { token: { colorPrimary }, } = theme.useToken();

    const clientPhone = `tel:${notificationData.ap_data.clientPhone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`

    const details = generateDetails(notificationData.ap_data);

    return (
        <>
            <div><NotificationScreenIcons.NewPpsIcon /></div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: colorPrimary }}
            >NEW PROPOSED PAYMENT SCHEDULE</div>

            <div>
                Please review the below payment schedules and let us know if the client wishes to proceed with them, or send us an amendment request.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientName}</div>
                <div>
                    <a href={clientPhone}>{validate.FormatTelephone(String(notificationData.ap_data.clientPhone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock 
                        data={details.approvalDetails} 
                        lessPadding={true}/>
                </div>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock   
                        data={details.summaryDetails} 
                        lessPadding={true}/>
                </div>
            </div>

            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/approvals/${notificationData.ap_data?.approvalId}/paymentschedules/${notificationData.ap_entityId}`)}
                    style={{ height: "40px" }}
                >View Details</Button>
            </div>
        </>
    );
}