import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams } from "react-router";
import { Button, ConfigProvider, Input, Select, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import RequestAsset from "../RequestAsset";
import ListingCard from "../../components/ListingCard";
import ListingLists from "../../components/ListingLists";
import CustomPagination from "../../components/Pagination";
import ListingDoesNotExists from "../ListingDoesNotExists";

import GridIcon from "../../assets/images/icons/GridIcon";
import ListIcon from "../../assets/images/icons/ListIcon";

import ApiService from "../../api";

import ListUtils from "./utils";
import constants from "../../constants";

import styles from "./index.module.css";

export default function ListingLayout() {

    const location = useLocation();
    const { id } = useParams();

    // The view can be grid or list
    const [view, setView] = useState('grid');
    const [userSelectedList, setUserSelectedList] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [filterValue, setFilterValue] = useState('');
    const [searchedText, setSearchedText] = useState('');
    const [listings, setListings] = useState([]);
    const [queryedListings, setQueryedListings] = useState([]);
    const [pagedListings, setPagedListings] = useState([]);
    const [requestAsset, setRequestAsset] = useState(false);
    const [loader, setLoader] = useState(true);

    const pageSize = 6;

    const isGrid = (view === 'grid');

    useEffect(() => {
        const queryParams = new URLSearchParams(location?.search);
        const gridParam = queryParams.get('grid');
        const updatedView = gridParam == 'false' ? 'list' : 'grid'; // This is important to handle the case when the view is set to undefined
        setView(userSelectedList ? 'list' : updatedView);
    }, [location]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location?.search);
        queryParams.set('grid', view === 'grid');
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
        setUserSelectedList(prevSelection => (prevSelection || view === 'list'));
    }, [view, id]);

    useEffect(() => {
        setLoader(true);
        ApiService.fetchAvailableVendorListing().then(async (data) => {
            const updatedList = await ListUtils.fetchAndClean(data);
            setListings(updatedList);
            setTimeout(() => setLoader(false), 1500);
        });
    }, [refresh]);

    const processFilters = (data, filters) => {
        if (!filters || Object.keys(filters).length === 0)
            return data;

        return data.filter(item => {
            for (const key in filters) {
                if (filters[key].length === 0)
                    continue;

                if (!filters[key].includes(item[key]))
                    return false;
            }
            return true;
        });
    }

    const processSearch = (data, searchedColumn, searchText) => {
        if (!searchedColumn || !searchText)
            return data;
        return data.filter(item => item[searchedColumn].toLowerCase().includes(searchText.toLowerCase()));
    }

    useEffect(() => {
        const searchedData = processSearch(listings, 'listingName', searchedText);
        const filteredData = processFilters(searchedData, { type: filterValue });
        setQueryedListings(filteredData);
    }, [searchedText, filterValue, listings])

    const imposeCardsView = useMediaQuery({ query: '(max-width: 1105px)' });

    useEffect(() => {
        if (imposeCardsView)
            setView('grid');
        else
            setView(prevView => userSelectedList ? 'list' : prevView);
    }, [imposeCardsView]);

    const displayCounter = ListUtils.getDisplayingCounter(queryedListings, currentPageNum, pageSize);

    const themeTokens = ListUtils.getThemeTokens();

    const searchComponent = (
        <ConfigProvider theme={themeTokens}>
            <Input
                value={searchedText}
                onChange={(e) => setSearchedText(e.target.value)}
                size="large"
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ minWidth: '100%', textAlign: 'left' }}
            />
        </ConfigProvider>
    )

    const filterComponent = (
        <ConfigProvider theme={themeTokens}>
            <Select
                placeholder="Filter by asset"
                getContainer=".AppTheme"
                size="large"
                showSearch
                value={filterValue || null}
                onChange={(value) => setFilterValue(value)}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={constants.LISTING_EQUIPMENT_TYPES}
                style={{ minWidth: '100%', textAlign: 'left' }}
            />
        </ConfigProvider>
    );

    const lists = (
        <>
            <div className={styles[isGrid ? "ListingLayout_Grid" : "Hide"]}>
                {pagedListings?.map((list, index) => (
                    <ListingCard
                        index={index}
                        data={list}
                        view={view}
                        setRefresh={setRefresh}
                    />
                ))}
            </div>

            <div className={styles[!isGrid ? "ListingLayout_List" : "Hide"]}>
                {pagedListings?.map((list, index) => (
                    <ListingLists
                        index={index}
                        data={list}
                        view={view}
                        setRefresh={setRefresh}
                    />
                ))}
            </div>

            <hr />
            <div className={styles["ListingLayout_Footer"]}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    Don't see what you're looking for?
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => setRequestAsset(true)}
                        style={{ fontSize: 'small' }}
                    >Request Asset</Button>
                </div>
                <div>Displaying {displayCounter} out of {queryedListings?.length}</div>
            </div>

            <div style={{ textAlign: 'center' }}>
                <CustomPagination
                    data={queryedListings}
                    setPagedData={setPagedListings}
                    searchedColumn={'listingName'}
                    pageSize={pageSize}
                    autoPagination={true}
                    showPagination={true}
                    setCurrentPageNum={setCurrentPageNum}
                />
            </div>

            <RequestAsset
                requestAsset={requestAsset}
                setRequestAsset={setRequestAsset}
            />
        </>
    )

    return (
        <>
            <div className={styles["ListingLayout_Tab"]}>

                <div style={{ width: '100%' }}>
                    {searchComponent}
                </div>

                <div style={{ width: '100%' }}>
                    {filterComponent}
                </div>

                <div className={styles[imposeCardsView ? "Hide" : ""]} onClick={() => ListUtils.handleView('grid', setView, setUserSelectedList)}>
                    <GridIcon selected={view === 'grid'} />
                </div>
                <div className={styles[imposeCardsView ? "Hide" : ""]} onClick={() => ListUtils.handleView('list', setView, setUserSelectedList)}>
                    <ListIcon selected={view === 'list'} />
                </div>
            </div>

            {queryedListings?.length > 0 ?
                lists :
                <>
                    {loader ?
                        <Spin
                            spinning={loader}
                            tip="Loading..."
                        />
                        :
                        <ListingDoesNotExists searched={!!searchedText} filtered={!!filterValue} />
                    }
                </>
            }
        </>
    )

}