import React from 'react';
import { ConfigProvider, Divider, theme } from 'antd';
import styles from './index.module.css';

function HorizontalDivider({ title }) {

    const { token: { themeFontColor } } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorSplit: themeFontColor
                }
            }}
        >
            <div className={styles["horizontalDivider-container"]}>
                <h2 className={styles["horizontalDivider-title"]}>{title}</h2>
                <Divider className={styles["dividerStyle"]} />
            </div>
        </ConfigProvider>
    )
}

export default HorizontalDivider;