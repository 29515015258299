import { useEffect, useState } from "react";
import HomeContent from "../../components/HomeContent";
import ClientList from "../../layouts/ClientList";
import CreateClient from "../../layouts/CreateClient";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function Client() {

    // TODO: agentId to be fetched from application memory in the future
    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;

    const [refresh, setRefresh] = useState(0);

    return (
        <HomeContent
            contentHeader={
                <div className={`${styles["Client_Header"]} Font_SemiBold`}>
                    <div className="Font_SemiBold">Clients</div>
                    <CreateClient agentId={agentId} setRefresh={setRefresh} />
                </div>
            }
            content={<ClientList agentId={agentId} refresh={refresh} setRefresh={setRefresh} />}
            bgColor="transparent"
        />
    );
}
