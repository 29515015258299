import Icon from '@ant-design/icons';
import { theme } from "antd";

function ToolTip() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 1.9C7.48435 1.9 5.55126 2.70071 4.12599 4.12599C2.70071 5.55126 1.9 7.48435 1.9 9.5C1.9 11.5156 2.70071 13.4487 4.12599 14.874C5.55126 16.2993 7.48435 17.1 9.5 17.1C11.5156 17.1 13.4487 16.2993 14.874 14.874C16.2993 13.4487 17.1 11.5156 17.1 9.5C17.1 7.48435 16.2993 5.55126 14.874 4.12599C13.4487 2.70071 11.5156 1.9 9.5 1.9ZM0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19C4.25315 19 0 14.7469 0 9.5Z" fill={ap_userPrimaryColor} />
            <path d="M9.49922 7.60005C9.75117 7.60005 9.99281 7.70014 10.171 7.8783C10.3491 8.05646 10.4492 8.29809 10.4492 8.55005V14.25C10.4492 14.502 10.3491 14.7436 10.171 14.9218C9.99281 15.1 9.75117 15.2 9.49922 15.2C9.24726 15.2 9.00563 15.1 8.82747 14.9218C8.64931 14.7436 8.54922 14.502 8.54922 14.25V8.55005C8.54922 8.29809 8.64931 8.05646 8.82747 7.8783C9.00563 7.70014 9.24726 7.60005 9.49922 7.60005ZM10.9242 5.22505C10.9242 5.60298 10.7741 5.96544 10.5068 6.23268C10.2396 6.49992 9.87715 6.65005 9.49922 6.65005C9.12129 6.65005 8.75883 6.49992 8.49159 6.23268C8.22435 5.96544 8.07422 5.60298 8.07422 5.22505C8.07422 4.84712 8.22435 4.48466 8.49159 4.21742C8.75883 3.95018 9.12129 3.80005 9.49922 3.80005C9.87715 3.80005 10.2396 3.95018 10.5068 4.21742C10.7741 4.48466 10.9242 4.84712 10.9242 5.22505Z" fill={ap_userPrimaryColor} />
        </svg>
    )
}

export default function ToolTipIcon(props) {
    return <Icon component={ToolTip} {...props} />
}