// Validates and Formats Canadian Postal Code
export default function FormatCanadianPostalCode(postalCode) {

    // If No Postal Code, return no input no error
    if (!postalCode)
        return { input: '', error: '', }

    // Check if user has entered space
    const lastCharacter = postalCode[postalCode.length - 1];
    let extraSpace = false;

    if (lastCharacter === ' ')
        extraSpace = true;

    const formattedCode = correctedCode(postalCode, extraSpace);
    const errorMessage = 'Enter a valid postal code (e.g., X1X 2X3)';

    return {
        input: formattedCode.toUpperCase(),
        error: (formattedCode.length !== 0 && formattedCode.length !== 7) ? errorMessage : ''
    }
}

// Helper function to format Canadian Postal Code
function correctedCode(code, extraSpace) {

    // Remove spaces and extra characters
    const codeArray = (code.replace(/\s/g, '')).split('').slice(0, 6);

    // If there is no code
    if (codeArray.length === 0)
        return '';

    let formattedArray = []
    for (let i = 0; i < codeArray.length; i++) {

        // If no space was entered, then add space after 3 charcters
        if (i === 3 && !extraSpace) {
            formattedArray.push(' ');
        }

        // For Even Characters
        if (i % 2 === 0) {

            // If Even Character match the format
            if (/[A-Za-z]/.test(codeArray[i])) {

                //  Push to the Array
                formattedArray.push(codeArray[i])

                // If user has entered a space after 3 charcters
                if (i === 2 && extraSpace)
                    formattedArray.push(' ')

            }
            else break;

        } else {

            // If Odd Character match the format
            if (/[0-9]/.test(codeArray[i])) {

                //  Push to the Array
                formattedArray.push(codeArray[i])

            } else break;
        }
    }
    return formattedArray.join('');
}