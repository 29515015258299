import { useState } from "react";
import { Button, ConfigProvider, Input, Modal, theme } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

const { TextArea } = Input;

export default function CancelDrawdown({ drawdownId, setRefresh }) {

    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor }, } = theme.useToken();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancellationReason, setCancellationReason] = useState({ value: '', error: '' });

    const handleCancellationReason = (e) => {
        setCancellationReason(prevReason => ({
            ...prevReason,
            value: e.target.value,
            error: ''
        }));
    }

    const handleCancel = () => {
        if (!cancellationReason?.value) {
            setCancellationReason(prevReason => ({
                ...prevReason,
                error: 'Please enter reason for cancellation'
            }));
            return;
        } else {
            setLoading(true);
            const data = {
                ap_beaconDrawdownCancelled: true,
                ap_closingCategory: 'Customer Withdrawn',
                ap_drawdownCancelledDate: dayjs(),
                ap_drawdownCancelledReason: cancellationReason?.value,
                ap_drawdownCancelledBy: 'Agent',
                ap_status: 'Inactive'
            }

            // setTimeout(() => {
            ApiService.cancelDrawdown(drawdownId, data)
                .then(response => {
                    if (response?.error) {
                        NotificationService.error(response?.error);
                        setLoading(false);
                        return;
                    }

                    setLoading(false);
                    setOpen(false);
                    setRefresh(prev => prev + 1);
                    NotificationService.success('Drawdown cancelled');
                })
                .catch(error => {
                    NotificationService.error(error?.message);
                    setLoading(false);
                });
            // }, 5000);

        }
    }

    const title = (
        <div className={styles["TitleFlex"]}>
            <CloseOutlined
                style={{ visibility: loading ? 'hidden' : 'visible' }}
                className={styles["Close"]}
                onClick={() => setOpen(false)}
            />
            <div className={`${styles["TitleHeader"]} Font_SemiBold`}>Cancel Drawdown</div>
        </div>
    );

    return (
        <>
            <Button
                type="primary"
                onClick={() => setOpen(true)}
                className={styles["CancelButton"]}
            >Cancel Drawdown</Button>

            <Modal
                title={title}
                open={open}
                destroyOnClose={true}
                footer={null}
                onCancel={() => setOpen(false)}
                closable={false}
                getContainer=".AppTheme"
                centered
            >
                <div className={styles["SubHeader"]}>
                    Please explain the reason you would like to cancel this drawdown.
                </div>
                <ConfigProvider
                    theme={{
                        token: {
                            activeBorderColor: ap_userPrimaryColor,
                            hoverBorderColor: ap_userPrimaryColor,
                            colorBgContainer: themePrimaryColor,
                            colorBgContainerDisabled: themePrimaryColor,
                            colorText: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                            colorTextDisabled: themeFontColor,
                            colorIcon: themeFontColor,
                        }
                    }}
                >
                    <TextArea
                        placeholder="Type your reason here..."
                        autoSize={{ minRows: 4, maxRows: 8 }}
                        value={cancellationReason?.value}
                        status={!!cancellationReason?.error ? 'error' : ''}
                        onChange={handleCancellationReason}
                    />
                </ConfigProvider>
                <div className={styles["Error"]}>{cancellationReason?.error || <br />}</div>


                <div className={styles["ModalActions"]}>
                    <Button
                        type="primary"
                        onClick={() => setOpen(false)}
                        disabled={loading}
                        className={styles["ActionButton"]}
                    >Exit</Button>
                    <Button
                        type="primary"
                        className={styles["CancelButton"]}
                        onClick={handleCancel}
                        loading={loading}
                    >{loading ? "Cancelling" : "Cancel"}</Button>
                </div>
            </Modal>
        </>
    );
}