export default function GenerateAssetDescription(assets = []) {
    // Initialization
    const assetItems = [];
    const assetMultiplicity = {};

    for (let i = 0; i < assets?.length; i++) {
        const assetName = [assets[i]?.ap_year, assets[i]?.ap_type].filter(Boolean)?.join(" ");

        const assetInfo = {
            "assetName": assetName,
            "assetClass": assets[i]?.ap_type,
        }

        if (assetInfo?.assetName in assetMultiplicity)
            assetMultiplicity[assetInfo?.assetName].quantity += 1;
        else
            assetMultiplicity[assetInfo?.assetName] = {
                "quantity": 1,
                "class": assetInfo?.assetClass,
            };
    }

    const sortedAssetMultiplicity = sortByClass(assetMultiplicity);
    const totalMultiplicity = Object.keys(assetMultiplicity).length;

    if (totalMultiplicity <= 2) {
        for (let assetName in sortedAssetMultiplicity) {
            const name = sortedAssetMultiplicity[assetName].quantity === 1 ? assetName : sortedAssetMultiplicity[assetName].quantity + "x" + assetName;
            assetItems.push(name);
        }
    } else {
        const assetClasses = {};
        for (let assetName in sortedAssetMultiplicity) {
            if (sortedAssetMultiplicity[assetName].class in assetClasses)
                assetClasses[sortedAssetMultiplicity[assetName].class].quantity += sortedAssetMultiplicity[assetName].quantity;
            else
                assetClasses[sortedAssetMultiplicity[assetName].class] = { "quantity": sortedAssetMultiplicity[assetName].quantity };
        }

        for (let assetClass in assetClasses) {
            const name = assetClasses[assetClass].quantity === 1 ? assetClass : assetClasses[assetClass].quantity + "x" + assetClass;
            assetItems.push(name);
        }
    }
    return assetItems.join(" and ");
}

function sortByClass(inputObj) {
    const entries = Object.entries(inputObj);

    const sortedEntries = entries.sort(([, a], [, b]) => {
        return a.class.localeCompare(b.class);
    });

    const sortedObj = Object.fromEntries(sortedEntries);

    return sortedObj;
}