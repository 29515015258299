import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";

import Approval from "../../layouts/Approval";
import Error404 from "../../layouts/Error404";

import ApiService from "../../api";

export default function IndividualApproval() {

    const [headerName, setHeaderName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        ApiService.fetchIndividualApprovals(id).then(response => {
            setShowError(!response);
            setLoading(false);
        });
    }, [id])

    return (
        <HomeContent
            contentHeader={<>
                {showError ?
                    <></> :
                    <div style={{ display: 'flex' }}>
                        <GoBackButton />
                        {headerName}
                    </div>
                }
            </>}
            content={
                <Spin
                    spinning={loading}
                    tip="Loading..."
                    size="large"
                >
                    {showError ?
                        <div style={{ maxHeight: '73vh', overflowY: 'hidden' }}>
                            <Error404 />
                        </div> :
                        <Approval headerName={headerName} setHeaderName={setHeaderName} />
                    }
                </Spin>
            }
            bgColor="transparent"
        />
    );
}