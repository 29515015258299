import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Application = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.22211 4.88889H5.33322C4.56704 4.88889 3.83224 5.19325 3.29047 5.73502C2.7487 6.2768 2.44434 7.0116 2.44434 7.77778V25.1111C2.44434 25.8773 2.7487 26.6121 3.29047 27.1539C3.83224 27.6956 4.56704 28 5.33322 28H11.111M8.22211 4.88889C8.22211 4.12271 8.52648 3.38791 9.06825 2.84614C9.61002 2.30436 10.3448 2 11.111 2H16.8888C17.655 2 18.3898 2.30436 18.9315 2.84614C19.4733 3.38791 19.7777 4.12271 19.7777 4.88889M8.22211 4.88889C8.22211 5.65507 8.52648 6.38987 9.06825 6.93164C9.61002 7.47341 10.3448 7.77778 11.111 7.77778H16.8888C17.655 7.77778 18.3898 7.47341 18.9315 6.93164C19.4733 6.38987 19.7777 5.65507 19.7777 4.88889M25.5554 10.373V7.77778C25.5554 7.0116 25.2511 6.2768 24.7093 5.73502C24.1675 5.19325 23.4327 4.88889 22.6666 4.88889H19.7777" stroke={ ap_userPrimaryColor } strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.3378 15.6313L15.8212 23.1786C15.3784 23.6915 15.0543 24.296 14.8722 24.9487V24.9487L17.218 27.0361V27.0361C17.867 26.8013 18.4483 26.4105 18.9108 25.8982L25.5764 18.5132L22.3378 15.6313ZM22.7989 15.0834L26.0375 17.9653L26.5618 17.3761C27.3576 16.4818 27.2777 15.1117 26.3834 14.3159V14.3159C25.4891 13.52 24.119 13.5999 23.3232 14.4942L22.7989 15.0834Z" fill={ ap_userPrimaryColor } />
            <path d="M17.218 27.0361L14.8722 24.9487L14.5815 25.991C14.4921 26.3116 14.6801 26.7514 15.0458 27.0768C15.4013 27.3931 15.8459 27.5311 16.1545 27.4208L17.218 27.0361Z" fill={ ap_userPrimaryColor } />
            <path d="M17.218 27.0361C17.867 26.8013 18.4483 26.4105 18.9108 25.8982L25.5764 18.5132L22.3378 15.6313L15.8212 23.1786C15.3784 23.6915 15.0543 24.296 14.8722 24.9487V24.9487L17.218 27.0361V27.0361Z" fill={ ap_userPrimaryColor } />
            <path d="M17.218 27.0361C17.867 26.8013 18.4483 26.4105 18.9108 25.8982L25.5764 18.5132L22.3378 15.6313L15.8212 23.1786C15.3784 23.6915 15.0543 24.296 14.8722 24.9487V24.9487L17.218 27.0361V27.0361Z" fill={ ap_userPrimaryColor } />
            <path d="M17.218 27.0361C17.867 26.8013 18.4483 26.4105 18.9108 25.8982L25.5764 18.5132L22.3378 15.6313L15.8212 23.1786C15.3784 23.6915 15.0543 24.296 14.8722 24.9487V24.9487L17.218 27.0361V27.0361Z" fill={ ap_userPrimaryColor } />
        </svg>
    );
};

export default function ApplicationIcon(props) {
    return <Icon component={Application} {...props} />
}