import Icon from '@ant-design/icons';
import { theme } from 'antd';

function VendorFunded() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="139" height="134" viewBox="0 0 139 134" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M115 134C128.255 134 139 123.255 139 110C139 96.7452 128.255 86 115 86C101.745 86 91 96.7452 91 110C91 123.255 101.745 134 115 134ZM111.58 113.54L106.42 108.38C105.98 107.94 105.42 107.72 104.74 107.72C104.06 107.72 103.5 107.94 103.06 108.38C102.62 108.82 102.4 109.38 102.4 110.06C102.4 110.74 102.62 111.3 103.06 111.74L109.9 118.58C110.38 119.06 110.94 119.3 111.58 119.3C112.22 119.3 112.78 119.06 113.26 118.58L126.82 105.02C127.26 104.58 127.48 104.02 127.48 103.34C127.48 102.66 127.26 102.1 126.82 101.66C126.38 101.22 125.82 101 125.14 101C124.46 101 123.9 101.22 123.46 101.66L111.58 113.54Z" fill={ap_userSecondaryColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M39.65 124.878C47.5583 128.293 56.0083 130 65 130C73.5662 130.004 81.6408 128.457 89.2237 125.358C87.0575 121.73 85.6355 117.606 85.1684 113.198C79.0044 115.733 72.2816 117 65 117C50.4833 117 38.1875 111.963 28.1125 101.888C18.0375 91.8125 13 79.5167 13 65C13 50.4833 18.0375 38.1875 28.1125 28.1125C38.1875 18.0375 50.4833 13 65 13C79.5167 13 91.8125 18.0375 101.888 28.1125C111.963 38.1875 117 50.4833 117 65C117 70.2955 116.33 75.2955 114.989 80C114.993 80 114.996 80 115 80C119.494 80 123.757 80.9882 127.584 82.759C129.195 77.0948 130 71.1752 130 65C130.004 56.0083 128.299 47.5583 124.884 39.65C121.47 31.7417 116.838 24.8625 110.988 19.0125C105.138 13.1668 98.2583 8.53667 90.35 5.122C82.4417 1.70733 73.9917 0 65 0C56.0083 0.00433333 47.5583 1.71167 39.65 5.122C31.7417 8.53233 24.8625 13.1625 19.0125 19.0125C13.1668 24.8625 8.53667 31.7417 5.122 39.65C1.70733 47.5583 0 56.0083 0 65C0.00433333 73.9917 1.71167 82.4417 5.122 90.35C8.53233 98.2583 13.1625 105.138 19.0125 110.988C24.8625 116.833 31.7417 121.463 39.65 124.878Z" fill={ap_userSecondaryColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M102.018 62.0537C100.98 59.6958 100.442 58.5447 98.498 58.5447H93.2183L81.2532 43.6339C80.5935 42.8121 79.757 42.1487 78.8054 41.6928C77.8538 41.2369 76.8115 41.0001 75.7557 41H63.3001C61.3565 41 59.7803 42.5713 59.7803 44.5089V58.5447H29.2798C27.3362 58.5447 25.76 60.1161 25.76 62.0537V69.0715C24.7877 69.0715 24 69.8567 24 70.826V74.3349C24 75.3043 24.7876 76.0894 25.7599 76.0894H39.4678C40.9969 72.435 44.6065 69.8673 48.8158 69.8673C53.0251 69.8673 56.6347 72.435 58.1638 76.0894H74.0377H81.5022C83.0313 72.435 86.6409 69.8673 90.8502 69.8673C95.0595 69.8673 98.6691 72.435 100.198 76.0894H103.778C104.75 76.0894 105.538 75.3043 105.538 74.3349V70.826C105.538 69.9785 103.602 65.6218 102.455 63.041L102.455 63.0408C102.29 62.6698 102.142 62.3355 102.018 62.0537ZM75.7557 48.0179H66.8199V58.5447H84.2033L75.7557 48.0179Z" fill="#447EB7"/>
            <circle cx="48.8158" cy="79.9963" r="8.10313" fill="#447EB7"/>
            <circle cx="90.8512" cy="79.9963" r="8.10313" fill="#447EB7"/>
            <path d="M36.1548 47.5322C36.1548 45.3231 37.9456 43.5322 40.1548 43.5322H53.4255C55.6346 43.5322 57.4255 45.3231 57.4255 47.5322V56.6998H36.1548V47.5322Z" fill="#447EB7"/>
        </svg>
    );
}

export default function VendorFundedIcon(props) {
    return <Icon component={VendorFunded} {...props} />
}