import { theme } from "antd";
import utils from "../../utils";
import ApiService from "../../api";

const handleView = (selectedView, setView, setUserSelectedList) => {
    if (selectedView && setView)
        setView(selectedView);

    if (setView && setUserSelectedList)
        setUserSelectedList(selectedView === 'list');
}

async function fetchListingPictures(uniqueEntityId) {
    if (!uniqueEntityId)
        return [];

    const requestData = {
        containerName: 'vendorlistings',    // Move to env vars
        blobName: uniqueEntityId
    }
    const pictures = await ApiService.fetchDocuments(requestData).then((response) => {
        if (response?.data) {
            return response?.data?.map(item => item.url);;
        }
        else
            return [];
    });
    return pictures;
}

async function fetchAndClean(data) {
    if (!data || data.length === 0)
        return [];

    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const transformedData = [];
    for (let i = 0; i < data.length; i++) {
        const assetPhotos = await fetchListingPictures(data[i]?.ap_uniqueEntityId);
        transformedData.push({
            ...data[i],
            ap_assetPhoto: assetPhotos
        });
    }

    const cleanedData = utils.CleanListingRecord(transformedData);
    return cleanedData;
}

function getDisplayingCounter(listings, currentPageNum, pageSize) {
    const totalItems = listings?.length;
    const maxPageListCounter = pageSize * currentPageNum;

    if (maxPageListCounter <= totalItems)
        return maxPageListCounter;
    else return totalItems;
}

function getThemeTokens() {
    const { token: { ap_userHighlightColor, themePrimaryColor, themeSecondaryColor, themeDisabledContainer, themeFontColor } } = theme.useToken();

    const themeTokens = {
        token: {
            colorBgContainer: themePrimaryColor,
            colorBgContainerDisabled: themeDisabledContainer,
            colorText: themeFontColor,
            colorTextDisabled: themeFontColor,
            colorIcon: themeFontColor,
            controlOutline: ap_userHighlightColor,
            colorPrimaryHover: ap_userHighlightColor,
            controlItemBgHover: themeSecondaryColor,
            colorIconHover: themeSecondaryColor,
            colorTextQuaternary: themeFontColor,
            colorBorder: ap_userHighlightColor,
            colorTextPlaceholder: "#898989"
        }
    }
    return themeTokens;
}

const exportObj = {
    handleView,
    fetchListingPictures,
    fetchAndClean,
    getDisplayingCounter,
    getThemeTokens,
}

export default exportObj;