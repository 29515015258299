import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

const handleRequestAssetModal = (setRequestAsset, setData) => {
    if (setRequestAsset)
        setRequestAsset(prevRequestAsset => !prevRequestAsset);

    if (setData)
        setData(prevData => ({
            ...prevData,
            assetType: '',
            make: '',
            model: '',
            minPrice: '',
            maxPrice: '',
            minMileageHours: '',
            maxMileageHours: '',
            additionalDetails: '',
            invokeValidation: false,
        }))
}

const handleAssetType = (assetType, setData) => {
    if (setData)
        setData(prevData => {
            const valueChanged = prevData?.assetType !== assetType;
            return {
                ...prevData,
                assetType: assetType,
                make: valueChanged ? '' : prevData?.make,
                model: valueChanged ? '' : prevData?.model,
            }
        });
}

const handleMake = (e, setData, makeType) => {
    if (setData)
        setData(prevData => {
            const value = makeType === 'dropdown' ? e : e?.target?.value;
            return {
                ...prevData,
                make: value,
            }
        });
}

const handleModel = (value, setData) => {
    if (setData)
        setData(prevData => ({
            ...prevData,
            model: value,
        }));
}

const handlePriceInputs = (value, valueName, setData) => {
    const cleanedData = String(value)?.replace(/\D/g, '');
    if (setData)
        setData(prevData => ({
            ...prevData,
            [valueName]: toNumber(cleanedData),
        }));
}

const checkIfRequiredFieldsAreFilled = (data) => {
    const requiredFields = {
        assetType: true,
        make: data?.assetType === 'Other',
        model: data?.make === 'Other',
        minPrice: true,
        maxPrice: true,
        minMileageHours: true,
        maxMileageHours: true,
        additionalDetails: false,
    }

    const errorExists = Object.keys(requiredFields).some(field => {
        return requiredFields[field] && (data[field] === undefined || data[field] === null || data[field] === '');
    });  
    return errorExists;
}

const validateRequestAsset = (data) => {
    const validations = {
        minPrice: data?.minPrice <= data?.maxPrice,
        maxPrice: data?.maxPrice >= data?.minPrice,
        minMileageHours: data?.minMileageHours <= data?.maxMileageHours,
        maxMileageHours: data?.maxMileageHours >= data?.minMileageHours,
    }
    return Object.values(validations).every(Boolean);
}

const toNumber = (value) => {
    if (!value) return 0;
    const num = +(String(value)?.replace(/,/g, ''));
    return num;
}

const handleSubmit = (data, setData, setRequestAsset) => {
    setData(prevData => ({ ...prevData, invokeValidation: true }));

    const allRequiredFilled = !checkIfRequiredFieldsAreFilled(data);
    if (!allRequiredFilled) {
        NotificationService.error("Please fill in all required fields");
        return;
    }

    const fieldValidated = validateRequestAsset(data);
    if (!fieldValidated) {
        NotificationService.error("Please ensure all the errors are resolved.");
        return
    }

    const assetRequest = {
        ap_assetType: data?.assetType,
        ap_make: data?.make,
        ap_model: data?.model,
        ap_minPrice: String(data?.minPrice),
        ap_maxPrice: String(data?.maxPrice),
        ap_minKmsHours: data?.minMileageHours,
        ap_maxKmsHours: data?.maxMileageHours,
        ap_additionalDetails: data?.additionalDetails,
    }

    ApiService.createListingAssetRequest(assetRequest).then((response) => {
        if (response?.success) {
            NotificationService.success("Thank you! Your request has been submitted to our team");
            handleRequestAssetModal(setRequestAsset, setData);
        } else {
            NotificationService.error(response?.error);
        }
    });
}

const RequestAssetUtils = {
    handleRequestAssetModal,
    handleAssetType,
    handleMake,
    handleModel,
    handlePriceInputs,
    toNumber,
    handleSubmit,
};

export default RequestAssetUtils;