import { theme } from 'antd';
import Icon from '@ant-design/icons';

const IDs = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M18 28.9246C18 25.1268 20.4906 23 23.8374 23C27.1842 23 29.6748 25.1268 29.6748 28.9246V72.0754C29.6748 75.8757 27.1842 78 23.8374 78C20.4906 78 18 75.8757 18 72.0754V28.9246ZM36.6797 29.3069C36.6797 25.8104 39.1704 23.4557 42.6728 23.4557H55.598C73.035 23.4557 83 34.396 83 51.2608C82.9975 67.2142 72.4123 77.5467 56.3738 77.5467H42.8285C40.26 77.5467 36.6797 76.177 36.6797 71.85V29.3069ZM48.3546 67.0622H56.2182C66.261 67.0622 70.8506 59.8464 70.8506 50.7291C70.8506 41.0042 66.1832 33.9402 55.4373 33.9402H48.3521L48.3546 67.0622Z" fill="white" />
        </svg>
    );
};

export default function IDsIcon(props) {
    return <Icon component={IDs} {...props} />
}