import { useEffect, useState } from "react";
import { Button, Drawer, Spin, notification } from "antd";
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import dayjs from "dayjs";

import constants from "../../constants";
import InputLayout from "../InputLayout";

import HasError from "../../utils/HasErrors";
import utils from "../../utils";
import validate from "../../utils/Validations";
import ApiService from "../../api";
import createClientConstants from "./constants.js";
import styles from "./index.module.css";

export default function CreateClient({ agentId, setRefresh }) {

    // Tracks the state of the 'Create Client' Button
    const [invokeFieldValidaton, setInvokeFieldValidation] = useState(false);

    // Default values of Form Inputs
    const [formData, setFormData] = useState(createClientConstants.generateCreateClientData());

    // Default value of Provinces dropdown
    const [provinces, setProvinces] = useState(constants.CANADIAN_PROVINCES);

    // Tracks state for opening/closing Create Client Form
    const [open, setOpen] = useState(false);

    // Tracks state for loading the form
    const [loading, setLoading] = useState(false);

    const [clientOnfile, setClientOnFile] = useState(false);
    const [autoFillData, setAutoFillData] = useState({});
    const [globalAutoFillData, setGlobalAutoFillData] = useState({})
    const [clientOnFileData, setClientOnFileData] = useState({});
    const [contactData, setContactData] = useState({});
    const [isFormAutoFilled, setIsFormAutoFilled] = useState(false);

    // Notification
    const [notifApi, contextHolder] = notification?.useNotification();
    const openNotification = (type, message, description) => {
        notifApi[type]({
            message: message,
            description: description,
            placement: 'bottomRight',
        });
    }

    const showCreateClient = () => {
        setOpen(true);
    }

    const closeCreateClient = () => {
        setOpen(false);
        setInvokeFieldValidation(false);
    }

    const resetAfterAutoFill = (tempClientOnFile= false) => {
        if (isFormAutoFilled && !tempClientOnFile) {
            setFormData(prevFormData => ({
                ...prevFormData,
                industry: {
                    ...prevFormData?.industry,
                    value: '',
                    error: '',
                    disabled: false,
                },
                type: {
                    ...prevFormData?.type,
                    value: '',
                    error: '',
                    disabled: false,
                },
                companyType: {
                    ...prevFormData.companyType,
                    value: '',
                    error: '',
                    disabled: false,
                },
                dateOfIncorporation: {
                    ...prevFormData?.dateOfIncorporation,
                    value: '',
                    error: '',
                    disabled: false,
                },
                filedFSDate: {
                    ...prevFormData?.filedFSDate,
                    value: '',
                    error: '',
                    disabled: false,
                },
                email: {
                    ...prevFormData?.email,
                    value: '',
                    error: '',
                    disabled: false,
                },
                telephone: {
                    ...prevFormData?.telephone,
                    value: '',
                    error: '',
                    disabled: false,
                },
                address: {
                    ...prevFormData?.address,
                    value: '',
                    error: '',
                    disabled: false,
                },
                city: {
                    ...prevFormData?.city,
                    value: '',
                    error: '',
                    disabled: false,
                },
                province: {
                    ...prevFormData?.province,
                    value: '',
                    error: '',
                    disabled: false,
                },
                postalCode: {
                    ...prevFormData?.postalCode,
                    value: '',
                    error: '',
                    disabled: false,
                },
                country: {
                    ...prevFormData?.country,
                    value: '',
                    error: '',
                    disabled: false,
                },
                primaryContactFirstName: {
                    ...prevFormData?.primaryContactFirstName,
                    value: '',
                    error: '',
                    disabled: false,
                },
                primaryContactLastName: {
                    ...prevFormData?.primaryContactLastName,
                    value: '',
                    error: '',
                    disabled: false,
                }
            }));
            setIsFormAutoFilled(false);
            setAutoFillData({});
            // setClientOnFile(false);
        }
        // setClientOnFile(false);
    } 

    useEffect(() => {
        if (!formData?.clientBusinessName?.value) {
            resetAfterAutoFill();
            return;
        }
        const delay = 1500;
        const debounce = setTimeout(() => {
            if (!formData?.clientBusinessName?.value) return;

            let tempClientOnFile = false;
            ApiService.searchGlobalClients(formData?.clientBusinessName?.value).then((resp) => {
                if(!resp){ throw new Error("Something went wrong!")}
                const { filteredClient, clientData, clientExist, alreadyExistWithAgent, alreadyExistWithOtherAgent} = resp;
                if (alreadyExistWithAgent) {
                    const existingClientError = (<>This client already exists. Please contact <a href={`mailto:${process.env.REACT_APP_SALES_DEPARTMENT_EMAIL}`}>{process.env.REACT_APP_SALES_DEPARTMENT_EMAIL}</a>.</>)
                    openNotification('error', 'Error', existingClientError);
                    formData.clientBusinessName.error = existingClientError;
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        clientBusinessName: { ...prevFormData.clientBusinessName, error: existingClientError }
                    }));
                } else {
                    if((formData?.clientBusinessName?.value === filteredClient.ap_businessName) && isFormAutoFilled){
                        return;
                    }
                    else if (alreadyExistWithOtherAgent) {
                        setClientOnFile(true);
                        setAutoFillData(clientData);
                        setClientOnFileData({});
                        setGlobalAutoFillData({})
                        tempClientOnFile = true;
                    } else if(clientExist){
                        setClientOnFile(true);
                        setAutoFillData({});
                        setClientOnFileData(filteredClient);
                        setGlobalAutoFillData({})
                        tempClientOnFile = true;
                    } 
                    else {
                        setClientOnFile(false);
                        setAutoFillData({});
                        setClientOnFileData({});
                        setGlobalAutoFillData({})
                        tempClientOnFile = false;
                    }
                }
                resetAfterAutoFill(tempClientOnFile);
            }).catch((error)=>{
                openNotification('error', 'Error', error.message);
                
            })
            ;
        }, delay)
        return () => {
            clearTimeout(debounce);
        }
    }, [formData?.clientBusinessName.value]);

    /**
     * Set Form Data with clientDat and contactData
     * @param {*} clientData 
     * @param {*} contactData 
     */
    const fillFormData = (clientData, contactData) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            clientBusinessName: {
                ...prevFormData?.clientBusinessName,
                value: clientData?.ap_businessName,
                error: '',
            },
            industry: {
                ...prevFormData?.industry,
                value: clientData?.ap_industry,
                error: '',
                disabled: true,
            },
            type: {
                ...prevFormData?.type,
                value: clientData?.ap_type,
                error: '',
                disabled: true,
            },
            companyType: {
                ...prevFormData.companyType,
                value: clientData?.ap_companyType,
                error: '',
                disabled: true,
            },
            dateOfIncorporation: {
                ...prevFormData?.dateOfIncorporation,
                value: !clientData?.ap_dateOfIncorporation ? '' : dayjs(clientData?.ap_dateOfIncorporation),
                error: '',
                disabled: true,
            },
            filedFSDate: {
                ...prevFormData?.filedFSDate,
                value: !clientData?.ap_filedFsYearEnd ? '' : dayjs(clientData?.ap_filedFsYearEnd),
                error: '',
                disabled: true,
            },
            email: {
                ...prevFormData?.email,
                value: clientData?.ap_email,
                error: '',
                disabled: true,
            },
            telephone: {
                ...prevFormData?.telephone,
                value: clientData?.ap_telephone,
                error: '',
                disabled: true,
            },
            address: {
                ...prevFormData?.address,
                value: clientData?.ap_address,
                error: '',
                disabled: true,
            },
            city: {
                ...prevFormData?.city,
                value: clientData?.ap_city,
                error: '',
                disabled: true,
            },
            province: {
                ...prevFormData?.province,
                value: clientData?.ap_province,
                error: '',
                disabled: true,
            },
            postalCode: {
                ...prevFormData?.postalCode,
                value: clientData?.ap_postalCode,
                error: '',
                disabled: true,
            },
            country: {
                ...prevFormData?.country,
                value: clientData?.ap_country,
                error: '',
                disabled: true,
            },
            primaryContactFirstName: {
                ...prevFormData?.primaryContactFirstName,
                value: contactData?.ap_firstName,
                error: '',
                disabled: true,
            },
            primaryContactLastName: {
                ...prevFormData?.primaryContactLastName,
                value: contactData?.ap_lastName,
                error: '',
                disabled: true,
            }
        }));
    }

    const handleAutoFill = () => {
        setLoading(true);
        setClientOnFile(false);
        
        if(clientOnFileData && Object.keys(clientOnFileData).length > 0){
            ApiService.fetchCRMClient(clientOnFileData.crm_accountId).then((responseData) => {
                setGlobalAutoFillData(responseData);
                const clientData = responseData.clientData;
                const contactData = responseData.contactData;
                setContactData(contactData);
                fillFormData(clientData, contactData);
                setIsFormAutoFilled(true);
                setLoading(false);
            }).catch((error)=> {
                openNotification('error', 'Error', "Unable to fetch Client's Information. Please contact Support team !!!");
                // Reset Form
                setIsFormAutoFilled(true);
                setLoading(false);
                setClientOnFile(false);
                setAutoFillData({});
                setGlobalAutoFillData({});
                setClientOnFileData({});
                resetAfterAutoFill();
            }) 
        }else{
            // fetch contact data
            ApiService.fetchClientContacts(autoFillData?._id).then((resp) => {
                setContactData(resp);
                if (resp.length > 0) {
                    const primaryContactData = resp.find((contact) => contact.ap_primaryContact === true);
                    fillFormData(autoFillData, primaryContactData);
                    setIsFormAutoFilled(true);
                    setLoading(false);
                }
            }).catch((error)=> {
                openNotification('error', 'Error', "Unable to fetch Client's Information. Please contact Support team !!!");
                // Reset Form
                setIsFormAutoFilled(true);
                setLoading(false);
                setClientOnFile(false);
                setAutoFillData({});
                setGlobalAutoFillData({});
                setClientOnFileData({});
                resetAfterAutoFill();
            }) ;   
        }
    }

    const handleCreateClient = () => {
        setLoading(true);
        if (autoFillData && Object.keys(autoFillData).length > 0) {
            // update agent id on client
            ApiService.updateClient(autoFillData._id, { autoFill: true }).then((resp) => {
                if (!!resp.success) {
                    // update agentId on contact
                    for (let i = 0; i < contactData.length; i++) {
                        ApiService.updateContact(contactData[i]._id, { autoFill: true }).then((resp) => {
                            if (!!resp.success) {
                                console.log('agentId updated on contact', resp);
                            }
                        });
                    }
                    setLoading(false);
                    closeCreateClient();
                    createClientConstants.generateResetForm(formData, setFormData);
                    openNotification('success', 'Success', 'Client Created Successfully');
                    setRefresh(prevRefresh => prevRefresh + 1);
                }
                if (!!resp.error) {
                    openNotification('error', 'Error', 'Something went wrong. Please try again later.')
                    setLoading(false);
                }
            });
            setRefresh(prevRefresh => prevRefresh + 1);
        } else if(globalAutoFillData && Object.keys(globalAutoFillData).length > 0){
            ApiService.createClient(globalAutoFillData.clientData).then((resp) => {
                if (!!resp.success) {
                    const clientContactData = {
                        ap_clientData: { ap_clientId: resp.success.clientId },
                        ...globalAutoFillData.contactData
                    }
                    ApiService.createContact(clientContactData).then((contactResp) => {
                        if (!!contactResp.success) {
                            setLoading(false);
                            closeCreateClient();
                            createClientConstants.generateResetForm(formData, setFormData); 
                        }
                        if (!!contactResp.error) {
                            setLoading(false);
                            openNotification('error', 'Error', 'Something went wrong in creating Client primary contact. Please try again later.')
                        }
                        openNotification('success', 'Success', 'Client Created Successfully')
                    });
                }
            });
        }
        else {
            setInvokeFieldValidation(true);
            const tempData = {
                ...formData,
                clientBusinessName: {
                    ...formData?.clientBusinessName,
                    error: formData.clientBusinessName.error || validate.ValidateRequiredField(formData.clientBusinessName.value).errorMessage,
                },
                industry: {
                    ...formData?.industry,
                    error: validate.ValidateRequiredField(formData.industry.value).errorMessage,
                },
                type: {
                    ...formData?.type,
                    error: validate.ValidateRequiredField(formData.type.value).errorMessage,
                },
                companyType: {
                    ...formData.companyType,
                    error: validate.ValidateRequiredField(formData.companyType.value).errorMessage,
                },
                primaryContactFirstName: {
                    ...formData.primaryContactFirstName,
                    error: validate.ValidateRequiredField(formData.primaryContactFirstName.value).errorMessage,
                },
                primaryContactLastName: {
                    ...formData.primaryContactLastName,
                    error: validate.ValidateRequiredField(formData.primaryContactLastName.value).errorMessage,
                },
                email: {
                    ...formData.email,
                    error: (!!validate.ValidateRequiredField(formData.email.value).errorMessage ? validate.ValidateRequiredField(formData.email.value).errorMessage : (!validate.EmailValidation(formData.email.value) ? 'Invalid Email Address' : '')),
                },
                telephone: {
                    ...formData.telephone,
                    error: validate.FormatTelephone(formData.telephone.value).error,
                },
                province: {
                    ...formData.province,
                    error: validate.ValidateRequiredField(formData.province.value).errorMessage,
                },
                country: {
                    ...formData.country,
                    error: validate.ValidateRequiredField(formData.country.value).errorMessage,
                }
            }

            setFormData(tempData);

            if (!HasError(tempData)) {
                const data = {
                    ap_agentId: agentId,
                    ap_businessName: tempData.clientBusinessName.value,
                    ap_industry: tempData.industry.value,
                    ap_type: tempData.type.value,
                    ap_companyType: tempData.companyType.value,
                    ap_dateOfIncorporation: tempData.dateOfIncorporation.value,
                    ap_filedFsYearEnd: tempData.filedFSDate.value,
                    ap_email: tempData.email.value,
                    ap_telephone: utils.TransformTelephone(tempData.telephone.value),
                    ap_address: tempData.address.value,
                    ap_city: tempData.city.value,
                    ap_stateOrProvince: tempData.province.value,
                    ap_postalCode: tempData.postalCode.value,
                    ap_country: tempData.country.value,
                    ap_responsibleType: '',
                    ap_lastModifiedBy: "beacon",
                }
                if (tempData.country.value === 'Canada') {
                    data['ap_province'] = tempData.province.value
                } else {
                    data['ap_state'] = tempData.province.value
                }

                ApiService.createClient(data).then((resp) => {
                    if (!!resp.success) {
                        const clientContactData = {
                            ap_clientId: resp.success.clientId,
                        }

                        const contactData = {
                            ap_contactType: "client",
                            ap_fullName: tempData.primaryContactFirstName.value + " " + tempData.primaryContactLastName.value,
                            ap_firstName: tempData.primaryContactFirstName.value,
                            ap_lastName: tempData.primaryContactLastName.value,
                            ap_email: tempData.email.value,
                            ap_telephone: utils.TransformTelephone(tempData.telephone.value),
                            ap_primaryContact: true,
                            ap_stateOrProvince: tempData.province.value,
                            ap_country: tempData.country.value,
                            ap_postalZipCode: tempData.postalCode.value,
                            ap_clientData: clientContactData,
                        }

                        if (tempData.country.value === 'Canada') {
                            contactData['ap_province'] = tempData.province.value
                        } else {
                            contactData['ap_state'] = tempData.province.value
                        }
                        ApiService.createContact(contactData).then((contactResp) => {
                            if (!!contactResp.success) {
                                setLoading(false);
                                closeCreateClient();
                                createClientConstants.generateResetForm(formData, setFormData);
                                openNotification('success', 'Success', 'Client Primary Contact Created Successfully')
                            }
                            if (!!contactResp.error) {
                                setLoading(false);
                                openNotification('error', 'Error', 'Something went wrong in creating Client primary contact. Please try again later.')
                            }
                        });
                        setRefresh(prevRefresh => prevRefresh + 1);
                    }
                    if (!!resp.error) {
                        openNotification('error', 'Error', 'Something went wrong. Please try again later.')
                        setLoading(false);
                    }
                });
            } else {
                openNotification('error', 'Error', 'Please resolve field errors');
                setLoading(false);
            }
        }
    }

    return (
        <>

            {/* Client Button */}
            <Button
                tpe="primary"
                icon={<PlusOutlined />}
                onClick={showCreateClient}
                className={styles["Client_Button"]}
            >
                Client
            </Button>

            {/* Quick Create Form - New Client */}

            <Drawer
                title="New Client"
                open={open}
                width="500px"
                onClose={closeCreateClient}
                getContainer=".AppTheme"
            >
                <Spin spinning={loading} >
                    {/* Generating Input Fields */}
                    <InputLayout data={createClientConstants.getFormFieldsA(formData, setFormData, validate, invokeFieldValidaton)} layout='vertical' />

                    {clientOnfile ?
                        <div className={styles["AutoFillCard"]}>
                            <div className={styles["AutoFill_AlertContainer"]}>

                                <ExclamationCircleFilled className={styles["ExclamationCircle"]} />
                                <div className={styles["AutoFill_AlertText"]}>
                                    This client already exists in our system. If you would like to use the information we have on file,
                                    click 'AutoFill'. If you would like to make changes to their information,
                                    please fill out this form, and someone from our team will review the amendment.

                                </div>
                            </div>
                            <div className={styles["AutoFill_Button"]}>
                                <Button
                                    className={styles["Client_Button_AutoFill"]}
                                    onClick={handleAutoFill}
                                >AutoFill</Button>
                            </div>
                        </div> : null}


                    <InputLayout data={createClientConstants.getFormFieldsB(formData, setFormData, validate, invokeFieldValidaton, setProvinces, provinces)} layout='vertical' autoFill={true} />

                    <div className={styles["Client_Button_Save"]}>
                        <Button
                            type="primary"
                            styles={{ margin: "0 auto" }}
                            disabled={clientOnfile || loading}
                            onClick={handleCreateClient}
                        >Create Client</Button>
                    </div>

                    {contextHolder}
                </Spin>
            </Drawer>
        </>
    );
}