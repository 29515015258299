import { Button } from "antd";
import dayjs from "dayjs";

import SearchColumn from "../../components/SearchColumn";
import SortColumn from "../../components/SortColumn";

import DraftUtils from "./utils";
import styles from "./index.module.css";

function generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort, setRefresh) {
    return [
        {
            title: 'Submitted On',
            key: 'createdAt',
            ellipsis: { showTitle: false },
            ...SortColumn(customSort, setCustomSort, 'createdAt'),
            render: (record) => (dayjs(record?.createdAt).format('DD MMM YYYY'))
        },
        {
            title: 'Client',
            key: 'ap_clientId',
            dataIndex: 'ap_client',
            ellipsis: { showTitle: false },
            ...SortColumn(customSort, setCustomSort, 'ap_client'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_client')
        },
        {
            title: 'Asset Description',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            ellipsis: { showTitle: false },
        },
        {
            title: 'Requested Amount',
            key: 'ap_requestedAmount',
            ellipsis: { showTitle: false },
            render: (record) => (record?.ap_requestedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        },
        {
            title: 'Action',
            ket: 'action',
            render: (record) => (
                <Button
                    type="primary"
                    className={styles["deleteButton"]}
                    onClick={(e) => { 
                        e.stopPropagation(); 
                        DraftUtils.deleteApplicationDraft(record?._id, setRefresh)
                    }}
                >Delete</Button>
            )
        }
    ];
}

export default {
    generateTableColumns,
}