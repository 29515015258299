import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";

import BeaconPagination from "../../components/BeaconPagination";
import ApiService from "../../api";
import drawdownConstants from "./constants";

import styles from "./index.module.css";

export default function DrawdownDetails() {

    const { token: { themePrimaryColor, themeFontColor } } = theme.useToken();

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [assetData, setAssetData] = useState([]);
    const [filters, setFilters] = useState({});
    const [customSort, setCustomSort] = useState({
        ap_make: { order: null, type: 'string' },
    });

    useEffect(() => {

        if (!id) return;

        setLoading(true);
        ApiService.fetchDrawdownAssets(id)
            .then(data => {
                setAssetData(data.data.map(asset => asset.ap_assetId));
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching asset data:", error);
                setLoading(false);
            });
    }, [id]);

    const { pagedData, paginationComponent } = BeaconPagination(assetData, "DrawdownAssetList", false, true, null, null, filters, customSort);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <div className={styles["header"]}>Assets</div>
            <Table
                id={"DrawdownAssetList"}
                columns={drawdownConstants.generateAssetTableColumns(assetData, setFilters, customSort, setCustomSort)}
                dataSource={pagedData}
                loading={loading}
                scroll={{ x: true, y: 400 }}
                pagination={false}
                footer={() => paginationComponent}
            />
        </ConfigProvider>
    );
}