import { theme } from "antd";
import Icon from '@ant-design/icons';

function LogOutIcon() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg
         width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.611 8.22454L27.7834 14.1429C27.9643 14.3994 28.059 14.7069 28.0536 15.0207C28.0483 15.3346 27.9433 15.6386 27.7538 15.8888L23.315 21.8072C23.1772 21.991 22.9985 22.1401 22.7931 22.2428C22.5876 22.3456 22.3611 22.399 22.1314 22.399C21.8112 22.399 21.4997 22.2952 21.2436 22.1031C21.0882 21.9865 20.9572 21.8405 20.8582 21.6733C20.7592 21.5061 20.6941 21.3211 20.6666 21.1287C20.6392 20.9364 20.6498 20.7405 20.6981 20.5522C20.7463 20.364 20.8311 20.1871 20.9477 20.0317L23.611 16.4807H9.17564C8.78323 16.4807 8.40689 16.3248 8.12941 16.0473C7.85193 15.7698 7.69604 15.3935 7.69604 15.0011C7.69604 14.6087 7.85193 14.2323 8.12941 13.9548C8.40689 13.6774 8.78323 13.5215 9.17564 13.5215H23.7441L21.1992 9.94087C21.0857 9.78184 21.0048 9.6019 20.9612 9.41143C20.9175 9.22096 20.9121 9.02374 20.9451 8.83115C20.9781 8.63856 21.0489 8.45442 21.1535 8.28935C21.2581 8.12429 21.3943 7.98157 21.5543 7.86943C21.8745 7.6448 22.2705 7.55605 22.6559 7.62259C23.0413 7.68914 23.3847 7.90555 23.611 8.22454Z" fill={ap_userPrimaryColor}/><path d="M16.3785 2H6.94629C4.18487 2 1.94629 4.23858 1.94629 7V23C1.94629 25.7614 4.18486 28 6.94629 28H16.3785" 
         stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round"/>
        </svg> 
    )
}


export default function LogOutListItem() {
    return (
        <>
            <Icon component={() => LogOutIcon()} />
        </>
    )
}