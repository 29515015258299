import { ConfigProvider, Modal, Table, theme, Button } from 'antd';
import React, { useEffect, useState } from 'react'
import ApiService_Obj from '../../api';
import approvalsConstant from "../TransferToApprovals/constants";
import { CloseOutlined } from '@ant-design/icons';
import styles from "./index.module.css"
export default function CloseApprovalsForApplication({ setRefresh, setShowMoadal, showModal, clientId, businessName, applicationID }) {
    const { token: { themeFontColor } } = theme.useToken();
    const userTheme = { token: { colorText: themeFontColor } };
    const modalProps = {
        open: showModal,
        footer: null,
        closable: false,
        getContainer: ".AppTheme",
        width: "80vw",
        centered: true
    };
    const [loading, setLoading] = useState(false);
    const [approvals, setApprovals] = useState([]);
    useEffect(() => {
        setLoading(true);
        ApiService_Obj.fetchClientCreditAvailableApprovals(clientId, 0).then((response) => {
            setApprovals(response);
            setLoading(false);
        });
    }, [clientId]);
    
    const updateApproval = (id, payload) => {
        return ApiService_Obj.updateApproval(id, payload);
    }
    const handleCloseApprovals = async () => {
        if (approvals?.length === 0) return;
        setLoading(true);
        try {
            const payload = {
                ap_cancelledReason: "Opportunity was manually closed.",
                ap_cancelledDate: new Date(),
                ap_status: "Cancelled",
                ap_statusStateCode: "Inactive",
                ap_closingCategory: "Customer Withdrawn",
                ap_approvalDeclined: true
            };
            const response = await Promise.all(approvals?.map((el) => {
                return updateApproval(el?._id, payload);
            }));
            if(response){
                let data = {
                    ap_stateCode: 'Won',
                    ap_closingCategory: 'Won'
                }
                await ApiService_Obj.updateApplication(applicationID, data);
                setRefresh(prev => prev + 1);
            }
        } catch (error) {
            throw new Error("Something went wrong !");
        }
        finally {
            setShowMoadal(false);
            setLoading(false);
        }
    };
    return (
        <div>
            <ConfigProvider
                theme={userTheme}
                renderEmpty={() => (<div>No Approvals Found with available credit</div>)}
            >
                <Modal {...modalProps}
                    onCancel={() => setShowMoadal(false)}
                    onOk={() => setShowMoadal(false)}>
                    <div className={styles["Container"]}>
                        <div className={styles["Header"]}>
                            <CloseOutlined
                                onClick={() => setShowMoadal(false)}
                                className={styles["Close"]}
                            />
                            Close Application
                        </div>
                        <div className={styles["SubHeader"]}>
                            {businessName}
                        </div>
                        <Table
                            dataSource={approvals}
                            columns={approvalsConstant.generateApprovalsTableColumns()}
                            loading={loading}
                            pagination={false}
                            rowKey="_id"
                        />
                        <div className={styles["ActionButtons"]}>
                            <Button
                                className={styles["CancelButton"]}
                                onClick={() => setShowMoadal(false)}
                            >Cancel</Button>
                            <Button
                                type="primary"
                                disabled={!(approvals.length > 0)}
                                loading={loading}
                                className={styles["Button"]}
                                onClick={handleCloseApprovals}
                            >Close</Button>
                        </div>
                    </div>
                </Modal>
            </ConfigProvider>
        </div>
    )
};