import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EmptyApprovals = () => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="108" height="69" viewBox="0 0 108 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73.4032 2.3125L35.3 1.8875L16.175 23.1375L15.75 55.0125L16.175 57.9875L18.725 60.1125H86.3L90.125 59.2625L91.825 56.2875V22.7125L73.4032 2.3125Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.325 47.8377C6.36563 50.0441 0.875 53.017 0.875 56.2875C0.875 63.0944 24.6599 68.6125 54 68.6125C83.3401 68.6125 107.125 63.0944 107.125 56.2875C107.125 53.017 101.634 50.0441 92.675 47.8377V54.4625C92.675 58.0523 89.7648 60.9625 86.175 60.9625H21.825C18.2351 60.9625 15.325 58.0523 15.325 54.4625V47.8377Z" fill="#D9D9D9"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M34.5003 4.54612C35.164 3.80862 36.1096 3.3875 37.1018 3.3875H54H70.8982C71.8904 3.3875 72.836 3.80862 73.4997 4.54612L88.882 21.6375H74.725C71.1351 21.6375 68.225 24.5476 68.225 28.1375V28.7625C68.225 30.6955 66.658 32.2625 64.725 32.2625H54H43.275C41.342 32.2625 39.775 30.6955 39.775 28.7625V28.1375C39.775 24.5476 36.8649 21.6375 33.275 21.6375H19.118L34.5003 4.54612ZM54 35.2625H43.275C39.6851 35.2625 36.775 32.3523 36.775 28.7625V28.1375C36.775 26.2045 35.208 24.6375 33.275 24.6375H17.25V55.1125C17.25 57.0455 18.817 58.6125 20.75 58.6125H54H87.25C89.183 58.6125 90.75 57.0455 90.75 55.1125V24.6375H74.725C72.792 24.6375 71.225 26.2045 71.225 28.1375V28.7625C71.225 32.3523 68.3148 35.2625 64.725 35.2625H54ZM54 61.6125H20.75C17.1601 61.6125 14.25 58.7023 14.25 55.1125V23.1375C14.25 22.7669 14.3872 22.4095 14.6351 22.134L32.2704 2.53923C33.5031 1.16959 35.2591 0.387497 37.1018 0.387497H54H70.8982C72.7408 0.387497 74.4969 1.16959 75.7296 2.53923L93.3649 22.134C93.6128 22.4095 93.75 22.7669 93.75 23.1375V55.1125C93.75 58.7023 90.8398 61.6125 87.25 61.6125H54Z" fill={ap_userPrimaryColor}/>
        </svg>

    );
};

export default function EmptyApprovalsIcon (props) {
    return <Icon component={EmptyApprovals} {...props}/>
}

