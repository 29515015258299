import FilterColumn from "../../components/FilterColumn";
import SearchColumn from "../../components/SearchColumn";
import SortColumn from "../../components/SortColumn";
import validate from "../../utils/Validations";

export default function generateClientTableColumns(clientData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, setFilters, customSort, setCustomSort) {

    return [
        {
            title: 'Client',
            dataIndex: 'ap_businessName',
            key: 'ap_businessName',
            ...SortColumn(customSort, setCustomSort, 'ap_businessName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_businessName'),
            ellipsis: { showTitle: false },
            width: 120,
        },
        {
            title: 'Industry',
            dataIndex: 'ap_industry',
            key: 'ap_industry',
            ...FilterColumn(clientData, setFilters, 'ap_industry'),
            ...SortColumn(customSort, setCustomSort, 'ap_industry'),
            ellipsis: { showTitle: false },
            width: 100,
        },
        {
            title: 'Type',
            dataIndex: 'ap_type',
            key: 'ap_type',
            ...FilterColumn(clientData, setFilters, 'ap_type'),
            ...SortColumn(customSort, setCustomSort, 'ap_type'),
            ellipsis: { showTitle: false },
            width: 100,
        },
        {
            title: 'Email',
            key: 'ap_email',
            ellipsis: { showTitle: false },
            width: 120,
            render: (record) => {
                return <a href={`mailto:${record.ap_email}`}>{record.ap_email}</a>
            }
        },
        {
            title: 'Telephone',
            key: 'ap_telephone',
            ellipsis: { showTitle: false },
            width: 100,
            render: (record) => {
                return <a href={`tel:${record.ap_telephone}`}>{validate.FormatTelephone(String(record.ap_telephone)).input}</a>
            }
        },
    ];
}