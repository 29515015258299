import Icon from '@ant-design/icons';
import { theme } from 'antd';

function NewRequirements() {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="90" height="160" viewBox="0 0 99 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17 0.5C12.6902 0.5 8.55698 2.21205 5.50951 5.25951C2.46205 8.30698 0.75 12.4402 0.75 16.75V114.25C0.75 118.56 2.46205 122.693 5.50951 125.74C8.55698 128.788 12.6902 130.5 17 130.5H82C86.3098 130.5 90.443 128.788 93.4905 125.74C96.5379 122.693 98.25 118.56 98.25 114.25V44.4888C98.2472 41.2575 96.9613 38.1597 94.675 35.8763L62.8737 4.06687C60.589 1.7836 57.4913 0.50069 54.2613 0.5H17ZM11.2548 11.0048C9.73103 12.5285 8.875 14.5951 8.875 16.75V114.25C8.875 116.405 9.73103 118.472 11.2548 119.995C12.7785 121.519 14.8451 122.375 17 122.375H82C84.1549 122.375 86.2215 121.519 87.7452 119.995C89.269 118.472 90.125 116.405 90.125 114.25V49.25H61.6875C58.4552 49.25 55.3552 47.966 53.0696 45.6804C50.784 43.3948 49.5 40.2948 49.5 37.0625V8.625H17C14.8451 8.625 12.7785 9.48102 11.2548 11.0048ZM61.6875 41.125H88.4431L57.625 10.3069V37.0625C57.625 38.1399 58.053 39.1733 58.8149 39.9351C59.5767 40.697 60.6101 41.125 61.6875 41.125Z" fill="#484848" />
            <path d="M49.5381 95.1046C48.5701 95.1046 47.8059 94.799 47.2455 94.1876C46.7361 93.5762 46.4304 92.7102 46.3285 91.5893L44.0359 62.397C43.8831 60.512 44.2906 59.0091 45.2586 57.8883C46.2266 56.7165 47.6531 56.1306 49.5381 56.1306C51.3722 56.1306 52.7477 56.7165 53.6648 57.8883C54.6328 59.0091 55.0403 60.512 54.8875 62.397L52.5949 91.5893C52.544 92.7102 52.2383 93.5762 51.6779 94.1876C51.1684 94.799 50.4552 95.1046 49.5381 95.1046ZM49.5381 111C47.8059 111 46.4049 110.465 45.335 109.395C44.3161 108.325 43.8066 106.95 43.8066 105.268C43.8066 103.638 44.3161 102.314 45.335 101.295C46.4049 100.225 47.8059 99.6898 49.5381 99.6898C51.3212 99.6898 52.6968 100.225 53.6648 101.295C54.6837 102.314 55.1932 103.638 55.1932 105.268C55.1932 106.95 54.6837 108.325 53.6648 109.395C52.6968 110.465 51.3212 111 49.5381 111Z" fill={themeDangerColor} />
        </svg>

    );
}

export default function NewRequirementsIcon(props) {
    return <Icon component={NewRequirements} {...props} />
}