import { theme } from "antd";
import format from "../../../utils/Validations";
import HomeTotalVolumeIcon from "../../../assets/images/icons/Home/HomeTotalVolumeIcon";
import HomeTotalFeeIcon from "../../../assets/images/icons/Home/HomeTotalFeeIcon";
import HomeAvgPercentageIcon from "../../../assets/images/icons/Home/HomeAvgPercentageIcon";

function growthChartConfiguration(data) {

    const { token: { ap_userPrimaryColor, ap_userSecondaryColor } } = theme.useToken();

    return {
        data,
        data: [data, data],
        xField: 'vendorFundedDate',
        yField: ['drawdownAmount', 'agentFee'],
        geometryOptions: [
            {
                geometry: 'column',
                color: ap_userPrimaryColor,
                smooth: true,
            },
            {
                geometry: 'line',
                color: ap_userSecondaryColor,
                lineStyle: {
                    lineWidth: 2,
                    lineDash: [4, 4],
                },
                smooth: true,
            },
        ],
        xAxis: { label: { autoRotate: false } },
        yAxis: {
            drawdownAmount: {
                title: { text: 'Drawdown Amount' },
                label: { formatter: (v) => format.FormatCurrency(v) }
            },
            agentFee: {
                title: { text: 'Agent Fee' },
                label: { formatter: (v) => format.FormatCurrency(v) }
            },
        },
        legend: { position: 'top' },
        meta: {
            drawdownAmount: { alias: 'Drawdown Amount' },
            agentFee: { alias: 'Agent Fee' },
        },
        tooltip: {
            formatter: (datum) => ({
                name: datum.drawdownAmount ? 'Drawdown Amount' : 'Agent Fee',
                value: format.FormatCurrency(datum.drawdownAmount || datum.agentFee)
            }),
        },
    };
}

function metricsComponentData(metrics = {}) {
    return [
        {
            icon: <HomeTotalVolumeIcon />,
            value: format.FormatCurrency(metrics?.totalVolume),
            label: "Total Volume",
        },
        {
            icon: <HomeTotalFeeIcon />,
            value: format.FormatCurrency(metrics?.totalFee),
            label: "Total Fee Earned",
        },
        {
            icon: <HomeAvgPercentageIcon />,
            value: metrics?.totalYield?.toFixed(2) + "%",
            label: "Total Yield",
        },
    ];
}

function cleanData(data = []) {

    const eligibleData = data.filter(({ ap_vendorFundedDate }) => new Date(ap_vendorFundedDate) >= new Date('2024-01-01T00:00:00.000Z'));

    const updatedData = eligibleData.map(({ ap_vendorFundedDate, ap_agentFee, ap_drawdownAmount }) => {
        const vendorFundedDate = ap_vendorFundedDate?.split('T')[0]; // Extract the date part
        const agentFee = ap_agentFee / 100;
        const drawdownAmount = ap_drawdownAmount / 100;

        // Only return an object if all required fields exist
        return (vendorFundedDate && agentFee && drawdownAmount) ? {
            vendorFundedDate,
            agentFee,
            drawdownAmount,
        } : null;
    }).filter(item => item !== null);

    const sortedData = updatedData.sort((a, b) => new Date(a.vendorFundedDate) - new Date(b.vendorFundedDate));

    return sortedData;
}

const growthChartConstants = {
    growthChartConfiguration,
    metricsComponentData,
    cleanData,
};

export default growthChartConstants;