import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EmptyGrowth = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="75" height="72" viewBox="0 0 75 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.2141 67.1429C67.5554 67.1429 68.6427 68.2302 68.6427 69.5714C68.6427 70.9127 67.5554 72 66.2141 72H4.31391C3.51826 72 2.7552 71.6839 2.19259 71.1213L1.52138 70.4501C0.958771 69.8875 0.6427 69.1244 0.6427 68.3288V6.42857C0.6427 5.08731 1.73001 4 3.07127 4C4.41253 4 5.49984 5.08731 5.49984 6.42857V64.1429C5.49984 65.7997 6.84299 67.1429 8.49984 67.1429H66.2141Z" fill="#D9D9D9" />
            <path d="M61.0499 21.6997C59.8783 20.5274 57.9782 20.5272 56.8064 21.6992L34.8122 43.6977C34.2496 44.2604 33.4865 44.5766 32.6907 44.5766H31.7378C30.9419 44.5766 30.1785 44.2603 29.6159 43.6972L24.6214 38.6998C23.4498 37.5275 21.5496 37.5273 20.3778 38.6994L6.50997 52.5711C5.56046 53.5208 4.02076 53.521 3.07112 52.5713C2.12157 51.6218 2.12157 50.0822 3.07112 49.1327L19.9017 32.3021C20.4643 31.7395 21.2274 31.4234 22.023 31.4234H22.9761C23.772 31.4234 24.5354 31.7397 25.098 32.3028L30.0926 37.3003C31.2641 38.4726 33.1643 38.4728 34.3361 37.3008L56.3302 15.3023C56.8929 14.7396 57.656 14.4234 58.4518 14.4234H59.4003C60.196 14.4234 60.959 14.7395 61.5216 15.3021L68.6405 22.421C69.5888 23.3693 69.5894 24.9067 68.6417 25.8557C67.6929 26.8058 66.1533 26.8061 65.2041 25.8564L61.0499 21.6997Z" fill="#D9D9D9" />
            <path d="M70.2141 63.1429C71.5554 63.1429 72.6427 64.2302 72.6427 65.5714C72.6427 66.9127 71.5554 68 70.2141 68H8.31391C7.51826 68 6.7552 67.6839 6.19259 67.1213L5.52138 66.4501C4.95877 65.8875 4.6427 65.1244 4.6427 64.3288V2.42857C4.6427 1.08731 5.73001 0 7.07127 0C8.41253 0 9.49984 1.08731 9.49984 2.42857V60.1429C9.49984 61.7997 10.843 63.1429 12.4998 63.1429H70.2141Z" fill={ap_userPrimaryColor} />
            <path d="M65.0499 17.6997C63.8783 16.5274 61.9782 16.5272 60.8064 17.6992L38.8122 39.6977C38.2496 40.2604 37.4865 40.5766 36.6907 40.5766H35.7378C34.9419 40.5766 34.1785 40.2603 33.6159 39.6972L28.6214 34.6998C27.4498 33.5275 25.5496 33.5273 24.3778 34.6994L10.51 48.5711C9.56046 49.5208 8.02076 49.521 7.07112 48.5713C6.12157 47.6218 6.12157 46.0822 7.07112 45.1327L23.9017 28.3021C24.4643 27.7395 25.2274 27.4234 26.023 27.4234H26.9761C27.772 27.4234 28.5354 27.7397 29.098 28.3028L34.0926 33.3003C35.2641 34.4726 37.1643 34.4728 38.3361 33.3008L60.3302 11.3023C60.8929 10.7396 61.656 10.4234 62.4518 10.4234H63.4003C64.196 10.4234 64.959 10.7395 65.5216 11.3021L72.6405 18.421C73.5888 19.3693 73.5894 20.9067 72.6417 21.8557C71.6929 22.8058 70.1533 22.8061 69.2041 21.8564L65.0499 17.6997Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function EmptyGrowthIcon(props) {
    return <Icon component={EmptyGrowth} {...props} />
}

