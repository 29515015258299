import axios from 'axios';
import HttpInterceptorService from '../services/http-interceptor.service';
import NotificationService from "../services/notification.service";
import API_ROUTES from './constants';
import appInsightsObj from '../services/app-insight-service';

// TODO: To create a separate internceptor for all external Axios Request. For reference, we have an AxiosInterceptor in the backend.

class ApiService extends HttpInterceptorService {

    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    // GRAPH API's
    // Returns Drawdown Graph Data for Area
    async fetchDrawdownsByProcessStageArea(timeState) {
        return (await this._httpClient.get(API_ROUTES.HOME_BAR_GRAPH, { params: { "graphDetails": "ap_drawdownFee,ap_agentFee", "timeState": timeState } }))?.data;
    }

    // PIE GRAPH API's
    // Returns Drawdown Graph Data for Area
    async fetchDrawdownsByProcessStagePie() {
        return (await this._httpClient.get(API_ROUTES.HOME_PIE_GRAPH))?.data;
    }


    // MY TASK API's
    // Returns Task List for Agent
    async fetchAllTasks() {
        return (await this._httpClient.get(API_ROUTES.HOME_TASKS))?.data;
    }

    // Creates a Task for Particular Agent
    async addMyTask(data) {
        const response = (await this._httpClient.post(API_ROUTES.HOME_TASKS, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { msg: response.data.msg },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a task, Please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a task, Please try again later.'
                }
            }))
        return response;
    }

    // Edit a Task for Particular Agent
    async editMyTask(ap_agentId, data) {
        const response = (await this._httpClient.put(API_ROUTES.HOME_TASKS + "/" + ap_agentId, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { msg: response.data.msg || "Task updated Successfully" },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a task, Please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a task, Please try again later.'
                }
            }))
        return response;
    }

    // delete a Task for Particular Agent
    async deleteMyTask(ap_agentId) {
        const response = (await this._httpClient.delete(API_ROUTES.HOME_TASKS + "/" + ap_agentId, {}, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { msg: response.data.msg || "Task deleted Successfully" },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a task, Please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a task, Please try again later.'
                }
            }))
        return response;
    }


    // CLIENT API's
    // Returns Individual Client's data
    async fetchClientDetails(ap_clientId) {
        return (await this._httpClient.get(API_ROUTES.CLIENTS + "/" + ap_clientId))?.data;
    }

    // Returns All Clients for Agents
    async fetchAgentClientList(ap_agentId) {
        return (await this._httpClient.get(API_ROUTES.CLIENTS + "/", { params: { ap_agentId: ap_agentId } }))?.data;
    }

    // Returns All Agent's Clients List for Dropdown
    async fetchAgentClients(ap_agentId) {
        const response = (await this._httpClient.get(API_ROUTES.CLIENTS + "/", { params: { ap_agentId: ap_agentId } }))?.data;
        let list = [];
        for (let i = 0; i < response.length; i++) {
            list.push({
                label: response[i].ap_businessName,
                value: response[i].ap_businessName,
            })
        }
        return list;
    }

    // TODO: Merge with fetchAgentClients
    async fetchAgentClient(ap_agentId) {
        const filter = {};
        // const filter = { ap_agentId: ap_agentId };
        const response = (await this._httpClient.get(API_ROUTES.CLIENTS + "/", { params: filter }))?.data;
        const list = [];
        for (let i = 0; i < response.length; i++) {
            list.push({
                label: response[i]?.ap_businessName,
                value: response[i]?._id
            })
        }
        return list;
    }

    // returns all the clients for Agent or on File
    async fetchSimilarClients(newClientName, onFileSearch = false) {
        return (await this._httpClient.get(`${API_ROUTES.CLIENTS}/search`, { params: { search: newClientName, onFileSearch: onFileSearch } }))?.data;
    }

    /**
     * 
     * @param {*} clientName 
     * @returns {
     *      filteredClient: {},
     *      clientData: {},
     *      clientExist: Boolean,
     *      alreadyExistWithAgent: Boolean,
     *      alreadyExistWithOtherAgent: Boolean
     * }
     */
    async searchGlobalClients(clientName) {
        return (await this._httpClient.get(`${API_ROUTES.CLIENTS}/search/global`, { params: { search: clientName } }))?.data;
    }

    // returns client fetched from CRM
    async fetchCRMClient(crm_accountId) {
        return (await this._httpClient.get(`${API_ROUTES.CLIENTS}/search/crm/${crm_accountId}`, {}))?.data;
    }

    // Post/Create Client data to DB
    async createClient(data) {

        const response = await this._httpClient.post(API_ROUTES.CLIENTS + "/", data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { clientId: response?.data?.data?._id, msg: 'Client Added Successfully!' },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a client, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a client, please try again later.'
                }
            })
        return response;
    }

    // Update Client data 
    async updateClient(ap_clientId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.CLIENTS}/${ap_clientId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Client Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a client, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a client, please try again later.'
                }
            })

        return response;
    }

    // Send Client Update Request
    async sendUpdateRequest(ap_clientId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.CLIENTS}/send_update_request/${ap_clientId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Client Update Request Sent Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a client, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a client, please try again later.'
                }
            })

        return response;
    }


    // CONTACT API's
    // Return Client Contacts
    async fetchClientContacts(ap_clientId) {
        if (ap_clientId)
            return (await this._httpClient.get(API_ROUTES.CONTACTS, { params: { ap_clientId: ap_clientId } }))?.data;
        else return [];
    }

    // Returns Agent Contacts
    async fetchAgentContacts(ap_agentId) {
        if (ap_agentId)
            return (await this._httpClient.get(API_ROUTES.CONTACTS, { params: { ap_agentId: ap_agentId } }))?.data;
        else return [];
    }

    async fetchIndividualContact(ap_contactId) {
        return (await this._httpClient.get(API_ROUTES.CONTACTS + "/" + ap_contactId))?.data;
    }

    async fetchAgentContactsData(accessToken) {

        if (!accessToken)
            return {
                success: false,
                error: 'Access Denied, please try again later.'
            }

        const headers = {
            headers: {
                'x-access-token': accessToken,
            },
            withCredentials: true,
            credentials: 'include',
        }

        const response = await this._httpClient.get(API_ROUTES.CONTACTS, headers)
            .then(response => {
                if (response?.code === 200)
                    return { success: response?.data, error: '' };
                return { success: false, error: 'Access Denied, please try again later.' }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'Access Denied, please try again later.' }
            })

        return response;
    }

    // Post Contact data to DB
    async createContact(data) {

        const response = await this._httpClient.post(API_ROUTES.CONTACTS, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Contact Added Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a contact, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a contact, please try again later.'
                }
            })

        return response;
    }

    // Update Contact data
    async updateContact(ap_contactId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.CONTACTS}/${ap_contactId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Contact Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a contact, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a contact, please try again later.'
                }
            })

        return response;
    }

    // Update Contact Dats if accessToken is provided
    async updateContactsData(ap_contactId, data, accessToken) {

        if (!accessToken)
            return {
                success: false,
                error: 'Access Denied, please try again later.'
            }

        const endPoint = `${API_ROUTES.CONTACTS}/${ap_contactId}`

        const headers = {
            headers: {
                'x-access-token': accessToken,
            },
            withCredentials: true,
            credentials: 'include',
        }

        const response = await this._httpClient.put(endPoint, data, headers)
            .then(response => {
                if (response?.code === 200)
                    return { success: 'Contact Updated Successfully!!!', error: false }
                return { success: false, error: 'There was problem updating the contact, please try again later.' }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was problem updating the contact, please try again later.' }
            })

        return response;
    }

    // Delete Contact data
    async deleteContact(ap_contactId, data) {
        const response = await this._httpClient.delete(`${API_ROUTES.CONTACTS}/${ap_contactId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Contact Deleted Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error deleting a contact, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error deleting a contact, please try again later.'
                }
            })

        return response;
    }


    // VENDOR API's
    // Fetch Vendor List for dropdown
    async fetchVendorsList() {
        const response = (await this._httpClient.get(API_ROUTES.VENDORS))?.data;
        let list = [];
        for (let i = 0; i < response.length; i++) {
            list.push({
                label: response[i].ap_name,
                value: response[i]._id,
                isPrivateSale: response[i]?.ap_privateSale
            })
        }
        return list;
    }

    // Post Vendor data to DB
    async createVendor(data) {

        const response = await this._httpClient.post(API_ROUTES.VENDORS, data, {
            headers: API_ROUTES.VENDORS.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Vendor Added Successfully!',
                        data: response?.data?.data,
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a vendor, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a vendor, please try again later.'
                }
            })

        return response;

    }

    // Delete Vendor data
    async deleteVendor(ap_vendorId) {
        const response = await this._httpClient.delete(`${API_ROUTES.VENDORS}/${ap_vendorId}`, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Vendor Deleted Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error deleting a Vendor, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error deleting a Vendor, please try again later.'
                }
            })

        return response;
    }

    async fetchIndividualVendor(ap_vendorId) {
        if (!ap_vendorId) return;
        return (await this._httpClient.get(`${API_ROUTES.VENDORS}/${ap_vendorId}`))?.data;
    }


    // APPROVAL API's
    // Fetch Agent Approvals
    async fetchAgentApprovals(ap_agentId) {
        if (!ap_agentId)
            return [];

        const queryFilter = {
            ap_agentId: ap_agentId,
            creditAvailable: 0,
            greaterThan: true
        }
        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    // Returns Client Approvals
    async fetchClientApprovals(ap_clientId) {
        if (!ap_clientId)
            return [];

        const queryFilter = {
            ap_clientId: ap_clientId,
            creditAvailable: 0,
            greaterThan: true
        }
        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    // Returns Client's Credit Available Approvals
    async fetchClientCreditAvailable(ap_clientId) {
        if (!ap_clientId)
            return [];

        const queryFilter = {
            ap_clientId: ap_clientId,
            creditAvailable: 0,
            greaterThan: true
        }

        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    // Returns Agent's Credit Available Approvals
    async fetchAgentCreditAvailable() {

        const queryFilter = {
            creditAvailable: 0,
            greaterThan: true
        }

        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    async fetchAgentCancelled() {
        const queryFilter = {
            ap_status: 'Cancelled'
        }
        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    // Returns Agent's Expired Approvals
    async fetchExpiredApprovals() {
        const queryFilter = {
            ap_status: 'Expired'
        }

        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    async fetchClientCreditAvailableApprovals(ap_clientId, minCreditAvailable) {
        if (!ap_clientId)
            return [];

        const queryFilter = {
            ap_clientId: ap_clientId,
            creditAvailable: minCreditAvailable,
            greaterThanEqual: true,
        }

        return (await this._httpClient.get(API_ROUTES.APPROVALS, { params: queryFilter }))?.data;
    }

    // Retruns Individual Approvals Data
    async fetchIndividualApprovals(ap_approvalId) {
        return (await this._httpClient.get(`${API_ROUTES.APPROVALS}/${ap_approvalId}`))?.data;
    }

    // Update Individual Approval
    async updateApproval(ap_approvalId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.APPROVALS}/${ap_approvalId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Approval Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating the approva, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the approval, please try again later.'
                }
            })

        return response;
    }


    // ASSET API's
    // Returns All Assets List for Individual Application
    async fetchApplicationAssetList(ap_applicationId) {
        return (await this._httpClient.get(API_ROUTES.ASSETS, { params: { ap_applicationId: ap_applicationId } }))?.data;
    }

    // Returns All Assets for Individual Application Drafts
    async fetchApplicationDraftAssetList(ap_applicationDraftId) {
        return (await this._httpClient.get(API_ROUTES.ASSETS, { params: { ap_applicationDraftId: ap_applicationDraftId } }))?.data;
    }

    // ASSET API's
    // Fetch All Drawdown Assets
    async fetchDrawdownAssets(ap_drawdownId) {
        return (await this._httpClient.get(API_ROUTES.DRAWDOWN_ASSETS, { params: { ap_drawdownId: ap_drawdownId } }))?.data;
    }

    // ASSET API's
    // Returns All Assets List for particular approval
    async fetchApprovalAssetList(ap_approvalId) {
        return (await this._httpClient.get(API_ROUTES.APPROVAL_ASSETS, { params: { ap_approvalId: ap_approvalId } }))?.data;
    }

    async fetchPPSAssetList(ap_ppsId) {
        return (await this._httpClient.get(API_ROUTES.PAYMENTSCHEDULES_ASSETS, { params: { ap_ppsId: ap_ppsId } }))?.data;
    }

    // Post Asset data to DB
    async createAsset(data) {

        const response = await this._httpClient.post(API_ROUTES.ASSETS, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {

                if (response.status === 'success') {
                    return {
                        success: { assetId: response?.data?.data?._id, msg: 'Asset Added Successfully!' },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a Asset, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a asset, please try again later.'
                }
            })
        return response;
    }

    // Update Asset Data to DB
    async updateAsset(ap_assetId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.ASSETS}/${ap_assetId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Asset Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating the asset data, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the asset, please try again later.'
                }
            })

        return response;
    }

    // Delete Asset data
    async deleteAsset(ap_assetId, data) {
        const response = await this._httpClient.delete(`${API_ROUTES.ASSETS}/${ap_assetId}`, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Asset Deleted Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error deleting a asset, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error deleting a asset, please try again later.'
                }
            })

        return response;
    }

    // APPLICATION DRAFT API's
    // GET ALL Application Drafts
    async fetchAllApplicationDrafts() {
        return (await this._httpClient.get(API_ROUTES.APPLICATION_DRAFT))?.data;
    }

    // GET ACTIVE Application Drafts
    async fetchActiveApplicationDrafts() {
        return (await this._httpClient.get(API_ROUTES.ACTIVE_APPLICATION_DRAFT))?.data;
    }

    // GET Individual Application Draft
    async fetchIndividualApplicationDraft(ap_applicationDraftId) {
        return (await this._httpClient.get(`${API_ROUTES.APPLICATION_DRAFT}/${ap_applicationDraftId}`))?.data;
    }

    // Get All Client's Open Applications
    async fetchClientOpenDrafts(ap_clientId) {
        const filters = {
            ap_clientId: ap_clientId,
            ap_status: 'Active'
        }
        return (await this._httpClient.get(API_ROUTES.APPLICATION_DRAFT, { params: filters }))?.data;
    }

    // POST (Create) Application Draft
    async createApplicationDraft(data) {
        const headers = { headers: API_ROUTES.HEADER_JSON };
        const response = await this._httpClient.post(API_ROUTES.APPLICATION_DRAFT, data, headers)
            .then(response => {
                if (response?.code === 200)
                    return {
                        success: {
                            applicationDraftId: response?.data?.data?._id,
                            msg: 'Application Drafted Successfully!'
                        },
                        error: ''
                    }
                else
                    return {
                        success: false,
                        error: 'Unable to Auto-save Application'
                    }
            });

        return response;
    }

    // PUT (Update) Application Draft
    async updateApplicationDraft(ap_applicationDraftId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.APPLICATION_DRAFT}/${ap_applicationDraftId}`, data, { headers: API_ROUTES.HEADER_JSON })
            .then(response => {
                return {
                    success: (response?.status === 'success') ? 'Application Auto-Saved!' : false,
                    error: (response?.status === 'success') ? '' : 'Unable to Auto-Save the Application'
                }
            });
        return response;
    }

    async deleteApplicationDraft(ap_applicationDraftId) {
        const data = { ap_status: 'Inactive' };
        const response = await this._httpClient.put(`${API_ROUTES.APPLICATION_DRAFT}/${ap_applicationDraftId}`, data, { headers: API_ROUTES.HEADER_JSON })
            .then(response => {
                return {
                    success: (response?.status === 'success') ? 'Application Draft Deleted!' : false,
                    error: (response?.status === 'success') ? '' : 'Unable to Delete the Application Draft'
                }
            });
        return response;
    }

    // APPLICATION API's
    // Post Application data to DB
    async createApplication(data) {

        const response = await this._httpClient.post(API_ROUTES.APPLICATIONS, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response?.code === 200)
                    return {
                        success: {
                            applicationId: response?.data?.data?._id,
                            msg: 'Application Sent Successfully!'
                        },
                        error: ''
                    }
                else return {
                    success: false,
                    error: 'There was an error creating a Application, please try again later.'
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a Application, please try again later.'
                }
            })
        return response;
    }

    // Update Application Data to DB
    async updateApplication(ap_applicationId, data) {
        const updatedData = {
            ...data,
            ap_lastModifiedBy: 'beacon'
        }
        const response = await this._httpClient.put(`${API_ROUTES.APPLICATIONS}/${ap_applicationId}`, updatedData, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Application Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating the application data, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the application, please try again later.'
                }
            })

        return response;
    }

    // Fetch Individual Application Data
    async fetchIndividualApplication(ap_applicationId) {
        return (await this._httpClient.get(`${API_ROUTES.APPLICATIONS}/${ap_applicationId}`))?.data;
    }

    // Get All Agent's Application
    async fetchAgentApplications(filters) {
        return (await this._httpClient.get(API_ROUTES.APPLICATIONS, { params: filters }))?.data;
    }

    // Get All Client's Open Applications
    async fetchClientOpenApplications(ap_agentId, ap_clientId) {
        const filters = {
            ap_agentId: ap_agentId,
            ap_clientId: ap_clientId,
            ap_stateCode: 'Open'
        }
        return (await this._httpClient.get(API_ROUTES.APPLICATIONS, { params: filters }))?.data;
    }

    async fetchRecentOpenApplications(ap_agentId, ap_clientId) {
        const filters = {
            ap_agentId: ap_agentId,
            ap_clientId: ap_clientId,
            ap_stateCode: 'Open',
        }
        return (await this._httpClient.get(API_ROUTES.RECENT_APPLICATIONS, { params: filters }))?.data;
    }

    // Get Latest Agent's Application
    async fetchRecentAgentApplications(limit) {
        return (await this._httpClient.get(`${API_ROUTES.APPLICATIONS}/top`, { params: { limit: limit } }))?.data;
    }

    // Get Recent Applications


    // DOCUMENT API's
    // Retuns all the uploaded document for the Deal
    // async fetchDrawdownDocs(ap_drawdownId) {
    //     return (await this._httpClient.get('document', { params: { ap_drawdownId: ap_drawdownId } }))?.data;
    // }

    // Post Contact data to DB
    async uploadDocs(data) {
        const response = await this._httpClient.post(API_ROUTES.UPLOAD, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return response
                } else {
                    return {
                        error: 'There was an error uploading document, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error uploading document, please try again later.'
                }
            })

        return response;
    }

    // This API will move the folder contents from source to destination and will delete the source folder
    async moveDocuments(data) {
        const response = await this._httpClient.post(`${API_ROUTES.UPLOAD}/rename/`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return response
                } else {
                    return {
                        error: 'There was an error moving documents, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error moving document, please try again later.'
                }
            })

        return response;
    }

    // Fetch All the documents in the blob
    async fetchDocuments(data) {
        const response = await this._httpClient.post(`${API_ROUTES.UPLOAD}/fetchFiles/`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return response
                } else {
                    return {
                        error: 'There was an error fetching documents, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error fetching document, please try again later.'
                }
            })

        return response;
    }


    // PAYMENT SCHEDULE API's
    // Returns Requested Payment Schedules List
    async fetchRequestedPaymentSchedulesList(ap_approvalId, ap_isDraft, ap_draftReferredFrom) {
        if (ap_approvalId) {
            return (await this._httpClient.get(API_ROUTES.PAYMENTSCHEDULES, { params: { ap_approvalId: ap_approvalId, ap_isDraft: ap_isDraft, ap_draftReferredFrom: ap_draftReferredFrom } }))?.data;
        }
        else return [];
    }

    // PAYMENT SCHEDULE API's
    // Returns Approval's Payment Schedules List
    async fetchApprovalPaymentSchedulesList(ap_approvalId, ap_isDraft) {
        if (ap_approvalId) {
            return (await this._httpClient.get(API_ROUTES.PAYMENTSCHEDULES, { params: { ap_approvalId: ap_approvalId, ap_isDraft: ap_isDraft } }))?.data;
        }
        else return [];
    }

    // Delete draft/requested PPS : Not in use
    async deleteDraftPPS(ap_ppsDraftId) {
        const response = await this._httpClient.delete(`${API_ROUTES.PAYMENTSCHEDULES}/${ap_ppsDraftId}`, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Requested PPS Deleted Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error deleting a Requested PPS, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error deleting a Requested PPS, please try again later.'
                }
            })

        return response;
    }

    // PAYMENT SCHEDULE API's
    // Returns Client & Broker Payment Schedules HTML
    async fetchPpsHtmlData(data, brokerSchedule, clientSchedule, color, color1, color2) {
        const response = await this._httpClient.post(`${API_ROUTES.GENERATE_PAYMENT_SCHEDULE_HTML}/${brokerSchedule}/${clientSchedule}/${color}/${color1}/${color2}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return response.data;
                } else {
                    return {
                        success: false,
                        error: 'There was an error fetching a HTML, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error fetching a HTML, please try again later.'
                }
            })

        return response;
    }

    // Returns Drawdown's Payment Schedules List
    async fetchDrawdownPaymentSchedulesList(ap_drawdownId) {
        if (ap_drawdownId)
            return (await this._httpClient.get(API_ROUTES.PAYMENTSCHEDULES, { params: { ap_drawdownId: ap_drawdownId } }))?.data;
        else return [];
    }

    // Fetch Individual Payment Schdeule
    async fetchIndividualPaymentSchedule(ap_paymentScheduleId) {
        return (await this._httpClient.get(`${API_ROUTES.PAYMENTSCHEDULES}/${ap_paymentScheduleId}`))?.data;
    }

    // Create Draft Payment Schedule
    async createDraftPaymentSchedule(data) {
        const headers = API_ROUTES.HEADER_JSON;

        const response = await this._httpClient.post(API_ROUTES.PAYMENTSCHEDULES, data, headers)
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { paymentScheduleDraftId: response.data.data._id, msg: 'Payment Schedule Draft Added Successfully!' },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a PPS Draft, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a PPS Draft, please try again later.'
                }
            })
        return response;
    }

    async createDraftPaymentScheduleAsset(data) {
        const headers = API_ROUTES.HEADER_JSON;

        const response = await this._httpClient.post(API_ROUTES.PAYMENTSCHEDULES_ASSETS, data, headers)
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { paymentScheduleAssetId: response.data.data._id, msg: 'Payment Schedule Asset Added Successfully!' },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a PPS Asset, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a PPS Asset, please try again later.'
                }
            })
        return response;
    }

    async createNewPPSRequest(data) {
        const headers = API_ROUTES.HEADER_JSON;

        const response = await this._httpClient.post(API_ROUTES.NEW_PPS_REQUEST, data, headers)
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: { newPPSRequestID: response.data.data._id, msg: 'New PPS Request Added Successfully!' },
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error creating a New PPS Request, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating a New PPS Request, please try again later.'
                }
            })
        return response;
    }

    // UpdatePayment Schedule
    async updatePaymentSchedule(ap_paymentScheduleId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.PAYMENTSCHEDULES}/${ap_paymentScheduleId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Payment Schedule Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating the payment schedule, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the payment schedule, please try again later.'
                }
            })

        return response;
    }

    // Inactive Draft Payment Schedule
    async cancelRequestedPaymentSchedule(ap_paymentScheduleId) {
        const response = await this._httpClient.put(`${API_ROUTES.PAYMENTSCHEDULES}/${ap_paymentScheduleId}`, { ap_status: false })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Requested Payment Schedule Canceled Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error Canceling the Requested payment schedule, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error Canceling the Requested payment schedule, please try again later.'
                }
            })

        return response;
    }

    // DRAWDOWN API's

    // Return All Agent Drawdowns
    async fetchAgentDrawdowns() {
        return (await this._httpClient.get('drawdowns'))?.data;
    }

    // Return All Drawdown for growth chart
    async fetchDrawdownsForGrowthChart() {
        return (await this._httpClient.get(`${API_ROUTES.DRAWDOWNS}/growth-chart`))?.data;
    }

    //Return ALL drawdowns for individual application
    async fetchAllDrawdownsForApplication(id) {
        return (await this._httpClient.get(`${API_ROUTES.DRAWDOWNS}/drawdown-for-application-id/${id}`))?.data;
    }

    // Returns Agent's Ongoing Drawdowns
    async fetchAgentOngoingDrawdowns(ap_agentId) {
        const filters = {
            ap_agentId: ap_agentId,
            "ap_processStage[$nin]": "Funded,Completed",
            // ap_drawdownCancelled: false
        }
        if (ap_agentId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: filters }))?.data;
        else return [];
    }

    // Returns Client's Ongoing Drawdowns
    async fetchClientOngoingDrawdowns(ap_clientId) {
        const filters = {
            ap_clientId: ap_clientId,
            "ap_processStage[$nin]": "Funded,Completed",
            // ap_drawdownCancelled: false
        }
        if (ap_clientId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: filters }))?.data;
        else return [];
    }

    // Returns Agent's Funded and Completed Deals
    async fetchAgentCompletedDrawdowns(ap_agentId) {
        if (ap_agentId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: { ap_agentId: ap_agentId, "ap_processStage[$in]": "Funded,Completed" } }))?.data;
        else return [];
    }

    // Returns Client's Funded and Completed Deals
    async fetchClientCompletedDrawdowns(ap_clientId) {
        if (ap_clientId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: { ap_clientId: ap_clientId, "ap_processStage[$in]": "Funded,Completed" } }))?.data;
        else return [];
    }

    // Returns Agent's Funded and Completed Deals
    async fetchAgentCancelledDrawdowns(ap_agentId) {
        const filters = {
            ap_agentId: ap_agentId,
            ap_beaconDrawdownCancelled: true
        }
        if (ap_agentId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: filters }))?.data;
        else return [];
    }

    // Returns Client's Funded and Completed Deals
    async fetchClientCancelledDrawdowns(ap_clientId) {
        const filters = {
            ap_clientId: ap_clientId,
            ap_drawdownCancelled: true
        }
        if (ap_clientId)
            return (await this._httpClient.get(API_ROUTES.DRAWDOWNS, { params: filters }))?.data;
        else return [];
    }

    // Fetch Individual Drawdown
    async fetchIndividualDrawdown(ap_drawdownId) {
        return (await this._httpClient.get(`${API_ROUTES.DRAWDOWNS}/${ap_drawdownId}`))?.data;
    }

    // Update Drawdown
    async updateDrawdown(ap_drawdownId, data) {
        data["ap_lastModifiedBy"] = "beacon";
        const response = await this._httpClient.put(`${API_ROUTES.DRAWDOWNS}/${ap_drawdownId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Drawdown Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a the data, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a drawdown, please try again later.'
                }
            })

        return response;
    }

    async cancelDrawdown(ap_drawdownId, data) {
        data["ap_lastModifiedBy"] = "beacon";
        const response = await this._httpClient.put(`${API_ROUTES.CANCEL_DRAWDOWN}/${ap_drawdownId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Drawdown Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a the data, please try again later.'
                    }
                }
            })
            .catch(error => {
                return {
                    success: false,
                    error: 'There was an error updating a drawdown, please try again later.'
                }
            })

        return response;
    }

    // REQUIREMENT API's
    // Returns Requirements List
    async fetchRequirementsList(ap_agentId, ap_entityId) {
        return (await this._httpClient.get(API_ROUTES.REQUIREMENTS, { params: { ap_agentId: ap_agentId, ap_entityId: ap_entityId } }))?.data;
    }

    async fetchUrgentRequirements() {
        return (await this._httpClient.get(API_ROUTES.URGENT_REQUIREMENTS))?.data;
    }

    async fetchIndividualRequirement(ap_requirementId) {
        return (await this._httpClient.get(`${API_ROUTES.REQUIREMENTS}/${ap_requirementId}`))?.data;
    }

    async fetchCopyableRequirement(ap_requirementId, request) {
        return (await this._httpClient.get(`${API_ROUTES.COPYABLE_REQUIREMENT}/${ap_requirementId}`, { params: request }))?.data;
    }

    async fetchDrawdownOutstandingRequirements(ap_agentId, ap_drawdownId) {
        const filters = {
            ap_agentId: ap_agentId,
            ap_entityId: ap_drawdownId,
            ap_completed: false
        }
        return (await this._httpClient.get(API_ROUTES.REQUIREMENTS, { params: filters }))?.data;
    }

    async fetchDrawdownRequirements(ap_drawdownId) {
        return (await this._httpClient.get(`${API_ROUTES.ENTITY_REQUIREMENTS}/${ap_drawdownId}`))?.data;
    }

    async fetchArrayFileDetails(array) {

        const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;

        if (!sasToken)
            return array;

        try {
            let fileDetails = [];
            for (let i = 0; i < array.length; i++) {
                const response = await axios.head(array[i] + "?" + sasToken);
                if (response.status === 200) {
                    const fileName = decodeURIComponent(array[i].split('/').pop());
                    const fileSize = parseInt(response.headers['content-length'], 10) || 0;

                    const lastModified = response.headers['last-modified'];
                    const fileDate = lastModified ? new Date(lastModified) : null;

                    fileDetails.push({
                        fileName: fileName,
                        fileSize: ((fileSize / 1024) > 1000) ? (fileSize / 1024000).toFixed(2) + " MB" : (fileSize / 1024).toFixed(2) + ' KB',
                        fileDate: fileDate,
                        fileUrl: array[i]
                    });
                } else {
                    appInsightsObj.info(new Error('Failed to retrieve file metadata. The server returned status code: ' + response.status));
                }
            }
            return fileDetails;
        }
        catch (error) {
            appInsightsObj.error(error)
            return null;
        }
    }

    async fetchFileDetails(array) {

        const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;

        if (!sasToken)
            return array;

        try {
            let fileDetails = [];
            for (let i = 0; i < array.length; i++) {

                const response = await axios.head(array[i].url + "?" + sasToken);
                if (response.status === 200) {
                    const fileName = decodeURIComponent(array[i].url.split('/').pop());
                    const fileSize = parseInt(response.headers['content-length'], 10) || 0;

                    const lastModified = response.headers['last-modified'];
                    const fileDate = lastModified ? new Date(lastModified) : null;

                    fileDetails.push({
                        fileName: fileName,
                        fileSize: ((fileSize / 1024) > 1000) ? (fileSize / 1024000).toFixed(2) + " MB" : (fileSize / 1024).toFixed(2) + ' KB',
                        fileDate: fileDate,
                        fileUrl: array[i].url,
                        updated: !!(array[i].updated)
                    });
                } else {
                    appInsightsObj.error(new Error('Failed to retrieve file metadata. The server returned status code: ' + response.status));
                }
            }
            return fileDetails;
        }
        catch (error) {
            appInsightsObj.error(error);
            return [];
        }
    }

    async updateUploadedRequirements(ap_requirementId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.REQUIREMENTS}/${ap_requirementId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Upload Requirements Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a the data, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a requirements, please try again later.'
                }
            })

        return response;
    }


    // CAPTCHA API's
    async verifyCaptcha(payload) {
        return await this._httpClient.post(API_ROUTES.VERIFY_CAPTCHA, payload, {
            headers: API_ROUTES.HEADER_JSON
        });
    }

    // Get IP Address
    async getIpAddress() {
        return (await axios.get(API_ROUTES.GET_IP_ADDRESS))?.data;
    }

    // do an axios post call to pps-ms to balance the draft payment schedule
    async fetchBalancedPPS(ppsData) {
        const responseData = await axios.post(process.env.REACT_APP_PPS_MS_URL, ppsData)
            .then(response => {
                return {
                    success: response?.data,
                    error: ''
                }
            })
            .catch(error => {
                if (error?.response?.data?.error_message?.term || error?.response?.data?.error_message?.downPaymentAmount || error?.response?.data?.error_message?.commissionAmount) {
                    return {
                        success: false,
                        error: error?.response?.data?.error_message
                    }
                } else {
                    appInsightsObj.error(error);
                    NotificationService.error('Error', "PPS cannot be calculated at this moment, please try again later or contact Lantern Sales team!");
                    return {
                        success: false,
                        error: error?.response?.data?.error_message
                    }
                }
            });
        return responseData;
    }

    // REGISTRATION REQUEST API's
    // Creates Registration Request Record
    async createRegistrationRequest(data) {

        const response = await this._httpClient.post(API_ROUTES.REGISTRATION_REQUEST, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Registration Request Created Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: response?.errors || 'Please try again later!'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error creating the agent settings, please try again later.'
                }
            });
        return response;
    }

    // Vendor Listing
    // Returns All Listings
    async fetchVendorListing() {
        return (await this._httpClient.get(API_ROUTES.LISTINGS))?.data;
    }

    // Return Available Listings
    async fetchAvailableVendorListing() {
        return (await this._httpClient.get(API_ROUTES.AVAILABLE_LISTINGS))?.data;
    }

    // Return Individual Listing
    async fetchIndividualVendorListing(ap_listingId) {
        return (await this._httpClient.get(`${API_ROUTES.LISTINGS}/${ap_listingId}`))?.data;
    }

    // Create Interest in Listing
    async showInterest(data) {
        const response = await this._httpClient.post(API_ROUTES.INTERESTS, data, { headers: API_ROUTES.HEADER_JSON })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Interest Shown Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error showing interest, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error showing interest, please try again later.'
                }
            });
        return response;
    }

    async removeInterest(ap_interestId) {
        const response = await this._httpClient.delete(`${API_ROUTES.INTERESTS}/${ap_interestId}`, { headers: API_ROUTES.HEADER_JSON })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Interest Removed Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error removing interest, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error removing interest, please try again later.'
                }
            });
        return response;
    }

    // Create Interest in Listing
    async createListingAssetRequest(data) {
        const response = await this._httpClient.post(API_ROUTES.ASSET_REQUEST, data, { headers: API_ROUTES.HEADER_JSON })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Asset Request Sent Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error sending asset request, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error sending asset request, please try again later.'
                }
            });
        return response;
    }

    // SALES CO-ORDINATOR API's
    // Return Sales Co-ordinator Data
    async fetchSalesCoordinatorData(ap_salesId) {
        return (await this._httpClient.get(`${API_ROUTES.SALES}/${ap_salesId}`))?.data;
    }

    // RESOURCES API's
    // Returns All Resources 
    async fetchResources() {
        return (await this._httpClient.get(API_ROUTES.RESOURCES))?.data;
    }

    async fetchIndividualResource(ap_resourceId) {
        return (await this._httpClient.get(`resources/${ap_resourceId}`))?.data;
    }

    // CODE API's
    // Validate code
    async validateCode(code) {
        const headers = {
            headers: API_ROUTES.HEADER_JSON
        }
        const response = await this._httpClient.post(API_ROUTES.VALIDATE_CODE, { codeId: code }, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error validating the code, please try again later' }
            });

        return response;
    }

    // Expire Code
    async expireCode(code) {
        const headers = {
            headers: API_ROUTES.HEADER_JSON
        }
        const response = await this._httpClient.post(API_ROUTES.EXPIRE_CODE, { codeId: code }, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error expiring the code, please try again later' }
            });

        return response;
    }

    // validate reset code
    async validateResetCode(code) {
        const headers = {
            headers: API_ROUTES.HEADER_JSON
        }
        const response = await this._httpClient.post(API_ROUTES.RESET_CODE, { codeId: code }, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error expiring the code, please try again later' }
            });

        return response;
    }


    // AGENT API's
    // Returns Agent Profile Data
    async fetchAgentProfileData(ap_agentId) {
        return (await this._httpClient.get(API_ROUTES.AGENT_PROFILE))?.data;
    }

    // Return Agent Profile Data if accessToken is provided
    async fetchAgentData(ap_agentId, accessToken) {

        if (!accessToken) return;

        const headers = {
            headers: {
                'x-access-token': accessToken
            },
            withCredentials: true,
            credentials: 'include',
        }

        const response = await this._httpClient.get(API_ROUTES.AGENT_PROFILE, headers)
            .then(response => {
                if (response.code === 200)
                    return response.data;
                else return {}
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {}
            })

        return response;
    }

    // Update Agent Profile Data
    async updateAgentProfile(ap_agentId, data) {

        const updatedData = {
            ...data,
            ap_lastModifiedBy: 'beacon'
        }

        const response = await this._httpClient.put(API_ROUTES.AGENT_PROFILE, updatedData, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Agent Profile Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating a the data, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a agent profile, please try again later.'
                }
            })

        return response;
    }

    // Update Agent Profile if accessToken is provided
    async updateAgent(ap_agentId, data, accessToken) {
        if (!(ap_agentId && accessToken))
            return {
                success: false,
                error: 'Access Denied, please try again later.',
            }

        const headers = {
            headers: {
                'x-access-token': accessToken,
            },
            withCredentials: true,
            credentials: 'include',
        }

        const response = await this._httpClient.put(API_ROUTES.AGENT_PROFILE, data, headers)
            .then(response => {
                if (response.code === 200)
                    return {
                        success: 'Agent Profile Updated Successfully!',
                        error: '',
                        data: response.data.data
                    }
                else return {
                    success: false,
                    error: 'There was an error updating a the data, please try again later.',
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating a the data, please try again later.',
                }
            })

        return response;

    }

    // Create Agent Profile
    async createAgentProfile(data) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'x-access-token': data.accessToken
            }
        }
        const agentData = data.agentData
        const response = await this._httpClient.post(API_ROUTES.AGENT_PROFILE, agentData, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating Agent Record, please try again later' }
            });

        return response;
    }

    async generateResetPasswordCode(data) {
        const headers = {
            headers: API_ROUTES.HEADER_JSON
        }
        const response = await this._httpClient.post(API_ROUTES.USERS_RESET_PASSWORD, data, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error generating reset password code, please try again later' }
            });

        return response;
    }

    // Update password
    async updatePassword(data) {
        const response = await this._httpClient.put(API_ROUTES.USERS_UPDATE_PASSWORD, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Password Updated Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error updating the password, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the password, please try again later.'
                }
            })

        return response;
    }

    // Fetch User Data
    async fetchUserData(ap_userId) {
        return (await this._httpClient.get(`${API_ROUTES.USERS}/${ap_userId}`))?.data;
    }

    // Fetch Agent User Data
    async fetchAgentUserData() {
        return (await this._httpClient.get(`${API_ROUTES.AGENT_USERS}`))?.data;
    }

    // Add additional User
    async addNewUser(emailAddress) {
        return (await this._httpClient.post(`${API_ROUTES.USERS}/add-user`, { emailAddress: emailAddress }))?.data;
    }

    //change password
    async changePassword(data) {
        const response = await this._httpClient.put(API_ROUTES.USERS_CHANGE_PASSWORD, data, {
            headers: API_ROUTES.HEADER_JSON
        })
        .then(response => {
            if (response.status === 'success') {
                return {
                    success: 'Password Updated Successfully!',
                    error: ''
                }
            } else {
                return {
                    success: false,
                    error: 'There was an error updating the password, please try again later.'
                }
            }
        })
        .catch(error => {
            appInsightsObj.error(error);
            return {
                success: false,
                error: 'There was an error updating the password, please try again later.'
            }
        })

    return response;
    }

    async fetchAgentLogo(url) {
        const response = await axios.get(url, {
            headers: {
                'Accept': '*/*',
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return "";
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return ""
            })

        return response;
    }

    // Create User Credentials
    async createUserCredentails(data, accessToken) {
        const headers = {
            headers: {
                ...API_ROUTES.HEADER_JSON,
                'x-access-token': accessToken
            }
        }

        const response = await this._httpClient.post(API_ROUTES.USERS_REGISTER, data, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating the credentials, please try again later' }
            });

        return response;
    }


    // SETTINGS API's

    async createDefaultSettings() {
        const headers = {
            headers: API_ROUTES.HEADER_JSON
        }

        const response = await this._httpClient.post(API_ROUTES.SETTINGS, {}, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating a settings, please try again later' }
            });

        return response;
    }

    async fetchAgentSettingsData(ap_settingId) {
        return (await this._httpClient.get(`${API_ROUTES.SETTINGS}/${ap_settingId}`))?.data;
    }

    async updateSettings(ap_settingsId, data) {
        const response = await this._httpClient.put(`${API_ROUTES.SETTINGS}/${ap_settingsId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                if (response.status === 'success') {
                    return {
                        success: 'Settings Saved Successfully!',
                        error: ''
                    }
                } else {
                    return {
                        success: false,
                        error: 'There was an error saving the settings, please try again later.'
                    }
                }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error saving the settings, please try again later.'
                }
            })

        return response;
    }

    // NOTIFICATION API's
    // Fetch Individual Notification
    async fetchIndividualNotification(ap_notificationId) {
        return (await this._httpClient.get(`${API_ROUTES.NOTIFICATIONS}/${ap_notificationId}`))?.data;
    }

    // fetch Notification Id using entity Id of requirement or drawdown
    async fetchNotificationId(ap_entityId, ap_notificationType) {
        return (await this._httpClient.get(API_ROUTES.NOTIFICATIONS, { params: { ap_entityId: ap_entityId, ap_notificationType: ap_notificationType } }))?.data;
    }

    // Fetch Agent's Active Notification
    async fetchAgentActiveNotifications(ap_agentId) {
        return (await this._httpClient.get(API_ROUTES.NOTIFICATIONS, { params: { ap_agentId: ap_agentId, ap_isActive: true } }))?.data;
    }

    // Fetch Agent's Active Accordian Notification
    async fetchAgentActiveAccordianNotifications(ap_agentId) {
        const paramsData = {
            ap_agentId: ap_agentId,
            ap_isActiveAccordian: true,
            ap_isActive: true,
            ap_isRead: false
        }
        return (await this._httpClient.get(API_ROUTES.NOTIFICATIONS, { params: paramsData }))?.data;
    }

    // Update an Individual Notification
    async updateNotification(ap_notificationId, data) {
        if(!ap_notificationId) {
            appInsightsObj.error("Err: NotificationId is undefined.");
            return {
                success: false,
                error: 'There was an error updating the notification, please try again later.'
            }
        }

        const response = await this._httpClient.put(`${API_ROUTES.NOTIFICATIONS}/${ap_notificationId}`, data, {
            headers: API_ROUTES.HEADER_JSON
        })
            .then(response => {
                // If Success
                if (response.code === 200) {
                    return {
                        code: response.code,
                        success: 'Notification Updated Successfully!',
                        error: '',
                        data: response.data
                    }
                } else {
                    return {
                        code: response.code,
                        success: false,
                        error: 'There was an error updating the notification, please try again later.'
                    }
                }
            })
            .catch((error) => {
                appInsightsObj.error(error);
                return {
                    success: false,
                    error: 'There was an error updating the notification, please try again later.'
                }
            })

        return response;
    }

    //LENDER API's
    //Fetch Individual Lender
    async fetchIndividualLender(ap_lenderId) {
        return (await this._httpClient.get(`${API_ROUTES.LENDERS}/${ap_lenderId}`))?.data;
    }

    // PG Draft API's

    /**
    * Fetch PG List by Available Application Drafts
    * @param {*} applicationDraftId 
    */
    async fetchAllAvailablePGDrafts(applicationDraftId) {
        return (await this._httpClient.get(`${API_ROUTES.PG_DRAFTS}/pg_applicationDrafts/${applicationDraftId}`))?.data;
    }

    async createPGDrafts(pgData) {
        const response = await this._httpClient.post(API_ROUTES.PG_DRAFTS, pgData)
            .then(response => {
                if (response?.code === 200)
                    return { success: response?.data, error: '' };
                return { success: false, error: 'Cannot create PG' }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating PG Record, Please try again' }
            });

        return response;
    }

    async updatePGDraft(id, pgData) {
        const response = await this._httpClient.put(`${API_ROUTES.PG_DRAFTS}/${id}`, pgData)
            .then(response => {
                if (response?.code === 200)
                    return { success: response?.data, error: '' };
                return { success: false, error: 'Cannot create PG' }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error updating PG Record, Please try again' }
            });

        return response;
    }

    async deletePGDraft(id) {
        const data = { ap_status: 'Inactive' }
        const response = await this._httpClient.put(`${API_ROUTES.PG_DRAFTS}/${id}`, data)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error updating PG Record, Please try again' }
            });

        return response;
    }

    // PG(PersonalGurantator) API's

    /**
     * Fetch PG List by Available Application
     * @param {*} applicationId 
     */
    async fetchAllAvailablePG(applicationId) {
        return (await this._httpClient.get(`${API_ROUTES.PGS}/pg_application/${applicationId}`))?.data;
    }

    async createPG(pgData) {
        const response = await this._httpClient.post(API_ROUTES.PGS, pgData)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating PG Record, Please try again' }
            });

        return response;
    }

    async updatePG(id, pgData) {
        const response = await this._httpClient.put(`${API_ROUTES.PGS}/${id}`, pgData)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error updating PG Record, Please try again' }
            });

        return response;
    }

    async deletePG(id) {
        const data = { ap_status: 'Inactive' }
        const response = await this._httpClient.put(`${API_ROUTES.PGS}/${id}`, data)
            .then(response => {
                return response;
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error deleting PG Record, Please try again' }
            });

        return response;
    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    async createAssetTransferRequest(data) {
        const response = await this._httpClient.post(API_ROUTES.ASSET_TRANSFER_REQUEST, data)
            .then(response => {
                return { success: response, error: false }
            })
            .catch(error => {
                appInsightsObj.error(error);
                return { success: false, error: 'There was an error creating Transfer Request, Please try again' }
            });

        return response;
    }
}

const ApiService_Obj = new ApiService();
export default ApiService_Obj;