import constants from "../../constants";
import validate from "../../utils/Validations";

export default function generateBuisnessDetailsData(data) {
    return ({
        businessName: {
            value: data.ap_businessName,
            error: validate.RequiredField(data.ap_businessName),
            hidden: false,
            required: true,
            inputType: 'text',
            // inputType: 'themed-input',
            // enableLock: true,
            label: 'Business Name',
        },
        taxNumber: {
            value: data.ap_taxNumber,
            error: validate.RequiredField(data.ap_taxNumber),
            hidden: false,
            required: true,
            inputType: 'text',
            // inputType: 'themed-input',
            // enableLock: true,
            label: 'Tax Number',
        },
        address: {
            value: data.ap_address,
            error: validate.RequiredField(data.ap_address),
            hidden: false,
            required: true,
            inputType: 'text',
            // inputType: 'themed-input',
            // enableLock: true,
            label: 'Address',
        },
        city: {
            value: data.ap_city,
            error: validate.RequiredField(data.ap_city),
            hidden: false,
            required: true,
            inputType: 'text',
            // inputType: 'themed-input',
            // enableLock: true,
            label: 'City',
        },
        provinceState: {
            value: data.ap_stateOrProvince,
            error: validate.RequiredField(data.ap_stateOrProvince),
            hidden: false,
            required: true,
            inputType: 'dropdown-search',
            // inputType: 'themed-dropdown-search',
            // enableLock: true,
            label: 'Province/State',
            dropdownContent: (data.ap_country === 'USA') ? constants.AMERICAN_STATES : constants.CANADIAN_PROVINCES,
        },
        country: {
            value: data.ap_country,
            error: validate.RequiredField(data.ap_country),
            hidden: false,
            required: true,
            inputType: 'dropdown-search',
            // inputType: 'themed-dropdown-search',
            // enableLock: true,
            label: 'Country',
            dropdownContent: constants.COUNTRIES,
        },
        postalZipCode: {
            value: data.ap_postalCode,
            error: validate.RequiredField(data.ap_postalCode),
            hidden: false,
            required: true,
            inputType: 'text',
            // inputType: 'themed-input',
            // enableLock: true,
            label: 'Postal/Zip Code',
        },
    })
}