import { theme } from 'antd';
import Icon from '@ant-design/icons';

const TransferAsset = () => {
    const { token: { ap_userPrimaryColor, ap_userSecondaryColor, userSecondaryHoverColor }, } = theme.useToken();
    return (
        <svg width="167" height="185" viewBox="0 0 167 185" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="125.807" cy="137.268" r="24.5818" transform="rotate(15 125.807 137.268)" fill="url(#paint0_linear_5708_26757)" />
            <circle cx="72.2009" cy="101.044" r="32.8704" transform="rotate(83.8077 72.2009 101.044)" fill="url(#paint1_linear_5708_26757)" />
            <circle cx="106.512" cy="59.9498" r="42.4696" transform="rotate(-135 106.512 59.9498)" fill="url(#paint2_linear_5708_26757)" />
            <circle cx="83.4998" cy="102.038" r="60.8142" transform="rotate(30 83.4998 102.038)" stroke={ap_userSecondaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="10 12" />
            <path d="M51.7726 49.2056C52.5573 48.9134 53.3322 49.6574 53.0721 50.4534L43.1197 80.909C42.8336 81.7847 41.616 81.8404 41.251 80.9946L35.4365 67.5194L21.7362 62.2563C20.8762 61.9259 20.8826 60.7071 21.7459 60.3857L51.7726 49.2056Z" fill="#CFDCE9" />
            <path d="M35.4365 67.5194L41.251 80.9946C41.616 81.8404 42.8336 81.7847 43.1197 80.909L53.0721 50.4534C53.3322 49.6574 52.5573 48.9134 51.7726 49.2056L21.7459 60.3857C20.8826 60.7071 20.8762 61.9259 21.7362 62.2563L35.4365 67.5194ZM35.4365 67.5194L52.7192 49.5203" stroke={ap_userPrimaryColor} strokeWidth="2" strokeLinejoin="round" />
            <path d="M43.1202 80.9091L52.3573 52.6421C52.6875 51.6317 51.4217 50.8721 50.6855 51.6389L35.897 67.0404C35.6183 67.3307 35.5407 67.7597 35.7001 68.1292L41.2515 80.9946C41.6164 81.8405 42.834 81.7847 43.1202 80.9091Z" fill={ap_userPrimaryColor} fillOpacity="0.4" stroke={ap_userPrimaryColor} strokeWidth="2" strokeLinejoin="round" />
            <circle cx="85.6962" cy="163.914" r="13.495" fill={ap_userSecondaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M92.8156 158.142C93.5193 158.761 93.5875 159.834 92.9677 160.538L85.0453 169.535C84.4256 170.238 83.3527 170.307 82.649 169.687C82.5629 169.611 82.4864 169.529 82.4195 169.441C82.3917 169.413 82.3647 169.385 82.3385 169.355L78.4237 164.909C77.8039 164.206 77.8721 163.133 78.5758 162.513C79.2796 161.893 80.3525 161.961 80.9722 162.665L83.7711 165.843L87.5738 161.525L90.4192 158.294C91.0389 157.59 92.1118 157.522 92.8156 158.142Z" fill="white" />
            <rect x="156.869" y="47.7581" width="83.5795" height="67.0089" rx="7" transform="rotate(90 156.869 47.7581)" fill="#CFDCE9" stroke={ap_userPrimaryColor} strokeWidth="6" />
            <path d="M78.507 120.174H120.825C125.731 120.009 129.666 116.923 129.666 113.179V89.0658C129.666 85.3213 125.731 82.2351 120.825 82.0705H106.746C105.133 82.0705 103.61 81.2922 102.672 79.9803L102.183 79.2979C101.231 77.9672 99.6879 77.1867 98.0517 77.2081L74.6006 77.515C71.8649 77.5508 69.666 79.7786 69.666 82.5145V89.0658V113.179C69.666 116.923 73.6013 120.009 78.507 120.174Z" fill="white" stroke={ap_userPrimaryColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="15.4629" y="116.201" width="33.2485" height="33.2486" rx="1" fill="#EDCB00" stroke={ap_userPrimaryColor} strokeWidth="2" />
            <path d="M14.7598 116.124H49.4147C49.967 116.124 50.4147 116.571 50.4147 117.124V122.898C50.4147 123.451 49.967 123.898 49.4147 123.898H14.7598C14.2075 123.898 13.7598 123.451 13.7598 122.898V117.124C13.7598 116.571 14.2075 116.124 14.7598 116.124Z" fill="#EDCB00" stroke={ap_userPrimaryColor} strokeWidth="2" />
            <rect x="24.0098" y="128.935" width="16.1545" height="2.48334" rx="1.24167" fill={ap_userPrimaryColor} />
            <defs>
                <linearGradient id="paint0_linear_5708_26757" x1="97.4045" y1="104.602" x2="136.988" y2="157.181" gradientUnits="userSpaceOnUse">
                    <stop stopColor={userSecondaryHoverColor} />
                    <stop offset="0.9999" stopColor="#4ECBD7" stopOpacity="0" />
                    <stop offset="1" stopColor="#EDCB00" stopOpacity="0" />
                    <stop offset="1" stopColor={userSecondaryHoverColor} stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_5708_26757" x1="34.2218" y1="57.3635" x2="87.1528" y2="127.671" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4ECBD7" />
                    <stop offset="0.9999" stopColor="#4ECBD7" stopOpacity="0" />
                    <stop offset="1" stopColor="#EDCB00" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_5708_26757" x1="57.4415" y1="3.51318" x2="125.83" y2="94.3524" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDCB00" />
                    <stop offset="1" stopColor="#EDCB00" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default function TransferAssetIcon(props) {
    return <Icon component={TransferAsset} {...props} />
}