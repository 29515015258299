import { useState } from "react";
import HomeContent from "../../components/HomeContent";
import ApprovalsList from "../../layouts/ApprovalsList";

import styles from "./index.module.css";
import { Tabs } from "antd";
import { useUpdateActiveTab } from "../../utils/UpdateActiveTab";

export default function Approvals({ agentId }) {

    const [tab, setTab] = useState("approved");

    const tabItems = [
        {
            key: 'creditAvailable',
            label: 'Credit Available',
            children: <ApprovalsList entity="agent" entityId={agentId} status="Approved" tab={tab} />
        },
        {
            key: 'cancelled',
            label: 'Cancelled',
            children: <ApprovalsList entity="agent" entityId={agentId} status="Cancelled" tab={tab} />
        },
        {
            key: 'expired',
            label: 'Expired',
            children: <ApprovalsList entity="agent" entityId={agentId} status="Expired" tab={tab} />
        }
    ];

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(tab, setTab, tabItems, "creditAvailable");

    const content = (
        <div className={styles["Container"]}>
            <Tabs
                activeKey={tab}
                defaultActiveKey={tabItems[0].key}
                items={tabItems}
                onChange={(key) => setTab(key)}
            />
        </div>
    );

    return (
        <HomeContent
            contentHeader='Approvals'
            content={content}
            bgColor="transparent"
        />
    );
}
