import Icon from '@ant-design/icons';
import { theme } from 'antd';

const Email = (color, width, height) => {

    const { token: { ap_userPrimaryColor } } = theme.useToken();
    const themeColor = color || ap_userPrimaryColor;
    const iconWidth = width || 26;
    const iconHeight = height || 23;

    return (
        <svg width={iconWidth} height={iconHeight} viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.77289 22.8926C2.08539 22.8926 1.49664 22.6476 1.00664 22.1576C0.51664 21.6676 0.272057 21.0792 0.27289 20.3926V5.39258C0.27289 4.70508 0.51789 4.11633 1.00789 3.62633C1.49789 3.13633 2.08622 2.89174 2.77289 2.89258H15.3979C15.3146 3.30924 15.2729 3.72591 15.2729 4.14258C15.2729 4.55924 15.3146 4.97591 15.3979 5.39258H2.77289L12.7729 11.6426L17.3354 8.79883C17.6271 9.06966 17.945 9.30424 18.2891 9.50258C18.6333 9.70091 18.9925 9.87258 19.3666 10.0176L12.7729 14.1426L2.77289 7.89258V20.3926H22.7729V10.2676C23.2521 10.1634 23.7 10.0176 24.1166 9.83008C24.5333 9.64258 24.9187 9.41341 25.2729 9.14258V20.3926C25.2729 21.0801 25.0279 21.6688 24.5379 22.1588C24.0479 22.6488 23.4596 22.8934 22.7729 22.8926H2.77289ZM21.5229 7.89258C20.4812 7.89258 19.5958 7.52799 18.8666 6.79883C18.1375 6.06966 17.7729 5.18424 17.7729 4.14258C17.7729 3.10091 18.1375 2.21549 18.8666 1.48633C19.5958 0.757161 20.4812 0.392578 21.5229 0.392578C22.5646 0.392578 23.45 0.757161 24.1791 1.48633C24.9083 2.21549 25.2729 3.10091 25.2729 4.14258C25.2729 5.18424 24.9083 6.06966 24.1791 6.79883C23.45 7.52799 22.5646 7.89258 21.5229 7.89258Z" fill={themeColor} />
        </svg>
    );
}

export default function EmailIcon({ color, width, height, ...props }) {
    return <Icon component={() => Email(color, width, height)} {...props} />
}