import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";

// Individual Notifications
import Notifications from "../../layouts/Notifications";

// Resource Not Found
import Error404 from "../../layouts/Error404";

import ApiService from "../../api";

import styles from "./index.module.css";

export default function IndividualNotification() {

    const { ap_notificationId } = useParams();

    const [loading, setLoading] = useState(true);
    const [responseCode, setResponseCode] = useState(null);
    const [notificationData, setNotificationData] = useState({});

    useEffect(() => {
        ApiService.fetchIndividualNotification(ap_notificationId).then(notifResp => {
            if (notifResp) {
                // Fetch and mark notification as read
                ApiService.updateNotification(ap_notificationId, { ap_isRead: true }).then(updateResponse => {
                    setResponseCode(updateResponse.code);
                    if (updateResponse.code === 200 && updateResponse.data)
                        setNotificationData(updateResponse?.data);
                    setLoading(false);
                })
            } else {
                setResponseCode(404);
                setLoading(false);
            }
        });
    }, [ap_notificationId]);

    const renderNotificationContent = () => {
        if (responseCode === 404)
            return <div className={styles["Error404Container"]}>
                <Error404 />
            </div>
        switch (notificationData.ap_notificationType) {
            case "newApproval":
                return <Notifications.SubmissionApproved notificationData={notificationData} />;
            case "approvalAmendment":
                return <Notifications.SubmissionAmendment notificationData={notificationData} />;
            case "approvalMaintained":
                return <Notifications.ApprovalMaintained notificationData={notificationData} />;
            case "expiryApproaching":
                return <Notifications.ApproachingExpiry notificationData={notificationData} />;
            case "creditExpiredMaintained":
                return <Notifications.CreditExpiredMaintained notificationData={notificationData} />;
            case "creditExpired":
                return <Notifications.CreditExpired notificationData={notificationData} />
            case "creditExtended":
                return <Notifications.CreditExtended notificationData={notificationData} />
            case "dealFunded":
                return <Notifications.DealFunded notificationData={notificationData} />
            case "newRequirements":
                return <Notifications.NewRequirements notificationData={notificationData} />
            case "vendorFunded":
                return <Notifications.VendorFunded notificationData={notificationData} />
            case "newPps":
                return <Notifications.NewPps notificationData={notificationData} />
            case "assetDeclined":
                return <Notifications.AssetDeclined notificationData={notificationData} />
            case "synergyFunded":
                return <Notifications.SynergyFunded notificationData={notificationData} />
            case "synergyCancelled":
                return <Notifications.SynergyCancelled notificationData={notificationData} />
            case "ownershipRequest":
                return <Notifications.OwnershipRequest notificationData={notificationData} />
            case "signingAuditing":
                return <Notifications.SigningAuditing notificationData={notificationData} />
            default:
                return <div className={styles["DefautMessage"]}>
                    There was a problem displaying this notification.
                </div>
        }
    };

    return (
        <Spin spinning={loading} tip="Loading..." size="large">
            <HomeContent
                contentHeader={
                    <div className={styles["Header"]}>
                        <GoBackButton />
                        Notifications
                    </div>
                }
                content={renderNotificationContent()}
                bgColor="transparent"
                fullScreen={true}
            />
        </Spin>
    );
}