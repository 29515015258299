import DarkIcon from "../../assets/images/icons/DarkIcon";
import LightIcon from "../../assets/images/icons/LightIcon";
import ThemeSampleIcon from "../../assets/images/icons/ThemeSampleIcon";
import constants from "../../constants";
import styles from "./index.module.css";

export default function ThemePreference({ theme, setTheme }) {

    const themeTypes = constants.THEMES;
    // const themeTypes = constants.NEW_THEMES;

    const currentTheme = theme?.token?.ap_theme;

    const selectTheme = (selectedTheme) => {
        setTheme(prevTheme => {
            return {
                token: {
                    ...prevTheme.token,
                    ap_theme: selectedTheme,
                    themePrimaryColor: themeTypes[selectedTheme]?.themePrimaryColor,
                    themeSecondaryColor: themeTypes[selectedTheme]?.themeSecondaryColor,
                    themeDangerColor: themeTypes[selectedTheme]?.themeDangerColor,
                    themeDisabledContainer: themeTypes[selectedTheme]?.themeDisabledContainer,
                    themeFontColor: themeTypes[selectedTheme]?.themeFontColor,
                    colorBgElevated: themeTypes[selectedTheme]?.themePrimaryColor,
                    colorBgLayout: themeTypes[selectedTheme]?.themeSecondaryColor,
                    colorTextDescription: themeTypes[selectedTheme]?.themeFontColor,
                    colorTextHeading: themeTypes[selectedTheme]?.themeFontColor,
                    colorTextLabel: themeTypes[selectedTheme]?.themeFontColor,
                    colorFillAlter: themeTypes[selectedTheme]?.themeSecondaryColor,
                    borderColor: themeTypes[selectedTheme]?.themeSecondaryColor,
                    footerBg: themeTypes[selectedTheme]?.themePrimaryColor,
                    bodySortBg: themeTypes[selectedTheme]?.themePrimaryColor,
                    colorText: themeTypes[selectedTheme]?.themeFontColor,
                    colorIcon: themeTypes[selectedTheme]?.themeFontColor,
                }
            }
        })
    }

    return (
        <div className={styles["ThemePreference_Container"]}>
            {Object.keys(themeTypes).map((themeItem) => {
                const themeItemValue = themeTypes[themeItem]?.value;
                const isSelected = themeItemValue?.toLowerCase() === currentTheme?.toLowerCase();

                const themeIcon = themeItemValue?.toLowerCase() === 'light' ? <LightIcon /> : <DarkIcon />;
                return (
                    <div
                        className={isSelected ? styles["OuterContainer_Selected"] : styles["OuterContainer"]}
                        onClick={() => selectTheme(themeItem)}
                    >
                        <div className={styles["InnerContainer"]}>
                            <div className={styles["InnerContainer_Header"]}>{themeIcon} {themeItemValue} Theme</div>
                            <div className={styles["InnerContainer_Content"]}>
                                <div>This theme will be activated when your system is set to {themeItemValue?.toLowerCase()} mode</div>
                                <ThemeSampleIcon colors={themeTypes[themeItem]} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}