// Importing necessary dependencies
import { useState } from "react";
import { Button, ConfigProvider, Input, Layout, Radio, Spin, theme } from "antd";

// Importing Utility functions, Validation, and Components
import utils from "../../utils";
import validate from "../../utils/Validations";
import HasError from "../../utils/HasErrors";

import InputLayout from "../InputLayout";
import quickQuoteConstants from "./constants";

// Import API and Notification Services
import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

// Importing CSS Styles
import styles from "./index.module.css";

// Define the main QuickQuoteCalculator component
export default function QuickQuoteCalculator() {

    // Extract background color from the theme
    const { token: { themePrimaryColor, themeFontColor, ap_userPrimaryColor, ap_userHighlightColor, colorBgContainerDisabled } } = theme.useToken();

    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
        colorBgContainerDisabled: colorBgContainerDisabled
    }

    // Initialize state variables
    const [loading, setLoading] = useState(false);
    const [invokeValidation, setInvokeValidation] = useState(false);
    const [calcType, setCalcType] = useState("paymentAmount");
    const [calculatedValue, setCalculatedValue] = useState('');
    const [formFields, setFormFields] = useState(quickQuoteConstants.generateFormFields());

    // Handler for input focus
    const focusHandler = (field) => {
        if (!field) return
        const value = formFields[field]?.value

        const data = { ...formFields }
        data[field] = {
            ...formFields[field],
            value: value ? utils.TransformCurrencyToNumber(value) : null,
        }
        setFormFields(data)
    }

    // Handler for input blur
    const blurHandler = (field) => {
        if (!field) return
        const value = formFields[field]?.value

        const data = { ...formFields }
        data[field] = {
            ...formFields[field],
            value: value || value === 0 ? utils.FormatCurrency(value) : '',
        }
        setFormFields(data);
    }

    // Event Handlers for input changes
    const handleLeaseAmount = (e) => {
        const input = e.target.value;
        const value = validate.ValidateCurrency(e.target.value).value;
        const error = validate.ValidateCurrency(e.target.value).error;

        setFormFields({
            ...formFields,
            ap_leaseAmount: {
                ...formFields.ap_leaseAmount,
                value: value,
                error: input && invokeValidation ? error : null
            }
        })
        setCalculatedValue('');
    }

    const handleDownPaymentAmount = (e) => {
        const input = e.target.value;
        const min = 0;
        const max = utils.TransformCurrencyToNumber(formFields.ap_leaseAmount.value);

        const value = validate.CheckCurrency(input).value;
        const error = validate.CheckCurrency(input).error || utils.isBetweenValue(min, Number(input), max);
        setFormFields({
            ...formFields,
            ap_downPaymentAmount: {
                ...formFields.ap_downPaymentAmount,
                value: value,
                error: input && invokeValidation ? error : null
            }
        });
        setCalculatedValue('');
    }

    const handleNumberOfPayments = (e) => {
        const input = Number(e.target.value);
        const min = 12;
        const max = 72;

        setFormFields({
            ...formFields,
            ap_numberOfPayments: {
                ...formFields.ap_numberOfPayments,
                value: input,
                error: invokeValidation ? utils.isBetweenValue(min, Number(input), max) : null,
            }
        });
        setCalculatedValue('');
    }

    const handleResidualAmount = (e) => {

        const input = e.target.value;
        const min = 0;
        const max = utils.TransformCurrencyToNumber(formFields.ap_leaseAmount.value) - utils.TransformCurrencyToNumber(formFields.ap_downPaymentAmount.value)

        const error = utils.isBetweenValue(min, Number(input), max);
        setFormFields({
            ...formFields,
            ap_residualAmount: {
                ...formFields.ap_residualAmount,
                value: input,
                error: input && invokeValidation ? error : null,
            }
        });
        setCalculatedValue('');
    }

    const handlePaymentAmount = (e) => {
        const input = e.target.value;
        const min = 0;
        const max = utils.TransformCurrencyToNumber(formFields.ap_paymentAmount.value);

        const value = validate.CheckCurrency(input).value;
        const error = validate.CheckCurrency(input).error;
        setFormFields({
            ...formFields,
            ap_paymentAmount: {
                ...formFields.ap_paymentAmount,
                value: value,
                error: input && invokeValidation ? error : null
            }
        });
        setCalculatedValue('');
    }

    const handleInterestRate = (e) => {
        const input = e.target.value;
        const error = (input && input < 0) ? "Incorrect Interest Rate" : "";

        setFormFields({
            ...formFields,
            ap_interestRate: {
                ...formFields.ap_interestRate,
                value: e.target.value,
                error: invokeValidation ? (validate.ValidateRequiredField(input).errorMessage || error) : null,
            }
        });
        setCalculatedValue('');
    }

    // Form Fields with Event Handlers
    const leaseAmount = {
        ap_leaseAmount: {
            ...formFields.ap_leaseAmount,
            eventHandler: handleLeaseAmount,
            onFocus: () => focusHandler("ap_leaseAmount"),
            onBlur: () => blurHandler("ap_leaseAmount")
        }
    }

    const downPaymentAmount = {
        ap_downPaymentAmount: {
            ...formFields.ap_downPaymentAmount,
            eventHandler: handleDownPaymentAmount,
            onFocus: () => focusHandler("ap_downPaymentAmount"),
            onBlur: () => blurHandler("ap_downPaymentAmount")
        }
    }

    const numberOfPayments = {
        ap_numberOfPayments: {
            ...formFields.ap_numberOfPayments,
            eventHandler: handleNumberOfPayments
        }
    }

    const residualAmount = {
        ap_residualAmount: {
            ...formFields.ap_residualAmount,
            eventHandler: handleResidualAmount,
            onFocus: () => focusHandler("ap_residualAmount"),
            onBlur: () => blurHandler("ap_residualAmount")
        }
    }

    const paymentAmount = {
        ap_paymentAmount: {
            ...formFields.ap_paymentAmount,
            value: (calcType !== "paymentAmount") ? formFields.ap_paymentAmount.value : '',
            eventHandler: handlePaymentAmount,
            onFocus: () => focusHandler("ap_paymentAmount"),
            onBlur: () => blurHandler("ap_paymentAmount")
        }
    }

    const interestRate = {
        ap_interestRate: {
            ...formFields.ap_interestRate,
            value: (calcType !== "interestRate") ? formFields.ap_interestRate.value : '',
            eventHandler: handleInterestRate
        }
    }

    // Function to perform calculations
    const calulate = () => {
        setLoading(true);
        setInvokeValidation(true);

        // Check for errors in form data
        const formData = quickQuoteConstants.CheckErrors(formFields, calcType);
        if (!HasError(formData)) {
            const data = quickQuoteConstants.generateCalculationData(calcType, formFields);

            // Fetch calculation from API for payment amount
            if (calcType === 'paymentAmount') {
                ApiService.fetchBalancedPPS(data).then(response => {
                    const regularPayment = response?.success?.calculatedValues?.regularPaymentAmount
                    if (typeof (regularPayment) !== 'number') {
                        setLoading(false);
                        NotificationService.error("Error", 'Cannot calculate at the moment, please try again later.')
                        return;
                    }
                    setCalculatedValue((calcType === 'paymentAmount') ? utils.FormatCurrency(regularPayment) : 'TBD');
                    setInvokeValidation(false);
                    setLoading(false);
                });
            } else {
                // Fetch calculation from API for interest rate
                ApiService.fetchBalancedPPS(data).then(response => {
                    const interestRate = response?.success?.calculatedValues?.annualClientRate
                    if (typeof (interestRate) !== 'number') {
                        setLoading(false);
                        NotificationService.error("Error", 'Cannot calculate at the moment, please try again later.')
                        return;
                    }
                    setCalculatedValue(interestRate.toFixed(2) + '%');
                    setInvokeValidation(false);
                    setLoading(false);
                });
            }
        } else setLoading(false);
        setFormFields(formData);
    }

    // Render the component
    return (
        <Layout style={themeColorVariables}>
            <Spin
                spinning={loading}
                tip={`Calculating ${quickQuoteConstants.findLabel(calcType)}...`}
                size="large"
            >
                <div className={styles["Container"]}>
                    {/* Output container */}
                    <div className={styles["InputContainer"]}>
                        <div className={styles["FlexCenter"]}><b>{quickQuoteConstants.findLabel(calcType)}</b></div>
                        <div id="qqcOutput" className={styles["FlexCenter"]}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorTextDisabled: themeFontColor,
                                        colorBgContainerDisabled: 'transparent'
                                    }
                                }}
                            >
                                <Input
                                    disabled
                                    value={calculatedValue}
                                    size="large"
                                    className={styles["CalculatedInput"]}
                                />
                            </ConfigProvider>

                        </div>
                        <Radio.Group
                            options={quickQuoteConstants.calcTypeOptions(calcType)}
                            value={calcType}
                            onChange={(e) => { setCalcType(e?.target?.value); setCalculatedValue('') }}
                            className={styles["FlexCenterMargin2"]}
                        />

                        {/* Input fields */}
                        <div className={styles["RowContent"]}>
                            <InputLayout data={leaseAmount} layout="vertical" />
                            <InputLayout data={downPaymentAmount} layout="vertical" />

                            <InputLayout data={numberOfPayments} layout="vertical" />
                            <>
                                {(calcType === 'paymentAmount') ?
                                    <InputLayout data={interestRate} layout="vertical" /> :
                                    <InputLayout data={paymentAmount} layout="vertical" />
                                }
                            </>
                            <InputLayout data={residualAmount} layout="vertical" />
                            <Button
                                type="primary"
                                onClick={calulate}
                                className={styles["CalculateButton"]}
                            >Calculate</Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Layout>
    )
}