import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Approval = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4138 14.3708C9.82075 13.7924 8.87107 13.8042 8.29262 14.3972C7.71417 14.9902 7.72598 15.9399 8.31901 16.5184L10.4138 14.3708ZM13.3143 19.2955L12.2669 20.3693C12.8552 20.943 13.7955 20.9367 14.376 20.3552L13.3143 19.2955ZM21.6953 13.0222C22.2805 12.4359 22.2796 11.4861 21.6933 10.9009C21.1069 10.3156 20.1572 10.3165 19.572 10.9029L21.6953 13.0222ZM26.5 15C26.5 21.3513 21.3513 26.5 15 26.5V29.5C23.0081 29.5 29.5 23.0081 29.5 15H26.5ZM15 26.5C8.64873 26.5 3.5 21.3513 3.5 15H0.5C0.5 23.0081 6.99187 29.5 15 29.5V26.5ZM3.5 15C3.5 8.64873 8.64873 3.5 15 3.5V0.5C6.99187 0.5 0.5 6.99187 0.5 15H3.5ZM15 3.5C21.3513 3.5 26.5 8.64873 26.5 15H29.5C29.5 6.99187 23.0081 0.5 15 0.5V3.5ZM8.31901 16.5184L12.2669 20.3693L14.3617 18.2217L10.4138 14.3708L8.31901 16.5184ZM14.376 20.3552L21.6953 13.0222L19.572 10.9029L12.2527 18.2358L14.376 20.3552Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function ApprovalIcon(props) {
    return <Icon component={Approval} {...props} />
}