import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";

import BeaconPagination from "../../components/BeaconPagination";
import ApiService from '../../api';
import generateClientTableColumns from "./constants";

import styles from "./index.module.css";

export default function ClientList({ agentId, refresh, setRefresh }) {
    const { token: { themeFontColor, themePrimaryColor, userHighlightColor }, } = theme.useToken();

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [clientData, setClientData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // Filter Fields
    const [filters, setFilters] = useState({});

    const [customSort, setCustomSort] = useState({
        ap_businessName: { order: null, type: 'string' },
        ap_industry: { order: null, type: 'string' },
        ap_type: { order: null, type: 'string' },
    });

    // Fetch Client List
    useEffect(() => {
        setLoading(true);
        ApiService.fetchAgentClientList(agentId).then((data) => {
            if (!data) {
                setLoading(false);
                return;
            }
            setClientData(data);
            setLoading(false);
        });
    }, [refresh]);

    const { pagedData, paginationComponent } = BeaconPagination(clientData, ClientList.name, true, true, searchedColumn, searchText, filters, customSort);

    return (
        <div className={styles["Container"]}>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50',
                    },
                }}
            >
                <Table
                    id={ClientList.name}
                    columns={generateClientTableColumns(clientData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, setFilters, customSort, setCustomSort)}
                    dataSource={pagedData}
                    loading={loading}
                    scroll={{ x: false, y: 475 }}
                    // scroll={{ x: false, y: '54vh' }}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate(`/clients/${record._id}`);
                        },
                        className: 'clickable-row'
                    })}
                    footer={() => paginationComponent}
                />
            </ConfigProvider>
        </div>
    );
}