import { Tooltip } from "antd";
import LensIcon from '@mui/icons-material/Lens';
import constants from "../constants";

const totalStages = constants.STAGE_PROPERTIES.length;

export default function VisualizeDrawdownStage(record, themeFontColor) {

    const responsibleActionRequired = (record?.responsibleType === 'Agent' || record?.responsibleType === 'Client');

    let numProcess;
    for (let i = 0; i < constants.STAGE_PROPERTIES.length; i++) {
        if (record?.[constants.STAGE_PROPERTIES[i]]) {
            numProcess = constants.STAGE_PROPERTIES.length - i;
            break;
        }
    }

    let process = [];
    let counter = 1;

    // Loop to generate icons for each process stage
    for (let j = 1; j <= numProcess; j++) {

        if (j === numProcess && responsibleActionRequired) {
            process.push(
                <Tooltip title={constants.PROCESS_STAGE_NAMES[counter]}>
                    <LensIcon style={{ color: '#FF5555', fontSize: 'medium' }} />
                </Tooltip>
            );
            counter++;
        } else {
            process.push(
                <Tooltip title={constants.PROCESS_STAGE_NAMES[counter]}>
                    <LensIcon style={{ color: "#4ECBD7", fontSize: 'medium' }} />
                </Tooltip>
            );
            counter++;
        }
    }

    // Add grey icons for remaining stages
    for (let k = 1; k <= (totalStages - numProcess); k++) {
        process.push(
            <Tooltip title={constants.PROCESS_STAGE_NAMES[counter]}>
                <LensIcon style={{ color: themeFontColor, fontSize: 'medium' }} />
            </Tooltip>
        )
        counter++;
    }

    return {
        bpf: process,
        processStage: constants.PROCESS_STAGE_NAMES[numProcess]
    };
}