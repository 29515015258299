export default [
    {
        label: 'First',
        value: 'First',
    },
    {
        label: 'First and Last',
        value: 'First and Last',
    },
    {
        label: '5%',
        value: '5%',
    },
    {
        label: '10%',
        value: '10%',
    },
    {
        label: '15%',
        value: '15%',
    },
    {
        label: '20%',
        value: '20%',
    },
    {
        label: 'Other',
        value: 'Other',
    },
    {
        label: 'None',
        value: 'None',
    },
]