import Icon from '@ant-design/icons';
import { theme } from 'antd';

const WhatsApp = (color, width, height) => {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    const themeColor = color || ap_userPrimaryColor;
    const iconWidth = width || 25;
    const iconHeight = height || 35;

    return (
        <svg width={iconWidth} height={iconHeight} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.496 22.499L12.5 22.499C18.023 22.499 22.5 18.022 22.5 12.499C22.5 6.97598 18.023 2.49902 12.5 2.49902C6.97698 2.49902 2.50002 6.97598 2.50002 12.499V12.5032C2.49707 14.2691 2.96456 16.0039 3.85441 17.5292L4.38245 18.4344L3.56555 21.4361L6.56599 20.6174L7.47179 21.1456C8.99668 22.0347 10.7308 22.5019 12.496 22.499ZM0.00501758 24.999L1.69502 18.789C0.581383 16.8801 -0.00367297 14.709 1.73503e-05 12.499C1.73503e-05 5.59527 5.59626 -0.000976562 12.5 -0.000976562C19.4038 -0.000976562 25 5.59527 25 12.499C25 19.4028 19.4038 24.999 12.5 24.999C10.291 25.0026 8.12083 24.418 6.21251 23.3053L0.00501758 24.999Z" fill={themeColor} />
            <path d="M8.24005 6.62402L7.99005 6.63402C7.8282 6.64388 7.67002 6.6864 7.52505 6.75902C7.38945 6.83581 7.26567 6.9318 7.15755 7.04402C7.00755 7.18527 6.92255 7.30777 6.8313 7.42652C6.36895 8.02765 6.12002 8.76566 6.1238 9.52402C6.1263 10.1365 6.2863 10.7328 6.5363 11.2903C7.04755 12.4178 7.8888 13.6115 8.9988 14.7178C9.2663 14.984 9.5288 15.2515 9.8113 15.5003C11.1905 16.7146 12.8341 17.5903 14.6113 18.0578L15.3213 18.1665C15.5525 18.179 15.7838 18.1615 16.0163 18.1503C16.3803 18.1315 16.7358 18.0329 17.0575 17.8615C17.2212 17.7772 17.381 17.6854 17.5363 17.5865C17.5363 17.5865 17.59 17.5515 17.6925 17.474C17.8613 17.349 17.965 17.2603 18.105 17.114C18.2088 17.0065 18.2988 16.8803 18.3675 16.7365C18.465 16.5328 18.5625 16.144 18.6025 15.8203C18.6325 15.5728 18.6238 15.4378 18.62 15.354C18.615 15.2203 18.5038 15.0815 18.3825 15.0228L17.655 14.6965C17.655 14.6965 16.5675 14.2228 15.9025 13.9203C15.833 13.8899 15.7584 13.8725 15.6825 13.869C15.597 13.8602 15.5106 13.8699 15.4291 13.8972C15.3476 13.9246 15.2729 13.9691 15.21 14.0278C15.2038 14.0253 15.12 14.0965 14.2163 15.1915C14.1644 15.2612 14.093 15.3139 14.011 15.3428C13.9291 15.3718 13.8404 15.3757 13.7563 15.354C13.6749 15.3322 13.5951 15.3046 13.5175 15.2715C13.3625 15.2065 13.3088 15.1815 13.2025 15.1365C12.4851 14.8234 11.8209 14.4005 11.2338 13.8828C11.0763 13.7453 10.93 13.5953 10.78 13.4503C10.2883 12.9793 9.8597 12.4465 9.50505 11.8653L9.4313 11.7465C9.37833 11.6667 9.3355 11.5806 9.3038 11.4903C9.2563 11.3065 9.38005 11.159 9.38005 11.159C9.38005 11.159 9.6838 10.8265 9.82505 10.6465C9.96255 10.4715 10.0788 10.3015 10.1538 10.1803C10.3013 9.94277 10.3475 9.69902 10.27 9.51027C9.92005 8.65527 9.55755 7.80402 9.18505 6.95902C9.1113 6.79152 8.89255 6.67152 8.6938 6.64777C8.6263 6.64027 8.5588 6.63277 8.4913 6.62777C8.40718 6.62359 8.32298 6.62193 8.2388 6.62277L8.24005 6.62402Z" fill={themeColor} />
        </svg>
    );
}

export default function WhatsAppIcon({ color, width, height, ...props }) {
    return <Icon component={() => WhatsApp(color, width, height)} {...props} />
}