import utils from "../../../utils";

const SynergyCancelledAssetTableColumns = [
    {
      title: 'Contract #',
      dataIndex: 'synergyContractNumber',
      key: 'synergyContractNumber',
      width: '10%',
      ellipsis: true,
      render(text, record, index) {
          return {
            props: {
              style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
            },
            children: <div>{text}</div>
          };
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'ap_vendor',
      key: 'ap_vendor',
      width: '20%',
      ellipsis: true,
      render(text, record, index) {
          return {
            props: {
              style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
            },
            children: <div>{text}</div>
          };
      }
    },
    {
        title: 'VIN',
        dataIndex: 'ap_vin',
        key: 'ap_vin',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
              props: {
                style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
              },
              children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Model',
        dataIndex: 'ap_model',
        key: 'ap_model',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
              props: {
                style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
              },
              children: <div>{text}</div>
            };
        }
    },
    {
      title: 'Make',
      dataIndex: 'ap_make',
      key: 'ap_make',
      width: '20%',
      ellipsis: true,
      render(text, record, index) {
          return {
            props: {
              style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
            },
            children: <div>{text}</div>
          };
      }
    },
    {
      title: 'Year',
      dataIndex: 'ap_year',
      key: 'ap_year',
      width: '10%',
      ellipsis: true,
      render(text, record, index) {
          return {
            props: {
              style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
            },
            children: <div>{text}</div>
          };
      }
    },
    {
      title: 'Fee',
      dataIndex: 'ap_synergyAgentFee',
      key: 'ap_synergyAgentFee',
      width: '10%',
      ellipsis: true,
      render(text, record, index) {
          return {
            props: {
              style: { background: index % 2 === 0 ? "var(--themePrimaryColor)": "rgba(207, 220, 233, 0.5)", color: "var(--themeFontColor)"}
            },
            children: <div>{utils.TransformDBCurrency(record?.ap_synergyAgentFee)}</div>
          };
      }
    }
];

const generateDetails = (notificationData) => {
  const assetDetails = {
    title: "Drawdown Details",
    data: [
      {
        "label": "Lender",
        "value": notificationData.lenderName
      },
      {
        "label": "Admin",
        "value": notificationData.adminAnalyst
      }
    ]
  };

  const fundingDetails = {
    title: "Cancellation Details",
    data: [
      {
        "label": "Cancelled As Of",
        "value": utils.TransformNotificationDate(notificationData.cancelledAsOf)
      },
      {
        "label": "Total Synergy Fee Refunded",
        "value": utils.TransformDBCurrency(notificationData.totalSynergyAgentFeeRefunded)
      }
    ]
  };

  return {
    "assetDetails": assetDetails,
    "fundingDetails": fundingDetails
  };
}

export const constants = {
    "SynergyCancelledAssetTableColumns": SynergyCancelledAssetTableColumns,
    "generateDetails": generateDetails
}
