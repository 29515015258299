import { theme } from "antd";
import Icon from '@ant-design/icons';

function ProfileIcon() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.20654 28.0001C2.20654 28.0001 3.76244 22.7211 7.48548 22.7211H14.964H22.5144C26.2375 22.7211 27.7933 28.0001 27.7933 28.0001M22.1786 9.21462C22.1786 13.1991 18.9485 16.4292 14.964 16.4292C10.9795 16.4292 7.74947 13.1991 7.74947 9.21462C7.74947 5.23013 10.9795 2.00006 14.964 2.00006C18.9485 2.00006 22.1786 5.23013 22.1786 9.21462Z" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round"/></svg> 
    )
}

export default function ProfileListItem() {
    return (
        <>
                <Icon component={() => ProfileIcon()} />
        </>
    )
}