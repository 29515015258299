import DOMPurify from 'dompurify';
import { useState, useMemo } from 'react';

const sanitizeHTML = (_html, tags = [], attr = []) => {
    const ALLOWED_ATTR = [
        "style", "class", "type", "href", "rel", "colSpan", "alt", "src", "width"
    ];
    
    const ALLOWED_TAGS = [
        "div", "ul", "li", "b", "table", "tr", "td", "img", "figure", 
        "caption", "thead", "tbody", "th", "link", "style"
    ];
    

    attr.forEach(each_attr => {
        ALLOWED_TAGS.push(each_attr);
    });

    tags.forEach(each_tag => {
        ALLOWED_TAGS.push(each_tag);
    });

    return DOMPurify.sanitize(_html, {
        FORCE_BODY: true,
        ALLOWED_ATTR: ALLOWED_ATTR,
        ALLOWED_TAGS: ALLOWED_TAGS
    })

}


// Custom Use State Hook For Sanitization
export const useSanitizedState = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);

    const sanitizedValue = useMemo(() => {
        return sanitizeHTML(value);
    }, [value]);

    return [sanitizedValue, setValue];
};


// Custom Use State Hook For Sanitization for Dict
export const useSanitizedDictState = (initialValues = {}) => {
    const [values, setValues] = useState(initialValues);

    const sanitizedValues = useMemo(() => {
        const sanitized = {};
        for (const key in values) {
            sanitized[key] = sanitizeHTML(values[key]);
        }
        return sanitized;
    }, [values]);

    return [sanitizedValues, setValues];
};



export default sanitizeHTML;
