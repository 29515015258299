// Import icons for different notification types
import AccordianIcons from "../../assets/images/icons/Notifications/AccordianIcons"

/**
 * Generates notification data based on type and data input.
 *
 * @param {string} type - The type of notification.
 * @param {object} data - Additional data (ap_data from notifications) for populating notification content.
 * @returns {object} - An object containing icon, headerText, and subText for the notification.
 */

// Generate notification details based on type and data input
function generateData(type, data) {

    switch (type) {
        case 'accessRequest': return {
            icon: <AccordianIcons.AccessRequest />,
            headerText: "Access Request Form Submitted",
            subText: <><b>{data?.clientBusinessName}</b> has submitted an access request form.</>,
        }
        case 'newRequirements': return {
            icon: <AccordianIcons.ActionRequired />,
            headerText: "Action Required",
            subText: <>Documents are required for <b>{data?.clientBusinessName}</b> to move forward.</>,
        }
        case 'approvalMaintained': return {
            icon: <AccordianIcons.ApprovalMaintained />,
            headerText: "Approval Maintained",
            subText: <><b>{(data?.lenderAbbreviation || data?.lenderName)}</b> has declined your amendment request and has maintained your previous approval.</>,
        }
        case 'approvalAmendment': return {
            icon: <AccordianIcons.ApprovalUpdated />,
            headerText: "Approval Updated",
            subText: <>Approval updated for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'concessionRequest': return {
            icon: <AccordianIcons.ConcessionRequest />,
            headerText: "Concession Request",
            subText: <>Concession request for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'concessionRequestDeclined': return {
            icon: <AccordianIcons.ConcessionRequestDeclined />,
            headerText: "Concession Request Declined",
            subText: <>Concession request declined for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'concessionRequestApproved': return {
            icon: <AccordianIcons.ConcessionRequestApproved />,
            headerText: "Concession Request Approved",
            subText: <>Concession request approved for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'creditExpired': return {
            icon: <AccordianIcons.CreditExpired />,
            headerText: "Credit Expired",
            subText: <>Credit has expired for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'creditExpiredMaintained': return {
            icon: <AccordianIcons.ApprovalMaintained />,
            headerText: "Credit Expired Maintained",
            subText: <><b>{(data?.lenderAbbreviation || data?.lenderName)}</b> has declined your credit extension request.</>,
        }

        case 'creditExtended': return {
            icon: <AccordianIcons.CreditExtended />,
            headerText: "Credit Extended",
            subText: <>Credit availability has been extended for <b>{data?.clientBusinessName} - {(data?.lenderAbbreviation || data?.lenderName)}.</b></>,
        }
        case 'dealFunded': return {
            icon: <AccordianIcons.DealFunded />,
            headerText: "Deal Funded",
            subText: <>Deal for <b>{data?.clientName} - {(data?.lenderAbbreviation || data?.lenderName)}</b> has been fully funded.</>,
        }
        case 'fundingInitiated': return {
            icon: <AccordianIcons.FundingInitiated />,
            headerText: "Funding Initiated",
            subText: <>Payment Schedule for <b>{data?.clientBusinessName || data?.clientName} - {(data?.lenderAbbreviation || data?.lenderName)}</b> has been initiated.</>,
        }
        case 'monthlyInvoice': return {
            icon: <AccordianIcons.MonthlyInvoice />,
            headerText: "Monthly Invoice",
            subText: <>Monthly invoice for <b>{data?.clientBusinessName || data?.clientName}.</b></>,
        }
        case 'newApplication': return {
            icon: <AccordianIcons.NewApplication />,
            headerText: "New Application Submitted",
            subText: <>A new application has been sent by <b>{data?.agentBusinessName || data?.agentName}.</b></>,
        }
        case 'newApproval': return {
            icon: <AccordianIcons.SubmissionApproved />,
            headerText: "Submission Approved",
            subText: <>The credit submission for <b>{data?.clientBusinessName || data?.clientName} - {(data?.lenderAbbreviation || data?.lenderName)}</b> has been approved.</>,
        }
        case 'synergyFunded': return {
            icon: <AccordianIcons.SynergyFunded />,
            headerText: "Synergy GAP Funded",
            subText: <>The asset(s) for <b>{data?.clientBusinessName || data?.clientName} - {(data?.lenderAbbreviation || data?.lenderName)}</b> has successfully been added to the Synergy GAP Program.</>,
        }
        case 'synergyCancelled': return {
            icon: <AccordianIcons.SynergyCancelled />,
            headerText: "Synergy GAP Cancelled",
            subText: <>Some or all asset(s) for <b>{data?.clientBusinessName || data?.clientName} - {(data?.lenderAbbreviation || data?.lenderName)}</b> have been cancelled from the Synergy GAP Program.</>,
        }
        case 'vendorFunded': return {
            icon: <AccordianIcons.VendorFunded />,
            headerText: "Vendor Funded",
            subText: <>The vendor is funded for <b>{data?.ap_clientBusinessName || data?.ap_clientName} - {(data?.lenderAbbreviation || data?.lenderName || "Lender")}.</b></>,
        }
        case 'weeklyReport': return {
            icon: <AccordianIcons.WeeklyReport />,
            headerText: "Weekly Report",
            subText: <>Your weekly report from <b>Lantern Capital</b> is ready.</>,
        }
        case 'assetDeclined': return {
            icon: <AccordianIcons.AssetDeclined />,
            headerText: "Asset Declined",
            subText: <>Unable to secure proposed payment schedule from your request. See current payment schedule for <b>{data?.clientBusinessName || data?.clientName}.</b></>,
        }
        case 'newPps': return {
            icon: <AccordianIcons.NewPps />,
            headerText: "New Proposed Payment Schedule",
            subText: <>Review new proposed payment schedule(s) for <b>{data?.clientBusinessName} - {data?.lenderName}.</b></>,
        }
        case 'expiryApproaching': return {
            icon: <AccordianIcons.ApproachingExpiry />,
            headerText: "Approaching Expiry",
            subText: <>Credit availability for <b>{data?.clientName} - {data?.lenderAbbreviation || data?.lenderName}</b> is approaching expiry.</>
        }
        case 'ownershipRequest': return {
            icon: <AccordianIcons.OwnershipRequest />,
            headerText: "Ownership Request",
            subText: <>Please see the attached documents for plating purposes and send us the ownership for <b>{data?.ap_assetDescription}</b>.</>,
        }
        case 'signingAuditing': return {
            icon: <AccordianIcons.SigningAuditing />,
            headerText: "Sign Lease Documents",
            subText: <>Please ensure that the client signs the lease documents.</>,
        }

        default: return {
            icon: <AccordianIcons.NewApplication />,
            headerText: "Notification",
            subText: <>You have new Notification.</>,
        }
    }
}

// Object that holds the generateData function
const notificationConstants = {
    generateData: generateData
};

export default notificationConstants;