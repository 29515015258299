export default function FormatPhoneNumber(obj) {

    // If Phone Number is Empty
    if (!obj.input) {
        return {
            input: obj.input, 
            error: "Required"
        };
    }
  
    const phoneNumber = obj.input.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    // Format Number
    if (phoneNumberLength < 4){

        return {
            input: phoneNumber, 
            error: "Enter a 10 digit number"
        };

    } else if (phoneNumberLength < 7) {
      
        return {
            input: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`, 
            error: "Enter a 10 digit number"
        };

    } else if(phoneNumberLength < 10){

        return {
            input: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`, 
            error: "Enter a 10 digit number"
        }

    } else {

        return {
            input: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`, 
            error: ""
        }

    }
}