import AccountIcon from "./Account";
import ApplicationIcon from "./Application";
import ApprovalIcon from "./Approval";
import BOSIcon from "./BillOfSale";
import CSRIcon from "./CreditSubmissionRequired";
import FinancialsIcon from "./Financials";
import IDsIcon from "./IDs";
import InspectionIcon from "./Inspection";
import InsuranceIcon from "./Insurance";
import LetterIcon from "./JobLetter";
import LicenseIcon from "./License";
import OtherIcon from "./Other";
import PictureIcon from "./AssetPictures";
import RegistrationIcon from "./Registration";
import RevisedIcon from "./RevisedDocs";
import SpecSheetIcon from "./SpecSheet";
import VoidIcon from "./VoidCheque";
import SignedIcon from "./SignedDocs";

export default function RequirementCategories(requirement) {
    switch (requirement?.requirement?.name) {
        case "Seller ID": return <IDsIcon />
        case "IDs": return <IDsIcon />
        case "Bill of Sale / Invoice": return <BOSIcon />
        case "Operations Summary": return <SpecSheetIcon />
        case "Spec Sheet": return <SpecSheetIcon />
        case "Signed BOS": return <BOSIcon />
        case "Signed Lease Docs": return <SignedIcon />
        case "Re-Signed Docs": return <RevisedIcon />
        case "Revised BOS": return <RevisedIcon />
        case "Credit Submission Required": return <CSRIcon />
        case "Borrower Void Cheque": return <VoidIcon />
        case "Seller Void Cheque": return <VoidIcon />
        case "Lantern Application": return <ApplicationIcon />
        case "Current Dated And Signed PNW": return <ApplicationIcon />
        case "Work Letter": return <LetterIcon />
        case "Job Letter": return <LetterIcon />
        case "Discharge Letter": return <LetterIcon />
        case "Buydown Letter": return <LetterIcon />
        case "Asset Pictures": return <PictureIcon />
        case "True Pic Audit": return <PictureIcon />
        case "Third-Party Inspection": return <InspectionIcon />
        case "Driver's License Copy": return <LicenseIcon />
        case "Plated Ownership": return <LicenseIcon />
        case "Insurance": return <InsuranceIcon />
        case "Insurance ICBC": return <InsuranceIcon />
        case "Pre-Funding": return <FinancialsIcon />
        case "Down Payment": return <FinancialsIcon />
        case "Financials": return <FinancialsIcon />
        case "Post-Funding": return <FinancialsIcon />
        case "Registration": return <RegistrationIcon />
        case "Registration/NVIS": return <RegistrationIcon />
        case "Articles Of Incorporation": return <RegistrationIcon />
        case "Waiver": return <RegistrationIcon />
        case "Corporate Guarantee": return <ApprovalIcon />
        case "Lease Amendment Agreement": return <ApprovalIcon />
        case "Vendor Approval": return <ApprovalIcon />
        case "Select GST#": return <AccountIcon />
        case "Accountant Verification": return <AccountIcon />
        default: return <OtherIcon />
    }
}