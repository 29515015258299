import constants from "../../constants";
import validate from "../../utils/Validations";

export default function generateContactFields(entity, data) {
    if (data)
        return {
            ap_firstName: {
                value: data.ap_firstName,
                error: validate.RequiredField(data.ap_firstName),
                hidden: false,
                required: true,
                disabled: false,
                inputType: 'text',
                label: 'First Name',
            },
            ap_lastName: {
                value: data.ap_lastName,
                error: validate.RequiredField(data.ap_lastName),
                hidden: false,
                required: true,
                disabled: false,
                inputType: 'text',
                label: 'Last Name',
            },
            ap_email: {
                value: data.ap_email,
                error: (validate.RequiredField(data.ap_email) ? validate.RequiredField(data.ap_email) : (!validate.EmailValidation(data.ap_email) ? 'Invalid Email Address' : '')),
                hidden: false,
                required: true,
                disabled: false,
                inputType: 'text',
                label: 'Email',
            },
            ap_telephone: {
                value: validate.FormatTelephone(String(data.ap_telephone)).input,
                error: validate.FormatTelephone(String(data.ap_telephone)).error,
                hidden: false,
                required: true,
                disabled: false,
                inputType: 'text',
                label: 'Telephone',
            },
            ap_address: {
                value: data.ap_address,
                error: '',
                hidden: false,
                required: false,
                disabled: false,
                inputType: 'text',
                label: 'Address',
            },
            ap_city: {
                value: data.ap_city,
                error: '',
                hidden: false,
                required: false,
                disabled: false,
                inputType: 'text',
                label: 'City',
            },
            ap_stateOrProvince: {
                value: data.ap_stateOrProvince,
                error: '',
                hidden: false,
                required: entity !== 'agent',
                disabled: false,
                inputType: 'dropdown-search',
                label: 'Province/State',
                dropdownContent: (data.ap_country === 'USA') ? constants.AMERICAN_STATES : constants.CANADIAN_PROVINCES,
            },
            ap_postalZipCode: {
                value: data.ap_postalZipCode,
                error: '',
                hidden: false,
                required: false,
                disabled: false,
                inputType: 'text',
                label: 'Postal/Zip Code',
            },
            ap_country: {
                value: data.ap_country || 'Canada',
                error: '',
                hidden: false,
                required: entity !== 'agent',
                disabled: false,
                inputType: 'dropdown-search',
                label: 'Country',
                dropdownContent: constants.COUNTRIES,
            },
            ...(entity === "client" && {
                ap_drivingExperienceYears: {
                    value: data?.ap_clientData.ap_drivingExperienceYears,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'number',
                    label: 'Driving Experience (Years)',
                },
            }),
            ...(entity === "client" && {
                ap_everBankrupt: {
                    value: data?.ap_clientData.ap_everBankrupt,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'switch',
                    label: 'Ever Bankrupt?',
                }
            }),
            ...(entity === "client" && {
                ap_everRepossessed: {
                    value: data.ap_clientData.ap_everRepossessed,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'switch',
                    label: 'Ever Repossessed?',
                }
            }),
            ...(entity === "client" && {
                ap_defendantInLegalAction: {
                    value: data.ap_clientData.ap_defendantInLegalAction,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'switch',
                    label: 'Defendant in a Legal Action?',
                }
            }),
            ...(entity === "client" && {
                ap_homeowner: {
                    value: data.ap_clientData.ap_homeowner,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'switch',
                    label: 'Home Owner?',
                }
            }),
            ...(entity === "client" && {
                ap_residentAtleast2Years: {
                    value: data.ap_clientData.ap_residentAtleast2Years,
                    error: '',
                    hidden: entity === 'agent',
                    required: false,
                    disabled: false,
                    inputType: 'switch',
                    label: 'Resident for 2 years?',
                }
            })
        }
    else return {}
}