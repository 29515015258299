import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "./index.module.css";

export default function SortColumn(customSort, setCustomSort, dataIndex) {

    const setSort = (sortOrder, field) => setCustomSort(prevSort => {
        const newSort = { ...prevSort };

        if (newSort?.[field]?.order !== sortOrder) {
            Object.keys(newSort).forEach(key => {
                if (key !== field)
                    newSort[key].order = null;
            });
            newSort[field].order = sortOrder;
        }

        return newSort;
    });

    return ({
        sortDirections: ['ascend', 'descend'],
        sortOrder: customSort[dataIndex]?.order,
        sortIcon: ({ sortOrder }) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpFilled className={(sortOrder === 'ascend') ? styles["ActiveCaret"] : styles["Caret"]} />
                <CaretDownFilled className={(sortOrder === 'descend') ? styles["ActiveCaret"] : styles["Caret"]} />
            </div>
        ),
        sorter: (a, b) => {
            if (customSort[dataIndex]?.type === 'date')
                return dayjs(a?.[dataIndex]).unix() - dayjs(b?.[dataIndex]).unix();
            return (a?.[dataIndex] || "").localeCompare(b?.[dataIndex] || "")
        },
        // explicitly set onClick event on the header cell to update sort order
        onHeaderCell: () => ({
            onClick: () => setSort(customSort[dataIndex]?.order === 'ascend' ? 'descend' : 'ascend', dataIndex),
        }),
    });
}