import utils from "../../../utils"

export default function generateDetails(notificationData) {
    const summaryDetails = {
        title: "Approval Details",
        data: [
            {
                label: "Approval Date",
                value: utils.TransformNotificationDate(notificationData.approvalDate)
            },
            {
                label: "Lender",
                value: notificationData.lenderName
            },
            {
                label: "Credit",
                value: notificationData.creditName
            }
        ]
    }
    return {
        summaryDetails: summaryDetails,
    }
}

const assetTableColumns = [
    {
        title: 'VIN',
        dataIndex: 'ap_vin',
        key: 'ap_vin',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Make',
        dataIndex: 'ap_make',
        key: 'ap_make',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Model',
        dataIndex: 'ap_model',
        key: 'ap_model',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Year',
        dataIndex: 'ap_year',
        key: 'ap_year',
        width: '10%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Price',
        dataIndex: 'ap_price',
        key: 'ap_price',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: 'Kilometers',
        dataIndex: 'ap_kms',
        key: 'ap_kms',
        width: '20%',
        ellipsis: true,
        render(text, record, index) {
            return {
                props: {
                    style: { background: index % 2 === 0 ? "var(--themePrimaryColor)" : "#D9D9D9", color: "var(--themeFontColor)" }
                },
                children: <div>{text}</div>
            };
        }
    },
];

export const constants = {
    "assetTableColumns": assetTableColumns
}