import validate from "../../utils/Validations";
import AddUserUtils from "./utils";

function generateAddUserFields(invokeValidation, newUserData, setNewUserData) {

    const emailAddress = newUserData?.emailAddress || "";
    const emailAddressError = () => {
        if (!invokeValidation)
            return "";
        return validate.ValidateRequiredField(emailAddress).errorMessage || (!validate.EmailValidation(emailAddress) ? "Please enter a valid email address" : "");
    }

    return ({
        emailAddress: {
            value: emailAddress,
            error: emailAddressError(),
            hidden: false,
            required: false,
            placeholder: "Enter email address to invite a user",
            inputType: "email",
            label: "",
            eventHandler: (e) => AddUserUtils.handleEmailAddress(e.target.value, "emailAddress", setNewUserData)
        }
    })
}

const AddUserConstants = {
    generateAddUserFields
}

export default AddUserConstants;