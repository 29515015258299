import { Breadcrumb, Layout, Spin, theme } from "antd";
import styles from './index.module.css';

const { Content } = Layout;

export default function HomeContent(props) {
    const { token: { contentBgContainer, themeFontColor }, } = theme.useToken();

    return (
        <Content
            className={styles['content-style']}
            style={{ '--themeFontColor': themeFontColor }}
        >

            <Breadcrumb className={styles['breadcrumb-style']} />

            <div
                style={{ background: props.bgColor || contentBgContainer }}
                className={!props.fullScreen ? styles["home-content"] : {}}
            >
                <div className={`${styles['content-header']} Font_SemiBold`}>{props.contentHeader}</div>

                {/* <Spin spinning={props?.loader?.loader} tip={props?.loader?.tip} size="large"> */}
                    <div className={(!props?.dashboard) ? styles["HomeLayout_Content"] : null}>{props.content}</div>
                {/* </Spin> */}

            </div>

        </Content>
    );
}