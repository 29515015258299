import { useEffect, useState } from "react";
import { Spin } from "antd";

import ErrorBoundary from "./components/ErrorBoundary";
import AppRoutes from './routes/';

import constants from "./constants";
import ApiService from "./api";
import AuthService from "./services/auth.service";

import './App.css';
import './assets/styles/fonts.css';
import utils from "./utils";

export default function App() {

    const isLoggedIn = AuthService.isLoggedIn();

    const agentId = isLoggedIn ? JSON.parse(localStorage?.getItem("user"))?.agent_id : null;

    const [theme, setTheme] = useState(constants.DEFAULT_THEME);
    const [loader, setLoader] = useState({ loading: false, tip: '' })

    useEffect(() => {
        if (!agentId)
            return;

        setLoader({ loading: true, tip: 'Loading theme...' });

        ApiService.fetchAgentProfileData(agentId).then((agentResponse) => {
            const settingsData = agentResponse?.ap_settingsId;

            const themeType = settingsData?.ap_theme || 'light';
            const themeDetails = constants.THEMES[themeType];

            const userSecondaryHoverColor = utils.DarkenHexColor(settingsData?.ap_userSecondaryColor || constants.DEFAULT_THEME.token.ap_userSecondaryColor);

            setTheme({
                '--userPrimaryColor': settingsData?.ap_userPrimaryColor || constants.DEFAULT_THEME.token.ap_userPrimaryColor,
                '--userHighlightColor': settingsData?.ap_userHighlightColor || constants.DEFAULT_THEME.token.ap_userHighlightColor,
                '--userSecondaryColor': themeDetails?.ap_userSecondaryColor || constants.DEFAULT_THEME.token.ap_userSecondaryColor,
                '--userSecondaryHoverColor': userSecondaryHoverColor,
                // '--userSecondaryHoverColor': themeDetails?.userSecondaryHoverColor,
                '--themePrimaryColor': themeDetails?.themePrimaryColor,
                '--themeSecondaryColor': themeDetails?.themeSecondaryColor,
                '--themeFontColor': themeDetails?.themeFontColor,
                '--themeDangerColor': themeDetails?.themeDangerColor,
            });

            setLoader({ loading: false, tip: '' });
        });

    }, [agentId]);

    return (
        <Spin spinning={loader?.loading} tip={loader?.tip}>
            <div style={theme}>
                <ErrorBoundary>
                    <div className="App">
                        <AppRoutes />
                    </div>
                </ErrorBoundary>
            </div>
        </Spin>
    );
}