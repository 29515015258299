import { theme } from 'antd';
import Icon from '@ant-design/icons';

const DeleteDustbin = () => {
    const { token: { themeDangerColor }, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 2.1C5.25 1.54305 5.47125 1.0089 5.86508 0.615076C6.2589 0.221249 6.79305 0 7.35 0H13.65C14.207 0 14.7411 0.221249 15.1349 0.615076C15.5287 1.0089 15.75 1.54305 15.75 2.1V4.2H19.95C20.2285 4.2 20.4955 4.31062 20.6925 4.50754C20.8894 4.70445 21 4.97152 21 5.25C21 5.52848 20.8894 5.79555 20.6925 5.99246C20.4955 6.18937 20.2285 6.3 19.95 6.3H18.8275L17.9172 19.0491C17.8795 19.5789 17.6424 20.0748 17.2537 20.4368C16.865 20.7987 16.3536 21 15.8224 21H5.1765C4.64535 21 4.13392 20.7987 3.74523 20.4368C3.35654 20.0748 3.11946 19.5789 3.08175 19.0491L2.1735 6.3H1.05C0.771523 6.3 0.504451 6.18937 0.307538 5.99246C0.110625 5.79555 0 5.52848 0 5.25C0 4.97152 0.110625 4.70445 0.307538 4.50754C0.504451 4.31062 0.771523 4.2 1.05 4.2H5.25V2.1ZM7.35 4.2H13.65V2.1H7.35V4.2ZM4.2777 6.3L5.17755 18.9H15.8235L16.7233 6.3H4.2777ZM8.4 8.4C8.67848 8.4 8.94555 8.51062 9.14246 8.70754C9.33937 8.90445 9.45 9.17152 9.45 9.45V15.75C9.45 16.0285 9.33937 16.2955 9.14246 16.4925C8.94555 16.6894 8.67848 16.8 8.4 16.8C8.12152 16.8 7.85445 16.6894 7.65754 16.4925C7.46062 16.2955 7.35 16.0285 7.35 15.75V9.45C7.35 9.17152 7.46062 8.90445 7.65754 8.70754C7.85445 8.51062 8.12152 8.4 8.4 8.4ZM12.6 8.4C12.8785 8.4 13.1455 8.51062 13.3425 8.70754C13.5394 8.90445 13.65 9.17152 13.65 9.45V15.75C13.65 16.0285 13.5394 16.2955 13.3425 16.4925C13.1455 16.6894 12.8785 16.8 12.6 16.8C12.3215 16.8 12.0545 16.6894 11.8575 16.4925C11.6606 16.2955 11.55 16.0285 11.55 15.75V9.45C11.55 9.17152 11.6606 8.90445 11.8575 8.70754C12.0545 8.51062 12.3215 8.4 12.6 8.4Z" fill={themeDangerColor} />
        </svg>
    );
};

const DeleteDustbinHover = () => {
    const { token: { themeDangerColor }, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.25 2.1C5.25 1.54305 5.47125 1.0089 5.86508 0.615076C6.2589 0.221249 6.79305 0 7.35 0H13.65C14.207 0 14.7411 0.221249 15.1349 0.615076C15.5287 1.0089 15.75 1.54305 15.75 2.1V4.2H19.95C20.2285 4.2 20.4956 4.31063 20.6925 4.50754C20.8894 4.70445 21 4.97152 21 5.25C21 5.52848 20.8894 5.79555 20.6925 5.99246C20.4956 6.18937 20.2285 6.3 19.95 6.3H18.8275L17.9172 19.0491C17.8795 19.5789 17.6424 20.0748 17.2537 20.4368C16.865 20.7987 16.3536 21 15.8224 21H5.1765C4.64535 21 4.13392 20.7987 3.74523 20.4368C3.35654 20.0748 3.11946 19.5789 3.08175 19.0491L2.1735 6.3H1.05C0.771523 6.3 0.504451 6.18937 0.307538 5.99246C0.110625 5.79555 0 5.52848 0 5.25C0 4.97152 0.110625 4.70445 0.307538 4.50754C0.504451 4.31063 0.771523 4.2 1.05 4.2H5.25V2.1ZM7.35 4.2H13.65V2.1H7.35V4.2ZM8.4 8.4C8.67848 8.4 8.94555 8.51062 9.14246 8.70754C9.33937 8.90445 9.45 9.17152 9.45 9.45V15.75C9.45 16.0285 9.33937 16.2955 9.14246 16.4925C8.94555 16.6894 8.67848 16.8 8.4 16.8C8.12152 16.8 7.85445 16.6894 7.65754 16.4925C7.46062 16.2955 7.35 16.0285 7.35 15.75V9.45C7.35 9.17152 7.46062 8.90445 7.65754 8.70754C7.85445 8.51062 8.12152 8.4 8.4 8.4ZM13.3425 8.70754C13.1455 8.51062 12.8785 8.4 12.6 8.4C12.3215 8.4 12.0545 8.51062 11.8575 8.70754C11.6606 8.90445 11.55 9.17152 11.55 9.45V15.75C11.55 16.0285 11.6606 16.2955 11.8575 16.4925C12.0545 16.6894 12.3215 16.8 12.6 16.8C12.8785 16.8 13.1455 16.6894 13.3425 16.4925C13.5394 16.2955 13.65 16.0285 13.65 15.75V9.45C13.65 9.17152 13.5394 8.90445 13.3425 8.70754Z" fill={themeDangerColor} />
        </svg>
    );
};


const DeleteIcon = {
    DeleteTaskIcon: function DeleteTaskIcon(props) {
        return <Icon component={DeleteDustbin} {...props} />
    },
    DeleteTaskHoverIcon: function DeleteTaskHoverIcon(props) {
        return <Icon component={DeleteDustbinHover} {...props} />
    },
}

export default DeleteIcon;