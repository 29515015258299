import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Input, Spin } from 'antd';
import ApiService from '../../api';
import { CheckCircleFilled } from '@ant-design/icons';

export default function PasswordResetVerifyLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const preFilledCode = queryParams.get('ap_code');

    const [code, setCode] = useState(preFilledCode || '');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isMinLengthValid, setIsMinLengthValid] = useState(false);
    const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [focused, setFocused] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
    const [isPassMatchedFirst, setIsPassMatchedFirst] = useState(false);
    const [resetButtonLabel, setResetButtonLabel] = useState('Reset Password');
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (code && code.length === 6) {
            handleVerifyCode();
        }
    }, [code]);

    useEffect(() => {
        // Password validation
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

        setIsPasswordValid(
            passwordRegex.test(newPassword)
        );
        if (confirmPasswordFocused)
            verifyConfirmPassword();
        setIsMinLengthValid(newPassword.length >= 8);
        setIsUpperCaseValid(/[A-Z]/.test(newPassword));
        setIsNumberValid(/\d/.test(newPassword));
        setIsSpecialCharValid(/\W/.test(newPassword));
    }, [newPassword]);


    useEffect(() => {
        if (confirmPasswordFocused || isPassMatchedFirst)
            verifyConfirmPassword();
    }, [confirmPasswordFocused, confirmPassword, newPassword, isPassMatchedFirst, passwordsMatch]);

    const verifyConfirmPassword = () => {
        if (confirmPassword === newPassword && isPasswordValid) {
            setPasswordsMatch(true);
            setIsPassMatchedFirst(true);
        } else if (confirmPassword.length > 0 && newPassword.length > 0) {
            setPasswordsMatch(false);
        }
        else {
            setPasswordsMatch(null);
        }
    };

    const handleUpdatePassword = () => {
        if (isPasswordValid && passwordsMatch) {
            const data = {
                new_password: newPassword,
                reset_code: code
            }
            setLoading(true);
            ApiService.updatePassword(data).then(resp => {
                if (resp.success) {
                    setMessageText('Password reset successful!');
                    setResetButtonLabel('Back to Login');
                }
                else setMessageText('Password reset unsuccessful. Please try again.');
                setLoading(false);
            });
        } else {
            setMessageText('Password reset unsuccessful. Please try again.');
        }
    };

    const handleVerifyCode = () => {
        if (code.length === 6) {
            setLoading(true);
            ApiService.validateResetCode(code)
                .then(async resp => {
                    setIsCodeVerified(resp.valid);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error verifying code:', error);
                    setIsCodeVerified(false);
                    setLoading(false);
                });
        }
    };

    const handleButtonClick = () => {
        if (resetButtonLabel === 'Back to Login') {
            navigate('/');
        } else {
            handleUpdatePassword();
        }
    };

    return (
        <div className={styles['Container']}>
            <div className={styles['emptyContainer']}>&nbsp;</div>
            <Spin spinning={loading}>
                <div className={styles['rightLayout']}>
                    <h1>password reset</h1>
                    <p>
                        Please enter your one-time code, followed by your new password, then confirm your new password.
                    </p>
                    <div className={styles['emailInput']}>
                        <label style={{ color: '#447EB7' }}>One-time Code</label>
                        <div>
                            <Input
                                style={{ width: '350px' }}
                                size='large'
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                maxLength={6}
                                disabled={isCodeVerified ? true : !hovered}
                                addonAfter={
                                    isCodeVerified ? (
                                        <CheckCircleFilled style={{ color: '#8ECEAE' }} />
                                    ) : null
                                }
                                className={(isCodeVerified ? true : !hovered) ? styles['Hovered'] : null}
                                onMouseOver={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                            />
                        </div>
                    </div>
                    <div className={styles['emailInput']} >
                        <label style={{ color: '#447EB7' }}>New Password</label>
                        <Input.Password
                            id="newPassword"
                            style={{ width: '350px' }}
                            size='large'
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            addonAfter={
                                isPasswordValid ? (
                                    <CheckCircleFilled style={{ color: '#8ECEAE' }} />
                                ) : null
                            }
                        />
                        <div style={focused ? { textAlign: 'left', marginLeft: '-6%', color: '#447EB7' } : { textAlign: 'left', marginLeft: '-6%', color: 'lightgray' }}>
                            <div style={{ marginLeft: '25px', color: isPasswordValid ? '#8ECEAE' : '#447EB7' }}>Password must:</div>
                            <ul style={{ margin: '0px' }}>
                                <li style={{ color: isMinLengthValid ? '#8ECEAE' : '#447EB7' }}>Contain min. 8 characters</li>
                                <li style={{ color: isUpperCaseValid ? '#8ECEAE' : '#447EB7' }}>Contain upper letters</li>
                                <li style={{ color: isNumberValid ? '#8ECEAE' : '#447EB7' }}>Contain at least 1 number</li>
                                <li style={{ color: isSpecialCharValid ? '#8ECEAE' : '#447EB7' }}>Contain 1 special character</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles['emailInput']}>
                        <label style={{ color: '#447EB7' }}>Confirm New Password</label>
                        <Input.Password
                            style={{ width: '350px' }}
                            size='large'
                            value={confirmPassword}
                            onChange={e => { setConfirmPassword(e.target.value); if (e.target.value === newPassword && isPasswordValid) { setConfirmPasswordFocused(true); verifyConfirmPassword() } }}
                            onBlur={() => { (confirmPassword.length > 0 && setConfirmPasswordFocused(true)); verifyConfirmPassword() }}
                            addonAfter={
                                passwordsMatch ? (
                                    <CheckCircleFilled style={{ color: '#8ECEAE' }} />
                                ) : null
                            }
                        />
                        {(passwordsMatch !== null) ? !passwordsMatch ? <div style={{ color: 'red' }}>Passwords do not match</div> : <div style={{ color: '#8ECEAE' }}>Passwords match</div> : null}
                    </div>
                    {messageText}
                    <Button
                        type="primary"
                        className={styles['resetPasswordButton']}
                        onClick={handleButtonClick}
                    >
                        {resetButtonLabel}
                    </Button>
                </div>
            </Spin>
        </div>
    );
}
