export default function ValidateCurrency(amount) {

    if (amount.endsWith('.0'))
        return {
            value: amount,
            error: ''
        }

    let amountFloat = parseFloat(amount);
    if (amountFloat)
        return {
            value: amount.endsWith('.') ? amount : amountFloat,
            error: CheckCurrency(amountFloat) ? '' : 'Please enter valid amount'
        }
    return {
        value: '',
        error: 'Please enter correct amount'
    };
}

function CheckCurrency(amount) {
    return (amount > 0);
}