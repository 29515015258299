import { Popover, Tag, theme } from "antd";
import TagComponent from "../../components/TagComponent";
import SortColumn from "../../components/SortColumn";
import KebabIcon from "../../assets/images/icons/KebabIcon";
import EditNewAssets from "../NewEditAssets";
import styles from "./index.module.css";
import utils from "../../utils";
import { useState } from "react";
import AssetRowOptions from "../../components/AssetRowOptions";

function generateAssetTableColumns(assets, setAssets, customSort, setCustomSort, EditAssetIcon, DeleteAssetIcon, isAppOpen) {

    const notApplicableTypes = ["Other", "Amount (PAL)", "Amount (Working Capital)"];

    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();
    const themeColorVariables = {
        "--userPrimaryColor": ap_userPrimaryColor,
        "--userHighlightColor": ap_userHighlightColor,
    }

    const tagColor = (status) => {
        const yellowTags = ['Requested', 'In-Funding'];
        const greenTags = ['Approved', 'Funded', 'Accepted'];
        const cyanTags = ['Submitted', 'Assigned']
        const redTags = ['Lost'];

        if (yellowTags.includes(status))
            return 'Tag-Yellow';
        else if (greenTags.includes(status))
            return 'Tag-Green';
        else if (cyanTags.includes(status))
            return 'Tag-Cyan';
        else if (redTags.includes(status))
            return 'Tag-Red';
        return 'Tag-Default';
    }

    return [
        {
            title: "Type",
            dataIndex: "ap_type",
            key: "ap_type",
            ...SortColumn(customSort, setCustomSort, 'ap_type'),
            width: 180,
            ellipsis: { showTitle: false },
        },
        {
            title: "Make",
            dataIndex: "ap_make",
            key: "ap_make",
            ...SortColumn(customSort, setCustomSort, 'ap_make'),
            width: 120,
            ellipsis: { showTitle: false },
        },
        {
            title: 'Model',
            dataIndex: 'ap_model',
            key: 'ap_model',
            width: 120,
            ellipsis: { showTitle: false },
        },
        {
            title: 'Purpose',
            dataIndex: 'ap_purpose',
            key: 'ap_purpose',
            ...SortColumn(customSort, setCustomSort, 'ap_purpose'),
            width: 180,
            ellipsis: { showTitle: false },
        },
        {
            title: 'Year',
            key: 'ap_year',
            width: 100,
            ellipsis: { showTitle: false },
            render: (record) => !notApplicableTypes.includes(record.ap_type) ? record.ap_year : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'VIN/Serial',
            key: 'ap_vinSerial',
            width: 150,
            ellipsis: { showTitle: false },
            render: (record) => (record.ap_vin || record.ap_serialNum)
        },
        {
            title: 'KMs',
            key: 'ap_kms',
            width: 100,
            ellipsis: { showTitle: false },
            render: (record) => !notApplicableTypes.includes(record.ap_type) ? record.ap_kms : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Price',
            key: 'ap_price',
            render: (record) => Number(record.ap_price).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            width: 150,
            ellipsis: { showTitle: false },
        },
        {
            title: 'Vendor',
            key: 'ap_vendorId',
            width: 140,
            ellipsis: { showTitle: false },
            render: (record) => (record?.ap_vendorId?.ap_name || record?.ap_vendorId)
        },
        {
            title: 'Plating Province',
            key: 'ap_platingprovince',
            width: 180,
            ellipsis: { showTitle: false },
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_platingprovince : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: 'Plating Address',
            key: 'ap_platingaddress',
            width: 180,
            ellipsis: { showTitle: false },
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_platingaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: 'Yard Address',
            key: 'ap_yardaddress',
            width: 150,
            ellipsis: { showTitle: false },
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_yardaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: "Status",
            key: "ap_status",
            width: 130,
            fixed: "right",
            ellipsis: { showTitle: false },
            render: record => {
                const assetStatus = record?.ap_assetStatus;
                if (assetStatus)
                    return <Tag className={styles[tagColor(assetStatus)]}>{assetStatus}</Tag>
            }
        },
        {
            title: "",
            key: "action",
            width: 50,
            fixed: "right",
            ellipsis: { showTitle: false },
            render: (text, record, index) => {
                const { allowEdit, allowDelete } = utils.IndividualAssetManagement(record?.ap_assetStatus);
                return (
                    <AssetRowOptions
                        isAppOpen={!isAppOpen}
                        record={record}
                        index={index}
                        assets={assets}
                        setAssets={setAssets}
                        allowEdit={allowEdit}
                        allowDelete={allowDelete}
                        EditAssetIcon={EditAssetIcon}
                        DeleteAssetIcon={DeleteAssetIcon}
                    />
                )
            }
        },
    ];
}

export default {
    generateAssetTableColumns: generateAssetTableColumns,
}