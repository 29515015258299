import { Button, Popover, theme } from "antd";
import KebabIcon from "../../assets/images/icons/KebabIcon";
import EditNewAssets from "../../layouts/NewEditAssets";
import styles from "./index.module.css";

export default function AssetRowOptions({ record, index, assets, setAssets, allowEdit, allowDelete, EditAssetIcon, DeleteAssetIcon, setUnsaved, isAppOpen }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();
    const themeColorVariables = {
        "--userPrimaryColor": ap_userPrimaryColor,
        "--userHighlightColor": ap_userHighlightColor,
    }

    return (
        <Popover
            title={null}
            trigger="click"
            placement="bottom"
            arrow={false}
            content={
                <div style={themeColorVariables} className={styles["PopoverContainer"]}>
                    <EditNewAssets
                        assetData={record}
                        assets={assets}
                        setAssets={setAssets}
                        assetIndex={index}
                        icon={EditAssetIcon(allowEdit)}
                        allowEdit={allowEdit}
                        setUnsaved={setUnsaved}
                    />
                    {DeleteAssetIcon(index, record, allowDelete)}
                </div>
            }
        >
            <Button icon={<KebabIcon isAppOpen={isAppOpen}/>} disabled={isAppOpen} className={styles["Kebab_Button"]}   />
        </Popover>
    );
}