// Import necessary modules and components
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Empty, Layout, Spin, theme } from 'antd';
import dayjs from 'dayjs';

import DismissIcon from "../../assets/images/icons/DismissIcon";
import ViewIcon from "../../assets/images/icons/ViewIcon";

import ApiService from '../../api';
import accordianConstants from "./constants";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

// Define the functional component that displays a list of notifications
export default function NotificationAccordian({ data, setOpen, setRefresh }) {

    const [loader, setLoader] = useState({ loading: false, tip: '' })

    // Extract primary color from the theme
    const { token: { themeFontColor, themeDangerColor, ap_userPrimaryColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--themeFontColor': themeFontColor,
        '--themeDangerColor': themeDangerColor
    }

    // Initialize navigation using the react-router-dom hook
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
        if (!data || data?.length === 0) {
            setNotifications([]);
            return;
        }

        const updatedData = data.map(item => ({ ...item, hovered: undefined }));
        setNotifications(updatedData);
    }, [data]);

    // Function to handle changes when a notification is updated
    const handleChange = (id, data) => {
        // Call the API service to update the notification
        setLoader({ loading: true, tip: 'Updating notification...' });
        ApiService.updateNotification(id, data).then(response => {
            if (response?.success)
                // If the update is successful, trigger a refresh of the notification list
                setRefresh(prevRefresh => prevRefresh + 1);
            setLoader({ loading: false, tip: '' });
        });
    }

    const handleViewAll = () => {
        setOpen(false);
        navigate("/notifications");
    }

    // If there are no notifications or the data array is empty, display an empty container with View All button
    if (!data || data.length === 0)
        return (
            <div className={styles["emptyContainer"]}>
                <Empty
                    description="No Active Notifications"
                />
                <div className={styles["viewAllContainer"]}>
                    {/* Button to navigate to the "View All" notifications page */}
                    <Button
                        type="link"
                        className={styles["ViewAllButton"]}
                        onClick={handleViewAll}
                    >View All</Button>
                </div>
            </div>
        );

    // Render the list of notifications
    return (
        <Spin spinning={loader?.loading} tip={loader?.tip}>
            <Layout style={themeColorVariables}>
                <div className={styles["ListContainer"]}>
                    {/* Map through the notification data and display each notification */}
                    {notifications.map((notif, index) => {
                        // Compile notification dataand generate details using constants
                        const compiledData = accordianConstants.generateData(notif?.ap_notificationType, notif?.ap_data);

                        // const itemClass = `${styles["item"]} ${notif?.hovered ? styles['item-hovered'] : (notif?.hovered === false ? styles['item-hovered-out'] : '')}`;
                        const itemClass = `${styles["item"]}`
                        return (
                            <div
                                id={index}
                                // className={styles["item"]}
                                className={itemClass}
                                onMouseEnter={() => {
                                    const updatedNotifications = [...notifications];
                                    updatedNotifications[index].hovered = true;
                                    setNotifications(updatedNotifications);
                                }}
                                onMouseLeave={() => {
                                    const updatedNotifications = [...notifications];
                                    updatedNotifications[index].hovered = false;
                                    setNotifications(updatedNotifications);
                                }}
                            >

                                {/* Notifcation Icon */}
                                <div className={styles["icon"]}>
                                    {compiledData?.icon}
                                </div>

                                {/* Content */}
                                <div className={styles["content"]}>

                                    {/* Notification Details */}
                                    <div className={styles["details"]}>

                                        {/* Header */}
                                        <div className={`${styles["headerText"]} Font_SemiBold`}>
                                            {compiledData?.headerText}
                                        </div>

                                        {/* SubText */}
                                        <div className={styles["subText"]}>
                                            {compiledData?.subText}
                                        </div>

                                    </div>

                                    {/* Time Details */}
                                    <div className={`${styles["timestamp"]} Font_UltraLight`}>
                                        <div>
                                            {dayjs(notif?.createdAt).format("HH:mm a")}
                                        </div>
                                        <div>
                                            {dayjs(notif?.createdAt).format("MM-DD-YYYY")}
                                        </div>
                                    </div>

                                </div>

                                {/* View Icon */}
                                <div className={styles["view"]} onClick={() => {
                                    setOpen(false);
                                    navigate(`/notifications/${notif?._id}`);
                                    handleChange(notif?._id, { ap_isRead: true });
                                }}>
                                    <ViewIcon />
                                </div>

                                {/* Dismiss Icon */}
                                <div
                                    className={styles["dismiss"]}
                                    onClick={() => handleChange(notif?._id, { ap_isActiveAccordian: false, ap_isRead: true })}
                                >
                                    <DismissIcon />
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* Button to navigate to the "View All" notifications page */}
                <div className={styles["viewAllContainer"]}>
                    <Button
                        type="link"
                        className={styles["ViewAllButton"]}
                        onClick={handleViewAll}
                    >View All</Button>
                </div>
            </Layout>
        </Spin>
    );
}