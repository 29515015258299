import { theme } from "antd";
import { useState } from "react";

export default function PreviousIcon({ enabled }) {
    const { token: { themeSecondaryColor, ap_userSecondaryColor, userSecondaryHoverColor } } = theme.useToken();

    const [hovered, setHovered] = useState(false);
    const onHover = () => setHovered(true);
    const onUnhover = () => setHovered(false);
    const iconColor = (hovered && enabled) ? userSecondaryHoverColor : (enabled ? ap_userSecondaryColor : themeSecondaryColor);
    return (
        <div
            onMouseEnter={onHover}
            onMouseLeave={onUnhover}
            style={containerStyle}
        >
            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.500024 8.18182C0.500024 7.98614 0.538193 7.79633 0.614529 7.61239C0.690865 7.42845 0.792645 7.27583 0.919871 7.1545L7.94275 0.403587C8.22265 0.134529 8.57889 0 9.01145 0C9.44402 0 9.80026 0.134529 10.0802 0.403587C10.3601 0.672645 10.5 1.01508 10.5 1.4309C10.5 1.84672 10.3601 2.18916 10.0802 2.45821L4.12597 8.18182L10.0802 13.9054C10.3601 14.1745 10.5 14.5169 10.5 14.9327C10.5 15.3486 10.3601 15.691 10.0802 15.96C9.80026 16.2291 9.44402 16.3636 9.01145 16.3636C8.57889 16.3636 8.22265 16.2291 7.94275 15.96L0.919871 9.20913C0.7672 9.06237 0.658803 8.90338 0.594681 8.73216C0.530559 8.56094 0.499006 8.3775 0.500024 8.18182Z" fill={iconColor} />
            </svg>
        </div>
    );
}

const containerStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}