import { theme } from "antd";
import utils from "../../utils";
import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

function cleanListingData(data) {
    if (!data) return {};

    const fallbackImage = process.env.REACT_APP_STATIC_CONTAINER + process.env.REACT_APP_LISTING_FALLBACK_IMAGE;
    const cardAssetImage = data?.ap_assetPhoto?.[0] || fallbackImage;

    const completeAssetDescription = [data?.ap_year, data?.ap_assetDescription].filter(item => item !== null && item !== undefined && item !== '')?.join(" ");
    const assetPrice = utils.parseDBCurrency(data.ap_price).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    return {
        fallbackImage,
        cardAssetImage,
        completeAssetDescription,
        assetPrice,
        make: data?.ap_make || '--',
        model: data?.ap_model || '--',
        listNumber: data?.crm_listNumber || '--',
        kms: utils.NumberWithCommas(data?.ap_kms) || '--',
        hasVin: data?.ap_hasVIN,
        vin: data?.ap_vin || '--',
        serialNum: data?.ap_serialNum || '--',
        vendorName: data?.ap_vendorName || '--',
        vendorAddress: data?.ap_vendorAddress || '--',
        vendorContactName: data?.ap_vendorContactName || null,
        vendorContactEmail: data?.ap_vendorContactEmail || null,
        vendorContactPhone: data?.ap_vendorContactPhone || null,
        vendorWebsite: data?.ap_vendorWebsite || null,
    }
}

function handleButtonHovers(buttonName, setHoveredButtons) {
    setHoveredButtons((prev) => {
        return {
            ...prev,
            [buttonName]: !prev[buttonName]
        }
    })
}

function color(hovered) {
    const { token: { userPrimaryColor, userHighlightColor }, } = theme.useToken();
    return hovered ? userHighlightColor : userPrimaryColor;
}

function handleInteresed(id, setRefresh) {
    const interestData = {
        ap_listingId: id,
        ap_userId: JSON.parse(localStorage?.getItem("user"))?.userId
    }
    ApiService.showInterest(interestData).then((data) => {
        if (data?.success) {
            NotificationService.success("Thank you! Our team has been notified of your interest");
            setRefresh(prevRefresh => prevRefresh + 1);
        }
    });
}

function handleNotInterested(id, setRefresh) {
    ApiService.removeInterest(id).then((data) => {
        if (data?.success) {
            NotificationService.success("Removed from interested");
            setRefresh(prevRefresh => prevRefresh + 1);
        }
    });
}

const exportObj = {
    cleanListingData,
    handleButtonHovers,
    color,
    handleInteresed,
    handleNotInterested
}
export default exportObj;