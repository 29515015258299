import React from "react";
import ErrorFallback from "../../layouts/ErrorFallback";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
    if( props?.link)
      this.setState({...this.state, link:  props.link});
  }

  
  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<ErrorFallback link={this.props} />);
    }

    return this.props.children;
  }
}


export default ErrorBoundary;