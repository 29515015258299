import { ConfigProvider, Form, Input, theme } from 'antd';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from './index.module.css';

const { TextArea } = Input;

export default function TextAreaComponent({ data, item, layout, rowCount, autoSize = true }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor }, } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    activeBorderColor: ap_userPrimaryColor,
                    hoverBorderColor: ap_userPrimaryColor,
                    colorBgContainer: themePrimaryColor,
                    colorBgContainerDisabled: themePrimaryColor,
                    colorText: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                    colorBorder: ap_userHighlightColor,
                }
            }}
        >
            {data.hidden ? null : (
                <div className={(layout === 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout === 'vertical' ?
                            <>
                                <TextArea
                                    onChange={data.eventHandler}
                                    value={data.value}
                                    status={!!data.error ? 'error' : ''}
                                    style={{ height: 'fit-content' }}
                                    addonBefore={data.addonBefore}
                                    pattern={data.pattern}
                                    disabled={data.disabled}
                                    placeholder={data.placeholder}
                                    allowClear={true}
                                    showCount={true}
                                    rows={rowCount || 5}
                                    autoSize={autoSize}
                                />
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </>
                            : null}
                    </Form.Item>
                    {layout === 'horizontal' ?
                        <>
                            <TextArea
                                onChange={data.eventHandler}
                                value={data.value}
                                status={!!data.error ? 'error' : ''}
                                style={{ height: 'fit-content' }}
                                addonBefore={data.addonBefore}
                                disabled={data.disabled}
                                suffix={data.suffix}
                                placeholder={data.placeholder}
                                allowClear={true}
                                showCount={true}
                                rows={rowCount || 5}
                                autoSize={autoSize}
                            />
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </>
                        : null}
                </div>
            )}
        </ConfigProvider>
    )
}