import { Badge, theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Notification(open, selected) {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["Notification"]}>
            <mask id="path-1-inside-1_601_4031" fill="white">
                <path d="M0 5C0 2.23858 2.23858 0 5 0H75C77.7614 0 80 2.23858 80 5V75C80 77.7614 77.7614 80 75 80H5C2.23858 80 0 77.7614 0 75V5Z" />
            </mask>
            <path d="M0 5C0 2.23858 2.23858 0 5 0H75C77.7614 0 80 2.23858 80 5V75C80 77.7614 77.7614 80 75 80H5C2.23858 80 0 77.7614 0 75V5Z" fillOpacity="0.5" className={styles[(selected || open) ? "Notification_BG_Selected" : "Notification_BG"]} />
            <path d="M0 0H80H0ZM80 75C80 82.1797 74.1797 88 67 88H13C5.8203 88 0 82.1797 0 75V72C0 72 2.23858 72 5 72H75C77.7614 72 80 72 80 72V75ZM0 80V0V80ZM80 0V80V0Z" mask="url(#path-1-inside-1_601_4031)" className={styles[selected ? "Notification_Selected" : "Notification_Un_Selected"]} />
            <path d="M37.312 27.3121C37.5286 26.7767 37.9002 26.3182 38.3791 25.9953C38.858 25.6725 39.4224 25.5 40 25.5C40.5776 25.5 41.142 25.6725 41.6209 25.9953C42.0998 26.3182 42.4714 26.7767 42.688 27.3121C44.832 27.9018 46.7231 29.1789 48.071 30.9474C49.4188 32.7159 50.1489 34.8779 50.149 37.1015V43.9114L52.8051 47.8956C52.9508 48.114 53.0344 48.3678 53.0472 48.6299C53.0599 48.8921 53.0012 49.1528 52.8774 49.3843C52.7536 49.6157 52.5692 49.8092 52.344 49.9441C52.1189 50.0789 51.8613 50.1502 51.5988 50.1501H45.0237C44.8492 51.358 44.2453 52.4625 43.3226 53.2614C42.4 54.0603 41.2204 54.5 40 54.5C38.7796 54.5 37.6 54.0603 36.6774 53.2614C35.7548 52.4625 35.1509 51.358 34.9763 50.1501H28.4012C28.1388 50.1502 27.8812 50.0789 27.656 49.9441C27.4308 49.8092 27.2465 49.6157 27.1227 49.3843C26.9988 49.1528 26.9401 48.8921 26.9529 48.6299C26.9656 48.3678 27.0493 48.114 27.195 47.8956L29.8511 43.9114V37.1015C29.8511 32.4272 33.0118 28.4894 37.312 27.3121ZM37.9499 50.1501C38.0997 50.5744 38.3773 50.9418 38.7446 51.2017C39.1119 51.4616 39.5508 51.6012 40.0007 51.6012C40.4507 51.6012 40.8896 51.4616 41.2569 51.2017C41.6242 50.9418 41.9018 50.5744 42.0516 50.1501H37.9485H37.9499ZM40 29.8523C38.0774 29.8523 36.2335 30.616 34.874 31.9755C33.5145 33.335 32.7508 35.1789 32.7508 37.1015V44.3507C32.7508 44.6371 32.6661 44.9171 32.5072 45.1554L31.111 47.2504H48.8876L47.4914 45.1554C47.333 44.917 47.2488 44.637 47.2493 44.3507V37.1015C47.2493 35.1789 46.4855 33.335 45.126 31.9755C43.7665 30.616 41.9226 29.8523 40 29.8523Z" className={styles["Notification_Selected"]} />
        </svg>
    );
}

export default function HeaderNotificationIcon({ selected, open, unreadCount }) {
    const pathName = window.location.pathname.split('/')[1];
    const {
        token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor },
    } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
        display: 'flex',
        width: '68.5px',
        height: '61px',
    };
    return (
        <Badge count={unreadCount} size="small" style={unreadCount > 9 ? doubleBadgeStyle : badgeStyle}>
            <Icon component={() => Notification(open, selected)} style={themeColorVariables} />
        </Badge>
    );
}

// Styles for the badge
const badgeStyle = {
    boxShadow: 'none',
    marginTop: '19px',
    marginRight: '24px',
};

const doubleBadgeStyle = {
    boxShadow: 'none',
    marginTop: '19px',
    marginRight: '17px',
};
