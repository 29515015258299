import HomeContent from "../../components/HomeContent";
import NotificationsLayout from "../../layouts/NotificationsLayout";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import { Checkbox, ConfigProvider, Select, Spin, theme } from "antd";
import { useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import ApiService from "../../api";
import { useLocation } from "react-router-dom";

export default function Notification() {

    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeSecondaryColor, themeDisabledContainer, themeFontColor } } = theme.useToken();

    const themeTokens = {
        token: {
            colorBgContainer: themePrimaryColor,
            colorBgContainerDisabled: themeDisabledContainer,
            colorText: themeFontColor,
            colorTextDisabled: themeFontColor,
            colorIcon: themeFontColor,
            controlOutline: ap_userHighlightColor,
            colorPrimaryHover: ap_userHighlightColor,
            controlItemBgHover: themeSecondaryColor,
            colorIconHover: themeSecondaryColor,
            colorTextQuaternary: themeFontColor,
            colorBorder: ap_userHighlightColor,
            colorTextPlaceholder: "#898989"
        }
    }

    const [client, setClient] = useState(null);
    const [clientList, setClientList] = useState([]);
    useEffect(() => {
        const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;
        if (!agentId) return;
        setLoading(true);
        ApiService.fetchAgentClient(agentId).then(dataList => {
            if (!dataList) {
                setLoading(false);
                return;
            }
            setClientList(dataList);
            setLoading(false);
        });
    }, []);


    const [filterList, setFilterList] = useState([
        { label: "New Approval", value: "newApproval", checked: false },
        { label: "Approval Amendment", value: "approvalAmendment", checked: false },
        { label: "Approval Maintained", value: "approvalMaintained", checked: false },
        { label: "Expiry Approaching", value: "expiryApproaching", checked: false },
        { label: "Credit Expired Maintained", value: "creditExpiredMaintained", checked: false },
        { label: "Credit Expired", value: "creditExpired", checked: false },
        { label: "Credit Extended", value: "creditExtended", checked: false },
        { label: "Deal Funded", value: "dealFunded", checked: false },
        { label: "New Requirements", value: "newRequirements", checked: false },
        { label: "Vendor Funded", value: "vendorFunded", checked: false },
        { label: "New PPS", value: "newPps", checked: false },
        { label: "Asset Declined", value: "assetDeclined", checked: false },
        { label: "Synergy Funded", value: "synergyFunded", checked: false },
        { label: "Synergy Cancelled", value: "synergyCancelled", checked: false },
        { label: "Ownwership Request", value: "ownwershipRequest", checked: false },
        { label: "Signing Auditing", value: "signingAuditing", checked: false }
    ]);

    const handleClearAll = () => {
        setFilterList(prevList => {
            let temp = [...prevList];
            temp.forEach(item => item.checked = false);
            return temp;
        })
    }

    const clearAllStyles = filterList.some(item => item.checked);

    return (
        <HomeContent
            contentHeader={
                <div className={styles["HeaderContainer"]}>
                    <div className={styles["Header"]}>Notifications</div>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <ConfigProvider theme={themeTokens}>
                            <Select
                                placeholder="Filter by type"
                                popupClassName={styles["FilterTypeContainer"]}
                                size="large"
                                placement="bottomLeft"
                                getContainer=".AppTheme"
                                suffixIcon={<FilterOutlined style={{ color: ap_userPrimaryColor, fontSize: 18 }} />}
                                dropdownRender={(menu) => (
                                    <>
                                        <div className={styles["FilterGrid"]}>
                                            {filterList.map((item, index) => {
                                                const checked = item?.checked;
                                                return (
                                                    <Checkbox
                                                        id={item?.value}
                                                        key={index}
                                                        value={checked}
                                                        onChange={(e) => {
                                                            setFilterList(prevList => {
                                                                let temp = [...prevList];
                                                                temp[index].checked = e.target.checked;
                                                                return temp;
                                                            })
                                                        }}
                                                    ><span style={(checked) ? { color: ap_userPrimaryColor } : {}}>{item?.label}</span></Checkbox>
                                                )
                                            })}
                                        </div>
                                        <div style={{ color: ap_userPrimaryColor, width: '100%' }}>
                                            <span className={clearAllStyles ? styles["ClearAll"] : styles["ClearAll-Disabled"]} onClick={handleClearAll}>Clear All</span>
                                        </div>
                                    </>
                                )}
                                style={{ width: '200px' }}
                            />

                            {/* <Select
                                showSearch
                                value={client}
                                placeholder="Search for client"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={clientList}
                                size="large"
                                style={{ width: '200px' }}
                                onChange={(value) => setClient(value)}
                            /> */}
                        </ConfigProvider>
                    </div>
                </div>
            }
            content={
                <Spin spinning={loading} tip="Loading...">
                    <NotificationsLayout filterList={filterList} setFilterList={setFilterList} client={client} />
                </Spin>
            }
            bgColor="transparent"
        />
    );
}