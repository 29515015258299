import { theme } from "antd";
import fallbackTheme from '../../../../../constants/THEMES';

export default function CreditExpired() {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={themeDangerColor || fallbackTheme.light.themeDangerColor} />
            <path d="M41.8207 41.8226C41.8207 42.4442 41.5738 43.0404 41.1343 43.4799C40.6947 43.9194 40.0986 44.1664 39.477 44.1664C38.8554 44.1664 38.2592 43.9194 37.8197 43.4799C37.3802 43.0404 37.1332 42.4442 37.1332 41.8226C37.1332 41.201 37.3802 40.6049 37.8197 40.1653C38.2592 39.7258 38.8554 39.4789 39.477 39.4789C40.0986 39.4789 40.6947 39.7258 41.1343 40.1653C41.5738 40.6049 41.8207 41.201 41.8207 41.8226Z" fill="#645A80" />
            <path d="M25.8246 6.67107C23.0966 6.6117 20.3885 7.14848 17.8893 8.24393C15.3902 9.33939 13.1603 10.9671 11.3552 13.0135C9.55021 15.0598 8.21358 17.4755 7.43866 20.0919C6.66375 22.7082 6.46923 25.4622 6.86868 28.1614C7.26814 30.8607 8.25193 33.4403 9.75139 35.7201C11.2509 37.9998 13.2298 39.9249 15.5502 41.3607C17.8705 42.7966 20.4763 43.7087 23.1856 44.0334C25.8949 44.3581 28.6424 44.0875 31.2364 43.2406C31.6796 43.0958 32.0471 42.7808 32.2581 42.365C32.469 41.9492 32.5062 41.4666 32.3614 41.0234C32.2165 40.5802 31.9016 40.2127 31.4858 40.0017C31.07 39.7907 30.5874 39.7536 30.1442 39.8984C27.3254 40.8188 24.2992 40.8966 21.4368 40.1222C18.5744 39.3478 16.0003 37.7549 14.03 35.5388C12.0598 33.3227 10.7791 30.5798 10.3451 27.6464C9.91105 24.7131 10.3425 21.7168 11.5866 19.0251C12.8306 16.3334 14.8332 14.0633 17.3486 12.4932C19.8641 10.923 22.7831 10.1211 25.7477 10.1858C28.7123 10.2505 31.5935 11.1789 34.0381 12.8573C36.4827 14.5356 38.3843 16.8909 39.5098 19.6343C39.6866 20.0657 40.0276 20.4092 40.4577 20.5892C40.6707 20.6783 40.8991 20.7246 41.1299 20.7255C41.3608 20.7263 41.5896 20.6817 41.8032 20.5941C42.0168 20.5065 42.211 20.3778 42.3749 20.2151C42.5387 20.0525 42.6689 19.8592 42.758 19.6462C42.8471 19.4332 42.8934 19.2048 42.8943 18.974C42.8951 18.7431 42.8505 18.5144 42.7629 18.3008C41.3778 14.9241 39.0373 12.0251 36.0285 9.95927C33.0198 7.89346 29.4735 6.75067 25.8246 6.67107Z" fill="white" />
            <path d="M27.1723 15.4554C27.1723 14.9892 26.9871 14.5421 26.6574 14.2125C26.3278 13.8828 25.8807 13.6976 25.4145 13.6976C24.9483 13.6976 24.5012 13.8828 24.1715 14.2125C23.8419 14.5421 23.6567 14.9892 23.6567 15.4554V24.6898L18.8895 29.4547C18.7168 29.6156 18.5783 29.8096 18.4822 30.0253C18.3861 30.2409 18.3344 30.4737 18.3303 30.7097C18.3261 30.9457 18.3695 31.1801 18.4579 31.399C18.5464 31.6179 18.6779 31.8167 18.8449 31.9837C19.0118 32.1506 19.2106 32.2822 19.4295 32.3706C19.6484 32.459 19.8828 32.5024 20.1188 32.4982C20.3549 32.4941 20.5876 32.4424 20.8032 32.3463C21.0189 32.2503 21.2129 32.1117 21.3739 31.939L26.6567 26.6586L27.1723 26.1429V15.4554Z" fill="white" />
            <path d="M41.2348 27.1742C41.2348 26.708 41.0496 26.2609 40.7199 25.9312C40.3903 25.6016 39.9432 25.4164 39.477 25.4164C39.0108 25.4164 38.5637 25.6016 38.234 25.9312C37.9044 26.2609 37.7192 26.708 37.7192 27.1742V35.3773C37.7192 35.8435 37.9044 36.2906 38.234 36.6203C38.5637 36.9499 39.0108 37.1351 39.477 37.1351C39.9432 37.1351 40.3903 36.9499 40.7199 36.6203C41.0496 36.2906 41.2348 35.8435 41.2348 35.3773V27.1742Z" fill="#645A80" />
        </svg>
    );
}