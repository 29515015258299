import HttpInterceptorService from './http-interceptor.service';

class AuthService extends HttpInterceptorService {

    async login(payload) {
        const response = await this._httpClient.post("users/login", payload, {isPublic: true});
        const user = response?.data;

        if (user?.access_token) {
            this.setCurrentUser(user);

            // check if there is any route which the user was trying to access
            const redirectUrl = localStorage.getItem('redirectUrl') || "/home";
            localStorage.removeItem('redirectUrl');

            window.location.href = redirectUrl;
        }
        return user;
    }

    async logout() {
        try {
            // Call the logout endpoint to invalidate tokens server-side
            await this._httpClient.post("users/logout", {}, {isPublic: true});
        } catch (err) {
            console.error(err);
        } finally {
            localStorage.removeItem("user");
            Object.keys(localStorage)
                ?.filter(key => key.startsWith("currentTablePage_"))
                ?.forEach(key => localStorage.removeItem(key));
            window.location.href = "/";
        }
    }

    register(payload) {
        return this._httpClient.post("users/register", payload, {isPublic: true});
    }

    async refreshToken() {
        try {
            const response = await this._httpClient.post("users/refresh-token");
            const newAccessToken = response?.access_token;

            if (newAccessToken) {
                const user = this.getCurrentUser();
                user.access_token = newAccessToken;
                this.setCurrentUser(user);
                return newAccessToken;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }

    setCurrentUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    checkTokenExpired(token) {
        const jwtPayload = JSON.parse(window.atob(token.split('.')[1]));
        return jwtPayload?.exp && Date.now() >= jwtPayload.exp * 1000;
    }

    isLoggedIn() {
        const user = this.getCurrentUser();
        if (user?.access_token) {
            return !this.checkTokenExpired(user.access_token);
        }
        return false;
    }

    authGuard() {
        const isLoggedIn = this.isLoggedIn();
        const currentPathName = (window.location.pathname).toLowerCase();
    
        // Define the root path (login page) and exceptional paths
        const rootPath = '/';
        const exceptionalPaths = ['/register', '/joinus', '/passwordreset', '/passwordreset/verify'];
    
        // If the user is not logged in and tries to access any page other than login or exceptional paths
        if (!isLoggedIn) {
            if (!exceptionalPaths.includes(currentPathName) && currentPathName !== rootPath) {
                // Redirect to login page and store the current path for redirect after login
                localStorage.setItem('redirectUrl', window.location.pathname);
                window.location.href = rootPath;
                return false;
            }
        } else {
            // If the user is logged in and visits the root path (login), redirect to home page
            if (currentPathName === rootPath) {
                window.location.href = '/home';
                return false;
            }
        }
    
        return true;  // Allow navigation to the requested page
    }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
