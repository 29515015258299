import { InfoCircleOutlined, StarFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

import StarIcon from "../../assets/images/icons/StarIcon";

import styles from "./index.module.css";

const tableColumns = [
    {
        title: (
            <div className={styles["Column_Status"]}>
                <span>Status</span>
                <Tooltip title="Indicates Primary User">
                    <InfoCircleOutlined className={styles["Column_Status_Icon"]} />
                </Tooltip>
            </div>
        ),
        key: "primaryUser",
        render: (record) => (
            record?.is_primary ?
                <StarFilled className={styles["Column_Status_Star_Filled"]} /> :
                <StarIcon />
        ),
        width: '100px'
    },
    {
        title: "Username",
        key: "username",
        render: (record) => (<p className={styles[(record?.is_primary) ? "Column_Username_Primary" : "Column_Username"]} >{record?.username}</p>),
    }
];

const UserListConstants = {
    tableColumns,
};

export default UserListConstants;