// Exporting a function named "CheckIdenticalObjects" as the default export
export default function CheckIdenticalObjects(obj1, obj2) {
    // Clean the data by removing empty, null, and undefined values from both objects
    const cleanedObj1 = cleanObjectData(obj1);
    const cleanedObj2 = cleanObjectData(obj2);

    // Compare the cleaned objects for equality
    const areEqual = areObjectEqual(cleanedObj1, cleanedObj2);

    // Find the differences between the cleaned objects
    const differences = findObjectDifferences(cleanedObj1, cleanedObj2);

    // Return an object containing whether the objects are equal and their differences
    return { areEqual, differences };
}

// Function to check if two objects are equal
function areObjectEqual(obj1, obj2) {
    return Object.keys(obj1).length === Object.keys(obj2).length &&
        Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
}

// Function to clean object data by removing empty, null, and undefined values
function cleanObjectData(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value !== '' && value !== null && value !== undefined)
    );
}

// Function to find the differences between two objects
function findObjectDifferences(obj1, obj2) {
    const differences = {};

    // Iterate over keys in obj1 and check for differences with obj2
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
                differences[key] = { oldValue: obj2[key], newValue: obj1[key] };
            }
        }
    }

    // Iterate over keys in obj2 not present in obj1 and add them to differences
    for (const key in obj2) {
        if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
            differences[key] = { oldValue: obj2[key], newValue: undefined };
        }
    }

    return differences;
}