import Icon from '@ant-design/icons';
import { theme } from 'antd';

function SynergyFunded() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="105" height="114" viewBox="0 0 124 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75 57.6062C5.75 37.6187 5.75 27.625 8.1125 24.2625C10.4688 20.9062 19.8625 17.6875 38.6563 11.2562L42.2375 10.0313C52.0313 6.675 56.925 5 62 5C67.0687 5 71.9687 6.675 81.7625 10.0313L85.3437 11.2562C104.137 17.6875 113.531 20.9062 115.887 24.2625C118.25 27.625 118.25 37.625 118.25 57.6062V67.4438C118.25 102.681 91.7562 119.788 75.1312 127.044C70.625 129.013 68.375 130 62 130C55.625 130 53.375 129.013 48.8688 127.044C32.2438 119.781 5.75 102.688 5.75 67.4438V57.6062Z" stroke={ap_userSecondaryColor} strokeWidth="10" />
            <path d="M46.375 70L55.3062 80L77.625 55" stroke={ap_userSecondaryColor} strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default function SynergyFundedIcon(props) {
    return <Icon component={SynergyFunded} {...props} />
}