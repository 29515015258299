import { useState } from "react";

import { theme } from "antd";
import Icon from '@ant-design/icons';


export default function SaveIcon() {
    const { token: { ap_userPrimaryColor, ap_userSecondaryColor }, } = theme.useToken();

    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovered ?
                <Icon component={() => SaveFilled(ap_userPrimaryColor, ap_userSecondaryColor)} /> :
                <Icon component={() => Save(ap_userPrimaryColor, ap_userSecondaryColor)} />
            }
        </div>
    );

}

function Save(ap_userPrimaryColor, ap_userSecondaryColor) {
    return (
        <svg width="25" height="25" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 1H2.5C1.94772 1 1.5 1.44772 1.5 2V21C1.5 21.5523 1.94772 22 2.5 22H21.5C22.0523 22 22.5 21.5523 22.5 21V6.66421C22.5 6.399 22.3946 6.14464 22.2071 5.95711L17.5429 1.29289C17.3554 1.10536 17.101 1 16.8358 1H14.625M6.75 1V5.25C6.75 5.80228 7.19772 6.25 7.75 6.25H13.625C14.1773 6.25 14.625 5.80228 14.625 5.25V1M6.75 1H14.625M15.6543 13.8101C15.6543 15.8288 14.0178 17.4653 11.999 17.4653C9.98027 17.4653 8.34375 15.8288 8.34375 13.8101C8.34375 11.7913 9.98027 10.1548 11.999 10.1548C14.0178 10.1548 15.6543 11.7913 15.6543 13.8101Z" stroke={ap_userSecondaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

function SaveFilled(ap_userPrimaryColor, ap_userSecondaryColor) {
    return (
        <svg width="25" height="25" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 1H2.5C1.94772 1 1.5 1.44772 1.5 2V21C1.5 21.5523 1.94772 22 2.5 22H21.5C22.0523 22 22.5 21.5523 22.5 21V6.66421C22.5 6.399 22.3946 6.14464 22.2071 5.95711L17.5429 1.29289C17.3554 1.10536 17.101 1 16.8358 1H14.625V5.25C14.625 5.80228 14.1773 6.25 13.625 6.25H7.75C7.19772 6.25 6.75 5.80228 6.75 5.25V1ZM11.999 17.4653C14.0178 17.4653 15.6543 15.8288 15.6543 13.8101C15.6543 11.7913 14.0178 10.1548 11.999 10.1548C9.98027 10.1548 8.34375 11.7913 8.34375 13.8101C8.34375 15.8288 9.98027 17.4653 11.999 17.4653Z" fill={ap_userSecondaryColor} />
            <path d="M6.75 1H2.5C1.94772 1 1.5 1.44772 1.5 2V21C1.5 21.5523 1.94772 22 2.5 22H21.5C22.0523 22 22.5 21.5523 22.5 21V6.66421C22.5 6.399 22.3946 6.14464 22.2071 5.95711L17.5429 1.29289C17.3554 1.10536 17.101 1 16.8358 1H14.625M6.75 1V5.25C6.75 5.80228 7.19772 6.25 7.75 6.25H13.625C14.1773 6.25 14.625 5.80228 14.625 5.25V1M6.75 1H14.625M15.6543 13.8101C15.6543 15.8288 14.0178 17.4653 11.999 17.4653C9.98027 17.4653 8.34375 15.8288 8.34375 13.8101C8.34375 11.7913 9.98027 10.1548 11.999 10.1548C14.0178 10.1548 15.6543 11.7913 15.6543 13.8101Z" stroke={ap_userSecondaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}