import constants from "../../constants";
import validate from "../../utils/Validations";
import EmailValidation from "../../utils/Validations/EmailValidation";

export default function generateClientProfileFields(clientData) {
    if (!clientData) return {};

    const profileData = {
        ap_businessName: {
            value: clientData.ap_businessName,
            error: validate.ValidateRequiredField(clientData.ap_businessName).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'Business Name',
        },
        ap_industry: {
            value: clientData.ap_industry,
            error: validate.ValidateRequiredField(clientData.ap_industry).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Industry',
            dropdownContent: constants.CLIENT_INDUSTRIES,
        },
        ap_type: {
            value: clientData.ap_type,
            error: validate.ValidateRequiredField(clientData.ap_type).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Type',
            dropdownContent: constants.CLIENT_TYPES,
        },
        ap_companyType: {
            value: clientData.ap_companyType,
            error: validate.ValidateRequiredField(clientData.ap_companyType).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Company Type',
            dropdownContent: constants.COMPANY_TYPES,
        },
        ap_email: {
            value: clientData.ap_email,
            error: !!validate.ValidateRequiredField(clientData.ap_email).errorMessage ? validate.ValidateRequiredField(clientData.ap_email).errorMessage : (!EmailValidation(clientData.ap_email) ? 'Invalid Email Address' : ''),
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'email',
            label: 'Email',
        },
        ap_telephone: {
            value: validate.FormatTelephone(String(clientData.ap_telephone)).input,
            error: validate.FormatTelephone(String(clientData.ap_telephone)).error,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'Telephone',
        },
        ap_address: {
            value: clientData.ap_address,
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Address',
        },
        ap_city: {
            value: clientData.ap_city,
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'City',
        },
        ap_stateOrProvince: {
            value: clientData.ap_stateOrProvince,
            error: validate.ValidateRequiredField(clientData.ap_stateOrProvince).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Province/States',
            dropdownContent: clientData.ap_country === 'Canada' ? constants.CANADIAN_PROVINCES : constants.AMERICAN_STATES,
        },
        ap_postalCode: {
            value: clientData.ap_postalCode,
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Postal Code',
        },
        ap_country: {
            value: clientData.ap_country,
            error: validate.ValidateRequiredField(clientData.ap_country).errorMessage,
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Country',
            dropdownContent: constants.COUNTRIES,
        },
    }
    return profileData;
}