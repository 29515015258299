import { LoadingOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function RegistrationComplete({ registered, registrationFailed, data }) {

    const navigate = useNavigate();

    const getStarted =  () => {
        const user = localStorage.getItem('user');
        window.location.href = '/profile'
        if (user) {
            setTimeout(() => { navigate('/profile'); }, 2000);
        } else {
            navigate('/')
        }
    }

    return (
        <>
            {registered ?
                <div style={{ display: 'block' }}>
                    <div style={{ width: '100%', marginBottom: '30%' }}>Registration Complete <CheckCircleFilled style={{ color: 'green' }} /></div>
                    <Button type="primary" onClick={getStarted}>Get Started</Button>
                </div> :
                <>
                    {registrationFailed ?
                        <div style={{ marginBottom: '30%' }}>Registration Failed <CloseCircleFilled style={{ color: 'red' }} /></div> :
                        <div style={{ marginBottom: '30%' }}>
                            <div><LoadingOutlined style={{ fontSize: '28px' }} /></div>
                            <div>Hold on, we are registering you</div>
                        </div>
                    }
                </>
            }
        </>
    )
}