import constants from "../../constants";

// Generate Multiple Assets Fields
function generateMultipleAssetsFields() {
    return {
        ap_quantity: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'number',
            label: 'Quantity',
        },
        ap_assetType: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Asset Type',
            dropdownContent: constants.ASSET_TYPES,
        },
        ap_truckBrand: {
            value: '',
            error: '',
            hidden: true,
            required: false,
            inputType: 'dropdown',
            label: 'Truck Brand',
            dropdownContent: constants.TRUCK_BRANDS,
        },
        ap_trailerBrand: {
            value: '',
            error: '',
            hidden: true,
            required: false,
            inputType: 'dropdown',
            label: 'Trailer Brand',
            dropdownContent: constants.TRAILER_BRANDS,
        },
        ap_brand: {
            value: '',
            error: '',
            hidden: true,
            required: false,
            inputType: 'text',
            label: 'Brand',
        },
        ap_purpose: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'dropdown',
            label: 'Purpose',
            dropdownContent: constants.ASSET_PURPOSES,
        },
        ap_purposeOther: {
            value: '',
            error: '',
            hidden: true,
            required: false,
            inputType: 'text',
            label: 'Purpose (Other)',
        },
        ap_model: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Model',
        },
        ap_year: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Year',
            pattern: /^\d{4}$/,
        },
        ap_kms: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'KMs',
        },
        ap_totalPrice: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Total Asset Price',
            addonBefore: "$",
        },
        ap_vendorId: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'add-dropdown-search',
            label: 'Vendor',
        },
        ap_platingprovince: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'dropdown',
            label: 'Plating Province/States',
            dropdownContent: constants.CANADIAN_PROVINCES,
        },
        ap_platingaddress: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Plating Address',
        },
        ap_yardaddress: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Yard Address',
        },
    }
}

const exportObj = {
    generateMultipleAssetsFields: generateMultipleAssetsFields,
}

export default exportObj;