import { theme } from "antd";
import FileTypeIcons from "../../assets/images/icons/FileTypeIcons";
import utils from "../../utils";

import styles from "./index.module.css";

function generateDocumentTableColumns() {

    const { token: { ap_userPrimaryColor } } = theme.useToken();

    return [
        {
            title: 'File',
            key: 'file',
            render: (record) => {
                return (
                    <div className={styles["FileList"]}>

                        <div>{FileTypeIcons(record?.fileName.split('.')[1])}</div>

                        <a
                            href={utils.FormBlobFileUrl(record?.fileUrl)}
                            style={{ color: ap_userPrimaryColor }}
                        >
                            {record?.fileName}
                        </a>
                    </div>
                );
            },
            ellipsis: true,
            width: 220,
            sorter: (a, b) => (a?.fileName || "").localeCompare(b?.fileName || ""),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Size',
            key: 'fileSize',
            dataIndex: 'fileSize',
            width: 100,
            sorter: (a, b) => {
                const sizeA = parseFloat(a.fileSize);
                const sizeB = parseFloat(b.fileSize);

                if (!isNaN(sizeA) && !isNaN(sizeB))
                    return sizeA - sizeB;
                return 0;
            },
            sortDirections: ['ascend', 'descend'],
        }
    ];
}

const exportObj = {
    generateDocumentTableColumns: generateDocumentTableColumns,
}

export default exportObj;