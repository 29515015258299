import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import HomeContent from "../../components/HomeContent";
import PDFIcon from "../../assets/images/icons/PDF";
import utils from "../../utils";

import ApiService from "../../api";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function BeaconPolicies() {

    const navigate = useNavigate();

    const headerName = 'Beacon Policies';

    const resourceData = [
        { name: "Terms & Conditions", url: "", navigateTo: "TermsAndConditions" },
        { name: "Privacy Policy", url: "", navigateTo: "PrivacyPolicy" },
        { name: "Accessibility", url: "", navigateTo: "Accessibility" }
    ];

    const [salesData, setSalesData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetching agent profile data
        setLoading(true);
        ApiService.fetchAgentProfileData().then((agentResponse) => {
            setSalesData(agentResponse?.ap_salesCoordinatorId);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <HomeContent
                contentHeader={
                    <div className={`${styles['Header']} Font_SemiBold`}>
                        <LeftOutlined onClick={() => navigate('/resources')} />
                        Resources | <span className={styles["Header_Title"]}>{headerName}</span>
                    </div>
                }
                content={<>
                    <div className={styles["Container"]}>
                        {resourceData?.map((item, index) => {
                            const onClickEvent = () => {
                                if (item?.navigateTo)
                                    navigate(`/resources/${item?.navigateTo}`);
                                else if (item?.url)
                                    window.open(item?.url, '_blank');
                            }
                            return (
                                <div key={index} className={styles["IndividualItem"]} onClick={onClickEvent}>
                                    <div><PDFIcon /></div>
                                    <div className="Font_Medium">{item?.name}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${styles["Assistance"]} Font_UltraLight`}>
                        If you need assistance, call your Sales Coordinator at
                        <a href={`tel:${salesData?.ap_phoneNumber}`} className={styles["UrlStyling"]}> {(salesData?.ap_position === "Department") ? utils.FormatDepartmentTelephone(salesData?.ap_phoneNumber) : salesData?.ap_phoneNumber}</a> or email
                        <a href={`mailto:${salesData?.ap_email}`} className={styles["UrlStyling"]}> {salesData?.ap_email}.</a>
                    </div>
                </>}
                bgColor="transparent"
            />
        </>
    );
}
