export default function ValidateDrivingExperience(value = '') {
    const years = String(value).trim();

    if (!years) {
        return { value: '', error: 'Required' };
    }

    const drivingExperience = Math.floor(Number(years));

    if (isNaN(drivingExperience) || drivingExperience < 0) {
        const parsedValue = parseFloat(years);
        if (!isNaN(parsedValue) && parsedValue >= 0) {
            return {
                value: Math.floor(parsedValue),
                error: ''
            };
        } else {
            return {
                value: '',
                error: 'Invalid driving experience'
            };
        }
    }

    return {
        value: drivingExperience,
        error: ''
    };
}