import dayjs from "dayjs";

export default function TransformNotificationDate(dateString) {
    if (!dateString)
        return;

    // EXAMPLE: dataString: 2023-05-31T00:00:00.000+00:00

    const dt = new Date(dateString);

    dt.setDate(dt.getDate() + 1);

    const convertedDate = dt.toISOString();
    
    // RETURN EXAMPLE: Wednesday, May 31st, 2023
    return dayjs(convertedDate).locale('en').format('dddd, MMMM Do, YYYY')

}