import { theme } from 'antd';
import Icon from '@ant-design/icons';

const EditPencil = ({props}) => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5087 0.341588C15.7275 0.122869 16.0242 0 16.3335 0C16.6429 0 16.9396 0.122869 17.1584 0.341588L20.6584 3.84163C20.8771 4.06041 21 4.35711 21 4.66647C21 4.97583 20.8771 5.27253 20.6584 5.49132L10.1583 15.9914C9.93954 16.2103 9.64284 16.3332 9.33344 16.3333H5.8334C5.52398 16.3333 5.22723 16.2104 5.00843 15.9916C4.78964 15.7728 4.66672 15.476 4.66672 15.1666V11.6666C4.66679 11.3572 4.78975 11.0605 5.00856 10.8417L15.5087 0.341588ZM7.00008 12.1496V13.9999H8.85044L18.1839 4.66647L16.3335 2.81612L7.00008 12.1496ZM0 4.66647C0 4.04763 0.245835 3.45413 0.683425 3.01654C1.12102 2.57895 1.71452 2.33311 2.33336 2.33311H8.16676C8.47619 2.33311 8.77294 2.45603 8.99173 2.67482C9.21053 2.89362 9.33344 3.19037 9.33344 3.49979C9.33344 3.80922 9.21053 4.10596 8.99173 4.32476C8.77294 4.54355 8.47619 4.66647 8.16676 4.66647H2.33336V18.6666H16.3335V12.8332C16.3335 12.5238 16.4564 12.2271 16.6752 12.0083C16.894 11.7895 17.1908 11.6666 17.5002 11.6666C17.8096 11.6666 18.1064 11.7895 18.3252 12.0083C18.544 12.2271 18.6669 12.5238 18.6669 12.8332V18.6666C18.6669 19.2855 18.4211 19.879 17.9835 20.3166C17.5459 20.7542 16.9524 21 16.3335 21H2.33336C1.71452 21 1.12102 20.7542 0.683425 20.3166C0.245835 19.879 0 19.2855 0 18.6666V4.66647Z" fill={ap_userPrimaryColor}/>
        </svg>
    );
};

const EditPencilHover = ({props}) => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5087 0.341588C15.7275 0.122869 16.0242 0 16.3335 0C16.6429 0 16.9396 0.122869 17.1584 0.341588L20.6584 3.84163C20.8771 4.06042 21 4.35711 21 4.66647C21 4.97583 20.8771 5.27253 20.6584 5.49132L10.1583 15.9914C9.93954 16.2103 9.64284 16.3332 9.33344 16.3333H5.8334C5.52398 16.3333 5.22723 16.2104 5.00844 15.9916C4.78964 15.7728 4.66672 15.476 4.66672 15.1666V11.6666C4.66679 11.3572 4.78975 11.0605 5.00856 10.8417L15.5087 0.341588ZM0 4.66647C0 4.04763 0.245835 3.45413 0.683426 3.01654C1.12102 2.57895 1.71452 2.33311 2.33336 2.33311H8.16676C8.47619 2.33311 8.77294 2.45603 8.99173 2.67482C9.21053 2.89362 9.33344 3.19037 9.33344 3.49979C9.33344 3.80922 9.21053 4.10597 8.99173 4.32476C8.77294 4.54356 8.47619 4.66647 8.16676 4.66647H2.33336V18.6666H16.3335V12.8332C16.3335 12.5238 16.4564 12.2271 16.6752 12.0083C16.894 11.7895 17.1908 11.6666 17.5002 11.6666C17.8096 11.6666 18.1064 11.7895 18.3252 12.0083C18.544 12.2271 18.6669 12.5238 18.6669 12.8332V18.6666C18.6669 19.2855 18.4211 19.879 17.9835 20.3166C17.5459 20.7542 16.9524 21 16.3335 21H2.33336C1.71452 21 1.12102 20.7542 0.683426 20.3166C0.245835 19.879 0 19.2855 0 18.6666V4.66647Z" fill={ap_userPrimaryColor}/>
        </svg>
    );
};

// export default function EditTaskIcon (props) {
//     return <Icon component={EditPencil} {...props}/>
// }


const EditIcon = {
    EditTaskIcon : function EditTaskIcon (props) {
        return <Icon component={EditPencil} {...props}/>
    },
    EditTaskHoverIcon: function EditTaskIcon (props) {
        return <Icon component={EditPencilHover} {...props}/>
    },
}

export default EditIcon;