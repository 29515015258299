import { Button, Col, Row } from "antd";
import styles from './index.module.css';

const ErrorFallback = ({ link }) => {

    const supportLink = `mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`;
    return (
        <div className={styles["errorBody"]}>
            <div className={styles.center}>
                <Row>
                    <Col span={24} className={styles.textFont}>
                        <h1 >Whoops! Something went wrong.</h1>
                    </Col>

                    <Col span={24}>
                        <h2 className={styles.textStyle}>Please either refresh the page or return home to try again</h2>
                    </Col>

                    <Col span={24}>
                        <h2 className={styles.textStyle}>If the issue continues, please
                            <a href={supportLink}> get in touch</a>
                        </h2>
                    </Col>

                    <Col span={24}>

                        <Button
                            size={"large"}
                            className={styles.button}
                            href={"/"}
                        >Go home
                        </Button>


                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ErrorFallback;