import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Asset = () => {
    const { token: { themeFontColor }, } = theme.useToken();
    return (
        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66572e-08 2.72222C1.66572e-08 2.13285 0.234126 1.56762 0.650874 1.15087C1.06762 0.734126 1.63285 0.5 2.22222 0.5H20C20.5894 0.5 21.1546 0.734126 21.5713 1.15087C21.9881 1.56762 22.2222 2.13285 22.2222 2.72222V4.94444C22.2223 5.33826 22.1177 5.72502 21.9191 6.06511C21.7206 6.40521 21.4352 6.68644 21.0922 6.88C21.1044 6.97333 21.1111 7.06889 21.1111 7.16667V18.2778C21.1111 18.8671 20.877 19.4324 20.4602 19.8491C20.0435 20.2659 19.4783 20.5 18.8889 20.5H3.33333C2.74396 20.5 2.17873 20.2659 1.76198 19.8491C1.34524 19.4324 1.11111 18.8671 1.11111 18.2778V7.16667C1.11111 7.07 1.11778 6.97333 1.13 6.88C0.787034 6.68644 0.501667 6.40521 0.303113 6.06511C0.104559 5.72502 -4.81947e-05 5.33826 1.66572e-08 4.94444V2.72222ZM20 4.94444V2.72222H2.22222V4.94444H20ZM3.33333 7.16667V18.2778H18.8889V7.16667H3.33333ZM6.66667 10.5C6.66667 10.2053 6.78373 9.9227 6.9921 9.71433C7.20048 9.50595 7.48309 9.38889 7.77778 9.38889H14.4444C14.7391 9.38889 15.0217 9.50595 15.2301 9.71433C15.4385 9.9227 15.5556 10.2053 15.5556 10.5C15.5556 10.7947 15.4385 11.0773 15.2301 11.2857C15.0217 11.494 14.7391 11.6111 14.4444 11.6111H7.77778C7.48309 11.6111 7.20048 11.494 6.9921 11.2857C6.78373 11.0773 6.66667 10.7947 6.66667 10.5Z" fill={themeFontColor} />
        </svg>
    );
};

export default function AssetIcon(props) {
    return <Icon component={Asset} {...props} />
}