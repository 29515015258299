export default function ValidateSIN(value) {
    if (!value)
        return { value: '', error: 'Required' };

    // Handle masked SIN
    if (value?.startsWith('xx')) return { value, error: '' };

    const sin = value.replace(/\D/g, '');
    const trimmedSin = sin.slice(0, 9);

    return {
        value: trimmedSin,
        error: (trimmedSin?.length < 9) ? "Invalid SIN" : ""
    }
}