export default function FormatTelephone(phone, countryCode = null) {
    // If Phone Number is Empty
    if (!phone) {
        return {
            input: phone,
            error: "Required"
        };
    }

    const phoneNumber = phone.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    // Format Number
    if (phoneNumberLength < 4) {

        return {
            input: countryCode ? (countryCode + phoneNumber) : phoneNumber,
            error: "Enter a 10 digit number"
        };

    } else if (phoneNumberLength < 7) {

        return {
            input: countryCode ? `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}` : `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
            error: "Enter a 10 digit number"
        };

    } else if (phoneNumberLength < 10) {

        return {
            input: countryCode ? `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}` : `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`,
            error: "Enter a 10 digit number"
        }

    } else {

        return {
            input: countryCode ? `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}` : `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`,
            error: ""
        }

    }
}