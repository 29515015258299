import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { ConfigProvider, Spin, Table, theme } from "antd";

import ApiService from "../../api";
import BeaconPagination from "../../components/BeaconPagination";
import ApplicationDraftConstants from "./constants";

export default function DraftedApplicationList({ setTab }) {

    const { token: { themePrimaryColor, themeFontColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState({ loading: false, tip: '' });

    const [draftedApplications, setDraftedApplications] = useState([]);
    const [refresh, setRefresh] = useState(0);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customSort, setCustomSort] = useState({
        createdAt: { order: null, type: 'string' },
        ap_client: { order: null, type: 'string' },
        ap_type: { order: null, type: 'string' },
    });

    useEffect(() => {
        setLoading(true);
        ApiService.fetchActiveApplicationDrafts().then((activeDrafts) => {
            if (activeDrafts && activeDrafts?.length > 0) {
                const parsedDrafts = activeDrafts.map(draft => ({ ...draft, ap_client: (draft?.ap_clientId?.ap_businessName || draft?.ap_clientId) }));
                setDraftedApplications(parsedDrafts);
            } else {
                setLoader({ loading: true, tip: 'No drafted applications found. Redirecting to Open Applications...' });
                setTimeout(() => {
                    setTab('open');
                    setLoader({ loading: false, tip: '' });
                }, 1000);
            }
        });
        setLoading(false);
    }, [refresh]);

    const { pagedData, paginationComponent } = BeaconPagination(draftedApplications, DraftedApplicationList.name, true, true, searchedColumn, searchText, {}, customSort, setCustomSort);

    return (
        <Spin spinning={loader.loading} tip={loader.tip}>
            <div style={{ minHeight: '120px' }}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: themePrimaryColor,
                            colorFillAlter: themePrimaryColor,
                            colorFillSecondary: themePrimaryColor,
                            colorText: themeFontColor,
                            colorIcon: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                            borderRadius: '50',
                        },
                    }}
                >
                    <Table
                        id="drafted-application-list"
                        columns={ApplicationDraftConstants.generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort, setRefresh)}
                        dataSource={pagedData}
                        onRow={(record) => ({
                            onClick: () => navigate(`/applications/draft/${record._id}`),
                            className: 'clickable-row'
                        })}
                        scroll={{ x: true, y: 420 }}
                        pagination={false}
                        footer={() => paginationComponent}
                        loading={loading}
                    />
                </ConfigProvider>
            </div>
        </Spin>
    )
}