export default function AssetManager(assets = []) {

    const isStatusAssetAvailable = (assets, status) => assets?.filter(asset => asset?.ap_assetStatus === status)?.length > 0;
    const generateStatusName = (status) => status?.toLowerCase()?.replace('-', '');

    const editedAssets = assets.filter(asset => asset.ap_edited === true);
    const deletedAssets = assets.filter(asset => asset.ap_deleted === true);

    const statuses = ['Funded', 'In-Funding', 'Approved', 'Submitted', 'Accepted', 'Requested', 'Assigned', 'Drafted', 'Lost'];
    const statusCounts = statuses.reduce((acc, status) => {
        acc[`${generateStatusName(status)}Edited`] = isStatusAssetAvailable(editedAssets, status);
        acc[`${generateStatusName(status)}Deleted`] = isStatusAssetAvailable(deletedAssets, status);
        return acc;
    }, {});

    const { fundedEdited, fundedDeleted, infundingEdited, infundingDeleted, approvedEdited, approvedDeleted, submittedEdited, submittedDeleted, acceptedEdited, acceptedDeleted, requestedEdited, requestedDeleted, assignedEdited, assignedDeleted, draftedEdited, draftedDeleted, lostEdited, lostDeleted } = statusCounts;

    const sendNotification = (infundingEdited || infundingDeleted) || (approvedEdited || approvedDeleted) || (submittedEdited || submittedDeleted) || (acceptedEdited || acceptedDeleted) || (requestedEdited || requestedDeleted) || (assignedEdited || assignedDeleted);
    const askReason = (infundingEdited || infundingDeleted) || (approvedEdited || approvedDeleted) || (submittedEdited || submittedDeleted);
    const cancelDrawdown = (assets.length === deletedAssets.length && infundingDeleted);
    const processUpdate = draftedEdited || draftedDeleted || requestedEdited || requestedDeleted || assignedEdited || assignedDeleted || acceptedEdited || acceptedDeleted || submittedEdited || submittedDeleted || approvedEdited || approvedDeleted || infundingEdited || infundingDeleted;

    return { askReason, cancelDrawdown, processUpdate, sendNotification }
}