//Custom Imports
import ApiService from "../../api";
import constants from "./constants";
const { _task } = constants;



const fetchAllTasks = async (entityId, editData, setEditData) => {
    ApiService.fetchAllTasks().then((data) => {
        if(!data || data?.length === 0) return;
        const isFirstCall = editData.length > 0 ? false : true;

        let editDataTemp = [...data];
        if (!isFirstCall) {
            let combineData = data.map(item2 => {
                let found = editData.find(item1 => item1._id === item2._id);
                return found ? { ...found, ...item2 } : item2;
            });
            setEditData(combineData);

            editDataTemp = [...combineData]
        }
        editDataTemp.map((editData) => {
            editData["is_edit"] = editData.hasOwnProperty("is_edit") ? editData.is_edit : false;
            editData["on_edit_hover"] = editData.hasOwnProperty("on_edit_hover") ? editData.on_edit_hover : false;
            editData["on_delete_hover"] = editData.hasOwnProperty("on_delete_hover") ? editData.on_delete_hover : false;
        })
        // clone if not first call
        setEditData(editDataTemp);
    });
}


const addMyTask = async (entityId, editData, setEditData, setLoading, task, setTask, setIsAdding) => {
    setLoading(true);

    const payLoad = {
        "task_description": task.new_task_description,
        "ap_agentId": entityId
    }

    ApiService.addMyTask(payLoad).then(() => {
        fetchAllTasks(entityId, editData, setEditData);
    });
    setTask(_task);
    setLoading(false);
    setIsAdding(false);
}

// Args obj
//      obj => 
//        obj["_id"] = // Id of Task
//        obj["field"] = // Field which is Changing;
//        obj["value"] = // Value of Field which is Changing;
// Return None
// Function: Updates state of editData
const setEditTaskData = async (obj, editData, setEditData) => {
    let editDataTemp = [...editData];
    editDataTemp.map((tempData) => {
        if (tempData._id == obj["_id"]) {
            tempData[obj["field"]] = obj["value"];
        }
    });
    setEditData(editDataTemp);

}

const onHover = async (obj, editData, setEditData) => {
    let editDataTemp = [...editData];
    editDataTemp.map((tempData) => {
        if (tempData._id == obj["_id"]) {
            tempData[obj["field"]] = obj["value"];
        } else {
            tempData[obj["field"]] = false;
        }
    });
    setEditData(editDataTemp);
}
// Args obj
//      obj => 
//        obj["_id"] = // Id of Task
//        obj["field"] = // Field which is Changing;
//        obj["value"] = // Value of Field which is Changing;
//        obj["edit_type"] = // Which type of edit is it: task_description OR is_done
// Return None
// Function: Updates data value on DB
const completeEditTaskData = async (entityId, obj, editData, setEditData) => {
    // set is_edit to false
    setEditTaskData(obj, editData, setEditData);
    // now send changes to DB
    const tempData = editData.find(taskItem => taskItem._id == obj._id);
    let payLoad = {}
    payLoad[obj.edit_type] = tempData[obj.edit_type]
    ApiService.editMyTask(tempData._id, payLoad).then((data) => {
        fetchAllTasks(entityId, editData, setEditData);
    });
}

// Delete the Task
const deleteMyTask = async (entityId, id, editData, setEditData, setLoading, setIsAdding) => {
    setLoading(true);
    ApiService.deleteMyTask(id).then((data) => {
        fetchAllTasks(entityId, editData, setEditData);
    });
    setLoading(false);
    setIsAdding(false);
}

const utilities = {
    fetchAllTasks: fetchAllTasks,
    addMyTask: addMyTask,
    setEditTaskData: setEditTaskData,
    onHover: onHover,
    completeEditTaskData: completeEditTaskData,
    deleteMyTask: deleteMyTask
}

export default utilities;