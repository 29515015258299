// Imports
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Row, Table, Tag, theme, ConfigProvider } from "antd";

//Custom Imports
import ApiService from "../../api";
import EmptyApprovalsIcon from "../../assets/images/icons/Home/EmptyApprovalsIcon";
import EmptyDataComponent from "../../components/EmptyDataComponent";
import utils from "../../utils";
import constants from "../../constants";

//Custom Styling
import styles from "./index.module.css";
import '../../components/HomeStyle/center.css';
import "../../assets/styles/fonts.css";

const HomeApprovalsRecent = ({ entity, entityId, callbackHeight }) => {

    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });

    const { token: { themeFontColor, themePrimaryColor, userHighlightColor }, } = theme.useToken();

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [approvalsData, setApprovalsData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // Fetch Approvals
    useEffect(() => {
        setLoading(true);

        if (entity === 'agent') {
            ApiService.fetchAgentApprovals(entityId).then((data) => {
                if (!data) return;
                setApprovalsData(() => {
                    data.map((item) => {
                        item.ap_client = item?.ap_clientId?.ap_businessName;
                        return item;
                    });
                    return data;
                });
            });
        } else {
            ApiService.fetchClientApprovals(entityId).then((data) => {
                if (!data) return;
                setApprovalsData(() => {
                    data.map((item) => {
                        item.ap_client = item?.ap_clientId?.ap_businessName;
                        return item;
                    });
                    return data;
                });
            });
        }
        callbackHeight();
        setLoading(false);
    }, [entity, entityId]);

    const ApprovalTable = () => {
        return (
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50',
                    },
                }}
            >
                <Table
                    columns={generateApprovalsTableColumns(approvalsData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, entity)}
                    dataSource={approvalsData}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: true, y: 300 }}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate(`/approvals/${record._id}`);
                        },
                        className: 'clickable-row'
                    })}

                />
            </ConfigProvider>
        )
    }

    const MobileApprovalsCards = () => {
        return (
            <div className={styles["Mobile_Content"]}>
                {approvalsData?.map((item, index) => {
                    const redTagsArray = ['Declined', 'Cancelled', 'Expired'];
                    const greenTagsArray = ['Approved', 'Funded', 'Partially Funded', 'Expired/Partially Funded', 'In Funding', 'Lease Transfer'];
                    const statusClass = greenTagsArray.includes(item?.ap_status) ? 'GreenTags' : redTagsArray.includes(item?.ap_status) ? 'RedTags' : 'NeutralTags';

                    return (
                        <div key={`mobile_approvals_${index}`} onClick={() => navigate(`/approvals/${item?._id}`)}>
                            <div>
                                <div>{item?.ap_client}</div>
                                <div>{item?.ap_lenderId?.ap_abbreviation || item?.ap_lenderId?.ap_name}</div>
                                <Tag bordered={false} style={{ whiteSpace: 'break-spaces', width: '100%', textAlign: 'center' }} className={styles[statusClass]}>{item.ap_status}</Tag>
                            </div>
                            <div>
                                <div>
                                    <div>Credit Available</div>
                                    <div>{(item?.ap_creditAvailable)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                                </div>
                                <div>
                                    <div>Approved Amount</div>
                                    <div>{(item?.ap_approvedAmount)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                                </div>
                                <div>{item?.ap_submissionType}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }


    const RenderFunction = () => {
        if (approvalsData?.length !== undefined && approvalsData?.length === 0) {
            return <EmptyDataComponent flex={true} className="centerApprovals" iconComponent={<EmptyApprovalsIcon />} ContentLine1={"There is nothing to show here."} ContentLine2={"Begin a new Submission to see content"} />
        } else {
            return isMobile ? <MobileApprovalsCards /> : <ApprovalTable />;
        }
    }

    return (
        <>
            <>
                <Row style={{ height: "10% !important" }} className="Lable">
                    <span className={`${styles["cardLabel"]} Font_SemiBold`}>Recent Approvals</span>
                </Row>
                <Row style={{ height: "90%", display: 'flex', justifyContent: 'center' }} className="Table">
                    <RenderFunction />
                </Row>
            </>
        </>
    );
}

export default HomeApprovalsRecent;


// Generate Columns for Approvals Table
function generateApprovalsTableColumns(approvalsData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, entity) {

    const columns = [];

    const redTagsArray = ['Declined', 'Cancelled', 'Expired'];
    const neutralTagsArray = ['Pre-Submission', 'Submitted', 'Amendment Requested', 'Credit Extension Requested'];
    const greenTagsArray = ['Approved', 'Funded', 'Partially Funded', 'Expired/Partially Funded', 'In Funding', 'Lease Transfer'];

    // Add Client Column if entity is not client
    if (entity !== 'client') {
        columns.push(
            {
                title: 'Client',
                dataIndex: 'ap_clientId',
                key: 'ap_clientId',
                render: (record) => (record?.ap_businessName),
                sorter: (a, b) => (a?.ap_clientId?.ap_businessName || "").localeCompare(b?.ap_clientId?.ap_businessName || ""),
                sortDirections: ['ascend', 'descend'],
                width: 150,
                ellipsis: true,
            },
        );
    }

    // Push rest of the columns
    columns.push(
        {
            title: 'Lender',
            dataIndex: 'ap_lenderId',
            key: 'ap_lenderId',
            render: (record) => (record?.ap_abbreviation || record?.ap_name),
            sorter: (a, b) => (a?.ap_lenderId?.ap_abbreviation || "").localeCompare(b?.ap_lenderId?.ap_abbreviation || ""),
            sortDirections: ['ascend', 'descend'],
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Sub. Type',
            dataIndex: 'ap_submissionType',
            key: 'ap_submissionType',
            width: 350,
            ellipsis: true,
        },
        {
            title: 'Sub. Amount',
            key: 'ap_submittedAmount',
            render: (record) => utils.TransformDBCurrency(record.ap_submittedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            sorter: (a, b) => (a?.ap_submittedAmount || "") - (b?.ap_submittedAmount || ""),
            width: 150,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Approved Amount',
            key: 'ap_approvedAmount',
            render: (record) => utils.TransformDBCurrency(record.ap_approvedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            sorter: (a, b) => (a?.ap_approvedAmount || "") - (b?.ap_approvedAmount || ""),
            width: 200,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Status',
            key: 'ap_status',
            width: 200,
            render: (record) => {
                const statusClass = greenTagsArray.includes(record.ap_status) ? 'GreenTags' : redTagsArray.includes(record.ap_status) ? 'RedTags' : 'NeutralTags';
                return (
                    <Tag
                        bordered={false}
                        className={styles[statusClass]}
                    >{record.ap_status}</Tag>
                )
            }
        },
    );

    return columns;
}