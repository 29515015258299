import { Button } from "antd";
import JoinUsSuccessIcon from "../../assets/images/icons/JoinUsSuccessIcon";
import styles from "./index.module.css";

export default function JoinUsSuccess() {

    const handleClick = () => {
        window.open(process.env.REACT_APP_ORG_URL, '_blank');
    }

    const orgURL = process.env.REACT_APP_ORG_URL?.replace(/^www\./, '');

    return (
        <div className={styles["Container"]}>
            <div style={{ display: 'grid', margin: 'auto', height: '80%' }}>
                <div style={{ margin: 'auto' }}>
                    <JoinUsSuccessIcon />
                    <div className={styles["SentSuccess"]}>Request Sent Successfully!</div>
                    <div className={styles["SentSuccess_Sub"]}>We will contact you within 1-2 business days should you qualify.</div>

                    <Button
                        type="primary"
                        onClick={handleClick}
                        className={styles["SentSuccess_Button"]}
                    >{orgURL}</Button>
                </div>
            </div>
        </div>
    );
}