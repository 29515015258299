export default function ConvertHexToRGB(hexCode, opacity) {
    // return;
    // Remove the '#' symbol if present
    if (String(hexCode).startsWith('#')) {
        hexCode = hexCode.slice(1);
    }

    // Parse the hex code as an integer
    const colorInt = parseInt(hexCode, 16);

    // Extract the RGB values from the integer
    const red = (colorInt >> 16) & 255;
    const green = (colorInt >> 8) & 255;
    const blue = colorInt & 255;

    // Convert the opacity to a decimal value between 0 and 1
    const alpha = opacity / 100;

    // Generate the RGBA string
    const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

    return rgba;
}