import { Modal, Steps } from 'antd';
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ApiService from "../../api";
import styles from './index.module.css';
import { CloseOutlined } from '@ant-design/icons';
import validate from "../../utils/Validations";
import { handleSaveRowValidation } from "./validation";
import HasError from "../../utils/HasErrors";
import NotificationService from "../../services/notification.service";
import DocumentRequestConstants from "./constants";
import "../../assets/styles/index.css";
import DocumentRequestStep1 from "../../assets/images/icons/DocumentReqStep1";
import DocumentRequestStep2Disabled from "../../assets/images/icons/DocumentReqStep2Disabled";
import DocumentRequestStep2 from "../../assets/images/icons/DocumentReqStep2";
import DocumentRequestStep1Filled from "../../assets/images/icons/DocumentReqStep1Filled";
import DocumentRequestStep2Filled from "../../assets/images/icons/DocumentReqStep2Filled";

export default function ({ open, setOpen, id, paymentScheduleId, isYardAddressRequired }) {

    const div1Ref = useRef(null);
    const div2Ref = useRef(null);

    // Adjust height of modal for each step acoording to prev step
    const callbackHeight = () => {
        div2Ref.current && (div2Ref.current.style.height = `${minHeight}px`);
    }

    // Step 1 listed assets
    const onSelectChange = (setSelectedRowKeys, selectdRows) => {
        setSelectedAssets(selectdRows);
    };

    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [comments, setComments] = useState("");
    const [folderName, setFolderName] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [updateMultiple, setUpdateMultiple] = useState(false);
    const [documentRequested, setDocumentRequested] = useState(false);
    const [current, setCurrent] = useState(0);
    const [minHeight, setMinHeight] = useState(0);
    const [errors, setErrors] = useState(false);
    const [assetSaved, setAssetSaved] = useState([]);
    const [rowSelection, setRowSelection] = useState({
        selectedAssets,
        onChange: onSelectChange,
    });
    const [values, setValues] = useState({
        ap_platingprovince: {
            value: null,
            error: ''
        },
        ap_platingaddress: {
            value: null,
            error: ''
        }
    });

    useEffect(() => {
        let isErrorFormValidation = false;
        if(!assets?.length) return;
        for(let assetData of assets){
            let {isError, errorMessage} = handleSaveRowValidation(assetData, isYardAddressRequired);
            if(isError){
                isErrorFormValidation = isError;
                break;
            }
        };
        
        setErrors(isErrorFormValidation);
    }, [assets]);

    useEffect(() => {
        if (current === 1 && div1Ref.current)
            setMinHeight(div1Ref.current.clientHeight);
        else if (current === 2 && div2Ref.current)
            callbackHeight();
    }, [current]);

    // fetch approval data
    useEffect(() => {
        setLoading(true);
        ApiService.fetchIndividualApprovals(id).then((data) => {
            if (!data) {
                setLoading(false);
                return;
            }
            setRequirements(data?.ap_preFundingRequirements?.split("\n") || []);
            setFolderName(`Clients/${data?.ap_clientId?._id}/Approvals/${id}/`);
            setLoading(false);
        })
    }, [current]);

     // fetch asset list under approval
     useEffect(() => {
        setLoading(true);
        ApiService.fetchPPSAssetList(paymentScheduleId).then(data => {
            const cleanData = data?.data?.map(asset => ({ ...asset.ap_assetId, ap_price: asset.ap_assetId.ap_price / 100 }));
            setAssets(cleanData);
            setAssetSaved(cleanData);
            setLoading(false);
        });
    }, [paymentScheduleId, refresh]);

    // reset selected assets on modal close
    useEffect(() => {
        const arr = [];
        setSelectedAssets(arr);
        setCurrent(0);
        setUpdateMultiple(false);
    }, [open]);
    const hasSelected = selectedAssets.length > 0;

    // reset plating province and address on modal close
    useEffect(() => {
        if (!hasSelected)
            setValues({
                ...values,
                ap_platingprovince: {
                    ...values.ap_platingprovince,
                    value: null,
                    error: '',
                },
                ap_platingaddress: {
                    ...values.ap_platingaddress,
                    value: null,
                    error: '',
                }
            });
    }, [hasSelected]);


    // Close the Modal and Refresh when user goes and come back to the Drawdown Initiation Page
    const onVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          setOpen(false);
          setRefresh(prev => prev + 1)
        }
      };
    
    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);

        return () =>
            document.removeEventListener("visibilitychange", onVisibilityChange);
    }, []);

    // Handle modal close
    const handleCancel = () => {
        setOpen(false);
        setUpdateMultiple(false);
        setRefresh(prevRefresh => prevRefresh + 1);
        setCurrent(0);
    };

    // Handle modal close
    const handleDocumentRequested = () => {
        setDocumentRequested(true);

        const obj = {
            ap_uploadedDocuments: fileList,
            ap_comments: comments
        }
        // Update the details to approval table
        setLoading(true);
        ApiService.updateApproval(id, obj).then((response) => {
            if (response.success) {
                setShowResult(true);
                setLoading(false);
            } else {
                NotificationService.error('Error', 'Something went wrong, try again later');
                setLoading(false);
            }
        })

        // Update pps as confirmed to trigger the creation of DDR and Drawdown
        setLoading(true);
        ApiService.updatePaymentSchedule(paymentScheduleId, { "ap_confirmed": true }).then((response) => {
            if (!response.success) {
                NotificationService.error('Error', 'Something went wrong, try again later');
            }
            setLoading(false);
        })

        // fetch all requested PPS under approval and delete
        ApiService.fetchRequestedPaymentSchedulesList(id, true, paymentScheduleId).then((data) => {
            setLoading(true);
            const idArray = data.map(obj => obj._id);
            idArray.forEach(id => {
                ApiService.cancelRequestedPaymentSchedule(id).then((response) => {
                    if (!response.error)
                        NotificationService.success('Success', "Modification Cancelled Successfully");
                    else
                        NotificationService.error('Error', "There was an error in cancel the modification, please try again later")
                });
            });
            setLoading(false);
        });
    };

    // handle next step movenment
    const handleSkipToUplaod = () => {
        setCurrent(prevValue => prevValue + 1)
    };

    // handle next step movenment
    const handleUpdateMultiple = () => {
        setUpdateMultiple(true);
    };

    // handle prev step movenment
    const handleBack = () => {
        setCurrent(prevValue => prevValue - 1)
        setUpdateMultiple(false);
        setRefresh(prevRefresh => prevRefresh + 1);
    }

    const handleUploadMultipleBack = () => {
        setUpdateMultiple(false);
    }

    // update Plating Province & address to selected ASSETs
    const handlePP = (value) => {
        setValues({
            ...values,
            ap_platingprovince: {
                ...values.ap_platingprovince,
                value: value
            }
        })
    }

    // update Plating Province & address to selected ASSETs
    const handlePA = (value) => {
        setValues({
            ...values,
            ap_platingaddress: {
                ...values.ap_platingaddress,
                value: value
            }
        })
    }

    // update Plating Province & address to selected ASSETs
    const handleAddressFill = () => {
        const tempValues = {
            ...values,
            ap_platingprovince: {
                ...values.ap_platingprovince,
                error: validate.ValidateRequiredField(values.ap_platingprovince.value).errorMessage
            },
            ap_platingaddress: {
                ...values.ap_platingaddress,
                error: validate.ValidateRequiredField(values.ap_platingaddress.value).errorMessage
            }
        }
        setValues(tempValues);

        if (!HasError(tempValues)) {
            for (const i in selectedAssets) {
                const asset = selectedAssets[i]
                ApiService.updateAsset(asset._id, { ap_platingprovince: values.ap_platingprovince.value, ap_platingaddress: values.ap_platingaddress.value }).then((response) => {
                    if (response?.success) {
                        setRefresh(prevRefresh => prevRefresh + 1);

                    } else {
                        NotificationService.error('Error', "There was an error in updating Plating Province, please try again later")
                    }
                })
            }
            const arr = [];
            setSelectedAssets(arr);
        }
    }

    // Steps for modal
    const items = [
        {
            title: (
                <div className={styles["Step1_Title"]} >
                    Review and Edit
                </div >
            ),
            content: <>
                <DocumentRequestConstants.Step1Component
                    assets={assets}
                    setAssets={setAssets}
                    assetSaved={assetSaved}
                    isYardAddressRequired={isYardAddressRequired}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    updateMultiple={updateMultiple}
                    setUpdateMultiple={setUpdateMultiple}
                    selectedAssets={selectedAssets}
                    setSelectedAssets={setSelectedAssets}
                    values={values}
                    setValues={setValues}
                    hasSelected={hasSelected}
                    handlePP={handlePP}
                    handlePA={handlePA}
                    handleAddressFill={handleAddressFill}
                    handleUploadMultipleBack={handleUploadMultipleBack}
                    handleUpdateMultiple={handleUpdateMultiple}
                    handleSkipToUplaod={handleSkipToUplaod}
                    rowSelection={rowSelection}
                    errors={errors}
                    setErrors={setErrors}
                />
            </>,
            icon: current === 0 ? <DocumentRequestStep1 /> : <DocumentRequestStep1Filled />
        },
        {
            title: (
                <div className={styles["Step2_Title"]}>
                    Upload Documents
                </div>
            ),
            content: <>
                <div ref={div2Ref} style={{ minHeight: "68vh" }}>
                    {!documentRequested ?
                        <DocumentRequestConstants.Step2Component
                            requirements={requirements}
                            folderName={folderName}
                            fileList={fileList}
                            setFileList={setFileList}
                            comments={comments}
                            setComments={setComments}
                            handleDocumentRequested={handleDocumentRequested}
                            handleBack={handleBack}
                        /> :
                        <div className={styles["modelDetailsResult"]}>
                            <DocumentRequestConstants.Step3Component
                                showResult={showResult}
                            />
                        </div>
                    }
                </div>
            </>,
            icon: current === 0 ? <DocumentRequestStep2Disabled /> : !showResult ? <DocumentRequestStep2 /> : <DocumentRequestStep2Filled />
        }
    ];

    return (
        <>
            <div className="modalTest">
                <Modal
                    className={styles["modal"]}
                    open={open}
                    width='1768px'
                    centered
                    footer={null}
                    closable={false}
                    getContainer=".AppTheme"
                >
                    <div className={styles["modalContent"]}>
                        <div className={styles["modalTitle"]}>
                            <CloseOutlined style={{ fontSize: 'x-large' }}
                                onClick={handleCancel} />
                            <div className={styles["modalSteps"]}>
                                <div>Audit Asset List</div>
                            </div>
                        </div>
                        <div id="RequestDocsSteps" style={{ marginTop: '1%' }}>
                            <Steps
                                current={current}
                                labelPlacement='vertical'
                                items={items}
                                className={styles["customSteps"]}
                            />
                            <div>{items[current]?.content}</div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};