import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Calculator(open) {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["Calculator"]}>
            <mask id="path-1-inside-1_2530_18472" fill="white">
                <path d="M0.5 5.23157C0.5 2.47014 2.73858 0.231567 5.5 0.231567H75.5C78.2614 0.231567 80.5 2.47014 80.5 5.23157V75.2316C80.5 77.993 78.2614 80.2316 75.5 80.2316H5.5C2.73858 80.2316 0.5 77.993 0.5 75.2316V5.23157Z" />
            </mask>
            <path d="M0.5 5.23157C0.5 2.47014 2.73858 0.231567 5.5 0.231567H75.5C78.2614 0.231567 80.5 2.47014 80.5 5.23157V75.2316C80.5 77.993 78.2614 80.2316 75.5 80.2316H5.5C2.73858 80.2316 0.5 77.993 0.5 75.2316V5.23157Z" fillOpacity="0.5" className={styles[open ? "Calculator_BG_Selected" : "Calculator_BG"]} />
            <path d="M0.5 0.231567H80.5H0.5ZM80.5 75.2316C80.5 82.4113 74.6797 88.2316 67.5 88.2316H13.5C6.3203 88.2316 0.5 82.4113 0.5 75.2316V72.2316C0.5 72.2316 2.73858 72.2316 5.5 72.2316H75.5C78.2614 72.2316 80.5 72.2316 80.5 72.2316V75.2316ZM0.5 80.2316V0.231567V80.2316ZM80.5 0.231567V80.2316V0.231567Z" mask="url(#path-1-inside-1_2530_18472)" className={styles[open ? "Calculator_Border_Selected" : "Calculator_Border"]} />
            <rect x="29" y="27.2316" width="23" height="26" rx="5" strokeWidth="3" className={styles["Calculator_Stroke"]} />
            <rect x="34.0957" y="32.287" width="12.8088" height="4.43726" rx="2" strokeWidth="3" className={styles["Calculator_Stroke"]} />
            <path d="M36.4092 41.9271C36.4092 42.8636 35.6501 43.6227 34.7136 43.6227C33.7772 43.6227 33.0181 42.8636 33.0181 41.9271C33.0181 40.9907 33.7772 40.2316 34.7136 40.2316C35.6501 40.2316 36.4092 40.9907 36.4092 41.9271Z" className={styles["Calculator_Fill"]} />
            <path d="M36.4094 46.9991C36.4094 47.9356 35.6503 48.6947 34.7139 48.6947C33.7774 48.6947 33.0183 47.9356 33.0183 46.9991C33.0183 46.0627 33.7774 45.3036 34.7139 45.3036C35.6503 45.3036 36.4094 46.0627 36.4094 46.9991Z" className={styles["Calculator_Fill"]} />
            <path d="M42.1958 41.9271C42.1958 42.8636 41.4367 43.6227 40.5002 43.6227C39.5638 43.6227 38.8047 42.8636 38.8047 41.9271C38.8047 40.9907 39.5638 40.2316 40.5002 40.2316C41.4367 40.2316 42.1958 40.9907 42.1958 41.9271Z" className={styles["Calculator_Fill"]} />
            <path d="M42.1963 46.9991C42.1963 47.9356 41.4372 48.6947 40.5007 48.6947C39.5643 48.6947 38.8052 47.9356 38.8052 46.9991C38.8052 46.0627 39.5643 45.3036 40.5007 45.3036C41.4372 45.3036 42.1963 46.0627 42.1963 46.9991Z" className={styles["Calculator_Fill"]} />
            <path d="M47.9819 41.9271C47.9819 42.8636 47.2228 43.6227 46.2864 43.6227C45.3499 43.6227 44.5908 42.8636 44.5908 41.9271C44.5908 40.9907 45.3499 40.2316 46.2864 40.2316C47.2228 40.2316 47.9819 40.9907 47.9819 41.9271Z" className={styles["Calculator_Fill"]} />
            <path d="M47.9822 46.9991C47.9822 47.9356 47.2231 48.6947 46.2866 48.6947C45.3502 48.6947 44.5911 47.9356 44.5911 46.9991C44.5911 46.0627 45.3502 45.3036 46.2866 45.3036C47.2231 45.3036 47.9822 46.0627 47.9822 46.9991Z" className={styles["Calculator_Fill"]} />
        </svg>
    )
}

export default function CalculatorIcon({ open }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        display: 'flex',
        width: '68.5px',
        height: '60px',
    };
    return <Icon component={() => Calculator(open)} style={themeColorVariables} />;
}