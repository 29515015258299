import { useEffect, useState } from "react";

import { Button, Drawer, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import InputLayout from "../InputLayout";
import utils from "../../utils";
import HasError from "../../utils/HasErrors";
import validate from "../../utils/Validations";

import contactsConstant from "./constants";

import styles from "./index.module.css";

export default function CreateContact({ entity, entityId, refresh, setRefresh }) {

    const [openContact, setOpenContact] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleDrawer = () => {
        setLoading(false);
        setOpenContact(!openContact);
    }

    const [contactData, setContactData] = useState({});
    const [invokeFieldValidation, setInvokeFieldValidation] = useState(false);

    const [primaryExists, setPrimaryExists] = useState(false);
    useEffect(() => {
        setLoading(true);
        if (entity === 'agent')
            ApiService.fetchAgentContacts(entityId).then((data) => {
                setPrimaryExists(utils.hasPrimaryContact(data));
                setContactData(contactsConstant.generateContactFields(entity, utils.hasPrimaryContact(data)));
                setLoading(false);
            })
        if (entity === 'client')
            ApiService.fetchClientContacts(entityId).then((data) => {
                setPrimaryExists(utils.hasPrimaryContact(data));
                setContactData(contactsConstant.generateContactFields(entity, utils.hasPrimaryContact(data)))
                setLoading(false);
            })
    }, [entityId, openContact]);

    // Event Handlers
    const handleFirstName = (e) => {
        setContactData({
            ...contactData,
            ap_firstName: {
                ...contactData.ap_firstName,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e.target.value) : '',
            },
        })
    }

    const handleLastName = (e) => {
        setContactData({
            ...contactData,
            ap_lastName: {
                ...contactData.ap_lastName,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e.target.value) : '',
            },
        })
    }

    const handleEmail = (e) => {
        setContactData({
            ...contactData,
            ap_email: {
                ...contactData.ap_email,
                value: e.target.value,
                error: invokeFieldValidation ? (validate.RequiredField(e.target.value) ? validate.RequiredField(e.target.value) : (!validate.EmailValidation(e.target.value) ? 'Invalid Email Address' : '')) : '',
            },
        })
    }

    const handleTelephone = (e) => {
        setContactData({
            ...contactData,
            ap_telephone: {
                ...contactData.ap_telephone,
                value: validate.FormatTelephone(e.target.value).input,
                error: invokeFieldValidation ? validate.FormatTelephone(e.target.value).error : '',
            },
        })
    }

    const handleAddress = (e) => {
        setContactData({
            ...contactData,
            ap_address: {
                ...contactData.ap_address,
                value: e.target.value,
                error: '',
            },
        })
    }

    const handleCity = (e) => {
        setContactData({
            ...contactData,
            ap_city: {
                ...contactData.ap_city,
                value: e.target.value,
                error: '',
            },
        })
    }

    const handleStateOrProvince = (value) => {
        setContactData({
            ...contactData,
            ap_stateOrProvince: {
                ...contactData.ap_stateOrProvince,
                value: value,
                error: '',
            },
        })
    }

    const handlePostalZipCode = (e) => {
        setContactData({
            ...contactData,
            ap_postalZipCode: {
                ...contactData.ap_postalZipCode,
                value: (contactData.ap_country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).input : validate.FormatCanadianPostalCode(e.target.value).input,
                error: (contactData.ap_country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).error : validate.FormatCanadianPostalCode(e.target.value).error,
            },
        })
    }

    const handleCountry = (value) => {
        setContactData({
            ...contactData,
            ap_country: {
                ...contactData.ap_country,
                value: value,
                error: '',
            },
            ap_stateOrProvince: {
                ...contactData.ap_stateOrProvince,
                value: '',
                error: '',
            },
            ap_postalZipCode: {
                ...contactData.ap_postalZipCode,
                value: '',
                error: '',
            },
        })
    }

    const handlePrimaryContact = (value) => {
        const op = !primaryExists || value
        setContactData({
            ...contactData,
            ap_primaryContact: {
                ...contactData.ap_primaryContact,
                value: op,
            },
            ap_emailNotification: {
                ...contactData.ap_emailNotification,
                value: op || contactData.ap_emailNotification.value,
            },
            ap_billingNotification: {
                ...contactData.ap_billingNotification,
                value: op || contactData.ap_billingNotification.value,
            }
        })
    }

    const handleSMSNotification = (value) => {
        setContactData({
            ...contactData,
            ap_smsNotification: {
                ...contactData.ap_smsNotification,
                value: value,
            }
        })
    }

    const handleWhatsAppNotification = (value) => {
        setContactData({
            ...contactData,
            ap_whatsAppNotification: {
                ...contactData.ap_whatsAppNotification,
                value: value,
            }
        })
    }

    const handleEmailNotification = (value) => {
        setContactData({
            ...contactData,
            ap_emailNotification: {
                ...contactData.ap_emailNotification,
                value: contactData.ap_primaryContact.value || value,
            }
        })
    }

    const handleBillingNotification = (value) => {
        setContactData({
            ...contactData,
            ap_billingNotification: {
                ...contactData.ap_billingNotification,
                value: contactData.ap_primaryContact.value || value,
            }
        })
    }

    // const handleDrivingExperience = (e) => {
    //     setContactData({
    //         ...contactData,
    //         ap_drivingExperienceYears: {
    //             ...contactData.ap_drivingExperienceYears,
    //             value: Number(e.target.value) >= 0 ? e.target.value : null,
    //         }
    //     })
    // }

    // const handleEverBankrupt = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_everBankrupt: {
    //             ...contactData.ap_everBankrupt,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleEverRepossessed = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_everRepossessed: {
    //             ...contactData.ap_everRepossessed,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleDefendantInLegalAction = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_defendantInLegalAction: {
    //             ...contactData.ap_defendantInLegalAction,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleHomeOwner = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_homeowner: {
    //             ...contactData.ap_homeowner,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleResidentForTwoYear = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_residentAtleast2Years: {
    //             ...contactData.ap_residentAtleast2Years,
    //             value: checked,
    //         }
    //     });
    // }

    const handleCreateContact = () => {
        setLoading(true);
        setInvokeFieldValidation(true);

        const tempData = {
            ...contactData,
            ap_firstName: {
                ...contactData.ap_firstName,
                error: validate.RequiredField(contactData.ap_firstName.value)
            },
            ap_lastName: {
                ...contactData.ap_lastName,
                error: validate.RequiredField(contactData.ap_lastName.value)
            },
            ap_email: {
                ...contactData.ap_email,
                error: (validate.RequiredField(contactData.ap_email.value) ? validate.RequiredField(contactData.ap_email.value) : (!validate.EmailValidation(contactData.ap_email.value) ? 'Invalid Email Address' : ''))
            },
            ap_telephone: {
                ...contactData.ap_telephone,
                error: validate.FormatTelephone(contactData.ap_telephone.value).error
            },
            ap_postalZipCode: {
                ...contactData.ap_postalZipCode,
                error: (contactData.ap_country.value === 'USA') ? validate.FormatAmericanZipCode(contactData.ap_postalZipCode.value).error : validate.FormatCanadianPostalCode(contactData.ap_postalZipCode.value).error,
            }
        };

        setContactData(tempData);

        if (!HasError(tempData)) {
            if (entity === 'agent') {
                setLoading(true);
                ApiService.fetchAgentContacts(entityId).then((data) => {
                    if (contactData.ap_primaryContact.value) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].ap_primaryContact) {
                                const updatedData = { ap_primaryContact: false }
                                ApiService.updateContact(data[i]._id, updatedData);
                            }
                        }
                    }

                    const createData = contactsConstant.generateFinalData(contactData, entity, entityId);
                    ApiService.createContact(createData).then((response) => {
                        if (response.success) {
                            NotificationService.success("Success", "Contact Created Successfully");
                            setOpenContact(!openContact);
                            setRefresh(prevRefresh => prevRefresh + 1);
                        } else NotificationService.error("Error", response.error)
                    })
                    setLoading(false);
                })
            }
            if (entity === 'client') {
                ApiService.fetchClientContacts(entityId).then((data) => {
                    setLoading(true);
                    if (contactData.ap_primaryContact.value) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].ap_primaryContact) {
                                const updatedData = { ap_primaryContact: false }
                                ApiService.updateContact(data[i]._id, updatedData);
                            }
                        }
                    }
                    const createData = contactsConstant.generateFinalData(contactData, entity, entityId);
                    ApiService.createContact(createData).then((response) => {
                        if (response.success) {
                            NotificationService.success("Success", "Contact Created Successfully");
                            setOpenContact(!openContact);
                            setRefresh(prevRefresh => prevRefresh + 1);
                        } else {
                            NotificationService.error("Error", response.error);
                        }
                    })
                    setLoading(false);
                })
            }

        } else {
            NotificationService.error("Error", "Please resolve all the errors on the form.");
        }
        setLoading(false);
    }

    // Form Fields
    const infoForm = {
        ap_firstName: {
            ...contactData.ap_firstName,
            eventHandler: handleFirstName,
        },
        ap_lastName: {
            ...contactData.ap_lastName,
            eventHandler: handleLastName,
        },
        ap_email: {
            ...contactData.ap_email,
            eventHandler: handleEmail,
        },
        ap_telephone: {
            ...contactData.ap_telephone,
            eventHandler: handleTelephone,
        },
        ap_address: {
            ...contactData.ap_address,
            eventHandler: handleAddress,
        },
        ap_city: {
            ...contactData.ap_city,
            eventHandler: handleCity,
        },
        ap_stateOrProvince: {
            ...contactData.ap_stateOrProvince,
            eventHandler: handleStateOrProvince,
        },
        ap_postalZipCode: {
            ...contactData.ap_postalZipCode,
            eventHandler: handlePostalZipCode,
        },
        ap_country: {
            ...contactData.ap_country,
            eventHandler: handleCountry,
        },
    }

    const primaryContactField = {
        ap_primaryContact: {
            ...contactData.ap_primaryContact,
            eventHandler: handlePrimaryContact,
        },
    }

    const drivingExperience = {
        // ap_drivingExperienceYears: {
        //     ...contactData.ap_drivingExperienceYears,
        //     eventHandler: handleDrivingExperience,
        // },
    }

    const notifForm = {
        ap_smsNotification: {
            ...contactData.ap_smsNotification,
            eventHandler: handleSMSNotification
        },
        ap_whatsAppNotification: {
            ...contactData.ap_whatsAppNotification,
            eventHandler: handleWhatsAppNotification,
        },
        ap_emailNotification: {
            ...contactData.ap_emailNotification,
            eventHandler: handleEmailNotification,
        },
        ap_billingNotification: {
            ...contactData.ap_billingNotification,
            eventHandler: handleBillingNotification,
        },
        // ap_everBankrupt: {
        //     ...contactData.ap_everBankrupt,
        //     eventHandler: handleEverBankrupt,
        // },
        // ap_everRepossessed: {
        //     ...contactData.ap_everRepossessed,
        //     eventHandler: handleEverRepossessed,
        // },
        // ap_defendantInLegalAction: {
        //     ...contactData.ap_defendantInLegalAction,
        //     eventHandler: handleDefendantInLegalAction,
        // },
        // ap_homeowner: {
        //     ...contactData.ap_homeowner,
        //     eventHandler: handleHomeOwner,
        // },
        // ap_residentAtleast2Years: {
        //     ...contactData.ap_residentAtleast2Years,
        //     eventHandler: handleResidentForTwoYear,
        // },
    }

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleDrawer}
                className={styles["CreateContact_Button"]}
            >Contact</Button>

            <Drawer
                width="550px"
                title="Add Contact"
                open={openContact}
                onClose={handleDrawer}
                getContainer=".AppTheme"
            >
                <Spin spinning={loading}>
                    <InputLayout
                        data={infoForm}
                        layout="vertical"
                    />

                    <InputLayout
                        data={drivingExperience}
                        layout="vertical"
                    />

                    <InputLayout
                        data={primaryContactField}
                        layout="horizontal"
                    />

                    {(entity === 'agent') ?
                        <>
                            <b>Please select which notifications you would like to receive from this contact:</b>
                            <span style={{ fontSize: '12px', color: 'gray' }}>
                                <br />
                                (Please note: Email and Billing notifications will automatically be turned on for Primary Contact)
                            </span>
                        </> : <></>
                    }

                    <InputLayout
                        data={notifForm}
                        layout="horizontal"
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            onClick={handleCreateContact}
                        >Done</Button>
                    </div>
                </Spin>

            </Drawer>
        </>
    );

}