import utils from "../../utils";

export default {
    generatePpsData: generatePpsData,
    generatePpsDraftData: generatePpsDraftData,
    generateModifyPaymentScheduleData: generateModifyPaymentScheduleData,
    monthlyPaymentField: monthlyPaymentField,
    clientRateField: clientRateField
}

function monthlyPaymentField(updatedPaymentFields) {
    return {
        monthlyPayment: {
            value: utils.TransformDBCurrency(updatedPaymentFields?.ap_regularMonthlyPayment),
            error: '',
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Monthly Payment',
            prefix: '$',
        }
    }
}

function clientRateField(updatedPaymentFields) {
    return {
        clientRate: {
            value: `${Number(updatedPaymentFields?.ap_clientRate).toFixed(2)} %`,
            error: '',
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Client Rate',
        }
    }
}


function generateModifyPaymentScheduleData() {
    return ({
        term: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Term',
            suffix: 'months',
        },
        downPayment: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Down Payment',
            prefix: '$',
        },
        commission: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Commission',
            prefix: '$',
        }
    });
}

function generatePpsData(existingPPSData) {
    const ppsData = {
        "lockedIn": "commissionAmount", //['commissionAmount', 'regularPaymentAmount', 'annualClientRate']
        "generateHTML": false,
        "commissionType": existingPPSData.ap_commissionType, // ['%invoice', '%netFinanced', '%spread']
        "maxCommissionPercent": Number(existingPPSData.ap_maxCommissionPercent),   // we don't need this; can be removed after discussion
        "maximumYield": existingPPSData.ap_maximumYield || 0,
        "maxCommissionAmount": existingPPSData.ap_maxCommissionAmount / 100,
        "commissionAmount": existingPPSData.ap_actualCommission / 100,
        "downPaymentType": existingPPSData.ap_downPayment, // ['First','FirstAndLast','5%','10%','15%','20%','Other','None']
        "downPaymentAmount": existingPPSData.ap_downPaymentAmount / 100,
        "bonusDownPaymentAmount": existingPPSData.ap_bonusDownPaymentAmount / 100 || 0,
        "baseAmount": existingPPSData.ap_baseAmount / 100,
        "docFeeToClient": existingPPSData.ap_totalDocFeeToClient / 100 || 0,
        "otherFees": existingPPSData.ap_otherFees / 100 || 0,
        "annualCostOfFunds": Number(existingPPSData.ap_costOfFunds),
        "annualClientRate": Number(existingPPSData.ap_clientRate) || 0,
        "residualPurchaseOption": existingPPSData.ap_purchaseOption / 100 || 0,
        "initiationDate": existingPPSData.ap_initiationDate,
        "downPaymentDate": existingPPSData.ap_downPaymentDate,
        "firstRegularPaymentDate": existingPPSData.ap_firstRegularPaymentDate,
        "daysInYear": Number(existingPPSData.ap_daysInYear),
        "term": Number(existingPPSData.ap_term),
        "regularPaymentAmount": 0
    };

    if (existingPPSData.ap_specialPayments) {
        ppsData["specialPayments"] = {};
        if (existingPPSData.ap_taxBalloon) {
            let taxBallon = {};
            taxBallon["taxAmount"] = existingPPSData.ap_taxAmount / 100;
            taxBallon["taxBalloonPaymentNumber"] = Number(existingPPSData.ap_taxBalloonAmountNumber);
            ppsData.specialPayments["taxBallon"] = taxBallon;
        }

        if (existingPPSData.ap_acceleratedPayments) {
            let acceleratedPayments = {};
            acceleratedPayments["acceleratedMonthlyBonus"] = existingPPSData.ap_acceleratedMonthlyBonus / 100;
            acceleratedPayments["acceleratedTerm"] = existingPPSData.ap_acceleratedTerm;
            ppsData.specialPayments["acceleratedPayments"] = acceleratedPayments;
        } if (existingPPSData.ap_skipPayments) {
            let fixedPayments = {};
            fixedPayments["paymentNumbers"] = existingPPSData.ap_skipPaymentPaymentNumbers.split(',').map(item => Number(item));
            fixedPayments["fixedAmount"] = existingPPSData.ap_skipAmount / 100;
            ppsData.specialPayments["fixedPayments"] = fixedPayments;
        } else if (existingPPSData.ap_interestOnlyPayment) {
            let interestOnlyPayments = {};
            interestOnlyPayments["paymentNumbers"] = existingPPSData.ap_interestOnlyPaymentNumbers.split(',').map(item => Number(item));
            ppsData.specialPayments["interestOnlyPayments"] = interestOnlyPayments;
        }
    }
    return ppsData;
}

function generatePpsDraftData(existingPPSData, paymentScheduleBalancedData, lockType) {

    const ppsDraftData = {
        "ap_expectedLockedIn": lockType,
        "ap_isDraft": true,
        "ap_emailType": "modified",
        "ap_scheduleRequested": true,
        "ap_draftReferredFrom": existingPPSData._id,
        "ap_approvalId": existingPPSData.ap_approvalId,
        "ap_financingType": existingPPSData.ap_financingType,
        "ap_assetDescription": existingPPSData.ap_assetDescription,
        "ap_commissionType": existingPPSData.ap_commissionType,
        "ap_maxCommissionPercent": existingPPSData.ap_maxCommissionPercent,
        "ap_maxCommissionAmount": existingPPSData.ap_maxCommissionAmount,
        "ap_actualCommission": paymentScheduleBalancedData.completeValueSet.commissionAmount * 100,
        "ap_baseAmount": existingPPSData.ap_baseAmount,
        "ap_term": paymentScheduleBalancedData.completeValueSet.term + 1,
        "ap_termExclDown": existingPPSData.ap_termExclDown,
        "ap_costOfFunds": existingPPSData.ap_costOfFunds,
        "ap_monthlyCostOfFunds": existingPPSData.ap_monthlyCostOfFunds,
        "ap_dailyCostOfFunds": existingPPSData.ap_dailyCostOfFunds,
        "ap_clientRate": paymentScheduleBalancedData.calculatedValues.annualClientRate,
        "ap_monthlyClientRate": paymentScheduleBalancedData.calculatedValues.monthlyClientRate,
        "ap_dailyClientRate": paymentScheduleBalancedData.calculatedValues.dailyClientRate,
        "ap_totalDocFeeToClient": existingPPSData.ap_totalDocFeeToClient,
        "ap_otherFees": existingPPSData.ap_otherFees,
        "ap_downPayment": paymentScheduleBalancedData.completeValueSet.downPaymentType,
        "ap_downPaymentAmount": paymentScheduleBalancedData.completeValueSet.downPaymentAmount * 100,
        "ap_regularMonthlyPayment": paymentScheduleBalancedData.calculatedValues.regularPaymentAmount * 100,
        "ap_brokerDownPaymentInterest": existingPPSData.ap_brokerDownPaymentInterest,
        "ap_brokerFirstPaymentInterest": existingPPSData.ap_brokerFirstPaymentInterest,
        "ap_clientDownPaymentInterest": existingPPSData.ap_clientDownPaymentInterest,
        "ap_clientFirstPaymentInterest": existingPPSData.ap_clientFirstPaymentInterest,
        "ap_totalOfPayments": existingPPSData.ap_totalOfPayments,
        "ap_initiationDate": existingPPSData.ap_initiationDate,
        "ap_downPaymentDate": existingPPSData.ap_downPaymentDate,
        "ap_firstRegularPaymentDate": existingPPSData.ap_firstRegularPaymentDate,
        "ap_scheduleEndDate": existingPPSData.ap_scheduleEndDate,
        "ap_daysInYear": existingPPSData.ap_daysInYear,
        "ap_specialPayments": existingPPSData.ap_specialPayments,

        "ap_taxBalloon": existingPPSData.ap_taxBalloon,
        "ap_taxRatePercent": existingPPSData.ap_taxRatePercent,
        "ap_taxAmount": existingPPSData.ap_taxAmount,
        "ap_taxBalloonAmountNumber": existingPPSData.ap_taxBalloonAmountNumber,
        "ap_taxBalloonMonthYear": existingPPSData.ap_taxBalloonMonthYear,

        "ap_acceleratedPayments": existingPPSData.ap_acceleratedPayments,
        "ap_acceleratedAmountPercent": existingPPSData.ap_acceleratedAmountPercent,
        "ap_acceleratedAmount": existingPPSData.ap_acceleratedAmount,
        "ap_acceleratedMonthlyBonus": existingPPSData.ap_acceleratedMonthlyBonus,
        "ap_acceleratedTerm": existingPPSData.ap_acceleratedTerm,

        "ap_skipPayments": existingPPSData.ap_skipPayments,
        "ap_skipMonths": existingPPSData.ap_skipMonths,
        "ap_skipAmount": existingPPSData.ap_skipAmount,
        "ap_skipFullTerm": existingPPSData.ap_skipFullTerm,
        "ap_skipPaymentStartDate": existingPPSData.ap_skipPaymentStartDate,
        "ap_skipPaymentEndDate": existingPPSData.ap_skipPaymentEndDate,
        "ap_skipPaymentPaymentNumbers": paymentScheduleBalancedData?.completeValueSet?.specialPayments?.fixedPayments?.paymentNumbers?.join(),

        "ap_interestOnlyPayment": existingPPSData.ap_interestOnlyPayment,
        "ap_interestOnlyMonths": existingPPSData.ap_interestOnlyMonths,
        "ap_interestOnlyFullTerm": existingPPSData.ap_interestOnlyFullTerm,
        "ap_interestOnlyStartDate": existingPPSData.ap_interestOnlyStartDate,
        "ap_interestOnlyEndDate": existingPPSData.ap_interestOnlyEndDate,
        "ap_interestOnlyPaymentNumbers": paymentScheduleBalancedData?.completeValueSet?.specialPayments?.interestOnlyPayments?.paymentNumbers?.join(),
    };

    return ppsDraftData;
}
