import dayjs from "dayjs";
import validate from "../../utils/Validations";
import constants from "../../constants";
import utils from "../../utils";

export default function generateApprovalDetailsFields(data) {
    const approvalDataFields = {
        ap_client: {
            value: data?.ap_clientId?.ap_businessName,
            error: validate.ValidateRequiredField(data?.ap_clientId?.ap_businessName).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Client',
            eventHandler: () => { },
        },
        ap_salesCoordinator: {
            value: data?.ap_salesCoordinatorId?.ap_name,
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Sales Co-ordinator',
            eventHandler: () => { },
        },
        ap_creditAnalyst: {
            value: data?.ap_creditAnalystId?.ap_fullName,
            error: validate.ValidateRequiredField(data?.ap_creditAnalystId?.ap_fullName).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Credit',
            eventHandler: () => { },
        },
        ap_lender: {
            value: data?.ap_lenderId?.ap_abbreviation || data?.ap_lenderId?.ap_name,
            error: validate.ValidateRequiredField(data?.ap_lenderId?.ap_abbreviation || data?.ap_lenderId?.ap_name).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Lender',
            eventHandler: () => { },
        },
        ap_financingType: {
            value: data.ap_financingType,
            error: validate.ValidateRequiredField(data.ap_financingType).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'dropdown',
            label: 'Financing Type',
            dropdownContent: constants.FINANCE_TYPES,
            eventHandler: () => { },
        },
        ap_submittedDate: {
            value: dayjs(new Date(data.ap_submittedDate)),
            error: validate.ValidateRequiredField(data.ap_submittedDate).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'date',
            label: 'Submitted Date',
            eventHandler: () => { },
        },
        ap_submissionType: {
            value: data.ap_submissionType,
            error: validate.ValidateRequiredField(data.ap_submissionType).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'dropdown',
            label: 'Submission Type',
            dropdownContent: constants.SUBMISSION_TYPES,
            eventHandler: () => { },
        },
        ap_approvalDate: {
            value: dayjs(new Date(data.ap_approvalDate)),
            error: validate.ValidateRequiredField(data.ap_approvalDate).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'date',
            label: 'Approval Date',
            eventHandler: () => { },
        },
        ap_expiryDate: {
            value: dayjs(new Date(data.ap_expiryDate)),
            error: validate.ValidateRequiredField(data.ap_expiryDate).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'date',
            label: 'Expiry Date',
            eventHandler: () => { },
        },
        ap_approvedAmount: {
            value: utils.parseDBCurrency(data.ap_approvedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            error: validate.ValidateRequiredField(data.ap_approvedAmount).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            label: 'Approved Amount',
            eventHandler: () => { },
        },
        ap_term: {
            value: data.ap_term,
            error: validate.ValidateRequiredField(data.ap_term).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'text',
            suffix: "months",
            label: 'Term',
            eventHandler: () => { },
        },
        ap_downPayment: {
            value: data.ap_downPayment,
            error: validate.ValidateRequiredField(data.ap_downPayment).errorMessage,
            hidden: false,
            required: true,
            disabled: true,
            inputType: 'dropdown',
            label: 'Down Payment',
            dropdownContent: constants.DOWN_PAYMENT_TYPES,
            eventHandler: () => { },
        },
        ap_downPaymentAmount: {
            value: data.ap_downPaymentAmount,
            error: '',
            hidden: !data.ap_downPaymentAmount,
            required: false,
            disabled: true,
            inputType: 'text',
            addonBefore: "$",
            label: 'Down Payment Amount',
            eventHandler: () => { },
        },
        ap_costOfFunds: {
            value: data.ap_costOfFunds + "%",
            error: '',
            hidden: !data.ap_costOfFunds,
            required: false,
            disabled: true,
            inputType: 'text',
            label: 'Cost of Funds',
            eventHandler: () => { },
        },
        ap_preFundingRequirements: {
            value: data.ap_preFundingRequirements,
            error: '',
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'textarea',
            label: 'Pre-Funding Requirements',
            eventHandler: () => { },
        },
        ap_approvalNotes: {
            value: data.ap_approvalNotes,
            error: validate.ValidateRequiredField(data.ap_approvalNotes).errorMessage,
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'textarea',
            label: 'Approval Notes',
            eventHandler: () => { },
        },
    }
    return approvalDataFields;
}