import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Other = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M28.6 32.25C28.6 33.2842 28.9504 34.1517 29.6512 34.8525C30.352 35.5533 31.2183 35.9024 32.25 35.9H61.45C62.4842 35.9 63.3516 35.5496 64.0524 34.8488C64.7533 34.148 65.1024 33.2817 65.1 32.25C65.1 31.2158 64.7496 30.3484 64.0488 29.6476C63.348 28.9468 62.4817 28.5976 61.45 28.6H32.25C31.2158 28.6 30.3484 28.9504 29.6476 29.6512C28.9468 30.352 28.5976 31.2183 28.6 32.25Z" fill="white" />
            <path d="M28.6 46.85C28.6 47.8842 28.9504 48.7517 29.6512 49.4525C30.352 50.1533 31.2183 50.5024 32.25 50.5H50.865C52.8117 48.675 54.9871 47.1542 57.3912 45.9375C59.7953 44.7208 62.3649 43.8996 65.1 43.4738C64.5525 43.3521 63.9442 43.2754 63.275 43.2438C62.6058 43.2122 61.9975 43.1976 61.45 43.2H32.25C31.2158 43.2 30.3484 43.5504 29.6476 44.2512C28.9468 44.952 28.5976 45.8183 28.6 46.85Z" fill="white" />
            <path d="M28.6 61.45C28.6 62.4842 28.9504 63.3516 29.6512 64.0524C30.352 64.7533 31.2183 65.1024 32.25 65.1H43.4738C43.6563 63.8225 43.9458 62.5754 44.3425 61.3588C44.7391 60.1421 45.1795 58.9558 45.6638 57.8H32.25C31.2158 57.8 30.3484 58.1504 29.6476 58.8512C28.9468 59.552 28.5976 60.4183 28.6 61.45Z" fill="white" />
            <path d="M21.3 79.7C19.2925 79.7 17.5734 78.9846 16.1426 77.5538C14.7118 76.123 13.9976 74.4051 14 72.4V21.3C14 19.2925 14.7154 17.5734 16.1462 16.1426C17.577 14.7118 19.2949 13.9976 21.3 14H72.4C74.4075 14 76.1266 14.7154 77.5574 16.1462C78.9883 17.577 79.7024 19.2949 79.7 21.3V45.755C78.5442 45.2075 77.3579 44.7354 76.1413 44.3388C74.9246 43.9422 73.6775 43.6538 72.4 43.4738V21.3H21.3V43.2V43.4738V68.75V72.4H43.3825C43.565 73.7383 43.8546 75.0158 44.2512 76.2325C44.6478 77.4492 45.1187 78.605 45.6638 79.7H21.3Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M68.75 87C63.7008 87 59.3963 85.22 55.8363 81.66C52.2763 78.1001 50.4976 73.7967 50.5 68.75C50.5 63.7008 52.28 59.3963 55.84 55.8363C59.3999 52.2763 63.7033 50.4976 68.75 50.5C73.7992 50.5 78.1037 52.28 81.6637 55.84C85.2237 59.3999 87.0024 63.7033 87 68.75C87 73.7992 85.22 78.1037 81.66 81.6637C78.1001 85.2237 73.7967 87.0024 68.75 87ZM60.6287 70.6662C60.0812 71.2137 59.4425 71.4875 58.7125 71.4875C57.9825 71.4875 57.3438 71.2137 56.7963 70.6662C56.2488 70.1187 55.975 69.48 55.975 68.75C55.975 68.02 56.2488 67.3813 56.7963 66.8338C57.3438 66.2863 57.9825 66.0125 58.7125 66.0125C59.4425 66.0125 60.0812 66.2863 60.6287 66.8338C61.1763 67.3813 61.45 68.02 61.45 68.75C61.45 69.48 61.1763 70.1187 60.6287 70.6662ZM70.6662 70.6662C70.1187 71.2137 69.48 71.4875 68.75 71.4875C68.02 71.4875 67.3813 71.2137 66.8338 70.6662C66.2863 70.1187 66.0125 69.48 66.0125 68.75C66.0125 68.02 66.2863 67.3813 66.8338 66.8338C67.3813 66.2863 68.02 66.0125 68.75 66.0125C69.48 66.0125 70.1187 66.2863 70.6662 66.8338C71.2137 67.3813 71.4875 68.02 71.4875 68.75C71.4875 69.48 71.2137 70.1187 70.6662 70.6662ZM80.7038 70.6662C80.1562 71.2137 79.5175 71.4875 78.7875 71.4875C78.0575 71.4875 77.4188 71.2137 76.8713 70.6662C76.3237 70.1187 76.05 69.48 76.05 68.75C76.05 68.02 76.3237 67.3813 76.8713 66.8338C77.4188 66.2863 78.0575 66.0125 78.7875 66.0125C79.5175 66.0125 80.1562 66.2863 80.7038 66.8338C81.2513 67.3813 81.525 68.02 81.525 68.75C81.525 69.48 81.2513 70.1187 80.7038 70.6662Z" fill="white" />
        </svg>
    );
};

export default function OtherIcon(props) {
    return <Icon component={Other} {...props} />
}