import { useState } from "react";
import { ConfigProvider, Divider, Select } from "antd";

import JoinUsPhone from "../../assets/images/icons/JoinUsPhone";
import JoinUsEmail from "../../assets/images/icons/JoinUsEmail";
import constants from "../../constants";

import styles from "./index.module.css";

export default function JoinUsContactPreference({ timePreferences, setTimePreferences, contactPreferences, setContactPreferences }) {

    const [phoneHovered, setPhoneHovered] = useState(false);
    const [emailHovered, setEmailHovered] = useState(false);

    const joinUsTheme = {
        components: {
            Select: {
                optionSelectedBg: "#CFDCE9",
                optionActiveBg: "#447EB7",
            }
        },
        token: {
            fontSize: 16,
            colorBorder: "#CFDCE9",
            controlOutline: "#CFDCE9",
            colorPrimaryHover: "#CFDCE9",
            colorPrimary: "#447EB7",
            lineWidth: 1.5,
            colorSplit: '#898989'
        }
    }

    const handleDaySelection = (day) => {
        setTimePreferences(prevTimePreference => {
            const updatedPreferences = { ...prevTimePreference };
            const newSelectedDay = !updatedPreferences[day].selected;

            if (day === 'anyday') {
                for (const key in updatedPreferences) {
                    if (key !== 'anyday') {
                        updatedPreferences[key].selected = false;
                    }
                }
                updatedPreferences['anyday'].selected = newSelectedDay;
            } else {
                updatedPreferences[day].selected = newSelectedDay;
                updatedPreferences['anyday'].selected = false;
            }
            return updatedPreferences;
        })
    }

    const handleTimeSelection = (day, time, selectionType) => {
        setTimePreferences(prevTimePreference => ({
            ...prevTimePreference,
            [day]: {
                ...prevTimePreference[day],
                [selectionType]: time,
            }
        }))
    }

    const handleContactSelection = (contactType) => {
        setContactPreferences(prevContactPreference => {
            const updatedPreferences = { ...prevContactPreference };
            for (const key in updatedPreferences) {
                if (key !== contactType) {
                    updatedPreferences[key] = false;
                }
            }
            updatedPreferences[contactType] = true;
            return updatedPreferences;
        });
    }

    return (
        <div className={styles["Container"]}>

            <ConfigProvider
                theme={joinUsTheme}
            >
                <Divider />
            </ConfigProvider>

            <div className={styles["Header"]}>Contact</div>
            <div className={styles["SubHeader"]}>Please select the most convenient days and times for us to reach you.</div>

            <div className={styles["TimePreferences"]}>
                {Object.keys(timePreferences).map((item, index) => {
                    const day = timePreferences[item];
                    return (
                        <div key={index} className={styles["IndividualPreference"]}>

                            <div
                                className={styles[(day?.selected) ? "Selected_IndividualPreference_Label" : "IndividualPreference_Label"]}
                                onClick={() => handleDaySelection(item)}
                            >{day?.label}</div>

                            <div className={styles["IndividualTime"]}>
                                <div>between</div>
                                <div className={styles["TimeDistribution"]}>
                                    <div>
                                        <ConfigProvider
                                            key={index}
                                            theme={joinUsTheme}
                                        >
                                            <Select
                                                value={day?.startTime}
                                                onChange={(value) => handleTimeSelection(item, value, 'startTime')}
                                                options={constants?.PREFERED_HOURS}
                                                className={styles["ContactPreference_Select"]}
                                                disabled={!day?.selected}
                                            />

                                        </ConfigProvider>
                                    </div>
                                    <div>to</div>
                                    <div>
                                        <ConfigProvider
                                            key={index}
                                            theme={joinUsTheme}
                                        >
                                            <Select
                                                value={day?.endTime}
                                                onChange={(value) => handleTimeSelection(item, value, 'endTime')}
                                                options={constants?.PREFERED_HOURS}
                                                className={styles["ContactPreference_Select"]}
                                                disabled={!day?.selected}
                                            />

                                        </ConfigProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className={styles["SubHeader2"]}>Please select your preferred method of contact.</div>

            <div className={styles["ContactPreference"]}>
                <div
                    className={styles[(contactPreferences?.phone) ? "Selected_IndividualContactPreference" : "IndividualContactPreference"]}
                    onMouseEnter={() => setPhoneHovered(true)}
                    onMouseLeave={() => setPhoneHovered(false)}
                    onClick={() => handleContactSelection('phone')}
                >
                    <div><JoinUsPhone hovered={phoneHovered} selected={contactPreferences?.phone} /></div>
                    <div className={styles["ContactPreference_LabelText"]}>phone</div>
                </div>

                <div
                    className={styles[(contactPreferences?.email) ? "Selected_IndividualContactPreference" : "IndividualContactPreference"]}
                    onMouseEnter={() => setEmailHovered(true)}
                    onMouseLeave={() => setEmailHovered(false)}
                    onClick={() => handleContactSelection('email')}
                >
                    <div><JoinUsEmail hovered={emailHovered} selected={contactPreferences?.email} /></div>
                    <div className={styles["ContactPreference_LabelText"]}>email</div>
                </div>
            </div>
        </div>
    );
}