import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useEffect, useCallback, forwardRef, useState, useImperativeHandle } from 'react';
import ApiService from '../../api';

const Captcha = forwardRef((props, _ref) => {

    const [token, setToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useImperativeHandle(_ref, () => ({
        isCaptchaValid: async () => {
            const _token = await generateCaptchaToken();
            const { ip } = await ApiService.getIpAddress();
            const response = await ApiService.verifyCaptcha({ token: _token, ip });
            if (response.status === 'success')
                return true;
            return false;
        },
        reset: () => {
            generateCaptchaToken();
        }
    }));

    const generateCaptchaToken = useCallback(async () => {
        if (!executeRecaptcha)
            return;

        const _token = await executeRecaptcha('yourAction');
        return _token;
    }, [executeRecaptcha]);

    useEffect(() => {
        generateCaptchaToken();
    }, [generateCaptchaToken]);

    return <></>;
});

export default React.memo(Captcha);