import { theme } from 'antd';
import styles from './index.module.css';

export default function NotificationDetailsBlock({ data, fullWidth, lessPadding }) {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <div className={styles["NotificationDetailsBlock"]}>

            <div
                className={lessPadding ? styles["NotificationDetailsTitle_LessPadding"] : styles["NotificationDetailsTitle"]}
                style={fullWidth ? { color: ap_userPrimaryColor, padding: '0%' } : { color: ap_userPrimaryColor }}
            >

                {data.title}

            </div>

            <>
                {(data.data).map((item, index) => (

                    <>

                        {(!item.hidden) ?
                            <div
                                key={index}
                                className={lessPadding ? styles["NotificationRow_LessPadding"] : styles["NotificationRow"]}
                                style={fullWidth ? { padding: '0%' } : {}}
                            >
                                <span style={{ fontWeight: 'bold' }}>{item.label}</span>

                                <span> {item.value}</span>

                            </div> :
                            <></>
                        }

                    </>

                ))}
            </>

        </div>
    );
}