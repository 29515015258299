import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Button, ConfigProvider, Modal, Spin, Switch, theme } from 'antd';
import { ExclamationCircleFilled, StarFilled } from '@ant-design/icons';

import EditContacts from '../EditContacts';
import DeleteModal from '../../components/DeleteModal';

import ApiService from '../../api';
import NotificationService from '../../services/notification.service';

import BillingIcon from '../../assets/images/icons/BillingIcon';
import EmailIcon from '../../assets/images/icons/EmailIcon';
import EmailSign from '../../assets/images/icons/EmailSign';
import LocationIcon from '../../assets/images/icons/LocationIcon';
import TelephoneIcon from '../../assets/images/icons/TelephoneIcon';
import SMSIcon from '../../assets/images/icons/SMSIcon';
import WhatsAppIcon from '../../assets/images/icons/WhatsAppIcon';

import FormatTelephone from '../../utils/Validations/FormatTelephone';

import styles from './index.module.css';
import utils from '../../utils';

export default function ContactProfile({ entity, entityId, contactId, contactData, refresh, setRefresh, loading }) {

    const { token: { themeSecondaryColor, ap_userHighlightColor } } = theme.useToken();

    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalIcon, setModalIcon] = useState(<></>);

    const [deleteModal, setDeleteModal] = useState(false);

    const cityProvince = (contactData?.ap_city && contactData?.ap_stateOrProvince) ?
        contactData?.ap_city + ", " + contactData?.ap_stateOrProvince :
        (contactData?.ap_city || contactData?.ap_stateOrProvince);

    // Event Handlers
    const handleSMS = (checked) => {
        const data = {
            ap_agentData: {
                ...contactData?.ap_agentData,
                ap_smsNotification: checked
            }
        }
        ApiService.updateContact(contactId, data).then((response) => {
            if (response.success) {
                NotificationService.success("Success", "SMS Notification updated successfully!!!");
                setRefresh(prevRefresh => prevRefresh + 1);
            } else NotificationService.error("Error", "Updating SMS Notification failed!!!")
        })
    }

    const handleEmail = (checked) => {
        if (!contactData?.ap_primaryContact) {
            const data = {
                ap_agentData: {
                    ...contactData?.ap_agentData,
                    ap_emailNotification: checked
                }
            }
            ApiService.updateContact(contactId, data).then((response) => {
                if (response.success) {
                    NotificationService.success("Success", "Email Notification updated successfully!!!");
                    setRefresh(prevRefresh => prevRefresh + 1);
                } else NotificationService.error("Error", "Updating Email Notification failed!!!")
            })
        } else {
            setModal(true);
            setModalIcon(<ExclamationCircleFilled className={styles["exclaimationCircleFilled"]} />);
            setModalMessage("Email notifications for Primary Contact cannot be turned off.");
        }
    }

    const handleWhatsApp = (checked) => {
        const data = {
            ap_agentData: {
                ...contactData?.ap_agentData,
                ap_whatsAppNotification: checked
            }
        }
        ApiService.updateContact(contactId, data).then((response) => {
            if (response.success) {
                NotificationService.success("Success", "WhatsApp Notification updated successfully!!!");
                setRefresh(prevRefresh => prevRefresh + 1);
            } else NotificationService.error("Error", "Updating WhatsApp Notification failed!!!")
        })
    }

    const handleBilling = (checked) => {
        if (!contactData?.ap_primaryContact) {
            const data = {
                ap_agentData: {
                    ...contactData?.ap_agentData,
                    ap_billingNotification: checked
                }
            }
            ApiService.updateContact(contactId, data).then((response) => {
                if (response.success) {
                    NotificationService.success("Success", "Billing Notification updated successfully!!!");
                    setRefresh(prevRefresh => prevRefresh + 1);
                } else NotificationService.error("Error", "Updating Billing Notification failed!!!")
            })
        } else {
            setModal(true);
            setModalIcon(<ExclamationCircleFilled className={styles["exclaimationCircleFilled"]} />);
            setModalMessage("Billing notifications for Primary Contact cannot be turned off.");
        }
    }

    const handleDelete = () => {
        ApiService.deleteContact(contactId, contactData).then((response) => {
            if (response.success) {
                navigate('/profile');
            } else NotificationService.error("Error", response.error);
        })
    }

    return (
        <div className={styles["contactContainer"]}>
            <Spin
                spinning={loading}
                tip="Loading..."
            >
                <DeleteModal open={deleteModal} handler={setDeleteModal} deleteEntity="contact" eventHandler={handleDelete} />

                <div className={styles["contactGrid_Header"]}>
                    <div style={{ display: 'flex' }}>
                        <Avatar
                            className={styles["contactGrid_AvatarInitials"]}
                            size={{ xs: 60, sm: 60, md: 60, lg: 80, xl: 80, xxl: 80 }}
                        >{utils.GetInitials(contactData?.ap_fullName)}</Avatar>
                        <div className={styles["contactGrid_Info"]}>
                            <span className={styles["contactGrid_InfoName"]}>{contactData?.ap_fullName}</span>
                            <span>{contactData?.ap_primaryContact ? <StarFilled className={styles["starFilled"]} /> : <></>}</span>
                        </div>
                    </div>

                    <div className={styles["contactGridButtons"]}>
                        <EditContacts
                            contactId={contactId}
                            entityId={entityId}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            iconButton={false}
                        />
                        <Button
                            type='primary'
                            className={styles["contactButtons"]}
                            disabled={contactData?.ap_primaryContact}
                            onClick={() => setDeleteModal(true)}
                        >Delete</Button>
                    </div>
                </div>

                <div className={styles["contactInfo"]}>
                    <div className={styles["contactInfo_Details"]}>
                        <EmailSign />
                        <a href={`mailto:${contactData?.ap_email}`}>{contactData?.ap_email}</a>
                    </div>

                    <div className={styles["contactInfo_Details"]}>
                        <TelephoneIcon />
                        <a href={`tel:${contactData?.ap_telephone}`}>{FormatTelephone(String(contactData?.ap_telephone)).input}</a>
                    </div>

                    <div className={styles["contactInfo_Loc"]}>
                        <LocationIcon />
                        <div>
                            {contactData?.ap_address} {contactData?.ap_address ? <br /> : <></>}
                            {cityProvince} {cityProvince ? <br /> : <></>}
                            {contactData?.ap_postalZipCode} {contactData?.ap_postalZipCode ? <br /> : <></>}
                            {contactData?.ap_country}
                        </div>
                    </div>
                </div>

                {(entity === 'agent') ?
                    <>
                        <div className={styles["notification"]}>Notification Preferences</div>
                        <ConfigProvider theme={{ token: { colorTextQuaternary: themeSecondaryColor, colorPrimary: ap_userHighlightColor } }}>
                            <div className={styles["notificationGrid"]}>
                                <div className={styles["contactPreferences"]}>
                                    <div className={styles["contactPreferences_Info"]}>
                                        <SMSIcon />
                                        <span className={styles["labelColors"]}>SMS</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checkedChildren="ON"
                                            unCheckedChildren="OFF"
                                            checked={contactData?.ap_agentData?.ap_smsNotification}
                                            onClick={handleSMS}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className={styles["contactPreferences"]}>
                                    <div className={styles["contactPreferences_Info"]}>
                                        <EmailIcon />
                                        <span className={styles["labelColors"]}>Email</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checkedChildren="ON"
                                            unCheckedChildren="OFF"
                                            checked={contactData?.ap_agentData?.ap_emailNotification}
                                            onClick={handleEmail}
                                        />
                                    </div>
                                </div>

                                <div className={styles["contactPreferences"]}>
                                    <div className={styles["contactPreferences_Info"]}>
                                        <WhatsAppIcon />
                                        <span className={styles["labelColors"]}>WhatsApp</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checkedChildren="ON"
                                            unCheckedChildren="OFF"
                                            checked={contactData?.ap_agentData?.ap_whatsAppNotification}
                                            onClick={handleWhatsApp}
                                        />
                                    </div>
                                </div>

                                <div className={styles["contactPreferences"]}>
                                    <div className={styles["contactPreferences_Info"]}>
                                        <BillingIcon />
                                        <span className={styles["labelColors"]}>Billing</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checkedChildren="ON"
                                            unCheckedChildren="OFF"
                                            checked={contactData?.ap_agentData?.ap_billingNotification}
                                            onClick={handleBilling}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ConfigProvider>
                    </> : <></>
                }

                <Modal
                    open={modal}
                    footer={null}
                    closable={false}
                    getContainer=".AppTheme"
                    centered
                >
                    <div className={styles["contactModal"]}>
                        <div className={styles["contactModal_Inner"]}>
                            {modalIcon}
                        </div>
                        <div className={styles["contactModal_Inner"]}>
                            {modalMessage}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            onClick={() => setModal(false)}
                        >Ok</Button>
                    </div>
                </Modal>

            </Spin>
        </div>
    );
}