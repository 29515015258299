export default [
    {
        key: '5%',
        label: '5%',
    },
    {
        key: '10%',
        label: '10%',
    },
    {
        key: '12.5%',
        label: '12.5%',
    },
    {
        key: '15%',
        label: '15%',
    },
    {
        key: '20%',
        label: '20%',
    },
    {
        key: 'Other',
        label: 'Other',
    },
]