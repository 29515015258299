const processSearch = (data, searchedColumn, searchText) => {
    if (!searchedColumn || !searchText)
        return data;
    return data.filter(item => item[searchedColumn].toLowerCase().includes(searchText.toLowerCase()));
}

const processFilters = (data, filters) => {
    if (!filters || Object.keys(filters).length === 0)
        return data;

    return data.filter(item => {
        for (const key in filters) {
            if (filters[key].length === 0)
                continue;

            if (!filters[key].includes(item[key]))
                return false;
        }
        return true;
    });
}

const exportObj = {
    processSearch,
    processFilters
};

export default exportObj;