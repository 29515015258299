export default function UpdateFileUrl(fileUrl, sourceFolder, destinationFolder) {
    if (!fileUrl || !sourceFolder || !destinationFolder)
        return;

    // Ensure sourceFolder does not have a trailing slash
    const normalizedSourceFolder = sourceFolder.replace(/\/$/, "");

    // Replace the source folder path in the fileUrl with the destination folder
    const modifiedUrl = fileUrl.replace(normalizedSourceFolder, destinationFolder);

    return modifiedUrl;
}