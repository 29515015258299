import HomeContent from "../../components/HomeContent";
import ListingLayout from "../../layouts/ListingLayout";

export default function Listings() {
    return (
        <>
            <HomeContent
                contentHeader="Vendor Listing"
                content={<ListingLayout />}
                bgColor="transparent"
            />
        </>
    )
}