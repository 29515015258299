import HomeContent from "../../components/HomeContent";
import RequirementList from "../../layouts/RequirementsList";

export default function Requirements() {

    return (
        <HomeContent
            contentHeader='Requirements'
            content={<RequirementList />}
            bgColor="transparent"
        />
    );
}
