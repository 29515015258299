import utils from "../../utils";
import FilterColumn from "../../components/FilterColumn";
import SortColumn from "../../components/SortColumn";
import TagComponent from "../../components/TagComponent";

function generateAssetTableColumns(assetData, setFilters, customSort, setCustomSort) {

    const notApplicableTypes = ["Other", "Amount (PAL)", "Amount (Working Capital)"];

    return [
        {
            title: 'VIN/Serial',
            key: 'ap_vinSerial',
            ellipsis: { showTitle: false },
            width: 250,
            render: (record) => (record.ap_vin || record.ap_serialNum)
        },
        {
            title: 'Make',
            dataIndex: 'ap_make',
            key: 'ap_make',
            ...SortColumn(customSort, setCustomSort, 'ap_make'),
            ...FilterColumn(assetData, setFilters, 'ap_make'),
            ellipsis: { showTitle: false },
            width: 180,
        },
        {
            title: 'Year',
            key: 'ap_year',
            ellipsis: { showTitle: false },
            width: 100,
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_year : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: 'Price',
            key: 'ap_price',
            render: (record) => (utils.parseDBCurrency(record?.ap_price)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            ellipsis: { showTitle: false },
            width: 100,
        },
        {
            title: 'Vendor',
            dataIndex: 'ap_vendorId',
            key: 'ap_vendorId',
            ellipsis: { showTitle: false },
            width: 150,
            render: (record) => (record?.ap_name)
        },
        {
            title: 'Plating Address',
            key: 'ap_platingaddress',
            ellipsis: { showTitle: false },
            width: 250,
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_platingaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: 'Plating Province',
            key: 'ap_platingprovince',
            ellipsis: { showTitle: false },
            width: 250,
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_platingprovince : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        },
        {
            title: 'Yard Address',
            key: 'ap_yardaddress',
            ellipsis: { showTitle: false },
            width: 250,
            render: (record) => (!notApplicableTypes.includes(record.ap_type) ? record.ap_yardaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />)
        }
    ]
}

const drawdownConstants = {
    generateAssetTableColumns: generateAssetTableColumns,
}

export default drawdownConstants;