export default [
    {
        label: 'Aggregate haulage',
        value: 'Aggregate haulage',
    },
    {
        label: 'Dump Trucking',
        value: 'Dump Trucking',
    },
    {
        label: 'Vocational Heavy Spec',
        value: 'Vocational Heavy Spec',
    },
    {
        label: 'Vocational Day cab',
        value: 'Vocational Day cab',
    },
    {
        label: 'Long Haul',
        value: 'Long Haul',
    },
    {
        label: 'Short Haul',
        value: 'Short Haul',
    },
    {
        label: 'Flatbeds',
        value: 'Flatbeds',
    },
    {
        label: 'Straight Truck',
        value: 'Straight Truck',
    },
    {
        label: 'Towing',
        value: 'Towing',
    },
    {
        label: 'Construction',
        value: 'Construction',
    },
    {
        label: 'Other',
        value: 'Other',
    },
    {
        label: 'N/A',
        value: 'N/A',
    },
];