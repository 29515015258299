import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Profile(open, selected) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles["Profile"]}>
      <mask id="path-1-inside-1_601_4030" fill="white">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H75C77.7614 0 80 2.23858 80 5V75C80 77.7614 77.7614 80 75 80H5C2.23858 80 0 77.7614 0 75V5Z" />
      </mask>
      <path d="M0 5C0 2.23858 2.23858 0 5 0H75C77.7614 0 80 2.23858 80 5V75C80 77.7614 77.7614 80 75 80H5C2.23858 80 0 77.7614 0 75V5Z" fillOpacity="0.5" className={styles[(open || selected) ? "Profile_BG_Selected" : "Profile_BG"]} />
      <path d="M0 0H80H0ZM80 75C80 82.1797 74.1797 88 67 88H13C5.8203 88 0 82.1797 0 75V72C0 72 2.23858 72 5 72H75C77.7614 72 80 72 80 72V75ZM0 80V0V80ZM80 0V80V0Z" mask="url(#path-1-inside-1_601_4030)" className={styles[selected ? "Profile_Fill" : ""]} />
      <path d="M36.6193 31.8096C36.6193 34.4659 34.4659 36.6193 31.8096 36.6193C29.1534 36.6193 27 34.4659 27 31.8096C27 29.1534 29.1534 27 31.8096 27C34.4659 27 36.6193 29.1534 36.6193 31.8096ZM36.6193 31.8096H53" strokeWidth="3" strokeLinecap="round" className={styles["Profile_Stroke"]} />
      <path d="M43.3807 48.1903C43.3807 50.8466 45.5341 53 48.1904 53C50.8466 53 53 50.8466 53 48.1903C53 45.534 50.8466 43.3807 48.1904 43.3807C45.5341 43.3807 43.3807 45.534 43.3807 48.1903ZM43.3807 48.1903H27" strokeWidth="3" strokeLinecap="round" className={styles["Profile_Stroke"]} />
    </svg>
  );
}

export default function HeaderProfileIcon({ selected, open, ...props }) {
  const {
    token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor },
  } = theme.useToken();
  const themeColorVariables = {
    '--userPrimaryColor': ap_userPrimaryColor,
    '--userHighlightColor': ap_userHighlightColor,
    '--themePrimaryColor': themePrimaryColor,
    '--themeFontColor': themeFontColor,
    display: 'flex',
    width: '68.5px',
    height: '60px',
  };
  return <Icon component={() => Profile(open, selected)} style={themeColorVariables} />;
}
