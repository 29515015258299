import Icon from '@ant-design/icons';
import { theme } from 'antd';

const DocumentReqStep1 = (color) => {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <svg width="35" height="35" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.125" y="1.5" width="47" height="47" rx="23.5" fill="white" />
            <rect x="2.125" y="1.5" width="47" height="47" rx="23.5" stroke={ap_userPrimaryColor} strokeWidth="3" />
            <path d="M25.9759 13.6H29.1759V36H25.4959V17.536L20.9839 18.816L20.0879 15.68L25.9759 13.6Z" fill={color || ap_userPrimaryColor} />
        </svg>

    );
}

export default function DocumentRequestStep1({ color, ...props }) {
    return <Icon component={() => DocumentReqStep1(color)} {...props} />
}