export default [
    { 
        value: 993210000, 
        label: 'Sales Agent', 
        disabled: false 
    },
    { 
        value: 993210001, 
        label: 'Referral Agent', 
        disabled: false 
    },
    { 
        value: 993210002, 
        label: 'Vendor Agent', 
        disabled: false 
    },
    { 
        value: 993210003, 
        label: 'House', 
        disabled: false 
    },
    { 
        value: 993210004, 
        label: 'Owner Operator', 
        disabled: false 
    }
];