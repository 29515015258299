import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Spin } from "antd";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import RequirementsChecklist2 from "../../components/RequirementsCheckList";
import DocumentRequirements from "../DocumentRequirements";
import ReqConstants from "./constants";

import "../../assets/styles/fonts.css";
import styles from "./index.module.css";

export default function Requirement({ setHeaderName, requirementData, setRefresh }) {

    const { id } = useParams();

    const [documentsList, setDocumentsList] = useState(ReqConstants.Documents(requirementData?.ap_requirementsList));
    const [showDocumentsError, setShowDocumentsError] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [comments, setComments] = useState("");
    const [folderName, setFolderName] = useState("");
    const [loader, setLoader] = useState({ loading: false, tip: "", });
    const [openSubmission, setOpenSubmission] = useState(false);

    useEffect(() => {
        if (!requirementData || Object.keys(requirementData).length === 0) return;
        setLoader({ loading: true, tip: "Loading..." });
        setDocumentsList(ReqConstants.Documents(requirementData?.ap_requirementsList));

        const checkedData = [...requirementData?.ap_requirementsList].filter(item => item.crm_confirmed === false && item.crm_statecode === 0);
        setCheckedItems(checkedData);

        setFileList(requirementData?.ap_uploadedDocuments.map((item) => ({ ...item, updated: true })))
        setComments(requirementData?.ap_comments);
        setFolderName(`Clients/${requirementData?.ap_clientId?._id}/Requirements/${id}/`);
        setHeaderName(
            <div className="Font_SemiBold">
                Requirements |
                <span className={`${styles["headerTitle"]} Font_SemiBold`}>{requirementData?.ap_clientId?.ap_businessName} - {requirementData?.ap_assetDescription}</span>
            </div>
        );
        setLoader({ loading: false, tip: "" });
    }, [requirementData])

    const handleSubmit = () => {
        setLoader({ loading: true, tip: "Sending Documents..." });
        setOpenSubmission(false);

        if (fileList?.length === 0) {
            setShowDocumentsError(true);
            setLoader({ loading: false, tip: "" });
            return;
        }

        setShowDocumentsError(false);
        const data = {
            ...requirementData,
            ap_requirementsList: checkedItems,
            ap_uploadedDocuments: fileList.map(({ updated, ...rest }) => rest),
            ap_comments: comments
        }

        ApiService.updateUploadedRequirements(id, data).then(response => {
            setLoader({ loading: false, tip: "" });
            if (response.success) {
                NotificationService.success('Success', 'Requirements has been submitted successfully!!!')
                setRefresh((prev) => prev + 1);
            }
            else NotificationService.error('Error', response.error);
        })
    }

    const anyChecked = checkedItems.some(item => item.checked);

    return (
        <Spin
            tip={loader?.tip}
            spinning={loader?.loading}
        >
            <DocumentRequirements
                documentContainerHeader="All Requested Documents"
                documentContainerSubHeader="The following documents are requested for submission."
                documentsList={documentsList}
                showDocumentsError={showDocumentsError}
                folderName={folderName}
                files={fileList}
                setFiles={setFileList}
                fileComments={comments}
                setFileComments={setComments}
                showActionButton={true}
                actionButtonOnClick={() => setOpenSubmission(true)}
            />

            <Modal
                open={openSubmission}
                footer={null}
                closable={false}
                centered
                width="800px"
                getContainer=".AppTheme"
            >
                <div className={styles["Header"]}>Before Submitting...</div>
                <div className={styles["SubHeader"]}>Please select which documents you have uploaded.</div>
                <div>
                    <RequirementsChecklist2
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                    />
                </div>
                <div className={styles["Modal_Actions"]}>
                    <Button
                        type="primary"
                        className={styles["Modal_Submit"]}
                        disabled={!anyChecked}
                        onClick={handleSubmit}
                    >Submit</Button>
                    <Button
                        type="primary"
                        className={styles["Modal_Cancel"]}
                        onClick={() => setOpenSubmission(false)}
                    >Cancel</Button>
                </div>
            </Modal>
        </Spin>
    );
}