import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Resources = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.1842 5.07898H16.5989L16.1105 3.55266C15.7938 2.65707 15.2065 1.88212 14.4299 1.33517C13.6532 0.78823 12.7257 0.496372 11.7758 0.500034H4.81576C3.60135 0.500034 2.43668 0.982457 1.57796 1.84118C0.719239 2.69989 0.236816 3.86457 0.236816 5.07898V24.9211C0.236816 26.1355 0.719239 27.3001 1.57796 28.1589C2.43668 29.0176 3.60135 29.5 4.81576 29.5H26.1842C27.3986 29.5 28.5632 29.0176 29.422 28.1589C30.2807 27.3001 30.7631 26.1355 30.7631 24.9211V9.65792C30.7631 8.44351 30.2807 7.27884 29.422 6.42012C28.5632 5.5614 27.3986 5.07898 26.1842 5.07898ZM27.7105 24.9211C27.7105 25.3259 27.5497 25.7141 27.2634 26.0003C26.9772 26.2866 26.589 26.4474 26.1842 26.4474H4.81576C4.41095 26.4474 4.02273 26.2866 3.73649 26.0003C3.45025 25.7141 3.28944 25.3259 3.28944 24.9211V5.07898C3.28944 4.67417 3.45025 4.28595 3.73649 3.99971C4.02273 3.71347 4.41095 3.55266 4.81576 3.55266H11.7758C12.0957 3.55184 12.4079 3.6516 12.6681 3.83784C12.9283 4.02409 13.1234 4.2874 13.2257 4.59056L14.05 7.09371C14.1524 7.39687 14.3474 7.66018 14.6076 7.84642C14.8678 8.03267 15.18 8.13243 15.5 8.1316H26.1842C26.589 8.1316 26.9772 8.29241 27.2634 8.57865C27.5497 8.86489 27.7105 9.25311 27.7105 9.65792V24.9211Z" fill={ap_userPrimaryColor} />
        </svg>
    );
};

export default function ResourcesIcon(props) {
    return <Icon component={Resources} {...props} />
}