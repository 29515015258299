import React from 'react';
import { Switch } from 'antd';
import styles from './index.module.css';
import RequiredLabelIndicator from '../RequiredLabelIndicator';

export default function SpecialPaymentFields({ formData }) {
    return (
        <div className={styles["SpecialPayment_Container"]}>
            {Object.keys(formData)?.map((item, key) => {
                const data = formData[item];
                if (!data?.hidden) {
                    if (data?.inputType === 'switch')
                        return (
                            <div className={styles["SpecialPayment_Fields"]}>
                                <b><RequiredLabelIndicator label={data?.label} required={data?.required} /></b>
                                <Switch checked={data?.value} style={{ width: 'fit-content' }} />
                            </div>
                        )
                    return <div className={styles["SpecialPayment_Fields"]}>
                        <b><RequiredLabelIndicator label={data?.label} required={data?.required} /></b>
                        <div>{data?.value}</div>
                    </div>
                }
            })}
        </div >
    )
}