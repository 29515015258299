import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import GoBackButton from "../../components/GoBackButton";
import HomeContent from "../../components/HomeContent";
import Error404 from "../../layouts/Error404";
import ContactProfile from "../../layouts/ContactProfile";

import ApiService from "../../api";

export default function ContactsPage({ entity }) {
    const { id, contactId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [contactData, setContactData] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (!entity && !id && !contactId) return;
        setLoading(true);
        if (entity === 'agent') {
            ApiService.fetchAgentProfileData(id).then(agentResponse => {
                ApiService.fetchIndividualContact(contactId).then(contactResponse => {
                    setShowError(!agentResponse || !contactResponse)
                    setContactData(contactResponse || {})
                })
            })
        } else if (entity === 'client') {
            ApiService.fetchClientDetails(id).then(clientResponse => {
                ApiService.fetchIndividualContact(contactId).then(contactResponse => {
                    setShowError(!clientResponse || !contactResponse)
                    setContactData(contactResponse || {})
                })
            })
        }

        ApiService.fetchIndividualContact(contactId).then(contactResponse => {
            setShowError(prevShowError => prevShowError || !contactResponse)
            setContactData(contactResponse || {})
        })

        setLoading(false);
    }, [entity, refresh, id, contactId]);

    return (
        <HomeContent
            contentHeader={<>
                {showError ?
                    <></> :
                    <div style={{ fontSize: '24px', display: 'flex', fontWeight: 'bold', gridGap: '1%' }}>

                        <span>
                            {(entity === 'agent') ?
                                <GoBackButton />:
                                <GoBackButton />
                            }
                                {/* <LeftOutlined onClick={() => navigate(`/${window.location.pathname.split('/')[1]}`)} /> : */}
                        </span>

                        <span>{(entity === 'agent') ? "Profile" : "Client"}</span>

                        <span style={{ color: 'lightgray' }}> | Contacts</span>

                    </div>
                }
            </>}
            content={
                <Spin
                    spinning={loading}
                    tip="Loading..."
                    size="large"
                >
                    {showError ?
                        <div style={{ maxHeight: '73vh', overflowY: 'hidden' }}>
                            <Error404 />
                        </div> :
                        <ContactProfile
                            entity={entity}
                            entityId={id}
                            contactId={contactId}
                            contactData={contactData}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            loading={loading}
                        />
                    }
                </Spin>
            }
            bgColor="transparent"
        />
    );
}