import HomeContent from "../../components/HomeContent";
import ResourcesLayout from "../../layouts/Resources";

export default function Resources() {

    return (
        <>
            <HomeContent
                contentHeader={"Resources"}
                content={<ResourcesLayout />}
                bgColor="transparent"
            />
        </>
    );
}
