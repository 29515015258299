import { theme } from 'antd';
import Icon from '@ant-design/icons';

const ArchiveFolder = () => {
    const { token: { themeFontColor }, } = theme.useToken();
    return (
        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3C0 2.33696 0.263392 1.70107 0.732233 1.23223C1.20107 0.763392 1.83696 0.5 2.5 0.5H8.75C9.08149 0.500071 9.39938 0.631814 9.63375 0.86625L11.7675 3H22.5C23.163 3 23.7989 3.26339 24.2678 3.73223C24.7366 4.20107 25 4.83696 25 5.5V18C25 18.663 24.7366 19.2989 24.2678 19.7678C23.7989 20.2366 23.163 20.5 22.5 20.5H2.5C1.83696 20.5 1.20107 20.2366 0.732233 19.7678C0.263392 19.2989 0 18.663 0 18V3ZM8.2325 3H2.5V18H22.5V5.5H11.25C10.9185 5.49993 10.6006 5.36819 10.3663 5.13375L8.2325 3Z" fill={themeFontColor}/>
        </svg>

    );
};

export default function ArchiveFolderIcon(props) {
    return <Icon component={ArchiveFolder} {...props} />
}