import { useNavigate } from "react-router-dom";
import { Table, Button, theme, ConfigProvider } from "antd";
import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";
import NotificationDetailsBlock from "../../NotificationDetailBlock";
import { constants } from "./constants.js";

import validate from "../../../utils/Validations";
import utils from "../../../utils"

import styles from "./index.module.css";

export default function SynergyFunded({ notificationData }) {
    const navigate = useNavigate();
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor }, } = theme.useToken();

    const clientTelephone = `tel:${notificationData.ap_data.clientTelephone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`

    const details = constants.generateDetails(notificationData.ap_data);

    return (
        <div className={styles["NotificationsBody"]}>
            <div><NotificationScreenIcons.SynergyFundedIcon /></div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: ap_userPrimaryColor }}
            >SYNERGY FUNDED</div>

            <div>
                The asset(s) for <span className={styles["NotificationTitleInfo"]}>{notificationData.ap_data.clientName} - {notificationData.ap_data.lenderName}</span> have been successfully added to the Synergy GAP program.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData.ap_data.clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock data={details.assetDetails}/>
                    <NotificationDetailsBlock data={details.fundingDetails}/>
                </div>
            </div>

            <div className={styles["Notification_RequirementListContainer"]}>

                <div className={styles["NotificationDetailsTitle"]} style={{ color: ap_userPrimaryColor }}>
                    Assets Added
                </div>

                <ConfigProvider
                    theme={{
                        token: {
                            colorFillAlter: ap_userPrimaryColor,
                            colorBgContainer: themePrimaryColor,
                            colorFillSecondary: themePrimaryColor,
                            colorText: themeFontColor,
                            colorIcon: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                            colorTextHeading: 'white' // this is the prop that controls the color of the table heading with priority over headerColor
                            // TODO: set colorTextHeading to be white for dark background colors and black for light background colors
                        }
                    }}
                >
                    <Table
                        columns={constants.SynergyFundedAssetTableColumns}
                        dataSource={notificationData.ap_data.ap_assetData}
                        scroll={{ x: true }}
                        pagination={false}
                    />
                </ConfigProvider>
            </div>

            <div className={styles["InstructionsTitle"]} style={{ color: ap_userPrimaryColor }}>Next Steps</div>
            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>
                You should receive payment for {utils.TransformDBCurrency(notificationData.ap_data.totalSynergyAgentFee)} by the end of the day, confirmed by a separate notification. Please see the attached invoice(s) for details.
            </div>

            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/drawdowns/${notificationData.ap_entityId}`)}
                    style={{ height: "40px" }}
                >See Drawdown</Button>
            </div>

            <div style={{ width: '70%', margin: '0% auto', fontSize: 'medium' }}>
                ** Please note: funds are subject to refund within 6 months. ** 
            </div>
        </div>
    )
}
