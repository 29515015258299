import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Skeleton, Spin } from "antd";
import dayjs from "dayjs";

import GoBackButton from "../../components/GoBackButton";
import HomeContent from "../../components/HomeContent";

import Error404 from "../../layouts/Error404";
import Application from "../../layouts/Application";

import ApiService from "../../api";

import styles from "./index.module.css";

export default function ApplicationForm() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showError, setShowError] = useState(false);
    const [applicationName, setApplicationName] = useState('');
    const [creditName, setCreditName] = useState('');

    useEffect(() => {
        if (!id) {
            setApplicationName('New Application');
            setCreditName('Lantern Credit');
        } else {
            setLoading(true);
            ApiService.fetchIndividualApplication(id).then((appData) => {
                setShowError(!appData);
                setLoading(false);
                if (!appData) {
                    setApplicationName("No Application Found")
                    return;
                };
                setApplicationName(`${dayjs(appData?.createdAt).format("MMMM YYYY")} - ${appData?.ap_clientId?.ap_businessName} - ${appData?.ap_clientId?.ap_type}`);
                setCreditName(appData?.ap_creditId?.ap_fullName);
            });
        }
    }, [id, refresh]);

    const contentHeader = (
        <div className={styles["HeaderContainer"]}>
            <GoBackButton />
            <div className={styles["HeaderName"]}>
                <div>{loading ? <Skeleton.Input active size="small" /> : applicationName}</div>
                <div>Credit: <span>{loading ? <Skeleton.Input active size="small" /> : creditName}</span></div>
            </div>
        </div>
    );

    const content = (
        <Spin
            spinning={loading}
            tip="Loading..."
            size="large"
        >
            {showError ?
                <div style={{ maxHeight: '73vh', overflowY: 'hidden' }}>
                    <Error404 />
                </div> :
                <Application
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setHeaderName={setApplicationName}
                    setCreditName={setCreditName}
                />
            }
        </Spin>
    );

    return (
        <HomeContent
            contentHeader={contentHeader}
            content={content}
            bgColor="transparent"
        />
    );
}