import { ColorPicker, ConfigProvider } from "antd";
import { useTheme } from "../../utils/ThemeContext";

import styles from "./index.module.css";
import PrimaryColorIcon from "../../assets/images/icons/PrimaryColorIcon";
import SecondaryColorIcon from "../../assets/images/icons/SecondaryColorIcon";
import HighlightColorIcon from "../../assets/images/icons/HighlightColorIcon";
import ColorPreferenceSampleIcon from "../../assets/images/icons/ColorPreferenceSampleIcon";

export default function ColorPreferences() {

    const { theme, setTheme } = useTheme();

    const colorOptions = {
        primaryColour: {
            label: "Primary Colour",
            icon: <PrimaryColorIcon />,
            description: " This is the predominant colour throughout the interface",
            value: theme?.token?.ap_userPrimaryColor || "#447EB7",
        },
        secondaryColour: {
            label: "Secondary Colour",
            icon: <SecondaryColorIcon />,
            description: "This is the secondary prominent colour throughout the interface",
            value: theme?.token?.ap_userSecondaryColor || "#8ECEAE",
        },
        highlightColour: {
            label: "Highlight Colour",
            icon: <HighlightColorIcon />,
            description: "This is the colour displayed when hovering over an element",
            value: theme?.token?.ap_userHighlightColor || "#CFDCE9",
        },
    }

    const overridingThemeTokens = {
        token: {
            activeBorderColor: theme?.token?.ap_userPrimaryColor,
            hoverBorderColor: theme?.token?.ap_userPrimaryColor,
            colorBgContainer: theme?.token?.themePrimaryColor,
            colorBgContainerDisabled: theme?.token?.themeDisabledContainer,
            colorText: theme?.token?.themeFontColor,
            colorTextPlaceholder: theme?.token?.themeFontColor,
            colorTextDisabled: theme?.token?.themeFontColor,
            colorIcon: theme?.token?.themeFontColor,
            colorTextQuaternary: theme?.token?.themeFontColor,
        }
    }

    const handleColorChange = (colorName, hex) => {
        let colorChanges = {};
        if (colorName === 'primaryColour') {
            colorChanges["colorPrimary"] = hex;
            colorChanges["ap_userPrimaryColor"] = hex;
            // colorChanges["ap_userPrimaryColor"] = hex;
        } else if (colorName === 'secondaryColour') {
            colorChanges["colorPrimaryHover"] = hex;
            colorChanges["ap_userSecondaryColor"] = hex;
        } else if (colorName === 'highlightColour') {
            colorChanges["ap_userHighlightColor"] = hex;
            // colorChanges["ap_userHighlightColor"] = hex;
        }

        setTheme(prevTheme => {
            return { token: { ...prevTheme.token, ...colorChanges } }
        });
    }

    return (
        <div className={styles["ColorPreferences_Container"]}>
            {Object.keys(colorOptions).map((color) => {
                const colorItem = colorOptions[color];
                return (
                    <div className={styles["InnerContainer"]}>
                        <div className={styles["InnerContainer_Header"]}>{colorItem?.icon} {colorItem?.label}</div>
                        <div className={styles["InnerContainer_Content"]}>
                            <div>{colorItem?.description}</div>
                            <div className={styles["ColorPreferences_Picker"]}>
                                <ColorPicker
                                    value={colorItem?.value}
                                    onChange={(value, hex) => handleColorChange(color, hex)}
                                    panelRender={(panel) => (
                                        <ConfigProvider theme={overridingThemeTokens}>
                                            <div style={{ padding: '12px' }}>
                                                {panel}
                                            </div>
                                        </ConfigProvider>
                                    )}
                                />
                            </div>
                            <div className={styles["Preview"]}>Preview:</div>
                            <div><ColorPreferenceSampleIcon /></div>
                        </div>
                    </div>
                )
            })}
        </div >
    )
}