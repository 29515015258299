import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Financials = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M13 42.2222V34.1111C13 31.9599 13.8546 29.8968 15.3757 28.3757C16.8968 26.8546 18.9599 26 21.1111 26H29.2222M13 42.2222C18.4061 42.2222 29.2222 38.9778 29.2222 26M13 42.2222V58.4444M29.2222 26H69.7778M13 58.4444V66.5556C13 68.7068 13.8546 70.7698 15.3757 72.291C16.8968 73.8121 18.9599 74.6667 21.1111 74.6667H29.2222M13 58.4444C18.4061 58.4444 29.2222 61.6889 29.2222 74.6667M86 42.2222V34.1111C86 31.9599 85.1454 29.8968 83.6243 28.3757C82.1032 26.8546 80.0401 26 77.8889 26H69.7778M86 42.2222C80.5939 42.2222 69.7778 38.9778 69.7778 26M86 42.2222V58.4444M29.2222 74.6667H45.4444" stroke="white" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M49.5 58.4444C53.9797 58.4444 57.6111 54.8129 57.6111 50.3333C57.6111 45.8536 53.9797 42.2222 49.5 42.2222C45.0204 42.2222 41.3889 45.8536 41.3889 50.3333C41.3889 54.8129 45.0204 58.4444 49.5 58.4444Z" stroke="white" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61.6667 74.6665H86.0001" stroke="white" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default function FinancialsIcon(props) {
    return <Icon component={Financials} {...props} />
}