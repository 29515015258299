import Icon from "@ant-design/icons"
import { theme } from "antd";

function SMS(color) {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    const themeColor = color || ap_userPrimaryColor;
    return (
        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.454987 2.49902C0.454987 1.83598 0.718379 1.2001 1.18722 0.731256C1.65606 0.262415 2.29195 -0.000976562 2.95499 -0.000976562H22.955C23.618 -0.000976562 24.2539 0.262415 24.7228 0.731256C25.1916 1.2001 25.455 1.83598 25.455 2.49902V16.249C25.455 16.9121 25.1916 17.548 24.7228 18.0168C24.2539 18.4856 23.618 18.749 22.955 18.749H17.2225L13.8387 22.1328C13.6043 22.3671 13.2864 22.4988 12.955 22.4988C12.6235 22.4988 12.3056 22.3671 12.0712 22.1328L8.68749 18.749H2.95499C2.29195 18.749 1.65606 18.4856 1.18722 18.0168C0.718379 17.548 0.454987 16.9121 0.454987 16.249V2.49902ZM22.955 2.49902H2.95499V16.249H9.20499C9.53648 16.2491 9.85437 16.3808 10.0887 16.6153L12.955 19.4815L15.8212 16.6153C16.0556 16.3808 16.3735 16.2491 16.705 16.249H22.955V2.49902Z" fill={themeColor} />
            <path d="M14.83 9.37402C14.83 9.8713 14.6324 10.3482 14.2808 10.6998C13.9292 11.0515 13.4523 11.249 12.955 11.249C12.4577 11.249 11.9808 11.0515 11.6292 10.6998C11.2775 10.3482 11.08 9.8713 11.08 9.37402C11.08 8.87674 11.2775 8.39983 11.6292 8.0482C11.9808 7.69657 12.4577 7.49902 12.955 7.49902C13.4523 7.49902 13.9292 7.69657 14.2808 8.0482C14.6324 8.39983 14.83 8.87674 14.83 9.37402ZM19.83 9.37402C19.83 9.8713 19.6324 10.3482 19.2808 10.6998C18.9292 11.0515 18.4523 11.249 17.955 11.249C17.4577 11.249 16.9808 11.0515 16.6292 10.6998C16.2775 10.3482 16.08 9.8713 16.08 9.37402C16.08 8.87674 16.2775 8.39983 16.6292 8.0482C16.9808 7.69657 17.4577 7.49902 17.955 7.49902C18.4523 7.49902 18.9292 7.69657 19.2808 8.0482C19.6324 8.39983 19.83 8.87674 19.83 9.37402ZM9.82999 9.37402C9.82999 9.8713 9.63244 10.3482 9.28081 10.6998C8.92918 11.0515 8.45227 11.249 7.95499 11.249C7.45771 11.249 6.98079 11.0515 6.62916 10.6998C6.27753 10.3482 6.07999 9.8713 6.07999 9.37402C6.07999 8.87674 6.27753 8.39983 6.62916 8.0482C6.98079 7.69657 7.45771 7.49902 7.95499 7.49902C8.45227 7.49902 8.92918 7.69657 9.28081 8.0482C9.63244 8.39983 9.82999 8.87674 9.82999 9.37402Z" fill={themeColor} />
        </svg>
    )
}

export default function SMSIcon({ color }) {
    return <Icon component={() => SMS(color)} />
}