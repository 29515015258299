import { useEffect, useState } from "react";
import { Button, Layout, theme } from "antd";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import InputLayout from "../InputLayout";
import validate from "../../utils/Validations";
import HasErrors from "../../utils/HasErrors";

import styles from "./index.module.css";
import generateBuisnessDetailsData from "./constants";
import constants from "../../constants";
import "../../assets/styles/fonts.css";

export default function ProfileBusinessDetails({ agentId, setError, setLoader }) {

    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor } } = theme.useToken();
    const themeColorVariables = {
        paddingTop: '2%',
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor,
    }

    const [agentBusinessDetails, setAgentBusinessDetails] = useState({});

    useEffect(() => {
        setLoader({ loading: true, tip: 'Loading...' })
        ApiService.fetchAgentProfileData(agentId).then((data) => {
            if (!data) {
                setError(true);
                setLoader({ loading: false, tip: '' })
                return;
            }
            setAgentBusinessDetails(generateBuisnessDetailsData(data));
            setLoader({ loading: false, tip: '' })
        });
    }, [agentId, setError]);

    // Event Handlers
    const handleBusinessName = (e) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            businessName: {
                ...agentBusinessDetails.businessName,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        });
    }

    const handleTaxNumber = (e) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            taxNumber: {
                ...agentBusinessDetails.taxNumber,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        });
    }

    const handleAddress = (e) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            address: {
                ...agentBusinessDetails.address,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        });
    }

    const handleCity = (e) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            city: {
                ...agentBusinessDetails.city,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        });
    }

    const handleProvinceState = (value) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            provinceState: {
                ...agentBusinessDetails.provinceState,
                value: value,
                error: validate.RequiredField(value),
            },
        });
    }

    const handleCountry = (value) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            country: {
                ...agentBusinessDetails.country,
                value: value,
                error: validate.RequiredField(value),
            },
            provinceState: {
                ...agentBusinessDetails.provinceState,
                value: '',
                error: 'Required',
                dropdownContent: (value === 'USA') ? constants.AMERICAN_STATES : constants.CANADIAN_PROVINCES,
            },
            postalZipCode: {
                ...agentBusinessDetails.postalZipCode,
                value: '',
                error: 'Required'
            }
        });
    }

    const handlePostalZipCode = (e) => {
        setAgentBusinessDetails({
            ...agentBusinessDetails,
            postalZipCode: {
                ...agentBusinessDetails.postalZipCode,
                value: (agentBusinessDetails.country.value !== 'USA') ? validate.FormatCanadianPostalCode(e.target.value).input : validate.FormatAmericanZipCode(e.target.value).input,
                error: validate.RequiredField(e.target.value) || ((agentBusinessDetails.country.value !== 'USA') ? validate.FormatCanadianPostalCode(e.target.value).error : validate.FormatAmericanZipCode(e.target.value).error),
            },
        });
    }

    const handleSave = () => {
        if (!HasErrors(agentBusinessDetails)) {
            const data = {
                ap_businessName: agentBusinessDetails.businessName.value,
                ap_taxNumber: agentBusinessDetails.taxNumber.value,
                ap_address: agentBusinessDetails.address.value,
                ap_city: agentBusinessDetails.city.value,
                ap_stateOrProvince: agentBusinessDetails.provinceState.value,
                ap_province: (agentBusinessDetails.country.value === "USA") ? '' : agentBusinessDetails.provinceState.value,
                ap_state: (agentBusinessDetails.country.value === "USA") ? agentBusinessDetails.provinceState.value : '',
                ap_country: agentBusinessDetails.country.value,
                ap_postalCode: agentBusinessDetails.postalZipCode.value,
            }

            setLoader({ loading: true, tip: 'Saving...' });
            ApiService.updateAgentProfile(agentId, data).then((response) => {
                setLoader({ loading: false, tip: '' });
                if (response.success)
                    NotificationService.success("Profile Updated Successdully")
                else NotificationService.error("Profile Update Failed. Please try again later")
            });
        }
    }

    const businessName = {
        businessName: {
            ...agentBusinessDetails.businessName,
            eventHandler: handleBusinessName,
        },
    }

    const taxNumber = {
        taxNumber: {
            ...agentBusinessDetails.taxNumber,
            eventHandler: handleTaxNumber,
        },
    }

    const address = {
        address: {
            ...agentBusinessDetails.address,
            eventHandler: handleAddress,
        },
    }

    const city = {
        city: {
            ...agentBusinessDetails.city,
            eventHandler: handleCity,
        },
    }

    const provinceState = {
        city: {
            ...agentBusinessDetails.provinceState,
            eventHandler: handleProvinceState,
        },
    }

    const country = {
        city: {
            ...agentBusinessDetails.country,
            eventHandler: handleCountry,
        },
    }

    const postalZipCode = {
        city: {
            ...agentBusinessDetails.postalZipCode,
            eventHandler: handlePostalZipCode,
        },
    }


    return (
        <Layout style={themeColorVariables} className={styles["profileContainer"]}>
            <div className={styles["profileContainer"]}>
                <div className={styles["profileInnerContainer"]}>
                    <div className={styles["businessDetails_Layout"]}>
                        <InputLayout data={businessName} layout="vertical" />
                        <InputLayout data={taxNumber} layout="vertical" />
                        <InputLayout data={address} layout="vertical" />
                        <InputLayout data={city} layout="vertical" />
                        <InputLayout data={provinceState} layout="vertical" />
                        <InputLayout data={country} layout="vertical" />
                        <InputLayout data={postalZipCode} layout="vertical" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button
                            onClick={handleSave}
                            type="primary"
                            className={styles["SaveButton"]}
                        >Save</Button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}