import { Button, Form, Upload, theme } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import ApiService from '../../api';
import styles from './index.module.css';

export default function UploadComponent({ data, item, layout, newApp }) {

    const { token: { inputColorBg }, } = theme.useToken();

    const handleUpload = (info) => {
        // Locally Stored File

        let fileList = [...info.fileList];
        // Allows one upload
        if (!data[item].multiple) {
            fileList = fileList.slice(-1);
        }
        fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        for (let i = 0; i < fileList.length; i++) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileData = {
                    data: base64Data,
                    fileName: fileList[i].name
                }
                ApiService.uploadDocs(fileData).then((resp) => {
                    const tempData = {
                        ...data[item].state,
                    }
                    tempData[item].value = fileList;
                    const tempArr = [...tempData[item].file]
                    tempArr.push({
                        fileName: fileList[i].name,
                        url: resp.data.url,
                    });
                    tempData[item].file = tempArr;
                    tempData[item].fileList = [...tempData[item].fileList, fileList[i]];
                    tempData[item].error = '';
                    data[item].setFormData(tempData);
                })
            }
            reader.readAsDataURL(fileList[i].originFileObj);
        }
    }

    const handleRemove = (record) => {
        const tempData = {
            ...data[item].state,
            item: {
                ...data[item],
            }
        }

        // Remove file from tempData[item].file if URL matches
        const fileIndex = tempData[item].file.findIndex(file => file.url === record.url);
        if (fileIndex !== -1) {
            tempData[item].file.splice(fileIndex, 1);
        }

        // Remove file from tempData[item].fileList if UID matches
        const fileListIndex = tempData[item].fileList.findIndex(file => file.uid === record.uid);
        if (fileListIndex !== -1) {
            tempData[item].fileList.splice(fileListIndex, 1);
        }

        // Clear other fields
        tempData[item].value = [];
        data[item].setFormData(tempData);
    }

    const files = [...data[item].value, ...data[item].file];
    const props = {
        listType: "picture",
        onRemove: handleRemove,
        beforeUpload: (file) => {
            data[item].setFormData({
                ...data[item].state,
                item: {
                    ...data[item],
                    value: [...file]
                }
            });
            return false;
        },
        files,
    }
    let fileList = [];
    if (newApp && data[item].value.length > 0) {
        fileList = [...data[item].value];
    } else if (data[item].fileList) {
        fileList = [...data[item].fileList];
    }
    return (
        <>
            {data[item].hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <>
                        <Form.Item
                            key={item}
                            label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                        >
                            {layout == 'vertical' ?
                                <div style={{ marginBottom: '20px', height: 'fit-content', }}>
                                    <Upload {...props} style={{ height: 'fit-content', }} onChange={handleUpload} fileList={fileList}>
                                        <Button
                                            icon={<UploadOutlined />}
                                            className={styles["uploadButtons_vertical"]}
                                            disabled={(!data[item].multipleFiles && (data[item].value.length == 1 || data[item].file.length == 1))}
                                        >
                                            {data[item].label}
                                        </Button>
                                    </Upload>
                                    {!!data[item].error && <div style={{ color: 'red', fontSize: 'smaller' }}>{data[item].error}</div>}
                                </div>
                                : null
                            }
                        </Form.Item>

                        {layout == 'horizontal' ?
                            <div style={{ marginBottom: '20px', height: 'fit-content', width: '400px' }}>
                                <Upload {...props} style={{ height: 'fit-content', }} onChange={handleUpload} fileList={fileList}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className={styles[data[item].error ? "uploadButtons_horizontal_error" : "uploadButtons_horizontal"]}
                                        disabled={(!data[item].multipleFiles && (data[item].value.length == 1 || data[item].file.length == 1))}
                                    >
                                        {data[item].label}
                                    </Button>
                                </Upload>
                                {!!data[item].error && <div style={{ color: 'red', fontSize: 'smaller' }}>{data[item].error}</div>}
                            </div>
                            : null}
                    </>
                </div>
            )}
        </>
    )
}