import { useNavigate } from "react-router-dom";
import { Button, Divider, theme } from "antd";
import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";
import RequirementCategories from "../../../assets/images/icons/RequirementCategories";
import validate from "../../../utils/Validations";
import styles from "./index.module.css";

export default function NewRequirements({ notificationData }) {

    const navigate = useNavigate();

    const { token: { ap_userPrimaryColor } } = theme.useToken();

    const clientTelephone = `tel:${notificationData.ap_data.clientTelephone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`

    return (
        <div className={styles["Notification"]}>
            <div>
                <NotificationScreenIcons.NewRequirementsIcon />
            </div>

            <div
                className={styles["NotificationTitle"]}
                style={{ color: ap_userPrimaryColor }}
            >Action Required</div>

            <div className={styles["Notification-ContactDetails"]}>

                <div className={styles["Notification_TextTransform"]}>
                    {notificationData.ap_data.clientBusinessName}
                </div>

                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData.ap_data.clientTelephone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>

            </div>

            <div className={styles["Notification_RequirementList"]}>

                <div className={styles["Notification_Bold"]}>
                    Action is required on your end. Please complete, upload, and submit the following:
                </div>

                <div className={styles["Notification_RequirementListContainer"]}>

                    {(notificationData?.ap_data?.requirementsList).map(req => (
                        <>
                            <div className={styles["Notification_IndividualBlock"]}>

                                <div className={styles["Notification_IndividualBlockGrid"]}>

                                    <div className={styles["Notification_WidthCenter"]}>

                                        <RequirementCategories requirement={req} />

                                    </div>

                                    <div className={styles["Notification_HeightCenter"]}>
                                        {req?.name}
                                    </div>

                                </div>

                            </div>

                            <Divider className={styles["Notification_Divider"]} />
                        </>
                    ))}

                </div>

            </div>

            <div className={styles["Notification_ButtonDiv"]}>

                <Button
                    type="primary"
                    onClick={() => navigate(`/requirements/${notificationData.ap_entityId}`)}
                    className={styles["Notification_Button"]}
                >Manage Requirements</Button>

            </div>



        </div>
    );
}