export default [
    {
        label: 'Dodge',
        value: 'Dodge',
    },
    {
        label: 'Ford',
        value: 'Ford',
    },
    {
        label: 'Freightliner',
        value: 'Freightliner',
    },
    {
        label: 'International',
        value: 'International',
    },
    {
        label: 'Kenworth',
        value: 'Kenworth',
    },
    {
        label: 'Mack',
        value: 'Mack',
    },
    {
        label: 'Peterbilt',
        value: 'Peterbilt',
    },
    {
        label: 'Sterling',
        value: 'Sterling',
    },
    {
        label: 'Volvo',
        value: 'Volvo',
    },
    {
        label: 'Western Star',
        value: 'Western Star',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];