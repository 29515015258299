import ApiService from "../../api";
import NotificationService from "../../services/notification.service";
import AddUserConstants from "./constants";

const handleModal = (setOpen) => {
    if (setOpen)
        setOpen(prev => !prev);
}

const handleEmailAddress = (value, fieldName, setNewUserData) => {
    if (fieldName && setNewUserData)
        setNewUserData(prevUserData => ({
            ...prevUserData,
            [fieldName]: value
        }));
}

const handleSendInvitation = (newUserData, setNewUserData, setInvokeValidation, setOpen, setRefresh) => {

    setInvokeValidation(true);

    const updatedFieldData = AddUserConstants.generateAddUserFields(true, newUserData, setNewUserData)

    const errorExists = Object.keys(updatedFieldData).some((key) => updatedFieldData[key].error !== "");

    if (errorExists) {
        NotificationService.error("Please correct the form errors before sending the invitation");
        return;
    }

    ApiService.addNewUser(newUserData?.emailAddress).then((response) => {
        if (setRefresh)
            setRefresh(prev => prev + 1);
        NotificationService.success("Invitation sent successfully");
    })
    .catch((error) => {
        console.log(error);
    });

    // Close form
    if (setOpen)
        setOpen(false);
}

const exportUtils = {
    handleModal,
    handleEmailAddress,
    handleSendInvitation
}

export default exportUtils;