import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Spin, theme } from "antd";
import dayjs from "dayjs";
import ApiService from "../../api";
import { useSanitizedState } from "../../utils/santitizeHtml";
import styles from './index.module.css';

export default function CopyableRequirementEmail() {

    const { id } = useParams();
    const { token: { themeFontColor }, } = theme.useToken();

    const [data, setData] = useState({});
    const [recipients, setRecipients] = useState('');
    const [recipientsName, setRecipientsName] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useSanitizedState('');
    const [copyableBody, setCopyableBody] = useSanitizedState('');
    const [copiedButtonText, setCopiedButtonText] = useState('Copy');
    const [loader, setLoader] = useState({ loading: false, tip: '' });

    useEffect(() => {
        if (!id) return;

        setLoader({ loading: true, tip: 'Loading...' })
        ApiService.fetchCopyableRequirement(id, { color: themeFontColor }).then(response => {
            setData({
                requirements: response?.data,
                agentLogo: response?.logo,
            })
            setRecipients(response?.recipients);
            setRecipientsName(response?.recipientsName);
            setSubject(response?.subject);

            setBody(response?.body);
            setCopyableBody(response?.copyableBody);
            setLoader({ loading: false, tip: '' });
        });
    }, [id]);

    const sendNow = () => {
        window.open(`mailto:${recipients}?subject=${encodeURIComponent(subject)}`, '_blank')
    }

    const copyToClipboard = () => {
        const range = document.createRange();
        range.selectNode(document.getElementById('copyable'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');

        // Update the text of the button to indicate that the text has been copied
        setCopiedButtonText('Copied!');

        // Set a timeout to change the button text back to 'Copy' after 5 seconds
        setTimeout(() => {
            setCopiedButtonText('Copy');
        }, 5000);
    };

    return (
        <Spin spinning={loader?.loading} tip={loader?.tip}>
            <div className={styles["Layout"]}>

                <div className={styles["Container"]}>
                    <div className={styles["FlexSubject"]}>
                        <div><b>{data?.requirements?.ap_agentId?.ap_name}</b> to <b>{recipientsName}</b></div>
                        <div>{dayjs().format('MMMM D[th], YYYY | hh:mm A')}</div>
                    </div>

                    <div className={styles["Subject"]}>{subject}</div>

                </div>

                <div className={styles["Container"]}>
                    <div
                        dangerouslySetInnerHTML={{ __html: body }}
                        className={styles["EmailBody"]}
                    />
                </div>

                <div className={styles["CopyableEmailBody"]}>
                    <div
                        id="copyable"
                        dangerouslySetInnerHTML={{ __html: copyableBody }}
                        className={styles["EmailBody"]}
                    />
                </div>


                <div className={styles["ActionButtons"]}>
                    <Button
                        type="primary"
                        onClick={copyToClipboard}
                        className={styles["CopyButton"]}
                    >{copiedButtonText}</Button>

                    <Button
                        type="primary"
                        onClick={sendNow}
                        className={styles["SendNow"]}
                    >Send Now</Button>
                </div>
            </div>
        </Spin>
    );
}
