import { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import RequestAssetUtils from "./utils";
import RequestAssetConstants from "./constants";

import styles from "./index.module.css";
import InputLayout from "../InputLayout";
import utils from "../../utils";

export default function RequestAsset({ requestAsset = false, setRequestAsset = false }) {

    const [data, setData] = useState({
        assetType: '',
        make: '',
        model: '',
        minPrice: '',
        maxPrice: '',
        minMileageHours: '',
        maxMileageHours: '',
        additionalDetails: '',
        invokeValidation: false,
    });
    const [formFields, setFormFields] = useState(RequestAssetConstants?.RequestAssetFormFields(data, setData));

    useEffect(() => {
        setFormFields(RequestAssetConstants?.RequestAssetFormFields(data, setData));
    }, [data]);



    const InputField = (label, valueName, prefix, suffix, minLabel, maxLabel) => {
        const min = RequestAssetUtils?.toNumber(data?.[minLabel]);
        const max = RequestAssetUtils?.toNumber(data?.[maxLabel]);
        const message = (min || min === 0) && (max || max === 0) && min > max ? 'Min value should be less than Max value' : '';

        const invalidValues = [undefined, '', null];
        const isInvalid = value => invalidValues.includes(value);

        return (
            <div>
                <div className={styles[(data?.invokeValidation && (message || isInvalid(data?.[valueName]))) ? "RequestAccess_Input-Error" : "RequestAccess_Input"]}>
                    <span>{label}</span>
                    <Input
                        variant="borderless"
                        value={utils?.NumberWithCommas(data?.[valueName])}
                        prefix={prefix}
                        suffix={suffix}
                        status={(data?.invokeValidation && (message || isInvalid(data?.[valueName]))) ? 'error' : ''}
                        onChange={(e) => RequestAssetUtils.handlePriceInputs(e?.target?.value, valueName, setData)}
                    />
                </div>
                <span className={styles[(data?.invokeValidation && (message || isInvalid(data?.[valueName]))) ? 'RequestAccess_Input_Error' : 'Hide']}>{message || 'Required'}</span>
            </div>
        )
    }

    return (
        <Modal
            centered
            closable={false}
            footer={null}
            getContainer=".AppTheme"
            open={requestAsset}
            width="600px"
        >
            <div className={styles["RequestAsset_Title"]}>
                <CloseOutlined onClick={() => RequestAssetUtils.handleRequestAssetModal(setRequestAsset, setData)} />
                <div>Request Asset</div>
            </div>

            <div className={styles["RequestAsset_Header"]}>
                If you can't find the asset you're looking for, please complete this form so our sales team can better understand your needs and assist you in obtaining your desired asset(s) as quickly as possible.
            </div>

            <InputLayout
                data={formFields}
                layout="vertical"
            />

            <span className={styles["RequestAsset_Label"]}>KMs / Hours</span>
            <div className={styles["RequestAsset_Container"]}>
                {InputField('Min. Mileage/Hours', 'minMileageHours', '', 'Kms/Hours', 'minMileageHours', 'maxMileageHours')}
                {InputField('Max. Mileage/Hours', 'maxMileageHours', '', 'Kms/Hours', 'minMileageHours', 'maxMileageHours')}
            </div>

            <span className={styles["RequestAsset_Label"]}>Price Range</span>
            <div className={styles["RequestAsset_Container"]}>
                {InputField('Min. Price', 'minPrice', '$', '', 'minPrice', 'maxPrice')}
                {InputField('Max. Price', 'maxPrice', '$', '', 'minPrice', 'maxPrice')}
            </div>

            <InputLayout
                data={RequestAssetConstants?.RequestAssetAdditionalDetails(data, setData)}
                layout="vertical"
                autoSize={{ minRows: 5, maxRows: 5 }}
            />

            <div className={styles["RequestAsset_Action"]}>
                <Button
                    type="primary"
                    onClick={() => RequestAssetUtils.handleSubmit(data, setData, setRequestAsset)}
                >Submit Request</Button>
            </div>
        </Modal>
    )
}