import { Button, ConfigProvider, Table, theme } from "antd";
import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";
import validate from "../../../utils/Validations";
import NotificationDetailsBlock from "../../NotificationDetailBlock";
import NotifConstants from "./constants";

import styles from "./index.module.css";
import ApiService from "../../../api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSanitizedState } from "../../../utils/santitizeHtml";

export default function SigningAuditing({ notificationData }) {
    const navigate = useNavigate();
    const [body, setBody] = useSanitizedState('');

    const { token: { themePrimaryColor, themeFontColor }, } = theme.useToken();
    const themeTokens = {
        token: {
            colorFillAlter: themePrimaryColor,
            colorBgContainer: themePrimaryColor,
            colorFillSecondary: themePrimaryColor,
            colorText: themeFontColor,
            colorIcon: themeFontColor,
            colorTextPlaceholder: themeFontColor,
        }
    }

    const [documentDetails, setDocumentDetails] = useState([]);
    const [requirementsId, setRequirementsId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!notificationData) return;

        if (notificationData?.ap_data?.ap_instructions)
            setBody(notificationData?.ap_data?.ap_instructions);

        ApiService.fetchArrayFileDetails(notificationData?.ap_data?.ap_documents).then(response => {
            setDocumentDetails(response);
            setLoading(false);
        });

        setLoading(true);
        ApiService.fetchDrawdownOutstandingRequirements(notificationData?.ap_agentId, notificationData?.ap_entityId).then(reqResponse => {
            if (!reqResponse || reqResponse?.length === 0) {
                return;
            }

            const requirement = reqResponse[0];

            if (!requirement?.ap_requirementsList || !requirement?.ap_requirementsList?.length === 0) return;

            const tempArr = [];
            for (const item of requirement?.ap_requirementsList) {
                if (!item.checked) {
                    tempArr.push(item.name);
                }
            }
            setRequirementsId(tempArr.length > 0 ? requirement._id : null)
            setLoading(false);
        })
    }, [notificationData]);

    const clientTelephone = `tel:${notificationData?.ap_data?.ap_clientTelephone}`;
    const clientEmail = `mailto:${notificationData?.ap_data?.ap_clientEmail}`;
    const details = NotifConstants?.generateDetails(notificationData?.ap_data);

    return (
        <div className={styles["NotificationsBody"]}>

            <div><NotificationScreenIcons.SigningAuditingIcon /></div>

            <div className={styles["NotificationTitle"]}>Sign Lease Documents</div>

            <div>Please ensure that the client signs the lease documents and return the signed documents to us along with any other requirements mentioned in the instructions.</div>

            <div className={styles["ClientInfo_Container"]}>
                <div>{notificationData?.ap_data?.ap_clientName}</div>
                <div>
                    <a href={clientTelephone}>{validate.FormatTelephone(String(notificationData?.ap_data?.ap_clientTelephone))?.input}</a> | <a href={clientEmail}>{notificationData?.ap_data?.ap_clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]}>
                <div className={styles["NotificationDetailsGrid"]}>
                    <NotificationDetailsBlock data={details.drawdownDetails} />
                    <div className={styles["NotificationDetailsBlock"]}>
                        <div className={styles["NotificationDetailsTitle"]}>Documents</div>
                        <ConfigProvider theme={themeTokens}>
                            <Table
                                columns={NotifConstants?.generateTableColumns()}
                                dataSource={documentDetails}
                                pagination={false}
                                loading={loading}
                                scroll={{ y: 130 }}
                            />
                        </ConfigProvider>
                    </div>
                </div>
                {/* {!details?.instructionDetails?.hidden && (
                    <div className={styles["NotificationDetailsBlock"]} dangerouslySetInnerHTML={{ __html: notificationData?.ap_data?.ap_instructions }} />
                )} */}

                {!details?.instructionDetails?.hidden && (
                    <div className={styles["NotificationDetailsBlock"]}>
                        <div className={styles["NotificationDetailsTitle"]}>INSTRUCTIONS</div>
                        <div dangerouslySetInnerHTML={{ __html: body }} />
                    </div>
                )}


            </div>

            {/* <div className={styles["InstructionsTitle"]}>Next Steps</div>
            <div className={styles["Instructions_SubTitle"]}>Please send us the ownership for <span>{notificationData?.ap_data?.ap_assetDescription}</span> once you have received it.</div> */}

            <div style={{ padding: "3%" }}>
                {requirementsId ? <Button
                    type="primary"
                    style={{ height: "40px" }}
                    onClick={() => navigate(`/requirements/${requirementsId}`)}
                >Upload Signed Docs</Button> : <> </>}
            </div>

        </div>
    )
}