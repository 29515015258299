import Icon from '@ant-design/icons';

function ApproachingExpiry() {
    return (
        <svg width="100" height="102" viewBox="0 0 128 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M123.737 121.875C123.737 124.03 122.871 126.097 121.33 127.62C119.789 129.144 117.699 130 115.52 130C113.341 130 111.251 129.144 109.71 127.62C108.17 126.097 107.304 124.03 107.304 121.875C107.304 119.72 108.17 117.653 109.71 116.13C111.251 114.606 113.341 113.75 115.52 113.75C117.699 113.75 119.789 114.606 121.33 116.13C122.871 117.653 123.737 119.72 123.737 121.875Z" fill="#447EB7" />
            <path d="M67.6604 0.0162431C58.0969 -0.189572 48.6033 1.67127 39.8423 5.46885C31.0812 9.26643 23.264 14.9092 16.9362 22.0033C10.6085 29.0973 5.9228 37.4717 3.20625 46.5417C0.489707 55.6116 -0.192206 65.1587 1.20812 74.5162C2.60845 83.8738 6.05727 92.8163 11.3138 100.719C16.5703 108.623 23.5079 115.296 31.6421 120.274C39.7764 125.251 48.9112 128.413 58.409 129.539C67.9068 130.665 77.5385 129.727 86.6318 126.791C88.1855 126.289 89.4739 125.197 90.2135 123.755C90.9531 122.314 91.0833 120.641 90.5756 119.104C90.0679 117.568 88.9637 116.294 87.5061 115.562C86.0485 114.831 84.3567 114.702 82.803 115.204C72.9213 118.395 62.3128 118.665 52.2784 115.98C42.244 113.296 33.22 107.773 26.3131 100.091C19.4062 92.4086 14.9166 82.8998 13.3951 72.7309C11.8735 62.562 13.386 52.1749 17.7472 42.8437C22.1083 33.5125 29.1285 25.6427 37.9467 20.1995C46.765 14.7563 56.9979 11.9764 67.3906 12.2006C77.7833 12.4248 87.8838 15.6435 96.4536 21.4618C105.023 27.2801 111.69 35.4452 115.635 44.9556C116.255 46.4511 117.451 47.6418 118.958 48.2658C119.705 48.5747 120.506 48.7353 121.315 48.7382C122.124 48.7411 122.926 48.5864 123.675 48.2828C124.424 47.9792 125.105 47.5328 125.679 46.969C126.253 46.4052 126.71 45.735 127.022 44.9967C127.335 44.2585 127.497 43.4666 127.5 42.6663C127.503 41.866 127.346 41.073 127.039 40.3325C122.184 28.6267 113.979 18.5768 103.431 11.4153C92.8837 4.25388 80.4518 0.292213 67.6604 0.0162431Z" fill="#EDCB00" />
            <path d="M72.3848 30.4687C72.3848 28.8526 71.7355 27.3026 70.5799 26.1598C69.4243 25.017 67.8569 24.375 66.2225 24.375C64.5882 24.375 63.0208 25.017 61.8652 26.1598C60.7096 27.3026 60.0603 28.8526 60.0603 30.4687V62.4812L43.3484 78.9994C42.743 79.5572 42.2574 80.23 41.9206 80.9775C41.5838 81.725 41.4027 82.5319 41.3881 83.3501C41.3735 84.1683 41.5257 84.9811 41.8356 85.7399C42.1455 86.4986 42.6068 87.1879 43.192 87.7666C43.7771 88.3452 44.4742 88.8014 45.2415 89.1079C46.0088 89.4144 46.8306 89.5649 47.658 89.5504C48.4854 89.536 49.3014 89.3569 50.0573 89.0238C50.8132 88.6908 51.4935 88.2106 52.0577 87.6119L70.5772 69.3062L72.3848 67.5187V30.4687Z" fill="#EDCB00" />
            <path d="M121.682 71.0937C121.682 69.4776 121.033 67.9276 119.878 66.7848C118.722 65.642 117.155 65 115.52 65C113.886 65 112.319 65.642 111.163 66.7848C110.007 67.9276 109.358 69.4776 109.358 71.0937V99.5312C109.358 101.147 110.007 102.697 111.163 103.84C112.319 104.983 113.886 105.625 115.52 105.625C117.155 105.625 118.722 104.983 119.878 103.84C121.033 102.697 121.682 101.147 121.682 99.5312V71.0937Z" fill="#447EB7" />
        </svg>
    );
}

export default function ApproachingExpiryIcon(props) {
    return <Icon component={ApproachingExpiry} {...props} />
}