import Icon from '@ant-design/icons';
import { theme } from 'antd';

const Message = () => {
    const { token: { themeFontColor } } = theme.useToken();
    return (
        <svg width="23" height="20" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0738525 3.3C0.0738525 2.42479 0.42153 1.58542 1.0404 0.966548C1.65927 0.347677 2.49864 0 3.37385 0H29.7739C30.6491 0 31.4884 0.347677 32.1073 0.966548C32.7262 1.58542 33.0739 2.42479 33.0739 3.3V21.45C33.0739 22.3252 32.7262 23.1646 32.1073 23.7835C31.4884 24.4023 30.6491 24.75 29.7739 24.75H22.207L17.7404 29.2166C17.431 29.5259 17.0114 29.6997 16.5739 29.6997C16.1363 29.6997 15.7167 29.5259 15.4073 29.2166L10.9408 24.75H3.37385C2.49864 24.75 1.65927 24.4023 1.0404 23.7835C0.42153 23.1646 0.0738525 22.3252 0.0738525 21.45V3.3ZM29.7739 3.3H3.37385V21.45H11.6239C12.0614 21.4501 12.481 21.624 12.7904 21.9335L16.5739 25.7169L20.3573 21.9335C20.6667 21.624 21.0863 21.4501 21.5239 21.45H29.7739V3.3Z" fill={themeFontColor} />
            <path d="M19.0488 12.3749C19.0488 13.0313 18.7881 13.6608 18.3239 14.125C17.8598 14.5891 17.2303 14.8499 16.5738 14.8499C15.9174 14.8499 15.2879 14.5891 14.8238 14.125C14.3596 13.6608 14.0988 13.0313 14.0988 12.3749C14.0988 11.7185 14.3596 11.089 14.8238 10.6248C15.2879 10.1607 15.9174 9.8999 16.5738 9.8999C17.2303 9.8999 17.8598 10.1607 18.3239 10.6248C18.7881 11.089 19.0488 11.7185 19.0488 12.3749ZM25.6488 12.3749C25.6488 13.0313 25.3881 13.6608 24.9239 14.125C24.4598 14.5891 23.8303 14.8499 23.1738 14.8499C22.5174 14.8499 21.8879 14.5891 21.4238 14.125C20.9596 13.6608 20.6988 13.0313 20.6988 12.3749C20.6988 11.7185 20.9596 11.089 21.4238 10.6248C21.8879 10.1607 22.5174 9.8999 23.1738 9.8999C23.8303 9.8999 24.4598 10.1607 24.9239 10.6248C25.3881 11.089 25.6488 11.7185 25.6488 12.3749ZM12.4488 12.3749C12.4488 13.0313 12.1881 13.6608 11.7239 14.125C11.2598 14.5891 10.6303 14.8499 9.97384 14.8499C9.31743 14.8499 8.6879 14.5891 8.22375 14.125C7.7596 13.6608 7.49884 13.0313 7.49884 12.3749C7.49884 11.7185 7.7596 11.089 8.22375 10.6248C8.6879 10.1607 9.31743 9.8999 9.97384 9.8999C10.6303 9.8999 11.2598 10.1607 11.7239 10.6248C12.1881 11.089 12.4488 11.7185 12.4488 12.3749Z" fill={themeFontColor} />
        </svg>
    );
}

export default function MessageIcon(props) {
    return <Icon component={Message} {...props} />
}