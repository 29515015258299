import { theme } from 'antd';
import Icon from '@ant-design/icons';

const HighlightColor = () => {
    const { token: { themeFontColor }, } = theme.useToken();
    return (
        <svg width="18" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.16675 16C7.11617 16 6.07589 15.7931 5.10528 15.391C4.13468 14.989 3.25276 14.3997 2.50989 13.6569C1.0096 12.1566 0.166748 10.1217 0.166748 8C0.166748 5.87827 1.0096 3.84344 2.50989 2.34315C4.01019 0.842855 6.04502 0 8.16675 0C12.5667 0 16.1667 3.2 16.1667 7.2C16.1667 8.47304 15.661 9.69394 14.7609 10.5941C13.8607 11.4943 12.6398 12 11.3667 12H9.92675C9.68675 12 9.52675 12.16 9.52675 12.4C9.52675 12.48 9.60675 12.56 9.60675 12.64C9.92675 13.04 10.0867 13.52 10.0867 14C10.1667 15.12 9.28675 16 8.16675 16ZM8.16675 1.6C6.46936 1.6 4.8415 2.27428 3.64126 3.47452C2.44103 4.67475 1.76675 6.30261 1.76675 8C1.76675 9.69739 2.44103 11.3253 3.64126 12.5255C4.8415 13.7257 6.46936 14.4 8.16675 14.4C8.40675 14.4 8.56675 14.24 8.56675 14C8.56675 13.84 8.48675 13.76 8.48675 13.68C8.16675 13.28 8.00675 12.88 8.00675 12.4C8.00675 11.28 8.88675 10.4 10.0067 10.4H11.3667C12.2154 10.4 13.0294 10.0629 13.6295 9.46274C14.2296 8.86263 14.5667 8.04869 14.5667 7.2C14.5667 4.08 11.6867 1.6 8.16675 1.6ZM3.76675 6.4C4.40675 6.4 4.96675 6.96 4.96675 7.6C4.96675 8.24 4.40675 8.8 3.76675 8.8C3.12675 8.8 2.56675 8.24 2.56675 7.6C2.56675 6.96 3.12675 6.4 3.76675 6.4ZM6.16675 3.2C6.80675 3.2 7.36675 3.76 7.36675 4.4C7.36675 5.04 6.80675 5.6 6.16675 5.6C5.52675 5.6 4.96675 5.04 4.96675 4.4C4.96675 3.76 5.52675 3.2 6.16675 3.2ZM10.1667 3.2C10.8067 3.2 11.3667 3.76 11.3667 4.4C11.3667 5.04 10.8067 5.6 10.1667 5.6C9.52675 5.6 8.96675 5.04 8.96675 4.4C8.96675 3.76 9.52675 3.2 10.1667 3.2ZM12.5667 6.4C13.2067 6.4 13.7667 6.96 13.7667 7.6C13.7667 8.24 13.2067 8.8 12.5667 8.8C11.9267 8.8 11.3667 8.24 11.3667 7.6C11.3667 6.96 11.9267 6.4 12.5667 6.4Z" fill={themeFontColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8037 12.3081C12.9062 12.2056 13.0467 12.1602 13.2117 12.1602C13.3788 12.1602 13.5205 12.2049 13.6205 12.3088C13.7232 12.412 13.7677 12.5562 13.7677 12.7263V13.5824H15.0598V12.7263C15.0598 12.5568 15.1023 12.4128 15.2013 12.3095L15.2026 12.3081C15.3055 12.2052 15.4481 12.1602 15.6158 12.1602C15.7802 12.1602 15.9199 12.2053 16.0195 12.3088C16.1222 12.412 16.1667 12.5562 16.1667 12.7263V15.434C16.1667 15.6019 16.1216 15.7451 16.0201 15.8508C15.9205 15.9548 15.7806 16.0002 15.6158 16.0002C15.4481 16.0002 15.3056 15.9551 15.2027 15.8522L15.2 15.8496C15.1026 15.7437 15.0598 15.601 15.0598 15.434V14.5274H13.7677V15.434C13.7677 15.601 13.7248 15.7436 13.6275 15.8495L13.6262 15.8509C13.5258 15.9556 13.3819 16.0002 13.2117 16.0002C13.0467 16.0002 12.9062 15.9548 12.8037 15.8522L12.8023 15.8509C12.7008 15.7452 12.6557 15.602 12.6557 15.434V12.7263C12.6557 12.5559 12.7004 12.4113 12.8037 12.3081Z" fill={themeFontColor} />
        </svg>
    );
}

export default function HighlightColorIcon(props) {
    return <Icon component={HighlightColor} {...props} />
}