function Documents(requirementsList = []) {
    const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;

    const docListObj = {};
    requirementsList.forEach((item, index) => {
        docListObj[index] = {
            name: item?.name,
            additionalInfo: item?.additionalInfo || null,
            required: true,
            hidden: false,
            resource: (item?.resource) ? `${item?.resource}?${sasToken}` : null,
            checked: false,
        }
    });
    return docListObj;
}

export default {
    Documents,
};