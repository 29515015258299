import Icon from '@ant-design/icons';
import { theme } from "antd";

function IndustryType() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="24" height="20" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.29707 0C1.02843 0 0 1.02843 0 2.29707V30.6956C0 31.9642 1.02843 32.9926 2.29707 32.9926H16.7556H25.7029C26.9716 32.9926 28 31.9642 28 30.6956V15.1817C28 13.913 26.9716 12.8846 25.7029 12.8846H17.053V2.29707C17.053 1.02843 16.0246 0 14.756 0H2.29707ZM2.75648 2.75648V30.2362H14.2966V2.75648H2.75648ZM17.215 15.6411V30.2362H25.2435V15.6411H17.215Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 5.76269C4.0441 5.25524 4.45547 4.84386 4.96292 4.84386H5.47804C5.98549 4.84386 6.39687 5.25524 6.39687 5.76269V6.27781C6.39687 6.78526 5.98549 7.19663 5.47804 7.19663H4.96292C4.45547 7.19663 4.0441 6.78526 4.0441 6.27781V5.76269Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 5.76269C7.31569 5.25524 7.72707 4.84386 8.23452 4.84386H8.74964C9.25709 4.84386 9.66846 5.25524 9.66846 5.76269V6.27781C9.66846 6.78526 9.25709 7.19663 8.74964 7.19663H8.23452C7.72707 7.19663 7.31569 6.78526 7.31569 6.27781V5.76269Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 5.76269C10.5873 5.25524 10.9987 4.84386 11.5061 4.84386H12.0212C12.5287 4.84386 12.9401 5.25524 12.9401 5.76269V6.27781C12.9401 6.78526 12.5287 7.19663 12.0212 7.19663H11.5061C10.9987 7.19663 10.5873 6.78526 10.5873 6.27781V5.76269Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 9.95311C4.0441 9.44566 4.45547 9.03429 4.96292 9.03429H5.47804C5.98549 9.03429 6.39687 9.44566 6.39687 9.95311V10.4682C6.39687 10.9757 5.98549 11.3871 5.47804 11.3871H4.96292C4.45547 11.3871 4.0441 10.9757 4.0441 10.4682V9.95311Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 9.95311C7.31569 9.44566 7.72707 9.03429 8.23452 9.03429H8.74964C9.25709 9.03429 9.66846 9.44566 9.66846 9.95311V10.4682C9.66846 10.9757 9.25709 11.3871 8.74964 11.3871H8.23452C7.72707 11.3871 7.31569 10.9757 7.31569 10.4682V9.95311Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 9.95311C10.5873 9.44566 10.9987 9.03429 11.5061 9.03429H12.0212C12.5287 9.03429 12.9401 9.44566 12.9401 9.95311V10.4682C12.9401 10.9757 12.5287 11.3871 12.0212 11.3871H11.5061C10.9987 11.3871 10.5873 10.9757 10.5873 10.4682V9.95311Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 14.1435C4.0441 13.6361 4.45547 13.2247 4.96292 13.2247H5.47804C5.98549 13.2247 6.39687 13.6361 6.39687 14.1435V14.6587C6.39687 15.1661 5.98549 15.5775 5.47804 15.5775H4.96292C4.45547 15.5775 4.0441 15.1661 4.0441 14.6587V14.1435Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 14.1435C7.31569 13.6361 7.72707 13.2247 8.23452 13.2247H8.74964C9.25709 13.2247 9.66846 13.6361 9.66846 14.1435V14.6587C9.66846 15.1661 9.25709 15.5775 8.74964 15.5775H8.23452C7.72707 15.5775 7.31569 15.1661 7.31569 14.6587V14.1435Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 14.1435C10.5873 13.6361 10.9987 13.2247 11.5061 13.2247H12.0212C12.5287 13.2247 12.9401 13.6361 12.9401 14.1435V14.6587C12.9401 15.1661 12.5287 15.5775 12.0212 15.5775H11.5061C10.9987 15.5775 10.5873 15.1661 10.5873 14.6587V14.1435Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 18.334C4.0441 17.8265 4.45547 17.4151 4.96292 17.4151H5.47804C5.98549 17.4151 6.39687 17.8265 6.39687 18.334V18.8491C6.39687 19.3565 5.98549 19.7679 5.47804 19.7679H4.96292C4.45547 19.7679 4.0441 19.3565 4.0441 18.8491V18.334Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 18.334C7.31569 17.8265 7.72707 17.4151 8.23452 17.4151H8.74964C9.25709 17.4151 9.66846 17.8265 9.66846 18.334V18.8491C9.66846 19.3565 9.25709 19.7679 8.74964 19.7679H8.23452C7.72707 19.7679 7.31569 19.3565 7.31569 18.8491V18.334Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 18.334C10.5873 17.8265 10.9987 17.4151 11.5061 17.4151H12.0212C12.5287 17.4151 12.9401 17.8265 12.9401 18.334V18.8491C12.9401 19.3565 12.5287 19.7679 12.0212 19.7679H11.5061C10.9987 19.7679 10.5873 19.3565 10.5873 18.8491V18.334Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 22.5244C4.0441 22.0169 4.45547 21.6056 4.96292 21.6056H5.47804C5.98549 21.6056 6.39687 22.0169 6.39687 22.5244V23.0395C6.39687 23.547 5.98549 23.9583 5.47804 23.9583H4.96292C4.45547 23.9583 4.0441 23.547 4.0441 23.0395V22.5244Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 22.5244C7.31569 22.0169 7.72707 21.6056 8.23452 21.6056H8.74964C9.25709 21.6056 9.66846 22.0169 9.66846 22.5244V23.0395C9.66846 23.547 9.25709 23.9583 8.74964 23.9583H8.23452C7.72707 23.9583 7.31569 23.547 7.31569 23.0395V22.5244Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 22.5244C10.5873 22.0169 10.9987 21.6056 11.5061 21.6056H12.0212C12.5287 21.6056 12.9401 22.0169 12.9401 22.5244V23.0395C12.9401 23.547 12.5287 23.9583 12.0212 23.9583H11.5061C10.9987 23.9583 10.5873 23.547 10.5873 23.0395V22.5244Z" fill={ap_userPrimaryColor} />
            <path d="M4.0441 26.7148C4.0441 26.2074 4.45547 25.796 4.96292 25.796H5.47804C5.98549 25.796 6.39687 26.2074 6.39687 26.7148V27.2299C6.39687 27.7374 5.98549 28.1488 5.47804 28.1488H4.96292C4.45547 28.1488 4.0441 27.7374 4.0441 27.2299V26.7148Z" fill={ap_userPrimaryColor} />
            <path d="M7.31569 26.7148C7.31569 26.2074 7.72707 25.796 8.23452 25.796H8.74964C9.25709 25.796 9.66846 26.2074 9.66846 26.7148V27.2299C9.66846 27.7374 9.25709 28.1488 8.74964 28.1488H8.23452C7.72707 28.1488 7.31569 27.7374 7.31569 27.2299V26.7148Z" fill={ap_userPrimaryColor} />
            <path d="M10.5873 26.7148C10.5873 26.2074 10.9987 25.796 11.5061 25.796H12.0212C12.5287 25.796 12.9401 26.2074 12.9401 26.7148V27.2299C12.9401 27.7374 12.5287 28.1488 12.0212 28.1488H11.5061C10.9987 28.1488 10.5873 27.7374 10.5873 27.2299V26.7148Z" fill={ap_userPrimaryColor} />
            <path d="M19.3685 17.1019C18.861 17.1019 18.4497 17.5132 18.4497 18.0207V18.5358C18.4497 19.0433 18.861 19.4546 19.3685 19.4546H19.8836C20.3911 19.4546 20.8024 19.0433 20.8024 18.5358V18.0207C20.8024 17.5132 20.3911 17.1019 19.8836 17.1019H19.3685Z" fill={ap_userPrimaryColor} />
            <path d="M22.6401 17.1019C22.1326 17.1019 21.7213 17.5132 21.7213 18.0207V18.5358C21.7213 19.0433 22.1326 19.4546 22.6401 19.4546H23.1552C23.6627 19.4546 24.074 19.0433 24.074 18.5358V18.0207C24.074 17.5132 23.6627 17.1019 23.1552 17.1019H22.6401Z" fill={ap_userPrimaryColor} />
            <path d="M18.4497 22.2111C18.4497 21.7037 18.861 21.2923 19.3685 21.2923H19.8836C20.3911 21.2923 20.8024 21.7037 20.8024 22.2111V22.7263C20.8024 23.2337 20.3911 23.6451 19.8836 23.6451H19.3685C18.861 23.6451 18.4497 23.2337 18.4497 22.7263V22.2111Z" fill={ap_userPrimaryColor} />
            <path d="M22.6401 21.2923C22.1326 21.2923 21.7213 21.7037 21.7213 22.2111V22.7263C21.7213 23.2337 22.1326 23.6451 22.6401 23.6451H23.1552C23.6627 23.6451 24.074 23.2337 24.074 22.7263V22.2111C24.074 21.7037 23.6627 21.2923 23.1552 21.2923H22.6401Z" fill={ap_userPrimaryColor} />
            <path d="M18.4497 26.4016C18.4497 25.8941 18.861 25.4828 19.3685 25.4828H19.8836C20.3911 25.4828 20.8024 25.8941 20.8024 26.4016V26.9167C20.8024 27.4242 20.3911 27.8355 19.8836 27.8355H19.3685C18.861 27.8355 18.4497 27.4242 18.4497 26.9167V26.4016Z" fill={ap_userPrimaryColor} />
            <path d="M22.6401 25.4828C22.1326 25.4828 21.7213 25.8941 21.7213 26.4016V26.9167C21.7213 27.4242 22.1326 27.8355 22.6401 27.8355H23.1552C23.6627 27.8355 24.074 27.4242 24.074 26.9167V26.4016C24.074 25.8941 23.6627 25.4828 23.1552 25.4828H22.6401Z" fill={ap_userPrimaryColor} />
        </svg>


    );
}

export default function IndustryTypeIcon(props) {
    return <Icon component={IndustryType} {...props} />
}