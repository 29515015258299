import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

class AppInsight {

  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.environment = process.env.REACT_APP_CURRENT_ENV;
    this.browserHistory = createBrowserHistory({ basename: '' });
    this.clickPlugin = new ClickAnalyticsPlugin();
    this.clickPluginConfig = {
      autoCapture: true
    };
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_AZURE_APP_INSIGHT,
        maxBatchSizeInBytes: 3000,
        maxBatchInterval: 3000,
        extensions: [this.reactPlugin, this.clickPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: { history: this.browserHistory },
          [this.clickPlugin.identifier]: this.clickPluginConfig
        }
      }
    });

    // TODO Uncomment once UAT Ready and comment/remove line 36-37
    // if (this.environment !== "DEV")
    // {
    //   this.appInsights.loadAppInsights(); // Loads AppInsight
    //   this.appInsights.trackPageView(); // Tracks Page View Session
    // }
    this.appInsights.loadAppInsights(); // Loads AppInsight
    this.appInsights.trackPageView(); // Tracks Page View Session
  }

  info = (message) => {
    let obj = {
      message: message,
      severityLevel: SeverityLevel.Information
    };

    if (this.environment === "DEV" || this.environment === "LOCAL") {
      return;
    }

    this.appInsights.trackTrace(obj);
  }

  warning = (message) => {

    let obj = {
      message: message,
      severityLevel: SeverityLevel.Warning
    };

    if (this.environment === "DEV" || this.environment === "LOCAL") {
      return;
    }

    this.appInsights.trackTrace(obj);
  }

  error = (error) => {
    let obj = {
      message: error,
      severityLevel: SeverityLevel.Error
    };

    if (this.environment === "DEV" || this.environment === "LOCAL") {
      return;
    }

    this.appInsights.trackException(obj);
  }

  event = (name, properties) => {

    let obj = {
      name,
      properties
    };

    if (this.environment === "DEV" || this.environment === "LOCAL") {
      return;
    }

    this.appInsights.trackEvent(obj);
  }

}

const appInsightsObj = new AppInsight();

export default appInsightsObj;