import { useEffect, useState } from "react";
import { Button, ConfigProvider, Table, theme } from "antd";

import ApiService from "../../api";

import AddUser from "../AddUser";
import UserListConstants from "./constants";

import styles from "./index.module.css";

export default function UsersList() {
    const { token: { themePrimaryColor, themeFontColor } } = theme.useToken();

    const [usersList, setUsersList] = useState([]);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        ApiService.fetchAgentUserData().then((userData) => {
            setUsersList(userData);
        });
    }, [refresh]);

    return (
        <>
            <div className={styles["UsersList_ButtonBlock"]}>
                <Button
                    type="primary"
                    onClick={() => setOpen(true)}
                >+ User</Button>
            </div>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: 50,
                    },
                }}
            >
                <Table
                    columns={UserListConstants.tableColumns}
                    dataSource={usersList}
                    pagination={false}
                />
            </ConfigProvider>

            <AddUser
                open={open}
                setOpen={setOpen}
                setRefresh={setRefresh}
            />
        </>
    )
}