import HomeContent from "../../components/HomeContent";
import ChangePasswordLayout from "../../layouts/ChangePassword";

export default function ChangePasswordPage() {
    return (
        <HomeContent
            contentHeader={<div style={{ fontSize: "24px" }}>Change Password</div>}
            content={<ChangePasswordLayout/>}
            bgColor="transparent"
        />
    );
}
