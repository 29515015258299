import dayjs from "dayjs";
import utils from ".";

// This function takes in sorting rules and data, and sorts the data accordingly
export default function ProcessSortingRules(sortobj, data) {

    const tableData = [...data];

    let keyWithValueInOrder;
    let valueInOrder;
    let dataType;

    // Iterate over the keys in the sorting object to find the key and order
    Object.keys(sortobj).forEach(key => {
        if (typeof sortobj[key].order === 'string') {
            keyWithValueInOrder = key;
            valueInOrder = sortobj[key].order;
            dataType = sortobj[key].type;
        }
    });

    // Check if a key and order were found
    if (keyWithValueInOrder && valueInOrder) {
        // Sorting data based on the specified key and order
        if (valueInOrder === 'ascend') {

            if (dataType === 'currency')
                return tableData.sort((a, b) => String(utils.TransformCurrencyToNumber(a[keyWithValueInOrder])).localeCompare(String(utils.TransformCurrencyToNumber(b[keyWithValueInOrder]))));

            // If the data type is date, use dayjs to compare dates
            if (dataType === 'date')
                return tableData.sort((a, b) => dayjs(a[keyWithValueInOrder]).unix() - dayjs(b[keyWithValueInOrder]).unix());

            // For other data types, use localeCompare for string comparison
            return tableData.sort((a, b) => (a[keyWithValueInOrder] || "").localeCompare(b[keyWithValueInOrder] || ""));

        } else if (valueInOrder === 'descend') {

            if (dataType === 'currency')
                return tableData.sort((a, b) => utils.TransformCurrencyToNumber(a[keyWithValueInOrder]).localeCompare(utils.TransformCurrencyToNumber(b[keyWithValueInOrder])));

            // If the data type is date, use dayjs to compare dates in reverse order
            if (dataType === 'date')
                return tableData.sort((a, b) => dayjs(b[keyWithValueInOrder]).unix() - dayjs(a[keyWithValueInOrder]).unix());

            // For other data types, use localeCompare for string comparison in reverse order
            return tableData.sort((a, b) => (b[keyWithValueInOrder] || "").localeCompare(a[keyWithValueInOrder] || ""));

        }
    } else {
        // If no sorting is needed, return tableData directly
        return tableData;
    }
}