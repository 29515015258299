// TODO: Move the themes into a database and create a service to get the themes from the database
const themes = {
    light: {
        value: "Light",
        themePrimaryColor: "#FFFFFF",
        themeSecondaryColor: "#F0F1F5",
        themeDangerColor: "#FF5555",
        themeFontColor: "black",
        themeDisabledContainer: "#CCCCCC",
        themeDisabledText: "#EEEEEE",
    },
    dark: {
        value: "Dark",
        themePrimaryColor: "#3D3D3D",
        themeSecondaryColor: "#2A2A2A",
        themeDangerColor: "#FF5555",
        themeFontColor: "white",
        themeDisabledContainer: "#525252",
        themeDisabledText: "#3D3D3D",
    }
}

export default themes;