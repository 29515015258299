import { useState } from "react";
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';

export default function CloseIcon() {

    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovered ?
                <CloseCircleFilled style={{ color: 'red' }} /> :
                <CloseOutlined style={{ color: 'red' }} />
            }
        </div>
    );

}