function HEXtoRGB(hex) {
    let r = parseInt(hex?.substring(1, 3), 16);
    let g = parseInt(hex?.substring(3, 5), 16);
    let b = parseInt(hex?.substring(5, 7), 16);
    return [r, g, b];
}

function RGBtoHEX(r, g, b) {
    return "#" +
        (r < 16 ? "0" : "") + r.toString(16) +
        (g < 16 ? "0" : "") + g.toString(16) +
        (b < 16 ? "0" : "") + b.toString(16);
}

function DarkenRGB([r, g, b], percent) {
    percent = Math.max(0, Math.min(100, percent));
    let factor = 1 - percent / 100;
    const darkR = Math.floor(r * factor);
    const darkG = Math.floor(g * factor);
    const darkB = Math.floor(b * factor);
    return [darkR, darkG, darkB];
}

export default function DarkenHexColor(hex, percent = 30) {
    if (!hex) return hex;
    let [r, g, b] = HEXtoRGB(hex);
    let [darkR, darkG, darkB] = DarkenRGB([r, g, b], percent);
    return RGBtoHEX(darkR, darkG, darkB);
}