import { useState } from "react";
import { useNavigate } from "react-router";
import { Image, Tooltip } from "antd";

import SpeedometerIcon from "../../assets/images/icons/ListingIcons/Speedometer";
import InterestedIcon from "../../assets/images/icons/InterestedIcon";
import ShareIcon from "../../assets/images/icons/ShareIcon";

import ListUtils from "./utils";
import utils from "../../utils";

import styles from "./index.module.css";

export default function ListingLists({ index, data, view, setRefresh }) {

    const cleanedData = { ...data };

    const [hoveredInterested, setHoveredInterested] = useState(false);
    const handleInterestedHover = (value) => setHoveredInterested(value);

    const handleShare = async (e) => {
        e.stopPropagation();
        const shareUrl = `${window.location.origin}/listings/${data?._id}`;
        await utils.CopyToClipboard(shareUrl);
    }

    const navigate = useNavigate();
    const redirectToListing = () => navigate(`/listings/${data?._id}?grid=${view !== 'list'}`);

    const multipleAssets = cleanedData?.quantity && cleanedData?.quantity > 1
    return (
        <div
            id={`Listing_Card_${index}`}
            className={styles["ListingList"]}
            onClick={redirectToListing}
        >
            <div id="ListingListsImage" className={styles["Listings_AssetImage"]}>
                <Image
                    src={cleanedData?.cardAssetImage}
                    alt="No Image Found"
                    fallback={cleanedData?.fallbackImage}
                    preview={false}
                />
            </div>
            <div className={styles["Listings_AssetBlock"]}>
                <div>
                    <div>{cleanedData?.assetDescription}</div>
                    <div className={styles["Listings_MakeModel"]}>
                        <div>{cleanedData?.make}</div>
                        <div>{cleanedData?.model}</div>
                    </div>
                </div>

                <div className={styles["Listings_AmountBlock"]}>
                    <div className={styles["Listings_Price"]}>
                        {cleanedData?.priceFormatted}
                        <div className={multipleAssets ? styles["Listings_Price_Condition"] : styles["Hide"]}>*</div>
                    </div>
                    <div className={multipleAssets ? styles["Listings_Condition"] : styles["Hide"]}>* Price will vary based on the kms/hours on the asset.</div>
                </div>
            </div>
            <div className={styles["Listings_AssetDetailsBlock"]}>
                <div>UL#: {cleanedData?.listNumber}</div>
                <div>

                    <div className={styles[!multipleAssets ? "Hide" : ""]}>
                        <div className={styles["Quantity"]}>Quantity</div>
                        <div>{cleanedData?.quantity}</div>
                    </div>

                    <div className={multipleAssets ? styles["Hide"] : null}>
                        <div><SpeedometerIcon width={27} height={21} /></div>
                        <div>{cleanedData?.kmsHours}</div>
                    </div>
                </div>
            </div>

            <div className={styles["Listings_InteractinsBlock"]}>
                <div
                    onMouseEnter={() => handleInterestedHover(true)}
                    onMouseLeave={() => handleInterestedHover(false)}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (cleanedData?.agentInterested) {
                            ListUtils.handleNotInterested(cleanedData?._id, setRefresh)
                        } else {
                            ListUtils.handleInteresed(cleanedData?._id, setRefresh)
                        }
                    }}
                >
                    <Tooltip
                        open={hoveredInterested}
                        placement="top"
                        title="Marking as Interested lets's our team know that you're interested in this asset"
                        destroyTooltipOnHide
                    >
                        <InterestedIcon interested={cleanedData?.agentInterested} />
                    </Tooltip>
                </div>

                <div onClick={handleShare}>
                    <ShareIcon />
                </div>
            </div>
        </div>
    );
}