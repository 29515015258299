export default function ApproachingExpiry() {
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill="#EDCB00" />
            <g clipPath="url(#clip0_8127_2577)">
                <path d="M41.981 41.4062C41.981 42.0278 41.7341 42.624 41.2945 43.0635C40.855 43.5031 40.2588 43.75 39.6372 43.75C39.0156 43.75 38.4195 43.5031 37.98 43.0635C37.5404 42.624 37.2935 42.0278 37.2935 41.4062C37.2935 40.7846 37.5404 40.1885 37.98 39.749C38.4195 39.3094 39.0156 39.0625 39.6372 39.0625C40.2588 39.0625 40.855 39.3094 41.2945 39.749C41.7341 40.1885 41.981 40.7846 41.981 41.4062Z" fill="white" />
                <path d="M25.9849 6.25468C23.2569 6.19531 20.5487 6.73209 18.0496 7.82755C15.5504 8.923 13.3205 10.5507 11.5155 12.5971C9.71046 14.6435 8.37382 17.0591 7.59891 19.6755C6.824 22.2918 6.62948 25.0458 7.02893 27.7451C7.42838 30.4444 8.41218 33.0239 9.91164 35.3037C11.4111 37.5835 13.3901 39.5085 15.7104 40.9444C18.0308 42.3802 20.6366 43.2923 23.3459 43.617C26.0552 43.9417 28.8027 43.6712 31.3966 42.8242C31.8398 42.6794 32.2073 42.3644 32.4183 41.9486C32.6293 41.5328 32.6664 41.0502 32.5216 40.607C32.3768 40.1638 32.0618 39.7963 31.646 39.5853C31.2302 39.3744 30.7476 39.3372 30.3044 39.482C27.4856 40.4025 24.4594 40.4802 21.5971 39.7058C18.7347 38.9314 16.1605 37.3385 14.1903 35.1224C12.2201 32.9063 10.9394 30.1634 10.5053 27.2301C10.0713 24.2967 10.5028 21.3005 11.7468 18.6088C12.9909 15.9171 14.9934 13.6469 17.5089 12.0768C20.0243 10.5066 22.9433 9.70472 25.9079 9.7694C28.8725 9.83408 31.7538 10.7625 34.1984 12.4409C36.6429 14.1193 38.5446 16.4746 39.67 19.218C39.8469 19.6494 40.1879 19.9928 40.618 20.1728C40.8309 20.2619 41.0593 20.3082 41.2902 20.3091C41.521 20.3099 41.7498 20.2653 41.9634 20.1777C42.177 20.0902 42.3713 19.9614 42.5351 19.7987C42.6989 19.6361 42.8291 19.4428 42.9183 19.2298C43.0074 19.0169 43.0537 18.7884 43.0545 18.5576C43.0554 18.3267 43.0107 18.098 42.9232 17.8844C41.5381 14.5077 39.1976 11.6087 36.1888 9.54289C33.18 7.47708 29.6337 6.33429 25.9849 6.25468Z" fill="white" />
                <path d="M27.3325 15.0391C27.3325 14.5729 27.1474 14.1258 26.8177 13.7961C26.488 13.4664 26.0409 13.2812 25.5747 13.2812C25.1085 13.2812 24.6614 13.4664 24.3318 13.7961C24.0021 14.1258 23.8169 14.5729 23.8169 15.0391V24.2734L19.0497 29.0383C18.877 29.1992 18.7385 29.3933 18.6424 29.6089C18.5464 29.8245 18.4947 30.0573 18.4905 30.2933C18.4864 30.5293 18.5298 30.7638 18.6182 30.9826C18.7066 31.2015 18.8382 31.4004 19.0051 31.5673C19.172 31.7342 19.3709 31.8658 19.5897 31.9542C19.8086 32.0426 20.0431 32.086 20.2791 32.0819C20.5151 32.0777 20.7479 32.026 20.9635 31.93C21.1791 31.8339 21.3732 31.6954 21.5341 31.5227L26.8169 26.2422L27.3325 25.7266V15.0391Z" fill="white" />
                <path d="M41.395 26.7578C41.395 26.2916 41.2099 25.8445 40.8802 25.5148C40.5505 25.1852 40.1034 25 39.6372 25C39.171 25 38.7239 25.1852 38.3943 25.5148C38.0646 25.8445 37.8794 26.2916 37.8794 26.7578V34.9609C37.8794 35.4271 38.0646 35.8742 38.3943 36.2039C38.7239 36.5335 39.171 36.7187 39.6372 36.7187C40.1034 36.7187 40.5505 36.5335 40.8802 36.2039C41.2099 35.8742 41.395 35.4271 41.395 34.9609V26.7578Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_8127_2577">
                    <rect width="37.5" height="37.5" fill="white" transform="translate(6.25 6.25)" />
                </clipPath>
            </defs>
        </svg>
    );
}