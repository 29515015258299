import utils from "./";

export default function CleanListingRecord(data = []) {
    const cleanedData = [];
    const fallbackImage = process.env.REACT_APP_STATIC_CONTAINER + process.env.REACT_APP_LISTING_FALLBACK_IMAGE;
    for (let i = 0; i < data?.length; i++) {
        const currentData = data[i];
        const cardAssetImage = currentData?.ap_assetPhoto?.[0] || fallbackImage;
        const assetPrice = utils.parseDBCurrency(currentData?.ap_price)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const listingName = `${currentData?.ap_year} ${currentData?.ap_make} ${currentData?.ap_model} ${currentData?.ap_equipmentType}`;
        const listingTitle = `${currentData?.ap_year} ${currentData?.ap_make} ${currentData?.ap_equipmentType}`;

        cleanedData.push({
            fallbackImage,
            cardAssetImage,
            assetDescription: currentData?.ap_assetDescription || '--',
            year: currentData?.ap_year || '--',
            make: currentData?.ap_make || '--',
            model: currentData?.ap_model || '--',
            type: currentData?.ap_equipmentType || '--',
            listNumber: currentData?.crm_listNumber || '--',
            kmsHours: utils.NumberWithCommas(currentData?.ap_kmsHours) || '--',
            price: currentData?.ap_price || '--',
            priceFormatted: assetPrice || '--',
            assetPictures: currentData?.ap_assetPhoto || [],
            _id: currentData?._id,
            description: currentData?.ap_description,
            multipleAssets: currentData?.ap_multipleAssets,
            quantity: currentData?.ap_quantity,
            status: currentData?.ap_status,
            listStatus: currentData?.ap_listStatus,
            uniqueEntityId: currentData?.ap_uniqueEntityId,
            listingName: listingName,
            agentInterested: currentData?.agentInterested,
            listingTitle: listingTitle,
        })
    }
    return cleanedData;
}