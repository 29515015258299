import DrawdownDetails from "../DrawdownDetails";
import DrawdownDocuments from "../DrawdownDocuments";
import utils from "../../utils";
import PaymentSchedules from "../PaymentSchedules";

// Function to generate tab items for UI
function tabItems() {
    return ([
        { key: 0, label: 'Details' },
        { key: 1, label: 'Payment Schedule' },
        { key: 2, label: 'Documents' },
    ]);
}

// Function to generate tab key items based on drawdown data
function tabKeyItems(drawdownData, setHeaderName) {

    return ([
        <DrawdownDetails />,
        <PaymentSchedules setHeaderName={setHeaderName} approvalId={drawdownData?.ap_approvalId} ppsId={drawdownData?.ap_paymentScheduleId?._id} isDrawdown={true} hideHoldAlert={true} />,
        <DrawdownDocuments documents={drawdownData?.ap_documents} />
    ]);
}

// Determine the current stage in the business process flow based on drawdown data
function getCurrentBpf(drawdownData) {
    if (drawdownData?.ap_brokerFundedDate)
        return 6;
    else if (drawdownData?.ap_postFundingRequirementDate)
        return 5;
    else if (drawdownData?.ap_vendorFundedDate)
        return 4;
    else if (drawdownData?.ap_fundingRequestedDate)
        return 3;
    else if (drawdownData?.ap_documentsReceivedDate)
        return 2;
    else if (drawdownData?.ap_documentsRequestedDate)
        return 1;
    else if (drawdownData?.ap_dealPrepDate)
        return 0;
    else return null;
}

// Generate an array of business process flow items with dates
function generateBpfItems(drawdownData) {
    return ([
        {
            title: 'Deal Prep',
            description: utils.formatDates(drawdownData?.ap_dealPrepDate),
        },
        {
            title: 'Documents Requested',
            description: utils.formatDates(drawdownData?.ap_documentsRequestedDate),
        },
        {
            title: 'Signing/Auditing',
            description: utils.formatDates(drawdownData?.ap_documentsReceivedDate),
        },
        {
            title: 'Funding Requested',
            description: utils.formatDates(drawdownData?.ap_fundingRequestedDate)
        },
        {
            title: 'Post-Funding Requirements',
            description: utils.formatDates(drawdownData?.ap_vendorFundedDate)
        },
        {
            title: 'Broker Funding',
            description: utils.formatDates(drawdownData?.ap_postFundingRequirementDate)
        },
        {
            title: 'Completed',
            description: utils.formatDates(drawdownData?.ap_brokerFundedDate)
        },
    ]);
}

function generateDetailsFields(drawdownData) {
    return ({
        admin: {
            value: drawdownData?.ap_adminId?.ap_firstName,
            error: "",
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'contact-input',
            label: <b>Admin</b>,
            mail: drawdownData?.ap_adminId?.ap_email,
        },
        sales: {
            value: (drawdownData?.ap_salesCoordinatorId?.ap_name)?.split(" ")[0],
            error: "",
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'contact-input',
            label: <b>Sales</b>,
            mail: drawdownData?.ap_salesCoordinatorId?.ap_email,
            tel: drawdownData?.ap_salesCoordinatorId?.ap_phoneNumber,
        },
        lcNumber: {
            value: drawdownData?.crm_lcNumber,
            error: "",
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'contact-input',
            label: <b>LC#</b>,
        },
        agentFee: {
            value: utils.TransformDBCurrency(drawdownData?.ap_drawdownFee),
            error: "",
            hidden: false,
            required: false,
            disabled: true,
            inputType: 'contact-input',
            label: <b>Drawdown Fee</b>,
        }
    });
}

const exportObj = {
    tabItems: tabItems,
    tabKeyItems: tabKeyItems,
    getCurrentBpf: getCurrentBpf,
    generateBpfItems: generateBpfItems,
    generateDetailsFields: generateDetailsFields,
}

export default exportObj;