import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, ConfigProvider, Divider, Form, Image, Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import AuthService from "../../services/auth.service";
import styles from "./index.module.css";

export default function LoginPage() {

    const navigate = useNavigate();

    const [invokeFieldValidaton, setInvokeFieldValidaton] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        userName: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
    });

    const handleUsername = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            userName: {
                ...prevFormData.userName,
                value: e.target.value,
                error: invokeFieldValidaton && !e.target.value ? 'Required' : '',
            },
        }));
    }

    const handlePassword = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            password: {
                ...prevFormData.password,
                value: e.target.value,
                error: invokeFieldValidaton && !e.target.value ? 'Required' : '',
            },
        }));
    }

    const handleLogin = async () => {
        setInvokeFieldValidaton(true);
        const username = formData?.userName?.value;
        const password = formData?.password?.value;

        if (!username || !password) {
            setFormData(prevFormData => ({
                ...prevFormData,
                userName: {
                    ...prevFormData.userName,
                    error: !username ? 'Required' : '',
                },
                password: {
                    ...prevFormData.password,
                    error: !password ? 'Required' : '',
                },
            }));

            const error = !username && !password ? 'Please enter username and password' : !username ? 'Please enter username' : 'Please enter password';
            setErrorMessage(error);
        } else {
            setErrorMessage('');
            setFormData(prevFormData => ({
                userName: {
                    ...prevFormData.userName,
                    error: '',
                },
                password: {
                    ...prevFormData.password,
                    error: '',
                },
            }));

            try {
                const user = await AuthService.login({
                    email_address: username,
                    password: password,
                });

                if (user)
                    navigate('/home');
                else
                    setErrorMessage('Username or Password is incorrect');
            }
            catch (error) {
                setErrorMessage(error?.errors || error || 'Something went wrong');
                return;
            }
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className={styles["LoginScreen"]}>

            <div className={styles["LoginSection"]}>

                <div className={styles["Logo"]}>
                    <Image
                        src="https://lanternpublicstaticfiles.blob.core.windows.net/main/BeaconLogo.svg"
                        alt="Beacon Logo"
                        width={"200px"}
                        preview={false}
                    />
                </div>

                <div className={styles["Welcome"]}>welcome!</div>

                <div className={styles["Form"]}>

                    {errorMessage && <div>
                        <Alert
                            message={<b>{errorMessage}</b>}
                            type="error"
                            showIcon
                            style={{ marginBottom: '20px', textAlign: 'left' }}
                        />
                    </div>}

                    <ConfigProvider
                        theme={{
                            components: {
                                Form: {
                                    labelColor: "#447EB7",
                                    labelFontSize: "16px",
                                    verticalLabelPadding: "0px",
                                },
                                Input: {
                                    activeBorderColor: "#447EB7",
                                    hoverBorderColor: "#447EB7",
                                }
                            },
                            token: {
                                fontSize: 16,
                                colorBorder: "#CFDCE9",
                            }
                        }}
                    >
                        <Form layout="vertical">
                            <Form.Item
                                key="username"
                                label={<b>Username:</b>}
                            >
                                <Input
                                    onChange={handleUsername}
                                    onPressEnter={handleEnter}
                                    value={formData?.userName?.value}
                                    type="text"
                                    status={formData?.userName?.error ? 'error' : ''}
                                    style={{ height: '40px' }}
                                />
                                {formData?.userName?.error && <div className={styles["VerticalWarning"]}>{formData?.userName?.error}</div>}

                            </Form.Item>

                            <Form.Item
                                key="password"
                                label={<b>Password:</b>}
                            >
                                <Input.Password
                                    onChange={handlePassword}
                                    onPressEnter={handleEnter}
                                    iconRender={(visible) => (
                                        visible ?
                                            <EyeOutlined style={{ color: '#447EB7', fontSize: '16px' }} /> :
                                            <EyeInvisibleOutlined style={{ color: '#447EB7', fontSize: '16px' }} />
                                    )}
                                    visibilityToggle={true}
                                    value={formData?.password?.value}
                                    type="password"
                                    status={formData?.password?.error ? 'error' : ''}
                                    style={{ height: '40px' }}
                                />
                                {formData?.password?.error && <div className={styles["VerticalWarning"]}>{formData?.password?.error}</div>}
                                <div
                                    onClick={() => navigate('/passwordreset')}
                                    className={styles["ForgotPassword"]}
                                >Forgot Password?</div>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>
                </div>

                <Button
                    type="primary"
                    className={styles["LoginButton"]}
                    onClick={handleLogin}
                >Log In</Button>

                <div className={styles["Divider"]}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorSplit: 'black'
                            },
                        }}
                    >
                        <Divider />
                    </ConfigProvider>
                </div>

                <div className={styles["dontHaveAccount"]}>Don't have an account?</div>

                <Button
                    type="primary"
                    className={styles["JoinUsButton"]}
                    onClick={() => navigate('/JoinUs')}
                >Join Us</Button>

                <div style={{ width: '100%' }}>
                    <div className={styles["LanternLogo"]}>
                        <Image
                            src="https://lanternpublicstaticfiles.blob.core.windows.net/main/LanternLogo.svg"
                            alt="Lantern Logo"
                            width={"180px"}
                            preview={false}
                        />
                    </div>
                </div>
            </div>
            <div className={styles["ImageSection"]} />
        </div>
    )
}