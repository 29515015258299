import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";
import Error404 from "../../layouts/Error404";
import Drawdown from "../../layouts/Drawdown";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function IndividualDrawdown() {

    const { id } = useParams();

    const [headerName, setHeaderName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [drawdownData, setDrawdownData] = useState({});
    const [refresh, setRefresh] = useState(0);

    // Fetch drawdown data when 'id' or 'refresh' changes
    useEffect(() => {
        if (!id) return;

        setLoading(true);
        ApiService.fetchIndividualDrawdown(id)
            .then(response => {
                setShowError(!response);
                setDrawdownData(!response ? {} : response);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                NotificationService.error("There was an error loading the drawdown. Please try again later.")
                setLoading(false);
            });
    }, [id, refresh]);

    // Generate header details when 'drawdownData' changes
    useEffect(() => {
        if (Object.keys(drawdownData).length === 0)
            return;

        const entity = `Drawdowns | `
        const headerDetails = `LC#${drawdownData?.crm_lcNumber} - ${drawdownData?.ap_lenderId?.ap_abbreviation || drawdownData?.ap_lenderId?.ap_name} - ${drawdownData?.ap_clientId?.ap_businessName} - ${drawdownData?.ap_assetDescription}`;

        setHeaderName(
            <div className={`${styles["Header"]} Font_SemiBold`}>
                <>{entity}</>
                <span className={`${styles["HeaderDetails"]} Font_SemiBold`}>{headerDetails}</span>
            </div>
        );

    }, [drawdownData]);

    return (
        <Spin
            spinning={loading}
            tip="Loading..."
        >
            <HomeContent
                contentHeader={
                    <>
                        {showError ?
                            <></> :
                            <div className={styles["ContentHeader"]}>
                                <GoBackButton />
                                {headerName}
                            </div>
                        }
                    </>
                }
                content={
                    <>
                        {showError ?
                            <div className={styles["Content"]}>
                                <Error404 />
                            </div> :
                            <Spin spinning={loading} tip="Loading Drawdown...">
                                <Drawdown
                                    drawdownData={drawdownData}
                                    setRefresh={setRefresh}
                                />
                            </Spin>
                        }
                    </>
                }
                bgColor="transparent"
            />
        </Spin>
    );
}