import { ConfigProvider, Form, Switch, theme } from 'antd';
import styles from "./index.module.css";
import { useMediaQuery } from 'react-responsive';
import constants from '../../constants';

export default function InlineSwitch({ data, item, layout }) {
    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });
    const { token: { themePrimaryColor } } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: "red",
                },
            }}
        >
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <>
                        <Form.Item
                            key={item}
                            label={data.label}
                        >
                            <div style={isMobile ? {} : { display: 'none' }}>
                                {layout == 'vertical' ?
                                    <Switch
                                        checked={data.value}
                                        onChange={data.eventHandler}
                                        disabled={data.disabled}
                                    /> : <div style={{ textAlign: 'center' }}>
                                        <Switch
                                            checked={data.value}
                                            onChange={data.eventHandler}
                                            style={{ width: 'fit-content', }}
                                            disabled={data.disabled}
                                        />
                                    </div>
                                }
                            </div>

                        </Form.Item>

                        {!isMobile ?
                            <div style={{ textAlign: 'right' }}>
                                <Switch
                                    checked={data.value}
                                    onChange={data.eventHandler}
                                    style={{ width: 'fit-content', }}
                                    disabled={data.disabled}
                                />
                            </div>
                            : null}
                    </>
                </div>
            )}
        </ConfigProvider>
    )
}