import Icon from '@ant-design/icons';

function SuccessIcon() {
    return (
        <svg width="350" height="477" viewBox="0 0 460 477" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="253.212" cy="252.369" r="146.605" fill="url(#paint0_linear_4802_26899)" />
            <circle cx="115.897" cy="159.218" r="92.8581" fill="url(#paint1_linear_4802_26899)" />
            <circle cx="115.897" cy="357.15" r="60.8064" fill="url(#paint2_linear_4802_26899)" />
            <path d="M138.499 125.536C138.499 125.536 57.4336 187.144 136.966 225.381C216.499 263.618 214.398 233.507 233.065 189.976C251.731 146.444 326.831 52.9331 370.252 109.779C413.673 166.626 287.21 322.073 212.029 337.245C136.847 352.416 126.527 422.235 187.377 434.082C248.228 445.929 337.081 337.245 337.081 337.245" stroke="#447EB7" strokeWidth="4" strokeLinecap="round" strokeDasharray="20 30" />
            <path d="M383.714 334.732H68.7759V344.764C68.7759 349.46 72.6179 353.302 77.3137 353.302H375.176C379.872 353.302 383.714 349.46 383.714 344.764V334.732Z" fill="white" />
            <path opacity="0.8" d="M81.6893 353.836H372.615C378.485 353.836 383.287 349.353 384.354 343.27H69.8431C70.9103 349.353 75.8196 353.836 81.6893 353.836Z" fill="#CFDCE9" />
            <path d="M356.607 334.732H98.1246V178.917C98.1246 174.222 101.967 170.38 106.662 170.38H348.069C352.765 170.38 356.607 174.222 356.607 178.917V334.732Z" fill="#CFDCE9" stroke="#447EB7" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M342.306 319.898V184.253H112.532V319.898H342.306Z" fill="#4ECBD7" stroke="#447EB7" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M383.714 334.732H68.7759V344.764C68.7759 349.46 72.6179 353.302 77.3137 353.302H375.176C379.872 353.302 383.714 349.46 383.714 344.764V334.732Z" stroke="#447EB7" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M334.423 310.299L334.441 310.283C335.908 309.07 336.165 306.885 335 305.34C335 305.339 334.999 305.339 334.999 305.338L329.201 297.635L334.994 296.083C336.555 295.665 337.392 294.334 337.544 293.091C337.696 291.843 337.202 290.375 335.824 289.572L306.156 271.524C304.799 270.647 303.242 270.92 302.218 271.717C301.184 272.523 300.566 273.942 300.974 275.463L310.535 311.145C310.923 312.594 312.104 313.535 313.388 313.752C314.723 313.977 316.198 313.417 316.977 311.993L316.977 311.993L320.577 305.415L325.914 312.503L325.929 312.523C327.065 313.993 329.27 314.46 330.888 313.153C330.888 313.153 330.888 313.153 330.888 313.153C330.889 313.153 330.889 313.152 330.889 313.152L334.423 310.299Z" fill="white" stroke="#447EB7" strokeWidth="4" strokeLinejoin="round" />
            <path d="M207.368 83.5216C208.201 83.4425 208.757 84.3617 208.3 85.0632L158.305 161.766C157.802 162.538 156.611 162.277 156.477 161.365L150.281 119.097L115.726 93.9776C114.98 93.4359 115.302 92.2603 116.219 92.1732L207.368 83.5216Z" fill="#CFDCE9" />
            <path d="M150.281 119.097L156.477 161.365C156.611 162.277 157.802 162.538 158.305 161.766L208.3 85.0632C208.757 84.3617 208.201 83.4425 207.368 83.5216L116.219 92.1732C115.302 92.2603 114.98 93.4359 115.726 93.9776L150.281 119.097ZM150.281 119.097L206.206 85.2768" stroke="#447EB7" strokeWidth="3.5" strokeLinejoin="round" />
            <path d="M158.305 161.767L207.043 86.9922C207.623 86.1016 206.597 85.0404 205.688 85.5905L150.849 118.754C150.505 118.962 150.319 119.356 150.377 119.754L156.477 161.366C156.611 162.277 157.802 162.538 158.305 161.767Z" fill="#447EB7" fillOpacity="0.4" stroke="#447EB7" strokeWidth="3.5" strokeLinejoin="round" />
            <circle cx="230" cy="252.076" r="45.7678" fill="#8ECEAE" stroke="#447EB7" strokeWidth="3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M254.074 237.31C253.999 236.033 253.42 234.838 252.464 233.987C251.508 233.137 250.254 232.701 248.976 232.775C247.699 232.85 246.504 233.428 245.653 234.383L223.514 259.293L214.245 248.87C213.382 247.962 212.2 247.425 210.949 247.372C209.697 247.319 208.474 247.755 207.538 248.587C206.602 249.419 206.026 250.583 205.931 251.832C205.837 253.081 206.232 254.318 207.033 255.281L219.903 269.763C220.356 270.273 220.912 270.681 221.534 270.96C222.155 271.24 222.83 271.384 223.511 271.384C224.193 271.384 224.867 271.24 225.489 270.96C226.111 270.681 226.667 270.273 227.12 269.763L252.865 240.799C253.714 239.842 254.149 238.587 254.074 237.31Z" fill="#8ECEAE" />
            <path d="M252.464 233.987C253.42 234.838 253.999 236.033 254.074 237.31C254.149 238.587 253.714 239.842 252.865 240.799L227.12 269.763C226.667 270.273 226.111 270.681 225.489 270.96C224.867 271.24 224.193 271.384 223.511 271.384C222.83 271.384 222.155 271.24 221.534 270.96C220.912 270.681 220.356 270.273 219.903 269.763L207.033 255.281C206.232 254.318 205.837 253.081 205.931 251.832C206.026 250.583 206.602 249.419 207.538 248.587C208.474 247.755 209.697 247.319 210.949 247.372C212.2 247.425 213.382 247.962 214.245 248.87L223.514 259.293L245.653 234.383C246.504 233.428 247.699 232.85 248.976 232.775C250.254 232.701 251.508 233.137 252.464 233.987Z" fill="white" />
            <path d="M379.785 183.868L384.559 193.218L389.332 183.868L399.817 179.618L389.332 175.368L384.559 166.018L379.785 175.368L369.301 179.618L379.785 183.868Z" fill="#EDCB00" />
            <path d="M338.784 145.618L354.424 152.078L361.672 166.018L368.919 152.078L384.559 145.618L368.919 139.158L361.672 125.218L354.424 139.158L338.784 145.618Z" fill="#EDCB00" />
            <path d="M23.0015 319.784L38.6411 326.244L45.8887 340.184L53.1363 326.244L68.7759 319.784L53.1363 313.324L45.8887 299.384L38.6411 313.324L23.0015 319.784Z" fill="#EDCB00" />
            <path d="M65.0695 281.004L69.8431 271.384L74.6167 281.004L85.1012 285.384L74.6167 289.764L69.8431 299.384L65.0695 289.764L54.5849 285.384L65.0695 281.004Z" fill="#EDCB00" />
            <defs>
                <linearGradient id="paint0_linear_4802_26899" x1="106.607" y1="282.597" x2="424.418" y2="167.166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4ECBD7" />
                    <stop offset="1" stopColor="#4ECBD7" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_4802_26899" x1="57.7163" y1="347.561" x2="142.369" y2="80.1677" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDCB00" />
                    <stop offset="1" stopColor="#EDCB00" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_4802_26899" x1="127.286" y1="329.294" x2="61.9959" y2="436.469" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CFDCE9" />
                    <stop offset="1" stopColor="#CFDCE9" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default function JoinUsSuccessIcon(props) {
    return <Icon component={SuccessIcon} {...props} />
}
