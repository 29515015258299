import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { Spin, theme } from 'antd';
import ApiService from '../../api';
import { ValidateNewPassword } from "../../utils/Validations/ValidatePassword";
import PasswordInputLayout from './PasswordInputLayout';
import NotificationService from '../../services/notification.service';

export default function ChangePasswordLayout() {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isMinLengthValid, setIsMinLengthValid] = useState(false);
    const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [focused, setFocused] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
    const [isPassMatchedFirst, setIsPassMatchedFirst] = useState(false);
    const [resetButtonLabel, setResetButtonLabel] = useState('Update Password');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Password validation
        ValidateNewPassword(newPassword, setIsPasswordValid);

        // ValidateNewPassword()
        if (confirmPasswordFocused)
            verifyConfirmPassword();
        setIsMinLengthValid(newPassword.length >= 8);
        setIsUpperCaseValid(/[A-Z]/.test(newPassword));
        setIsNumberValid(/\d/.test(newPassword));
        setIsSpecialCharValid(/\W/.test(newPassword));
    }, [newPassword]);


    useEffect(() => {
        if (confirmPasswordFocused || isPassMatchedFirst)
            verifyConfirmPassword();
    }, [confirmPasswordFocused, confirmPassword, newPassword, isPassMatchedFirst, passwordsMatch]);

    const verifyConfirmPassword = () => {
        if (confirmPassword === newPassword && isPasswordValid) {
            setPasswordsMatch(true);
            setIsPassMatchedFirst(true);
        } else if (confirmPassword.length > 0 && newPassword.length > 0) {
            setPasswordsMatch(false);
        }
        else {
            setPasswordsMatch(null);
        }
    };

    const handleButtonClick = () => {
        if (resetButtonLabel === 'Back to Login') {
            navigate('/');
        } else {
            handleChangePassword();
        }
    };

    const handleChangePassword = () => {
        if (isPasswordValid && passwordsMatch) {
            const data = {
                newPassword,
                oldPassword,
            }
            setLoading(true);
            ApiService.changePassword(data).then(resp => {
                if (resp.success) {
                    setMessageText('Password updated successfully !');
                    setResetButtonLabel('Back to Login');
                }
                else {
                    setMessageText('Password reset unsuccessful. Please try again.');
                    NotificationService.error("Password reset unsuccessful. Please try again.")
                }
                setLoading(false);
            });
        } else {
            setMessageText('Password reset unsuccessful. Please try again.');
            NotificationService.error("Password reset unsuccessful. Please try again.")
        }
    };

    const passwordData = {
        oldPassword,
        newPassword,
        confirmPassword,
        isPasswordValid,
        passwordsMatch,
        isMinLengthValid,
        isNumberValid,
        isUpperCaseValid,
        isSpecialCharValid,
        focused
      };
      
      const passwordHandlers = {
        setOldPassword,
        setNewPassword,
        setConfirmPassword,
        verifyConfirmPassword,
        setFocused,
        setConfirmPasswordFocused,
        handleButtonClick
      };

    return (
         <div className={styles['Container']}>
            <Spin spinning={loading}>
                <PasswordInputLayout
                    passwordData={passwordData}
                    passwordHandlers={passwordHandlers}
                    messageText={messageText}
                    resetButtonLabel={resetButtonLabel}
                />
            </Spin>
         </div>
    );
}
