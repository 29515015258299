import { Checkbox } from "antd";
import React from "react";

import styles from "./index.module.css";

export default function RequirementsChecklist({ checkedItems, setCheckedItems }) {

    const handleCheckboxChange = (name) => {
        const updatedItems = checkedItems.map(item => {
            if (item.name === name) {
                return {
                    ...item,
                    checked: !item.checked,
                    ...(!item?.checked) && { updated: true }
                };
            }
            return item;
        });
        setCheckedItems(updatedItems);
    }

    return (
        <div className={styles["Container"]}>
            {checkedItems.map(item => (
                <div key={item.name} className={styles["IndividualReq"]}>
                    <Checkbox
                        key={item?.name}
                        checked={item?.checked}
                        onChange={() => handleCheckboxChange(item?.name)}
                    >
                        <span className={(item?.checked) ? styles["selectedColor"] : null}>{item?.name}</span>
                    </Checkbox>
                </div>
            ))}
        </div>
    );

}