import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { theme, Alert, Button, Spin } from "antd";

import ApiService from "../../api";
import utils from "../../utils";
import InputLayout from '../InputLayout';

import ppsConstants from "./constants";
import styles from './index.module.css';
import "../../assets/styles/fonts.css";
import SpecialPaymentFields from "../../components/PpsSpecialPayments";


export default function PaymentScheduleSummary({ setPPS, refresh, handleCancel, handleViewPPS, paymentScheduleId }) {

    const { token: { ap_userPrimaryColor, themePrimaryColor, ap_userHighlightColor, sideNavBgColor } } = theme.useToken();

    // const { paymentScheduleId } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({});
    const [specialPayment, setSpecialPayment] = useState(false);
    const [isDraft, setIsDraft] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (!paymentScheduleId) return;
        ApiService.fetchIndividualPaymentSchedule(paymentScheduleId).then(async (ppsData) => {
            if (!ppsData) return;
            setData(ppsData)
            setIsDraft(ppsData.ap_isDraft);
            setLoading(false);
        });
    }, [paymentScheduleId, refresh, ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, sideNavBgColor]);

    useEffect(() => {
        if (Object.keys(data).length === 0) return;
        setSpecialPayment(data.ap_specialPayments);
        let clientHtml = true;
        let brokerHtml = true;
        ApiService.fetchPpsHtmlData(utils.CleanData(data), brokerHtml, clientHtml, ap_userPrimaryColor?.replace("#", ""), themePrimaryColor?.replace("#", ""), `${ap_userHighlightColor?.replace("#", "")}80`).then(respHtml => {
            setPPS({
                client: respHtml.ppsClientHtml,
                broker: respHtml.ppsBrokerHtml
            })
        })
        setFormData(ppsConstants.generatePaymentScheduleFields(data));
    }, [data]);

    return (
        <Spin spinning={loading} tip="Fetching data...">
            {isDraft ?
                <div style={{ textAlign: "left" }}>
                    <Alert
                        style={{ backgroundColor: 'lightgray', borderRadius: "8px", fontWeight: "bold" }}
                        message={<div style={{ display: 'flex', justifyContent: 'space-between', padding: '0% 2%', alignItems: 'center' }}>
                            <span>Payment Schedule requested, not confirmed.</span>
                            <div style={{ display: 'flex', gridGap: '5%' }}>
                                <Button
                                    onClick={handleViewPPS}
                                    style={{ background: 'white', border: '1px solid darkgray', fontWeight: 'bold' }}
                                    type="text"
                                >View Approved PPS</Button>
                                <Button
                                    onClick={handleCancel}
                                    style={{ background: 'white', border: '1px solid darkgray', fontWeight: 'bold' }}
                                    type="text"
                                    danger>Cancel</Button>
                            </div>

                        </div>}
                        banner
                    />
                </div> : <></>
            }

            {/* Commission */}
            {/* <div className={`${styles["Header"]} Font_SemiBold`}>Commission</div>
            <div style={{ margin: "0% 2%" }}>
                <InputLayout
                    data={ppsConstants.commissionData(formData)}
                    layout="vertical"
                    formStyle={paymentScheduleFormStyle}
                    fieldStyle={paymentScheduleFieldStyle}
                />
            </div> */}

            {/* Details */}
            {/* <div className={`${styles["Header"]} Font_SemiBold`}>Details</div> */}
            <div style={{ margin: "0% 2%" }}>
                <InputLayout
                    data={ppsConstants.details(formData)}
                    layout="vertical"
                    formStyle={paymentScheduleFormStyle}
                    fieldStyle={paymentScheduleFieldStyle}
                />
            </div>

            {/* Special Payments */}
            <div style={specialPayment ? {} : { display: 'none' }}><div className={`${styles["Header"]} Font_SemiBold`}>Special Payments</div></div>
            <div style={specialPaymentStyle}>
                <SpecialPaymentFields formData={ppsConstants.taxBalloonFields(formData)} />
                <SpecialPaymentFields formData={ppsConstants.acceleratedFields(formData)} />
                <SpecialPaymentFields formData={ppsConstants.skipPaymentFields(formData)} />
                <SpecialPaymentFields formData={ppsConstants.interestOnlyFields(formData)} />
            </div>
        </Spin>
    );
}

const paymentScheduleFormStyle = {
    "display": 'grid',
    "gridTemplateColumns": 'repeat(auto-fit, minmax(18rem, 1fr))',
}

const specialPaymentStyle = {
    "display": 'grid',
    "gridTemplateColumns": 'repeat(auto-fit, minmax(18rem, 1fr))',
    "margin": "0% 2%"
}

const paymentScheduleFieldStyle = {
    padding: "0% 2%",
}