import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Picture = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.55 21C37.582 21.0002 36.6538 21.3849 35.9695 22.0695L29.7389 28.3H20.3C18.3639 28.3 16.5071 29.0691 15.1381 30.4381C13.7691 31.8071 13 33.6639 13 35.6V72.1C13 74.0361 13.7691 75.8929 15.1381 77.2619C16.5071 78.6309 18.3639 79.4 20.3 79.4H78.7C80.6361 79.4 82.4929 78.6309 83.8619 77.2619C85.2309 75.8929 86 74.0361 86 72.1V35.6C86 33.6639 85.2309 31.8071 83.8619 30.4381C82.4929 29.0691 80.6361 28.3 78.7 28.3H69.2611L63.0306 22.0695C62.3462 21.3849 61.418 21.0002 60.45 21H38.55ZM33.8305 34.5306L40.0611 28.3H58.9389L65.1694 34.5306C65.8538 35.2151 66.782 35.5998 67.75 35.6H78.7V72.1H20.3V35.6H31.25C32.218 35.5998 33.1462 35.2151 33.8305 34.5306Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.9 52.025C34.9 48.1528 36.4382 44.4393 39.1762 41.7012C41.9143 38.9632 45.6278 37.425 49.5 37.425C53.3722 37.425 57.0857 38.9632 59.8238 41.7012C62.5618 44.4393 64.1 48.1528 64.1 52.025C64.1 55.8972 62.5618 59.6107 59.8238 62.3488C57.0857 65.0868 53.3722 66.625 49.5 66.625C45.6278 66.625 41.9143 65.0868 39.1762 62.3488C36.4382 59.6107 34.9 55.8972 34.9 52.025ZM44.3381 46.8631C45.7071 45.4941 47.5639 44.725 49.5 44.725C51.4361 44.725 53.2929 45.4941 54.6619 46.8631C56.0309 48.2321 56.8 50.0889 56.8 52.025C56.8 53.9611 56.0309 55.8179 54.6619 57.1869C53.2929 58.5559 51.4361 59.325 49.5 59.325C47.5639 59.325 45.7071 58.5559 44.3381 57.1869C42.9691 55.8179 42.2 53.9611 42.2 52.025C42.2 50.0889 42.9691 48.2321 44.3381 46.8631Z" fill="white" />
        </svg>
    );
};

export default function PictureIcon(props) {
    return <Icon component={Picture} {...props} />
}