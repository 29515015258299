import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import ApiService from "../../api";

import HomeContent from "../../components/HomeContent";
import Error404 from "../../layouts/Error404";
import GoBackButton from "../../components/GoBackButton";
import Client from "../../layouts/Client";

export default function IndividualClient() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showError, setShowError] = useState(false);

    const [headerName, setHeaderName] = useState('');
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchClientDetails(id).then((response) => {
            if (!response) {
                setHeaderName('');
                setData(null);
                setShowError(true);
            }
            else {
                setHeaderName(response?.ap_businessName);
                setData(response);
                setShowError(false);
            }
            setLoading(false);
        });
    }, [id, refresh]);

    return (
        <HomeContent
            contentHeader={
                <>
                    {
                        <div style={{ display: 'flex' }}>
                            <GoBackButton />
                            {(!data && showError) ? ("No Client Found") : (headerName)}
                        </div>
                    }
                </>
            }
            content={
                <>
                    <Spin
                        spinning={loading}
                        tip="Loading..."
                        size="large"
                    >
                        {(!data) ?
                            <div style={{ maxHeight: '70vh', overflow: 'hidden' }}>
                                {showError ? <Error404 /> : <></>}
                            </div> :
                            <Client
                                refresh={refresh}
                                setRefresh={setRefresh}
                                data={data}
                                setLoading={setLoading}
                            />
                        }
                    </Spin>
                </>
            }
            bgColor="transparent"
        />
    );
}