import { Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons";

import constants from "../../constants";

import utils from "../../utils";

function generateContactFields(entity, primaryExists) {
    return {
        ap_firstName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'First Name',
        },
        ap_lastName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'Last Name',
        },
        ap_email: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'Email',
        },
        ap_telephone: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            disabled: false,
            inputType: 'text',
            label: 'Telephone',
        },
        ap_address: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Address',
        },
        ap_city: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'City',
        },
        ap_stateOrProvince: {
            value: '',
            error: '',
            hidden: false,
            required: entity !== 'agent',
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Province/State',
            dropdownContent: constants.CANADIAN_PROVINCES,
        },
        ap_postalZipCode: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'text',
            label: 'Postal/Zip Code',
        },
        ap_country: {
            value: 'Canada',
            error: '',
            hidden: false,
            required: entity !== 'agent',
            disabled: false,
            inputType: 'dropdown-search',
            label: 'Country',
            dropdownContent: constants.COUNTRIES,
        },
        ap_primaryContact: {
            value: !primaryExists || false,
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Set this person as your Primary Contact?</b>,
        },
        ap_smsNotification: {
            value: false,
            error: '',
            hidden: entity !== 'agent',
            required: false,
            disabled: true,
            inputType: 'switch',
            label: <>
                <span style={{ fontWeight: 'bold' }}>SMS</span>
                <Tooltip title="Toggle to receive SMS communications for this contact">
                    <InfoCircleOutlined style={{ paddingLeft: '5%', fontSize: 'small' }} />
                </Tooltip>
            </>,
        },
        ap_whatsAppNotification: {
            value: false,
            error: '',
            hidden: entity !== 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <>
                <span style={{ fontWeight: 'bold' }}>WhatsApp</span>
                <Tooltip title="Toggle to receive WhatsApp communications for this contact">
                    <InfoCircleOutlined style={{ paddingLeft: '5%', fontSize: 'small' }} />
                </Tooltip>
            </>,
        },
        ap_emailNotification: {
            value: !primaryExists || false,
            error: '',
            hidden: entity !== 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <>
                <span style={{ fontWeight: 'bold' }}>Email</span>
                <Tooltip title="Toggle to receive Email communications for this contact">
                    <InfoCircleOutlined style={{ paddingLeft: '5%', fontSize: 'small' }} />
                </Tooltip>
            </>,
        },
        ap_billingNotification: {
            value: !primaryExists || false,
            error: '',
            hidden: entity !== 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <>
                <span style={{ fontWeight: 'bold' }}>Billing</span>
                <Tooltip title="Toggle to receive invoices for this contact">
                    <InfoCircleOutlined style={{ paddingLeft: '5%', fontSize: 'small' }} />
                </Tooltip>
            </>,
        },
        ap_drivingExperienceYears: {
            value: null,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'number',
            label: 'Driving Experience',
            suffix: 'years',
        },
        ap_everBankrupt: {
            value: false,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Ever Bankrupt?</b>,
        },
        ap_everRepossessed: {
            value: false,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Ever Repossessed?</b>,
        },
        ap_defendantInLegalAction: {
            value: false,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Defendant in a Legal Action?</b>,
        },
        ap_homeowner: {
            value: false,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Home Owner?</b>,
        },
        ap_residentAtleast2Years: {
            value: false,
            error: '',
            hidden: entity === 'agent',
            required: false,
            disabled: false,
            inputType: 'switch',
            label: <b>Resident for 2 years?</b>,
        },
    }
}

function generateFinalData(contactData, entity, entityId) {

    const agentData = {
        ap_agentId: entityId,
        ap_smsNotification: contactData.ap_smsNotification.value,
        ap_whatsAppNotification: contactData.ap_whatsAppNotification.value,
        ap_emailNotification: contactData.ap_emailNotification.value,
        ap_billingNotification: contactData.ap_billingNotification.value,
    }

    const clientData = {
        ap_clientId: entityId,
        ap_drivingExperienceYears: contactData.ap_drivingExperienceYears.value,
        ap_everBankrupt: contactData.ap_everBankrupt.value,
        ap_everRepossessed: contactData.ap_everRepossessed.value,
        ap_defendantInLegalAction: contactData.ap_defendantInLegalAction.value,
        ap_homeowner: contactData.ap_homeowner.value,
        ap_residentAtleast2Years: contactData.ap_residentAtleast2Years.value,
    }

    return {
        ap_contactType: entity,
        ap_firstName: contactData.ap_firstName.value,
        ap_lastName: contactData.ap_lastName.value,
        ap_fullName: contactData.ap_firstName.value + " " + contactData.ap_lastName.value,
        ap_email: contactData.ap_email.value,
        ap_telephone: utils.TransformTelephone(contactData.ap_telephone.value),
        ap_address: contactData.ap_address.value,
        ap_city: contactData.ap_city.value,
        ap_stateOrProvince: contactData.ap_stateOrProvince.value,
        ...(contactData.ap_country.value === 'USA' && { ap_state: contactData.ap_stateOrProvince.value }),
        ...(contactData.ap_country.value === 'Canada' && { ap_province: contactData.ap_stateOrProvince.value }),
        ap_postalZipCode: contactData.ap_postalZipCode.value,
        ap_country: contactData.ap_country.value,
        ap_primaryContact: contactData.ap_primaryContact.value,
        ap_lastModifiedBy: "beacon",
        ...(entity === 'agent' && { ap_agentData: agentData }),
        ...(entity === 'client' && { ap_clientData: clientData }),
    }
}

export default {
    generateContactFields: generateContactFields,
    generateFinalData: generateFinalData
}