import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import HomeContent from "../../components/HomeContent";
import GoBackButton from "../../components/GoBackButton";

import Error404 from "../../layouts/Error404";
import Listing from "../../layouts/Listing";
import ListUtils from "./utils";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import styles from "./index.module.css";

export default function IndividualListing() {
    const { id } = useParams();

    const [loader, setLoader] = useState({
        loading: false,
        tip: '',
    });
    const [headerName, setHeaderName] = useState(<></>);
    const [showError, setShowError] = useState(false);
    const [listingData, setListingData] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (!id) return;
        setLoader({ loading: true, tip: 'Loading...' });
        ApiService.fetchIndividualVendorListing(id)
            .then(async listingResponse => {
                const updatedList = await ListUtils.fetchAndClean(listingResponse);
                setShowError(!listingResponse);
                setListingData(!listingResponse ? {} : updatedList);
                setLoader({ loading: false, tip: '' });
            })
            .catch(error => {
                NotificationService.error("There was an error loading the listing. Please try again later.")
                setLoader({ loading: false, tip: '' });
            });
    }, [id, refresh]);

    // Generate Header Details when 'listingData' changes
    useEffect(() => {
        if (Object.keys(listingData).length === 0)
            return;

        const entity = `Vendor Listings | `;
        const headerDetails = listingData?.assetDescription;
        setHeaderName(
            <div className={`${styles["Header"]} Font_SemiBold`}>
                <>{entity}</>
                <span className={`${styles["HeaderDetails"]} Font_SemiBold`}>{headerDetails}</span>
            </div>
        );
    }, [listingData]);

    return (
        <Spin spinning={loader?.loading} tip={loader?.tip}>
            <HomeContent
                bgColor="transparent"
                contentHeader={
                    <>
                        {showError ?
                            <></> :
                            <div className={styles["ContentHeader"]}>
                                <GoBackButton />
                                {headerName}
                            </div>
                        }
                    </>
                }
                content={
                    <>
                        {showError ?
                            <div className={styles["Content"]}>
                                <Error404 />
                            </div> :
                            <Listing
                                data={listingData}
                                setShowError={setShowError}
                                setRefresh={setRefresh}
                            />
                        }
                    </>
                }
            />
        </Spin>
    )
}