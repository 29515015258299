import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Registration = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M21 20.3C21 18.3639 21.7691 16.5071 23.1381 15.1381C24.5071 13.7691 26.3639 13 28.3 13H57.5C58.468 13.0002 59.3962 13.3849 60.0806 14.0695L78.3306 32.3195C79.0151 33.0038 79.3998 33.932 79.4 34.9V78.7C79.4 80.6361 78.6309 82.4929 77.2619 83.8619C75.8929 85.2309 74.0361 86 72.1 86H28.3C26.3639 86 24.5071 85.2309 23.1381 83.8619C21.7691 82.4929 21 80.6361 21 78.7V20.3ZM70.5889 34.9L57.5 21.8111V34.9H70.5889ZM50.2 20.3H28.3V78.7H72.1V42.2H53.85C52.882 42.2 51.9536 41.8154 51.2691 41.1309C50.5846 40.4464 50.2 39.518 50.2 38.55V20.3ZM35.6 53.15C35.6 52.182 35.9846 51.2536 36.6691 50.5691C37.3536 49.8846 38.282 49.5 39.25 49.5H61.15C62.118 49.5 63.0464 49.8846 63.7309 50.5691C64.4154 51.2536 64.8 52.182 64.8 53.15C64.8 54.118 64.4154 55.0464 63.7309 55.7309C63.0464 56.4154 62.118 56.8 61.15 56.8H39.25C38.282 56.8 37.3536 56.4154 36.6691 55.7309C35.9846 55.0464 35.6 54.118 35.6 53.15ZM35.6 67.75C35.6 66.782 35.9846 65.8536 36.6691 65.1691C37.3536 64.4846 38.282 64.1 39.25 64.1H61.15C62.118 64.1 63.0464 64.4846 63.7309 65.1691C64.4154 65.8536 64.8 66.782 64.8 67.75C64.8 68.718 64.4154 69.6464 63.7309 70.3309C63.0464 71.0155 62.118 71.4 61.15 71.4H39.25C38.282 71.4 37.3536 71.0155 36.6691 70.3309C35.9846 69.6464 35.6 68.718 35.6 67.75Z" fill="white" />
        </svg>
    );
};

export default function RegistrationIcon(props) {
    return <Icon component={Registration} {...props} />
}