import { theme } from "antd";
import fallbackTheme from '../../../../../constants/DEFAULT_THEME';

export default function FundingInitiated() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor || fallbackTheme.token.ap_userSecondaryColor} />
            <path d="M29.0671 6.7514C25.6766 5.94437 22.1239 6.14834 18.848 7.33809C15.5721 8.52784 12.7165 10.6512 10.6341 13.446C8.55163 16.2407 7.33355 19.5843 7.13035 23.0636C6.92715 26.5429 7.74771 30.0056 9.49067 33.0237C11.2336 36.0418 13.8226 38.4832 16.9377 40.0462C20.0529 41.6092 23.5577 42.2254 27.0191 41.8185C27.9568 41.7083 28.6276 40.8588 28.5174 39.9211C28.4072 38.9835 27.5577 38.3127 26.62 38.4229C23.8221 38.7517 20.9891 38.2537 18.4711 36.9903C15.953 35.7269 13.8603 33.7535 12.4515 31.3139C11.0426 28.8742 10.3793 26.0753 10.5436 23.2629C10.7078 20.4505 11.6924 17.7479 13.3757 15.4888C15.059 13.2298 17.3672 11.5134 20.0152 10.5518C22.6631 9.59006 25.5348 9.42519 28.2755 10.0775C31.0161 10.7299 33.5056 12.1708 35.4363 14.2223C37.3671 16.2739 38.6545 18.8461 39.1395 21.6212C39.3021 22.5513 40.1878 23.1734 41.1178 23.0109C42.0479 22.8483 42.6701 21.9626 42.5075 21.0326C41.9074 17.5994 40.3147 14.4171 37.9261 11.8791C35.5375 9.34108 32.4577 7.55843 29.0671 6.7514Z" fill="white" stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M38.7892 27.6883C38.7892 26.9651 38.2029 26.3788 37.4796 26.3788C36.7564 26.3788 36.1701 26.9651 36.1701 27.6883V28.1922C35.2619 28.2866 34.4086 28.69 33.7571 29.3415C33.0074 30.0912 32.5862 31.108 32.5862 32.1681C32.5862 33.2283 33.0074 34.2451 33.7571 34.9947C34.5067 35.7444 35.5235 36.1656 36.5837 36.1656H38.3756C38.7412 36.1656 39.0918 36.3108 39.3503 36.5693C39.6088 36.8278 39.754 37.1784 39.754 37.5439C39.754 37.9095 39.6088 38.2601 39.3503 38.5186C39.0918 38.7771 38.7412 38.9223 38.3756 38.9223H33.8958C33.1725 38.9223 32.5862 39.5086 32.5862 40.2318C32.5862 40.9551 33.1725 41.5414 33.8958 41.5414H36.1701V42.0238C36.1701 42.747 36.7564 43.3333 37.4796 43.3333C38.2029 43.3333 38.7892 42.747 38.7892 42.0238V41.5199C39.6974 41.4255 40.5507 41.0221 41.2022 40.3705C41.9519 39.6209 42.373 38.6041 42.373 37.5439C42.373 36.4838 41.9519 35.467 41.2022 34.7173C40.4525 33.9677 39.4358 33.5465 38.3756 33.5465H36.5837C36.2181 33.5465 35.8675 33.4013 35.609 33.1428C35.3505 32.8843 35.2053 32.5337 35.2053 32.1681C35.2053 31.8026 35.3505 31.452 35.609 31.1935C35.8675 30.935 36.2181 30.7898 36.5837 30.7898H41.0635C41.7867 30.7898 42.373 30.2035 42.373 29.4802C42.373 28.757 41.7867 28.1707 41.0635 28.1707H38.7892V27.6883Z" fill="#645A80" />
            <path d="M39.1892 27.6883C39.1892 26.7442 38.4238 25.9788 37.4796 25.9788C36.5355 25.9788 35.7701 26.7442 35.7701 27.6883V27.8466C34.9064 28.0092 34.1045 28.4284 33.4742 29.0587C32.6495 29.8834 32.1862 31.0019 32.1862 32.1681C32.1862 33.3344 32.6495 34.4529 33.4742 35.2776C34.2989 36.1023 35.4174 36.5656 36.5837 36.5656H38.3756C38.6351 36.5656 38.8839 36.6686 39.0674 36.8521C39.2509 37.0356 39.354 37.2845 39.354 37.5439C39.354 37.8034 39.2509 38.0523 39.0674 38.2358C38.8839 38.4192 38.6351 38.5223 38.3756 38.5223H33.8958C32.9516 38.5223 32.1862 39.2877 32.1862 40.2318C32.1862 41.176 32.9516 41.9414 33.8958 41.9414H35.7701V42.0238C35.7701 42.9679 36.5355 43.7333 37.4796 43.7333C38.4238 43.7333 39.1892 42.9679 39.1892 42.0238V41.8655C40.0529 41.7029 40.8548 41.2837 41.485 40.6534C42.3097 39.8287 42.773 38.7102 42.773 37.5439C42.773 36.3777 42.3097 35.2592 41.485 34.4345C40.6604 33.6098 39.5419 33.1465 38.3756 33.1465H36.5837C36.3242 33.1465 36.0753 33.0434 35.8918 32.86C35.7084 32.6765 35.6053 32.4276 35.6053 32.1681C35.6053 31.9087 35.7084 31.6598 35.8919 31.4763C36.0753 31.2928 36.3242 31.1898 36.5837 31.1898H41.0635C42.0076 31.1898 42.773 30.4244 42.773 29.4802C42.773 28.5361 42.0076 27.7707 41.0635 27.7707H39.1892V27.6883Z" stroke="#645A80" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.2458 15.1447C26.2458 14.4215 25.6595 13.8352 24.9362 13.8352C24.213 13.8352 23.6267 14.4215 23.6267 15.1447V24.1044C23.6267 24.4517 23.7647 24.7848 24.0103 25.0304L26.6982 27.7183C27.2096 28.2297 28.0387 28.2297 28.5501 27.7183C29.0615 27.2069 29.0615 26.3777 28.5501 25.8663L26.2458 23.562V15.1447Z" fill="white" />
            <path d="M26.6458 15.1447C26.6458 14.2006 25.8804 13.4352 24.9362 13.4352C23.9921 13.4352 23.2267 14.2006 23.2267 15.1447V24.1044C23.2267 24.5578 23.4068 24.9926 23.7274 25.3132L26.4153 28.0011C27.0829 28.6687 28.1653 28.6687 28.8329 28.0011C29.5006 27.3335 29.5006 26.2511 28.8329 25.5835L26.6458 23.3963V15.1447Z" stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}