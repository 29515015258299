import Icon from '@ant-design/icons';

function phone() {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.58913 2.93563H5.90365L6.97182 5.60643L5.25551 6.75064C5.15444 6.81808 5.07157 6.90942 5.01427 7.01655C4.95696 7.12369 4.92699 7.24332 4.92701 7.36482V7.38107C4.92739 7.41408 4.92887 7.44707 4.93144 7.47998C4.93587 7.54052 4.94325 7.62246 4.95728 7.72359C4.98607 7.9229 5.04143 8.19751 5.14921 8.52085C5.36624 9.17046 5.79144 10.0113 6.6197 10.8395C7.44796 11.6678 8.28877 12.093 8.93765 12.31C9.26172 12.4178 9.53559 12.4724 9.73638 12.502C9.84971 12.5178 9.9638 12.5276 10.0782 12.5315L10.0878 12.5322H10.0937C10.2307 12.5321 10.3658 12.4939 10.4823 12.4218C10.5989 12.3497 10.6931 12.2466 10.7544 12.124L11.249 11.1348L14.5236 11.6811V14.8701C12.9653 15.0953 8.75605 15.3174 5.44892 12.0103C2.14178 8.70318 2.36324 4.49323 2.58913 2.93563ZM6.4573 7.72359L7.79122 6.8348C8.07303 6.64683 8.28162 6.36768 8.38203 6.04416C8.48243 5.72064 8.46853 5.37244 8.34266 5.05795L7.27449 2.38715C7.16487 2.1132 6.97569 1.87839 6.73135 1.71299C6.487 1.5476 6.19871 1.45921 5.90365 1.45923H2.55075C1.87972 1.45923 1.24856 1.92503 1.13857 2.6529C0.887583 4.30794 0.547273 9.1963 4.4051 13.0541C8.26293 16.912 13.1513 16.5709 14.8063 16.3207C15.5342 16.2099 16 15.5795 16 14.9085V11.6811C16 11.3316 15.8761 10.9934 15.6502 10.7267C15.4244 10.46 15.1112 10.2821 14.7665 10.2246L11.4918 9.67908C11.1804 9.62709 10.8605 9.67633 10.579 9.81956C10.2976 9.96278 10.0696 10.1925 9.92831 10.4749L9.67289 10.9864C9.58257 10.9642 9.49318 10.9383 9.40493 10.9089C8.94724 10.7569 8.31165 10.4439 7.66352 9.79572C7.01538 9.14758 6.70238 8.51199 6.55031 8.05356C6.51401 7.9452 6.48247 7.83507 6.4573 7.72359Z" fill="#447EB7" />
            <path d="M2.58913 2.93563H5.90365L6.97182 5.60643L5.25551 6.75064C5.15444 6.81808 5.07157 6.90942 5.01427 7.01655C4.95696 7.12369 4.92699 7.24332 4.92701 7.36482V7.38107C4.92739 7.41408 4.92887 7.44707 4.93144 7.47998C4.93587 7.54052 4.94325 7.62246 4.95728 7.72359C4.98607 7.9229 5.04143 8.19751 5.14921 8.52085C5.36624 9.17046 5.79144 10.0113 6.6197 10.8395C7.44796 11.6678 8.28877 12.093 8.93765 12.31C9.26172 12.4178 9.53559 12.4724 9.73638 12.502C9.84971 12.5178 9.9638 12.5276 10.0782 12.5315L10.0878 12.5322H10.0937C10.2307 12.5321 10.3658 12.4939 10.4823 12.4218C10.5989 12.3497 10.6931 12.2466 10.7544 12.124L11.249 11.1348L14.5236 11.6811V14.8701C12.9653 15.0953 8.75605 15.3174 5.44892 12.0103C2.14178 8.70318 2.36324 4.49323 2.58913 2.93563Z" fill="white" />
            <path d="M8.06867 7.25076L8.06847 7.25089L7.03875 7.93699C7.17434 8.32773 7.44997 8.87506 8.01707 9.44216C8.53462 9.95971 9.03575 10.2345 9.41597 10.3817L9.48097 10.2515L9.48113 10.2512C9.67023 9.87314 9.97554 9.56569 10.3522 9.37395C10.7289 9.18224 11.1571 9.11633 11.574 9.18588C11.574 9.18589 11.5741 9.18589 11.5741 9.1859L14.8486 9.73141L14.8487 9.73142C15.3102 9.80838 15.7294 10.0466 16.0318 10.4036C16.3341 10.7606 16.5 11.2132 16.5 11.6811C16.5 11.6811 16.5 11.6811 16.5 11.6811V14.9085C16.5 15.8019 15.8773 16.6635 14.8815 16.815L14.8811 16.815C13.1758 17.0729 8.08078 17.4369 4.05155 13.4077C0.0225588 9.37869 0.385532 4.28404 0.644184 2.57819L8.06867 7.25076ZM8.06867 7.25076C8.44591 6.99913 8.72515 6.62544 8.85956 6.19236C8.99395 5.75932 8.97536 5.29324 8.80691 4.87228C8.80689 4.87224 8.80687 4.8722 8.80686 4.87216L7.73873 2.20147L7.7387 2.20139M8.06867 7.25076L7.7387 2.20139M7.7387 2.20139C7.59196 1.83468 7.33871 1.52034 7.01162 1.29893M7.7387 2.20139L7.01162 1.29893M7.01162 1.29893C6.68454 1.07753 6.29862 0.959209 5.90365 0.959229M7.01162 1.29893L5.90365 0.959229M5.90365 0.959229C5.90364 0.959229 5.90363 0.959229 5.90361 0.959229M5.90365 0.959229H5.90361M5.90361 0.959229H2.55075C1.65781 0.959229 0.794933 1.58149 0.644223 2.57793L5.90361 0.959229Z" stroke="#447EB7" />
        </svg>
    );
}

function phoneHovered() {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67289 10.9859C9.58257 10.9637 9.49318 10.9378 9.40493 10.9084M9.67289 10.9859L9.92831 10.4744L9.40493 10.9084M9.67289 10.9859L9.79245 10.5004C9.71512 10.4814 9.63858 10.4593 9.56301 10.4341L9.56258 10.4339C9.51607 10.4185 9.46711 10.401 9.41597 10.3812M9.67289 10.9859L9.42101 10.8602L9.42097 10.8602L9.22555 10.7626L9.41597 10.3812M9.40493 10.9084C8.94724 10.7564 8.31165 10.4434 7.66352 9.79523C7.01538 9.14709 6.70238 8.5115 6.55031 8.05308L9.40493 10.9084ZM9.41597 10.3812C9.03575 10.234 8.53462 9.95922 8.01707 9.44167C7.44997 8.87457 7.17434 8.32724 7.03875 7.93651M9.41597 10.3812L9.48097 10.251L9.48113 10.2507C9.67023 9.87265 9.97554 9.5652 10.3522 9.37346C10.7289 9.18175 11.1571 9.11584 11.574 9.18539C11.574 9.1854 11.5741 9.18541 11.5741 9.18542L14.8486 9.73092L14.8487 9.73093C15.3102 9.80789 15.7294 10.0461 16.0318 10.4031C16.3341 10.7601 16.5 11.2128 16.5 11.6806C16.5 11.6806 16.5 11.6806 16.5 11.6806V14.908C16.5 15.8014 15.8773 16.663 14.8815 16.8145L14.8811 16.8146C13.1758 17.0724 8.08078 17.4364 4.05155 13.4072C0.0225591 9.3782 0.385532 4.28355 0.644184 2.5777L7.03875 7.93651M7.03875 7.93651L8.06847 7.2504L8.06867 7.25027C8.44591 6.99864 8.72515 6.62495 8.85956 6.19187C8.99395 5.75883 8.97536 5.29275 8.80691 4.87179C8.80689 4.87175 8.80687 4.87171 8.80686 4.87167L7.73873 2.20098L7.7387 2.20091C7.59196 1.83419 7.33871 1.51985 7.01162 1.29845C6.68454 1.07704 6.29862 0.95872 5.90365 0.95874C5.90364 0.95874 5.90363 0.95874 5.90361 0.95874H2.55075C1.65781 0.95874 0.794933 1.581 0.644223 2.57744L7.03875 7.93651Z" fill="#447EB7" stroke="#447EB7" />
        </svg>
    );
}

function phoneSelected() {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67289 10.9857C9.58257 10.9635 9.49318 10.9376 9.40493 10.9082M9.67289 10.9857L9.92831 10.4741L9.40493 10.9082M9.67289 10.9857L9.79245 10.5002C9.71512 10.4812 9.63858 10.459 9.56301 10.4338L9.56258 10.4337C9.51607 10.4182 9.46711 10.4008 9.41597 10.381M9.67289 10.9857L9.42101 10.8599L9.42097 10.8599L9.22555 10.7624L9.41597 10.381M9.40493 10.9082C8.94724 10.7561 8.31165 10.4431 7.66352 9.79498C7.01538 9.14684 6.70238 8.51125 6.55031 8.05283L9.40493 10.9082ZM9.41597 10.381C9.03575 10.2338 8.53462 9.95898 8.01707 9.44143C7.44997 8.87433 7.17434 8.32699 7.03875 7.93626M9.41597 10.381L9.48097 10.2508L9.48113 10.2505C9.67023 9.87241 9.97554 9.56495 10.3522 9.37322C10.7289 9.18151 11.1571 9.11559 11.574 9.18514C11.574 9.18515 11.5741 9.18516 11.5741 9.18517L14.8486 9.73067L14.8487 9.73069C15.3102 9.80765 15.7294 10.0458 16.0318 10.4028C16.3341 10.7598 16.5 11.2125 16.5 11.6803C16.5 11.6804 16.5 11.6804 16.5 11.6804V14.9078C16.5 15.8012 15.8773 16.6628 14.8815 16.8142L14.8811 16.8143C13.1758 17.0722 8.08078 17.4362 4.05155 13.4069C0.0225591 9.37796 0.385532 4.2833 0.644184 2.57746L7.03875 7.93626M7.03875 7.93626L8.06847 7.25016L8.06867 7.25002C8.44591 6.9984 8.72515 6.62471 8.85956 6.19163C8.99395 5.75859 8.97536 5.2925 8.80691 4.87155C8.80689 4.87151 8.80687 4.87147 8.80686 4.87143L7.73873 2.20074L7.7387 2.20066C7.59196 1.83395 7.33871 1.51961 7.01162 1.2982C6.68454 1.0768 6.29862 0.958476 5.90365 0.958496C5.90364 0.958496 5.90363 0.958496 5.90361 0.958496H2.55075C1.65781 0.958496 0.794933 1.58076 0.644223 2.5772L7.03875 7.93626Z" fill="white" stroke="white" />
        </svg>
    );
}

export default function JoinUsPhone({ hovered, selected }) {
    if (selected)
        return <Icon component={() => phoneSelected()} style={iconStyles} />
    else if (hovered)
        return <Icon component={() => phoneHovered()} style={iconStyles} />
    else
        return <Icon component={() => phone()} style={iconStyles} />
}

const iconStyles = {
    width: '15px',
    height: '40px'
}