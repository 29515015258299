import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Divider, Layout, theme } from "antd";
import { MoreOutlined, MenuOutlined } from '@ant-design/icons';

import ApiService from "../../api";

import LC_Logo from '../../assets/images/LC.svg';

import constants from "../../constants";
import sideNavConstants from "./constants";
import sideNavUtils from "./utils";

import styles from "./index.module.css";

const { Sider } = Layout;

export default function SideNavigator({ collapsed, setCollapsed, ...props }) {

    const { token: { ap_logoUrl } } = theme.useToken();

    const navigate = useNavigate();
    const [loader, setLoader] = useState({ loading: false, tip: '' });
    const [logo, setLogo] = useState(LC_Logo);
    const [defaultLogo, setDefaultLogo] = useState(true);

    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });
    const pathName = window.location.pathname?.split('/')[1];

    useEffect(() => {
        ApiService.fetchAgentProfileData().then((agentProfile) => {
            const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;
            const userUrl = agentProfile?.ap_settingsId?.ap_logoUrl;

            const defaultLogo = process.env.REACT_APP_STATIC_CONTAINER + process.env.REACT_APP_DEFAULT_LOGO_PATH;
            const logoUrl = userUrl ? `${userUrl}?${sasToken}` : defaultLogo;

            setLoader({ loading: true, tip: 'Fetching logo...' });
            ApiService.fetchAgentLogo(logoUrl).then((logoResp) => {
                setLoader({ loading: false, tip: '' });
                if (!logoResp) return;
                setLogo(logoResp);
                setDefaultLogo(!userUrl);
            });

        });
    }, []);

    useEffect(() => {
        if (!ap_logoUrl) return;
        const sasToken = JSON.parse(localStorage?.getItem("user"))?.sasToken;
        setLoader({ loading: true, tip: 'Fetching logo...' });
        ApiService.fetchAgentLogo(ap_logoUrl + "?" + sasToken).then((response) => {
            setLoader({ loading: false, tip: '' });
            if (!response) return;
            setLogo(response);
            setDefaultLogo(false);
        });
    }, [ap_logoUrl]);

    useEffect(() => {
        setCollapsed(isMobile);
    }, [isMobile]);

    const logoContainer = defaultLogo ? "Default_Logo_Container" : "User_Logo_Container";
    const desktopLogo = collapsed ? (defaultLogo ? "Logo_Desktop_Collapsed" : "Logo_Desktop") : (defaultLogo ? "Logo_Desktop_Expanded" : "User_Logo_Desktop");
    const mobileLogo = collapsed ? "Hide" : (defaultLogo ? "" : "User_Mobile_Logo");

    const logoContent = (
        <div className={styles[logoContainer]}>
            <img
                src={`data:image/svg+xml;base64,${btoa(logo)}`}
                className={styles[isMobile ? mobileLogo : desktopLogo]}
            />
        </div>
    );

    const expandContractIcon = (
        <div>
            {collapsed ?
                <MenuOutlined
                    onClick={() => sideNavUtils.handleCollapse(setCollapsed)}
                    className={styles["Icon_Menu"]}
                /> :
                <MoreOutlined
                    onClick={() => sideNavUtils.handleCollapse(setCollapsed)}
                    className={styles["Icon_More"]}
                />
            }
        </div>
    );

    const optionHeadersGenerator = (headerName) => (
        <div className={styles[collapsed ? "Invisible" : "SideNav_HeaderName"]}>{headerName}</div>
    )

    const dividerContent = (<Divider className={styles["SideNav_Divider"]} />);

    const navItemContent = (navItem) => (<>
        {navItem.map((item, index) => (
            <div
                key={index}
                onClick={() => navigate(`/${item.path}`)}
                className={`${styles[(pathName === item.path) ? (collapsed ? "SideNav_Item_Selected_Collapsed" : "SideNav_Item_Selected") : ""]} ${styles["SideNav_Item"]} ${styles[collapsed ? "Collapsed" : ""]}`}
            >
                <div>&nbsp;</div>
                <div className={styles["SideNav_Item_Icon"]}>{item.icon}</div>
                <div className={styles[collapsed ? "Hide" : "SideNav_Item_Name"]}>{item.name}</div>
            </div>
        ))}
    </>)

    return (
        <Sider
            collapsed={collapsed}
            collapsedWidth={isMobile ? 0 : 100}
            trigger={isMobile ? null : ''}
            onCollapse={(closed) => {
                sideNavUtils.handleCollapse(setCollapsed);
                props?.setMobileSideOpen(!closed);
            }}
            width={isMobile ? '90%' : '250px'}
            className={styles["Sider"]}
        >
            <div className={styles["Sider_Container"]}>
                <div>
                    {logoContent}
                    {dividerContent}
                    {expandContractIcon}
                    {optionHeadersGenerator("Main")}
                    <div>{navItemContent(sideNavConstants.mainNavItems)}</div>
                    {dividerContent}
                    {optionHeadersGenerator("Additional")}
                    <div>{navItemContent(sideNavConstants.additionalNavItems)}</div>
                </div>
                <div className={styles[collapsed ? "Hide" : "Copyright"]}>© Lantern Capital 2024</div>
            </div>
        </Sider>
    )
}