import { Button } from "antd";
import styles from "./index.module.css";

export default function generateRequirementsTableColumns(navigate) {

    return [
        {
            title: 'Client',
            key: 'ap_client',
            render: (record) => (record?.ap_clientId?.ap_businessName)
        },
        {
            title: 'Deal',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
        },
        {
            title: 'Action',
            key: '_id',
            render: (record) => (
                <Button
                    type="primary"
                    className={styles["Requirements_Table_Button"]}
                    onClick={() => navigate(`/requirements/${record._id}`)}
                >View</Button>
            )
        },
    ];
}