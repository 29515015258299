import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

const deleteApplicationDraft = (id, setRefresh) => {
    if (!id) return;

    ApiService.deleteApplicationDraft(id)
        .then(deleteResponse => {
            if (deleteResponse?.error)
                NotificationService.error(deleteResponse?.error);
            else {
                NotificationService.success(deleteResponse?.success);
                setRefresh((prev) => prev + 1);
            }
        });
}

const DraftUtils = {
    deleteApplicationDraft,
}

export default DraftUtils;