import axios from "axios";
import EncryptionService from "./encryption.service";
import NotificationService from "./notification.service";
import AuthService from "./auth.service";
import appInsightsObj from "./app-insight-service";

export default class HttpInterceptorService extends EncryptionService {
  _httpClient = Symbol('protected');
  #baseURL = process.env.REACT_APP_BACKEND_PROXY_IP + 'api/v1.0/';

  constructor() {
    super();
    if (this.constructor === HttpInterceptorService) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this._httpClient = axios.create({
      withCredentials: true,
      credentials: 'include',
      baseURL: this.#baseURL
    });

    this.requestInterceptor();
    this.responseInterceptor();
  }

  /**
   ** Request Interceptor: Add access token to headers before sending requests
   */
  requestInterceptor() {
    this._httpClient.interceptors.request.use(
      (config) => {
        try {
          let requestHeadersRequired = true;

          // Check for routes that don't require authentication
          if ((config.url === 'users/login' || config.url === 'users/register' || config.url === 'registration_request' || config.url === 'common/verify_captcha' || config.url === 'code/validate' || config.url === 'code/validate-reset-code' || config.url === 'code/expire' || config.url === 'users/reset-password' || config.url === 'users/update-password') && (config.method === 'post'))
            requestHeadersRequired = false;

          if ((config.url).includes('registration_request') || config.url === 'users/update-password')
            requestHeadersRequired = false;

          if (config.headers?.['x-access-token'])
            requestHeadersRequired = false;

          if (requestHeadersRequired) {
            const user = AuthService.getCurrentUser();
            if (user && user !== '') {
              const token = user?.access_token;
              if (!token || token === '') {
                // Add notification here if needed
                window.location.href = '/';
              } else {
                config.headers["x-access-token"] = token ? token : '';
              }
            }
            else {
              // Add notification here if needed
              console.log("User not found in local storage");
              window.location.href = '/';
            }
          }

          config = this.encrypt(config);  // Your existing encryption logic
          return config;
        } catch (error) {
          appInsightsObj.error(error);
          NotificationService.error('Error', "Something went wrong. Please try again later.");
        }
      },
      (error) => {
        appInsightsObj.error(error);
        return Promise.reject(error);
      }
    );
  }

  /**
   ** Response Interceptor: Handle 401 errors and try refreshing the access token (token rotation)
   */
  responseInterceptor() {
    this._httpClient.interceptors.response.use(
      async (response) => {
        try {
          const { data } = await this.decrypt(response);
          return this._isSecure && data?.data?.data ? data.data : data;
        } catch (error) {
          this.handleInterceptorError(error, "Something went wrong. Please try again later.");
        }
      },
      async (error) => {
        const originalRequest = error.config;
        let data = (await this.decrypt(error?.response))?.data?.data || (await this.decrypt(error?.response))?.data;

        // If response is 401 and the request is not retried yet, and it's not a public route
        if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.isPublic) {
          originalRequest._retry = true;
          try {
            if (originalRequest.url === 'users/refresh-token') {
              AuthService.logout();  // Force logout if refresh fails
              return null;
            }
            const newAccessToken = await AuthService.refreshToken();
            if (newAccessToken) {
              originalRequest.headers['x-access-token'] = newAccessToken;
              return this._httpClient(originalRequest); // Retry the original request
            }
          } catch (refreshError) {
            return this.handleAuthError();
          }
        } else {
          return this.handleResponseError(data);
        }
      }
    );
  }

  /**
   * Handle generic response error notifications and rejection
   * @param {Object} data - decrypted error response data
   */
  handleResponseError(data) {
    const errorMessage = data?.errors?.message || data?.errors || "Something went wrong. Please try again later.";
    console.log("Error response:", data);
    NotificationService.error('Error', errorMessage);
    return Promise.reject(data);
  }

  /**
   * Handle authentication error (logout and redirect to login)
   */
  handleAuthError() {
    AuthService.logout(); // Force logout if refresh fails
    window.location.href = '/';
  }

  /**
   * Handle general interceptor errors
   * @param {Object} error - the error object
   * @param {string} message - the error message
   */
  handleInterceptorError(error, message) {
    appInsightsObj.error(error);
    NotificationService.error('Error', message);
  }

}
