import constants from "../../constants";

function joinUsInputFields() {
    return {
        firstName: {
            label: "First Name",
            value: "",
            error: "",
        },
        lastName: {
            label: "Last Name",
            value: "",
            error: "",
        },
        phoneNumber: {
            label: "Phone Number",
            value: "",
            error: "",
        },
        email: {
            label: "Email",
            value: "",
            error: "",
        },
    }
}

function joinUsSelectFields() {
    return {
        provinceState: {
            label: "Province/State",
            value: "",
            error: "",
            dropdownContent: constants?.CANADIAN_PROVINCES,
        },
        country: {
            label: "Country",
            value: "Canada",
            error: "",
            dropdownContent: constants?.COUNTRIES,
        },
    }
}

function joinUsBusinessNameFields() {
    return {
        businessName: {
            label: "Business Name",
            value: "",
            error: "",
        }
    }
}

function joinUsTextAreaFields() {
    return {
        businessDetails: {
            label: "Business Details",
            placeholder: "Example: “I work mainly with clients in the transportation and construction industry. I can bring about 4 deals per month, funding about $500k per month, for a total of 48 deals per year with a volume of $6,000,000.00.”",
            value: "",
            error: "",
        },
    }
}

function joinUsTimePreferences() {
    return {
        sunday: {
            label: "Sunday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        monday: {
            label: "Monday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        tuesday: {
            label: "Tuesday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        wednesday: {
            label: "Wednesday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        thursday: {
            label: "Thursday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        friday: {
            label: "Friday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        saturday: {
            label: "Saturday",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
        anyday: {
            label: "Any day",
            selected: false,
            startTime: "9:00 am",
            endTime: "5:00 pm",
        },
    }
}

function joinUsContactPreferences() {
    return {
        phone: true,
        email: false,
    }
}

const joinUsConstants = {
    joinUsInputFields: joinUsInputFields,
    joinUsSelectFields: joinUsSelectFields,
    joinUsTextAreaFields: joinUsTextAreaFields,
    joinUsBusinessNameFields: joinUsBusinessNameFields,
    joinUsTimePreferences: joinUsTimePreferences,
    joinUsContactPreferences: joinUsContactPreferences,
}

export default joinUsConstants;