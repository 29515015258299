import { theme } from 'antd';
import styles from './index.module.css';

export default function NotificationComparisionBlock({ data, fullWidth }) {

    const { token: { ap_userPrimaryColor }, } = theme.useToken();

    return (
        <div className={styles["NotificationDetailsBlock"]}>

            <>
                {(data).map((item, index) => (

                    <>

                        {(!item.hidden) ?
                            <div
                                key={index}
                                className={styles["NotificationRow"]}
                                style={fullWidth ? { padding: '0%' } : {}}
                            >
                                <div className={styles["NewDetails"]}>
                                    <div
                                        className={styles["NotificationDetailsTitle"]}
                                        style={fullWidth ? { color: ap_userPrimaryColor, padding: '0%' } : { color: ap_userPrimaryColor }}
                                    >{item.data.new.label}</div>

                                    <div> {item.data.new.value}</div>
                                </div>

                                <div>
                                    <div
                                        className={styles["NotificationDetailsTitle"]}
                                        style={fullWidth ? { color: ap_userPrimaryColor, padding: '0%' } : { color: ap_userPrimaryColor }}
                                    >{item.data.old.label}</div>

                                    <div> {item.data.old.value}</div>
                                </div>

                            </div> :
                            <></>
                        }

                    </>

                ))}
            </>

        </div>
    );
}