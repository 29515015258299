import utils from "../../../utils"

export default function generateDetails(notificationData) {

    const pgs = [notificationData.personalGuarantor1, notificationData.personalGuarantor2, notificationData.personalGuarantor3, notificationData.personalGuarantor4].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const corpCoApp = [notificationData.corpCoApp1, notificationData.corpCoApp2, notificationData.corpCoApp3].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const drawdownDetails = {
        title: "Drawdown Details",
        data: [
            {
                label: "Asset Description",
                value: notificationData.assetDescription
            },
            {
                label: "Broker Funded Date",
                value: utils.TransformNotificationDate(notificationData.brokerFundedDate)
            },
            {
                label: "Lender",
                value: notificationData.lenderAbbreviation || notificationData.lenderName
            },
            {
                label: "Vendor",
                value: notificationData.vendorName
            },
            {
                label: "Admin",
                value: notificationData.adminName,
            }
        ]
    }

    const fundingDetails = {
        title: "Funding Details",
        data: [
            {
                label: "Amount",
                value: utils.TransformDBCurrency(notificationData.drawdownAmount),
            },
            {
                label: "Rate",
                value: `${notificationData.rate} %`,
            },
            {
                label: "Term",
                value: `${notificationData.term} months`,
            },
            {
                label: "Agent Fee",
                value: utils.TransformDBCurrency(notificationData.agentFee),
            },
            {
                label: "Agent Fee Tax",
                value: utils.TransformDBCurrency(notificationData.agentFeeTax - notificationData.agentFee),
            },
            {
                label: "Total Agent Fee",
                value: utils.TransformDBCurrency(notificationData.agentFeeTax),
            },
            {
                label: "Days to Fund",
                value: `${notificationData.daysToBrokerFund} business day(s)`
            }
        ]
    }

    return {
        drawdownDetails: drawdownDetails,
        fundingDetails: fundingDetails,
    }
}