import { theme } from 'antd';
import Icon from '@ant-design/icons';

const ActionRequired = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="35" height="35" rx="5" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.125 5.5C10.2962 5.5 9.50134 5.82924 8.91529 6.41529C8.32924 7.00134 8 7.7962 8 8.625V27.375C8 28.2038 8.32924 28.9987 8.91529 29.5847C9.50134 30.1708 10.2962 30.5 11.125 30.5H23.625C24.4538 30.5 25.2487 30.1708 25.8347 29.5847C26.4208 28.9987 26.75 28.2038 26.75 27.375V13.9594C26.7495 13.338 26.5022 12.7422 26.0625 12.3031L19.9469 6.18594C19.5075 5.74685 18.9118 5.50013 18.2906 5.5H11.125ZM10.0201 7.52015C9.72712 7.81317 9.5625 8.2106 9.5625 8.625V27.375C9.5625 27.7894 9.72712 28.1868 10.0201 28.4799C10.3132 28.7729 10.7106 28.9375 11.125 28.9375H23.625C24.0394 28.9375 24.4368 28.7729 24.7299 28.4799C25.0229 28.1868 25.1875 27.7894 25.1875 27.375V14.875H19.7188C19.0971 14.875 18.501 14.6281 18.0615 14.1885C17.6219 13.749 17.375 13.1529 17.375 12.5312V7.0625H11.125C10.7106 7.0625 10.3132 7.22712 10.0201 7.52015ZM19.7188 13.3125H24.8641L18.9375 7.38594V12.5312C18.9375 12.7385 19.0198 12.9372 19.1663 13.0837C19.3128 13.2302 19.5115 13.3125 19.7188 13.3125Z" fill="white" />
            <path d="M14.814 18.3085C14.531 18.0255 14.0722 18.0255 13.7892 18.3085C13.5062 18.5915 13.5062 19.0503 13.7892 19.3333L16.4882 22.0324L13.7892 24.7314C13.5062 25.0144 13.5062 25.4732 13.7892 25.7562C14.0722 26.0392 14.531 26.0392 14.814 25.7562L17.513 23.0572L20.2121 25.7562C20.4951 26.0392 20.9539 26.0392 21.2369 25.7562C21.5199 25.4732 21.5199 25.0144 21.2369 24.7314L18.5378 22.0324L21.2369 19.3333C21.5199 19.0503 21.5199 18.5915 21.2369 18.3085C20.9539 18.0255 20.4951 18.0255 20.2121 18.3085L17.513 21.0076L14.814 18.3085Z" fill="white" />
            <path d="M14.814 18.3085C14.531 18.0255 14.0722 18.0255 13.7892 18.3085C13.5062 18.5915 13.5062 19.0503 13.7892 19.3333L16.4882 22.0324L13.7892 24.7314C13.5062 25.0144 13.5062 25.4732 13.7892 25.7562C14.0722 26.0392 14.531 26.0392 14.814 25.7562L17.513 23.0572L20.2121 25.7562C20.4951 26.0392 20.9539 26.0392 21.2369 25.7562C21.5199 25.4732 21.5199 25.0144 21.2369 24.7314L18.5378 22.0324L21.2369 19.3333C21.5199 19.0503 21.5199 18.5915 21.2369 18.3085C20.9539 18.0255 20.4951 18.0255 20.2121 18.3085L17.513 21.0076L14.814 18.3085Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default function ActionRequiredIcon(props) {
    return <Icon component={ActionRequired} {...props} />
}