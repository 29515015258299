import Icon from '@ant-design/icons';
import { theme } from 'antd';

function SubmissionUpdated() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="180" height="110" viewBox="0 0 178 147" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M146.261 83.7448C146.261 92.5287 144.575 100.758 141.202 108.431C137.83 116.105 133.279 122.781 127.551 128.459C121.813 134.137 115.073 138.644 107.329 141.979C99.586 145.315 91.2774 146.985 82.4035 146.989C73.5344 146.989 65.2258 145.319 57.4777 141.979C49.7296 138.639 42.989 134.132 37.256 128.459C31.523 122.776 26.9725 116.1 23.6046 108.431C20.2367 100.762 18.5504 92.5334 18.5457 83.7448C18.5457 74.1409 20.6151 65.0337 24.7541 56.423C28.893 47.8124 34.7466 40.5228 42.315 34.5544L32.7363 34.5544C30.7259 34.5544 29.0396 33.8798 27.6773 32.5306C26.315 31.1814 25.6362 29.5136 25.641 27.5272C25.641 25.5362 26.3221 23.866 27.6844 22.5168C29.0467 21.1676 30.7307 20.4953 32.7363 20.5L61.1176 20.5C63.1279 20.5 64.8142 21.1746 66.1765 22.5238C67.5388 23.8731 68.2176 25.5409 68.2129 27.5272L68.2129 55.636C68.2129 57.627 67.5317 59.2972 66.1694 60.6464C64.8071 61.9956 63.1232 62.6679 61.1176 62.6632C59.1072 62.6632 57.4209 61.9886 56.0586 60.6393C54.6963 59.2901 54.0175 57.6223 54.0222 55.636L54.0222 43.3384C47.3999 48.1403 42.1967 54.0549 38.4125 61.0821C34.6284 68.1093 32.7363 75.6635 32.7363 83.7448C32.7363 97.4478 37.5564 109.073 47.1965 118.621C56.8367 128.168 68.5724 132.94 82.4035 132.935C96.2394 132.935 107.977 128.161 117.618 118.614C127.258 109.066 132.075 97.4431 132.071 83.7448C132.071 72.9697 128.878 63.2183 122.492 54.4906C116.106 45.7628 107.71 39.8787 97.3037 36.8382C95.2933 36.2526 93.6094 35.1681 92.2518 33.5846C90.8942 32.0012 90.4496 30.2163 90.9179 28.2299C91.3909 26.1218 92.5735 24.5688 94.4656 23.5709C96.3576 22.573 98.368 22.3692 100.497 22.9595C114.096 26.8245 125.124 34.3787 133.582 45.6222C142.04 56.8657 146.266 69.5733 146.261 83.7448Z" fill={ap_userSecondaryColor} />
            <path d="M162.093 50.2355L165.761 58.1643L169.43 50.2355L177.5 46.6315L169.43 43.0274L165.761 35.0986L162.093 43.0274L154.023 46.6315L162.093 50.2355Z" fill="#FFC700" />
            <path d="M130.546 17.7993L142.578 23.2774L148.154 35.0986L153.729 23.2774L165.761 17.7993L153.729 12.3212L148.154 0.5L142.578 12.3212L130.546 17.7993Z" fill="#FFC700" />
            <path d="M14.3269 132.719L11.0457 125.5L7.7644 132.719L0.545653 136L7.7644 139.281L11.0457 146.5L14.3269 139.281L21.5457 136L14.3269 132.719Z" fill="#FFC700" />
        </svg>
    );
}

export default function SubmissionUpdatedIcon(props) {
    return <Icon component={SubmissionUpdated} {...props} />
}