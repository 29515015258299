import { Tag } from "antd";
import SearchColumn from "../../components/SearchColumn";
import SortColumn from "../../components/SortColumn";
import utils from "../../utils";
import dayjs from "dayjs";
import styles from "./index.module.css";

function generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort) {

    const columns = [
        {
            title: 'LC#',
            dataIndex: 'lcNumber',
            key: 'lcNumber',
            ...SortColumn(customSort, setCustomSort, 'lcNumber'),
            width: 100,
            ellipsis: true,
        }
    ];

    if (entity !== 'client') {
        columns.push({
            title: 'Client',
            key: 'clientName',
            dataIndex: 'clientName',
            ...SortColumn(customSort, setCustomSort, 'clientName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'clientName'),
            width: 150,
            ellipsis: true,
        });
    }

    columns.push(
        {
            title: 'Asset Description',
            dataIndex: 'assetDescription',
            key: 'assetDescription',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Lender',
            key: 'ap_lender',
            dataIndex: 'lenderName',
            ...SortColumn(customSort, setCustomSort, 'lenderName'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'lenderName'),
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Fee',
            key: 'drawdownFee',
            render: (record) => utils.TransformDBCurrency(record?.drawdownFee),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Cancelled By',
            key: 'cancelledBy',
            dataIndex: 'cancelledBy',
            ...SortColumn(customSort, setCustomSort, 'cancelledBy'),
            ellipsis: true,
            width: 110,
        },
        {
            title: 'Cancelled Date',
            key: 'cancelledDate',
            render: (record) => dayjs(record?.cancelledDate).format('DD/MM/YYYY'),
            ...SortColumn(customSort, setCustomSort, 'cancelledDate'),
            ellipsis: true,
            width: 120,
        },
        {
            title: "Process Stage",
            key: "status",
            render: (record) => <Tag bordered={false} className={styles["RedTags"]}>{record?.status}</Tag>,
            ellipsis: true,
            width: 150
        }
    );

    return columns;
}

function generateProcessStage(record) {
    if (record?.ap_brokerFundedDate)
        return "Completed";
    else if (record?.ap_postFundingRequirementDate)
        return "Broker Funding";
    else if (record?.ap_vendorFundedDate)
        return "Post-Funding Requirements";
    else if (record?.ap_fundingRequestedDate)
        return "Funding Requested";
    else if (record?.ap_documentsReceivedDate)
        return "Signing/Auditing";
    else if (record?.ap_documentsRequestedDate)
        return "Documents Requested";
    else if (record?.ap_dealPrepDate)
        return "Deal Prep";
    return "";
}

const exportObj = {
    generateTableColumns: generateTableColumns,
    generateProcessStage: generateProcessStage,
}

export default exportObj;