import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import dayjs from "dayjs";

import NoApplicationIcon from "../../assets/images/icons/Home/NoApplicationsIcon";
import EmptyDataComponent from "../../components/EmptyDataComponent";

import ApiService from "../../api";
import utils from "../../utils"
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function HomeApplications() {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const limit = 3;
        setLoading(true);
        ApiService.fetchRecentAgentApplications(limit).then((data) => {
            setData(data || []);
            setLoading(false);
        });
    }, []);

    const RenderFunction = () => {
        if (data.length !== undefined && data.length === 0) {
            return (
                <>
                    <EmptyDataComponent flex={true}
                        className="centerApplications"
                        iconComponent={<NoApplicationIcon />}
                        ContentLine1={"There's nothing to show here..."}
                        ContentLine2={"Add an application to see content."}
                        ComponentLine3={
                            <div>
                                <Button
                                    type="primary"
                                    onClick={() => navigate("/applications/new")}
                                >+ Application</Button>
                            </div>
                        }
                    />
                </>
            )
        } else {
            return (
                <>
                    <div className={styles["AppCards"]}>
                        {data.map((application, index) => {
                            return (
                                <div key={index} className={styles["IndividualApp"]}>
                                    <div className={styles["IndividualApp_Content"]}>
                                        <div className={styles["IndividualApp_Business"]}>{application?.ap_clientId?.ap_businessName}</div>
                                        <div className={styles["Content"]}>
                                            <div><b>Date </b>{dayjs(application?.createdAt).format("DD-MM-YYYY")}</div>
                                            <div><b>Asset </b>{application?.ap_assetDescription}</div>
                                            <div><b>Amount </b>{utils.TransformDBCurrency(application?.ap_requestedAmount)}</div>
                                        </div>
                                    </div>
                                    <div className={styles["Center"]}>
                                        <Button
                                            type="primary"
                                            onClick={() => navigate(`/applications/${application?._id}`)}
                                        > See More Info</Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )
        }
    }

    return (
        <div className={styles["Applications"]}>
            <div className={`${styles["Header"]} Font_SemiBold`}>Recent Applications</div>
            <div className={styles["List"]}>
                <Spin spinning={loading} tip={"Loading Applications..."}>
                    <RenderFunction />
                </Spin>
            </div>

        </div >
    );
}