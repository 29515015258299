export default function FilterSelectedTimePreferences(timePreferences) {
    const selectedTimes = {};

    Object.entries(timePreferences).forEach(([day, { selected, startTime, endTime }]) => {
        if (selected) {
            selectedTimes[day] = { startTime, endTime };
        }
    });

    return selectedTimes;
}