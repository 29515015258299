import Icon from '@ant-design/icons';

const Unsaved = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V15C0 15.663 0.263392 16.2989 0.732233 16.7678C1.20107 17.2366 1.83696 17.5 2.5 17.5H8.25C8.04667 17.1033 7.88292 16.6867 7.75875 16.25H3.75V10.625C3.75 10.4592 3.81585 10.3003 3.93306 10.1831C4.05027 10.0658 4.20924 10 4.375 10H9.07125C9.4625 9.525 9.9175 9.105 10.4212 8.75H4.375C3.87772 8.75 3.40081 8.94754 3.04917 9.29917C2.69754 9.65081 2.5 10.1277 2.5 10.625V16.25C2.16848 16.25 1.85054 16.1183 1.61612 15.8839C1.3817 15.6495 1.25 15.3315 1.25 15V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H3.75V4.375C3.75 4.87228 3.94754 5.3492 4.29917 5.70083C4.6508 6.05246 5.12772 6.25 5.625 6.25H10.625C11.1223 6.25 11.5992 6.05246 11.9508 5.70083C12.3025 5.3492 12.5 4.87228 12.5 4.375V1.25H12.9737C13.3052 1.25007 13.6231 1.38181 13.8575 1.61625L15.8837 3.6425C16.1182 3.87687 16.2499 4.19476 16.25 4.52625V7.75875C16.6817 7.88131 17.1004 8.04585 17.5 8.25V4.52625C17.4999 3.86326 17.2364 3.22748 16.7675 2.75875L14.7413 0.7325C14.2725 0.263627 13.6367 0.000141594 12.9737 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233ZM5 4.375V1.25H11.25V4.375C11.25 4.54076 11.1842 4.69973 11.0669 4.81694C10.9497 4.93415 10.7908 5 10.625 5H5.625C5.45924 5 5.30027 4.93415 5.18306 4.81694C5.06585 4.69973 5 4.54076 5 4.375Z" fill="#FF5555" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.75 14.375C8.75 15.8668 9.34263 17.2976 10.3975 18.3525C11.4524 19.4074 12.8832 20 14.375 20C15.8668 20 17.2976 19.4074 18.3525 18.3525C19.4074 17.2976 20 15.8668 20 14.375C20 12.8832 19.4074 11.4524 18.3525 10.3975C17.2976 9.34263 15.8668 8.75 14.375 8.75C12.8832 8.75 11.4524 9.34263 10.3975 10.3975C9.34263 11.4524 8.75 12.8832 8.75 14.375ZM14.3627 15.4901C14.2132 15.4901 14.0698 15.4307 13.9641 15.325C13.8584 15.2193 13.799 15.0759 13.799 14.9264V11.5437C13.799 11.3942 13.8584 11.2508 13.9641 11.1451C14.0698 11.0394 14.2132 10.98 14.3627 10.98C14.5123 10.98 14.6557 11.0394 14.7614 11.1451C14.8671 11.2508 14.9265 11.3942 14.9265 11.5437V14.9264C14.9265 15.0759 14.8671 15.2193 14.7614 15.325C14.6557 15.4307 14.5123 15.4901 14.3627 15.4901ZM13.7648 16.3016C13.6062 16.4602 13.5171 16.6753 13.5171 16.8996C13.5171 17.1238 13.6062 17.3389 13.7648 17.4975C13.9234 17.6561 14.1385 17.7452 14.3627 17.7452C14.587 17.7452 14.8021 17.6561 14.9607 17.4975C15.1193 17.3389 15.2084 17.1238 15.2084 16.8996C15.2084 16.6753 15.1193 16.4602 14.9607 16.3016C14.8021 16.143 14.587 16.0539 14.3627 16.0539C14.1385 16.0539 13.9234 16.143 13.7648 16.3016Z" fill="#FF5555" />
        </svg>

    );
};

export default function UnsavedIcon(props) {
    return <Icon component={Unsaved} {...props} />
}