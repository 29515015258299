export default function ProcessStageProgress(stage) {

    if (!stage)
        return 0;

    const totalStages = 7;
    const processStages = {
        'Deal Prep': 1,
        'Documents Requested': 2,
        'Signing/Auditing': 3,
        'Funding Requested': 4,
        'Post-Funding Requirements': 5,
        'Broker Funding': 6,
        'Completed': 7,
        'Funded': 7,
    }
    const progress = (processStages[stage] / totalStages) * 100;

    if (progress)
        return Math.floor(progress);
    return 0;
}