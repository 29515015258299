import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button } from "antd";
import { CheckCircleFilled, SaveOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import constants from "../../constants";
import utils from "../../utils";
import validate from "../../utils/Validations";
import HasError from "../../utils/HasErrors";

import InputLayout from "../InputLayout";
import generateClientProfileFields from "./constants";
import dayjs from "dayjs";
import styles from "./index.module.css";

import EditClients from "../EditClients";
import EmailIconOutlined from "../../assets/images/icons/EmailIconOutlined";
import TelephoneIconOutlined from "../../assets/images/icons/TelephoneIconOutlined";
import LocationIconOutlined from "../../assets/images/icons/LocationIconOutlined";
import FormatTelephone from '../../utils/Validations/FormatTelephone';
import IndustryTypeIcon from "../../assets/images/icons/IndustryTypeIcon";
import CompanyTypeIcon from "../../assets/images/icons/CompanyTypeIcon";
import DateOfIncorporationIcon from "../../assets/images/icons/DateOfIncorporationIcon";
import FiledFsYearEndIcon from "../../assets/images/icons/FiledFsYearEndIcon";

export default function ClientProfile({ setRefresh, clientId, data, setLoading }) {

    const navigate = useNavigate();
    // State Variables
    const [formData, setFormData] = useState(generateClientProfileFields(data));
    const [layout, setLayout] = useState('horizontal');
    const [profileLoading, setProfileLoading] = useState(false);
    const [edited, setEdited] = useState(false);

    const cityProvince = (data?.ap_city && data?.ap_stateOrProvince) ?
        data?.ap_city + ", " + data?.ap_stateOrProvince :
        (data?.ap_city || data?.ap_stateOrProvince);

    // Event Handlers
    const handleBusinessName = (e) => {
        setFormData({
            ...formData,
            ap_businessName: {
                ...formData.ap_businessName,
                value: e.target.value,
                error: formData.ap_businessName.required ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            },
        });
    }

    const handleIndustry = (industry) => {
        setFormData({
            ...formData,
            ap_industry: {
                ...formData.ap_industry,
                value: industry,
                error: validate.ValidateRequiredField(industry).errorMessage,
            }
        });
    }

    const handleType = (type) => {
        setFormData({
            ...formData,
            ap_type: {
                ...formData.ap_type,
                value: type,
                error: formData.ap_type.required ? validate.ValidateRequiredField(type).errorMessage : '',
            }
        });
    }

    const handleCompanyType = (companyType) => {
        setFormData({
            ...formData,
            ap_companyType: {
                ...formData.ap_companyType,
                value: companyType,
                error: formData.ap_companyType.required ? validate.ValidateRequiredField(companyType).errorMessage : '',
            }
        });
    }

    const handleEmail = (e) => {
        setFormData({
            ...formData,
            ap_email: {
                ...formData.ap_email,
                value: e.target.value,
                error: !!validate.ValidateRequiredField(e.target.value).errorMessage ? validate.ValidateRequiredField(e.target.value).errorMessage : (!validate.EmailValidation(e.target.value) ? 'Invalid Email Address' : ''),
            },
        });
    }

    const handleTelephone = (e) => {
        setFormData({
            ...formData,
            ap_telephone: {
                ...formData.ap_telephone,
                value: validate.FormatTelephone(e.target.value).input,
                error: validate.FormatTelephone(e.target.value).error,
            }
        });
    }

    const handleAddress = (e) => {
        setFormData({
            ...formData,
            ap_address: {
                ...formData.ap_address,
                value: e.target.value,
                error: '',
            }
        });
    }

    const handleCity = (e) => {
        setFormData({
            ...formData,
            ap_city: {
                ...formData.ap_city,
                value: e.target.value,
                error: '',
            }
        });
    }

    const handleProvinceStates = (provinceStates) => {
        setFormData({
            ...formData,
            ap_stateOrProvince: {
                ...formData.ap_stateOrProvince,
                value: provinceStates,
                error: formData.ap_stateOrProvince.required ? validate.ValidateRequiredField(provinceStates).errorMessage : '',
            }
        });
    }

    const enrichPostalCode = (country, postalCode) => {
        let func = validate.FormatCanadianPostalCode;
        if (country === 'Canada') {
            func = validate.FormatCanadianPostalCode
        } else {
            func = validate.FormatAmericanZipCode(postalCode).input;
        }

        if (func(postalCode).error === '') {
            return func(postalCode).input;
        } else {
            return postalCode;
        }
    }

    const handlePostalCode = (e) => {
        setFormData({
            ...formData,
            ap_postalCode: {
                ...formData.ap_postalCode,
                value: enrichPostalCode(formData.ap_country.value, e.target.value),
                error: ((formData.ap_country.value !== 'USA') ? validate.FormatCanadianPostalCode(e.target.value).error : validate.FormatAmericanZipCode(e.target.value).error),
            }
        });
    }

    const handleCountry = (country) => {
        setFormData({
            ...formData,
            ap_country: {
                ...formData.ap_country,
                value: country,
                error: formData.ap_country.required ? validate.ValidateRequiredField(country).errorMessage : '',
            },
            ap_stateOrProvince: {
                ...formData.ap_stateOrProvince,
                dropdownContent: country === 'Canada' ? constants.CANADIAN_PROVINCES : constants.AMERICAN_STATES,
            }
        });
    }

    const handleSave = () => {
        setProfileLoading(true);
        setLoading(true);
        if (!HasError(formData)) {
            const clientData = {
                ap_businessName: formData.ap_businessName.value,
                ap_industry: formData.ap_industry.value,
                ap_type: formData.ap_type.value,
                ap_companyType: formData.ap_companyType.value,
                ap_email: formData.ap_email.value,
                ap_telephone: utils.TransformTelephone(formData.ap_telephone.value),
                ap_address: formData.ap_address.value,
                ap_city: formData.ap_city.value,
                ap_stateOrProvince: formData.ap_stateOrProvince.value,
                ap_postalCode: formData.ap_postalCode.value,
                ap_country: formData.ap_country.value,
                ap_lastModifiedBy: "beacon",
            }
            if (formData.ap_country.value === 'Canada') {
                clientData.ap_province = formData.ap_stateOrProvince.value;
            } else {
                clientData.ap_state = formData.ap_stateOrProvince.value;
            }

            // Update Client Profile
            ApiService.updateClient(clientId, clientData).then((resp) => {
                if (!resp.error) {
                    setRefresh(prevRefresh => prevRefresh + 1);
                    NotificationService.success('Success', 'Client Profile Updated')
                } else {
                    NotificationService.error('Error', resp.error)
                }
            });
        } else {
            NotificationService.error('Error', 'Please resolve the errors in the form')
        }
        setLoading(false);
        setProfileLoading(false);
    }

    const handleDelete = () => {
        ApiService.updateClient(clientId, { removeClient: true }).then((resp) => {
            if (!resp.error) {
                // fetch client contacts
                ApiService.fetchClientContacts(clientId).then((resp) => {
                    if (resp.length > 0) {
                        resp.forEach((contact) => {
                            ApiService.updateContact(contact._id, { removeContact: true }).then((resp) => {
                                if (!resp.error) {
                                    NotificationService.success('Success', 'Client Contact Deleted');
                                } else {
                                    NotificationService.error('Error', resp.error)
                                }
                            });
                        });
                    }
                });
                navigate('/clients');
                // setRefresh(prevRefresh => prevRefresh + 1);
                NotificationService.success('Success', 'Client Deleted');
            } else {
                NotificationService.error('Error', resp.error)
            }
        });
    }

    // Check if its a Mobile Screen
    const isMobile = useMediaQuery({ query: constants.BREAKPOINTS.xs });
    useEffect(() => {
        setLayout(isMobile ? 'vertical' : 'horizontal')
    }, [isMobile]);

    // Add Event Handlers to Form 
    const clientProfileForm = {
        ...formData,
        ap_businessName: {
            ...formData.ap_businessName,
            eventHandler: handleBusinessName,
        },
        ap_industry: {
            ...formData.ap_industry,
            eventHandler: handleIndustry,
        },
        ap_type: {
            ...formData.ap_type,
            eventHandler: handleType,
        },
        ap_companyType: {
            ...formData.ap_companyType,
            eventHandler: handleCompanyType,
        },
        ap_email: {
            ...formData.ap_email,
            eventHandler: handleEmail,
        },
        ap_telephone: {
            ...formData.ap_telephone,
            eventHandler: handleTelephone,
        },
        ap_address: {
            ...formData.ap_address,
            eventHandler: handleAddress,
        },
        ap_city: {
            ...formData.ap_city,
            eventHandler: handleCity,
        },
        ap_stateOrProvince: {
            ...formData.ap_stateOrProvince,
            eventHandler: handleProvinceStates,
        },
        ap_postalCode: {
            ...formData.ap_postalCode,
            eventHandler: handlePostalCode,
        },
        ap_country: {
            ...formData.ap_country,
            eventHandler: handleCountry,
        },
    }

    return (
        <>
            <div>
                {data.ap_multiAgent ?
                    <>
                        {edited ? <div className={styles["AutoFill_AlertContainer"]}>
                            <CheckCircleFilled className={styles["CheckCircle"]} />
                            <div className={styles["Edited_Text"]}>
                                Thank you! We will notify you once we've reviewed your submission.
                            </div>
                        </div> : <></>}
                        <div className={styles["clientGrid"]}>
                            <div className={styles["clientGrid_Info"]}>
                                <span className={styles["clientGrid_InfoName"]}>{data?.ap_businessName}</span>
                            </div>


                            <div className={styles["clientGridButtons"]}>
                                <EditClients
                                    clientId={clientId}
                                    setRefresh={setRefresh}
                                    setLoading={setLoading}
                                    setEdited={setEdited}
                                />
                                <Button
                                    type='primary'
                                    className={styles["clientButtons"]}
                                    onClick={handleDelete}
                                >Delete</Button>
                            </div>

                        </div>

                        <div className={styles["clientInfo"]}>
                            <div className={styles["clientInfo_Details"]}>
                                <EmailIconOutlined />
                                <a href={`mailto:${data?.ap_email}`}>{data?.ap_email}</a>
                            </div>

                            <div className={styles["clientInfo_Details"]}>
                                <TelephoneIconOutlined />
                                <a href={`tel:${data?.ap_telephone}`}>{FormatTelephone(String(data?.ap_telephone)).input}</a>
                            </div>

                            <div className={styles["clientInfo_Loc"]}>
                                <LocationIconOutlined />
                                <div>
                                    {data?.ap_address} {data?.ap_address ? <br /> : <></>}
                                    {cityProvince} {cityProvince ? <br /> : <></>}
                                    {data?.ap_postalCode} {data?.ap_postalCode ? <br /> : <></>}
                                    {data?.ap_country}
                                </div>
                            </div>

                            <div className={styles["clientGrid1"]}>
                                <div className={styles["clientInfo_Details1"]}>
                                    <span className={styles["clientGrid_InfoName1"]}><IndustryTypeIcon />{data?.ap_industry}</span>
                                    <span className={styles["clientGrid_InfoName1"]}><CompanyTypeIcon />{data?.ap_companyType}</span>
                                </div>
                                <div className={styles["clientInfo_Details1"]}>
                                    <span className={styles["clientGrid_InfoName1"]}><DateOfIncorporationIcon />{dayjs(data?.ap_dateOfIncorporation).format('DD-MM-YYYY')}</span>
                                    <span className={styles["clientGrid_InfoName1"]}><FiledFsYearEndIcon />{dayjs(data?.ap_filedFsYearEnd).format('DD-MM-YYYY')}</span>
                                </div>
                            </div>
                        </div>

                    </> :
                    <>
                        <InputLayout
                            data={clientProfileForm}
                            layout={layout}
                        />
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={handleSave}
                            className={styles["SaveButton"]}
                            disabled={profileLoading}
                        >Save</Button>
                    </>}

            </div>
        </>
    );
}