import constants from "../../constants";
import RequestAssetUtils from "./utils";

const makeType = (assetType) => {
    if (assetType === "Dump Truck" || assetType === "Trucks" || assetType === "Reefers" || assetType === "Trailers")
        return 'dropdown';
    return 'text';
}

const makeDropdownContent = (assetType) => {
    if (assetType === 'Dump Truck' || assetType === 'Trucks')
        return constants?.TRUCK_BRANDS;
    else if (assetType === 'Reefers' || assetType === 'Trailers')
        return constants?.TRAILER_BRANDS;
    return [];
}

const RequestAssetFormFields = (data, setData) => {
    const invokeFieldValidation = data?.invokeValidation || false;

    return {
        assetType: {
            value: data?.assetType || '',
            error: (invokeFieldValidation && !data?.assetType) ? 'Required' : '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Asset Type',
            dropdownContent: constants?.LISTING_EQUIPMENT_TYPES?.filter(item => item?.label && item?.value),
            eventHandler: (value) => RequestAssetUtils.handleAssetType(value, setData),
        },
        make: {
            value: data?.make || '',
            error: (invokeFieldValidation && data?.assetType === 'Other' && !data?.make) ? 'Required' : '',
            hidden: false,
            required: (data?.assetType === 'Other'),
            inputType: makeType(data?.assetType),
            dropdownContent: makeDropdownContent(data?.assetType),
            label: 'Make',
            placeholder: 'e.g. Ford',
            eventHandler: (e) => RequestAssetUtils.handleMake(e, setData, makeType(data?.assetType)),
        },
        model: {
            value: data?.model || '',
            error: (invokeFieldValidation && data?.make === 'Other' && !data?.model) ? 'Required' : '',
            hidden: false,
            required: (data?.make === 'Other'),
            inputType: 'text',
            label: 'Model',
            placeholder: 'e.g. F-150',
            eventHandler: (e) => RequestAssetUtils.handleModel(e?.target?.value, setData),
        },
    }
};

const RequestAssetAdditionalDetails = (data, setData) => {
    return {
        additionalDetails: {
            value: data?.additionalDetails || '',
            error: '',
            required: false,
            inputType: 'textarea',
            label: 'Additional Details',
            placeholder: 'Please provide any additional details that may help us find the asset you are looking for.',
            eventHandler: (e) => setData(prevData => ({ ...prevData, additionalDetails: e?.target?.value })),
        }
    }
}

const RequestAssetConstants = {
    RequestAssetFormFields,
    RequestAssetAdditionalDetails,
}

export default RequestAssetConstants;