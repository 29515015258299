import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Dark = () => {
    const { token: { themeFontColor }, } = theme.useToken();
    return (
        <svg width="18" height="18" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.65208 0.761288C5.78448 0.89344 5.87183 1.06399 5.90168 1.24867C5.93152 1.43335 5.90235 1.62273 5.81832 1.78987C5.19256 3.03409 4.97495 4.44391 5.19643 5.8189C5.41791 7.1939 6.0672 8.46409 7.052 9.44888C8.0368 10.4337 9.30699 11.083 10.682 11.3045C12.057 11.5259 13.4668 11.3083 14.711 10.6826C14.8781 10.5984 15.0674 10.5691 15.2521 10.5988C15.4368 10.6284 15.6074 10.7156 15.7396 10.8479C15.8719 10.9801 15.9591 11.1508 15.9888 11.3354C16.0184 11.5201 15.9891 11.7095 15.905 11.8765C15.3073 13.0638 14.4364 14.0923 13.3639 14.8775C12.2913 15.6627 11.0478 16.1822 9.73547 16.3934C8.42311 16.6045 7.07939 16.5012 5.81472 16.092C4.55005 15.6828 3.40053 14.9793 2.46062 14.0394C1.52071 13.0995 0.817249 11.95 0.408031 10.6853C-0.00118721 9.42061 -0.104477 8.07689 0.10665 6.76453C0.317778 5.45218 0.837295 4.20865 1.62251 3.13614C2.40773 2.06362 3.43623 1.19274 4.6235 0.595044C4.79074 0.510801 4.9803 0.481518 5.16517 0.511371C5.35003 0.541223 5.52074 0.628687 5.65297 0.761288H5.65208ZM3.39667 3.76345C2.3026 5.02937 1.7282 6.66246 1.78891 8.33454C1.84963 10.0066 2.54095 11.5938 3.72396 12.777C4.90696 13.9602 6.49397 14.6519 8.16604 14.7129C9.83811 14.7739 11.4713 14.1998 12.7374 13.106C11.4741 13.2577 10.1928 13.1204 8.99026 12.7045C7.78772 12.2886 6.69536 11.605 5.79563 10.7053C4.89589 9.80553 4.21229 8.71317 3.7964 7.51063C3.38052 6.3081 3.24323 5.0268 3.3949 3.76345H3.39667Z" fill={themeFontColor} />
        </svg>
    );
}

export default function DarkIcon(props) {
    return <Icon component={Dark} {...props} />
}