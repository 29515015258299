import { theme } from 'antd';
import Icon from '@ant-design/icons';

const ThemeSample = ({ colors }) => {
  const { themePrimaryColor, themeSecondaryColor, themeDangerColor, themeFontColor, themeDisabledContainer, themeDisabledText } = colors;
  const {
    token: { ap_userPrimaryColor, ap_userHighlightColor },
  } = theme.useToken();
  return (
    <svg width="228" height="239" viewBox="0 0 228 239" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9057_848)">
        <rect x="0.5" y="0.874023" width="227" height="238" rx="10" fill={themeSecondaryColor} />
        <rect x="0.5" y="0.874023" width="227" height="23.8779" fill={themePrimaryColor} />
        <rect x="0.5" y="228.392" width="227" height="10.4819" fill={ap_userPrimaryColor} />
        <rect x="46.301" y="40.8428" width="113.111" height="56.9634" rx="5" fill={themePrimaryColor} />
        <rect x="163.412" y="40.8428" width="59.6214" height="56.9634" rx="5" fill={themePrimaryColor} />
        <rect x="46.301" y="101.806" width="100.064" height="41.2837" rx="5" fill={themePrimaryColor} />
        <rect x="150.365" y="101.806" width="72.3459" height="41.2837" rx="5" fill={themePrimaryColor} />
        <rect x="153.5" y="115.129" width="9.22388" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="165.724" y="115.129" width="7.20117" height="2.84375" rx="1.42188" fill={ap_userPrimaryColor} />
        <rect x="175.925" y="115.129" width="43.575" height="2.84375" rx="1.42188" fill={themeDisabledContainer} />
        <rect x="153.5" y="125.208" width="9.22388" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="165.724" y="125.208" width="7.20117" height="2.84375" rx="1.42188" fill={ap_userPrimaryColor} />
        <rect x="175.925" y="125.208" width="43.575" height="2.84375" rx="1.42188" fill={themeDisabledContainer} />
        <rect x="153.5" y="135.286" width="9.22388" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="165.724" y="135.286" width="7.20117" height="2.84375" rx="1.42188" fill={ap_userPrimaryColor} />
        <rect x="175.925" y="135.286" width="43.575" height="2.84375" rx="1.42188" fill={themeDisabledContainer} />
        <rect x="122.647" y="147.09" width="100.064" height="41.2837" rx="5" fill={themePrimaryColor} />
        <rect x="122.5" y="199.129" width="31.3333" height="23" rx="5" fill={themePrimaryColor} />
        <rect x="156.833" y="199.129" width="31.3333" height="23" rx="5" fill={themePrimaryColor} />
        <rect x="191.167" y="199.129" width="31.3333" height="23" rx="5" fill={themePrimaryColor} />
        <rect x="46.301" y="147.09" width="72.7817" height="75.0396" rx="5" fill={themePrimaryColor} />
        <rect x="41.0911" y="0.874023" width="238" height="40.5911" transform="rotate(90 41.0911 0.874023)" fill={themePrimaryColor} />
        <path
          d="M27.2552 23.5295C27.2552 27.0539 24.4708 29.7922 21.0761 29.7922C19.0004 29.7922 17.4372 28.8873 16.4603 27.435V29.4829H14.3357V14.8854C14.3357 13.741 15.2872 12.814 16.4603 12.814V19.6247C17.4372 18.1724 19.0004 17.2675 21.0761 17.2675C24.4708 17.2675 27.2552 20.0057 27.2552 23.5302V23.5295ZM25.1306 23.5295C25.1306 21.1005 23.2496 19.2672 20.8078 19.2672C18.3661 19.2672 16.4603 21.1005 16.4603 23.5295C16.4603 25.9585 18.3406 27.7918 20.8078 27.7918C23.275 27.7918 25.1306 25.9585 25.1306 23.5295Z"
          fill={ap_userPrimaryColor}
        />
        <path d="M27.22 14.8844V12.813H16.4598V14.8844H27.22Z" fill="#8ECEAE" />
        <rect x="4.5127" y="40.8428" width="32.5654" height="4.74658" rx="2.37329" fill={ap_userHighlightColor} />
        <rect x="150.365" y="8.54541" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="51.6853" y="51.8853" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="51.6853" y="66.8496" width="8.53442" height="8.53442" rx="4.26721" fill="#EDCB00" />
        <rect x="51.6853" y="81.814" width="8.53442" height="8.53442" rx="4.26721" fill="#8ECEAE" />
        <rect x="166.254" y="55.6494" width="1.80396" height="1.80396" rx="0.901978" fill={ap_userHighlightColor} />
        <rect x="166.254" y="61.4932" width="1.80396" height="1.80396" rx="0.901978" fill={ap_userPrimaryColor} />
        <rect x="166.254" y="67.3369" width="1.80396" height="1.80396" rx="0.901978" fill="#8ECEAE" />
        <rect x="166.254" y="73.1807" width="1.80396" height="1.80396" rx="0.901978" fill="#EDCB00" />
        <rect x="166.254" y="79.0244" width="1.80396" height="1.80396" rx="0.901978" fill="#4ECBD7" />
        <rect x="166.254" y="84.8682" width="1.80396" height="1.80396" rx="0.901978" fill="#6FACB2" />
        <rect x="164.955" y="8.54565" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="179.546" y="8.54565" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="194.136" y="8.54541" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="199.5" y="8.54541" width="4.72485" height="4.72485" rx="2.36243" fill={themeDangerColor} />
        <rect x="208.726" y="8.54565" width="8.53442" height="8.53442" rx="4.26721" fill={ap_userPrimaryColor} />
        <rect x="46.301" y="30.0508" width="64.375" height="5.49316" rx="2.74658" fill={themeDisabledContainer} />
        <rect x="176.717" y="31.1011" width="45.9941" height="3.39209" rx="1.69604" fill={themeDisabledContainer} />
        <rect x="122.647" y="191.929" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="127.335" y="150.522" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="153.833" y="105.276" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="166.254" y="43.7671" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="169.426" y="55.1294" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="169.426" y="60.9731" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="169.426" y="66.8169" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="169.426" y="72.6606" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="169.426" y="78.5044" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="169.426" y="84.3481" width="15.1714" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="50.4897" y="115.61" width="92" height="2.84375" rx="1.42188" fill={themeDisabledContainer} />
        <rect x="50.4897" y="122.329" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="123.901" y="122.329" width="18.5886" height="2.84375" rx="1.42188" fill={themeDangerColor} />
        <rect x="50.4897" y="129.047" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="123.901" y="129.047" width="18.5886" height="2.84375" rx="1.42188" fill="#8ECEAE" />
        <rect x="50.4897" y="135.766" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="123.901" y="135.766" width="18.5886" height="2.84375" rx="1.42188" fill="#8ECEAE" />
        <rect x="127.49" y="159.61" width="92" height="2.84375" rx="1.42188" fill={themeDisabledContainer} />
        <rect x="127.49" y="166.329" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="200.901" y="166.329" width="18.5886" height="2.84375" rx="1.42188" fill="#8ECEAE" />
        <rect x="127.49" y="173.047" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="200.901" y="173.047" width="18.5886" height="2.84375" rx="1.42188" fill="#8ECEAE" />
        <rect x="127.49" y="179.766" width="70.4114" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="200.901" y="179.766" width="18.5886" height="2.84375" rx="1.42188" fill="#8ECEAE" />
        <rect x="50.5" y="158.129" width="3.89062" height="2.84375" rx="1.42188" fill={ap_userHighlightColor} />
        <rect x="57.3906" y="158.129" width="46.4294" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="106.82" y="158.129" width="2.84" height="2.84375" rx="1.42" fill={ap_userPrimaryColor} />
        <rect x="112.66" y="158.129" width="2.84" height="2.84375" rx="1.42" fill={themeDangerColor} />
        <rect x="50.5" y="164.848" width="3.89062" height="2.84375" rx="1.42188" fill={ap_userHighlightColor} />
        <rect x="57.3906" y="164.848" width="46.4294" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="106.82" y="164.848" width="2.84" height="2.84375" rx="1.42" fill={ap_userPrimaryColor} />
        <rect x="112.66" y="164.848" width="2.84" height="2.84375" rx="1.42" fill={themeDangerColor} />
        <rect x="50.5" y="171.567" width="3.89062" height="2.84375" rx="1.42188" fill={ap_userHighlightColor} />
        <rect x="57.3906" y="171.567" width="46.4294" height="2.84375" rx="1.42188" fill={themeSecondaryColor} />
        <rect x="106.82" y="171.567" width="2.84" height="2.84375" rx="1.42" fill={ap_userPrimaryColor} />
        <rect x="112.66" y="171.567" width="2.84" height="2.84375" rx="1.42" fill={themeDangerColor} />
        <rect x="50.5" y="178.286" width="2.84" height="2.84375" rx="1.42" fill={ap_userPrimaryColor} />
        <rect x="56.3401" y="178.286" width="21.842" height="2.84375" rx="1.42188" fill={ap_userPrimaryColor} />
        <rect x="84.6519" y="43.7671" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="50.2107" y="105.276" width="31.1863" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="50.2107" y="150.522" width="20.0181" height="3.64502" rx="1.82251" fill={themeDisabledContainer} />
        <rect x="4.5127" y="49.5115" width="32.5654" height="4.74658" rx="2.37329" fill={themeSecondaryColor} />
        <rect x="4.5127" y="58.1807" width="32.5654" height="4.74658" rx="2.37329" fill={themeSecondaryColor} />
        <rect x="4.5127" y="66.8496" width="32.5654" height="4.74658" rx="2.37329" fill={themeSecondaryColor} />
        <rect x="4.5127" y="75.5186" width="32.5654" height="4.74658" rx="2.37329" fill={themeSecondaryColor} />
        <rect x="4.5127" y="84.1875" width="32.5654" height="4.74658" rx="2.37329" fill={themeSecondaryColor} />
        <circle cx="204.224" cy="69.3245" r="16.1443" fill={ap_userPrimaryColor} />
        <path d="M220.34 70.2857C220.131 73.7836 218.79 77.1186 216.519 79.7872C214.249 82.4558 211.171 84.3132 207.752 85.0787L204.224 69.3245L220.34 70.2857Z" fill="#4ECBD7" />
        <path d="M193.784 57.0102C196.199 54.9627 199.163 53.6705 202.307 53.2945C205.451 52.9184 208.636 53.475 211.465 54.8951L204.224 69.3245L193.784 57.0102Z" fill={ap_userHighlightColor} />
        <path
          d="M213.014 82.866C210.583 84.4443 207.771 85.3388 204.875 85.4556C201.978 85.5724 199.104 84.9072 196.553 83.5298C194.003 82.1524 191.87 80.1136 190.379 77.6275C188.888 75.1414 188.094 72.2998 188.08 69.401L204.224 69.3245L213.014 82.866Z"
          fill="#8ECEAE"
        />
        <path d="M188.162 67.7049C188.591 63.4448 190.695 59.5297 194.011 56.8211L204.224 69.3245L188.162 67.7049Z" fill="#6FACB2" />
        <path d="M189.564 76.0856C188.676 74.1604 188.176 72.0792 188.093 69.9607C188.009 67.8423 188.344 65.7281 189.077 63.7389L204.224 69.3245L189.564 76.0856Z" fill="#EDCB00" />
        <circle cx="204.324" cy="69.4071" r="10.8807" fill={themePrimaryColor} />
        <rect x="129.397" y="202.629" width="17.1208" height="1.8418" rx="0.920898" fill={themeDisabledContainer} />
        <rect x="129.397" y="206.148" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect x="129.397" y="209.668" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect width="17.1208" height="5.44238" rx="2.72119" transform="matrix(1 0 0 -1 129.397 218.629)" fill={ap_userPrimaryColor} />
        <rect x="163.939" y="202.589" width="17.1208" height="1.8418" rx="0.920898" fill={themeDisabledContainer} />
        <rect x="163.939" y="206.108" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect x="163.939" y="209.627" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect width="17.1208" height="5.44238" rx="2.72119" transform="matrix(1 0 0 -1 163.939 218.589)" fill={ap_userPrimaryColor} />
        <rect x="198.481" y="202.548" width="17.1208" height="1.8418" rx="0.920898" fill={themeDisabledContainer} />
        <rect x="198.481" y="206.067" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect x="198.481" y="209.587" width="17.1208" height="1.8418" rx="0.920898" fill={themeSecondaryColor} />
        <rect width="17.1208" height="5.44238" rx="2.72119" transform="matrix(1 0 0 -1 198.481 218.548)" fill={ap_userPrimaryColor} />
        <path
          d="M76.4458 62.7322C76.4458 62.7322 80.5565 58.7042 86.3816 58.7042C91.8142 58.7042 101.75 70.4579 108.755 71.2503C112.708 71.6974 115.975 66.7601 121.55 66.7601C127.077 66.7601 130.628 71.7786 134.846 71.7786C139.063 71.7786 145.925 56.6572 145.925 56.6572"
          stroke={ap_userPrimaryColor}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M76.3027 72.9672C76.3027 72.9672 78.6675 64.9773 83.6926 64.9773C88.7177 64.9773 89.5793 76.1367 96.6249 76.1367C100.689 76.1367 102.98 73.0992 106.084 73.0992C110.37 73.0992 113.769 77.6554 118.721 77.6554C122.933 77.6554 129.149 66.0277 134.239 66.2979C141.703 66.6941 146.137 79.9005 146.137 79.9005"
          stroke="#EDCB00"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M76.374 74.8164C76.374 74.8164 79.63 84.2207 85.738 84.391C88.357 84.4641 91.528 82.8723 94.6017 82.8723C99.8824 82.8723 101.75 86.6361 105.395 86.6361C110.256 86.6361 112.043 80.3631 117.333 80.3631C120.907 80.3631 127.082 83.7157 133.63 84.391C138.485 84.8918 146.139 84.391 146.139 84.391"
          stroke="#8ECEAE"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
      <rect x="1" y="1.37402" width="226" height="237" rx="9.5" stroke={themeDisabledContainer} />
      <defs>
        <clipPath id="clip0_9057_848">
          <rect x="0.5" y="0.874023" width="227" height="238" rx="10" fill={themePrimaryColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default function ThemeSampleIcon(colors) {
  return <Icon component={() => ThemeSample(colors)} />;
}
