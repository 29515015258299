import { theme } from 'antd';
import Icon from '@ant-design/icons';

const BOS = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M49.5794 43.6282C50.705 43.6282 51.626 44.5491 51.626 45.6747V47.8876C52.7132 48.0411 53.762 48.2841 54.7086 48.5399C55.7958 48.8341 56.4482 49.9469 56.154 51.047C55.8598 52.147 54.747 52.7865 53.6469 52.4923C52.2271 52.1086 50.8329 51.8272 49.541 51.8144C48.4666 51.8016 47.3154 52.0446 46.5223 52.5179C45.7933 52.9528 45.4863 53.4517 45.4863 54.1552C45.4863 54.6284 45.6526 54.9866 46.42 55.4471C47.3026 55.9715 48.5433 56.3552 50.155 56.8413L50.2189 56.8541C50.2238 56.8555 50.2286 56.857 50.2334 56.8584C51.6762 57.2925 53.4597 57.8292 54.8621 58.7215C56.4098 59.6937 57.7273 61.2414 57.7656 63.6077C57.804 66.0764 56.5377 67.8671 54.8365 68.9288C53.8516 69.5427 52.7388 69.9009 51.626 70.0927V72.28C51.626 73.4056 50.705 74.3266 49.5794 74.3266C48.4538 74.3266 47.5328 73.4056 47.5328 72.28V70.0032C46.1002 69.7346 44.7572 69.2741 43.5804 68.8648L43.5789 68.8643C43.3108 68.7749 43.0427 68.6856 42.7874 68.5962C41.7129 68.238 41.1373 67.0741 41.4955 66.0124C41.8536 64.9508 43.0176 64.3624 44.0793 64.7205C44.3989 64.8228 44.6934 64.9252 44.9874 65.0275C45.0238 65.0398 45.06 65.0521 45.096 65.0643C46.7858 65.636 48.1653 66.1028 49.6306 66.1403C50.7945 66.1787 51.9201 65.9229 52.662 65.4624C53.3144 65.0531 53.6725 64.5287 53.6597 63.6717C53.6469 63.0833 53.4295 62.674 52.6748 62.1879C51.805 61.6379 50.5643 61.2414 48.9654 60.7553L48.7608 60.6914C47.3538 60.2693 45.6526 59.7576 44.3095 58.939C42.7746 58.018 41.4187 56.5215 41.4059 54.168C41.3932 51.6865 42.7874 49.9725 44.4502 48.9876C45.4095 48.4248 46.4712 48.0666 47.5328 47.8748V45.6747C47.5328 44.5491 48.4538 43.6282 49.5794 43.6282Z" fill="black" />
            <path d="M49.5794 43.6282C50.705 43.6282 51.626 44.5491 51.626 45.6747V47.8876C52.7132 48.0411 53.762 48.2841 54.7086 48.5399C55.7958 48.8341 56.4482 49.9469 56.154 51.047C55.8598 52.147 54.747 52.7865 53.6469 52.4923C52.2271 52.1086 50.8329 51.8272 49.541 51.8144C48.4666 51.8016 47.3154 52.0446 46.5223 52.5179C45.7933 52.9528 45.4863 53.4517 45.4863 54.1552C45.4863 54.6284 45.6526 54.9866 46.42 55.4471C47.3026 55.9715 48.5433 56.3552 50.155 56.8413L50.2189 56.8541L50.2334 56.8584C51.6762 57.2925 53.4597 57.8292 54.8621 58.7215C56.4098 59.6937 57.7273 61.2414 57.7656 63.6077C57.804 66.0764 56.5377 67.8671 54.8365 68.9288C53.8516 69.5427 52.7388 69.9009 51.626 70.0927V72.28C51.626 73.4056 50.705 74.3266 49.5794 74.3266C48.4538 74.3266 47.5328 73.4056 47.5328 72.28V70.0032C46.1002 69.7346 44.7572 69.2741 43.5804 68.8648L43.5789 68.8643C43.3108 68.7749 43.0427 68.6856 42.7874 68.5962C41.7129 68.238 41.1373 67.0741 41.4955 66.0124C41.8536 64.9508 43.0176 64.3624 44.0793 64.7205C44.3989 64.8228 44.6934 64.9252 44.9874 65.0275L45.096 65.0643C46.7858 65.636 48.1653 66.1028 49.6306 66.1403C50.7945 66.1787 51.9201 65.9229 52.662 65.4624C53.3144 65.0531 53.6725 64.5287 53.6597 63.6717C53.6469 63.0833 53.4295 62.674 52.6748 62.1879C51.805 61.6379 50.5643 61.2414 48.9654 60.7553L48.7608 60.6914C47.3538 60.2693 45.6526 59.7576 44.3095 58.939C42.7746 58.018 41.4187 56.5215 41.4059 54.168C41.3932 51.6865 42.7874 49.9725 44.4502 48.9876C45.4095 48.4248 46.4712 48.0666 47.5328 47.8748V45.6747C47.5328 44.5491 48.4538 43.6282 49.5794 43.6282Z" fill="white" />
            <path d="M33.1873 28.3265H45.1873" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M33.1873 35.3265H45.1873" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M21 20.3C21 18.3639 21.7691 16.5071 23.1381 15.1381C24.5071 13.7691 26.3639 13 28.3 13H57.5C58.468 13.0002 59.3962 13.3849 60.0805 14.0695L78.3306 32.3195C79.0151 33.0038 79.3998 33.932 79.4 34.9V78.7C79.4 80.6361 78.6309 82.4929 77.2619 83.8619C75.8929 85.2309 74.0361 86 72.1 86H28.3C26.3639 86 24.5071 85.2309 23.1381 83.8619C21.7691 82.4929 21 80.6361 21 78.7V20.3ZM70.5889 34.9L57.5 21.8111V34.9H70.5889ZM50.2 20.3H28.3V78.7H72.1V42.2H53.85C52.882 42.2 51.9536 41.8154 51.2691 41.1309C50.5846 40.4464 50.2 39.518 50.2 38.55V20.3Z" fill="white" />
        </svg>

    );
};

export default function BOSIcon(props) {
    return <Icon component={BOS} {...props} />
}