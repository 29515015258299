import utils from "../../utils";
import FilterColumn from "../../components/FilterColumn";
import TagComponent from "../../components/TagComponent";
import SortColumn from "../../components/SortColumn";

export default {
    generateAssetTableColumns: generateAssetTableColumns,
    generatePpsData: generatePpsData,
    generatePpsDraftData: generatePpsDraftData,
    generateModifyPaymentScheduleData: generateModifyPaymentScheduleData,
    generateDraftPpsAssetData: generateDraftPpsAssetData,
    generateNewPpsRequestData: generateNewPpsRequestData
}

// Generate Asset Table Columns
function generateAssetTableColumns(tableData, setFilters, customSort, setCustomSort) {

    const notApplicableTypes = ["Other", "Amount (PAL)", "Amount (Working Capital)"];

    return [
        {
            title: 'Type',
            dataIndex: 'ap_type',
            key: 'ap_type',
            ellipsis: { showTitle: false },
            ...FilterColumn(tableData, setFilters, 'ap_type'),
            ...SortColumn(customSort, setCustomSort, 'ap_type'),
        },
        {
            title: 'Make',
            dataIndex: 'ap_make',
            key: 'ap_make',
            ellipsis: { showTitle: false },
            ...FilterColumn(tableData, setFilters, 'ap_make'),
            ...SortColumn(customSort, setCustomSort, 'ap_make'),
        },
        {
            title: 'Model',
            dataIndex: 'ap_model',
            key: 'ap_model',
        },
        {
            title: 'Purpose',
            dataIndex: 'ap_purpose',
            key: 'ap_purpose',
            ellipsis: { showTitle: false },
            ...SortColumn(customSort, setCustomSort, 'ap_purpose'),
        },
        {
            title: 'Year',
            key: 'ap_year',
            render: (record) => (!notApplicableTypes.includes(record.ap_type)) ? record.ap_year : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'VIN/Serial',
            key: 'ap_vinSerial',
            render: (record) => (record.ap_vin || record.ap_serialNum)
        },
        {
            title: 'KMs',
            key: 'ap_kms',
            render: (record) => (!notApplicableTypes.includes(record.ap_type)) ? record.ap_kms : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Price',
            key: 'ap_price',
            render: (record) => utils.parseDBCurrency(record.ap_price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        },
        {
            title: 'Vendor',
            key: 'ap_vendorId',
            render: (record) => (record?.ap_name)
        },
        {
            title: 'Plating Province',
            key: 'ap_platingprovince',
            render: (record) => (!notApplicableTypes.includes(record.ap_type)) ? record.ap_platingprovince : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Plating Address',
            key: 'ap_platingaddress',
            render: (record) => (!notApplicableTypes.includes(record.ap_type)) ? record.ap_platingaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Yard Address',
            key: 'ap_yardaddress',
            render: (record) => (!notApplicableTypes.includes(record.ap_type)) ? record.ap_yardaddress : <TagComponent _id={record._id} active={false} message={"N/A"} />
        }
    ];
}

function generateModifyPaymentScheduleData() {
    return ({
        term: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Term',
            suffix: 'months',
        },
        downPayment: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Down Payment',
            prefix: '$',
        },
        commission: {
            value: '',
            error: '',
            required: true,
            inputType: 'slider',
            label: 'Commission',
            prefix: '$',
        }
    });
}

function generatePpsData(existingPPSData, totalPrice) {
    const ppsData = {
        "lockedIn": "commissionAmount",
        "generateHTML": false,
        "commissionType": existingPPSData.ap_commissionType,
        "maxCommissionPercent": Number(existingPPSData.ap_maxCommissionPercent),
        "maximumYield": existingPPSData.ap_maximumYield || 0,
        "maxCommissionAmount": existingPPSData.ap_maxCommissionAmount / 100,
        "commissionAmount": existingPPSData.ap_actualCommission / 100,
        "downPaymentType": existingPPSData.ap_downPayment,
        "downPaymentAmount": existingPPSData.ap_downPaymentAmount / 100,
        "bonusDownPaymentAmount": existingPPSData.ap_bonusDownPaymentAmount / 100 || 0,
        "baseAmount": totalPrice,
        "docFeeToClient": existingPPSData.ap_totalDocFeeToClient / 100 || 0,
        "otherFees": existingPPSData.ap_otherFees / 100 || 0,
        "annualCostOfFunds": Number(existingPPSData.ap_costOfFunds),
        "annualClientRate": Number(existingPPSData.ap_clientRate) || 0,
        "residualPurchaseOption": existingPPSData.ap_purchaseOption / 100 || 0,
        "initiationDate": existingPPSData.ap_initiationDate,
        "downPaymentDate": existingPPSData.ap_downPaymentDate,
        "firstRegularPaymentDate": existingPPSData.ap_firstRegularPaymentDate,
        "daysInYear": Number(existingPPSData.ap_daysInYear),
        "term": Number(existingPPSData.ap_term),
        "regularPaymentAmount": 0
    };

    if (existingPPSData.ap_specialPayments) {
        ppsData["specialPayments"] = {};
        if (existingPPSData.ap_taxBalloon) {
            let taxBallon = {};
            taxBallon["taxAmount"] = totalPrice - totalPrice / (1 + 13 / 100); // Adjust tax amount based on new base amount
            taxBallon["taxBalloonPaymentNumber"] = Number(existingPPSData.ap_taxBalloonAmountNumber);
            ppsData.specialPayments["taxBallon"] = taxBallon;
        }

        if (existingPPSData.ap_acceleratedPayments) {
            let acceleratedPayments = {};
            acceleratedPayments["acceleratedMonthlyBonus"] = existingPPSData.ap_acceleratedMonthlyBonus / 100;
            acceleratedPayments["acceleratedTerm"] = existingPPSData.ap_acceleratedTerm;
            ppsData.specialPayments["acceleratedPayments"] = acceleratedPayments;
        } if (existingPPSData.ap_skipPayments) {
            let fixedPayments = {};
            fixedPayments["paymentNumbers"] = existingPPSData.ap_skipPaymentPaymentNumbers.split(',').map(item => Number(item));
            fixedPayments["fixedAmount"] = existingPPSData.ap_skipAmount / 100;
            ppsData.specialPayments["fixedPayments"] = fixedPayments;
        } else if (existingPPSData.ap_interestOnlyPayment) {
            let interestOnlyPayments = {};
            interestOnlyPayments["paymentNumbers"] = existingPPSData.ap_interestOnlyPaymentNumbers.split(',').map(item => Number(item));
            ppsData.specialPayments["interestOnlyPayments"] = interestOnlyPayments;
        }
    }
    return ppsData;
}

function generateDraftPpsAssetData(paymentScheduleDraftId, assetId, applicationId) {
    return {
        "ap_applicationId": applicationId,
        "ap_paymentScheduleId": paymentScheduleDraftId,
        "ap_assetId": assetId,
        "ap_pushedToCrm": false,
        "ap_lastModifiedBy": "beacon",
        "ap_status": "Active"
    }
}

function generatePpsDraftData(existingPPSData, paymentScheduleBalancedData, selectedAssets) {

    const ppsDraftData = {
        "ap_assetIds": selectedAssets,
        "ap_expectedLockedIn": "Commission",
        "ap_isDraft": true,
        "ap_emailType": "new",
        "ap_scheduleRequested": true,
        "ap_draftReferredFrom": existingPPSData._id,
        "ap_approvalId": existingPPSData.ap_approvalId,
        "ap_financingType": existingPPSData.ap_financingType,
        "ap_assetDescription": existingPPSData.ap_assetDescription,
        "ap_commissionType": paymentScheduleBalancedData.commissionType,
        "ap_maxCommissionPercent": existingPPSData.ap_maxCommissionPercent,
        "ap_maxCommissionAmount": paymentScheduleBalancedData.maxCommissionAmount * 100,
        "ap_actualCommission": paymentScheduleBalancedData.commissionAmount * 100,
        "ap_baseAmount": paymentScheduleBalancedData.baseAmount * 100,
        "ap_term": paymentScheduleBalancedData.term + 1,
        "ap_termExclDown": existingPPSData.ap_termExclDown,
        "ap_costOfFunds": existingPPSData.ap_costOfFunds,
        "ap_monthlyCostOfFunds": existingPPSData.ap_monthlyCostOfFunds,
        "ap_dailyCostOfFunds": existingPPSData.ap_dailyCostOfFunds,
        "ap_clientRate": paymentScheduleBalancedData.annualClientRate,
        "ap_monthlyClientRate": paymentScheduleBalancedData.monthlyClientRate,
        "ap_dailyClientRate": paymentScheduleBalancedData.dailyClientRate,
        "ap_totalDocFeeToClient": existingPPSData.ap_totalDocFeeToClient,
        "ap_otherFees": existingPPSData.ap_otherFees,
        "ap_downPayment": paymentScheduleBalancedData.downPaymentType,
        "ap_downPaymentAmount": paymentScheduleBalancedData.downPaymentAmount * 100,
        "ap_regularMonthlyPayment": paymentScheduleBalancedData.regularPaymentAmount * 100,
        "ap_initiationDate": existingPPSData.ap_initiationDate,
        "ap_downPaymentDate": existingPPSData.ap_downPaymentDate,
        "ap_firstRegularPaymentDate": existingPPSData.ap_firstRegularPaymentDate,
        "ap_scheduleEndDate": existingPPSData.ap_scheduleEndDate,
        "ap_daysInYear": existingPPSData.ap_daysInYear,
        "ap_specialPayments": existingPPSData.ap_specialPayments
    }

    if (existingPPSData?.ap_specialPayments) {
        if (existingPPSData?.ap_taxBalloon) {
            ppsDraftData["ap_taxBalloon"] = existingPPSData.ap_taxBalloon;
            ppsDraftData["ap_taxRatePercent"] = existingPPSData.ap_taxRatePercent;
            ppsDraftData["ap_taxAmount"] = paymentScheduleBalancedData?.specialPayments?.taxBallon?.taxAmount * 100;
            ppsDraftData["ap_taxBalloonAmountNumber"] = existingPPSData.ap_taxBalloonAmountNumber;
            ppsDraftData["ap_taxBalloonMonthYear"] = paymentScheduleBalancedData.taxBalloonMonthYear;
        }
        if (existingPPSData?.ap_acceleratedPayments) {
            ppsDraftData["ap_acceleratedPayments"] = existingPPSData.ap_acceleratedPayments;
            ppsDraftData["ap_acceleratedAmountPercent"] = existingPPSData.ap_acceleratedAmountPercent;
            ppsDraftData["ap_acceleratedAmount"] = existingPPSData.ap_acceleratedAmount;
            ppsDraftData["ap_acceleratedMonthlyBonus"] = existingPPSData.ap_acceleratedMonthlyBonus;
            ppsDraftData["ap_acceleratedTerm"] = existingPPSData.ap_acceleratedTerm;
        }
        if (existingPPSData.ap_skipPayments) {
            ppsDraftData["ap_skipPayments"] = existingPPSData.ap_skipPayments;
            ppsDraftData["ap_skipMonths"] = existingPPSData.ap_skipMonths;
            ppsDraftData["ap_skipAmount"] = existingPPSData.ap_skipAmount;
            ppsDraftData["ap_skipFullTerm"] = existingPPSData.ap_skipFullTerm;
            ppsDraftData["ap_skipPaymentStartDate"] = existingPPSData.ap_skipPaymentStartDate;
            ppsDraftData["ap_skipPaymentEndDate"] = existingPPSData.ap_skipPaymentEndDate;
            ppsDraftData["ap_skipPaymentPaymentNumbers"] = paymentScheduleBalancedData?.specialPayments?.fixedPayments?.paymentNumbers?.join();
        } else if (existingPPSData.ap_interestOnlyPayment) {
            ppsDraftData["ap_interestOnlyPayment"] = existingPPSData.ap_interestOnlyPayment;
            ppsDraftData["ap_interestOnlyMonths"] = existingPPSData.ap_interestOnlyMonths;
            ppsDraftData["ap_interestOnlyFullTerm"] = existingPPSData.ap_interestOnlyFullTerm;
            ppsDraftData["ap_interestOnlyStartDate"] = existingPPSData.ap_interestOnlyStartDate;
            ppsDraftData["ap_interestOnlyEndDate"] = existingPPSData.ap_interestOnlyEndDate;
            ppsDraftData["ap_interestOnlyPaymentNumbers"] = paymentScheduleBalancedData?.specialPayments?.interestOnlyPayments?.paymentNumbers?.join();
        }
    }
    return ppsDraftData;
}

function generateNewPpsRequestData(paymentScheduleDraftId, ppsAssetIds, approvalId) {
    return {
        "ap_draftPaymentScheduleId": paymentScheduleDraftId,
        "ap_approvalId": approvalId,
        "ap_ppsAssetIds": ppsAssetIds
    }
}
