import { useEffect, useState } from "react";
import { theme } from "antd";

import ApiService from "../../api";
import ProgressBar from "../ProgressBar";
import CloseIcon from "../../assets/images/icons/CloseIcon";
import FileTypeIcons from "../../assets/images/icons/FileTypeIcons";
import utils from "../../utils";

import styles from "./index.module.css";

export default function UploadedFileList({ fileList, setFileList }) {

    const { token: { themeFontColor } } = theme.useToken();

    const [updatedList, setUpdatedList] = useState([]);

    useEffect(() => {
        if (!fileList) return;

        // Fetch file details only for files that have been fully uploaded
        const uploadedFiles = fileList.filter(file => !file.uploading);
        const pendingUploads = fileList.filter(file => file.uploading);
        ApiService.fetchFileDetails(uploadedFiles).then(response => {
            setUpdatedList([...response, ...pendingUploads]);
        });
 
    }, [fileList]);

    const handleDelete = (fileUrl) => {
        setFileList(fileList.filter(item => item?.url !== (fileUrl?.url ? fileUrl?.url : fileUrl?.fileUrl)));
    }
    // TODo: for large files, the progress bar is visible after few seconds because the updatedList gets updated everytime the url is set.

    return (
        <>
            {(updatedList?.length > 0) && updatedList.map((file, index) => {
                const isUploading = file?.uploading;
 
                return (
                    <div key={file?.name} className={styles["listContainer"]}>
                        <div className={styles["listContainerGrid"]}>
                            {/* Icon */}
                            <div className={styles["listIcon"]}>
                                {FileTypeIcons((file?.name || file?.fileName)?.split('.')[1], themeFontColor)}
                            </div>
                            <div>
                                <div className={styles["listDetails"]}>
                                    <div style={{ textAlign: 'left' }}>
                                        {/* Display the link if the file has a URL */}
                                        {file?.fileUrl ? (
                                            <a
                                                href={utils.FormBlobFileUrl(file?.fileUrl)}
                                                className={styles["fileName"]}
                                            >{file?.fileName}</a>
                                        ) : (
                                            <span className={styles["fileName"]}>{file?.name}</span>
                                        )}
                                    </div>
                                    <div
                                        style={{ textAlign: 'right' }}
                                        onClick={() => handleDelete(file)}
                                    >
                                        <CloseIcon />
                                    </div>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <ProgressBar
                                        size={Number(file?.fileSize?.split(' ')[0])}
                                        measure={file?.fileSize?.split(' ')[1]}
                                        updated={!isUploading}
                                        uploading={!!isUploading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}