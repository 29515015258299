import utils from "../../../utils"

export default function generateDetails(notificationData) {

    const amendedDetails = [
        // Approved Amount Comparision
        {
            hidden: notificationData.approvedAmount === notificationData.approvedAmountSaved,
            data: {
                new: {
                    label: "New Approved Amount",
                    value: utils.TransformDBCurrency(notificationData.approvedAmount),
                },
                old: {
                    label: "Previous Approved Amount",
                    value: utils.TransformDBCurrency(notificationData.approvedAmountSaved),
                },
            }
        },
        // Term Length Comparision
        {
            hidden: notificationData.term === notificationData.termSaved,
            data: {
                new: {
                    label: "New Term Length",
                    value: `${notificationData.term || 0} months`,
                },
                old: {
                    label: "Previous Term Length",
                    value: `${notificationData.termSaved || 0} months`,
                },
            }
        },
        // Cost of funds Comparision
        // {
        //     hidden: notificationData.costOfFunds === notificationData.costOfFundsSaved,
        //     data: {
        //         new: {
        //             label: "New Cost of Funds",
        //             value: `${notificationData.costOfFunds || 0} %`,
        //         },
        //         old: {
        //             label: "Previous Cost of Funds",
        //             value: `${notificationData.costOfFundsSaved || 0} %`,
        //         },
        //     }
        // },
        // Down Payment Comparision
        {
            hidden: (notificationData.downPayment === notificationData.downPaymentSaved) || (notificationData.downPaymentAmount === notificationData.downPaymentAmountSaved),
            data: {
                new: {
                    label: "New Down Payment",
                    value: (notificationData.downPayment === 'Other') ? utils.TransformDBCurrency(notificationData.downPaymentAmount) : notificationData.downPayment,
                },
                old: {
                    label: "Previous Down Payment",
                    value: (notificationData.downPaymentSaved === 'Other') ? utils.TransformDBCurrency(notificationData.downPaymentAmountSaved) : notificationData.downPaymentSaved,
                },
            }
        },
        // Pre-Funding Requirements Comparision
        {
            hidden: notificationData.preFundingRequirements === notificationData.preFundingRequirementsSaved,
            data: {
                new: {
                    label: "New Pre-Funding Requirements",
                    value: notificationData.preFundingRequirements || "-",
                },
                old: {
                    label: "Previous Pre-Funding Requirements",
                    value: notificationData.preFundingRequirementsSaved || "-",
                },
            }
        },
        // Approval Notes Comparision
        {
            hidden: notificationData.approvalNotes === notificationData.approvalNotesSaved,
            data: {
                new: {
                    label: "New Submmission Notes",
                    value: notificationData.approvalNotes,
                },
                old: {
                    label: "Previous Submission Notes",
                    value: notificationData.approvalNotesSaved,
                },
            }
        },
    ];

    const pgs = [notificationData.personalGuarantor1, notificationData.personalGuarantor2, notificationData.personalGuarantor3, notificationData.personalGuarantor4].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const corpCoApp = [notificationData.corpCoApp1, notificationData.corpCoApp2, notificationData.corpCoApp3].filter((item) => (item !== undefined && item !== '' && item !== null)).join(', ');

    const submissionDetails = {
        title: "Submission Details",
        data: [
            {
                label: "Submission Date",
                value: utils.TransformNotificationDate(notificationData.submittedDate)
            },
            {
                label: "Submitted Amount",
                value: utils.TransformDBCurrency(notificationData.submittedAmount)
            },
            {
                label: "Asset Description",
                value: notificationData.assetDescription
            },
            {
                label: "Submission Type",
                value: notificationData.submissionType
            },
            {
                label: "Lender",
                value: notificationData.lenderName
            },
            {
                label: "Credit",
                value: notificationData.creditAnalyst
            },
            {
                label: "Personal Guarantor(s)",
                value: pgs,
                hidden: !pgs
            },
            {
                label: "Corporate Co-Applicant(s)",
                value: corpCoApp,
                hidden: !corpCoApp
            }
        ]
    }

    const approvalDetails = {
        title: "Approval Details",
        data: [
            {
                label: "Approval Date",
                value: utils.TransformNotificationDate(notificationData.approvalDate)
            },
            {
                label: "Approved Amount",
                value: utils.TransformDBCurrency(notificationData.approvedAmount)
            },
            // {
            //     label: "Rate",
            //     value: `${notificationData.costOfFunds} %`,
            //     hidden: notificationData.submissionType === 'PAL'
            // },
            {
                label: "Term",
                value: `${notificationData.term} months`,
                hidden: notificationData.submissionType === 'PAL'
            },
            {
                label: "Down Payment",
                value: (notificationData.downPayment !== 'Other') ? notificationData.downPayment : utils.TransformDBCurrency(notificationData.downPaymentAmount),
                hidden: notificationData.submissionType === 'PAL'
            },
            {
                label: "Expiry Date",
                value: utils.TransformNotificationDate(notificationData.expiryDate),
                hidden: !notificationData.expiryDate
            },
        ]
    }

    const preFundingRequirements = {
        title: "Pre-Funding Requirements",
        data: [
            {
                label: "",
                value: <ul>
                    <li>{notificationData.preFundingRequirements}</li>
                </ul>
            }
        ],
        hidden: !notificationData.preFundingRequirements
    }

    const approvalNotes = {
        title: "Approval Notes",
        data: [
            {
                label: "",
                value: <ul>
                    <li>{notificationData.approvalNotes}</li>
                </ul>
            }
        ]
    }

    return {
        amendedDetails: amendedDetails,
        submissionDetails: submissionDetails,
        approvalDetails: approvalDetails,
        preFundingRequirements: preFundingRequirements,
        approvalNotes: approvalNotes
    }
}