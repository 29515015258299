import { ConfigProvider, Form, Input, Slider, theme } from "antd";

export default function SliderComponent({ data, item, layout }) {
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor, themeSecondaryColor }, } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    activeBorderColor: ap_userPrimaryColor,
                    hoverBorderColor: ap_userPrimaryColor,
                    colorBgContainer: themePrimaryColor,
                    colorBgContainerDisabled: themePrimaryColor,
                    colorText: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                   
                },
                components: {
                    Slider: {
                        railBg: themeSecondaryColor,
                        railHoverBg: themeSecondaryColor,
                        trackBg: ap_userPrimaryColor,
                        trackHoverBg: ap_userPrimaryColor,
                    }
                }
            }}
        >
            {data.hidden ? null : (
                <div>
                    <Form.Item
                        key={item}
                        label={data.label}
                        required={data.required}
                    >
                        <Slider
                            onChange={(value) => data.eventHandler(value)}
                            value={data.value}
                            min={data.min}
                            max={data.max}
                            marks={data.marks}
                        />

                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                            <Input
                                onChange={(e) => data.eventHandler(e)}
                                value={data.value}
                                status={!!data.error ? 'error' : ''}
                                suffix={data.suffix}
                                prefix={data.prefix}
                                style={{ minWidth: '100px', width: '30%' }}
                            />
                        </div>
                        {!!data.error && <div style={{ color: 'red', fontSize: 'smaller' }}>{data.error}</div>}
                    </Form.Item>
                </div>
            )}
        </ConfigProvider>
    );
}