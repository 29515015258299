import { useEffect, useState } from "react";

import { Button, Drawer, Spin } from "antd";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import InputLayout from "../InputLayout";

import utils from "../../utils";
import HasError from "../../utils/HasErrors";
import validate from "../../utils/Validations";

import EditIcon from "../../assets/images/icons/EditIcon";
import generateContactFields from "./constants";

import styles from "./index.module.css";

export default function EditContacts({ entity, entityId, contactId, refresh, setRefresh, iconButton }) {

    const [loader, setLoader] = useState({ loading: false, message: '' })

    const [openContact, setOpenContact] = useState(false);
    const handleDrawer = () => {
        setOpenContact(!openContact);
    }
    const [contactData, setContactData] = useState({});
    useEffect(() => {
        if (contactId) {
            setLoader({ loading: true, message: 'Loading Contact...' });
            ApiService.fetchIndividualContact(contactId).then((data) => {
                setContactData(generateContactFields(entity, data));
                setLoader({ loading: false, message: '' });
            });
        }
    }, [contactId, refresh, entity]);

    // Event Handlers
    const handleFirstName = (e) => {
        setContactData({
            ...contactData,
            ap_firstName: {
                ...contactData.ap_firstName,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        })
    }

    const handleLastName = (e) => {
        setContactData({
            ...contactData,
            ap_lastName: {
                ...contactData.ap_lastName,
                value: e.target.value,
                error: validate.RequiredField(e.target.value),
            },
        })
    }

    const handleEmail = (e) => {
        setContactData({
            ...contactData,
            ap_email: {
                ...contactData.ap_email,
                value: e.target.value,
                error: (validate.RequiredField(e.target.value) ? validate.RequiredField(e.target.value) : (!validate.EmailValidation(e.target.value) ? 'Invalid Email Address' : '')),
            },
        })
    }

    const handleTelephone = (e) => {
        setContactData({
            ...contactData,
            ap_telephone: {
                ...contactData.ap_telephone,
                value: validate.FormatTelephone(e.target.value).input,
                error: validate.FormatTelephone(e.target.value).error,
            },
        })
    }

    const handleAddress = (e) => {
        setContactData({
            ...contactData,
            ap_address: {
                ...contactData.ap_address,
                value: e.target.value,
                error: '',
            },
        })
    }

    const handleCity = (e) => {
        setContactData({
            ...contactData,
            ap_city: {
                ...contactData.ap_city,
                value: e.target.value,
                error: '',
            },
        })
    }

    const handleStateOrProvince = (value) => {
        setContactData({
            ...contactData,
            ap_stateOrProvince: {
                ...contactData.ap_stateOrProvince,
                value: value,
                error: '',
            },
        })
    }

    const handlePostalZipCode = (e) => {
        setContactData({
            ...contactData,
            ap_postalZipCode: {
                ...contactData.ap_postalZipCode,
                value: (contactData.ap_country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).input : validate.FormatCanadianPostalCode(e.target.value).input,
                error: (contactData.ap_country.value === 'USA') ? validate.FormatAmericanZipCode(e.target.value).error : validate.FormatCanadianPostalCode(e.target.value).error,
            },
        })
    }

    const handleCountry = (value) => {
        setContactData({
            ...contactData,
            ap_country: {
                ...contactData.ap_country,
                value: value,
                error: '',
            },
            ap_stateOrProvince: {
                ...contactData.ap_stateOrProvince,
                value: '',
                error: '',
            },
            ap_postalZipCode: {
                ...contactData.ap_postalZipCode,
                value: '',
                error: '',
            },
        })
    }

    // const handleDrivingExperience = (e) => {
    //     setContactData({
    //         ...contactData,
    //         ap_drivingExperienceYears: {
    //             ...contactData.ap_drivingExperienceYears,
    //             value: e.target.value,
    //         }
    //     })
    // }

    // const handleEverBankrupt = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_everBankrupt: {
    //             ...contactData.ap_everBankrupt,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleEverRepossessed = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_everRepossessed: {
    //             ...contactData.ap_everRepossessed,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleDefendantInLegalAction = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_defendantInLegalAction: {
    //             ...contactData.ap_defendantInLegalAction,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleHomeOwner = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_homeowner: {
    //             ...contactData.ap_homeowner,
    //             value: checked,
    //         }
    //     });
    // }

    // const handleResidentForTwoYear = (checked) => {
    //     setContactData({
    //         ...contactData,
    //         ap_residentAtleast2Years: {
    //             ...contactData.ap_residentAtleast2Years,
    //             value: checked,
    //         }
    //     });
    // }

    const handleSave = () => {
        if (!HasError(contactData)) {
            setLoader({ loading: true, message: 'Updating Contact...' });
            const data = {
                ap_firstName: contactData.ap_firstName.value,
                ap_lastName: contactData.ap_lastName.value,
                ap_fullName: contactData.ap_firstName.value + " " + contactData.ap_lastName.value,
                ap_email: contactData.ap_email.value,
                ap_telephone: utils.TransformTelephone(contactData.ap_telephone.value),
                ap_address: contactData.ap_address.value,
                ap_city: contactData.ap_city.value,
                ap_stateOrProvince: contactData.ap_stateOrProvince.value,
                ap_province: (contactData.ap_country.value === 'USA') ? '' : contactData.ap_stateOrProvince.value,
                ap_state: (contactData.ap_country.value === 'USA') ? contactData.ap_stateOrProvince.value : '',
                ap_postalZipCode: contactData.ap_postalZipCode.value,
                ap_country: contactData.ap_country.value,
                ...(entity === 'agent' && { ap_agentId: entityId }),
                ...(entity === 'client' && { ap_clientId: entityId }),
            }

            ApiService.updateContact(contactId, data).then((response) => {
                if (response.success) {
                    setRefresh(prevRefresh => prevRefresh + 1);
                    NotificationService.success("Success", "Contact Updated Successfully!!")
                    handleDrawer();
                } else {
                    NotificationService.error("Error", response.error);
                }
            })
            setLoader({ loading: false, message: '' });
        }
    }

    const formFields = {
        ap_firstName: {
            ...contactData.ap_firstName,
            eventHandler: handleFirstName,
        },
        ap_lastName: {
            ...contactData.ap_lastName,
            eventHandler: handleLastName,
        },
        ap_email: {
            ...contactData.ap_email,
            eventHandler: handleEmail,
        },
        ap_telephone: {
            ...contactData.ap_telephone,
            eventHandler: handleTelephone,
        },
        ap_address: {
            ...contactData.ap_address,
            eventHandler: handleAddress,
        },
        ap_city: {
            ...contactData.ap_city,
            eventHandler: handleCity,
        },
        ap_stateOrProvince: {
            ...contactData.ap_stateOrProvince,
            eventHandler: handleStateOrProvince,
        },
        ap_postalZipCode: {
            ...contactData.ap_postalZipCode,
            eventHandler: handlePostalZipCode,
        },
        ap_country: {
            ...contactData.ap_country,
            eventHandler: handleCountry,
        }
    }

    return (
        <>
            <div onClick={handleDrawer}>
                {iconButton ?
                    <EditIcon /> :
                    <Button
                        type="primary"
                        style={{ width: '100px', minWidth: 'fit-content' }}
                        className={styles["editButton"]}
                    >Edit</Button>
                }
            </div>

            <Drawer
                width="550px"
                title="Edit Contact"
                open={openContact}
                onClose={handleDrawer}
                getContainer=".AppTheme"
            >
                <Spin spinning={loader.loading} tip={loader.message}>
                    <InputLayout
                        layout="vertical"
                        data={formFields}
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            onClick={handleSave}
                        >Save</Button>
                    </div>
                </Spin>
            </Drawer>
        </>
    );

}