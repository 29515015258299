import { useEffect, useState } from 'react';
import { Avatar, ConfigProvider, Layout, Popover, Spin, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// Importing various icons
import HeaderSalesIcon from '../../../assets/images/icons/HeaderSalesIcon';
import PhoneIcon from '../../../assets/images/icons/PhoneIcon';
import FormatTelephone from '../../../utils/Validations/FormatTelephone';
import HeaderMail from '../../../assets/images/icons/HeaderMail';
import LC_Logo from '../../../assets/images/LC.svg';

// Importing authentication and API services
import AuthService from '../../../services/auth.service';
import ApiService from '../../../api';

// Importing CSS styles
import styles from './index.module.css';
import '../../../assets/styles/fonts.css';

export default function HeaderSalesProfile() {
  // Fetching theme tokens for header and footer background color
  const {
    token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor },
  } = theme.useToken();

  const themeColorVariables = {
    '--userPrimaryColor': ap_userPrimaryColor,
    '--themePrimaryColor': themePrimaryColor,
    '--themeFontColor': themeFontColor,
    'boxShadow': '0px 1px 10px 0px rgba(68,126,183, 0.25)',
    'width': '250px',
    'textAlign': 'center',
    'fontsize': '13px',
    'borderRadius': '10px'
  };

  // State variables for managing popover and data
  const [open, setOpen] = useState(false);
  const handleOpenChange = () => setOpen((newOpen) => !newOpen);
  const [salesData, setSalesData] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [agentId, setAgentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const countryCodeString = "+1";

  // Effect to handle user authentication and fetching agent data
  useEffect(() => {
    const isLoggedIn = AuthService.isLoggedIn();
    const authGuard = AuthService.authGuard();

    if (!authGuard || !isLoggedIn) {
      // Refreshing component if not authenticated
      setRefresh((prevRefresh) => prevRefresh + 1);
    } else {
      const currentUser = AuthService?.getCurrentUser();
      if (currentUser?.agent_id)
        // Setting agent ID if available
        setAgentId(currentUser?.agent_id);
      // Refreshing component if agent ID is not available
      else setRefresh((prevRefresh) => prevRefresh + 1);
    }
  }, [refresh]);

  // Effect to fetch agent and sales coordinator data
  useEffect(() => {
    if (!agentId) return;

    // Fetching agent profile data
    setLoading(true);
    ApiService.fetchAgentProfileData(agentId).then((agentResponse) => {
      setSalesData(agentResponse?.ap_salesCoordinatorId);
      setLoading(false);
    });
  }, [agentId, refresh]);

  // Content for the popover
  const content = (
    <div style={{ margin: "0px 5px 5px" }}>
      <Spin spinning={loading} tip={"Fetching your Coordinator..."}>
        <Layout style={themeColorVariables}>
          <div className={styles["SalesContent"]}>

            {/* Avatar section */}
            <div className={styles["ContentAvatar"]}>
              <Avatar
                icon={<UserOutlined />}
                src={salesData?.ap_profilePhotoLink || null}
                size={72}
                className={styles["AvatarBG"]}
              />
            </div>

            {/* Sales details section */}
            <div>

              {/* Salesperson name */}
              <div className={styles["SalesName"]}>
                {salesData?.ap_name}
              </div>

              {/* Salesperson position */}
              <div className={`${styles["SalesPosition"]} Font_UltraLight`}>
                {salesData?.ap_position}
              </div>

              {/* Phone number with icon */}
              <div className={styles["FlexCenterMargin"]}>
                <PhoneIcon />
                <a
                  href={`tel:${salesData?.ap_phoneNumber}`}
                  className={styles["MarginLeft"]}
                >
                  {FormatTelephone(String(salesData?.ap_phoneNumber)).input}
                </a>
              </div>

              {/* Email with icon */}
              <div className={styles["FlexCenterMargin"]}>
                <HeaderMail />
                <a
                  href={`mailto:${salesData?.ap_email}`}
                  className={styles["MarginLeft"]}
                >{salesData?.ap_email}</a>
              </div>

              {/* Company logo and link */}
              <div
                className={styles["FlexCenterMargin"]}
              >
                <img
                  src={LC_Logo}
                  className={styles["CompanyLogo"]}
                  alt="logo"
                />
                <a
                  href={"https://" + process.env.REACT_APP_ORG_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={styles["MarginLeft"]}>
                    {process.env.REACT_APP_ORG_URL}
                  </span>
                </a>
              </div>

            </div>

          </div>
        </Layout>

        <div style={themeColorVariables} className={styles["HeaderSales_AdditionalContent"]}>
          For technical support or to report a bug, please contact
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className={styles["MarginLeft"]}>
            <br />
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </a> or
          <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`} className={styles["MarginLeft"]}>
            <br />
            {FormatTelephone(String(process.env.REACT_APP_SUPPORT_PHONE), countryCodeString).input}
          </a>
        </div>
      </Spin>
    </div>
  )

  // Rendering the component with a popover
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: 'transparent',
          boxShadowSecondary: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <div id="HeaderSalesProfile">

        <Popover
          id="HeaderSalesProfile"
          title={null}
          content={content}
          arrow={false}
          rootClassName=".TestSalesPopover"
          placement="bottom"
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <div className={styles["SalesIcon"]}>
            {/* Displaying the sales icon */}
            <HeaderSalesIcon open={open} />
          </div>
        </Popover>
      </div>
    </ConfigProvider>
  );
}
