// Importing necessary modules
import { useState } from 'react';
import { Popover, theme } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

// Importing components and icons
import QuickQuoteCalculator from '../../QuickQuoteCalculator';
import HeaderCalculatorIcon from '../../../assets/images/icons/HeaderCalculatorIcon';

// Importing CSS Styles
import styles from './index.module.css';

// Define a functional component named HeaderQuickQuote
export default function HeaderQuickQuote() {
  // Extract the background color value from the theme token
  const {
    token: { themePrimaryColor, ap_userPrimaryColor },
  } = theme.useToken();

  // State variables to manage popover visibility and hover states
  const [open, setOpen] = useState(false);
  const handleOpenChange = () => setOpen((newOpen) => !newOpen);

  // Define the JSX element for the title of the Popover
  const title = (
    <div className={styles['TitleContainer']} style={{ background: themePrimaryColor }}>
      <div className={styles['CloseContainer']}>
        <CloseOutlined className={styles['CloseButton']} style={{ color: ap_userPrimaryColor }} onClick={handleOpenChange} />
      </div>
      <div className={styles['TitleHeader']} style={{ color: ap_userPrimaryColor }}>
        Quick Quote
      </div>
    </div>
  );

  // Define the content of the Popover as the QuickQuoteCalculator component
  const content = <QuickQuoteCalculator />;

  // Render the component
  return (
    <div id="headerQuickQuote">
      <Popover title={title} content={content} arrow={false} placement="bottom" trigger="click" open={open} onOpenChange={handleOpenChange}>
        <div className={styles['CalcIcon']}>
          <HeaderCalculatorIcon open={open} />
        </div>
      </Popover>
    </div>
  );
}
