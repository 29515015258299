import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Input, Form, Select, Button } from "antd";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import joinUsConstants from "./constants";
import constants from "../../constants";

import utils from "../../utils";
import validate from "../../utils/Validations";
import HasError from "../../utils/HasErrors";

import Captcha from '../../components/Captcha';
import JoinUsContactPreference from "../JoinUsContactPreference";

import styles from "./index.module.css";

export default function JoinUsForm({ setLoading, setRequestSent }) {

    const navigate = useNavigate();
    const captchaRef = useRef(null);

    const [invokeFieldValidation, setFieldValidation] = useState(false);
    const [inputFields, setInputFields] = useState({});
    const [selectfields, setSelectFields] = useState({});
    const [textAreaFields, setTextAreaFields] = useState({});
    const [businessNameField, setBusinessNameField] = useState({});
    const [timePreferences, setTimePreferences] = useState(joinUsConstants?.joinUsTimePreferences());
    const [contactPreferences, setContactPreferences] = useState(joinUsConstants?.joinUsContactPreferences());

    const joinUsTheme = {
        components: {
            Form: {
                labelColor: "#447EB7",
                labelFontSize: "16px",
                verticalLabelPadding: "0px",
            },
            Input: {
                activeBorderColor: "#447EB7",
                hoverBorderColor: "#447EB7",
            },
            Select: {
                optionSelectedBg: "#CFDCE9",
                optionActiveBg: "#447EB7",
            }
        },
        token: {
            fontSize: 16,
            colorBorder: "#CFDCE9",
            controlOutline: "#CFDCE9",
            colorPrimaryHover: "#CFDCE9",
            colorPrimary: "#447EB7",
        }
    }

    const handleFirstName = (e) => {
        setInputFields(prevInputFields => ({
            ...prevInputFields,
            firstName: {
                ...prevInputFields.firstName,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e.target.value) : '',
            },
        }));
    }

    const handleLastName = (e) => {
        setInputFields(prevInputFields => ({
            ...prevInputFields,
            lastName: {
                ...prevInputFields.lastName,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e.target.value) : '',
            },
        }));
    }

    const handlePhoneNumber = (e) => {
        setInputFields(prevInputFields => ({
            ...prevInputFields,
            phoneNumber: {
                ...prevInputFields.phoneNumber,
                value: validate.FormatTelephone(e.target.value).input,
                error: invokeFieldValidation ? validate.FormatTelephone(e.target.value).error : '',
            },
        }));
    }

    const handleEmail = (e) => {
        setInputFields(prevInputFields => ({
            ...prevInputFields,
            email: {
                ...prevInputFields.email,
                value: e.target.value,
                error: (invokeFieldValidation && !validate.EmailValidation(e.target.value)) ? 'Invalid Email Address' : '',
            },
        }));
    }

    const handleProvinceState = (provinceStateValue) => {
        setSelectFields(prevSelectFields => ({
            ...prevSelectFields,
            provinceState: {
                ...prevSelectFields.provinceState,
                value: provinceStateValue,
                error: (invokeFieldValidation && !provinceStateValue) ? validate.RequiredField(provinceStateValue) : '',
                // dropdownContent: (provinceStateValue === 'Canada') ? constants?.CANADIAN_PROVINCES : constants?.AMERICAN_STATES,
            },
        }));
    }

    const handleCountry = (countryValue) => {
        setSelectFields(prevSelectFields => ({
            ...prevSelectFields,
            provinceState: {
                ...prevSelectFields.provinceState,
                value: "",
                dropdownContent: (countryValue === 'Canada') ? constants?.CANADIAN_PROVINCES : constants?.AMERICAN_STATES,
            },
            country: {
                ...prevSelectFields.country,
                value: countryValue,
                error: (invokeFieldValidation && !countryValue) ? validate.RequiredField(countryValue) : '',
            },
        }));
    }

    const handleBusinessName = (e) => {
        setBusinessNameField(prevBusinessNameField => ({
            ...prevBusinessNameField,
            businessName: {
                ...prevBusinessNameField.businessName,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e?.target?.value) : '',
            },
        }));
    }

    const handleBusinessDetails = (e) => {
        setTextAreaFields(prevTextAreaFields => ({
            ...prevTextAreaFields,
            businessDetails: {
                ...prevTextAreaFields.businessDetails,
                value: e.target.value,
                error: invokeFieldValidation ? validate.RequiredField(e.target.value) : '',
            },
        }));
    }

    useEffect(() => {
        const inputFields = { ...joinUsConstants.joinUsInputFields() };
        setInputFields({
            ...inputFields,
            firstName: {
                ...inputFields.firstName,
                onChange: handleFirstName,
            },
            lastName: {
                ...inputFields.lastName,
                onChange: handleLastName,
            },
            phoneNumber: {
                ...inputFields.phoneNumber,
                onChange: handlePhoneNumber,
            },
            email: {
                ...inputFields.email,
                onChange: handleEmail,
            },
        });

        const selectFields = { ...joinUsConstants.joinUsSelectFields() };
        setSelectFields({
            ...selectFields,
            provinceState: {
                ...selectFields.provinceState,
                onChange: handleProvinceState,
            },
            country: {
                ...selectFields.country,
                onChange: handleCountry,
            },
        });

        const businessNameField = { ...joinUsConstants.joinUsBusinessNameFields() };
        setBusinessNameField({
            ...businessNameField,
            businessName: {
                ...businessNameField.businessName,
                onChange: handleBusinessName,
            },
        });

        const textAreaFields = { ...joinUsConstants.joinUsTextAreaFields() };
        setTextAreaFields({
            ...textAreaFields,
            businessDetails: {
                ...textAreaFields.businessDetails,
                onChange: handleBusinessDetails,
            },
        });

    }, []);

    const handleSendRequest = async () => {
        setLoading(true);
        setFieldValidation(true);
        const tempInputData = {
            ...inputFields,
            firstName: {
                ...inputFields?.firstName,
                error: validate.RequiredField(inputFields?.firstName?.value),
            },
            lastName: {
                ...inputFields?.lastName,
                error: validate.RequiredField(inputFields?.lastName?.value),
            },
            phoneNumber: {
                ...inputFields?.phoneNumber,
                error: validate.FormatTelephone(inputFields?.phoneNumber?.value).error,
            },
            email: {
                ...inputFields?.email,
                error: validate.EmailValidation(inputFields?.email?.value) ? '' : 'Invalid Email Address',
            },
        }

        const tempSelectData = {
            ...selectfields,
            provinceState: {
                ...selectfields?.provinceState,
                error: validate.RequiredField(selectfields?.provinceState?.value),
            },
            country: {
                ...selectfields?.country,
                error: validate.RequiredField(selectfields?.country?.value),
            },
        }

        const tempBusinessNameField = {
            ...businessNameField,
            businessName: {
                ...businessNameField?.businessName,
                error: validate.RequiredField(businessNameField?.businessName?.value),
            },
        }

        const tempTextAreaData = {
            ...textAreaFields,
            businessDetails: {
                ...textAreaFields?.businessDetails,
                error: validate.RequiredField(textAreaFields?.businessDetails?.value),
            },
        }

        setInputFields(tempInputData);
        setSelectFields(tempSelectData);
        setBusinessNameField(tempBusinessNameField);
        setTextAreaFields(tempTextAreaData);

        if (!HasError(tempInputData) && !HasError(tempSelectData) && !HasError(tempBusinessNameField) && !HasError(tempTextAreaData)) {
            const isCaptchaValid = await captchaRef.current.isCaptchaValid();
            if (!isCaptchaValid)
                return NotificationService.error("Captcha Error. Please refresh the page to proceeed.");

            const data = {
                ap_first_name: inputFields?.firstName?.value,
                ap_last_name: inputFields?.lastName?.value,
                ap_email_address: inputFields?.email?.value,
                ap_phone_number: utils.TransformTelephone(inputFields?.phoneNumber?.value),
                ap_business_name: businessNameField?.businessName?.value,
                ap_business_details: textAreaFields?.businessDetails?.value,
                ap_state: selectfields?.provinceState?.value,
                ap_country: selectfields?.country?.value,
                ap_contact_preferences: Object.keys(contactPreferences).find(key => contactPreferences[key] === true),
                ap_time_preferences: utils.FilterSelectedTimePreferences(timePreferences),
            }

            ApiService.createRegistrationRequest(data).then((response) => {
                if (response?.success)
                    setRequestSent(true);
                setLoading(false);
            });

        } else {
            NotificationService.error("Please resolve the errors.");
            setLoading(false);
        }
    }

    return (
        <div style={{ height: '100vh', marginBottom: '30%' }}>
            <div className={styles["Header"]}>Details</div>
            <Form className={styles['Form']} layout="vertical">
                <div className={styles["FormLayout"]}>

                    {/* Input Fields */}
                    {Object.keys(inputFields).map((value, index) => {
                        const data = inputFields[value];
                        return (
                            <ConfigProvider
                                key={index}
                                theme={joinUsTheme}
                            >
                                <Form.Item
                                    key="username"
                                    label={<b>{data?.label}</b>}
                                >
                                    <Input
                                        value={data?.value}
                                        onChange={data?.onChange}
                                        type="text"
                                        status={data?.error ? 'error' : ''}
                                        style={{ height: '40px' }}
                                    />
                                    {data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}

                                </Form.Item>
                            </ConfigProvider>
                        )
                    })}

                    {/* Dropdown Fields */}
                    {Object.keys(selectfields).map((value, index) => {
                        const data = selectfields[value];
                        return (
                            <ConfigProvider
                                key={index}
                                theme={joinUsTheme}
                            >
                                <Form.Item
                                    key="index"
                                    label={<b>{data?.label}</b>}
                                >
                                    <Select
                                        showSearch
                                        value={data?.value}
                                        onChange={data?.onChange}
                                        placeholder={`Select ${data?.label}`}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={data?.dropdownContent}
                                        style={{ height: '40px', textAlign: 'left' }}
                                    />
                                    {data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}

                                </Form.Item>
                            </ConfigProvider>
                        )
                    })}

                    <div className={styles["FormLayout_Merge"]}>
                        {Object.keys(businessNameField).map((value, index) => {
                            const data = businessNameField[value];
                            return (
                                <ConfigProvider
                                    key={index}
                                    theme={joinUsTheme}
                                >
                                    <Form.Item
                                        key="username"
                                        label={<b>{data?.label}</b>}
                                    >
                                        <Input
                                            value={data?.value}
                                            onChange={data?.onChange}
                                            type="text"
                                            status={data?.error ? 'error' : ''}
                                            style={{ height: '40px' }}
                                        />
                                        {data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}

                                    </Form.Item>
                                </ConfigProvider>
                            )
                        })}
                    </div>

                    <div className={styles["FormLayout_Merge"]}>
                        {/* Text Area Fields */}
                        {Object.keys(textAreaFields).map((value, index) => {
                            const data = textAreaFields[value];
                            return (
                                <ConfigProvider
                                    key={index}
                                    theme={joinUsTheme}
                                >
                                    <Form.Item
                                        key="username"
                                        label={<b>{data?.label}</b>}
                                    >
                                        <Input.TextArea
                                            value={data?.value}
                                            onChange={data?.onChange}
                                            status={data?.error ? 'error' : ''}
                                            style={{ height: '40px' }}
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                            placeholder={data?.placeholder}
                                            maxLength={300}
                                            allowClear
                                        />
                                        <div className={styles["MaxChar"]}>max. 300 characters</div>
                                        {data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}

                                    </Form.Item>
                                </ConfigProvider>
                            )
                        })}
                    </div>

                </div>

            </Form>

            <JoinUsContactPreference
                timePreferences={timePreferences}
                setTimePreferences={setTimePreferences}
                contactPreferences={contactPreferences}
                setContactPreferences={setContactPreferences}
            />

            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
                <Captcha ref={captchaRef} />
            </GoogleReCaptchaProvider>

            <Button
                type="primary"
                className={styles["SendRequest"]}
                onClick={handleSendRequest}
            >Send Request</Button>

            <div
                className={styles['CancelRequest']}
                onClick={() => navigate('/')}
            >Cancel Request</div>
        </div>
    );
} 