import { theme } from "antd";
import fallbackTheme from '../../../../../constants/THEMES';

export default function ConcessionRequestDeclined() {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={themeDangerColor || fallbackTheme.light.themeDangerColor} />
            <path d="M38.0159 30.7339C36.6875 30.7339 35.3889 31.1278 34.2844 31.8658C33.1799 32.6038 32.319 33.6528 31.8107 34.88C31.3023 36.1073 31.1693 37.4578 31.4285 38.7606C31.6877 40.0635 32.3273 41.2602 33.2666 42.1995C34.2059 43.1388 35.4027 43.7785 36.7056 44.0377C38.0084 44.2968 39.3589 44.1638 40.5861 43.6555C41.8134 43.1471 42.8623 42.2863 43.6004 41.1817C44.3384 40.0772 44.7323 38.7787 44.7323 37.4503C44.7304 35.6696 44.0222 33.9623 42.763 32.7032C41.5038 31.444 39.7966 30.7358 38.0159 30.7339ZM40.448 39.1514C40.496 39.1994 40.5341 39.2564 40.56 39.3191C40.586 39.3818 40.5994 39.449 40.5994 39.5169C40.5994 39.5848 40.586 39.652 40.56 39.7147C40.5341 39.7774 40.496 39.8344 40.448 39.8824C40.4 39.9304 40.343 39.9685 40.2803 39.9945C40.2176 40.0205 40.1503 40.0338 40.0824 40.0338C40.0146 40.0338 39.9473 40.0205 39.8846 39.9945C39.8219 39.9685 39.7649 39.9304 39.7169 39.8824L38.0159 38.1807L36.3148 39.8824C36.2668 39.9304 36.2098 39.9685 36.1471 39.9945C36.0844 40.0205 36.0172 40.0338 35.9493 40.0338C35.8814 40.0338 35.8142 40.0205 35.7514 39.9945C35.6887 39.9685 35.6317 39.9304 35.5837 39.8824C35.5357 39.8344 35.4977 39.7774 35.4717 39.7147C35.4457 39.652 35.4323 39.5848 35.4323 39.5169C35.4323 39.449 35.4457 39.3818 35.4717 39.3191C35.4977 39.2564 35.5357 39.1994 35.5837 39.1514L37.2854 37.4503L35.5837 35.7492C35.4868 35.6523 35.4323 35.5208 35.4323 35.3837C35.4323 35.2466 35.4868 35.1151 35.5837 35.0182C35.6807 34.9212 35.8122 34.8668 35.9493 34.8668C36.0864 34.8668 36.2179 34.9212 36.3148 35.0182L38.0159 36.7199L39.7169 35.0182C39.7649 34.9702 39.8219 34.9321 39.8846 34.9061C39.9473 34.8801 40.0146 34.8668 40.0824 34.8668C40.1503 34.8668 40.2176 34.8801 40.2803 34.9061C40.343 34.9321 40.4 34.9702 40.448 35.0182C40.496 35.0662 40.5341 35.1232 40.56 35.1859C40.586 35.2486 40.5994 35.3158 40.5994 35.3837C40.5994 35.4516 40.586 35.5188 40.56 35.5815C40.5341 35.6443 40.496 35.7012 40.448 35.7492L38.7463 37.4503L40.448 39.1514Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9294 41.6138C19.1426 42.5694 21.5073 43.0472 24.0236 43.0472C26.4209 43.0484 28.6805 42.6153 30.8026 41.7481C30.1964 40.7328 29.7985 39.5788 29.6678 38.3451C27.9428 39.0545 26.0614 39.4092 24.0236 39.4092C19.9611 39.4092 16.5201 37.9994 13.7006 35.1799C10.8811 32.3604 9.47137 28.9194 9.47137 24.8569C9.47137 20.7944 10.8811 17.3534 13.7006 14.5339C16.5201 11.7144 19.9611 10.3047 24.0236 10.3047C28.0861 10.3047 31.5271 11.7144 34.3466 14.5339C37.1661 17.3534 38.5759 20.7944 38.5759 24.8569C38.5759 26.3389 38.3883 27.7381 38.0131 29.0547C38.0141 29.0547 38.0151 29.0547 38.0162 29.0547C39.2738 29.0547 40.4669 29.3312 41.5379 29.8268C41.9886 28.2417 42.2139 26.585 42.2139 24.8569C42.2151 22.3406 41.7379 19.9759 40.7823 17.7627C39.8267 15.5496 38.5304 13.6244 36.8933 11.9873C35.2561 10.3514 33.331 9.05562 31.1178 8.10002C28.9047 7.14442 26.5399 6.66663 24.0236 6.66663C21.5073 6.66784 19.1426 7.14564 16.9294 8.10002C14.7162 9.05441 12.7911 10.3502 11.154 11.9873C9.51806 13.6244 8.22231 15.5496 7.26671 17.7627C6.31111 19.9759 5.83331 22.3406 5.83331 24.8569C5.83453 27.3733 6.31232 29.738 7.26671 31.9511C8.22109 34.1643 9.51685 36.0894 11.154 37.7266C12.7911 39.3625 14.7162 40.6582 16.9294 41.6138Z" fill="white" />
            <path d="M23.9779 37.5902C24.4023 37.5902 24.774 37.4307 25.093 37.1118C25.4119 36.7929 25.5708 36.4218 25.5695 35.9985V35.3164C27.0854 35.0436 28.389 34.4524 29.4805 33.5429C30.5719 32.6333 31.1176 31.2842 31.1176 29.4955C31.1176 28.2222 30.7538 27.055 30.0262 25.9939C29.2986 24.9328 27.8433 24.0081 25.6605 23.2199C23.8415 22.6135 22.5833 22.083 21.886 21.6282C21.1887 21.1735 20.8401 20.552 20.8401 19.7637C20.8401 18.9755 21.1208 18.354 21.6823 17.8992C22.2438 17.4444 23.0544 17.2171 24.1143 17.2171C24.7207 17.2171 25.2512 17.3232 25.706 17.5354C26.1607 17.7476 26.5397 18.0356 26.8429 18.3994C27.146 18.7632 27.4874 19.0131 27.867 19.1489C28.2466 19.2847 28.6025 19.2774 28.9348 19.1271C29.3895 18.9451 29.7006 18.6341 29.8679 18.1939C30.0353 17.7537 29.9971 17.3523 29.7533 16.9897C29.2682 16.2924 28.6692 15.7012 27.9561 15.2161C27.2431 14.7311 26.4778 14.4582 25.6605 14.3976V13.7154C25.6605 13.291 25.501 12.9193 25.1821 12.6004C24.8632 12.2814 24.4927 12.1226 24.0707 12.1238C23.6462 12.1238 23.2745 12.2833 22.9556 12.6022C22.6367 12.9211 22.4778 13.2922 22.479 13.7154V14.3976C20.9632 14.7311 19.7808 15.398 18.9319 16.3985C18.083 17.399 17.658 18.5207 17.6568 19.7637C17.6568 21.1886 18.0739 22.3407 18.9083 23.2199C19.7426 24.0991 21.0535 24.857 22.841 25.4937C24.751 26.1909 26.0777 26.8124 26.821 27.3582C27.5644 27.9039 27.9355 28.6163 27.9343 29.4955C27.9343 30.496 27.5778 31.2315 26.8647 31.702C26.1516 32.1725 25.2955 32.4072 24.2962 32.406C23.508 32.406 22.7955 32.2168 22.1589 31.8384C21.5222 31.4601 20.9917 30.8913 20.5672 30.1322C20.3247 29.7077 20.0063 29.4197 19.6122 29.2681C19.2181 29.1166 18.824 29.1166 18.4299 29.2681C18.0054 29.4197 17.6944 29.7077 17.4967 30.1322C17.299 30.5566 17.2917 30.9659 17.4749 31.36C17.9599 32.3908 18.6118 33.2324 19.4303 33.8848C20.2489 34.5373 21.2342 34.9841 22.3862 35.2255V35.9985C22.3862 36.423 22.5457 36.7947 22.8646 37.1136C23.1836 37.4326 23.5547 37.5914 23.9779 37.5902Z" fill="#645A80" />
        </svg>
    );
}