import { useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import ClientProfile from "../ClientProfile";
import OngoingDrawdownsList from "../OngoingDrawdownsList";
import ApprovalsList from "../ApprovalsList";
import ContactsBlock from "../ContactsBlock";

import styles from "./index.module.css";
import { useUpdateActiveTab } from "../../utils/UpdateActiveTab";

export default function Client({ refresh, setRefresh, data, setLoading }) {

    // Get Client ID from URL
    const { id } = useParams();
    const tabItems = [
        {
            key: 'profile',
            label: 'Profile',
            children: <ClientProfile setRefresh={setRefresh} clientId={id} data={data} setLoading={setLoading} />,
        },
        {
            key: 'contacts',
            label: 'Contacts',
            children: <ContactsBlock entity='client' entityId={id} refresh={refresh} setRefresh={setRefresh} />,
        },
        {
            key: 'ongoingDeals',
            label: 'Ongoing Deals',
            children: <OngoingDrawdownsList entity='client' entityId={id} />,
        },
        {
            key: 'approvals',
            label: 'Approvals',
            children: <ApprovalsList entity='client' entityId={id} />,
        },
    ];

    const [activeTab, setActiveTab] = useState(tabItems[0].key);

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(activeTab, setActiveTab, tabItems, tabItems[0]?.key);


    return (
        <>
            <div className={styles["container"]}>
                <Tabs
                    activeKey={activeTab}
                    defaultActiveKey={activeTab}
                    items={tabItems}
                    onChange={(key) => setActiveTab(key)}
                />
            </div>
        </>
    );
}