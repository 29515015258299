import Icon from '@ant-design/icons';
import { theme } from "antd";

function Location() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="31" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 -0.000976562C6.3 -0.000976562 0 6.29902 0 13.999C0 23.449 12.25 34.124 12.775 34.649C13.125 34.824 13.65 34.999 14 34.999C14.35 34.999 14.875 34.824 15.225 34.649C15.75 34.124 28 23.449 28 13.999C28 6.29902 21.7 -0.000976562 14 -0.000976562ZM14 30.974C10.325 27.474 3.5 19.949 3.5 13.999C3.5 8.22402 8.225 3.49902 14 3.49902C19.775 3.49902 24.5 8.22402 24.5 13.999C24.5 19.774 17.675 27.474 14 30.974ZM14 6.99902C10.15 6.99902 7 10.149 7 13.999C7 17.849 10.15 20.999 14 20.999C17.85 20.999 21 17.849 21 13.999C21 10.149 17.85 6.99902 14 6.99902ZM14 17.499C12.075 17.499 10.5 15.924 10.5 13.999C10.5 12.074 12.075 10.499 14 10.499C15.925 10.499 17.5 12.074 17.5 13.999C17.5 15.924 15.925 17.499 14 17.499Z" fill={ap_userPrimaryColor} />
        </svg>
    )
}

export default function LocationIcon(props) {
    return <Icon component={Location} {...props} />
}