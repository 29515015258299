import { useEffect, useState } from "react";
import PDFIcon from "../../assets/images/icons/PDF";
import ApiService from "../../api";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import utils from "../../utils";
import { Spin } from "antd";

export default function IndividualResources({ data }) {

    const [salesData, setSalesData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetching agent profile data
        setLoading(true);
        ApiService.fetchAgentProfileData().then((agentResponse) => {
            setSalesData(agentResponse?.ap_salesCoordinatorId);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <Spin spinning={loading} tip="Fetching Resources...">
                <div className={styles["Container"]}>
                    {data?.map((item, index) => {
                        return (
                            <div key={index} className={styles["IndividualItem"]} onClick={() => window.open(item?.url, '_blank')}>
                                <div><PDFIcon /></div>
                                <div className="Font_Medium">{item?.name}</div>
                            </div>
                        )
                    })}
                </div>
            </Spin>
            <div className={`${styles["Assistance"]} Font_UltraLight`}>
                If you need assistance, call your Sales Coordinator at
                <a href={`tel:${salesData?.ap_phoneNumber}`} className={styles["UrlStyling"]}> {(salesData?.ap_position === "Department") ? utils.FormatDepartmentTelephone(salesData?.ap_phoneNumber) : salesData?.ap_phoneNumber}</a> or email
                <a href={`mailto:${salesData?.ap_email}`} className={styles["UrlStyling"]}> {salesData?.ap_email}.</a>
            </div>
        </>
    );
}