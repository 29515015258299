import Icon from '@ant-design/icons';

function ApprovalMaintained() {
    return (
        <svg width="110" height="110" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65 13.5C51.2087 13.5 37.9823 18.9786 28.2304 28.7304C18.4786 38.4823 13 51.7087 13 65.5C13 79.2913 18.4786 92.5177 28.2304 102.27C37.9823 112.021 51.2087 117.5 65 117.5C78.7913 117.5 92.0177 112.021 101.77 102.27C111.521 92.5177 117 79.2913 117 65.5C117 51.7087 111.521 38.4823 101.77 28.7304C92.0177 18.9786 78.7913 13.5 65 13.5ZM0 65.5C0 29.6005 29.1005 0.5 65 0.5C100.899 0.5 130 29.6005 130 65.5C130 101.399 100.899 130.5 65 130.5C29.1005 130.5 0 101.399 0 65.5ZM32.5 65.5C32.5 63.7761 33.1848 62.1228 34.4038 60.9038C35.6228 59.6848 37.2761 59 39 59H91C92.7239 59 94.3772 59.6848 95.5962 60.9038C96.8152 62.1228 97.5 63.7761 97.5 65.5C97.5 67.2239 96.8152 68.8772 95.5962 70.0962C94.3772 71.3152 92.7239 72 91 72H39C37.2761 72 35.6228 71.3152 34.4038 70.0962C33.1848 68.8772 32.5 67.2239 32.5 65.5Z" fill="#EDCB00" />
        </svg>
    );
}

export default function ApprovalMaintainedIcon(props) {
    return <Icon component={ApprovalMaintained} {...props} />
}