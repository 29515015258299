import Icon from '@ant-design/icons';
import { theme } from "antd";

function DNE() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="150" height="128" viewBox="0 0 124 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.03027 14.6244V68.2179C2.03027 71.9524 5.26145 74.9799 9.2473 74.9799H66.602M2.03027 14.6244V9.61403C2.03027 5.87949 5.26145 2.85205 9.2473 2.85205H91.0403C95.0262 2.85205 98.2573 5.87949 98.2573 9.61403V14.6244M2.03027 14.6244H98.2573M98.2573 14.6244V48.6821M105.264 86.4417C109.564 82.2472 112.234 76.3892 112.234 69.9076C112.234 60.3908 106.478 52.2184 98.2573 48.6821M105.264 86.4417C101.102 90.5016 95.4123 93.0031 89.1387 93.0031C78.126 93.0031 68.9135 85.2951 66.602 74.9799M105.264 86.4417L121.97 103.147M66.602 74.9799C66.2362 73.3476 66.0432 71.6502 66.0432 69.9076C66.0432 57.1523 76.3834 46.8121 89.1387 46.8121C92.3771 46.8121 95.4599 47.4787 98.2573 48.6821" stroke={ap_userPrimaryColor} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.15796 8.85205C8.15796 7.74748 9.05339 6.85205 10.158 6.85205C11.2625 6.85205 12.158 7.74748 12.158 8.85205C12.158 9.95662 11.2625 10.8521 10.158 10.8521C9.05339 10.8521 8.15796 9.95662 8.15796 8.85205Z" fill={ap_userPrimaryColor} />
            <path d="M14.158 8.85205C14.158 7.74748 15.0534 6.85205 16.158 6.85205C17.2625 6.85205 18.158 7.74748 18.158 8.85205C18.158 9.95662 17.2625 10.8521 16.158 10.8521C15.0534 10.8521 14.158 9.95662 14.158 8.85205Z" fill={ap_userPrimaryColor} />
            <path d="M20.158 8.75928C20.158 7.65471 21.0534 6.75928 22.158 6.75928C23.2625 6.75928 24.158 7.65471 24.158 8.75928C24.158 9.86385 23.2625 10.7593 22.158 10.7593C21.0534 10.7593 20.158 9.86385 20.158 8.75928Z" fill={ap_userPrimaryColor} />
            <path d="M82.0772 63.1543C82.2671 62.9645 82.5246 62.8579 82.793 62.8579C83.0615 62.8579 83.319 62.9645 83.5089 63.1543L88.8681 68.5135L94.2272 63.1543C94.3206 63.0576 94.4324 62.9805 94.5559 62.9274C94.6794 62.8744 94.8123 62.8464 94.9467 62.8453C95.0812 62.8441 95.2145 62.8697 95.3389 62.9206C95.4634 62.9715 95.5764 63.0467 95.6715 63.1418C95.7665 63.2368 95.8417 63.3499 95.8926 63.4743C95.9435 63.5988 95.9692 63.7321 95.968 63.8665C95.9668 64.001 95.9389 64.1338 95.8858 64.2573C95.8328 64.3809 95.7556 64.4926 95.6589 64.586L90.2997 69.9452L95.6589 75.3044C95.8434 75.4953 95.9454 75.7511 95.9431 76.0166C95.9408 76.282 95.8343 76.536 95.6466 76.7237C95.4589 76.9114 95.2049 77.0179 94.9394 77.0202C94.674 77.0225 94.4182 76.9205 94.2272 76.736L88.8681 71.3769L83.5089 76.736C83.3179 76.9205 83.0622 77.0225 82.7967 77.0202C82.5312 77.0179 82.2773 76.9114 82.0895 76.7237C81.9018 76.536 81.7953 76.282 81.793 76.0166C81.7907 75.7511 81.8928 75.4953 82.0772 75.3044L87.4364 69.9452L82.0772 64.586C81.8874 64.3961 81.7808 64.1386 81.7808 63.8702C81.7808 63.6017 81.8874 63.3442 82.0772 63.1543Z" fill={ap_userPrimaryColor} stroke={ap_userPrimaryColor} />
        </svg>
    )
}

export default function DoesNotExists(props) {
    return <Icon component={DNE} {...props} />
}