import Icon from '@ant-design/icons';
import { theme } from "antd";

function DateOfIncorporation() {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="24" height="20" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33333 0C9.74589 0 10.1416 0.163888 10.4333 0.455612C10.725 0.747335 10.8889 1.143 10.8889 1.55556V3.11111H17.1111V1.55556C17.1111 1.143 17.275 0.747335 17.5667 0.455612C17.8584 0.163888 18.2541 0 18.6667 0C19.0792 0 19.4749 0.163888 19.7666 0.455612C20.0583 0.747335 20.2222 1.143 20.2222 1.55556V3.11111H24.8889C25.714 3.11111 26.5053 3.43889 27.0888 4.02233C27.6722 4.60578 28 5.3971 28 6.22222V26.4444C28 27.2696 27.6722 28.0609 27.0888 28.6443C26.5053 29.2278 25.714 29.5556 24.8889 29.5556H3.11111C2.28599 29.5556 1.49467 29.2278 0.911223 28.6443C0.327777 28.0609 0 27.2696 0 26.4444V6.22222C0 5.3971 0.327777 4.60578 0.911223 4.02233C1.49467 3.43889 2.28599 3.11111 3.11111 3.11111H7.77778V1.55556C7.77778 1.143 7.94167 0.747335 8.23339 0.455612C8.52511 0.163888 8.92077 0 9.33333 0ZM7.77778 6.22222H3.11111V10.8889H24.8889V6.22222H20.2222V7.77778C20.2222 8.19034 20.0583 8.586 19.7666 8.87772C19.4749 9.16944 19.0792 9.33333 18.6667 9.33333C18.2541 9.33333 17.8584 9.16944 17.5667 8.87772C17.275 8.586 17.1111 8.19034 17.1111 7.77778V6.22222H10.8889V7.77778C10.8889 8.19034 10.725 8.586 10.4333 8.87772C10.1416 9.16944 9.74589 9.33333 9.33333 9.33333C8.92077 9.33333 8.52511 9.16944 8.23339 8.87772C7.94167 8.586 7.77778 8.19034 7.77778 7.77778V6.22222ZM24.8889 14H3.11111V26.4444H24.8889V14Z" fill={ap_userPrimaryColor} />
            <path d="M6.11014 24.0954C5.78748 24.0954 5.53814 24.0074 5.36214 23.8314C5.18614 23.648 5.09814 23.3914 5.09814 23.0614V17.1764C5.09814 16.839 5.18614 16.5824 5.36214 16.4064C5.53814 16.2304 5.78748 16.1424 6.11014 16.1424C6.44014 16.1424 6.68948 16.2304 6.85814 16.4064C7.03414 16.5824 7.12214 16.839 7.12214 17.1764V23.0614C7.12214 23.3914 7.03781 23.648 6.86914 23.8314C6.70048 24.0074 6.44748 24.0954 6.11014 24.0954Z" fill={ap_userPrimaryColor} />
            <path d="M9.4709 24.0954C9.1629 24.0954 8.92824 24.0147 8.7669 23.8534C8.60557 23.692 8.5249 23.4537 8.5249 23.1384V17.1434C8.5249 16.8207 8.60557 16.575 8.7669 16.4064C8.92824 16.2304 9.1409 16.1424 9.4049 16.1424C9.63957 16.1424 9.8229 16.1864 9.9549 16.2744C10.0869 16.3624 10.2336 16.509 10.3949 16.7144L13.8819 21.0704H13.5739V17.0884C13.5739 16.7804 13.6509 16.5457 13.8049 16.3844C13.9662 16.223 14.2009 16.1424 14.5089 16.1424C14.8169 16.1424 15.0479 16.223 15.2019 16.3844C15.3632 16.5457 15.4439 16.7804 15.4439 17.0884V23.1824C15.4439 23.461 15.3706 23.6847 15.2239 23.8534C15.0772 24.0147 14.8792 24.0954 14.6299 24.0954C14.3806 24.0954 14.1826 24.0514 14.0359 23.9634C13.8966 23.868 13.7462 23.7214 13.5849 23.5234L10.0979 19.1564H10.4059V23.1384C10.4059 23.4537 10.3252 23.692 10.1639 23.8534C10.0099 24.0147 9.7789 24.0954 9.4709 24.0954Z" fill={ap_userPrimaryColor} />
            <path d="M20.7768 24.1174C19.9188 24.1174 19.1818 23.9524 18.5658 23.6224C17.9498 23.2924 17.4768 22.8267 17.1468 22.2254C16.8242 21.624 16.6628 20.92 16.6628 20.1134C16.6628 19.512 16.7545 18.9657 16.9378 18.4744C17.1285 17.983 17.3998 17.5614 17.7518 17.2094C18.1112 16.8574 18.5438 16.5897 19.0498 16.4064C19.5632 16.2157 20.1388 16.1204 20.7768 16.1204C21.1142 16.1204 21.4625 16.1607 21.8218 16.2414C22.1885 16.3147 22.5112 16.4284 22.7898 16.5824C22.9952 16.6924 23.1382 16.8317 23.2188 17.0004C23.2995 17.169 23.3252 17.3414 23.2958 17.5174C23.2738 17.6934 23.2115 17.851 23.1088 17.9904C23.0062 18.1297 22.8742 18.225 22.7128 18.2764C22.5515 18.3204 22.3755 18.2947 22.1848 18.1994C21.9648 18.0967 21.7448 18.0197 21.5248 17.9684C21.3122 17.9097 21.0885 17.8804 20.8538 17.8804C20.3992 17.8804 20.0178 17.9684 19.7098 18.1444C19.4092 18.313 19.1818 18.5624 19.0278 18.8924C18.8738 19.2224 18.7968 19.6294 18.7968 20.1134C18.7968 20.5974 18.8738 21.008 19.0278 21.3454C19.1818 21.6754 19.4092 21.9284 19.7098 22.1044C20.0178 22.273 20.3992 22.3574 20.8538 22.3574C21.0445 22.3574 21.2498 22.3354 21.4698 22.2914C21.6898 22.24 21.9062 22.163 22.1188 22.0604C22.3388 21.965 22.5332 21.9394 22.7018 21.9834C22.8778 22.0274 23.0172 22.1154 23.1198 22.2474C23.2298 22.3794 23.2995 22.5334 23.3288 22.7094C23.3582 22.878 23.3325 23.0467 23.2518 23.2154C23.1785 23.384 23.0465 23.5197 22.8558 23.6224C22.5992 23.7764 22.2802 23.8974 21.8988 23.9854C21.5248 24.0734 21.1508 24.1174 20.7768 24.1174Z" fill={ap_userPrimaryColor} />
        </svg>

    );
}

export default function DateOfIncorporationIcon(props) {
    return <Icon component={DateOfIncorporation} {...props} />
}