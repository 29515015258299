import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Signed = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="5" fill={ap_userPrimaryColor} />
            <path d="M79.2635 34.9862L79.2634 34.9863L72.8135 41.4362L51.3139 62.9358C51.3139 62.9358 51.3139 62.9358 51.3139 62.9358C50.8169 63.4327 50.5376 64.1066 50.5375 64.8093V64.8094V73.4093C50.5375 74.1121 50.8167 74.7861 51.3136 75.2831C51.8106 75.7801 52.4846 76.0592 53.1874 76.0592H61.7873H61.7874C62.4901 76.0591 63.164 75.7798 63.6609 75.2829C63.6609 75.2828 63.6609 75.2828 63.6609 75.2828L91.6105 47.3333L91.6105 47.3332C92.1073 46.8363 92.3864 46.1624 92.3864 45.4597C92.3864 44.757 92.1073 44.0831 91.6105 43.5862L91.6105 43.5861L83.0106 34.9863L83.0105 34.9862C82.5136 34.4894 81.8397 34.2103 81.137 34.2103C80.4343 34.2103 79.7604 34.4894 79.2635 34.9862ZM55.8374 70.7593V65.9066L74.6871 47.0569L79.5398 51.9096L60.6901 70.7593H55.8374ZM81.137 40.607L85.9897 45.4597L83.287 48.1624L78.4343 43.3097L81.137 40.607Z" fill="white" stroke="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.1381 15.1381C18.7691 16.5071 18 18.3639 18 20.3V78.7C18 80.6361 18.7691 82.4929 20.1381 83.8619C21.5071 85.2309 23.3639 86 25.3 86H69.1C71.0361 86 72.8929 85.2309 74.2619 83.8619C75.6309 82.4929 76.4 80.6361 76.4 78.7V65.86L69.1 72.9395V78.7H25.3V20.3H47.2V38.55C47.2 39.518 47.5846 40.4464 48.2691 41.1309C48.9536 41.8154 49.882 42.2 50.85 42.2H69.1L76.4 34.9C76.3998 33.932 76.0151 33.0038 75.3305 32.3195L57.0805 14.0694C56.3962 13.3849 55.468 13.0002 54.5 13H25.3C23.3639 13 21.5071 13.7691 20.1381 15.1381ZM54.5 21.8111L67.5889 34.9H54.5V21.8111Z" fill="white" />
            <path d="M28.3832 74.4326C29.4732 74.4326 30.9043 73.5357 31.6865 72.847C33.2278 71.4899 35.7647 68.7495 38.6879 63.7575C40.1373 61.2824 41.4895 58.6127 41.58 55.6721C41.5917 55.2928 41.3833 54.6673 41.1262 54.6101C40.6978 54.5148 40.2809 54.453 39.843 54.7658C37.8093 56.2185 35.9346 60.2736 35.9346 60.2736C35.9346 60.2736 34.861 62.3632 34.0467 65.4652C33.8187 66.3339 33.222 71.126 34.5187 72.0727C36.4372 73.4734 39.6667 70.4898 40.1007 70.1282C41.2555 69.3113 43.4173 66.9127 44.9019 66.8811C48.198 66.9337 45.8459 72.1652 47.7337 73.8834C49.6216 75.6016 52.4534 74.4326 52.4534 74.4326" stroke="white" strokeWidth="3.5" strokeLinecap="round" />
        </svg>
    );
};

export default function SignedIcon(props) {
    return <Icon component={Signed} {...props} />
}