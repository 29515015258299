import { theme } from 'antd';
import Icon from '@ant-design/icons';

const Requirement = () => {
    const { token: { ap_userPrimaryColor }, } = theme.useToken();
    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="2" width="26" height="26" rx="5" stroke={ap_userPrimaryColor} strokeWidth="3" />
            <path d="M7.46606 8.64886L8.49475 9.78041L10.6551 7.46582M14.8643 8.62311H22.5341M7.46606 15.0256L8.49475 16.1571L10.6551 13.8426M14.8643 14.9998H22.5341M7.46606 21.4023L8.49475 22.5339L10.6551 20.2193M14.8643 21.3766H22.5341" stroke={ap_userPrimaryColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default function RequirementIcon(props) {
    return <Icon component={Requirement} {...props} />
}