import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip, Switch, Table, Modal, Button, theme, ConfigProvider } from "antd";
import { CloseCircleFilled, ExclamationCircleFilled, InfoCircleOutlined, StarFilled } from "@ant-design/icons";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import EditContacts from "../EditContacts";
import validate from "../../utils/Validations";

import BillingIcon from "../../assets/images/icons/BillingIcon";
import DeleteIcon from "../../assets/images/icons/DeleteIcon";
import EmailIcon from "../../assets/images/icons/EmailIcon";
import MessageIcon from "../../assets/images/icons/MessageIcon";
import StarIcon from "../../assets/images/icons/StarIcon";
import WhatsAppIcon from "../../assets/images/icons/WhatsAppIcon";

import styles from "./index.module.css";

export default function ContactsList({ entity, entityId, refresh, setRefresh }) {

    const { token: { themePrimaryColor, themeFontColor } } = theme.useToken();

    const [loader, setLoader] = useState({ loading: false, message: '' });
    const [contacts, setContacts] = useState([]);
    const [primaryContact, setPrimaryContact] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalIcon, setModalIcon] = useState(<></>);

    const navigate = useNavigate();

    useEffect(() => {
        if (!contacts.length) return;
        const newprimaryContact = contacts.find(contact => contact.ap_primaryContact === true)?._id;
        if (newprimaryContact) setPrimaryContact(newprimaryContact);
    }, [contacts])

    useEffect(() => {
        if (entity === 'agent') {
            setLoader({ loading: true, message: 'Fetching Contacts...' });
            ApiService.fetchAgentContacts(entityId).then((contactsResponse) => {
                if (!contactsResponse) {
                    setLoader({ loading: false, message: '' });
                    return;
                }
                let arr = [];
                for (let i = 0; i < contactsResponse.length; i++) {
                    arr.push({
                        ...contactsResponse[i],
                        ap_name: contactsResponse[i]?.ap_firstName + " " + contactsResponse[i]?.ap_lastName
                    })
                }
                arr.sort((a, b) => b.ap_primaryContact - a.ap_primaryContact);
                setContacts(arr);
                setLoader({ loading: false, message: '' });
            })
        } else if (entity === 'client') {
            setLoader({ loading: true, message: 'Fetching Contacts...' });
            ApiService.fetchClientContacts(entityId).then((contactsResponse) => {
                if (!contactsResponse) {
                    setLoader({ loading: false, message: '' });
                    return;
                }
                let arr = [];
                for (let i = 0; i < contactsResponse.length; i++) {
                    arr.push({
                        ...contactsResponse[i],
                        ap_name: contactsResponse[i]?.ap_firstName + " " + contactsResponse[i]?.ap_lastName
                    })
                }
                arr.sort((a, b) => b.ap_primaryContact - a.ap_primaryContact);
                setContacts(arr);
                setLoader({ loading: false, message: '' });
            })
        }
    }, [entity, entityId, refresh]);

    const handleToggle = (id, record, data, attribute, value) => {
        const updateData = {
            ...record,
            ...(entity === 'agent' && { ap_agentData: { ...data, [attribute]: value } }),
            ...(entity === 'client' && { ap_clientData: { ...data, [attribute]: value } }),

        }
        delete updateData._id
        ApiService.updateContact(id, updateData).then((resp) => {
            if (resp?.success) setRefresh(prevRefresh => prevRefresh + 1);
            else NotificationService.error("Error", "Failed to update field");
        })
    }

    const handleBilling = () => {
        setModal(true);
        setModalIcon(<ExclamationCircleFilled style={{ color: 'gold', fontSize: '22px' }} />);
        setModalMessage("Billing notifications for Primary Contact cannot be turned off.");
    }

    const handleEmail = () => {
        setModal(true);
        setModalIcon(<ExclamationCircleFilled style={{ color: 'gold', fontSize: '22px' }} />);
        setModalMessage("Email notifications for Primary Contact cannot be turned off.");
    }

    const handlePrimaryError = () => {
        setModal(true);
        setModalIcon(<CloseCircleFilled style={{ color: 'red', fontSize: '22px' }} />);
        setModalMessage("Uh oh! Your Primary Contact Cannot be deleted");
    }

    const handleDeleteContact = (contactId, data) => {
        ApiService.deleteContact(contactId, data).then((response) => {
            if (response?.success) {
                NotificationService.success('Success', response?.success);
                setRefresh(prevRefresh => prevRefresh + 1);
            } else NotificationService.error("Error", response?.error);
        })
    }

    const prepareContactData = (id, data) => {
        return {
            "ap_lastModifiedBy": "beacon",
            ...(entity === 'agent' && {
                ap_agentData: {
                    ...data.ap_agentData,
                    ap_billingNotification: data?._id === id || data?.ap_agentData?.ap_billingNotification,
                    ap_emailNotification: (data?._id === id) || data?.ap_agentData?.ap_emailNotification
                }
            }),
        }
    }

    const handlePrimaryContact = (id, data) => {
        let primaryContactData = contacts.find(contact => contact._id === id);
        let previousPrimaryContactData = contacts.find(contact => contact._id === primaryContact);

        let updatedData = prepareContactData(id, previousPrimaryContactData)
        updatedData["ap_primaryContact"] = false

        try {
            if (primaryContact && Object.keys(previousPrimaryContactData).length !== 0) {
                setLoader({ loading: true, message: 'Updating Primary Contact...' });
                ApiService.updateContact(previousPrimaryContactData._id, updatedData).then((resp) => {
                    if (resp.success) {
                        setLoader({ loading: false, message: '' });
                        setRefresh(prevRefresh => prevRefresh + 1);
                    }
                    else {
                        NotificationService.error("Error", "Failed to update field");
                        setLoader({ loading: false, message: '' });
                        return;
                    }
                })
            }
            updatedData = prepareContactData(id, primaryContactData)
            updatedData["ap_primaryContact"] = true

            setLoader({ loading: true, message: 'Updating Primary Contact...' });
            ApiService.updateContact(primaryContactData._id, updatedData).then((resp) => {
                if (resp.success) {
                    setPrimaryContact(primaryContactData._id)
                    setRefresh(prevRefresh => prevRefresh + 1);
                    setLoader({ loading: false, message: '' });
                    NotificationService.success("Success", "Primary Contact Updated");
                }
                else {
                    NotificationService.error("Error", "Failed to update field");
                    setLoader({ loading: false, message: '' });
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const tableColumns = [
        {
            title: <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                Status
                <Tooltip title="Select Primary Contact by clicking the star">
                    <InfoCircleOutlined style={{ paddingLeft: '5%', fontSize: '18px' }} />
                </Tooltip>
            </div>,
            key: 'ap_primaryContact',
            render: (record) => (
                record?.ap_primaryContact ?
                    <StarFilled style={{ color: 'gold', fontSize: '24px' }} onClick={(e) => e.stopPropagation()}/> :
                    <div  onClick={(e) => {
                        e.stopPropagation();
                        handlePrimaryContact(record?._id, record?.ap_agentData || record?.ap_clientData)}}>
                        <StarIcon />
                    </div>
            )
        },
        {
            title: 'Name',
            key: 'ap_name',
            sorter: (a, b) => (a?.ap_name || "").localeCompare(b?.ap_name || ""),
            sortDirection: ['ascend', 'descend'],
            render: (record) => (<p style={(record?.ap_primaryContact) ? { fontWeight: 'bold', whiteSpace: 'nowrap', color: themeFontColor } : { whiteSpace: 'nowrap', color: themeFontColor }}>{record?.ap_name}</p>),
        },
        {
            title: 'Email',
            key: 'ap_email',
            render: (record) => (<a style={(record?.ap_primaryContact) ? { fontWeight: 'bold' } : {}} href={`mailto:${record?.ap_email}`}>{record?.ap_email}</a>)
        },
        {
            title: 'Telephone',
            key: 'ap_telephone',
            render: (record) => (<a style={(record?.ap_primaryContact) ? { fontWeight: 'bold', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }} href={`tel:${record?.ap_telephone}`}>{validate.FormatTelephone(String(record.ap_telephone)).input}</a>)
        },
    ];

    if (entity === 'agent')
        tableColumns.push(
            {
                title: <div>
                    <MessageIcon style={{ paddingRight: '5%' }} />
                    <Tooltip title="Toggle to receive SMS communications for this contact">
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                </div>,
                key: "ap_smsNotification",
                render: (record) => (
                <div onClick={(e) => e.stopPropagation()}>
                <Switch
                    checked={record?.ap_agentData?.ap_smsNotification}
                    onClick={(value) => handleToggle(record?._id, record, record?.ap_agentData, "ap_smsNotification", value)}
                    disabled
                />
                </div>
                )
            },
            {
                title: <div>
                    <WhatsAppIcon style={{ paddingRight: '5%', fontSize: '18px' }} color={themeFontColor} width={22} height={22} />
                    <Tooltip title="Toggle to receive WhatsApp communications for this contact">
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                </div>,
                key: "ap_whatsAppNotification",
                render: (record) => (
                <div onClick={(e) => e.stopPropagation()}>
                <Switch
                    checked={record?.ap_agentData?.ap_whatsAppNotification}
                    onClick={(value) => handleToggle(record?._id, record, record?.ap_agentData, "ap_whatsAppNotification", value)}
                />
                </div>
                )
            },
            {
                title: <div>
                    <EmailIcon style={{ paddingRight: '5%' }} color={themeFontColor} width={23} height={20} />
                    <Tooltip title="Toggle to receive email communications for this contact">
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                </div>,
                key: "ap_emailNotification",
                render: (record) => (
                <div onClick={(e) => e.stopPropagation()}>
                <Switch
                    checked={record?.ap_agentData?.ap_emailNotification}
                    onClick={(value) => {
                        record?.ap_primaryContact ?
                            handleEmail() :
                            handleToggle(record?._id, record, record?.ap_agentData, "ap_emailNotification", value)
                    }}
                />
                </div>
                )
            },
            {
                title: <div>
                    <BillingIcon style={{ paddingRight: '5%' }} color={themeFontColor} width={20} height={23} />
                    <Tooltip title="Toggle to receive invoices for this contact">
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                </div>,
                key: "ap_billingNotification",
                render: (record) => (
                    <div onClick={(e) => e.stopPropagation()}>
                    <ConfigProvider
                        theme={{
                            token: {
                                handleBg: 'red',
                            }
                        }}
                    >
                        <Switch
                            checked={record?.ap_agentData?.ap_billingNotification}
                            onClick={(value) => {
                                record?.ap_primaryContact ?
                                    handleBilling() :
                                    handleToggle(record?._id, record, record?.ap_agentData, "ap_billingNotification", value)
                            }}
                        />
                    </ConfigProvider>
                    </div>
                )
            },
        )

    tableColumns.push(
        {
            title: "Action",
            key: 'ap_action',
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }} 
                        onClick={(e) => {
                            e.stopPropagation()
                            }}>
                        <EditContacts
                            entity={entity}
                            entityId={entityId}
                            contactId={record._id}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            iconButton={true}
                        />
                        <div
                            onClick={() => {
                                record?.ap_primaryContact ? handlePrimaryError() : handleDeleteContact(record?._id, record)
                            }}
                        >
                            <DeleteIcon />
                        </div>
                    </div>
                )
            }
        },
    );

    return (
        <div style={{ margin: "2% 0% 0% 0%" }}>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50',
                    },
                }}
            >
                <Table
                    columns={tableColumns}
                    dataSource={contacts}
                    scroll={{ x: true }}
                    pagination={false}
                    className={styles["tableBody"]}
                    size="small"
                    onRow={(record) => ({
                        onClick: () => {
                            if (entity === 'agent')
                                navigate(`/profile/${entityId}/contacts/${record._id}`);
                            else navigate(`/clients/${entityId}/contacts/${record._id}`);
                        },
                    })}
                    loading={loader?.loading}
                />
            </ConfigProvider>
            <Modal
                open={modal}
                footer={null}
                closable={false}
                getContainer=".AppTheme"
                centered
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2%', minHeight: '80px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {modalIcon}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {modalMessage}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type="primary"
                        onClick={() => setModal(false)}
                    >Ok</Button>
                </div>
            </Modal>
        </div>
    );
}