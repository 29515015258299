import React, { useEffect, useCallback, useState } from 'react';
import { Select, Divider, Space, Input, Button, ConfigProvider, theme } from 'antd';
import debounce from 'lodash.debounce';
import styles from "./index.module.css";

const DropdownSelectSearch = ({
  value,
  index,
  onChange,
  options,
  handleCreateOption,
  allowClear = true,
  placeholder = 'Please select',
  status,
  handleValidation = () => false,
}) => {
    const { token: { ap_userHighlightColor, ap_userPrimaryColor, themePrimaryColor, themeSecondaryColor, themeFontColor, themeDisabledContainer }, } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor
    };

    const [newOption, setNewOption] = useState({ value: '', error: '' });
    const [filteredOptions, setFilteredOptions] = useState([]);    

    useEffect(() => {
        setFilteredOptions(options)
    }, [])

    useEffect(() => {
        handleSearch(newOption.value)
    }, [options]);
    
    const handleSearch = (searchValue) => {
        let searchTerm = searchValue?.trim();
        if(!searchTerm){
            setFilteredOptions(options);
            return;
        }
        const filtered = options.filter(option =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filtered);
    }


    // Handle new option input
    const handleNewValue = (value) => {
        handleSearch(value);
        setNewOption({ value: value, error: '' });
    };

    
    const handleOnCreate = () => {
        handleCreateOption(newOption.value, index)
        // reset new option
        setFilteredOptions(options);
        setNewOption({ value: '', error: '' });
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: ap_userHighlightColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                },
            }}
        >
            <Select
                value={value}
                status={status || (handleValidation(value) ? 'error' : '')}
                className={styles["OptionRow"]}
                allowClear={allowClear}
                onChange={(selectedOption) => onChange(selectedOption, index)}
                optionFilterProp="children"
                style={{ textAlign: 'left' }}
                options={filteredOptions}
                dropdownStyle={{width: "240px"}}
                maxTagCount={2}
                dropdownRender={(menu) => (
                    <div >
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input placeholder={placeholder} value={newOption.value} onChange={(e) => handleNewValue(e.target.value)} />
                            <Button
                                type="primary"
                                className={styles["CreateOption"]}
                                style={themeColorVariables}
                                onClick={() => handleOnCreate()}
                                disabled={!!filteredOptions.length}
                            >
                                Add
                            </Button>
                        </Space>
                    </div>
                )}
            />
        </ConfigProvider>
    );
};

export default DropdownSelectSearch;
