export default function ValidateVIN(vin) {
    return {
        value: vin,
        error: CheckVIN(vin) ? '' : 'Invalid VIN Number'
    }
}

function CheckVIN(vin) {
    if (vin?.length !== 17) {
        return false;
    }
    return CheckDigits(vin) === vin[8];
}

function CheckDigits(vin) {
    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for (let i = 0; i < 17; ++i) {
        sum += Transliterate(vin[i]) * map.indexOf(weights[i]);
    }
    return map[sum % 11];
}

function Transliterate(c) {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
}