import { Progress, theme } from "antd";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function ProgressBar({ size, measure, updated, uploading }) {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    const [progress, setProgress] = useState(0);
    const [currentSize, setCurrentSize] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(!updated);

    useEffect(() => {
        let timer;
 
        const updateProgress = () => {
            const duration = 1000;
            const interval = 20;
            const steps = duration / interval;
            const increment = 100 / steps;
            const sizeIncrement = size / steps;
 
            let currentValue = progress || 0;
            let currentSizeValue = 0;
 
            timer = setInterval(() => {
                if (uploading && currentValue >= 80) {
                    clearInterval(timer);
                    return;
                }
 
                currentValue += increment;
                currentSizeValue += sizeIncrement;
 
                setProgress(Math.min(currentValue, uploading ? 80 : 100));
                setCurrentSize(Math.min(currentSizeValue, size));
 
                if (currentValue >= 100) {
                    clearInterval(timer);
                    setInterval(() => setShowProgressBar(false), 1000);
                }
            }, interval);
        };
 
        updateProgress();
 
        return () => clearInterval(timer);
    }, [size, uploading]);

    return (
        <div className={styles["smooth-progress-bar"]}>
            {showProgressBar ?
                <>
                    <Progress
                        percent={progress}
                        showInfo={true}
                        strokeColor={ap_userSecondaryColor}
                    />
                    <div className={`${styles[uploading ? "Hide" : "current-size"]} Font_UltraLight`}>
                        {currentSize.toFixed(2)} {measure} of {size.toFixed(2)} {measure}
                    </div>
                </> :
                <div className={`${styles[uploading ? "Hide" : "current-size"]} Font_UltraLight`}>
                    {size?.toFixed(2)} {measure}
                </div>
            }
        </div>
    );
}