import InputLayout from "../InputLayout";
import styles from "./index.module.css";

export default function RegistrationProfile({ data }) {

    const firstName = { firstName: { ...data.firstName } }
    const lastName = { lastName: { ...data.lastName } }

    const email = { email: { ...data.email } }
    const phoneNumber = { phoneNumber: { ...data.phoneNumber } }

    const password = { password: { ...data.password } }
    const confirmPassword = { confirmPassword: { ...data.confirmPassword } }

    return (
        <>
            <div style={{ minWidth: 'fit-content', fontSize: '18px' }}>
                Please enter the login information for your account.
            </div>
            <div className={styles["row1"]}>
                <InputLayout data={firstName} layout="vertical" />
                <InputLayout data={lastName} layout="vertical" />
            </div>

            <div className={styles["row2"]}>
                <InputLayout data={email} layout="vertical" />
                <InputLayout data={phoneNumber} layout="vertical" />
            </div>

            <div className={styles["row3"]}>
                <InputLayout data={password} layout="vertical" showRequirement={true} />
                <InputLayout data={confirmPassword} layout="vertical" showRequirement={true} />
            </div>
        </>
    );

}