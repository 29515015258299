import { Form } from "antd";
import styles from "./index.module.css";
import InputPhone from "../../assets/images/icons/InputPhone";
import InputMail from "../../assets/images/icons/InputMail";
import RequiredLabelIndicator from '../RequiredLabelIndicator';

export default function ContactInputField({ data, item, layout }) {

    return (
        <>
            {data?.hidden ? null : (
                <div id="ContactInputField" className={(layout === "horizontal") ? styles["inner-content"] : styles["vertical-content"]}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout == 'vertical' ?
                            <>
                                <div className={styles["input"]}>
                                    <div>{data?.value}</div>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <a href={`tel:${data?.tel}`} className={styles[(data?.tel) ? "visible" : "hide"]}>
                                            <InputPhone />
                                        </a>
                                        <a href={`mailto:${data?.mail}`} className={styles[(data?.mail) ? "visible" : "hide"]}>
                                            <InputMail />
                                        </a>
                                    </div>
                                </div>
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </>
                            : null}
                    </Form.Item>
                    {layout == 'horizontal' ?
                        <>
                            <div className={styles["input"]}>
                                <div>{data?.value}</div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <a href={`tel:${data?.tel}`} className={styles[(data?.tel) ? "visible" : "hide"]}>
                                        <InputPhone />
                                    </a>
                                    <a href={`mailto:${data?.mail}`} className={styles[(data?.mail) ? "visible" : "hide"]}>
                                        <InputMail />
                                    </a>
                                </div>
                            </div>
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </>
                        : null}
                </div>
            )}
        </>
    );
}