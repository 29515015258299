import Icon from '@ant-design/icons';
import { theme } from 'antd';

function CreditExtended() {
    const { token: { ap_userPrimaryColor, ap_userSecondaryColor } } = theme.useToken();
    return (
        <svg width="100" height="100" viewBox="0 0 142 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M37.1622 130.775C44.5332 133.925 52.4375 135.5 60.875 135.5C77.858 135.505 92.2287 129.628 103.987 117.869C115.746 106.111 121.625 91.7375 121.625 74.75V74.4125C121.625 72.7295 120.95 71.24 119.6 69.944C118.25 68.648 116.675 68 114.875 68C112.967 68 111.365 68.7313 110.069 70.1938C108.773 71.6563 108.125 73.3438 108.125 75.2563C107.904 88.4188 103.265 99.5 94.2065 108.5C85.148 117.5 74.0375 122 60.875 122C47.6 122 36.4062 117.444 27.2937 108.331C18.1812 99.2188 13.625 88.025 13.625 74.75C13.625 61.475 18.1812 50.2813 27.2937 41.1688C36.4062 32.0563 47.6 27.5 60.875 27.5C61.775 27.5045 62.675 27.5338 63.575 27.5878C64.475 27.6418 65.375 27.725 66.275 27.8375C68.633 28.067 70.5725 27.59 72.0935 26.4065C73.6145 25.223 74.375 23.5063 74.375 21.2563C74.375 19.6858 73.8687 18.2525 72.8562 16.9565C71.8437 15.6605 70.6062 14.9 69.1437 14.675C67.7937 14.45 66.4437 14.2813 65.0937 14.1688C63.7437 14.0563 62.3375 14 60.875 14C43.892 13.9955 29.5212 19.8725 17.7627 31.631C6.00425 43.3895 0.125 57.7625 0.125 74.75C0.125 83.183 1.7 91.085 4.85 98.456C8 105.827 12.3312 112.269 17.8437 117.781C23.3517 123.294 29.7912 127.625 37.1622 130.775ZM56.15 79.475L75.05 98.375C76.2875 99.6125 77.8625 100.231 79.775 100.231C81.6875 100.231 83.2625 99.6125 84.5 98.375C85.7375 97.1375 86.3562 95.5625 86.3562 93.65C86.3562 91.7375 85.7375 90.1625 84.5 88.925L67.625 72.05V47.75C67.6295 45.842 66.9837 44.24 65.6877 42.944C64.3917 41.648 62.7875 41 60.875 41C58.967 40.9955 57.365 41.6413 56.069 42.9373C54.773 44.2333 54.125 45.8375 54.125 47.75V74.5813C54.125 75.4858 54.2937 76.3588 54.6312 77.2003C54.9687 78.0418 55.475 78.8 56.15 79.475Z" fill={ap_userSecondaryColor} />
            <path d="M114.875 54.5C112.962 54.5 111.358 53.852 110.062 52.556C108.766 51.26 108.12 49.658 108.125 47.75V34.25H94.625C92.7125 34.25 91.1082 33.602 89.8122 32.306C88.5162 31.01 87.8705 29.408 87.875 27.5C87.875 25.5875 88.523 23.9833 89.819 22.6873C91.115 21.3913 92.717 20.7455 94.625 20.75H108.125V7.25002C108.125 5.33752 108.773 3.73327 110.069 2.43727C111.365 1.14127 112.967 0.495523 114.875 0.500023C116.787 0.500023 118.392 1.14802 119.688 2.44402C120.984 3.74002 121.629 5.34202 121.625 7.25002V20.75H135.125C137.037 20.75 138.642 21.398 139.938 22.694C141.234 23.99 141.879 25.592 141.875 27.5C141.875 29.4125 141.227 31.0168 139.931 32.3128C138.635 33.6088 137.033 34.2545 135.125 34.25H121.625V47.75C121.625 49.6625 120.977 51.2668 119.681 52.5628C118.385 53.8588 116.783 54.5045 114.875 54.5Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function CreditExtendedIcon(props) {
    return <Icon component={CreditExtended} {...props} />
}