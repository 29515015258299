export default function ValidateConfirmPassword(password, confirmPassword){
    
    if(!confirmPassword){
        return 'Required';
    }
    
    if(password !== confirmPassword){
        return 'Password does not match';
    }
    
    return '';
}