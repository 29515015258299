import { theme } from 'antd';
import Icon from '@ant-design/icons';

const AddTask = () => {
    const { token: {ap_userPrimaryColor}, } = theme.useToken();
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.45 11.55V14.7C9.45 14.9975 9.5508 15.247 9.7524 15.4486C9.954 15.6502 10.2032 15.7507 10.5 15.75C10.7975 15.75 11.047 15.6492 11.2486 15.4476C11.4502 15.246 11.5507 14.9968 11.55 14.7V11.55H14.7C14.9975 11.55 15.247 11.4492 15.4486 11.2476C15.6502 11.046 15.7507 10.7968 15.75 10.5C15.75 10.2025 15.6492 9.95295 15.4476 9.75135C15.246 9.54975 14.9968 9.4493 14.7 9.45H11.55V6.3C11.55 6.0025 11.4492 5.75295 11.2476 5.55135C11.046 5.34975 10.7968 5.2493 10.5 5.25C10.2025 5.25 9.95295 5.3508 9.75135 5.5524C9.54975 5.754 9.4493 6.0032 9.45 6.3V9.45H6.3C6.0025 9.45 5.75295 9.5508 5.55135 9.7524C5.34975 9.954 5.2493 10.2032 5.25 10.5C5.25 10.7975 5.3508 11.047 5.5524 11.2486C5.754 11.4502 6.0032 11.5507 6.3 11.55H9.45ZM10.5 21C9.0475 21 7.6825 20.7242 6.405 20.1726C5.1275 19.621 4.01625 18.873 3.07125 17.9287C2.12625 16.9838 1.3783 15.8725 0.8274 14.595C0.2765 13.3175 0.0007 11.9525 0 10.5C0 9.0475 0.2758 7.6825 0.8274 6.405C1.379 5.1275 2.12695 4.01625 3.07125 3.07125C4.01625 2.12625 5.1275 1.3783 6.405 0.8274C7.6825 0.2765 9.0475 0.0007 10.5 0C11.9525 0 13.3175 0.2758 14.595 0.8274C15.8725 1.379 16.9838 2.12695 17.9287 3.07125C18.8737 4.01625 19.622 5.1275 20.1736 6.405C20.7252 7.6825 21.0007 9.0475 21 10.5C21 11.9525 20.7242 13.3175 20.1726 14.595C19.621 15.8725 18.873 16.9838 17.9287 17.9287C16.9838 18.8737 15.8725 19.622 14.595 20.1736C13.3175 20.7252 11.9525 21.0007 10.5 21Z" fill={ap_userPrimaryColor}/>
        </svg>


    );
};

export default function AddTaskIcon (props) {
    return <Icon component={AddTask} {...props}/>
}